export const PlanTypes = [
  {
    name: "Free",
    slug: "free",
    order: 1,
    amount: { monthly: 0, yearly: 0 },
    tasks: 2000,
    subDescription: "free forever",
    description: "For individuals who need the basics of automation.",
    productId: {
      monthly: "prod_NpL5Ib475szF4X",
      yearly: "prod_NpL5Ib475szF4X",
    },
    priceId: {
      monthly: "price_1N3gPLCwaHmK3UjoXGzXlM1l",
      yearly: "price_1N3gPLCwaHmK3UjoXGzXlM1l",
    },
    features: [
      "5 Konnectors",
      "15 min polling interval",
      "Event Logs",
      "Lookup Actions",
      "Rule based Konnectors",
      "SSO Login",
      "Email support",
      "Multiple Actions Konnector",
      "No Code Platform",
    ],
  },
  {
    name: "Startup",
    slug: "startup",
    order: 2,
    active: true,
    productId: {
      monthly: "prod_NpLLUsGVy7jKXf",
      yearly: "prod_NpLMzvLn8Ks5xc",
    },
    priceId: {
      monthly: "price_1N3gerCwaHmK3UjoSVu2jUov",
      yearly: "price_1N3gg0CwaHmK3Ujo7WqbrmwU",
    },
    amount: { monthly: 24.99, yearly: 14.99 },
    tasks: 7500,
    description: "For individuals with simple, multi-step automation needs.",
    features: [
      "Unlimted Konnectors",
      "10 min polling interval",
      "Event Logs",
      "Lookup Actions",
      "Rule based Konnectors",
      "SSO Login",
      "Support 10hrs/month",
      "Multiple Actions Konnector",
      "No Code Platform",
      "Smart Forms Upto 5",
      "Webhook Konnectors",
      "Premimium Apps",
    ],
  },
  {
    name: "Growth",
    slug: "growth",
    order: 3,
    amount: { monthly: 49.99, yearly: 39.99 },
    productId: {
      monthly: "prod_NpP79kyOzeHfTm",
      yearly: "prod_NpP8TBqdFuaESP",
    },
    priceId: {
      monthly: "price_1N3kJUCwaHmK3UjokH3jJOU2",
      yearly: "price_1N3kKdCwaHmK3UjowduT5Ukw",
    },
    active: true,
    tasks: 25000,
    description: "For individuals that need the full power of Konnectify's automation platform.",
    features: [
      "Unlimted Konnectors",
      "5 min polling interval",
      "Event Logs",
      "Lookup Actions",
      "Rule based Konnectors",
      "SSO Login",
      "Support 30hrs/month",
      "Multiple Actions Konnector",
      "No Code Platform",
      "Unlimited Smart forms",
      "Webhook Konnectors",
      "Premimium Apps",
    ],
  },
];

export const PlanPeriods = ["monthly", "yearly"];
export const PlanOrder = {
  free: 1,
  startup: 2,
  growth: 3,
};

export const addOnId = {
  monthlt: "prod_NpLH1ghxIBG2T2",
  yearly: "prod_NpLIrFaMXHwsRj",
};
