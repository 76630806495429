import { Slider, styled } from "@mui/material";
import Badge from "common/components/Badge";
import { BadgeTypes } from "common/components/Badge/types";
import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useAppSelector } from "state/store";
import { PlanTypes } from "./Constants/planTypes";

const CurrentPlans: React.FC<any> = ({ user }) => {
  const currentPlan = useAppSelector((state) => state.payments.currentPlan);
  const trialDays = useAppSelector((state) => state.payments.trialDays);
  const { billing, taskExecutedCount, eventsLimit, addon, cycle, stripeId, hasTrial } = user;
  const trail = true;
  const percentage = (900 / 10000) * 100;
  const wrapperClass = "w-1/4 h-full flex justify-center items-center text-center flex-col text-primary-color ";

  return (
    <div className="flex h-28 w-full justify-between items-center px-14  bg-gray-100 border-b-2 bg-[#F2F6FF]">
      <div className={wrapperClass}>
        <h1 className="font-normal">Current plan</h1>
        <div className="flex items-center">
          <h1 className="font-bold text-xl">{currentPlan}</h1>
          {currentPlan === "trial_expired" && <Badge type={BadgeTypes.Error} text={"Trial Expired"} />}
          {hasTrial && <Badge type={BadgeTypes.Info} text={"Trial"} />}
          {/* <h1 className="">Your trial will expire in 21 Days</h1> */}
        </div>
      </div>
      <div className={wrapperClass}>
        <h1 className="font-normal">Tasks Executed</h1>
        <div className="flex space-x-2 text-xl">
          <h1 className="font-bold">{taskExecutedCount}</h1>
          <h1>of</h1>
          <h1 className="font-bold">{eventsLimit}</h1>
        </div>
      </div>
      <div className={wrapperClass}>
        <div className="font-medium">
          {currentPlan === "trial" ? (
            <>
              <h1>Remaining Trial period</h1>
              <h1 className="font-bold">{trialDays} Days</h1>
            </>
          ) : (
            <>
              <h1>{"Addons"}</h1>
              <h1 className="font-bold">{billing?.addon}</h1>
            </>
          )}
        </div>
      </div>
      <div className={wrapperClass}>
        <div className="font-medium">
          <h1>Current Task Limit</h1>
          <h1 className="font-bold">{eventsLimit}</h1>
        </div>
      </div>

      <div className={wrapperClass}>
        <div className="font-medium">
          <h1>Subscription Cycle</h1>
          <h1 className="font-bold">{billing?.cycle}</h1>
        </div>
      </div>
    </div>
  );
};

export default CurrentPlans;
