import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";


const SingleSelectField: React.FC<Options> = ({ choices, placeholder, pattern, name, required, register, errors }) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  const renderOptions = (): JSX.Element[] => {
    return choices.map((choice) => <option value={choice.value} selected >{choice.label}</option>);
  };
  
  return (
    <div>
      <select
        className={`form-select appearance-none
					block
					w-full
					px-2
					py-1
					text-base
					font-normal
					text-gray-700
					bg-white bg-clip-padding bg-no-repeat
					border border-solid 
					rounded
					transition
					ease-in-out
					m-0
					${errors[name] ? "border-red-600 focus:border-red-600" : "border-gray-300 focus:border-blue-600"}
					focus:text-gray-700 focus:bg-white"
				aria-label="Default select example`}
        {...register(name, getFormConfigs())}
      >
        <option value="">{placeholder || "Choose an option"} </option>
        {renderOptions()}
      </select>
      {/* <ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
			/> */}
    </div>
  );
};

export default SingleSelectField;
