import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import useEventLogs from "api/eventLogsApi";
import Filters from "./components/Filters";
import { getEventLogsCurrentPage, getEventLogsKonnectorName, getEventLogsSortBy } from "state/event-logs/selectors";
import { updateFilter, updateSortBy } from "state/event-logs/slices";
import EventLogs from "./components/EventLogs";
import Spinner from "common/components/Spinner";
import Pagination from "./components/EventLogs/Pagination";
import { useAllKonnector, useKonnector } from "api/konnectorsApi";

const EventLogsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const currentPage = useAppSelector(getEventLogsCurrentPage);
  const sortBy = useAppSelector(getEventLogsSortBy);
  const konnectorName = useAppSelector(getEventLogsKonnectorName);
  const { data: eventLogs, error: eventLogsError } = useEventLogs(currentPage, sortBy, konnectorName);
  const { data: konnectors, error: konnectorError } = useAllKonnector();

  useEffect(() => {
    const params = new URLSearchParams({ sortBy: sortBy, konnectorId: konnectorName });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [sortBy, konnectorName]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sortBy: any = query.get("sortBy") || "dtCreated";
    const filterBy: any = query.get("konnectorId") || "";
    dispatch(updateSortBy(sortBy));
    dispatch(updateFilter(filterBy));
  }, []);
  if (eventLogsError) return <p>Failed to load konnectors</p>;
  if (!eventLogs || !konnectors) return <Spinner />;

  let konnector = { data: { items: konnectors.data } };
  return (
    <div className="h-[calc(100vh-5rem)]">
      <div className="px-6">
        <h2 className="px-4 p-2 font-medium">
          Event executed
          {eventLogs.data.items.length > 0 ? (
            <span className="ml-2 bg-blue-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-md ">
              {eventLogs.data.totalItems} events
            </span>
          ) : (
            <span className="ml-2 bg-blue-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-md ">
              0 events
            </span>
          )}
        </h2>
        {/* <Filters /> */}
      </div>
      <div className=" m-2 px-6">
        <div className=" mt-4  p-1.5 w-[100%] h-[calc(100vh-5rem)]">
          <table className="min-w-full max-w-full divide-y divide-gray-200 border-2 bg-fixed ">
            <thead className=" bg-[#F2F6FF]">
              <tr>
                <th scope="col" className="pl-6 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                  Events
                </th>
                <th scope="col" className=" py-3 pr-32 text-left text-xs font-bold text-gray-500 uppercase ">
                  Apps
                </th>
                <th scope="col" className=" py-3 text-left text-xs font-bold text-gray-500 uppercase ">
                  Event Id
                </th>
                <th scope="col" className=" py-3 text-xs font-bold text-right text-gray-500 uppercase ">
                  Task executed
                </th>
                <th scope="col" className="pl-10 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                  Created
                </th>
                <th scope="col" className="pl-8 py-3  text-xs font-bold text-left text-gray-500 uppercase  ">
                  Status
                </th>
                <th scope="col" className=" py-3  pr-70 text-xs font-bold text-left text-gray-500 uppercase  ">
                  View
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              <EventLogs data={eventLogs.data} konnector={konnector} />
            </tbody>
          </table>
        </div>
      </div>
      <div className="md:mt-14 sm:mt-14 lg:mt-13 sm:p-11 md:p-11 lg:p-6">
        {eventLogs.data.items.length > 0 && <Pagination total={eventLogs.data.totalItems} />}
      </div>
    </div>
  );
};

export default EventLogsPage;
