import React from "react";
import { FieldIcons } from "../Icons/TextBoxIcon";
import { Draggable } from "react-beautiful-dnd";
import FormTitle from "./FormTitle.settings";
import FormLogo from "./FormLogo.settings";
import FormBg from "./FormBg.settings";
import FormSubmitButton from "./FormSubmitButton.settings";
import FormOnSubmit from "./FormOnsubmit.settings";

const RenderSettings: React.FC<any> = ({ field }) => {
  const renderSettings = () => {
    switch (field.type) {
      case "formTitle":
        return <FormTitle field={field} />;
      case "logo":
        return <FormLogo field={field} />;
      case "background":
        return <FormBg field={field} />;
      case "submitButton":
        return <FormSubmitButton field={field} />;
      case "onSubmitHandler":
        return <FormOnSubmit field={field} />;
    }
  };
  return <div className="w-full  flex justify-center">{renderSettings()}</div>;
};

export default RenderSettings;
