import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { ActionMode, ParentProps } from "state/new-konnector/types";
import { useAppDispatch, useAppSelector } from "state/store";

const SelectEvent: React.FC<any> = ({ sourceApp, sourceEvent, apps, konnectorSlice, repeater, hitInputFieldsApi }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const status = useAppSelector((state) => state[konnectorState].data.trigger.outputFields.hasData);
  const dispatch = useAppDispatch();
  const edit = useAppSelector((state) => state.createNewKonnector.data.actions);
  const loadingState = useAppSelector((state) => state.createNewKonnector.isLoading);
  const kon = useAppSelector((state) => state.createNewKonnector?.data?.actions[0]?.connectionName);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  const parentId = currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source;
  const onChangeEventHandler = ({ value: eventId }) => {
    const hitInputFieldsApi = hasInputFields.find((app) => app.id === eventId).inputFields;

    dispatch(
      konnectorSlice.updateTargetEvent({
        eventId,
        id: isRepeaterCurrentActionId,
        hasDynamicInputFields: hitInputFieldsApi,
        parentId,
      })
    );
    if (hitInputFieldsApi) {
      dispatch(konnectorSlice.fetchDynamicInputFieldsLoading({ parentId }));
    }
  };
  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };

  const hasInputFields = getApp().actions.map((action) => {
    return { id: action.id, inputFields: action.dynamicInputFields || false };
  });

  const renderEvents = () => {
    return getApp().actions.map((event) => ({
      label: event.name,
      value: event.id,
      disabled: event.disabled ? event.disabled : false,
    }));
  };
  const { other, appid, accountId, eventid, name } = useParams<any>();

  useEffect(() => {
    if (other && status && edit[0].actionMode !== ActionMode.Edit && edit.length == 1 && kon) {
      dispatch(konnectorSlice.updateTargetEvent({ eventId: eventid, id: isRepeaterCurrentActionId, parentId }));
      if (loadingState) {
        dispatch(konnectorSlice.updateLoadingState(false));
      }
    }
  }, [sourceEvent]);
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Event</label>
      <Select
        options={renderEvents().filter((event) => !event.disabled)}
        value={renderEvents().filter(function (option) {
          return option.value === sourceEvent;
        })}
        onChange={onChangeEventHandler}
      />
    </div>
  );
};

export default SelectEvent;
