import React from "react";
import { FieldIcons } from "../Icons/TextBoxIcon";
import { useAppDispatch, useAppSelector } from "state/store";
import { TrashIcon } from "@heroicons/react/outline";
import { deleteFormField, editField, setShowModal } from "state/form-builder/slices";
import { EditIcon } from "../Icons/EditIcon";
import { Draggable } from "react-beautiful-dnd";
import FieldSettings from "../Modal/RenderFieldModal";
import { ActionMode } from "state/new-konnector/types";
import { DraggableIcon } from "../Icons/DragableIcon";

const FormFields: React.FC<any> = ({ field, index }) => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.formBuilder.showModal);
  const closeModal = () => {
    dispatch(setShowModal({ modal: false, id: field.fieldId }));
  };
  return (
    <Draggable draggableId={field.fieldId} index={index} key={field.fieldId} isDragDisabled={showModal}>
      {(provided, snapshot) => (
        <div
          className={`flex flex-col ${
            (field.actionMode === ActionMode.Edit || field.actionMode === ActionMode.Create) &&
            "z-50 bg-white shadow-md"
          } h-auto rounded text-lg text-gray-500 border-2 border-gray-300 relative
             transition-all`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={`flex space-x-2 w-full p-2 justify-between ${
              (field.actionMode === ActionMode.Edit || field.actionMode === ActionMode.Create) && "z-50"
            }`}
          >
            <div className="flex space-x-1 items-center">
              <DraggableIcon />
              <FieldIcons type={field.inputType} />
              <div>{field.label || field.inputType}</div>
            </div>
            <div>
              {field.actionMode === ActionMode.View ? (
                <button
                  className="cursor-pointer ml-3"
                  onClick={() => {
                    dispatch(editField(field.fieldId));
                  }}
                >
                  <EditIcon />
                </button>
              ) : (
                <button
                  type="button"
                  className="cursor-pointer "
                  data-modal-toggle="account-modal"
                  onClick={() => closeModal()}
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}

              <button className="cursor-pointer ml-3">
                <TrashIcon className="h-5 w-5" onClick={() => dispatch(deleteFormField(field.fieldId))} />
              </button>
            </div>
          </div>
          {field.actionMode !== ActionMode.View && (
            <FieldSettings field={field} showModal={showModal} closeModalHandler={closeModal} />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default FormFields;
