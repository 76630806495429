import React from "react";

const Modal: React.FC<any> = ({ children, visible, closeModalHandler, disabled }) => {
  return (
    <>
      {
        <>
          <div
            id="account-modal"
            aria-hidden="true"
            className={`flex fixed z-50 ${
              visible ? "w-full transition-all ease-in duration-500" : "w-0 "
            } justify-end  items-center h-full top-0 right-0`}
          >
            <div className="relative w-full max-w-2xl flex flex-col h-screen py-4 bg-gray-50 ">
              {/* Modal content */}
              <div className="fixed flex justify-between w-full max-w-2xl px-4 bg-gray-50 z-50 border-b-[1px]">
                <h1 className="text-gray-600 py-2 px-10 font-semibold w-full   text-2xl">Checkout</h1>

                <button
                  type="button"
                  disabled={disabled}
                  className="text-gray-600 disabled:cursor-not-allowed bg-transparent rounded-sm text-sm p-1.5 ml-auto inline-flex items-center"
                  data-modal-toggle="account-modal"
                  onClick={() => closeModalHandler()}
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              {visible && children}
            </div>
          </div>
          {visible && <div className={`opacity-25 fixed inset-0 z-40 bg-black`}></div>}
        </>
      }
    </>
  );
};

export default Modal;
