import React from "react";
import { useAppSelector } from "state/store";
import FormFieldLabel from "../Components/FormFieldLabel";
import { fieldList } from "../constants/fieldList";
import { Droppable } from "react-beautiful-dnd";
import RenderFields from "../Components/FormFieldItems";
import { DragDropIcon } from "../Icons/DragDropIcon";
import { ActionMode } from "state/edit-konnector/types";
import { Tab } from "@headlessui/react";
import { formSettings } from "../constants/formSettings";
import { FieldIcons } from "../Icons/TextBoxIcon";
import { SettingsIcon } from "../Icons/SettingsIcon";
import RenderSettings from "./RenderSettings";

const FormSettings: React.FC<any> = () => {
  const formField = useAppSelector((state) => state.formBuilder.formFields);
  const actionMode = formField.find(
    (field) => field.actionMode === ActionMode.Create || field.actionMode === ActionMode.Edit
  );
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <>
      <div className={`flex w-full bg-gray-50 h-full justify-center `}>
        <Tab.Group>
          <div className="flex-col w-1/4 h-full space-y-2 bg-white p-1 flex justify-center">
            <div className="flex flex-col justify-start space-y-3 h-full">
              <div className="text-xl p-3 text-center font-semibold border-b-2 text-gray-500">Form Builder</div>

              <Tab.List className="flex flex-col space-y-3 h-full justify-start items-start p-2">
                {formSettings.map((opt, index) => {
                  return (
                    <>
                      <Tab
                        key={opt.type}
                        className={({ selected }) =>
                          classNames(
                            "text-lg w-full rounded text-left flex items-center p-1 space-x-2 ",
                            selected ? "bg-gray-400 text-white" : "text-gray-600"
                          )
                        }
                      >
                        <div className="bg-blue-100 p-2 rounded">
                          <SettingsIcon type={opt.type} />
                        </div>
                        <div>{`${opt.label}`}</div>
                      </Tab>
                    </>
                  );
                })}
              </Tab.List>
            </div>
          </div>

          <div className={"flex flex-col w-3/4 h-11/12 bg-gray-50 items-center overflow-y-scroll"}>
            <div className="flex flex-col  p-4 w-full space-y-2 justify-center  mt-10 items-center h-full transition-all">
              {formSettings.map((opt, index) => {
                return (
                  <>
                    <Tab.Panel key={opt.type} className={classNames("h-full w-full flex justify-center")}>
                      <RenderSettings field={opt} />
                    </Tab.Panel>
                  </>
                );
              })}
            </div>
          </div>
        </Tab.Group>
      </div>
    </>
  );
};

export default FormSettings;
