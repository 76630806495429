import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";

const TextField: React.FC<Options> = ({
  type,
  pattern,
  label,
  name,
  required,
  register,
  placeholder,
  errors,
  isDisabled = false,
}) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };
  return (
    <div>
      {required ? (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label ? label : name} <span className="text-red-500 ">*</span>
        </label>
      ) : (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label ? label : name}
        </label>
      )}
      <input
        type={type}
        disabled={isDisabled}
        className="bg-gray-50 border border-gray-300 text-gray-800 text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        placeholder={placeholder ? `${placeholder}` : `Enter ${name}`}
        {...register(name, getFormConfigs())}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      />
    </div>
  );
};

export default TextField;
