import useSWR from "swr";

import { fetcher, axiosInstance, options } from "./libs/fetch";

const PAGE_LIMIT = 5;

export default function useKonnectors(page, sortOrder = "", filterBy, limit = PAGE_LIMIT) {
  const queryValue = sortOrder.split("-")[0] || "";
  const sortId = sortOrder.split("-")[1] || "";
  return useSWR(
    `/konnectors?pageNumber=${page}&pageSize=${limit}${
      filterBy !== "active" ? "&sortValue=" + queryValue + "&sortOrder=" + sortId : ""
    }${
      filterBy === "active"
        ? "&filterBy=status"
        : filterBy === "freshdesk"
        ? "&filterBy=sourceTemplate.freshdesk"
        : filterBy === "stripe"
        ? "&filterBy=sourceTemplate.stripe"
        : ""
    }`,
    fetcher
  );
}
export function useKonnectorTrialPlan() {
  return useSWR(`/konnectors`, fetcher);
}
export function useKonnector(id) {
  return useSWR(`/konnectors/${id}`, fetcher);
}
export function useAllKonnector() {
  return useSWR(`/konnectors/all`, fetcher);
}

export function saveKonnectorApi(payload) {
  return axiosInstance.post(`/konnectors`, payload, options);
}
export function updateKonnectorApi(konnectorId, payload) {
  return axiosInstance.put(`/konnectors/${konnectorId}`, payload, options);
}
export function getKonnectorApi(konnectorId) {
  return axiosInstance.get(`/konnectors/${konnectorId}`, options);
}
export function activateKonnectorApi(konnectorId) {
  return axiosInstance.post(`/konnectors/activate/${konnectorId}`, options);
}
export function deactivateKonnectorApi(konnectorId) {
  return axiosInstance.post(`/konnectors/deactivate/${konnectorId}`, options);
}
export function deleteKonnectorApi(konnectorId) {
  return axiosInstance.delete(`/konnectors/${konnectorId}`, options);
}

export function bulckDeleteKonnectorApi(selectedKonnectorId, store) {
  return axiosInstance.post(`/konnectors/bulk_actions?store=${store}`, { selectedKonnectorId }, options);
}

export function generateWebhookUrl(tenantId, payload) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };

  return axiosInstance.post(`/konnectors/generate_webhook`, payload, requestOptions);
}

export function getWebhookData(webhookId, konnectorType, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  console.log(konnectorType);

  return axiosInstance.get(`/konnectors/webhook/${webhookId}?konnectorType=${konnectorType}`, requestOptions);
}

export function getWebhookFromTemplate(webhookId, konnectorType, tenantId, eventId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };

  return axiosInstance.get(
    `/konnectors/webhook/${webhookId}/${eventId}?konnectorType=${konnectorType}`,
    requestOptions
  );
}

export function generateFormSampleDataApi(webhookId, tenantId, payload) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.post(`/konnectors/webhook/form_data/${webhookId}`, payload, requestOptions);
}

export function resetEditFormData(tenantId, payload) {
  console.log("reset Edit form data");
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.post(`/konnectors/reset_form`, payload, requestOptions);
}

export function resetWebhookDataApi(webhookId, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.post(`/konnectors/webhook/reset/${webhookId}`, requestOptions);
}

export function deleteWebhookDataApi(webhookId, konnectorType, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return axiosInstance.delete(`/konnectors/webhook/${webhookId}?konnector_type=${konnectorType}`, requestOptions);
}
