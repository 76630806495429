import React, { useEffect, useState } from "react";
import Spinner from "common/components/Spinner";
import { useAppAccounts } from "api/accountsApi";
import { useParams } from "react-router-dom";
import AccountList from "./AccountList";
import { useApp } from "api/appsApi";
import { useAppDispatch, useAppSelector } from "state/store";
import UpdateAccount from "common/containers/Account/UpdateAccount";
import CreateAccount from "common/containers/Account/CreateAccount";
import { setAccountModal } from "state/account/slices";
import { StatusType } from "state/new-konnector/types";
import { mutate } from "swr";

const AppAccounts: React.FC = () => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.account.showModal);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const showUpdateModal = useAppSelector((state) => state.account.showUpdateModal);
  const activeAccount = useAppSelector((state) => state.account.activeAccount);
  const { id: appId } = useParams<any>();
  const [allPageAccounts, setAccounts] = useState([]);

  const [testdata, settestdata] = useState<any>();
  console.log(testdata);
  console.log(allPageAccounts);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: accounts, error: accountsError } = useAppAccounts(appId, pageNumber);
  const { data: app, error: appError } = useApp(appId);
  const appAccountState = useAppSelector((state) => state.account.data);
  const apiStatus = useAppSelector((state) => state.account.status);
  const connectionId = useAppSelector((state) => state.account.data.id);

  useEffect(() => {
    if (accounts) {
      setAccounts((prevState) => {
        // if (prevState.length) {
        //   return [...prevState, ...accounts.data.items];
        // }
        return accounts.data.items;
      });
    }
  }, [accounts]);

  useEffect(() => {
    if (apiStatus === StatusType.success && connectionId) {
      var accountList = [...accounts.data.items, appAccountState];
      var newAccountList = [];
      var tempIdArray = [];
      for (let i = 0; i < accountList.length; i++) {
        if (tempIdArray.includes(accountList[i].id)) {
          continue;
        }
        newAccountList.push(accountList[i]);
        tempIdArray.push(accountList[i].id);
      }
      setAccounts(newAccountList);

      return accounts.data.items;
    }
  }, [appAccountState, connectionId]);
  if (accountsError || appError) return <p>Failed to load apps</p>;
  if (!accounts || !app) return <Spinner />;

  return !allPageAccounts.length ? (
    <>
      {showModal && <CreateAccount appDetails={app.data} />}
      <div className="flex w-full h-[100vh] justify-center items-center flex-col">
        <p className="mb-3">You haven't created accounts yet!</p>
        <button
          onClick={() => dispatch(setAccountModal(true))}
          className="text-white  bg-primary-color hover:bg-[#1f324e] font-medium rounded-sm text-sm px-5 py-2 text-center mr-2 mb-6 hover:transition-all"
        >
          Create New Account
        </button>
        <div className="text-center"></div>
      </div>
    </>
  ) : (
    <>
      {showUpdateModal && activeAccount && <UpdateAccount appDetails={app} accountDetails={activeAccount} />}
      <div className="p-5 w-full h-[calc(100vh-5rem)]">
        <AccountList accounts={allPageAccounts} pageNumber={pageNumber} setPageNumber={setPageNumber} app={app.data} />
      </div>
    </>
  );
};

export default AppAccounts;
