import React from "react";
import FieldValueType from "../../FieldValueType";
import SourceFields from "../../SourceFields";
import { TargetInputField } from "pages/KonnectorPage/types";
import SourceTypes from "../../SourceTypes";

interface FieldItemProps {
  field: TargetInputField;
  register: any;
  errors: object;
  watch: any;
  setInputValue?: any;
  control: any;
}

const FieldItem: React.FC<FieldItemProps> = ({
  field,
  control,
  register,
  watch,
  errors,
  setInputValue,
}): JSX.Element => {
  return (
    <div className="flex space-x-2 mb-3 w-full justify-between items-center">
      <FieldValueType field={field} register={register} errors={errors} />
      <SourceTypes
        field={field}
        control={control}
        watch={watch}
        register={register}
        setInputValue={setInputValue}
        errors={errors}
      />
      <SourceFields
        field={field}
        setInputValue={setInputValue}
        control={control}
        watch={watch}
        register={register}
        errors={errors}
      />
    </div>
  );
};

export default FieldItem;
