import { KonnectorType } from "pages/KonnectorList/types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "state/store";
import * as Icons from "../icons";

const resources = {
  konnectors: "konnectors",
  apps: "apps",
  eventLogs: "event-logs",
  billing: "payments",
};

const SidebarWithIcons = () => {
  const isFormGenerated = useAppSelector((state) => state.editKonnector.isFormGenerated);

  const [active, setActive] = useState(resources.konnectors);
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const konnectorType = useAppSelector((state) => state.editKonnector.data.konnectorType);
  const hasConfigurationSaved = useAppSelector((state) => state.editKonnector.hasConfigurationSaved);
  const history = useHistory();
  const activeClasses = "flex items-center justify-center w-12 h-12 mt-2 text-gray-200 bg-[#405472] rounded";
  useEffect(() => {
    setActive(history.location.pathname.split("/")[1]);
  }, []);
  return (
    <nav className="flex flex-col items-center w-16 min-h-screen overflow-hidden text-gray-400 bg-primary-color rounded-sm fixed z-30">
      <div className="relative z-20 flex items-center group">
        <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
            Konnectify
          </span>
        </div>
        <Link to={"/konnectors"} className="flex items-center justify-center mt-3">
          <Icons.Logo />
        </Link>
      </div>
      {konnectorType === KonnectorType.Form && !hasConfigurationSaved && isFormGenerated ? (
        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Konnectors
              </span>
            </div>
            <div
              className={
                active === resources.konnectors
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Konnectors />
            </div>
          </div>
          <div className="relative z-20 flex items-center hover:transition-all group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Apps
              </span>
            </div>
            <div
              className={
                active === resources.apps
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Apps />
            </div>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Event Logs
              </span>
            </div>
            <div
              className={
                active === resources.eventLogs
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.EventLogs />
            </div>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Billing
              </span>
            </div>
            <div
              className={
                active === resources.billing
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Billing />
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16  items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none  text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Konnectors
              </span>
            </div>
            <Link
              to={"/konnectors"}
              className={
                active === resources.konnectors
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Konnectors />
            </Link>
          </div>
          <div className="relative z-20 flex items-center hover:transition-all group">
            <div className="fixed left-16  items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Apps
              </span>
            </div>
            <Link
              to={"/apps"}
              className={
                active === resources.apps
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Apps />
            </Link>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Event Logs
              </span>
            </div>
            <Link
              to={"/event-logs"}
              className={
                active === resources.eventLogs
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.EventLogs />
            </Link>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-primary-color shadow-lg">
                Billing
              </span>
            </div>
            <Link
              to={"/payments"}
              className={
                active === resources.billing
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Billing />
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default SidebarWithIcons;
