import { StatusType } from "state/auth/types";
import { KonnectorAppState, AppRole, InitialState } from "./types";
export const getTriggerDetails = (state): KonnectorAppState<AppRole.Source> => state.editWorkFlow.data.trigger;
export const getTargetDetails = (state): KonnectorAppState<AppRole.Target> => {
  const findActionIndex = state.editWorkFlow.data.actions.findIndex(
    (action) => action.id === state.editWorkFlow.currentActionId
  );
  return state.editWorkFlow.data.actions[findActionIndex];
};

export const getLookupDetails = (state, lookupId): KonnectorAppState<AppRole.Lookup> => {
  return state.editWorkFlow.data.lookups.find((lookup) => lookup.id == lookupId);
};

export const getEditKonnectorDetails = (state): InitialState => state.editWorkFlow;
export const getEditKonnectorStatus = (state): StatusType => state.editWorkFlow.status;
