import React, { useEffect } from "react";
import MultipleSelectField from "common/components/FormFields/MultipleSelect";
import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import { Choice } from "common/components/FormFields/types";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";
import { getSourceFields } from "utils/konnectorStateUtils";
export default function SourceFields({ field, register, control, watch, errors, setInputValue, selectedEvent }) {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const sourceFields: any = useAppSelector(getSourceFields);
  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);

  const getChoices = (): Choice[] => {
    const findSourceFields = sourceFields.find((item) => item.sourceId === fieldSourceId);
    if (!findSourceFields) {
      setInputValue(field.propName + "__field_source_type", "");
      return [{ label: "", value: "" }];
    }
    return findSourceFields
      ? flatSourceOutputFields(findSourceFields.eventResponse.data)
          .filter((field) => field.propType !== "object" && field.propType !== "array")
          .map((field) => ({ label: field.label || field.propName, value: field.propName }))
      : [];
  };

  const getFixedChoices = (): Choice[] => {
    return field.propValue.map((ele) => ({ label: ele.label, value: ele.value }));
  };
  const getFieldOptions = () => {
    return new Field(field).format();
  };

  useEffect(() => {
    if (fieldType === "fixed" && fieldSourceId) {
      setInputValue(field.propName, "");
      setInputValue(field.propName + "__field_source_type", "");
    }
  }, [fieldType]);

  useEffect(() => {
    if (selectedEvent) {
      setInputValue(field.propName, "");
      setInputValue(field.propName + "__field_source_type", "");
    }
  }, [selectedEvent]);
  return (
    <div className="flex justify-center w-[30%] self-end">
      <div className="mb-3 w-full">
        {fieldType === "fixed" ? (
          field.propValue ? (
            <MultipleSelectField
              choices={getFixedChoices()}
              control={control}
              {...getFieldOptions()}
              register={register}
              errors={errors}
            />
          ) : (
            <TextField {...getFieldOptions()} register={register} errors={errors} />
          )
        ) : (
          fieldSourceId && (
            <MultipleSelectField
              choices={getChoices()}
              control={control}
              {...getFieldOptions()}
              register={register}
              errors={errors}
            />
          )
        )}
      </div>
    </div>
  );
}
