import React, { useEffect } from "react";
import { getEditKonnectorStatus } from "state/edit-konnector/selectors";
import { getKonnectorsDeleted, getKonnectorsSortBy } from "state/konnectors/selectors";
import { updateActiveSortBy, updateSortBy } from "state/workFlows/slices";
import {
  getworkflowsApiStatus,
  getworkflowsDeleted,
  getworkflowsFilterType,
  getworkflowsSortBy,
} from "state/workFlows/selectors";

import { useAppDispatch, useAppSelector } from "state/store";
import { FilterType } from "state/workFlows/types";

const SortBy: React.FC = () => {
  const sortBy = useAppSelector(getworkflowsSortBy);
  const activeSortBy = useAppSelector((state) => state.workflows.activeSortBy);
  const filterBy = useAppSelector(getworkflowsFilterType);
  const dispatch = useAppDispatch();
  const editKonnectorState = useAppSelector(getworkflowsApiStatus);
  const hasDeleted = useAppSelector(getworkflowsDeleted);
  const onChangeHandler = (e) => {
    const value = e.target.value;
    dispatch(updateSortBy(value));
  };
  const onChangeHandlerSortBy = (e) => {
    const value = e.target.value;
    dispatch(updateActiveSortBy(value));
  };

  // useEffect(() => {
  //   if (editKonnectorState === "success") {
  //     const value: any = "dtCreated-desc";
  //     dispatch(updateSortBy(value));
  //   }
  // }, [editKonnectorState]);
  return (
    <div className="ml-auto my-4">
      {filterBy == FilterType.ACTIVE ? (
        ""

      ) : (
        <select
          className="px-3 cursor-pointer text-sm rounded-[20px] shadow-md py-2"
          onChange={onChangeHandler}
          value={sortBy}
        >
          <option value="" disabled>
            Select an option
          </option>
          <>
            <optgroup label="Status">
              <option value="status-asc">Sortby: Enabled</option>
              <option value="status-desc">Sortby: Disabled</option>
            </optgroup>
            <optgroup label="Created">
              <option value="dtCreated-desc">Sortby: Latest</option>
              <option value="dtCreated-asc">Sortby: Oldest</option>
            </optgroup>
          </>
        </select>
      )}
    </div>
  );
};

export default SortBy;
