import React from "react";
import { Field } from "utils/appsUtils";
import SingleSelectField from "../../../SingleSelect";
import { Choices } from "../../../types";

const DataType: React.FC<any> = ({ register, watchField, errors, index,id }) => {
  const field = { propName: "Parse_condition_Operator" + index, propType: "string", isRequired: true };
  const fieldValue = watchField(`${id}___field_data_type`);
  const propType = fieldValue;
  const getChoices = (propType) => {
    switch (propType) {
      case "string": {
        return Choices.string;
      }
      case "number": {
        return Choices.numeric;
      }
      default: {
        return Choices.default;
      }
    }
  };

  const getFieldOptions = () => {
    return new Field(field).format();
  };

  return (
    <>
      <div className=" flex w-[40%]">
        <SingleSelectField
          placeholder="Choose operator type"
          choices={getChoices(propType)}
          {...getFieldOptions()}
          name={`${id}___field_operator`}
          register={register}
          errors={errors}
        />
      </div>
    </>
  );
};

export default DataType;
