import Badge from "common/components/Badge";
import { BadgeTypes } from "common/components/Badge/types";
import { FormIcon, WebhookIcon } from "common/components/Icons";
import { KonnectorType } from "interfaces/pages/create-new-konnector";
import moment from "moment";
import { Item } from "pages/EventLogs/types";
import React from "react";
import { Link } from "react-router-dom";

interface EventItemProps {
  eventLog: Item;
  konnectorData: any;
}
const MAX_LENGTH = 20;

const EventItem: React.FC<EventItemProps> = ({ eventLog, konnectorData }) => {
  const getHumanReadableTime = (datetime) => moment(datetime).fromNow();
  const iconUrl = konnectorData && konnectorData.konnectorApps.map((konnector) => konnector.iconUrl);
  const konnectorType = konnectorData && konnectorData.konnectorType;

  const renderAppIcons = () => {
    return iconUrl ? (
      iconUrl.map((app, index) => {
        return app ? (
          <img className=" p-1 rounded-md ring-1 ring-gray-100" src={app} key={index} width="30" height="35"></img>
          // <img className=" ml-2 mr-2" src={app} key={index} alt="" width="30" height="35" />
        ) : (
          <>
            {konnectorType !== KonnectorType.Form ? <WebhookIcon classNameOp={"h-8 w-8 rounded-md"} /> : <FormIcon />}
          </>
        );
      })
    ) : (
      <Badge type={BadgeTypes.Error} text={"deleted"} />
    );
  };
  return (
    <><tr className="border-b ">
      <th scope="row" className="px-4 py-3 font-medium text-gray-900 whitespace-nowrap"> {eventLog.trigger.konnectorName.length > MAX_LENGTH ? (
        <Link
          to={"/admin/event-logs/" + eventLog.id}
          className="hover:text-blue-400 "
        >{`${eventLog.trigger.konnectorName.substring(0, MAX_LENGTH)}...`}</Link>
      ) : (
        <Link to={"/admin/event-logs/" + eventLog.id} className="hover:text-blue-400 w-1/2">
          {eventLog.trigger.konnectorName}
        </Link>
      )}</th>
      <td className="px-4 py-3 ml-2"><div className="flex -ml-2 space-x-2 w-1/2">{renderAppIcons()}</div></td>
      <td className="px-4 py-3">{eventLog.userId}</td>
      <td className="px-4 py-3">Created {getHumanReadableTime(eventLog.dtUpdated)}</td>
      <td className="px-4 py-3"> {eventLog.taskExecuted ? (
        <>

          {eventLog.taskExecuted}

        </>
      ) : (
        <>
          0
        </>
      )}</td>

      <td className="px-4 py-3">
        {eventLog.status !== BadgeTypes.Data_not_found ? (
          <Badge type={eventLog.status} text={eventLog.status} />
        ) : (
          <Badge type={BadgeTypes.Info} text={"Data not found"} />
        )}
      </td>
      {/* <td className="px-4 py-3 flex items-center justify-end">
        <button id="apple-imac-27-dropdown-button" data-dropdown-toggle="apple-imac-27-dropdown" className="inline-flex items-center p-0.5 text-sm font-medium text-center text-gray-500 hover:text-gray-800 rounded-lg focus:outline-none dark:text-gray-400 dark:hover:text-gray-100" type="button">
          <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
          </svg>
        </button>
      
      </td> */}
    </tr></>
    // <tr className="mb-2">
    //   <td className="px-6 pr-56 py-4 text-sm font-medium  text-gray-800 whitespace-nowrap">
    //     <div className="flex">
    //       {eventLog.trigger.konnectorName.length > MAX_LENGTH ? (
    //         <Link
    //           to={"/admin/event-logs/" + eventLog.id}
    //           className="hover:text-blue-400 "
    //         >{`${eventLog.trigger.konnectorName.substring(0, MAX_LENGTH)}...`}</Link>
    //       ) : (
    //         <Link to={"/admin/event-logs/" + eventLog.id} className="hover:text-blue-400 w-1/2">
    //           {eventLog.trigger.konnectorName}
    //         </Link>
    //       )}
    //     </div>
    //   </td>
    //   <td className=" py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
    //     <div className="flex -ml-2 space-x-2 w-1/2">{renderAppIcons()}</div>
    //   </td>

    //   {/* <td className=" py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{eventLog.id}</td> */}
    //   <td className=" py-4   text-sm font-medium text-gray-800 whitespace-nowrap">{eventLog.userId}</td>
    //   <td className="px-4 py-4 text-sm  text-gray-800 whitespace-nowrap text-right">
    //     {eventLog.taskExecuted ? (
    //       <>
    //         <span className="ml-2  text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 ">
    //           {eventLog.taskExecuted}
    //         </span>
    //       </>
    //     ) : (
    //       <>
    //         <span className="ml-2  text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-sm ">0</span>
    //       </>
    //     )}
    //   </td>
    //   <td className="px-6 py-4 pl-10 text-sm text-left text-gray-800 whitespace-nowrap">
    //     Created {getHumanReadableTime(eventLog.dtUpdated)}
    //   </td>
    //   <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
    //     {eventLog.status !== BadgeTypes.Data_not_found ? (
    //       <Badge type={eventLog.status} text={eventLog.status} />
    //     ) : (
    //       <Badge type={BadgeTypes.Info} text={"Data not found"} />
    //     )}
    //   </td>
    //   <td className="px-2 py-4 text-sm text-gray-800 whitespace-nowrap">
    //     <a href={"/admin/event-logs/" + eventLog.id}>
    //       <svg
    //         xmlns="http://www.w3.org/2000/svg"
    //         className="h-5 w-5 opacity-100 cursor-pointer"
    //         fill="none"
    //         viewBox="0 0 24 24"
    //         stroke="black"
    //         strokeWidth={2}
    //       >
    //         <>
    //           <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
    //           />
    //         </>
    //         {/* ) : (
    //           <path
    //             strokeLinecap="round"
    //             strokeLinejoin="round"
    //             d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
    //           />
    //         )} */}
    //       </svg>
    //     </a>
    //   </td>
    // </tr>
  );
};

export default EventItem;
