import { KonnectorType } from "pages/KonnectorList/types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PrimarySidebar from "./PrimarySidebar";
import SecondarySidebar from "./SecondarySidebar";
import { useAppSelector } from "state/store";
import * as Icons from "common/containers/Layout/components/icons";

const resources = {
  konnectors: "konnectors",
  apps: "apps",
  eventLogs: "event-logs",
  billing: "payments",
  embeddedApps: "KompApps",
  adminSettings: "admin",
};

const sidebar = (type) => {
  let className,
    shrinkWidth = false;
  switch (type.type.name) {
    case "Marketplace settings":
    case "Admin Dashboard":
    case "Theme Editor":
      className = "flex w-16 fixed z-30 h-full";
      shrinkWidth = true;
      break;
    case "Events Summary":
    case "Event logs":
      className = "flex w-16 fixed z-30 h-full";
      shrinkWidth = true;
      break;
    default:
      className = "flex w-80 fixed z-30 h-full";
      break;
  }
  return (
    <>
      <div className={className}>
        <PrimarySidebar shrinkWidth={shrinkWidth} />
        <SecondarySidebar type={type.type.name} />
      </div>
    </>
  );
};

export default sidebar;
