import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
    setPrimaryBackground,
} from "state/Embedded-markteplace/AdminSettings/slices";
import "../style.css"
import { useAppDispatch, useAppSelector } from "state/store";
import FontStyles from "../Font-Styles";
import ReactQuill from "react-quill";
import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import { setCustomization } from "state/Embedded-markteplace/ThemeSettings/slices";

const StyleTab: React.FC<any> = () => {
    const [loadingStatus, setLoadingStatus] = useState("loading");
    const currentUser = useAppSelector((state) => state.auth.currentUser);
    const dispatch = useAppDispatch();
    const adminSettings = useAppSelector((state) => state.adminSettings);
    const customization = useAppSelector((state) => state.themeSettings.customization)
    const [value, setValues] = useState(2);
    const [states, setstates] = useState(false);

    const [description, setdescription] = useState(false);
    useEffect(() => {
        const titlevalues = adminSettings.portalTile;
        function isHTMLStringEmpty(htmlString) {
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlString;

            return tempElement.innerText.trim() === "";
        }
        setstates(isHTMLStringEmpty(titlevalues));
    }, [adminSettings.portalTile]);

    useEffect(() => {
        const descriptionvalues = adminSettings.portalDescription;
        function isHTMLStringEmpty(htmlString) {
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlString;

            return tempElement.innerText.trim() === "";
        }
        setdescription(isHTMLStringEmpty(descriptionvalues));
    }, [adminSettings.portalDescription]);

    const url = `<iframe src=https://enduser.konnectify.io/apps?apiKey=${currentUser.tenantId}&user={add enduser email}></iframe>`;
    const [state, setstate] = useState(false);
    useEffect(() => {
        if (state) {
            dispatch(setPrimaryBackground(adminSettings.primaryBackground));
        }
    }, [state]);


    const onChangeCustomization = (e, type) => {
        const value = type == "title" || type == "description" ? e : e.target.value
        dispatch(setCustomization({ type: type, value: value }));
    };
    return (
        <>
            {/* {adminSettings.tabModalStatus && <ResetModal></ResetModal>} */}
            <div className="relative w-[90%] h-2/3 top-32 ml-14 overflow-scroll">
                <div className="flex justify-items-center align-items-center w-full p-4 mb-10">
                    <div className="flex w-[90%] text-sm  flex-col space-y-3 pl-8">
                        <div className="border rounded-md shadow-sm p-6 w-[70%] border-slate-300">
                            <div className="flex space-x-3">
                                <span className="mb-4 text-md font-extrabold"> Portal title</span>
                                <Tooltip text="Maximum 100 characters">
                                    <button className="cursor-pointer ">
                                        <svg width="12" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.47933 10.712 5.288C10.52 5.09667 10.2827 5.00067 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="black" />
                                        </svg>

                                    </button>
                                </Tooltip>
                            </div>
                            <ReactQuill
                                placeholder={"Enter the portal title"}
                                className="Title mb-5"
                                // value={adminSettings.portalTile}
                                // onChange={handletitlechange}
                                value={customization?.title}
                                onChange={(e) => {
                                    onChangeCustomization(e, "title")
                                }}
                                theme="snow" // you can change the theme (snow, bubble)
                                modules={{
                                    toolbar: [
                                        ["bold", "italic", "underline", "strike"], // toggled buttons
                                        ["blockquote", "code-block"],
                                        [{ list: "ordered" }, { list: "bullet" }],
                                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                        [{ font: [] }], // font family dropdown
                                        [{ color: [] }],
                                        [{ align: [] }],
                                        [{ background: [] }],
                                        ["clean"], // remove formatting button
                                    ],
                                }}
                            />
                            <div className="flex space-x-3">
                                <h2 className="mb-4 text-md font-extrabold">Portal description</h2>
                                <Tooltip text="Maximum 300 characters">
                                    <button className="cursor-pointer ">
                                        <svg width="12" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.47933 10.712 5.288C10.52 5.09667 10.2827 5.00067 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="black" />
                                        </svg>

                                    </button>
                                </Tooltip>
                            </div>
                            <ReactQuill
                                placeholder={"Enter the Portal description"}
                                className="mb-8 h-28"
                                value={customization?.description}
                                // onChange={handledescriptionchange}
                                onChange={(e) => {
                                    onChangeCustomization(e, "description")
                                }}
                                theme="snow" // you can change the theme (snow, bubble)
                                // bounds={".app"}
                                modules={{
                                    toolbar: [
                                        ["bold", "italic", "underline", "strike"], // toggled buttons
                                        ["blockquote", "code-block"],
                                        [{ list: "ordered" }, { list: "bullet" }],
                                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                        [{ font: [] }], // font family dropdown
                                        [{ color: [] }],
                                        [{ align: [] }],
                                        [{ background: [] }],
                                        ["clean"], // remove formatting button
                                    ],
                                }}
                            />
                        </div>
                        {/* <div className="border rounded-xl shadow-sm p-6 w-[70%] border-slate-300 h-[20%]	">
                        <h2 className="ml-2 mb-4 text-sm font-semibold">Portal description</h2>
                        <ReactQuill
                            placeholder={"Enter the Portal description"}
                            className="  mb-5"
                            value={adminSettings.portalDescription}
                            onChange={handledescriptionchange}
                            theme="snow" // you can change the theme (snow, bubble)
                            modules={{
                                toolbar: [
                                    ["bold", "italic", "underline", "strike"], // toggled buttons
                                    ["blockquote", "code-block"],
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [{ font: [] }], // font family dropdown
                                    [{ color: [] }],
                                    [{ align: [] }],
                                    [{ background: [] }],
                                    ["clean"], // remove formatting button
                                ],
                            }}
                        />
                    </div> */}
                        <div className="border rounded-md shadow-sm p-6 w-[70%] border-slate-300">
                            <div className="flex space-x-3">
                                <label className="block  ml-2 mb-4 text-md font-extrabold">Portal color and font</label>
                                <Tooltip text=" Primary color will be applied to background and button elements.">
                                    <button className="cursor-pointer ">
                                        <svg width="12" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.47933 10.712 5.288C10.52 5.09667 10.2827 5.00067 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="black" />
                                        </svg>

                                    </button>
                                </Tooltip>
                            </div>
                            <div className="grid grid-cols-3 w-[98%] p-2 cursor-pointer">
                                <div className="grid">
                                    <label htmlFor={""} className=" mr-2 mt-2 mb-2 text-sm font-medium text-gray-500">
                                        Primary Background color
                                    </label>
                                    <div className="flex rounded-full">
                                        <span className=" flex rounded-md w-52 border border-gray-300 ">
                                            <input
                                                type="text"
                                                className=" mx-5 w-[50%] bg-transparent divide-x text-black"
                                                value={customization?.primary_colour}
                                            // value={adminSettings.primaryBackground}
                                            // {...register("primary_bgColor_text")}
                                            />
                                            <input
                                                type="color"
                                                className="mt-2 mb-2 ml-4 border w-[40%] bg-transparent mr-2 "
                                                onChange={(e) => {
                                                    onChangeCustomization(e, "primary_colour")
                                                }}
                                                value={customization?.primary_colour}
                                            // value={adminSettings.primaryBackground}
                                            // {...register("primary_bgColor")}
                                            // onChange={onChangeFormColor}
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <label htmlFor={""} className=" mr-2 mt-2 mb-2 text-sm font-medium text-gray-500">
                                        Primary button color
                                    </label>
                                    <div className="flex rounded-full">
                                        <span className=" flex rounded-md w-52 border border-gray-300 ">
                                            <input
                                                type="text"
                                                className="  mx-5 w-[50%] bg-transparent divide-x text-black"
                                                value={customization?.primary_button_colour}
                                            // value={adminSettings.primaryButtonColor}
                                            // {...register("primary_button_Text")}
                                            // onChange={onChangePrimaryButtonColor}
                                            />
                                            <input
                                                type="color"
                                                className="mt-2 mb-2 ml-4 border w-[40%] bg-transparent mr-2 "
                                                onChange={(e) => {
                                                    onChangeCustomization(e, "primary_button_colour")
                                                }}
                                                value={customization?.primary_button_colour}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="grid">
                                    <label htmlFor={""} className="  mr-2 mt-2 mb-2 text-sm font-medium text-gray-500">
                                        {" "}
                                        Secondary button color
                                    </label>
                                    <div className="flex rounded-full">
                                        <span className=" flex rounded-md w-52 border border-gray-300 ">
                                            <input
                                                type="text"
                                                className=" mx-5 w-[50%] bg-transparent text-black"
                                                value={customization?.secondary_button_colour}
                                            />
                                            <input
                                                type="color"
                                                className="mt-2 mb-2 ml-4 border w-[40%] bg-transparent mr-2  "
                                                onChange={(e) => {
                                                    onChangeCustomization(e, "secondary_button_colour")
                                                }}
                                                value={customization?.secondary_button_colour}
                                            />
                                        </span>
                                    </div>
                                </div>

                                <div className="grid ">
                                    <label htmlFor={""} className=" mr-2 mt-2 mb-2 text-sm font-medium text-gray-500">
                                        Text color
                                    </label>
                                    <div className="flex rounded-full">
                                        <span className=" flex rounded-md w-52 border border-gray-300 ">
                                            <input
                                                type="text"
                                                className="w-[50%] mx-5 bg-transparent text-black"
                                                value={customization?.text_colour}
                                            />
                                            <input
                                                type="color"
                                                className="mt-2 mb-2 ml-4 border w-[40%] bg-transparent mr-2  "
                                                value={customization?.text_colour}
                                                onChange={(e) => {
                                                    onChangeCustomization(e, "text_colour")
                                                }}
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <FontStyles loadingStatus={loadingStatus} setLoadingStatus={setLoadingStatus} />
                        </div>
                    </div>
                </div>
            </div></>
        // </div>

    );
};

export default StyleTab;
