import React, { useEffect } from "react";
import { reset, editRule } from "state/rule-engine/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import FilterList from "./Filters/Filter/filterList";
import { ParentProps } from "state/new-konnector/types";

export default function RuleEngine() {
  const dispatch = useAppDispatch();

  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);

  let editRules;
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  const getRuleConfig = (state) => {
    let findRuleIndex, ruleConfig;
    if (currentActionId === ParentProps.Repeater) {
      editRules = state[konnectorState].data.repeater.editRule;
      findRuleIndex = state[konnectorState].data.repeater.ruleEngine.findIndex(
        (action) => action.id === isRepeaterCurrentActionId
      );
      return (ruleConfig = state[konnectorState].data.repeater.ruleEngine[findRuleIndex]);
    } else {
      editRules = state[konnectorState].editRule;

      findRuleIndex = state[konnectorState].data.ruleEngine.findIndex(
        (action) => action.id === isRepeaterCurrentActionId
      );
      return (ruleConfig = state[konnectorState].data.ruleEngine[findRuleIndex]);
    }
  };
  const ruleData = useAppSelector(getRuleConfig);
  console.log({ ruleData });

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (editRules === true) {
      dispatch(editRule(ruleData));
    }
  }, [editRules]);

  return (
    <div className="  w-[100%]">
      <FilterList />
    </div>
  );
}
