import React, { useEffect } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { setAccountApp } from "state/account/slices";

const SelectApp: React.FC<AppsProps> = ({ apps }) => {
  const selectedApp = useAppSelector((state) => state.account.appDetails.id);
  const dispatch = useAppDispatch();

  const getApp = (value) => {
    return apps.find((app) => app.id === value);
  };

  const onChangeAppHandler = ({ value }) => {
    const app = getApp(value);
    if (value) {
      dispatch(setAccountApp(app));
    }
  };
  const renderApps = () => {
    return apps.map((app) => ({
      label: app.appName,
      value: app.id,
    }));
  };

  return (
    <div className="p-3">
      <label className="form-label inline-block font-medium mb-2 text-grey-600 dark:text-white text-md">
        Please select the account to be added
      </label>
      <Select
        options={renderApps()}
        value={renderApps().filter(function (option) {
          return option.value === selectedApp;
        })}
        onChange={onChangeAppHandler}
      />
    </div>
  );
};

export default SelectApp;
