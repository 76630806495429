import { StyledThemeProvider } from "definitions/styled-components";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import routesTemplates from "routes";
import { GlobalLayout } from "templates";
import store from "./state/store";
import { Error404 } from "templates/errors";
import Auth from "./Auth";
import "tailwindcss/tailwind.css";
import "./styles/main.css";

function App(): JSX.Element {
  return (
    <StyledThemeProvider>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            {routesTemplates.map((routesTemplate) => {
              const { routes: appRoutes, template: Template, type } = routesTemplate;
              return appRoutes.map((appRoute) => (
                <Route
                  exact={appRoute.exact}
                  path={appRoute.path}
                  key={appRoute.path}
                  render={(route) => (
                    <Auth params={appRoute.params} appRoute={appRoute} Template={Template} route={route} type={type} />
                  )}
                />
              ));
            })}
            <Route render={(route) => <GlobalLayout Component={Error404} route={route} type={"public"} />} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </StyledThemeProvider>
  );
}

export default App;
