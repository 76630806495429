import { AxiosResponse } from "axios";
import { activateKonnectorApi, deactivateKonnectorApi, deleteKonnectorApi } from "api/konnectorsApi";
import { takeEvery, put, call } from "redux-saga/effects";
import { ErrorStatus } from "./types";
import {
  activateKonnectorLoading,
  deactivateKonnectorLoading,
  deactivateKonnectorSuccess,
  activateKonnectorSuccess,
  apiError,
  deleteKonnectorLoading,
  deleteKonnectorSuccess,
  displayErrorMessage,
} from "./slices";

function* activateKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(activateKonnectorApi, data.payload);
    const statusCode = ErrorStatus.find((status) => status === result.status) ? true : false;
    if (statusCode) {
      yield put(displayErrorMessage(result.data.message));
      throw Error(result.data.message);
    }
    yield put(activateKonnectorSuccess(result.data));
  } catch (error) {
    yield put(displayErrorMessage(error.response?.data?.message));
    yield put(apiError(error.message));
  }
}
function* deactivateKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(deactivateKonnectorApi, data.payload);
    yield put(deactivateKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* deleteKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(deleteKonnectorApi, data.payload);
    yield put(deleteKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export default function* rootSaga() {
  yield takeEvery(activateKonnectorLoading, activateKonnector);
  yield takeEvery(deactivateKonnectorLoading, deactivateKonnector);
  yield takeEvery(deleteKonnectorLoading, deleteKonnector);
}
