import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import TickMarkIcon from "pages/BillingTest/Icons/TickMarkIcon";
import React, { useState } from "react";
import ViewJson from "../../ViewJSon";

const Success: React.FC<any> = ({ text, outputFields, title }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };

  return (
    <div>
      {/* // <Tooltip text="success"> */}
      <TickMarkIcon onClick={handleActionClick} />
      {state && <ViewJson visible={state} setViewOutputFields={setState} outputFields={outputFields} title={title} />}
      {/* // </Tooltip> */}
    </div>
  );
};

export default Success;
