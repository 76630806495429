import React from "react";
import { AppProps } from "pages/KonnectorPage/types";
import SelectApps from "./SelectApps";
import SelectAccount from "./SelectAccount";
import SelectEvent from "./SelectEvent";
import { AppRole, KonnectorAppState } from "state/edit-konnector/types";

interface LookupConfigurationProps {
  apps: AppProps[];
  lookupDetails: KonnectorAppState<AppRole.Lookup>;
  konnectorSlice: any;
}

const LookupConfiguration: React.FC<LookupConfigurationProps> = ({ apps, lookupDetails, konnectorSlice }) => {
  return (
    <>
      <div className="flex mb-3 justify-between">
        <SelectApps apps={apps} sourceApp={lookupDetails.appId} konnectorSlice={konnectorSlice} />
        {lookupDetails.appId && (
          <SelectAccount
            sourceApp={lookupDetails.appId}
            apps={apps}
            sourceAccount={lookupDetails.connectionId}
            konnectorSlice={konnectorSlice}
          />
        )}
      </div>
      <div className="flex justify-between">
        {lookupDetails.appId && lookupDetails.connectionId && (
          <SelectEvent
            sourceApp={lookupDetails.appId}
            sourceEvent={lookupDetails.eventId}
            apps={apps}
            konnectorSlice={konnectorSlice}
          />
        )}
      </div>
    </>
  );
};

export default LookupConfiguration;
