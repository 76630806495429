import React, { useEffect } from "react";
import Select from "react-select";
import { useAppAccounts } from "api/accountsApi";
import { useAppDispatch, useAppSelector } from "state/store";
import CreateAccount from "common/containers/Account/CreateAccount";
import { setAccountModal } from "state/account/slices";
import { useParams } from "react-router-dom";
import { ActionMode, ParentProps } from "state/new-konnector/types";

const SelectAccount: React.FC<any> = ({ sourceApp, apps, sourceAccount, konnectorSlice, repeater }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const dispatch = useAppDispatch();

  const { data: appAccounts, error: appAccountsError, mutate } = useAppAccounts(sourceApp);

  const showCreateAccount = useAppSelector((state) => state.account.showModal);
  const createdAccount = useAppSelector((state) => state.account.data);
  const appAccountState = useAppSelector((state) => state.account);
  const edit = useAppSelector((state) => state.createNewKonnector.data.actions);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  const parentId = currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source;
  const { other, appid, accountid, eventid, name } = useParams<any>();

  useEffect(() => {
    if (other && edit[0].actionMode !== ActionMode.Edit && edit.length == 1) {
      dispatch(
        konnectorSlice.updateTargetAccount({
          connectionId: accountid,
          id: isRepeaterCurrentActionId,
          connectionName: "Stripe-to-freshdesk",
          actionType: "actions",
        })
      );
    }
  }, [sourceAccount]);

  useEffect(() => {
    if (appAccountState.data.id && appAccountState.status === "success") {
      mutate({
        ...appAccounts,
        data: {
          ...appAccounts.data,
          items: [appAccountState.data, ...appAccounts.data.items],
        },
      });
    }
  }, [appAccountState.data, appAccountState.status, appAccounts, mutate]);

  useEffect(() => {
    if (sourceApp && appAccounts && !appAccounts.data.items.length) {
      dispatch(setAccountModal(true));
    }
  }, [sourceApp, appAccounts, dispatch]);

  useEffect(() => {
    if (createdAccount.id) {
      dispatch(
        konnectorSlice.updateTargetAccount({
          connectionId: createdAccount.id,
          id: isRepeaterCurrentActionId,
          connectionName: createdAccount.connectionName,
          parentId,
        })
      );
    }
  }, [createdAccount.id, isRepeaterCurrentActionId, dispatch, konnectorSlice]);

  const onChangeAccountHandler = ({ value: connectionId, label: connectionName }) => {
    dispatch(
      konnectorSlice.updateTargetAccount({
        connectionId,
        id: isRepeaterCurrentActionId,
        connectionName,
        parentId,
      })
    );
  };

  const onClickCreateAccountHandler = (e) => {
    e.preventDefault();
    dispatch(setAccountModal(true));
  };

  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };

  const renderAppAccounts = () => {
    if (!appAccounts) return [{ label: "Loading...", value: "" }];
    return appAccounts.data.items.map((account) => ({
      label: account.connectionName,
      value: account.id,
    }));
  };

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Account</label>
      <Select
        options={renderAppAccounts()}
        value={renderAppAccounts().filter(function (option) {
          return option.value === sourceAccount;
        })}
        onChange={onChangeAccountHandler}
      />
      <div className="text-sm mt-2 font-medium text-gray-500 dark:text-gray-300">
        Not registered?{" "}
        <button
          onClick={onClickCreateAccountHandler}
          className="text-blue-700 cursor-pointer hover:underline dark:text-blue-500"
        >
          Create account
        </button>
      </div>
      {showCreateAccount && <CreateAccount appDetails={getApp()} />}
    </div>
  );
};

export default SelectAccount;
