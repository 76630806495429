import { Konnectors as KonnectorsProp } from "pages/KonnectorList/types";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import KonnectorList from "./KonnectorList";
import Pagination from "./Pagination";
import Select from "react-select";
import SortBy from "./SortBy";
import { FilterType } from "state/workFlows/types";
import { StatusType } from "state/new-konnector/types";
import { useAppSelector } from "state/store";
import { getworkflowsFilterType } from "state/workFlows/selectors";
import { LoadingIcon } from "common/components/Icons";
import { useAppDescription } from "api/workflowApi";
import { updateAppDesc, updateAppDescription, updateAppInstall, updateAppOverview } from "state/new-workflow/slices";
import { useDispatch } from "react-redux";
import { watch } from "fs";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { setWorkflowIndex, updateFilter } from "state/workFlows/slices";
import { DragDropContext } from "react-beautiful-dnd";
import StateManagedSelect from "react-select/dist/declarations/src/stateManager";
// import EditorConvertToHTML from "./TextFormater";
import KonnectorListActive from "./KonnectorListActive";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
interface KonnectorsProps {
  data: KonnectorsProp;
}

const Konnectors: React.FC<any> = ({ data }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id: any = query.get("appId");
  const dispatch = useDispatch();
  const filterBy = useAppSelector(getworkflowsFilterType);
  const konnectorState = useAppSelector((kon) => kon.workflows.workflowState);
  const status = useAppSelector((state) => state.createNewWorkflow.appStatus);
  const { data: apps, error: error } = useAppDescription(id);
  const appOverView = useAppSelector((state) => state.createNewWorkflow.data.appOverview);
  console.log(appOverView);
  const appInstall = useAppSelector((state) => state.createNewWorkflow.data.appInstall);
  console.log(appInstall);
  const appDesc = useAppSelector((state) => state.createNewWorkflow.data.appDescription);
  // const [appOverVieww, setappOverVieww] = useState(appOverView);
  console.log(appDesc);
  const [editorValueDescription, seteditorValueDescription] = useState(
    localStorage.getItem("editorContentDescription") || ""
  );

  const [editorValue, setEditorValue] = useState(localStorage.getItem("editorContent") || "");
  const [editorValueinstall, setEditorValueinstall] = useState(localStorage.getItem("editorContentsinstall") || "");

  const handleInput = () => {
    dispatch(updateAppDescription({ appDesc, appOverView, appInstall, id }));
    localStorage.setItem("editorContentDescription", appDesc);
    localStorage.setItem("editorContent", appOverView);
    localStorage.setItem("editorContentsinstall", appInstall);
  };
  useEffect(() => {
    const storedValue = localStorage.getItem("editorContent");
    if (storedValue) {
      setEditorValue(storedValue);
    }
    const Stored_Installvalue = localStorage.getItem("editorContentsinstall");
    if (Stored_Installvalue) {
      setEditorValueinstall(Stored_Installvalue);
    }
    const Stored_Description = localStorage.getItem("editorContentDescription");
    if (Stored_Description) {
      seteditorValueDescription(Stored_Description);
    }
  }, []);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!result.destination) {
      return;
    }
    if (source.droppableId === "droppable" && destination.droppableId === "droppable") {
      const updatedItems = data.items
      const [reorderedItem] = updatedItems.splice(result.source.index, 1);
      updatedItems.splice(result.destination.index, 0, reorderedItem);
      let workflowItems = []
      updatedItems.filter((items: any) => {
        workflowItems.push(items.id)
      })
      dispatch(setWorkflowIndex({ workflowItems: workflowItems, workflowId: result.draggableId }))
    }
  }

  useEffect(() => {
    dispatch(updateAppDesc(apps?.data[0]?.description));
    dispatch(updateAppOverview(apps?.data[0]?.appOverview));
    dispatch(updateAppInstall(apps?.data[0]?.appInstall));
  }, [apps?.data[0]]);

  const handlechange = (value) => {
    dispatch(updateAppDesc(value));
  };

  const handleOverviewchange = (value) => {
    dispatch(updateAppOverview(value));
  };

  const handleInstallchange = (value) => {
    dispatch(updateAppInstall(value));
  };
  useEffect(() => {
    if (status === StatusType.success && filterBy == FilterType.description) {
      const toastMessage = "Saved Successfully";
      toast.success(toastMessage, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          dispatch(updateFilter(FilterType.ALL));
        },
      });
    }

    // if (hasKonnectorCreated && konnectorType === KonnectorType.Form) {
    //   setShowFormModal(true);
    // }
  }, [status]);
  const [content, setContent] = useState("");
  console.log(content);
  const handleEditorChange = (value) => {
    setContent(value);
    dispatch(updateAppDesc(value));
  };
  return (
    <>
      {data?.items?.length > 0 ? (
        <div className={"flex h-[calc(90vh-5rem)] w-full px-10 pb-10  flex-col "}>
          {/* <SortBy /> */}
          {filterBy === FilterType.ALL || filterBy === FilterType.ACTIVE ? <SortBy /> : null}
          {filterBy === FilterType.description ? (
            <div className="overflow-y-scroll mt-16">
              <div>
                <label htmlFor="h1" className="block mb-2 text-sm font-medium text-gray-900  flex items-start">
                  Enter App Description
                </label>
                <ReactQuill
                  placeholder={"Enter App Description"}
                  className="w-[80%]  mb-10"
                  value={appDesc}
                  onChange={handlechange}
                  theme="snow" // you can change the theme (snow, bubble)
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ font: [] }], // font family dropdown
                      [{ color: [] }],
                      [{ align: [] }],
                      [{ background: [] }],
                      ["clean"], // remove formatting button
                    ],
                  }}
                />

                {/* <EditorConvertToHTML
                  placeholder={"Enter App Description"}
                  content={appDesc ? `${appDesc}` : editorValueDescription}
                  onEditorStateChangeState={updateAppDesc}
                  isCustomisation={false}
                /> */}

                <label htmlFor="h2" className="my-2  mb-2 text-sm font-medium text-gray-900  items-start">
                  Enter Overview
                </label>
                <ReactQuill
                  placeholder={appOverView == "" && "Enter App Description"}
                  className="w-[80%]  mb-10"
                  value={appOverView}
                  onChange={handleOverviewchange}
                  theme="snow" // you can change the theme (snow, bubble)
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ font: [] }], // font family dropdown
                      [{ color: [] }],
                      [{ align: [] }],
                      [{ background: [] }],
                      ["link", "image"],
                      ["clean"], // remove formatting button
                    ],
                  }}
                />
                {/* <EditorConvertToHTML
                  placeholder={"Enter the Overview"}
                  content={appOverView ? `${appOverView}` : editorValue}
                  onEditorStateChangeState={updateAppOverview}
                  isCustomisation={false}
                /> */}
                <label htmlFor="h3" className="my-2  block mb-2 text-sm font-medium text-gray-900 flex items-start">
                  Enter How to install?
                </label>
                <ReactQuill
                  placeholder={"Enter App Description"}
                  className="w-[80%] mb-10"
                  value={appInstall}
                  onChange={handleInstallchange}
                  theme="snow" // you can change the theme (snow, bubble)
                  modules={{
                    toolbar: [
                      ["bold", "italic", "underline", "strike"], // toggled buttons
                      ["blockquote", "code-block"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      [{ font: [] }], // font family dropdown
                      [{ color: [] }],
                      [{ align: [] }],
                      [{ background: [] }],
                      ["link", "image"],
                      ["clean"], // remove formatting button
                    ],
                  }}
                />
                {/* <EditorConvertToHTML
                  placeholder={"Enter How to install?"}
                  content={appInstall ? `${appInstall}` : editorValueinstall}
                  onEditorStateChangeState={updateAppInstall}
                  isCustomisation={false}
                /> */}
              </div>
              <div className="flex flex-row  p-2 mt-4 space-x-2">
                <button
                  type="button"
                  onClick={handleInput}
                  // onClickCapture={closeModalHandler}
                  disabled={status === StatusType.loading ? true : false}
                  className=" text-white bg-primary-color hover:bg-primary-color focus:outline-none   font-medium rounded-sm text-sm inline-flex items-center px-5 py-2.5 text-center mr-2 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {status === StatusType.loading ? (
                    <>
                      <LoadingIcon />
                      {"Loading..."}
                    </>
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          ) : (
            <>
              {filterBy === FilterType.ACTIVE ? <DragDropContext onDragEnd={onDragEnd}>
                <KonnectorListActive data={data.items} />
              </DragDropContext> : <DragDropContext onDragEnd={onDragEnd}>
                <KonnectorList data={data.items} />
              </DragDropContext>}

              {/* {isTrue ? "" : <Pagination total={data.totalItems} />} */}
            </>
          )}
        </div>
      ) : (
        <div className="flex w-full h-[calc(100vh-5rem)] justify-center items-center flex-col">
          {filterBy === FilterType.ALL ? (
            <>
              <p className="mb-3">You haven't created a Konnectors yet!</p>
              <Link
                to={`/${id}/workflow/new`}
                type="button"
                className="text-white  bg-primary-color hover:bg-[#1f324e] font-medium rounded-sm text-sm px-5 py-2 text-center mr-2 mb-6 hover:transition-all"
              >
                Create New Konnector Template
              </Link>
            </>
          ) : (
            <>
              {filterBy === FilterType.ACTIVE ? (
                <p className="mb-3">There is no active workflow!</p>
              ) : (
                <p className="mb-3">There is no active workflow!</p>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Konnectors;
