import Modal from "common/components/Modal/DefaultModal";
import React from "react";
import ReactJson from "react-json-view";

const ViewJson: React.FC<any> = ({ visible, outputFields, setViewOutputFields, title }) => {
  return (
    <div>
      <Modal title={title} visible={visible} setVisible={setViewOutputFields}>
        <ReactJson src={outputFields} />
      </Modal>
    </div>
  );
};

export default ViewJson;
