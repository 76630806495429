import React from "react";
import { dependentDescriptionField, requiredField } from "../constants/fieldList";
import DependentFieldModal from "../Modal/DependentFieldModal";
import SubCategoryModal from "../Modal/SubCategoryModal";
import { useAppSelector } from "state/store";
import ItemsModal from "../Modal/ItemsModal";
import RenderField from "./genericFields";

const RenderDependentField: React.FC<any> = ({ fieldList, register, errors, reset }) => {
  const renderFieldList = (fieldList) => {
    return fieldList.map((field) => {
      return <RenderField field={field} register={register} errors={errors} />;
    });
  };
  const currentFieldId = useAppSelector((state) => state.formBuilder.currentFormField);
  const currentFormField = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId)
  );

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex w-full space-x-2 justify-between">{renderFieldList(fieldList)}</div>
        <div className="flex w-full space-x-2 justify-between">{renderFieldList(dependentDescriptionField)}</div>
        <div className="flex flex-col space-x-2  w-1/3 ">
          {[requiredField].map((field) => {
            return (
              <label
                htmlFor={`${field.name}`}
                className="border-[1px] border-gray-300 h-[42px] mr-1 items-center flex rounded p-2"
              >
                <RenderField field={field} register={register} errors={errors} />
              </label>
            );
          })}
        </div>
      </div>
      <div className="w-full flex flex-col space-y-1">
        <div className="grid grid-cols-3 gap-2">
          <div
            className={`flex flex-col  transition-all max-h-60 ${
              currentFormField.options.length > 4 && "overflow-y-scroll"
            }`}
          >
            <DependentFieldModal register={register} errors={errors} reset={reset} />
          </div>
          {currentFieldId.optionId !== -1 && (
            <div
              className={`flex flex-col transition-all max-h-60 ${
                currentFormField.options[currentFieldId.optionId]?.subCategory.length > 4 && "overflow-y-scroll"
              }`}
            >
              <SubCategoryModal register={register} errors={errors} reset={reset} />
            </div>
          )}
          {currentFieldId.subCategoryId !== -1 &&
            currentFieldId.optionId !== -1 &&
            currentFormField.options[currentFieldId.optionId]?.subCategory[currentFieldId.subCategoryId]?.items.length >
              0 && (
              <div
                className={`flex flex-col transition-all max-h-60 ${
                  currentFormField.options[currentFieldId.optionId]?.subCategory[currentFieldId.subCategoryId]?.items
                    .length > 4 && "overflow-y-scroll"
                }`}
              >
                <ItemsModal register={register} errors={errors} reset={reset} />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default RenderDependentField;
