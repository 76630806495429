import moment from "moment";
import React from "react";
import Badge from "../../Badge";

export interface ActionsProps {
  name: string;
  id: string;
  status: "success" | "failure";
}

const ActionSummary: React.FC<any> = ({
  name,
  id,
  status,
  handleActionChange,
  iconUrl,
  appEventId,
  dtUpdated,
  outputFields,
  title,
}) => {
  return (
    <tr className="">
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">Action</td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
        {iconUrl ? <><img src={iconUrl} alt="" width="30" height="30" className="inline-flex mr-2" />{appEventId}</> : <>{appEventId}</>}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{moment(dtUpdated).fromNow()}</td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
        <Badge type={status} text={status} outputFields={outputFields} title={title} />
      </td>
      <td className="px-4 py-4 text-sm text-gray-800 whitespace-nowrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-4 h-4 cursor-pointer"
          onClick={() => handleActionChange()}
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </td>
    </tr>
  );
};

export default ActionSummary;
