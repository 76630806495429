import React, { useEffect, useState } from "react";
import { useApps } from "api/appsApi";
import { useAccounts } from "api/accountsApi";
import { getKonnectorLoading, reset as resetEditKonnector } from "state/edit-workflow/slices";
import { resetWebhook as resetNewKonnector } from "state/new-workflow/slices";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/edit-workflow/types";
import Spinner from "common/components/Spinner";
import { setKonnectorState } from "state/workFlows/slices";
import UserMessage from "common/containers/Layout/components/EmailVerification";
import { reset } from "state/form-builder/slices";
import SidebarControls from "./components/SidebarControls/index";
import ConfigureKonnector from "./components/ConfigureWorkflow/index";
import { WorkFlowState } from "state/workFlows/types";
import WorkflowDescription from "./components/ConfigureWorkflow/WorkflowSetting";

const WorkFlowPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id, appId, workflowId } = useParams<any>();
  useEffect(() => {
    if (id) {
      dispatch(setKonnectorState(WorkFlowState.EditWorkFlowState));
    } else {
      dispatch(setKonnectorState(WorkFlowState.NewWorkFlowState));
    }
  }, []);
  const [konnectorSlice, setKonnectorSlice]: any = useState();
  const workflowState = useAppSelector((state) => state.workflows.workflowState);
  const apiStatus = useAppSelector((state) => state[workflowState].status);
  const konnector = useAppSelector((state) => state[workflowState]);
  const konnectorType = useAppSelector((state) => state[workflowState].data.workflowType);
  const actions = useAppSelector((state) => state[workflowState].data.actions);
  const planDetails = useAppSelector((state) => state.payments.createKonnector);
  const loadingState = useAppSelector((state) => state.createNewWorkflow.isLoading);
  const isDisplay = useAppSelector((state) => state.editWorkFlow.isDisplay);

  const setKonnectorSliceAsync = async () => {
    switch (workflowState) {
      case WorkFlowState.EditWorkFlowState:
        return await import("state/edit-workflow/slices");
      case WorkFlowState.NewWorkFlowState:
        return await import("state/new-workflow/slices");
    }
  };
  useEffect(() => {
    setKonnectorSliceAsync()
      .then((data) => {
        setKonnectorSlice(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [workflowState]);

  //mount
  useEffect(() => {
    if (id) {
      const data = {
        id: id,
        appId: appId,
      };
      dispatch(getKonnectorLoading(data));
    }
  }, []);

  const { data: apps, error: appsError } = useApps(1, 150);
  const { data: accounts, error: accountsError } = useAccounts();
  //unmount
  useEffect(() => {
    return () => {
      dispatch(resetEditKonnector());
      dispatch(reset());
      dispatch(resetNewKonnector({ konnectorType }));
    };
  }, []);
  const { other, appid, accountid, eventid, name } = useParams<any>();

  if (appsError || accountsError) return <p>Failed to load apps</p>;
  if (
    (apiStatus === StatusType.loading && !actions.length && workflowState === WorkFlowState.EditWorkFlowState) ||
    !apps ||
    !accounts ||
    !konnectorSlice
  )
    return <Spinner />;
  if (workflowState === WorkFlowState.EditWorkFlowState && !konnector.data.id)
    return <UserMessage actionType={"konnectorNotFound"} />;

  return !planDetails.status && workflowState === WorkFlowState.NewWorkFlowState ? (
    <UserMessage actionType={planDetails.type} />
  ) : (
    <div className="relative   top-20 overflow-auto ml-80">
      <section className="flex items-start">
        {/* SidebarControls */}
        {/* <SidebarControls /> */}
        {/* ConfigureKonnector */}
        {other && loadingState && (
          <>
            <div className="ml-96 pl-72 ">
              <Spinner />
            </div>
          </>
        )}
        {isDisplay ? (
          <WorkflowDescription konnectorState={workflowState} konnectorSlice={konnectorSlice} />
        ) : (
          <ConfigureKonnector
            apps={apps.data.items}
            accounts={accounts.data.items}
            konnectorState={workflowState}
            konnectorSlice={konnectorSlice}
          />
        )}
        {/* <ConfigureKonnector
        apps={apps.data.items}
        accounts={accounts.data.items}
        konnectorState={workflowState}
        konnectorSlice={konnectorSlice}
      /> */}
      </section>
    </div>
  );
};

export default WorkFlowPage;
