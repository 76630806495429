import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "../types";

const Password: React.FC<Options> = ({ pattern, name, required, register, errors, isDisabled = false }) => {
  return (
    <div>
      {required ? (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {name} <span className="text-red-500 ">*</span>
        </label>
      ) : (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {name}
        </label>
      )}
      <input
        type="password"
        disabled={isDisabled}
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
        placeholder={"Enter " + name}
        {...register(name, {
          required: { value: required.value, message: required.message },
          pattern: {
            value: new RegExp(pattern.value, pattern.flags),
            message: pattern.message,
          },
        })}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      />
    </div>
  );
};

export default Password;
