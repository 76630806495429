import React from "react";
import { addCondition, operatorCondition, operatorType, removeFilterState } from "state/rule-engine/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import { ruleConfigRawDataMapping } from "utils/ruleEngineUtils";
import Condition from "../Conditions/Condition";

const FilterItem: React.FC<any> = ({
  filter,
  index,
  register,
  errors,
  handleSubmit,
  watch,
  getValues,
  reset,
  setValue,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const filterLength = useAppSelector((state) => state.ruleEngine.filters.length);
  const operatorArray = useAppSelector((state) => state.ruleEngine.operatorArray);
  const OnChangeHandler = (e) => {
    e.preventDefault();
    dispatch(addCondition({ name: filter.name }));
  };
  const removeFilter = () => {
    const formValue = getValues();
    const res = ruleConfigRawDataMapping(formValue, index);
    reset(res);
    dispatch(removeFilterState({ name: filter.name, index: index }));
  };
  const changeMatchType = (match) => {
    dispatch(operatorCondition({ filterName: filter.name, match_type: match }));
  };

  const handleOnChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      dispatch(operatorType({ toggleState: "AND", attachedFilters: [index + 1, index + 2] }));
    } else {
      dispatch(operatorType({ toggleState: "OR", attachedFilters: [index + 1, index + 2] }));
    }
  };
  const matchType = filter.match_type === "all" ? true : false;

  let renderedOperator;
  for (const i in operatorArray) {
    if (i.includes((index + 1).toString())) {
      renderedOperator = operatorArray[i];
    }
  }

  return (
    <div className="flex flex-col mt-8 mb-4">
      <div className=" p-8 m-auto w-[90%] bg-white rounded-md shadow-md ">
        <div className="flex space-x-3 px-4 ">
          <div className="flex items-center mb-2">
            <label className="ml-2 text-sm  text-gray-900">
              <input
                type="radio"
                value="all"
                checked={matchType}
                onClick={() => changeMatchType("all")}
                {...register(`filter_${index}___match_type`)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500   dark:bg-gray-700 dark:border-gray-600"
              />
              Match <b>All</b> of the below
            </label>
          </div>
          <div className="flex items-center mb-2">
            <label className="ml-2 text-sm text-gray-900 ">
              <input
                type="radio"
                value="any"
                checked={!matchType}
                onClick={() => changeMatchType("any")}
                {...register(`filter_${index}___match_type`)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500  dark:bg-gray-700 dark:border-gray-600"
              />
              Match <b>Any</b> of the below
            </label>
          </div>
          {filterLength === 1 ? null : (
            <div className="flex mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="black"
                className="w-4 h-4 cursor-pointer"
                onClick={() => removeFilter()}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
          )}
        </div>

        <div className=" flex flex-col  p-2">
          <Condition
            register={register}
            watchField={watch}
            errors={errors}
            conditions={filter.conditions}
            filterName={filter.name}
            match_type={filter.match_type}
            filterIndex={index}
            getValues={getValues}
            reset={reset}
            setValue={setValue}
          />
        </div>
        {filter.conditions.length > 4 ? null : (
          <button onClick={(e) => OnChangeHandler(e)} className=" flex rounded-full text-center mt-2 space-x-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="primary-color"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              className="w-6 h-6"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            Add Condition
          </button>
        )}
      </div>
      <div className="flex items-center justify-center mt-7 ">
        {filterLength === 1
          ? null
          : filterLength !== index + 1 && (
              <label htmlFor={index} className="inline-flex items-center space-x-4 cursor-pointer">
                <span className="relative">
                  <input
                    id={index}
                    onChange={handleOnChange}
                    checked={renderedOperator === "AND" ? true : false}
                    type="checkbox"
                    className="hidden peer"
                  />
                  <div className="absolute bg-white inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto  peer-disabled:bg-gray-500"></div>
                  <div className="w-10 h-6 bg-gray-500 peer-checked:bg-green-700 rounded-full shadow-inner peer-disabled:bg-gray-200"></div>
                  {renderedOperator === "AND" ? "AND" : "OR"}
                </span>
              </label>
            )}
      </div>
    </div>
  );
};

export default FilterItem;
