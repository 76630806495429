import React from "react";
import { AccountsProps } from "pages/KonnectorPage/types";
import AccountListItem from "./AccountListItem";

interface AccountListProps extends AccountsProps {
  pageNumber: number;
  setPageNumber: any;
}

const AccountList: React.FC<AccountListProps> = ({
  accounts,
  configurationType,
  pageNumber,
  setPageNumber,
  konnectorSlice,
}) => {
  const renderAccounts = () => {
    if (configurationType === "action") {
      return accounts.map((account) => (
          <AccountListItem
            key={account.configId}
            configurationType={configurationType}
            konnectorSlice={konnectorSlice}
            {...account}
          />
        ));
    }
    return accounts.map((account) => (
      <AccountListItem
        key={account.configId}
        configurationType={configurationType}
        konnectorSlice={konnectorSlice}
        {...account}
      />
    ));
  };
  return (
    <div className="grid gap-2 my-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8">
      {renderAccounts()}
      <div className="flex flex-col justify-center ">
        {accounts.length / pageNumber >= 16 && (
          <button
            type="button"
            className="font-medium transition-all mt-4 cursor-pointer hover:bg-gray-50 hover:shadow-md rounded-sm text-sm  py-3  mr-2  hover:transition-all text-primary-color bg-gray-100 "
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

export default AccountList;
