import React from "react";
import All from "./AllApps.icon";
import Communication from "./Communication.icon";
import Crm from "./Crm.icon";
import DocumentManagement from "./Form.icon";
import Productivity from "./Productivity.icon";
import Helpdesk from "./HelpDesk.icon";
import Marketing from "./Sales.icon";
import ActiveKonnectors from "./ActiveKonnector.icon";
import AllKonnectors from "./AllKonnector.icon";
import Trigger from "./Trigger.icon";
import Actions from "./Action.icon";
import ParseEngine from "./parseEngine.icon";
import Lookup from "./Lookup.icon";

export const Icon: React.FC<any> = ({ type, fill, className }) => {
  console.log("type : ", type)

  const renderIcon = (type) => {
    switch (type.type) {
      case "all":
        return <All fill={fill} className={className} />;
      case "communication":
        return <Communication className={className} />;
      case "crm":
        return <Crm fill={fill} className={className} />;
      case "document_management":
        return <DocumentManagement fill={fill} className={className} />;
      case "configration":
      case "description":
      case "productivity":
        return <Productivity fill={fill} className={className} />;
      case "helpdesk":
        return <Helpdesk fill={fill} className={className} />;
      case "marketing":
        return <Marketing fill={fill} className={className} />;
      case "active_konnectors":
        return <ActiveKonnectors fill={fill} className={className} />;
      case "all_konnectors":
        return <AllKonnectors fill={fill} className={className} />;
      case "source":
      case "trigger":
        return <Trigger className={className} />;
      case "target":
      case "actions":
        return <Actions className={className} />;
      case "parseEngine":
        return <ParseEngine></ParseEngine>;
      case "lookup":
        return <Lookup></Lookup>;
    }
  };
  return <>{renderIcon(type)}</>;
};
