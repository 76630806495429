import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { AppsProps } from "../types";
import AppItem from "./AppItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { setDevheight, setpageNumber } from "state/account/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import { useAppsByTypes } from "api/appsApi";
interface AppListProps extends AppsProps {
  pageNumber: number;
  setPageNumber: any;
  selected?: any;
}

const AppList: React.FC<AppListProps> = () => {
  const renderApps = () => {
    return (
      <>
        {allPageApps.map((app, index) => (
          <AppItem key={index} app={app} />
        ))}
      </>
    );
  };
  const dispatch = useAppDispatch();
  const [hasMore, sethasMore] = useState(true);
  const appType = useAppSelector((state) => state.konnectors.appType);
  const pageNumberr = useAppSelector((state) => state.account.pageNumber);
  const [allPageApps, setApps] = useState([]);
  const { data: apps, error: appsError } = useAppsByTypes(pageNumberr, appType);

  const ref = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [totalHeight, settotalHeights] = useState(0);
  useEffect(() => {
    const totaHeight = Math.ceil(width / 1046) * 16;
    settotalHeights(totaHeight);
    dispatch(setDevheight(totaHeight));
  }, [width]);
  useLayoutEffect(() => {
    setWidth(ref.current?.offsetWidth);
    setHeight(ref.current?.offsetHeight);
  }, [ref]);

  useEffect(() => {
    setApps([]);
  }, [totalHeight]);
  useEffect(() => {
    sethasMore(true);

    if (allPageApps?.length == apps?.data?.totalItems) {
      sethasMore(false);
    }
  }, [allPageApps]);
  useEffect(() => {
    dispatch(setpageNumber(1));
    setApps([]);
  }, [appType]);

  useEffect(() => {
    if (apps) {
      setApps((prevState) => {
        if (prevState.length) {
          return [...prevState, ...apps.data.items];
        }
        return apps.data.items;
      });
    }
  }, [apps]);
  if (appsError) return <p>Failed to load apps</p>;

  const fetchData = () => {
    sethasMore(true);

    dispatch(setpageNumber(pageNumberr + 1));
    if (allPageApps?.length == apps?.data?.totalItems) {
      sethasMore(false);
    }
  };

  return (
    <>
      <div ref={ref} id="parrentscrolldiv" style={{ overflow: "auto" }}>
        <InfiniteScroll
          dataLength={allPageApps?.length}
          next={fetchData}
          hasMore={hasMore}
          loader={<h1>Loading...</h1>}
          scrollableTarget="parrentscrolldiv"
        >
          <div className="flex flex-col space-y-7 items-start">
            <div className="grid grid-cols-4 gap-6">
              {allPageApps?.map((app, index) => (
                <AppItem key={index} app={app} />
              ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default AppList;
