import React from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";

const SelectEvent: React.FC<any> = ({ sourceApp, sourceEvent, apps, konnectorSlice, disabled }) => {
  const dispatch = useAppDispatch();

  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const connectionId = useAppSelector((state) => state[konnectorState].data.trigger.connectionId);
  const onChangeEventHandler = ({ value, type }) => {
    const hitInputFieldsApi = hasInputFields.find((app) => app.id === value).inputFields;

    dispatch(konnectorSlice.updateSourceEvent({ appId: sourceApp, connectionId, event: value, type }));
    if (hitInputFieldsApi) {
      dispatch(konnectorSlice.fetchTriggerInputfieldsLoading({ appId: sourceApp, connectionId, event: value, type }));
    }
  };
  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };
  const hasInputFields = getApp().triggers.map((trigger) => {
    return { id: trigger.id, inputFields: trigger.dynamicInputFields || false };
  });

  const renderEvents = () => {
    return getApp().triggers.map((event) => ({
      label: event.name,
      type: event.eventSource,
      value: event.id,
      disabled: event.disabled ? event.disabled : false,
    }));
  };
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Event</label>
      <Select
        options={renderEvents().filter((event) => !event.disabled)}
        value={renderEvents().filter(function (option) {
          return option.value === sourceEvent;
        })}
        onChange={onChangeEventHandler}
      />
    </div>
  );
};

export default SelectEvent;
