import { EventLogsPayloadProps } from "pages/EventLogs/types";
import React from "react";
import Events from "./EventList";

const EventLogs: React.FC<EventLogsPayloadProps> = ({ data, konnector }) => {
  return (
    <>
      {data.items.length > 0 ? (
        <>
          <Events data={data.items} konnectorData={konnector.data.items} />
        </>
      ) : (
        <tr>
          <td>
            <div className="flex w-[230%] h-[calc(100vh-4rem)] justify-center items-center flex-col">
              <p className="mb-3 text-center">No events occurred!</p>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default EventLogs;
