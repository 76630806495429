import React from "react";

const HelpDesk: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 15.8 15.8"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style></style>
        </defs>
        <g id="darkmode_1" data-name="darkmode 1">
          <g id="apps_sub">
            <g>
              <path
                className="cls-1"
                d="M0,7.4c.07-.33,.17-.65,.36-.93,.43-.64,1.01-.97,1.75-1,.14,0,.18-.06,.2-.2C2.6,3.02,3.73,1.43,5.68,.49,6.38,.15,7.12,0,7.88,0c2.82,.01,5.05,2.11,5.54,4.82,.03,.16,.05,.32,.07,.48,.01,.11,.05,.16,.17,.16,1.11,.04,1.93,.8,2.13,1.95,.01,.08,.02,.16,.02,.24,0,.52,0,1.04,0,1.57,0,1.09-.86,2.07-1.89,2.19-.29,.03-.59,.02-.88,.02-.33,0-.53-.22-.53-.57,0-1.62,0-3.24,0-4.86,0-1.18-.34-2.25-1.06-3.16-.82-1.03-1.86-1.62-3.13-1.75-2.36-.25-4.55,1.49-4.97,4.01-.05,.29-.07,.58-.07,.88,0,1.61,0,3.23,0,4.84,0,.36-.13,.55-.46,.6-.63,.09-1.23,.03-1.79-.32-.51-.32-.84-.8-.99-1.42-.01-.06-.03-.11-.05-.17,0-.71,0-1.41,0-2.12Z"
              />
              <path
                className="cls-1"
                d="M7.9,13.65c.18,0,.36,0,.54,0,.59,0,1.03,.41,1.07,.99,.03,.58-.35,1.07-.93,1.13-.47,.04-.95,.05-1.43,0-.53-.07-.9-.57-.86-1.13,.04-.55,.47-.97,1-.98,.21,0,.41,0,.62,0Z"
              />
              <path
                className="cls-1"
                d="M5.77,14.73q0,.3-.29,.3c-1.66,0-3-1.11-3.38-2.79,0-.04-.02-.09-.02-.13-.02-.09,.02-.12,.1-.12,.13,0,.26,.02,.38,.02,.11,0,.11,.09,.13,.17,.2,.76,.6,1.36,1.24,1.78,.47,.31,.98,.44,1.52,.44,.31,0,.31,0,.31,.32Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default HelpDesk;
