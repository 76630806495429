import React, { useState } from "react";
import { KonnectityEngineFeatures } from "pages/KonnectorPage/types";
import { ParseEngineIcon, RuleEngineIcon, SentimentIcon } from "./Icons";
import { useDispatch } from "react-redux";
import { useAppSelector } from "state/store";
import { setParseEngineSelected } from "state/new-workflow/slices";
const KonnectifyEngine: React.FC<any> = ({ konnectorSlice, workflowState }) => {
  const [selcted, setSelected] = useState(false);
  console.log({ workflowState });
  // const isParseSelected = useAppSelector((state) => state[workflowState].isParseEngineSelected);
  const dispatch = useDispatch();
  const onClickRuleHandler = () => {
    dispatch(konnectorSlice.createRule());
  };

  const onClickParseOpen = () => {
    dispatch(konnectorSlice.createParse());
    // setSelected(!selcted);
    // dispatch(setParseEngineSelected(selcted));
    // console.log("onClickParseOpen");
  };
  return (
    <div className="w-[20%]">
      <h4 className="text-md font-semibold text-primary-color">Konnectify Engine</h4>
      <div className="flex flex-col">
        <button
          onClick={() => onClickRuleHandler()}
          className="my-3 max-w-[80%]  bg-gray-100 relative cursor-pointer h-28 hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
        >
          <span className="absolute top-1 left-1 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
            Active
          </span>
          <RuleEngineIcon />
          <figcaption className="text-center text-sm mt-2">{KonnectityEngineFeatures.RuleEngine}</figcaption>
        </button>
      </div>
      <div className="flex flex-col">
        <button
          onClick={() => onClickParseOpen()}
          className="my-3 max-w-[80%]  bg-gray-100 relative cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
        >
          <span className="absolute top-1 left-1 bg-green-100 text-green-800  text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
            Active
          </span>
          <ParseEngineIcon />
          <figcaption className="text-center text-sm mt-2">{KonnectityEngineFeatures.ParseEngine}</figcaption>
        </button>
      </div>
      <div className="my-3 max-w-[80%] bg-gray-100 relative cursor-pointer hover:bg-gray-50 h-28 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm">
        <SentimentIcon />
        <figcaption className="text-center text-sm mt-2">{KonnectityEngineFeatures.SentimentAnalysis}</figcaption>
      </div>
    </div>
  );
};

export default KonnectifyEngine;
