import { AxiosResponse } from "axios";
import { takeEvery, put, call, select } from "redux-saga/effects";
import { ErrorStatus } from "state/konnectors/types";
import { displayErrorMessage } from "state/new-konnector/slices";
import { apiError } from "state/konnectors/slices";
import { getThemeApi, savePortalCongrationApi, saveThemeApi } from "api/adminApi";
import { saveCardData, setPortaCustomization, setSaveData, setSavedValues, setTab1Data } from "./slices";
import { setRawDataMappingSuccess } from "../AdminSettings/slices";

function* saveConfigration() {
    try {
        const cardData = yield select((state) => state.themeSettings);
        const result: AxiosResponse<any> = yield call(saveThemeApi, cardData);
        console.log(result, "result final data")
        const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
        if (statusCode) {
            yield put(displayErrorMessage(result.data.message));
            throw Error(result.data.message);
        }
        console.log(result.data, "data 123")
        yield put(setSaveData(result.data));
    } catch (error) {
        yield put(apiError(error.message));
    }
}

function* saveTabConfig() {
    try {
        console.log("text")
        const adminSettings = yield select((state) => state.adminSettings);
        const themeSettings = yield select((state) => state.themeSettings);
        const data = {
            card: themeSettings.card,
            template: themeSettings.template,
            layout: themeSettings.layout,
            banner: themeSettings.banner,
            logo: themeSettings.logo,
            portalConfigration: {
                portalTitle: adminSettings.portalTile,
                portalDescription: adminSettings.portalDescription,
                fontFamily: adminSettings.fontFamily,
                primary_bgColor: adminSettings.primaryBackground,
                primary_bgColor_text: adminSettings.primaryBackground,
                primary_button_Text: adminSettings.primaryButtonText,
                primary_button_Color: adminSettings.primaryButtonColor,
                secondary_button_text: adminSettings.secondaryButtonText,
                secondary_button_Color: adminSettings.secondaryButtonColor,
                bodytext: adminSettings.TextColor,
                bodytext_color: adminSettings.TextColor
            }
        }
        console.log(data, "data check")

        const result: AxiosResponse<any> = yield call(saveThemeApi, data);
        const result1: AxiosResponse<any> = yield call(savePortalCongrationApi, data.portalConfigration);
        const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
        if (statusCode) {
            yield put(displayErrorMessage(result.data.message));
            throw Error(result.data.message);
        }
        console.log(result.data, "data 123")
        yield put(setRawDataMappingSuccess(result.data));
        yield put(setSaveData(result.data));
    } catch (error) {
        console.log(error, "err check")
        yield put(apiError(error.message));
    }
}

function* getPortalData() {
    try {
        const portalData: AxiosResponse<any> = yield call(getThemeApi);
        console.log(portalData, "portal data testing")
        if (portalData.data.data.length == 0) {
            const data = {
                card: {
                    background: "#fcfcfc",
                    hover: "#F0F0F0",
                    text: "#000000",
                    heigth: "100",
                    width: "600",
                    radius: "3",
                    bordercolour: "#ededed",
                    cardSnippet: "",
                    view: "Grid",
                    gridsize: "3",
                    cardSnippetStatus: false,
                },
                template: {
                    background: "#fcfcfc",
                    hover: "#F0F0F0",
                    text: "#000000",
                    heigth: "100",
                    width: "600",
                    radius: "3",
                    templateSnippet: "",
                    templateSnippetStatus: false,
                    togglecolour: "#000000"
                },
                layout: {
                    width: "1440",
                    heigth: "780",
                },
                banner: {
                    width: "300",
                    heigth: "38",
                    radius: "0",
                    successMsg: "Konnector saved successfully",
                    failureMsg: "Error in saving the konnector",
                    bordercolour: ""
                },
                cardState: false,
                logo: {
                    url: "",
                    size: "10",
                    colour: "#000000"
                },
                customization: {
                    title: "",
                    description: "",
                    primary_colour: "#f0f0f0",
                    primary_button_colour: "#07006b",
                    secondary_button_colour: "#F0F0F0",
                    text_colour: "#07006b",
                    font: ""
                },
            }
            yield put(setPortaCustomization(data))
        } else {
            console.log(portalData.data.data[0], "portalData.data.data[0]")
            yield put(setPortaCustomization(portalData.data.data[0]))
        }
    } catch (error) {
        yield put(apiError(error.message));
    }
}

export default function* rootSaga() {
    yield takeEvery(saveCardData.type, saveConfigration);
    yield takeEvery(setSavedValues.type, getPortalData);
    yield takeEvery(setTab1Data.type, saveTabConfig)
    // yield takeEvery(previewSetRawDataMapping.type, PreviewConfigration);
}
