import React, { useEffect, useRef } from "react";
import TextField from "../FormFields/Text";
import { useAppDispatch, useAppSelector } from "state/store";
import { retirveOptions } from "utils/formBuilder.utils";
import { optionsField } from "../constants/fieldList";
import {
  createSubCategory,
  deleteCurrentFormSubCategoryId,
  deleteOption,
  deleteSubcategory,
  saveOptionData,
  saveSubCategoryData,
  setCurrentSubCategoryId,
} from "state/form-builder/slices";
import { TrashIcon } from "@heroicons/react/outline";

const SubCategoryModal: React.FC<any> = ({ register, errors, reset }) => {
  const dispatch = useAppDispatch();
  const ref = useRef([]);
  const currentFieldId = useAppSelector((state) => state.formBuilder.currentFormField);
  const getFormField = (state) => {
    const findField = state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId);

    if (findField) {
      const fieldVlaue = retirveOptions(findField);
      return fieldVlaue;
    }
  };

  const currentFormField = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId)
  );

  const formField = useAppSelector(getFormField);
  const setOptionVlaue = (e, option) => {
    dispatch(saveSubCategoryData({ value: e.target.value, id: option.id }));
  };

  const renderField = (field, index) => {
    switch (field.type) {
      case "textbox | textarea":
      case "textbox": {
        return (
          <TextField
            {...field}
            // label={field.label}
            key={`option_${index.optionIndex}_subcategory_${index.subCategoryIndex}`}
            name={`option_${index.optionIndex}_subcategory_${index.subCategoryIndex}`}
            register={register}
            isDisabled={false}
            errors={errors}
            placeholder={field.placeholder}
          />
        );
      }
      default:
        // return "<p>Field not found</p>";
        return <p></p>;
    }
  };

  const renderForm = (index) => {
    let fields: any;
    fields = [...optionsField, (optionsField[0].placeholder = "subcategory")];
    return fields.map((field) => renderField(field, index));
  };

  return (
    <div className="rounded-sm ">
      <p className="text-sm mb-2">Subcategory</p>
      {currentFormField.options[currentFieldId.optionId]?.subCategory?.map((option, index) => {
        return (
          <>
            <div
              className="flex w-full items-center space-x-2"
              ref={(ele) => (ref.current[index] = ele)}
              onChange={(e) => {
                setOptionVlaue(e, option);
              }}
            >
              <div
                className={` ${currentFieldId.subCategoryId === option.id ? "w-full" : "w-4/5"}  mb-2 transition-all`}
              >
                {renderForm({ optionIndex: currentFieldId.optionId, subCategoryIndex: option.id })}
              </div>
              {currentFormField.options[currentFieldId.optionId]?.subCategory?.length > 1 &&
                currentFieldId.subCategoryId !== option.id && (
                  <button
                    className="cursor-pointer ml-3 w-1/12"
                    onClick={(e) => {
                      e.preventDefault();
                      dispatch(deleteSubcategory(option.id));
                    }}
                  >
                    <TrashIcon className="h-5 w-5" onClick={() => {}} />
                  </button>
                )}

              {currentFieldId.subCategoryId === option.id ? (
                <button
                  className="cursor-pointer  w-1/12 text-2xl"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteCurrentFormSubCategoryId());
                  }}
                >
                  -
                </button>
              ) : (
                <button
                  className="cursor-pointer  w-1/12 text-2xl"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setCurrentSubCategoryId(option.id));
                  }}
                >
                  +
                </button>
              )}
            </div>
          </>
        );
      })}
      <div className="w-full flex mt-2 justify-start">
        {
          <button
            className="bg-gray-300 text-sm text-gray-600 p-2 rounded"
            onClick={(e) => {
              dispatch(createSubCategory(currentFormField.options[currentFieldId.optionId].id));
            }}
            type="button"
          >
            +Add more subcategories
          </button>
        }
      </div>
    </div>
  );
};

export default SubCategoryModal;
