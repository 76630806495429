import { AxiosResponse } from "axios";
import { createPayment, freePlan, retrivePaymentMethod } from "api/paymentApi";
import { takeEvery, put, call } from "redux-saga/effects";
import {
  subscribePlanLoading,
  subscribePlanSuccess,
  upgradePlanFailed,
  deleteBulkKonnectorLoading,
  deleteBulkKonnectorSuccess,
  deleteBulkKonnectorError,
  getCardDetailsLoading,
  getCardDetailsSucess,
} from "./slices";
import { upgradePlanApi } from "api/paymentApi";

function* retriveCardDetails(data) {
  try {
    const result: AxiosResponse<any> = yield call(retrivePaymentMethod, data.payload);

    yield put(getCardDetailsSucess(result.data?.data));
  } catch (e) {
    yield put(upgradePlanFailed(e));
  }
}
function* subscribeToPlan(data) {
  try {
    const result: AxiosResponse<any> = yield call(createPayment, data.payload);
    yield put(subscribePlanSuccess(result.data?.data));
  } catch (e) {
    yield put(upgradePlanFailed(e));
  }
}
function* deleteBulkKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(freePlan, data.payload);
    yield put(deleteBulkKonnectorSuccess(result.data));
  } catch (error) {
    yield put(deleteBulkKonnectorError());
  }
}

export default function* rootSaga() {
  yield takeEvery(getCardDetailsLoading.type, retriveCardDetails);
  yield takeEvery(subscribePlanLoading.type, subscribeToPlan);
  yield takeEvery(deleteBulkKonnectorLoading, deleteBulkKonnector);
}
