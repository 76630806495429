import React, { useState } from "react";
import ViewJson from "../../ViewJSon";
import RuleSummary from "./RuleReportItem";

const RuleReport: React.FC<any> = ({ ruleReport, apps,dtUpdated }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };

  return (
    <>
      <RuleSummary
        status={ruleReport.outcome}
        handleActionChange={() => handleActionClick()}
        outputFields={ruleReport}
        title={"Rule report"}
        dtUpdated={dtUpdated}
      />
      {state && (
        <ViewJson visible={state} setViewOutputFields={setState} outputFields={ruleReport} title={"Rule report"} />
      )}
    </>
  );
};

export default RuleReport;
