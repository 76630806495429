import { AppRootObject } from "interfaces/modules/apps";
import { DataSource } from "interfaces/pages/create-new-konnector";

//Common Types
export enum StatusType {
  idle = "idle",
  loading = "loading",
  success = "success",
  failed = "failed",
}

export enum DynamicFieldsSchemaType {
  Input = "input",
  Output = "output",
  DynamicInput = "dynamic_input",
  DefaultOutput = "default_output",
  DefaultInput = "default_input",
}

export enum ActionMode {
  Edit = "edit",
  Save = "save",
  View = "view",
  Create = "create",
}

export enum ConfigurationType {
  Source = "source",
  Lookup = "lookup",
  Target = "target",
  RuleEngine = "ruleEngine",
  ParseEngine = "parseEngine",
}

export enum AppRole {
  Source = "source",
  Target = "target",
  Lookup = "lookup",
  ruleEngine = "ruleEngine",
  ParseEngine = "parseEngine",
}

export enum StatusCode {
  "SuccessOK" = 200,
  "SuccessCreated" = 201,
  "ClientErrorBadRequest" = 400,
  "ClientErrorUnauthorized" = 401,
  "ClientErrorNotFound" = 404,
  "ServerErrorInternal" = 500,
}

export interface KonnectifyApiResponse<T> {
  data: T;
  status: StatusCode;
}

export interface OutputFieldsObject {
  appId?: string;
  eventId?: string;
  eventResponse?: {
    status?: StatusCode.SuccessOK;
    data?: object;
  };
}

export interface OutputFields {
  hasData: boolean;
  fields?: OutputFieldsObject;
  schema:
  | DataSource
  | {
    sourceId?: string;
    eventResponse?: {
      status?: StatusCode.SuccessOK;
      data?: object;
    };
  };
}

export interface PropValueObject {
  label: string;
  value: string;
}

export interface InputField {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue?: PropValueObject[];
}
export interface InputFields {
  sourceId: string;
  eventId: string;
  appId: string;
  data: InputField[];
  rawDataMapping?: any;
  dataMapping?: any;
  hasData?: boolean;
}

export interface DynamicInputFields {
  data: InputField[] | any;
  hasDynamicInputFields: boolean;
  dataMapping: any;
  hasData: boolean;
}

export interface DataMappingObject {
  propName: string;
  propValue?: any[];
  propType: string;
  isRequired: boolean;
  source: string;
  sourceProp: string[];
  staticValue: string;
}
export interface KonnectorAppState<T> {
  id?: string;
  actionOrderId: number;
  triggerType?: string;
  formFields?: any;
  appSelectorType: T;
  appName: string;
  connectionName: string;
  appSelectorKey: string;
  iconUrl: string;
  appId: string;
  eventId: string;
  connectionId: string;
  actionMode?: ActionMode;
  isAccountValidated: boolean;
  outputFields?: OutputFields;
  inputFields?: InputFields;
  dynamicInputFields?: DynamicInputFields;
  dataMapping?: DataMappingObject[];
  shouldReconfigure?: boolean;
  rawDataMapping?: object;
  appDetails: AppRootObject | {};
}
export interface ParseEngineType {
  outputFields?: OutputFields;
  parseConfigration?: {};
  rawDataMapping?: object;
  appDetails: AppRootObject | {};
}
export interface NewWorkflowState {
  id: string;
  name: string;
  workflowType: WorkflowType;
  webhookUrl: {
    url: string;
    id: string;
  };
  trigger: KonnectorAppState<AppRole.Source>;
  lookups: KonnectorAppState<AppRole.Lookup>[];
  actions: KonnectorAppState<AppRole.Target>[];
  ruleEngine?: any;
  parseEngine?: any;
  sourceTemplate?: string;
  workflowAppId: string;
  workflowDescription?: string;
  workflowInstruction?: string;
}

export interface UpdateSourceAppPayload {
  appId: string;
  appName?: string;
  iconUrl?: string;
  connectionId?: string;
  connectionName?: string;
  appDetails?: AppRootObject;
}
export interface UpdateSourceAccountPayload {
  connectionId: string;
  connectionName: string;
}

export interface UpdateTargetAppPayload {
  id: string;
  appId: string;
  appName?: string;
  iconUrl?: string;
  connectionId?: string;
  connectionName?: string;
  appDetails?: AppRootObject;
}
export interface UpdateTargetAccountPayload {
  id: string;
  connectionId: string;
  connectionName: string;
}

export interface UpdateTargetEventPayload {
  id: string;
  eventId: string;
  hasDynamicInputFields?: boolean;
}

export interface UpdateLookupEventPayload {
  id: string;
  eventId: string;
  attribute?: string;
}

export interface AccountField {
  value: string;
  name: string;
}

export interface AccountData {
  fields: AccountField[];
  connectionName: string;
  id: string;
  connectorTypeId: string;
  appId: string;
  dtUpdated: Date;
  dtCreated: Date;
  tenantId: string;
}

export interface FetchDynamicInputFieldsRequestPayload {
  schemaType: DynamicFieldsSchemaType;
  connectionConfigId: string;
  eventId: string;
}

export enum WorkflowType {
  WebHook = "webhook",
  Regular = "regular",
  Initial = "",
  Form = "form",
}

export type ValidateAccountSuccessResponse = KonnectifyApiResponse<boolean>;
export type CreateAccountSuccessResponse = KonnectifyApiResponse<AccountData>;

export interface InitialState {
  data: NewWorkflowState;
  currentActionId: string;
  hasConfigurationSaved: boolean;
  configurationType: ConfigurationType;
  actionMode: ActionMode;
  generateUrl?: StatusType;
  resetWebhook?: StatusType;
  status: StatusType;
  resetFormStatus?: StatusType;
  isFormGenerated?: boolean;
  created: boolean;
  errorMessage: string;
  saveButtonStatus?: boolean;
  editRule?: boolean;
  openSource?: boolean;
  edit?: boolean;
  editParse?: boolean;
  isLoading?: boolean;
  isDisplay?: boolean;
  appId?: string;
  hasDynamicInput?: boolean;
  hasActionDynamicInput?: boolean;
  displayApp?: boolean;
  isParseEngineSelected?: boolean;
}
