import React from "react";
import Error from "./Error";
import Success from "./Success";
import Info from "./Info";
import { BadgeTypes } from "./types";
import Warning from "./Warning";

const Badge: React.FC<any> = ({ type, text, outputFields, title }) => {
  const renderBadge = () => {
    switch (type) {
      case BadgeTypes.Success:
        return <Success text={text} outputFields={outputFields} title={title} />;
      case BadgeTypes.Error:
        return <Error text={text} outputFields={outputFields} title={title} />;
      case BadgeTypes.FieldMappingFailed:
        return <Error text={text} outputFields={outputFields} title={title} />;
      case BadgeTypes.Info:
        return <Info text={text} />;
      case BadgeTypes.Warning:
        return <Warning text={text} />;
    }
  };
  return <>{renderBadge()}</>;
};

export default Badge;
