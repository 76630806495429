import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { serializeDataMapping, serializeLookupDataMapping } from "utils/appsUtils";
import { v4 as uuidv4 } from "uuid";
import {
  InitialState,
  InputFields,
  UpdateTargetEventPayload,
  StatusType,
  AppRole,
  UpdateSourceAppPayload,
  ValidateAccountSuccessResponse,
  UpdateSourceAccountPayload,
  UpdateTargetAppPayload,
  UpdateTargetAccountPayload,
  KonnectorAppState,
  ActionMode,
  ConfigurationType,
  UpdateLookupEventPayload,
  WorkflowType,
} from "../types";
import { setActionOrderId, setTriggerData } from "utils/workflowUtils";

export const initialState: InitialState = {
  data: {
    name: "Untitled",
    workflowType: WorkflowType.Initial,
    webhookUrl: {
      url: "",
      id: "",
    },
    sourceTemplate: "",
    trigger: {
      triggerType: "",
      appSelectorKey: "",
      appName: "",
      formFields: [],
      description: "",
      actionMode: ActionMode.Create,
      iconUrl: "",
      appSelectorType: AppRole.Source,
      appId: "",
      actionOrderId: 0,
      connectionId: "",
      connectionName: "",
      eventId: "",
      isAccountValidated: false,
      outputFields: {
        hasData: false,
        fields: {},
        schema: {},
      },
      inputFields: { appId: "", data: [], eventId: "", sourceId: "", dataMapping: {}, hasData: false },
      appDetails: {},
    },
    lookups: [],
    actions: [],
    ruleEngine: [],
    parseEngine: [],
    workflowAppId: "",
    workflowDescription: "",
    workflowInstruction: "",
    appDescription: "",
    appOverview: "",
    appInstall: "",
  },
  currentActionId: "",
  hasConfigurationSaved: false,
  configurationType: ConfigurationType.Source,
  actionMode: ActionMode.Edit,
  status: StatusType.idle,
  appStatus: StatusType.idle,
  generateUrl: StatusType.idle,
  resetWebhook: StatusType.idle,
  errorMessage: "",
  created: false,
  saveButtonStatus: false,
  editRule: false,
  editParse: false,
  isLoading: true,
  workflowAppId: "",
  triggerId: "",
  editSource: false,
  hasDynamicInput: false,
  hasActionDynamicInput: false,
  isParseEngineSelected: false,
};

const createNewWorkflowSlice = createSlice({
  name: "createNewWorkflow",
  initialState,
  reducers: {
    updateAppId: (state, data) => {
      state.triggerId = data.payload;
    },
    updateName: (state, data: PayloadAction<string>) => {
      state.data.name = data.payload;
    },
    updateAppDescription: (state, data) => {
      state.appStatus = StatusType.loading;
      // state.data.appDescription = data.payload;
      // state.data.appOverview = state.data.appOverview;
    },
    updateAppOverview: (state, data) => {
      state.data.appOverview = data.payload;
    },
    updateAppDesc: (state, data) => {
      state.data.appDescription = data.payload;
    },
    updateAppInstall: (state, data) => {
      state.data.appInstall = data.payload;
    },
    appDescriptionSuccess: (state, data) => {
      state.appStatus = StatusType.success;
    },
    updateDescription: (state, data) => {
      state.data.workflowDescription = data.payload;
    },
    updateInstruction: (state, data) => {
      state.data.workflowInstruction = data.payload;
    },
    updateKonnectorSource: (state, data) => {
      state.data.sourceTemplate = data.payload;
    },
    updateLoadingState: (state, data) => {
      state.isLoading = data.payload;
    },
    setGenarateUrlLoading: (state, data) => {
      state.generateUrl = StatusType.loading;
    },
    saveFormSourceConfiguration: (state, data) => {
      state.generateUrl = StatusType.loading;
      state.status = StatusType.loading;
    },
    setGenarateUrlSuccess: (state, data) => {
      state.generateUrl = StatusType.success;
      state.data.webhookUrl = data.payload;
    },
    setGenarateUrlSuccessed: (state, data) => {
      state.generateUrl = StatusType.success;
      state.data.webhookUrl = data.payload;
    },
    getSampleDataLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    generateFormSampleDataLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    getSampleDataSucess: (state, data) => {
      state.status = StatusType.success;
      state.data.trigger.outputFields = data.payload;
      state.data.trigger.outputFields.hasData = true;
    },
    getSampleDataError: (state, data) => {
      state.status = StatusType.success;
      state.errorMessage = data.payload.errorMessage;
      state.data.trigger.outputFields = data.payload;
    },
    resetWebhookLoading: (state, data) => {
      state.resetWebhook = StatusType.loading;
    },
    resetWebhookSuccess: (state) => {
      state.resetWebhook = StatusType.success;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.outputFields.schema = {};
      state.data.trigger.outputFields.hasData = false;
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.rawDataMapping = {};
        lookup.shouldReconfigure = true;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.rawDataMapping = {};
        action.shouldReconfigure = true;
        return action;
      });
    },
    deleteWebhook: (state, data) => {
      state.generateUrl = StatusType.loading;
    },
    deleteWebhookSucess: (state) => {
      state.generateUrl = StatusType.success;
    },
    addFormFields: (state, data) => {
      state.data.trigger.formFields = data.payload;
    },
    updateSourceAppLoading: (state, data: PayloadAction<UpdateSourceAppPayload>) => {
      state.data.workflowType = WorkflowType.Regular;
      state.status = StatusType.loading;
    },
    // updateAppLoading:(state, data: PayloadAction<UpdateSourceAppPayload>)=>{
    //   state.data.workflowType = WorkflowType.Regular;
    //   state.status = StatusType.loading;
    // },
    updateKonnectorType: (state, data: PayloadAction<WorkflowType>) => {
      state.data.workflowType = data.payload;
      state.data.trigger = setTriggerData(data.payload, state.data.trigger);
    },
    updateSourceAppSuccess: (state, data: PayloadAction<UpdateSourceAppPayload>) => {
      state.status = StatusType.idle;
      state.data.trigger.appId = data.payload.appId;
      state.data.trigger.appName = data.payload.appName;
      state.data.trigger.iconUrl = data.payload.iconUrl;
      state.data.trigger.connectionId = data.payload.connectionId || "";
      state.data.trigger.connectionName = data.payload.connectionName || "";
      state.data.trigger.eventId = "";
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.appDetails = data.payload.appDetails;
      state.data.trigger.description = data.payload.appDetails.description;
    },
    updateSourceAccount: (state, data: PayloadAction<UpdateSourceAccountPayload>) => {
      state.data.workflowType = WorkflowType.Regular;
      state.data.trigger.connectionId = data.payload.connectionId;
      state.data.trigger.connectionName = data.payload.connectionName;
      state.data.trigger.eventId = "";
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.inputFields.hasData = false;
      state.data.trigger.inputFields.dataMapping = {};
      state.data.trigger.inputFields.data = [];
    },
    updateSourceEvent: (state, data: PayloadAction<any>) => {
      state.data.trigger.eventId = data.payload.event;
      state.data.trigger.triggerType = data.payload.type;
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.inputFields.hasData = false;
      state.hasDynamicInput = false;
      state.data.trigger.inputFields.dataMapping = {};
      state.data.trigger.inputFields.data = [];
      state.hasDynamicInput = false;
    },
    fetchTriggerInputfieldsLoading: (state, data) => {
      state.hasDynamicInput = true;
      state.status = StatusType.loading;
    },
    fetchTriggerInputfieldsSuccess: (state, data) => {
      state.status = StatusType.success;
      state.data.trigger.inputFields.data = data.payload.fields;
      state.data.trigger.inputFields.hasData = true;
    },
    setSourceEventDataMapping: (state, data) => {
      state.data.trigger.inputFields.dataMapping[data.payload.field] = data.payload.value;
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
    },
    validateAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    validateAccountSuccess: (state, data: PayloadAction<ValidateAccountSuccessResponse>) => {
      state.status = StatusType.success;
      state.data.trigger.isAccountValidated = data.payload.data;
    },
    validateAccountFailed: (state) => {
      state.status = StatusType.failed;
    },
    createAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    createAccountSuccess: (state, data: PayloadAction<string>) => {
      state.data.trigger.connectionId = data.payload;
      state.status = StatusType.success;
    },
    createAccountFailed: (state) => {
      state.status = StatusType.failed;
    },
    fetchOutputFieldsLoading: (state) => {
      state.status = StatusType.loading;
    },
    fetchOutputFieldsSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      state.data.trigger.outputFields.fields = data.payload.sampleData;
      state.data.trigger.outputFields.schema = data.payload.outputFieldsSchema;
      state.data.trigger.outputFields.hasData = true;
    },
    saveSourceConfiguration: (state) => {
      state.hasConfigurationSaved = true;
      state.data.trigger.actionMode = ActionMode.View;
      state.data.lookups = state.data.lookups.map((lookup) => {
        const copyOfLookup = { ...lookup };
        copyOfLookup.shouldReconfigure = true;
        // copyOfLookup.rawDataMapping = {};
        return copyOfLookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        const copyOfAction = { ...action };
        copyOfAction.shouldReconfigure = true;
        // copyOfAction.rawDataMapping = {};
        return copyOfAction;
      });
    },

    closeSourceConfigration: (state) => {
      state.hasConfigurationSaved = true;
      state.currentActionId = "";
      state.data.trigger.actionMode = ActionMode.View;
      state.actionMode = ActionMode.View;
    },

    //Actions
    createAction: (state) => {
      const action: KonnectorAppState<AppRole.Target> = {
        id: uuidv4(),
        actionOrderId: 0,
        appSelectorKey: "",
        appName: "",
        iconUrl: "",
        appSelectorType: AppRole.Target,
        appId: "",
        connectionId: "",
        connectionName: "",
        eventId: "",
        isAccountValidated: false,
        appDetails: {},
        description: "",
        actionMode: ActionMode.Create,
      };
      state.data.actions = [...state.data.actions, action];
      state.currentActionId = action.id;
      state.configurationType = ConfigurationType.Target;
      const findActionIndex = state.data.actions.findIndex((actionItem) => actionItem.id === action.id);
      state.data.actions[findActionIndex].actionOrderId = setActionOrderId(
        state.data.lookups,
        state.data.ruleEngine,
        state.data.parseEngine,
        state.data.actions
      );
      state.actionMode = ActionMode.Edit;
    },
    updateTargetAppLoading: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.loading;
    },
    updateTargetAppSuccess: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.idle;
      const findActionIndex = state.data.actions.findIndex((action) => action.id === data.payload.id);
      state.data.actions[findActionIndex] = {
        ...state.data.actions[findActionIndex],
        appId: data.payload.appId,
        appName: data.payload.appName,
        iconUrl: data.payload.iconUrl,
        connectionId: data.payload.connectionId || "",
        connectionName: data.payload.connectionName || "",
        eventId: "",
        appDetails: data.payload.appDetails,
      };
    },
    updateTargetAccount: (state, data: PayloadAction<UpdateTargetAccountPayload>) => {
      const findActionIndex = state.data.actions.findIndex((action) => action.id === data.payload.id);
      state.data.actions[findActionIndex] = {
        ...state.data.actions[findActionIndex],
        connectionId: data.payload.connectionId,
        connectionName: data.payload.connectionName,
        eventId: "",
      };
    },
    updateTargetEvent: (state, data: PayloadAction<UpdateTargetEventPayload>) => {
      state.hasActionDynamicInput = false;
      const findActionIndex = state.data.actions.findIndex((action) => action.id === data.payload.id);
      state.data.actions[findActionIndex].eventId = data.payload.eventId;
      state.data.actions[findActionIndex].inputFields = {
        appId: "",
        eventId: "",
        sourceId: "",
        data: [],
      };
      state.data.actions[findActionIndex].dynamicInputFields = {
        data: [],
        hasData: false,
        hasDynamicInputFields: data.payload.hasDynamicInputFields,
        dataMapping: {},
      };
      state.data.actions[findActionIndex].rawDataMapping = {};
    },
    fetchDynamicInputFieldsLoading: (state) => {
      state.hasActionDynamicInput = true;
      state.status = StatusType.loading;
    },
    fetchInputFieldsLoading: (state) => {
      state.status = StatusType.loading;
    },
    setActionEventDataMapping: (state, data) => {
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      state.data.actions[findActionIndex].dynamicInputFields.dataMapping[data.payload.field] = data.payload.value;
      state.data.actions[findActionIndex].rawDataMapping = {};
    },
    fetchDynamicInputFieldsSuccess: (state, data: PayloadAction<InputFields>) => {
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      state.status = StatusType.success;
      state.data.actions[findActionIndex].inputFields = data.payload;
    },
    fetchActionDynamicInputFieldsSuccess: (state, data: PayloadAction<InputFields>) => {
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      state.status = StatusType.success;
      state.data.actions[findActionIndex].dynamicInputFields.data = data.payload;
      state.data.actions[findActionIndex].dynamicInputFields.hasData = true;
    },
    saveDataMapping: (state, data: PayloadAction<any>) => {
      const sourceId = state.data.trigger.outputFields.schema.sourceId;
      const isParseEngineSelected = state.isParseEngineSelected;
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      state.data.actions[findActionIndex].actionMode = ActionMode.View;
      const serializeData = serializeDataMapping(
        state.data.actions[findActionIndex].inputFields.data,
        data.payload,
        sourceId
      );
      state.data.actions[findActionIndex].dataMapping = serializeData;
      state.data.actions[findActionIndex].rawDataMapping = data.payload;
      state.data.actions[findActionIndex].shouldReconfigure = false;
      state.actionMode = ActionMode.Save;
      state.currentActionId = "";
    },
    //Lookups
    createLookup: (state) => {
      const lookup: KonnectorAppState<AppRole.Lookup> = {
        id: uuidv4(),
        actionOrderId: 0,
        appSelectorKey: "",
        appName: "",
        iconUrl: "",
        appSelectorType: AppRole.Lookup,
        appId: "",
        connectionId: "",
        connectionName: "",
        eventId: "",
        isAccountValidated: false,
        actionMode: ActionMode.Create,
        description: "",
        appDetails: {},
        inputFields: {
          sourceId: "",
          eventId: "",
          appId: "string",
          data: [
            {
              propName: "email",
              propType: "string",
              isRequired: true,
            },
          ],
        },
        outputFields: {
          hasData: true,
          schema: {
            sourceId: "AMVr3a4T9G:new-contact-trigger",
            eventResponse: {
              data: [
                {
                  propName: "id",
                  propType: "string",
                  isRequired: false,
                },
              ],
            },
          },
        },
      };
      state.data.lookups = [...state.data.lookups, lookup];
      state.currentActionId = lookup.id;
      state.configurationType = ConfigurationType.Lookup;
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === lookup.id);
      state.data.lookups[findActionIndex].actionOrderId = setActionOrderId(
        state.data.lookups,
        state.data.ruleEngine,
        state.data.parseEngine
      );
      state.data.actions = state.data.actions.map((action) => {
        action.actionOrderId += 1;
        return action;
      });
      state.actionMode = ActionMode.Edit;
    },
    updateLookupAppLoading: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.loading;
    },
    updateLookupAppSuccess: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.idle;
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === data.payload.id);
      state.data.lookups[findActionIndex] = {
        ...state.data.lookups[findActionIndex],
        appId: data.payload.appId,
        appName: data.payload.appName,
        iconUrl: data.payload.iconUrl,
        connectionId: data.payload.connectionId || "",
        connectionName: data.payload.connectionName || "",
        eventId: "",
        appDetails: data.payload.appDetails,
      };
    },
    updateLookupAccount: (state, data: PayloadAction<UpdateTargetAccountPayload>) => {
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === data.payload.id);
      state.data.lookups[findActionIndex] = {
        ...state.data.lookups[findActionIndex],
        connectionId: data.payload.connectionId,
        connectionName: data.payload.connectionName,
        eventId: "",
      };
    },
    updateLookupEvent: (state, data: PayloadAction<UpdateLookupEventPayload>) => {
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === data.payload.id);
      state.data.lookups[findActionIndex].eventId = data.payload.eventId;
      state.data.lookups[findActionIndex].inputFields = {
        appId: "",
        eventId: "",
        sourceId: "",
        data: [
          {
            propName: data.payload.attribute || "value",
            propType: "string",
            isRequired: true,
          },
        ],
      };
      state.data.lookups[findActionIndex].rawDataMapping = {};
    },
    saveLookupDataMapping: (state, data: PayloadAction<any>) => {
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === state.currentActionId);
      const serializeData = serializeLookupDataMapping(
        state.data.lookups[findActionIndex].inputFields.data,
        data.payload
      );
      state.data.lookups[findActionIndex].dataMapping = serializeData;
      state.data.lookups[findActionIndex].rawDataMapping = data.payload;
    },
    saveLookupConfigurationLoading: (state, payload) => {
      state.status = StatusType.loading;
    },
    saveLookupConfigurationSuccess: (state, data: PayloadAction<any>) => {
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === state.currentActionId);
      state.data.lookups[findActionIndex].outputFields.fields = data.payload.sampleData;
      state.data.lookups[findActionIndex].outputFields.schema = data.payload.outputFieldsSchema;
      state.data.lookups = state.data.lookups.map((lookup, index) => {
        const copyOfLookup = { ...lookup };
        if (lookup.id === state.currentActionId) {
          copyOfLookup.outputFields.fields = data.payload.sampleData;
          copyOfLookup.outputFields.schema = data.payload.outputFieldsSchema;
          copyOfLookup.shouldReconfigure = false;
        }
        if (index > findActionIndex) {
          // copyOfLookup.rawDataMapping = {};
          copyOfLookup.shouldReconfigure = true;
        }
        return copyOfLookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        const copyOfAction = { ...action };
        // copyOfAction.rawDataMapping = {};
        copyOfAction.shouldReconfigure = true;
        return copyOfAction;
      });
      state.data.lookups[findActionIndex].actionMode = ActionMode.View;
      state.actionMode = ActionMode.Save;
      state.status = StatusType.success;
      state.currentActionId = "";
    },
    editSource: (state) => {
      state.editSource = true;
      state.hasConfigurationSaved = false;
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      if (state.currentActionId && !state.data.actions[findActionIndex]?.dataMapping) {
        state.data.actions = state.data.actions.filter((action) => action.id !== state.currentActionId);
      }
      state.currentActionId = "";
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.ruleEngine = state.data.ruleEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.trigger.actionMode = ActionMode.Edit;
      state.actionMode = ActionMode.Edit;
      state.configurationType = ConfigurationType.Source;
    },
    editAction: (state, data: PayloadAction<string>) => {
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.ruleEngine = state.data.ruleEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.currentActionId = data.payload;
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      state.configurationType = ConfigurationType.Target;
      state.data.actions[findActionIndex].actionMode = ActionMode.Edit;
      state.actionMode = ActionMode.Edit;
    },
    deleteAction: (state, data: PayloadAction<string>) => {
      state.data.actions = state.data.actions.filter((action) => action.id !== data.payload);
    },
    editLookup: (state, data: PayloadAction<string>) => {
      state.currentActionId = data.payload;
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.ruleEngine = state.data.ruleEngine.map((ruleEngine) => {
        ruleEngine.actionMode = ActionMode.View;
        return ruleEngine;
      });
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === state.currentActionId);
      state.data.lookups[findActionIndex].actionMode = ActionMode.Edit;
      state.configurationType = ConfigurationType.Lookup;
      state.actionMode = ActionMode.Edit;
    },
    deleteLookup: (state, data: PayloadAction<string>) => {
      const findActionIndex = state.data.lookups.findIndex((action) => action.id === data.payload);
      if (state.data.lookups[findActionIndex]?.dataMapping) {
        state.data.actions = state.data.actions.map((action) => {
          const copyOfAction = { ...action };
          // copyOfAction.rawDataMapping = {};
          copyOfAction.shouldReconfigure = true;
          return copyOfAction;
        });
      }
      state.data.lookups = state.data.lookups
        .map((lookup, index) => {
          const copyOfLookup = { ...lookup };
          if (lookup.id !== data.payload && index < findActionIndex) {
            copyOfLookup.rawDataMapping = {};
            copyOfLookup.shouldReconfigure = true;
          }
          return copyOfLookup;
        })
        .filter((lookup) => lookup.id !== data.payload);
    },
    saveKonnectorLoading: (state, data) => {
      state.status = StatusType.loading;
      state.saveButtonStatus = true;
      state.workflowAppId = data.payload;
      state.data.workflowAppId = data.payload;
      state.isParseEngineSelected = state.isParseEngineSelected;
    },
    saveKonnectorSuccess: (state) => {
      state.status = StatusType.success;
      state.created = true;
      // state.data.webhookUrl = { url: "", id: "" };
    },
    displayErrorMessage: (state, data: PayloadAction<string>) => {
      state.status = StatusType.failed;
      state.errorMessage = data.payload;
    },
    resetStatus: (state) => {
      state.status = StatusType.idle;
    },
    reset: () => initialState,
    updateCurrentActionId: (state) => {
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      const rawDataMapping = state.data.actions[findActionIndex]?.rawDataMapping;
      state.data.actions[findActionIndex].actionMode = ActionMode.View;
      if (rawDataMapping) {
        if (!Object.keys(rawDataMapping).length) {
          state.data.actions[findActionIndex].shouldReconfigure = true;
          state.currentActionId = "";
        }
      } else {
        state.data.actions = state.data.actions.filter((action) => action.id !== state.currentActionId);
      }
      state.currentActionId = "";
      state.actionMode = ActionMode.View;
    },
    updateCurrentLookupId: (state) => {
      const findActionIndex = state.data.lookups.findIndex((lookup) => lookup.id === state.currentActionId);
      state.data.lookups[findActionIndex].actionMode = ActionMode.View;
      const rawDataMapping = state.data.lookups[findActionIndex].rawDataMapping;
      if (rawDataMapping) {
        if (!Object.keys(rawDataMapping).length) {
          state.data.lookups[findActionIndex].shouldReconfigure = true;
          state.currentActionId = "";
        }
        // } else {
        //   const findActionIndexNew = state.data.lookups.findIndex((action) => action.id === state.currentActionId);
        //   state.data.lookups = state.data.lookups.map((lookup, index) => {
        //     const copyOfLookup = { ...lookup };
        //     if (lookup.id !== state.currentActionId && index > findActionIndexNew) {
        //       copyOfLookup.rawDataMapping = {};
        //       copyOfLookup.shouldReconfigure = true;
        //     }
        //     return copyOfLookup;
        //   });
        //   state.data.actions = state.data.actions.map((action) => {
        //     const copyOfAction = { ...action };
        //     copyOfAction.rawDataMapping = {};
        //     copyOfAction.shouldReconfigure = true;
        //     return copyOfAction;
        //   });
        // }
      } else {
        state.data.lookups = state.data.lookups.filter((action) => action.id !== state.currentActionId);
      }
      state.currentActionId = "";
      state.actionMode = ActionMode.View;
    },
    updateRuleCurrentActionId: (state) => {
      const rule = state.data.ruleEngine.findIndex((action) => action.id === state.currentActionId);
      const ruleData = state.data.ruleEngine[rule].ruleConfigration;
      state.data.ruleEngine[rule].actionMode = ActionMode.View;
      if (ruleData) {
        if (!Object.keys(ruleData).length) {
          state.data.ruleEngine = state.data.ruleEngine.filter((action) => action.id !== state.currentActionId);
          state.currentActionId = "";
        } else {
          state.currentActionId = "";
          state.editRule = false;
        }
      }
    },
    //Rule Engine
    createRule: (state) => {
      const prevCurrentActionId = state.currentActionId;
      const configurationType = state.configurationType;
      if (configurationType === "target") {
        state.data.actions = state.data.actions.filter((action) => action.id !== prevCurrentActionId);
      }
      state.data.lookups = state.data.lookups.filter((lookup) => lookup.id !== prevCurrentActionId);
      const ruleEngine = {
        id: uuidv4(),
        ruleConfigration: {},
        actionOrderId: 0,
        appSelectorType: "ruleEngine",
        actionMode: ActionMode.Create,
      };
      state.data.ruleEngine = [...state.data.ruleEngine, ruleEngine];
      state.currentActionId = ruleEngine.id;
      const findActionIndex = state.data.ruleEngine.findIndex((action) => action.id === ruleEngine.id);
      state.data.ruleEngine[findActionIndex].actionOrderId = setActionOrderId(
        state.data.lookups,
        state.data.ruleEngine,
        state.data.parseEngine
      );
      state.configurationType = ConfigurationType.RuleEngine;
      state.actionMode = ActionMode.Edit;
    },
    addRuleConfig(state, data) {
      const findActionIndex = state.data.ruleEngine.findIndex((action) => action.id === state.currentActionId);
      state.data.ruleEngine[findActionIndex].ruleConfigration = data.payload;
      state.data.ruleEngine[findActionIndex].actionMode = ActionMode.View;
      state.data.actions = state.data.actions.map((action) => {
        action.actionOrderId += 1;
        return action;
      });
      state.currentActionId = "";
      state.editRule = false;
      state.actionMode = ActionMode.View;
    },
    deleteRuleConfig(state, data) {
      state.data.ruleEngine = state.data.ruleEngine.filter((action) => action.id !== data.payload);
    },
    editRuleConfig: (state, data: PayloadAction<string>) => {
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.ruleEngine = state.data.ruleEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.currentActionId = data.payload;
      const findActionIndex = state.data.ruleEngine.findIndex((action) => action.id === state.currentActionId);
      state.data.ruleEngine[findActionIndex].actionMode = ActionMode.Edit;
      if (state.currentActionId && !state.data.ruleEngine[findActionIndex].ruleConfigration) {
        state.data.ruleEngine = state.data.ruleEngine.filter((action) => action.id !== state.currentActionId);
      }
      state.configurationType = ConfigurationType.RuleEngine;
      state.editRule = true;
    },
    resetWebhook: (state, data) => {
      state.status = StatusType.loading;
    },
    createParse: (state) => {
      const prevCurrentActionId = state.currentActionId;
      const configurationType = state.configurationType;
      if (configurationType === "target") {
        state.data.actions = state.data.actions.filter((action) => action.id !== prevCurrentActionId);
      }
      state.data.lookups = state.data.lookups.filter((lookup) => lookup.id !== prevCurrentActionId);
      const parseEngine = {
        id: uuidv4(),
        parseConfigration: {},
        actionOrderId: 0,
        appSelectorType: AppRole.ParseEngine,
        actionMode: ActionMode.Create,
        outputFields: {
          hasData: true,
          schema: {
            sourceId: "Genric:Parse-engine-data",
            eventResponse: {
              sourceId: "Genric:Parse-engine-data",
              data: [
                {
                  propName: "parse-engine",
                  propType: "string",
                  isRequired: false,
                },
              ],
            },
          },
        },
      };
      state.data.parseEngine = [...state.data.parseEngine, parseEngine];
      state.currentActionId = parseEngine.id;
      const findActionIndex = state.data.parseEngine.findIndex((action) => action.id === parseEngine.id);
      state.data.parseEngine[findActionIndex].actionOrderId = setActionOrderId(
        state.data.lookups,
        state.data.ruleEngine,
        state.data.parseEngine
      );
      state.configurationType = ConfigurationType.ParseEngine;
      state.actionMode = ActionMode.Edit;
    },
    updateParseCurrentActionId: (state) => {
      const rule = state.data.parseEngine.findIndex((action) => action.id === state.currentActionId);
      const ruleData = state.data.parseEngine[rule].parseConfigration;
      state.data.parseEngine[rule].actionMode = ActionMode.View;
      if (ruleData) {
        if (!Object.keys(ruleData).length) {
          state.data.parseEngine = state.data.parseEngine.filter((action) => action.id !== state.currentActionId);
          state.currentActionId = "";
        } else {
          state.currentActionId = "";
          state.editRule = false;
        }
      }
    },
    addParseConfig(state, data) {
      const findActionIndex = state.data.parseEngine.findIndex((action) => action.id === state.currentActionId);
      state.data.parseEngine[findActionIndex].parseConfigration = data.payload;
      state.data.parseEngine[findActionIndex].actionMode = ActionMode.View;
      state.data.actions = state.data.actions.map((action) => {
        action.actionOrderId += 1;
        return action;
      });
      state.currentActionId = "";
      state.editParse = false;
      state.actionMode = ActionMode.View;
      const id = state.data.parseEngine[findActionIndex].id;
      const propName = `Parsed-output-${id}`;
      state.data.parseEngine[findActionIndex].outputFields = {
        hasData: true,
        schema: {
          sourceId: `Generic:Parse-engine-${id}`,
          eventResponse: {
            sourceId: `Generic:Parse-engine-${id}`,
            data: [
              {
                propName: propName,
                propType: "string",
                isRequired: false,
              },
            ],
          },
        },
      };
    },
    setParseEngineSelected(state, data) {
      state.isParseEngineSelected = data.payload;
    },
    deleteParseConfig(state, data) {
      state.data.parseEngine = state.data.parseEngine.filter((action) => action.id !== data.payload);
    },
    editParseConfig: (state, data: PayloadAction<string>) => {
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.ruleEngine = state.data.ruleEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.parseEngine = state.data.parseEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.currentActionId = data.payload;
      let findActionIndex = state.data.parseEngine.findIndex((action) => action.id === state.currentActionId);
      state.data.parseEngine[findActionIndex].actionMode = ActionMode.Edit;
      if (state.currentActionId && !state.data.parseEngine[findActionIndex]?.parseConfigration) {
        state.data.parseEngine = state.data.parseEngine.filter((action) => action.id !== state.currentActionId);
      }
      state.configurationType = ConfigurationType.ParseEngine;
      state.editParse = true;
    },
  },
});

export const {
  updateAppId,
  updateName,
  updateAppOverview,
  updateAppDescription,
  appDescriptionSuccess,
  updateDescription,
  updateInstruction,
  setGenarateUrlSuccess,
  setGenarateUrlSuccessed,
  setGenarateUrlLoading,
  getSampleDataLoading,
  getSampleDataSucess,
  getSampleDataError,
  resetWebhookLoading,
  resetWebhookSuccess,
  updateLoadingState,
  deleteWebhook,
  deleteWebhookSucess,
  addFormFields,
  saveFormSourceConfiguration,
  generateFormSampleDataLoading,
  updateSourceAppLoading,
  updateSourceAppSuccess,
  setSourceEventDataMapping,
  fetchTriggerInputfieldsLoading,
  fetchTriggerInputfieldsSuccess,
  fetchActionDynamicInputFieldsSuccess,
  updateKonnectorType,
  updateSourceAccount,
  updateSourceEvent,
  updateTargetAppLoading,
  updateTargetAppSuccess,
  updateTargetAccount,
  updateTargetEvent,
  updateCurrentLookupId,
  validateAccountLoading,
  validateAccountSuccess,
  validateAccountFailed,
  createAccountLoading,
  createAccountSuccess,
  createAccountFailed,
  fetchOutputFieldsLoading,
  fetchOutputFieldsSuccess,
  fetchInputFieldsLoading,
  fetchDynamicInputFieldsLoading,
  fetchDynamicInputFieldsSuccess,
  setActionEventDataMapping,
  createAction,
  saveDataMapping,
  saveSourceConfiguration,
  closeSourceConfigration,
  editSource,
  editAction,
  updateRuleCurrentActionId,
  updateLookupAccount,
  updateLookupEvent,
  updateLookupAppLoading,
  updateLookupAppSuccess,
  saveLookupDataMapping,
  createLookup,
  editLookup,
  deleteLookup,
  createRule,
  saveLookupConfigurationLoading,
  saveLookupConfigurationSuccess,
  resetStatus,
  deleteAction,
  saveKonnectorLoading,
  saveKonnectorSuccess,
  displayErrorMessage,
  resetWebhook,
  reset,
  updateCurrentActionId,
  addRuleConfig,
  deleteRuleConfig,
  editRuleConfig,
  createParse,
  addParseConfig,
  editParseConfig,
  updateParseCurrentActionId,
  deleteParseConfig,
  updateKonnectorSource,
  updateAppDesc,
  updateAppInstall,
  setParseEngineSelected,
} = createNewWorkflowSlice.actions;

export default createNewWorkflowSlice.reducer;
