import { StatusType } from "state/new-konnector/types";

export enum FilterType {
  ALL = "all",
  ACTIVE = "active",
  freshdesk = "freshdesk",
  stripe = "stripe",
}

export enum SortByType {
  DEFAULT = "",
  STATUS = "status",
  ACTIVE = "active",
  INACTIVE = "inactive",
  CREATED = "dtCreated",
  LATEST = "latest",
  OLDEST = "oldest",
}

export interface InitialState {
  sortBy: SortByType;
  filterBy: FilterType;
  status: StatusType;
  currentPage: number;
  appType: string;
  konnectorState: KonnectorState;
  hasActivated: boolean;
  currentActionId: string;
  hasDeleted: boolean;
  errorMessage: string;
  selectedKonnectorId: any;
}

export enum KonnectorState {
  NewKonnectorState = "createNewKonnector",
  EditKonnectorState = "editKonnector",
  // NewKon = "createNewKon",
}
export const ErrorStatus = [400, 401, 500, 403];
