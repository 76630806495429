import React from "react";
import { AppsProps } from "../types";
import AppItem from "./AppItem";
import { Droppable } from "react-beautiful-dnd";
import { connect } from 'react-redux';

interface AppListProps extends AppsProps {
  embeddedApps: any;
  isTrue: boolean
}

const AppList: React.FC<AppListProps> = ({ apps, embeddedApps, isTrue }) => {

  const renderApps = () => {
    return apps?.map((app, index) => <AppItem key={app.id} app={app} embeddedApps={embeddedApps} index={index} isTrue={isTrue} />);
  };


  return (
    <div
      className="grid mt-5 grid-cols-4 gap-4"
    >
      {renderApps()}
    </div>

  );
};

export default AppList;
