import React from "react";

const ActiveKonnectors: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 26.62 13.86"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style>
            {`.cls-1 {
        fill: #e9eaec;
      }

      .cls-2 {
        fill: #374252;
     `}
          </style>
        </defs>
        <g id="konnector">
          <g id="konnectors">
            <g id="konn_sub">
              <g>
                <rect className="cls-1" x="0" y="0" width="26.62" height="13.86" rx="6.93" ry="6.93" />
                <circle className="cls-2" cx="18.97" cy="6.98" r="5.25" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
export default ActiveKonnectors;
