import { AppObject } from "pages/Apps/types";
import React from "react";
import Controls from "../../Controls";
import { useDispatch } from "react-redux";
import { setApp } from "state/workFlows/slices";
import { useHistory } from "react-router-dom";


interface AppItemProps {
  app: AppObject;
  embeddedApps: any;
  index: any
  isTrue: any
}

const AppItem: React.FC<AppItemProps> = ({ app, embeddedApps, index, isTrue }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const appId = app.id
  // const controlsComponent = e.target.closest('.controls-component');
  return (
    <>
      <a
        onClick={(e) => {
          const targetElement = e.target as Element | null;
          const controlsComponent = targetElement?.closest('.controls-component');
          if (!controlsComponent) {
            history.push(`/KompApps/templates/?appId=${app.id}`);
          }
        }}
        // href={`/KompApps/templates?appId=${app.id}`}
        className="relative flex h-[125px] w-[100%] flex-col items-start cursor-pointer space-y-2 transition-all border-[1px] border-gray-300 rounded-2xl p-4 ">
        <div className="w-7">
          <img src={app.iconUrl} alt="" />
        </div>
        <div className="space-y-1 flex flex-col items-start">
          <h3 className="text-base font-semibold">{app.appName}</h3>
          <p className="text-xs font-extralight "> {app.description ? (
            app.description.length > 45 ? (
              `${app.description.substring(0, 45)}...`
            ) : (
              app.description
            )
          ) : ""}</p>
        </div>
        <div className="absolute right-3 controls-component">
          <Controls appId={app.id} status={app.status ? app.status : ""} embeddedApps={embeddedApps} />
        </div>
      </a>
    </>
  );
};

export default AppItem;
