import React, { useEffect } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { StatusType, KonnectorType } from "state/new-konnector/types";
import { useParams } from "react-router-dom";
import { setAccountModal, setnewmodel } from "state/account/slices";

const SelectApp: React.FC<AppsProps> = ({ apps, konnectorSlice, konnectorState }) => {
  const selectedApp = useAppSelector((state) => state[konnectorState].data.trigger.appId);
  const apiStatus = useAppSelector((state) => state[konnectorState].status);

  const dispatch = useAppDispatch();
  const getApp = (value) => {
    return apps.find((app) => app.id === value);
  };
  const { other } = useParams<any>();

  const onChangeAppHandler = (data) => {
    if (!data.value) {
      return dispatch(konnectorSlice.updateSourceAppLoading({ appId: data.value }));
    }
    const app = getApp(data.value);
    switch (data.label) {
      case "webhook" || other:
        return dispatch(konnectorSlice.updateKonnectorType(KonnectorType.WebHook));
      case "form":
        return dispatch(konnectorSlice.updateKonnectorType(KonnectorType.Form));
    }

    dispatch(konnectorSlice.updateSourceAppLoading({ appId: data.value, iconUrl: app.iconUrl, appName: app.appName }));
  };
  const renderApps = () => {
    return apps.map((app) => ({
      label: app.appName,
      value: app.id,
    }));
  };

  useEffect(() => {
    return () => {
      // reset the status
      if (apiStatus == StatusType.success && selectedApp) {
        dispatch(konnectorSlice.resetStatus());
      }
    };
  }, [selectedApp]);
  useEffect(() => {
    if (other) {
      dispatch(konnectorSlice.updateKonnectorType(KonnectorType.WebHook));
    }
  }, [selectedApp]);

  return (
    <div className="mb-3  xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Apps</label>
      <Select
        options={renderApps()}
        value={renderApps().filter(function (option) {
          return option.value === selectedApp;
        })}
        onChange={onChangeAppHandler}
      />
    </div>
  );
};

export default SelectApp;
