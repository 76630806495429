import React from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";

const SelectEvent: React.FC<any> = ({ sourceApp, sourceEvent, apps, konnectorSlice }) => {
  const workflowState = useAppSelector((state) => state.workflows.workflowState);
  const currentActionId = useAppSelector((state) => state[workflowState].currentActionId);
  const dispatch = useAppDispatch();

  const onChangeEventHandler = ({ value: eventId, attribute }) => {
    dispatch(konnectorSlice.updateLookupEvent({ eventId, id: currentActionId, attribute }));
  };
  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };
  const renderEvents = () => {
    return getApp().lookups.map((event) => ({
      label: event.name,
      value: event.id,
      attribute: event.attribute || "",
      disabled: event.disabled ? event.disabled : false,
    }));
  };

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Event</label>
      <Select
        options={renderEvents().filter((event) => !event.disabled)}
        value={renderEvents().filter(function (option) {
          return option.value === sourceEvent;
        })}
        onChange={onChangeEventHandler}
      />
    </div>
  );
};

export default SelectEvent;
