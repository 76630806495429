import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import Filters from "./components/Filters";
import { getEventLogsCurrentPage, getEventLogsSortBy } from "state/event-logs/selectors";
import { updateFilter, updateSortBy } from "state/event-logs/slices";
import EventLogs from "./components/EventLogs";
import Spinner from "common/components/Spinner";
import Pagination from "./components/EventLogs/Pagination";
import { getEventLogsFilters } from "state/Embedded-markteplace/event-logs/selectors";
import SortBy from "./components/EventLogs/SortBy";
import { useAllKonnector, useAdminEventLogs } from "api/adminEventLogsApi";

const EventLogsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const currentPage = useAppSelector(getEventLogsCurrentPage);
  const sortBy = useAppSelector(getEventLogsSortBy);
  const filterParam = useAppSelector(getEventLogsFilters);
  const { data: eventLogs, error: eventLogsError } = useAdminEventLogs(currentPage, sortBy, filterParam);
  const { data: konnectors, error: konnectorError } = useAllKonnector();
  const [isOpen, setOpen] = useState(false);
  const filters = useAppSelector((state) => state.adminEventLogs.filters);
  console.log({ eventLogs });
  useEffect(() => {
    const params = new URLSearchParams({
      sortBy,
      konnectorId: filters.konnectorName,
      appName: filters.appName,
      userId: filters.userId,
      startDate: filters?.date?.start,
      endDate: filters?.date?.end,
    });

    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [sortBy, filters]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sortBy: any = query.get("sortBy") || "dtCreated";
    const filterBy: any = query.get("konnectorId") || "";
    dispatch(updateSortBy(sortBy));
    dispatch(updateFilter(filterBy));
  }, []);

  const toggleMenu = () => {
    setOpen(!isOpen);
  };
  // const onChangeKonnectorHandler = useCallback((konnectorValue) => {
  //   dispatch(setFilters({ konnectorName: konnectorss, appName: app, date: date, userId: user }));
  //   setKonnector(konnectorValue.value);
  // }, [dispatch, konnectorss, app, date, user]);

  if (eventLogsError) return <p>Failed to load konnectors</p>;
  if (!eventLogs || !konnectors) return <Spinner />;

  let konnector = { data: { items: konnectors.data } };

  return (
    <><section className=" max-h-full overflow-hidden w-full">
      <div className="relative top-20 ml-[4%]">
        <div className="justify-between items-start w-[100%] flex-row">
          <div className={`bg-[#ebeff3] w-[100%] shadow-sm p-2 h-14`}>
            <div className="flex ml-auto justify-end">
              <SortBy />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="komp-secondary-color"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="black"
                className={`w-10 h-10 cursor-pointer ml-2 shadow-md bg-[#f3f5f7] p-2 rounded-md ${isOpen && ""} : "transition-transform"
          }`}
                onClick={toggleMenu}
              >
                {!isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                ) : (
                  <path d="M6 18L18 6M6 6l12 12" />
                )}
              </svg>
            </div>
          </div>
          <div className="flex w-full pb-10 h-[calc(100vh-6rem)] overflow-hidden justify-between flex-row">
            <div className={`flex w-full m-5 transition-all mt-2`}>
              <div className="overflow-x-auto w-full">
                <table className="w-full text-sm text-left text-gray-500 border mt-1">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4"><div className="flex items-center">Konnectors </div>
                      </th>
                      <th scope="col" className="px-4 py-4"><div className="flex items-center">Apps</div>
                      </th>
                      <th scope="col" className="px-4 py-4"><div className="flex items-center">User</div>
                      </th>
                      <th scope="col" className="px-4 py-4">Created</th>
                      <th scope="col" className="px-4 py-4">Task Executed</th>
                      <th scope="col" className="px-4 py-4"><div className="flex items-center">Status </div></th>
                      {/* <th scope="col" className="px-4 py-4"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    <EventLogs data={eventLogs.data} konnector={konnector} />
                  </tbody>
                </table>
              </div>
            </div>
            <div className={`w-full ${isOpen ? "md:w-[22%] h-[calc(100vh-1rem)]" : "md:w-0"}`}>
              {isOpen && <Filters />}
            </div>
          </div>
        </div>
      </div>
    </section><div className="mt-2 mr-2">
        {eventLogs.data.items.length > 0 && <Pagination total={eventLogs.data.totalItems} />}
      </div></>
  );
};

export default EventLogsPage;
