import React, { useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";

const SelectInputSchema: React.FC<any> = ({ field, konnectorSlice, index, dynamicInputfields }) => {
  const dispatch = useAppDispatch();

  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const fieldValue = dynamicInputfields.dataMapping[field.propName] || "";
  const dependentField = field.dependentTo;
  const dependentFieldValue = dynamicInputfields.dataMapping[dependentField] || "";
  const propValues = field.propValues ? field.propValues : field.propValue;
  const renderFields = propValues.find((val) => val.value === fieldValue) || { label: fieldValue, value: fieldValue };
  const renderEvents = () => {
    let choices = propValues;
    if (propValues.length === 0) {
      choices = [{ label: fieldValue, value: fieldValue }];
    }
    return choices.map((prop) => {
      return { label: prop.label, value: prop.value };
    });
  };

  const onChangeHandler = (e) => {
    dispatch(
      konnectorSlice.setActionEventDataMapping({
        field: field.propName,
        value: e.value,
        actionType: "actions",
      })
    );
    if (index === dynamicInputfields.data.length - 1) {
      dispatch(konnectorSlice.fetchInputFieldsLoading({ actionType: "actions" }));
    }
  };

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">{`Select ${field.label}`}</label>
      <Select
        options={renderEvents()}
        value={renderFields}
        onMenuOpen={() => {
          if (dependentField && dependentFieldValue && (!propValues || propValues.length === 0)) {
            dispatch(
              konnectorSlice.fetchDynamicFieldsLoading({
                actionType: "actions",
                eventId: field.lookupId,
                eventData: dependentFieldValue,
                field: field.propName,
              })
            );
          }
        }}
        onChange={(e: any) => {
          onChangeHandler(e);
        }}
      />
    </div>
  );
};

export default SelectInputSchema;
