const Logo = () => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 87.41 124.73"
        className="w-9 h-9"
      >
        <defs>
          <linearGradient id="linear-gradient" x1="61.9" y1="38.77" x2="61.9" y2="38.69" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#2193b0" />
            <stop offset="1" stopColor="#6dd5ed" />
          </linearGradient>
          <linearGradient id="linear-gradient-2" x1="60.67" y1="38.69" x2="60.67" y2="38.69" />
          <linearGradient id="linear-gradient-3" x1="58.92" y1="43.26" x2="58.92" y2="0" />
          <linearGradient
            id="linear-gradient-4"
            x1="-1054.88"
            y1="-928.14"
            x2="-1054.88"
            y2="-928.22"
            gradientTransform="translate(-751.11 1257.12) rotate(83.32)"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-5"
            x1="-1056.11"
            y1="-928.21"
            x2="-1056.11"
            y2="-928.22"
            gradientTransform="translate(-751.11 1257.12) rotate(83.32)"
            xlinkHref="#linear-gradient"
          />
          <linearGradient
            id="linear-gradient-6"
            x1="-1057.86"
            y1="-923.65"
            x2="-1057.86"
            y2="-966.91"
            gradientTransform="translate(-751.11 1257.12) rotate(83.32)"
            xlinkHref="#linear-gradient"
          />
          <linearGradient id="linear-gradient-7" x1="0" y1="62.25" x2="45.47" y2="62.25" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#034d90" />
            <stop offset="1" stopColor="#03062a" />
          </linearGradient>
        </defs>
        <g id="svg">
          <g>
            <path className="cls-1" d="M24.07,43.57s.08,0,.12,0c-.06-.01-.12-.02-.12,0Z" />
            <g>
              <path className="cls-2" d="M60.8,38.69c.72,0,1.45,.01,2.19,.08,0,0-.89-.09-2.19-.08Z" />
              <path className="cls-5" d="M60.54,38.69c.09,0,.18,0,.26,0-.09,0-.18,0-.26,0Z" />
              <path
                className="cls-3"
                d="M67.73,0c-10.8,0-19.56,8.71-19.67,19.48,0,.07-.01,.13-.01,.2,0,.86,.07,1.71,.18,2.54h-.02c-.94,10.41-7.99,19.08-17.77,21.03h0s11.7-1.78,11.7-1.78l13.13-2.01c1.82-.57,3.78-.74,5.27-.77,.09,0,.18,0,.26,0,1.3-.01,2.19,.08,2.19,.08h0s0,0,0,0c.07,.02,.13,.03,.13,.03,.01,0,.02,0,.04,0,1.47,.35,2.99,.55,4.56,.55,10.87,0,19.68-8.81,19.68-19.68S78.6,0,67.73,0Z"
                fill="url(#linear-gradient-6)"
              />
            </g>
            <g>
              <path className="cls-4" d="M48.04,100.38c.09,.72,.15,1.44,.18,2.19,0,0-.01-.9-.18-2.19Z" />
              <path className="cls-8" d="M48.01,100.12c.01,.09,.02,.18,.03,.26-.01-.09-.02-.18-.03-.26Z" />
              <path
                className="cls-6"
                d="M87.27,102.76c-1.26-10.73-10.92-18.42-21.64-17.27-.07,0-.13,0-.2,.01-.86,.1-1.69,.27-2.51,.48v-.02c-10.45,.28-19.88-5.72-22.95-15.21h0s3.14,11.41,3.14,11.41l3.52,12.81c.78,1.74,1.18,3.67,1.38,5.15,.01,.09,.02,.17,.03,.26,.16,1.29,.18,2.18,.18,2.19h0s0,0,0,0c-.01,.08-.02,.13-.02,.13,0,.01,0,.03,0,.04-.18,1.5-.2,3.03-.02,4.6,1.26,10.8,11.04,18.53,21.84,17.26,10.8-1.26,18.53-11.04,17.26-21.84Z"
                fill="url(#linear-gradient-6)"
              />
            </g>
            <path
              className="cls-7"
              d="M42.13,41.47l-11.7,1.79h0c-1.98,.39-4.07,.52-6.24,.32-.04,0-.08,0-.12,0h-.01c-1.41-.31-2.87-.49-4.37-.49C8.81,43.08,0,51.89,0,62.76s8.81,19.68,19.68,19.68c1.85,0,3.63-.27,5.33-.75,.03,0,.06-.02,.09-.03,.65-.14,5.07-.59,5.46-.63,3.64-.35,7.26,.5,10.69,1.67,.75,.25,1.5,.54,2.24,.84l-3.52-12.8c-.21-1.19-.7-2.83-.79-4.17-.02-.26-.06-.51-.07-.78,.15-.99,.26-2,.26-3.03,0-1.13-.12-2.23-.3-3.31,1.78-11.22,6.4-18.49,6.4-18.49l-3.34,.51Z"
              fill="url(#linear-gradient-7)"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Logo;
