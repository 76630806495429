import Login from "../pages/Login";
import Signup from "../pages/Signup";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ResetPassword from "../pages/Login/ResetPassword";
import { RouteProperties } from "./interfaces";
import EmbedSignUp from "pages/EmbeddedMarketPlace/Auth/SignUp";
import EmbeddedLogin from "pages/EmbeddedMarketPlace/Auth/Login";

const routes: RouteProperties[] = [
  {
    component: EmbedSignUp,
    exact: true,
    path: "/embedded/signup",
  },
  {
    component: EmbeddedLogin,
    exact: true,
    path: "/embedded/login",
  },
  {
    component: Signup,
    exact: true,
    path: "/register",
  },
  {
    component: Login,
    exact: true,
    path: "/login",
  },
  {
    component: Signup,
    exact: true,
    path: "/external_signup",
  },
  {
    component: Login,
    exact: true,
    path: "/external_login",
  },
  {
    component: ForgotPassword,
    exact: true,
    path: "/forgot-password",
  },
  {
    component: ResetPassword,
    exact: true,
    path: "/reset-password",
  },
];

export default routes;
