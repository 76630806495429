import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";

const Password: React.FC<Options> = ({ pattern, label, name, required, register, errors, isDisabled = false }) => {
  console.log(name);

  return (
    <div>
      <div className="flex h-full items-center space-x-1 justify-start">
        <input
          type="checkbox"
          disabled={isDisabled}
          id={name}
          //  checked={true}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm placeholder-gray-900 ml-2"
          placeholder={"Enter " + name}
          {...register(name, {
            required: { value: required.value, message: required.message },
            pattern: {
              value: new RegExp(pattern.value, pattern.flags),
              message: pattern.message,
            },
          })}
        />
        <div className="text-sm">{label}</div>
      </div>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      />
    </div>
  );
};

export default Password;
