import React, { useEffect, useState } from "react";
import SingleSelectField from "common/components/FormFields/SingleSelect";
import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { setSourceIdLabel } from "utils/konnectorUtils";
import { getSourceIds } from "utils/konnectorStateUtils";

export default function SourceTypes({ field, register, control, watch, errors, setInputValue }) {
  const [selectFieldType, setSelectFieldType] = useState(true);
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  // const getSourceIds = (state) => {

  //   const triggerSourceId = {
  //     label: setSourceIdLabel(state[konnectorState].data.trigger.outputFields.schema.sourceId),
  //     value: state[konnectorState].data.trigger.outputFields.schema.sourceId,
  //   };
  //   const lookupSourceIds = state[konnectorState].data.lookups.map((lookup) => {
  //     return {
  //       label: setSourceIdLabel(lookup.outputFields.schema.sourceId),
  //       value: lookup.outputFields.schema.sourceId,
  //     };
  //   });

  //   const parseSourceIds = state[konnectorState].data.parseEngine.map((lookup) => {
  //     return {
  //       label: setSourceIdLabel(lookup.outputFields.schema.sourceId),
  //       value: lookup.outputFields.schema.sourceId,
  //     };
  //   });

  //   return [triggerSourceId, ...lookupSourceIds, ...parseSourceIds];
  // };

  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);

  useEffect(() => {
    if (fieldType === "fixed") {
      setSelectFieldType(false);
    } else {
      setSelectFieldType(true);
    }
  }, [fieldType]);

  const sourceIds = useAppSelector((state) => {
    return getSourceIds(state, true);
  });

  const getFieldOptions = () => {
    return new Field(field).format();
  };
  return (
    <div className="flex justify-center w-[30%] self-end">
      <div className="mb-3 w-full">
        {selectFieldType && (
          <SingleSelectField
            placeholder="Choose source type"
            choices={sourceIds}
            {...getFieldOptions()}
            name={field.propName + "__field_source_type"}
            register={register}
            errors={errors}
          />
        )}
      </div>
    </div>
  );
}
