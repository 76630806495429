import useSWR from "swr";
import { authRequest } from "./libs/fetch";

const options: any = {
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
  withCredentials: true,
};
export function loginApi(type) {
  return authRequest.get(`/${type}`);
}

export function logoutApi() {
  return authRequest.get(`/embbed_logout`, options);
}

export function useCurrentUserApi() {
  return useSWR(`/users/current_user`, authRequest);
}

export function currentUserApi() {
  return authRequest.get(`/users/current_user`, options);
}
export function useGetAllUser(payload) {
  return useSWR(`/end_users/allUsers`, payload, options);
}

export function getAllUser(payload) {
  return authRequest.get(`/end_users/allUsers`, {
    headers: {
      "x-api-key": payload.payload,
    },
    withCredentials: true,
  });
}

export function saveUserApi(userId, payload) {
  return authRequest.put(`/users/${userId}`, payload, options);
}

export function signInApi(payload) {
  console.log(payload, "payload");
  return authRequest.post(`/register`, payload, options);
}

export function embedSignInApi(payload) {
  return authRequest.post(`/embedded/signup`, payload, options);
}

export function loginLocalApi(payload) {
  return authRequest.post(`/login`, payload, options);
}
export function EmbeddedloginLocalApi(payload) {
  return authRequest.post(`/login`, payload, options);
}

export function forgotPasswordApi(payload) {
  return authRequest.post(`/embedded_forgot_password`, payload, options);
}
export function resetPasswordApi(token, payload) {
  return authRequest.post(`/embedded_reset_password?token=${token}`, payload, options);
}

export function verifyUserApi(payload, tenantId) {
  const requestOptions = {
    ...options,
    headers: {
      ...options.headers,
      tenantId: tenantId,
    },
  };
  return authRequest.post(`/send_verification_email`, payload, requestOptions);
}
