import { StatusType } from "state/new-konnector/types";

export enum SortByType {
  DEFAULT = "",
  STATUS = "status",
  CREATED = "dtCreated",
}

export interface InitialState {
  sortBy: SortByType;
  filterBy: string;
  status: StatusType;
  currentPage: number;
  hasActivated: boolean;
  currentActionId: string;
  hasDeleted: boolean;
  filters?: any;
  resetCalendar?: boolean
}
