import { useKonnectorTrialPlan } from "api/konnectorsApi";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteBulkKonnectorLoading, resetStatus } from "state/payment/slices";
import { StatusType } from "state/new-konnector/types";
import { useAppSelector } from "state/store";
import Instruction from "./Instruction";
import RemoveKonnector from "./RemoveKonnectors";
import { PaymentTypes } from "./types";
import Spinner from "common/components/Spinner";

const Downgrade: React.FC<any> = ({ text, konnector, visible, closeModalHandler }) => {
  const [unRemovedKonnectors, setUnRemovedKonnectos] = useState([]);
  const [myType, setMyType] = useState("instruction");
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const freePlanStatus = useAppSelector((state) => state.payments.status);
  const dispatch = useDispatch();
  const handleOnChange = () => {
    if (konnector.data.items.length > 5) {
      setMyType("removeKonnectors");
    } else {
    }
  };
  const onEnableKonnector = (findNonSelectedKonnector) => {
    setUnRemovedKonnectos(findNonSelectedKonnector);
    setMyType("enableKonnectors");
  };
  const handleClick = () => {
    setMyType("instruction");
  };
  const history = useHistory();

  return (
    <>
      <RemoveKonnector
        visible={visible}
        text={text}
        data={konnector.data}
        onChange={onEnableKonnector}
        closeModalHandler={closeModalHandler}
        goBack={handleClick}
        konnector={konnector}
      />
    </>
  );
};

export default Downgrade;
