import React from "react";

const ParseEngine: React.FC<any> = ({ fill, className }) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 lucide lucide-file-search" viewBox="0 0 24 24" fill="none" stroke="#fff9f5" stroke-width="1.6875" stroke-linecap="round" stroke-linejoin="round" ><path d="M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v3" /><polyline points="14 2 14 8 20 8" /><path d="M5 17a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" /><path d="m9 18-1.5-1.5" /></svg>
        </>
    );
};

export default ParseEngine;