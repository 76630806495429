import React from "react";
import { AccountsProps } from "../types";
import AccountItem from "./AccountItem";
import { useAppDispatch, useAppSelector } from "state/store";
import { useParams } from "react-router";
import { useApp } from "api/appsApi";
import CreateAccount from "common/containers/Account/CreateAccount";
import { setAccountModal } from "state/account/slices";
interface AccountListProps extends AccountsProps {
  pageNumber: number;
  setPageNumber: any;
  app: {
    iconUrl: string;
  };
}

const AccountList: React.FC<AccountListProps> = ({ accounts, app, pageNumber, setPageNumber }) => {
  const renderApps = () => {
    return accounts.map((account, index) => <AccountItem key={index} account={account} app={app} />);
  };
  const showModal = useAppSelector((state) => state.account.showModal);
  const dispatch = useAppDispatch();
  const { id: appId } = useParams<any>();
  const { data: apps, error: appError } = useApp(appId);
  return (
    <div className="flex w-full flex-col space-y-5 items-start">
      <div>
        {showModal && <CreateAccount appDetails={apps.data} />}
        <div className="flex w-full  flex-col">
          <button
            onClick={() => dispatch(setAccountModal(true))}
            className="text-white  bg-primary-color hover:bg-[#1f324e] font-medium 
			rounded-sm text-sm px-5 py-2 text-center mr-2 mb-6 hover:transition-all"
          >
            Create Account
          </button>
        </div>
      </div>
      <div className="w-full grid grid-cols-4 gap-4">{renderApps()}</div>
      {accounts.length / pageNumber >= 20 && (
        <button
          type="button"
          className="font-medium transition-all rounded-sm text-sm px-6 py-3 text-center mr-2 mb-6 hover:transition-all text-white bg-primary-color hover:bg-[#1f324e]"
          onClick={() => setPageNumber(pageNumber + 1)}
        >
          Load More
        </button>
      )}
    </div>
  );
};

export default AccountList;
