import React, { useEffect, useState } from "react";
import Modal from "./Modal";
import { toast } from "react-toastify";
const WebhookModal: React.FC<any> = ({ visible, url, closeModalHandler }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const instructionClassName = {
    mainDiv: "relative px-4 w-full max-w-xl h-full md:h-auto",
    insetOpacity: "opacity-50 fixed inset-0 z-40 bg-black",
  };
  const modalText = () => {
    return (
      <div className="font-semibold text-center text-xl flex justify-center space-x-1">
        <div>Webhook URL</div>
      </div>
    );
  };
  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);

      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };
  useEffect(() => {
    if (copySuccess) {
      toast.info("Successfully copied", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        onClose: () => {
          setCopySuccess(false);
          closeModalHandler();
        },
      });
    }
  }, [copySuccess]);
  return (
    <>
      <Modal
        visible={visible}
        closeModalHandler={closeModalHandler}
        text={modalText()}
        className={instructionClassName}
      >
        <span className="text-gray-200 flex justify-center p-3 space-x-2">This serves as an example webhook URL, illustrating how an end user can dynamically generate their own unique webhook URL</span>
        <div className="flex justify-center p-3 space-x-2">

          <input className="w-full h-8 p-2 text-gray-200 bg-gray-800" value={`https://us-central1-konnectify-d1.cloudfunctions.net/konnectify-webhook/webhook/endusers/konnector/{WebHookId}`} readOnly={true}></input>
          <h2 className="inline-flex justify-center items-center ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="white"
              className="w-4 h-4 cursor-pointer"
              onClick={() => copyToClipBoard(`${url}`)}
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
              />
            </svg>
          </h2>
        </div>
      </Modal>
    </>
  );
};

export default WebhookModal;
