import { createSlice, current } from "@reduxjs/toolkit";
import { ConfigurationType, StatusType } from "state/new-konnector/types";
import { InitialState } from "../types";
import { v4 as uuidv4 } from "uuid";
import { setParseConfig, setParseConfigForEdit, setRawDataMappingKeys } from "utils/parseEngineUtils";

const initialState: InitialState = {
  parseType: "User defined pattern",
  sourceField: "",
  parsePattern: "",
  conditions: [],
  parsingItems: [],
  currentConditionId: "",
  status: StatusType.idle,
  rawDataMapping: {},
  configurationType: ConfigurationType.ParseEngine,
  currentFormField: "",
  sourceId: "",
};
const parseSlice = createSlice({
  name: "parseEngine",
  initialState,
  reducers: {
    addCondition(state) {
      const newCondition = {
        id: uuidv4(),
        operator: "",
        field_value: "",
        startIndex: 0,
        totalCharecter: 0,
        data_type: "",
      };
      state.conditions.push(newCondition);
    },
    removeCondition(state, data) {
      state.conditions = state.conditions.filter((action) => action.id !== data.payload);
    },
    saveParseDataMapping: (state, data) => {
      state.rawDataMapping = data.payload;
      state.saveDataMapping = StatusType.loading;
    },
    reorderItems: (state, action) => {
      const { sourceIndex, destinationIndex, dragableId } = action.payload;
      const reorderedItem = state.parsingItems.splice(sourceIndex, 1);
      state.parsingItems.splice(destinationIndex, 0, ...reorderedItem);
    },
    createParsingItems: (state, data) => {
      const newParsingItem = {
        id: uuidv4(),
        name: data.payload.name,
        value: data.payload.value,
      };
      state.parsingItems = [...state.parsingItems, newParsingItem];
    },
    updateValue: (state, data) => {
      const id = state.parsingItems.find((id) => id.id);
      // const name = state.parsingItems[id].map((values) => (values.name = data.payload.name));
      // state.parsingItems[id] = { ...state.parsingItems[id], ...name }
      const updatedItems = state.parsingItems.map((item) => {
        if (item.value === "Custom" && item.id == data.payload.id) {
          return {
            ...item,
            name: data.payload.name,
          };
        }
        return item;
      });
      state.parsingItems = [...updatedItems];
    },
    deletePattern: (state, data) => {
      state.parsingItems = state.parsingItems.filter((id) => id.id !== data.payload);
    },
    setSourceFieldDatatype: (state, data) => {
      const rawDataFormat = setRawDataMappingKeys(data.payload.id);
      state.rawDataMapping[rawDataFormat] = data.payload;
    },
    reset: () => initialState,
    editParse(state, data) {
      if (Object.keys(data.payload.parseConfigration).length) {
        state.rawDataMapping = data.payload.parseConfigration.rawDataMapping;
        state.sourceField = data.payload.parseConfigration.rawDataMapping.data.Source_field;
        state.parsePattern = data.payload.parseConfigration.parsingPattern;
        if (data.payload.parseConfigration.conditions?.length) {
          const stateValue = setParseConfig(data.payload.parseConfigration);
          state.conditions = stateValue.conditions;
          state.parsingItems = data.payload.parseConfigration.parsingItems;
        } else {
          state.parsingItems = data.payload.parseConfigration.parsingItems;
        }
      }
    },
    editParseData(state, data) {
      console.log(data.payload);
    },
    saveDataMappingSucces(state) {
      state.saveDataMapping = StatusType.success;
    },
    //have to add state ,
  },
});
export const {
  addCondition,
  removeCondition,
  reorderItems,
  createParsingItems,
  deletePattern,
  reset,
  updateValue,
  editParse,
  saveParseDataMapping,
  saveDataMappingSucces,
  editParseData,
} = parseSlice.actions;

export default parseSlice.reducer;
