import React from "react";
import { AccountProps, ConfigurationType } from "pages/KonnectorPage/types";
import { useAppDispatch, useAppSelector } from "state/store";
import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import { ParentProps } from "state/new-konnector/types";

interface AccountListItemProps extends AccountProps {
  configurationType: ConfigurationType;
  konnectorSlice?: any;
}
const MAX_LENGTH = 5;
const AccountListItem: React.FC<AccountListItemProps> = ({
  configurationType,
  appId,
  configId,
  connectionName,
  connectorTypeId,
  iconUrl,
  konnectorSlice,
}) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);

  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;

  const onClickAccountHandler = () => {
    if (configurationType === ConfigurationType.source) {
      dispatch(
        konnectorSlice.updateSourceAppLoading({
          appId,
          iconUrl,
          connectionId: configId,
          connectionName,
          appName: connectorTypeId,
        })
      );
    } else if (configurationType === ConfigurationType.lookup) {
      if (currentActionId === ParentProps.Repeater) {
        dispatch(
          konnectorSlice.updateLookupAppLoading({
            appId,
            id: isRepeaterCurrentActionId,
            iconUrl,
            connectionId: configId,
            connectionName,
            appName: connectorTypeId,
            parentId: currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source,
          })
        );
      } else {
        dispatch(
          konnectorSlice.updateLookupAppLoading({
            appId,
            id: currentActionId,
            iconUrl,
            connectionId: configId,
            connectionName,
            appName: connectorTypeId,
          })
        );
      }
    } else {
      if (currentActionId === ParentProps.Repeater) {
        dispatch(
          konnectorSlice.updateTargetAppLoading({
            appId,
            id: isRepeaterCurrentActionId,
            iconUrl,
            connectionId: configId,
            connectionName,
            appName: connectorTypeId,
            parentId: currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source,
          })
        );
      }
      dispatch(
        konnectorSlice.updateTargetAppLoading({
          appId,
          id: currentActionId,
          iconUrl,
          connectionId: configId,
          connectionName,
          appName: connectorTypeId,
        })
      );
    }
  };

  return (
    <div
      onClick={onClickAccountHandler}
      className="bg-gray-100 cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
    >
      <Tooltip text={connectionName}>
        <figure className="w-[80%]">
          <img src={iconUrl} alt="Gmail" />
        </figure>
      </Tooltip>
      {connectionName.length > MAX_LENGTH ? (
        <figcaption className="text-center mt-2"> {`${connectionName.substring(0, MAX_LENGTH)}..`}</figcaption>
      ) : (
        <figcaption className="text-center mt-2">{connectionName}</figcaption>
      )}
    </div>
  );
};

export default AccountListItem;
