import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import Plans from "./Plans";
import CurrentPlans from "./CurrentPlan";
import RenameModal from "./Modal";
import { useAppDispatch, useAppSelector } from "state/store";
import { useCurrentUserApi } from "api/authApi";
import Spinner from "common/components/Spinner";
import { StatusType } from "state/new-konnector/types";
import { setPlanDetailsData } from "utils/authUtils";
import { setPlanDetails } from "state/payment/slices";

const Billing: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.payments.currentUser);
  const paymentStatus = useAppSelector((state) => state.payments.status);
  let { data: user, error, mutate } = useCurrentUserApi();

  useEffect(() => {
    if (user) {
      const planDetails = setPlanDetailsData(user?.data);
      dispatch(setPlanDetails(planDetails));
    }
  }, [user]);

  if (!user || !user?.data) {
    return <Spinner />;
  }
  return (
    <>
      <section className="h-[calc(100vh-5rem)] overflow-y-scroll">
        {user?.data?.isEmailVerified && user?.data?.billing?.plan !== "trial_expired" && (
          <CurrentPlans user={user?.data} />
        )}
        <Plans user={user?.data} />
      </section>
    </>
  );
};

export default Billing;
