import React from "react";
import Apps from "./icons/Apps";

const AdminPage: React.FC = () => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div className="w-full mt-4">
      <Apps />
    </div>
  );
};

export default AdminPage;
