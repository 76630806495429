import React from "react";
import { AppRole, KonnectorAppState } from "state/edit-konnector/types";
import TargetFields from "./TargetFields";

interface ConfigurationDetails {
  action: KonnectorAppState<AppRole.Target | AppRole.Repeater>;
  konnectorSlice: any;
  repeater: boolean;
}

const DataMapping: React.FC<ConfigurationDetails> = ({ action, konnectorSlice, repeater }): JSX.Element => {
  const shouldRenderDynamicFields = action.dynamicInputFields.hasDynamicInputFields
    ? Object.keys(action.dynamicInputFields.dataMapping).length === action.dynamicInputFields.data.length
    : true;

  return (
    shouldRenderDynamicFields && (
      <div className="w-full">
        <h4 className="text-md my-3 font-semibold text-primary-color">Field Mapping</h4>
        {<TargetFields action={action} konnectorSlice={konnectorSlice} repeater={repeater} />}
      </div>
    )
  );
};

export default DataMapping;
