import React from "react";

const Modal: React.FC<any> = ({ children, visible, goBack, closeModalHandler, text, className }) => {
  return (
    <>
      {visible && (
        <>
          <div
            id="account-modal"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center h-modal md:h-full md:inset-0"
          >
            <div className="relative px-4 w-full  max-w-2xl h-full md:h-auto">
              {/* Modal content */}
              <div className="relative rounded-sm shadow bg-gray-50">
                <div className="flex justify-end p-2 bg-gray-200 mb-4  border-b-2">
                  {goBack && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={goBack}
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="3.5"
                      stroke="gray"
                      className="w-5 h-5 mt-3 cursor-pointer"
                    >
                      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                  )}
                  <span className="text-center ml-7 p-2 px-3 mb-3 text-gray-700 text-sm">
                    <div className="ml-16  text-lg text-center">
                      Please select any 5 konnectors you wish to proceed <br />
                      <div className="text-sm flex text-gray-600 ml-14 font-semibold space-x-2">
                        Note that remaining konnectors will be deleted
                      </div>
                    </div>
                  </span>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="account-modal"
                    onClick={() => closeModalHandler()}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                {children}
              </div>
            </div>
          </div>
          {/* <div className={className.insetOpacity}></div> */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default Modal;
