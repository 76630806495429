import React from "react";
import { AppsProps, ConfigurationType } from "pages/KonnectorPage/types";
import AppListItem from "./AppListItem";
import { WebhookIcon } from "common/components/Icons";
import { useAppDispatch } from "state/store";
import Logo from "../Trigger/Icons/Logo";


const GenericAppList: React.FC<any> = ({ configurationType, konnectorSlice }) => {
  const dispatch = useAppDispatch();
  const onClickHandler = (type) => {
    dispatch(konnectorSlice.updateKonnectorType(type));
  };
  const renderApps = () => {
    return (
      configurationType === ConfigurationType.source && (
        <>
          <div
            onClick={() => {
              onClickHandler("webhook");
            }}
            className="bg-gray-100 relative h-28 cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
          >
            <div className="absolute top-3 left-1">
              <Logo />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 absolute top-1 right-1 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <figure className="w-[28%]">
              <WebhookIcon />
            </figure>
            <figcaption className="text-center mt-2">{"Webhook"}</figcaption>
          </div>
          <div
            onClick={() => {
              onClickHandler("form");
            }}
            className="bg-gray-100 relative cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
          >
            <div className="absolute top-3 left-1">
              <Logo />
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 absolute top-1 right-1 text-gray-500"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <figure className="w-[28%]">
              <img src="https://img.icons8.com/material-outlined/96/707070/application-form.png" alt="" />
            </figure>
            <figcaption className="text-center mt-2">{"Form Builder"}</figcaption>
          </div>
        </>
      )
    );
  };
  return <>{renderApps()}</>;
};

export default GenericAppList;
