import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusType } from "state/new-konnector/types";

import { SortByType, InitialState } from "../types";

export const initialState: InitialState = {
  sortBy: SortByType.DEFAULT,
  filterBy: "",
  status: StatusType.idle,
  currentPage: 1,
  currentActionId: "",
  hasActivated: false,
  hasDeleted: false,
  filters: {
    userId: "",
    konnectorName: "",
    appName: "",
    date: "",
  },
  resetCalendar: false
};

const eventLogsSlice = createSlice({
  name: "adminEventlogs",
  initialState,
  reducers: {
    updateSortBy(state, data: PayloadAction<SortByType>) {
      state.currentPage = 1;
      state.sortBy = data.payload;
    },
    updateCurrentPage(state, data: PayloadAction<number>) {
      state.currentPage = data.payload;
    },
    updateFilter(state, data: PayloadAction<string>) {
      state.currentPage = 1;
      state.filterBy = data.payload;
    },
    apiError(state, data: PayloadAction<any>) {
      state.status = StatusType.failed;
    },
    setFilters(state, data) {
      state.filters = {
        userId: data.payload.userId,
        konnectorName: data.payload.konnectorName,
        appName: data.payload.appName,
        date: data.payload.date,
      };
    },
    reset: () => initialState,
    resetCalendar(state) {
      state.resetCalendar = true
    }
  },
});

export const { updateSortBy, updateCurrentPage, apiError, updateFilter, reset, setFilters, resetCalendar } = eventLogsSlice.actions;

export default eventLogsSlice.reducer;
