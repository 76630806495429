import React from "react";

const AllApps: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      {/* <svg xmlns="http://www.w3.org/2000/svg" className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"} viewBox="0 0 20 20" >
        <path
          fillRule="evenodd"
          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
          clipRule="evenodd"
        />
      </svg> */}
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 15.29 15.29"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style></style>
        </defs>
        <g id="darkmode_1" data-name="darkmode 1">
          <g id="apps_sub">
            <g>
              <path
                className="cls-1"
                d="M3.54,0c.6,0,1.2,0,1.8,0,.95,0,1.73,.78,1.73,1.73,0,1.2,0,2.41,0,3.61,0,.94-.78,1.72-1.72,1.72-1.21,0-2.42,0-3.63,0C.79,7.06,0,6.28,0,5.36c0-1.22,0-2.43,0-3.65C0,.79,.79,.01,1.71,0c.61,0,1.22,0,1.83,0Z"
              />
              <path
                className="cls-1"
                d="M8.22,3.54c0-.59,0-1.19,0-1.78,0-.98,.77-1.75,1.75-1.75,1.19,0,2.38,0,3.58,0,.96,0,1.74,.78,1.74,1.74,0,1.2,0,2.4,0,3.6,0,.96-.78,1.73-1.74,1.74-1.19,0-2.38,0-3.58,0-.97,0-1.74-.78-1.75-1.75,0-.59,0-1.19,0-1.78Z"
              />
              <path
                className="cls-1"
                d="M3.54,15.29c-.59,0-1.19,0-1.78,0-.98,0-1.75-.77-1.75-1.75,0-1.19,0-2.38,0-3.58,0-.96,.78-1.74,1.74-1.74,1.2,0,2.4,0,3.6,0,.95,0,1.73,.78,1.74,1.73,0,1.2,0,2.41,0,3.61,0,.95-.78,1.72-1.74,1.73-.6,0-1.2,0-1.8,0Z"
              />
              <path
                className="cls-1"
                d="M11.75,15.29c-.59,0-1.19,0-1.78,0-.98,0-1.75-.78-1.75-1.75,0-1.19,0-2.38,0-3.58,0-.96,.78-1.74,1.74-1.74,1.2,0,2.4,0,3.6,0,.95,0,1.73,.78,1.73,1.73,0,1.2,0,2.41,0,3.61,0,.95-.78,1.72-1.74,1.73-.6,0-1.2,0-1.8,0Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
export default AllApps;
