import React from "react";
import HeaderComponent from "./component/eventsummary/Header";
import TriggerDetails from "./component/eventsummary/Trigger";
import Actiondetails from "./component/eventsummary/Action";
import RuleReport from "./component/eventsummary/RuleReport";
import useEventLogs, { useEventlog } from "api/eventLogsApi";
import Spinner from "common/components/Spinner";
import { useLocation, useParams } from "react-router-dom";
import { useKonnector } from "api/konnectorsApi";
import LookupDetails from "./component/eventsummary/Lookup";
import { ActionType } from "interfaces/pages/create-new-konnector";
import ParseReport from "./component/eventsummary/ParseReport";
import { useAdminKonnector, useAdminEventlog } from "api/adminEventLogsApi";
import Repeaterdetails from "./component/eventsummary/Repeater";

const EventLogsPage: React.FC = () => {
  const { id } = useParams<any>();
  const { pathname } = useLocation();

  const useEventlogsOrAdminEventlogs = pathname.includes("admin") ? useAdminEventlog : useEventlog;

  const { data: eventSummary, error: eventSummaryError } = useEventlogsOrAdminEventlogs(id);

  const useKonnectorOrAdminKonnector = pathname.includes("admin") ? useAdminKonnector : useKonnector;
  console.log(eventSummary);
  const { data: konnector, error: konnectorError } = useKonnectorOrAdminKonnector(
    eventSummary?.data?.konnectorId || ""
  );

  if (eventSummaryError || konnectorError) return <p>Failed to load events</p>;
  if (!eventSummary || !konnector) return <Spinner />;

  const findIconUrl = (actionOrderId) => {
    return konnector.data ? konnector.data.konnectorApps.find((icon) => icon.actionOrderId === actionOrderId) : "";
  };

  const renderEventItems = (eventItems) => {
    return eventItems?.map((eventItem) => {
      switch (eventItem.actionType) {
        case ActionType.Repeater:
          return (
            <Repeaterdetails
              actions={eventItem}
              dtUpdated={eventSummary.data.dtUpdated}
              actionData={findIconUrl(eventItem.actionOrderId)}
            />
          );
        case ActionType.Action:
          return (
            <Actiondetails
              actions={eventItem}
              dtUpdated={eventSummary.data.dtUpdated}
              actionData={findIconUrl(eventItem.actionOrderId)}
            />
          );
        case ActionType.Lookup:
          return (

            <LookupDetails
              lookups={eventItem}
              dtUpdated={eventSummary.data.dtUpdated}
              lookupData={findIconUrl(eventItem.actionOrderId)}
            />
          );
        case ActionType.RuleConfig:
          return <RuleReport dtUpdated={eventSummary.data.dtUpdated} ruleReport={eventItem} />;
        case ActionType.ParseConfig:
          return <ParseReport dtUpdated={eventSummary.data.dtUpdated} parseReport={eventItem} />;
      }
    });
  };

  return (
    <>
      <div className=" ">
        <div className="flex-grow ">
          <HeaderComponent
            header={{
              trigger: eventSummary.data.trigger,
              id: eventSummary.data.id,
              status: eventSummary.data.status,
              dtUpdated: eventSummary.data.dtUpdated,
            }}
            apps={konnector.data ? konnector.data.konnectorApps : null}
            taskExecuted={eventSummary.data.taskExecuted ? eventSummary.data.taskExecuted : null}
          />
          <div
            className={` ${pathname.includes("admin")
              ? "ml-[5%] flex h-[calc(80vh-5rem)] justify-start items-start flex-col"
              : "flex h-[calc(80vh-5rem)] w-full justify-start items-start flex-col"
              }`}
          >
            <h1 className="text-xl mb-4 px-5 mt-8">
              <b>Action log:</b> {eventSummary ? eventSummary.data.trigger.konnectorName : null}
            </h1>
            <div className=" px-4 mt-4 overflow-x-auto p-1.5 w-[100%] ">
              <table className="min-w-full max-w-full divide-y divide-gray-200 border  ">
                <thead className=" bg-[#F2F6FF]">
                  <tr>
                    <th scope="col" className="pl-4  py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                      Events
                    </th>
                    <th scope="col" className="pl-4 pr-56 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                      Event Name
                    </th>
                    <th scope="col" className="pl-4 pr-70 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                      Created date
                    </th>
                    <th scope="col" className="pl-4 pr-70 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                      status
                    </th>
                    <th scope="col" className="pl-4 pr-70 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                      View
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  <TriggerDetails
                    trigger={eventSummary.data.trigger}
                    konnectorStatus={konnector.data ? true : false}
                    konnector={konnector}
                    dtUpdated={eventSummary.data.dtUpdated}
                  />
                  {renderEventItems(eventSummary.data.eventLogItems)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventLogsPage;
