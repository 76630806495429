import React from "react";
import { AppRole, KonnectorAppState } from "state/new-konnector/types";
import TargetFields from "./TargetFields";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "state/store";
import { useForm } from "react-hook-form";
import { WorkFlowState } from "state/workFlows/types";

interface ConfigurationDetails {
  action: KonnectorAppState<AppRole.Target>;
  konnectorSlice: any;
}

const DataMapping: React.FC<ConfigurationDetails> = ({ action, konnectorSlice }): JSX.Element => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
    setValue,
    control,
  } = useForm();
  const dispatch = useAppDispatch();

  const workflowState = useAppSelector((state) => state.workflows.workflowState);
  const type: any = useAppSelector((state) => state[workflowState].data);
  const workflowType = useAppSelector((state) => {
    if (workflowState == WorkFlowState.NewWorkFlowState) {
      return state[workflowState].data.workflowType;
    } else {
      return type.konnectorType;
    }
  });
  const hasActionDynamicInput = useAppSelector((state) => state[workflowState].hasActionDynamicInput);
  const hasDynamicInput = useAppSelector((state) => state[workflowState].hasDynamicInput);
  const shouldRenderDynamicFields = action.dynamicInputFields.hasDynamicInputFields
    ? Object.keys(action.dynamicInputFields.dataMapping).length === action.dynamicInputFields.data.length
    : true;
  const onSubmit = (data) => {
    data = {
      hasDynamicInput: hasDynamicInput,
      hasActionDynamicInput: hasActionDynamicInput,
    };
    dispatch(konnectorSlice.saveDataMapping(data));
  };

  if (!hasDynamicInput && !hasActionDynamicInput && workflowType != "webhook") {
    return (
      <div className="w-full">
        <h4 className="text-md my-3 font-semibold text-primary-color">Field Mapping</h4>
        {<TargetFields action={action} konnectorSlice={konnectorSlice} />}
      </div>
    );
  } else {
    return (
      <div className="w-full">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex justify-end">
            <button
              type="submit"
              className="text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all  font-medium rounded-sm text-sm px-5 py-2.5 text-center"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    );
  }
};

export default DataMapping;
