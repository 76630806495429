import { useKonnectors } from "api/adminEventLogsApi";
import React from "react";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  container: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
};

const SelectKonnector: React.FC<any> = ({ selectedKonnector, selectedApp, onChangeKonnectorHandler }) => {
  console.log({ selectedKonnector });
  const { data: konnectors, error: konnectorsError } = useKonnectors(1, "status", "", 100, selectedApp);
  if (konnectorsError) return <p></p>;

  const renderKonnectors = () => {
    if (!konnectors) return [{ label: "Loading...", value: "" }];

    const kon = konnectors.data.items ? konnectors.data.items : konnectors.data;

    const konnectorItems = [{ name: "All", id: "" }, ...kon];
    return konnectorItems.map((konnector) => ({ label: konnector.name, value: konnector.id }));
  };

  return (
    <div className="mb-2">
      <label className="form-label inline-block mb-2 text-gray-700 text-sm">Select Konnector</label>
      <Select
        styles={customStyles}
        options={renderKonnectors()}
        value={renderKonnectors().filter(function (option) {
          return option.value === selectedKonnector;
        })}
        onChange={onChangeKonnectorHandler}
      />
    </div>
  );
};

export default SelectKonnector;
