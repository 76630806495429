import { KonnectorAppState, AppRole, InitialState } from "./types";
export const getTriggerDetails = (state): KonnectorAppState<AppRole.Source> => state.createNewWorkflow.data.trigger;
export const getTargetDetails = (state): KonnectorAppState<AppRole.Target> => {
	const findActionIndex = state.createNewWorkflow.data.actions.findIndex(
    (action) => action.id === state.createNewWorkflow.currentActionId
  );
	return state.createNewWorkflow.data.actions[findActionIndex];
};
export const getLookupDetails = (state, lookupId): KonnectorAppState<AppRole.Lookup> => {
	return state.createNewWorkflow.data.lookups.find((lookup) => lookup.id == lookupId);
};

export const getCreateWorkflowDetails = (state): InitialState => state.createNewWorkflow;
