import Login from "pages/Login";
import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import PageProperties from "routes/PageProperties";
import { useAppSelector } from "state/store";

const Index: React.FC<PageProperties> = () => {
  const { isAuthenticated } = useAppSelector((state) => state.auth);
  if (isAuthenticated) return <Redirect to={"/KompApps"} />;
  return <Login />;
};
export default Index;
