import React, { useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";
import { sendUserApiLoading } from "state/auth/slices";

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  container: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
};

const SelectUserEmail: React.FC<any> = ({ selectedKonnector, onChangeUserHandler }) => {
  const apiKey = useAppSelector((state) => state.auth.currentUser.tenantId);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(sendUserApiLoading(apiKey));
  }, [apiKey]);
  if (!user?.data) return <p>Failed to load users</p>;

  const renderUsers = () => {
    if (!user?.data) return [{ label: "Loading...", value: "" }];
    const appsItems = [
      { label: "All", value: "" },
      ...user.data.map((app) => ({ label: app.userId, value: app.userId })),
    ];
    return appsItems;
  };
  return (
    <div className="mb-2">
      <label className="form-label inline-block mb-2 text-gray-700 text-sm mt-2">Select User</label>
      <Select
        styles={customStyles}
        options={renderUsers()}
        value={renderUsers().filter(function (option) {
          return option.value === selectedKonnector;
        })}
        onChange={onChangeUserHandler}
      />
    </div>
  );
};

export default SelectUserEmail;
