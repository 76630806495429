import React, { useState } from "react";
import ViewJson from "../../ViewJSon";
import ParseSummary from "./RuleReportItem";
import RuleSummary from "./RuleReportItem";

const ParseReport: React.FC<any> = ({ parseReport, apps,dtUpdated }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };

  return (
    <>
      <ParseSummary
        status={parseReport.outcome}
        handleActionChange={() => handleActionClick()}
        outputFields={parseReport}
        title={"Parse report"}
        dtUpdated={dtUpdated}
      />
      {state && (
        <ViewJson visible={state} setViewOutputFields={setState} outputFields={parseReport} title={"Parse report"} />
      )}
    </>
  );
};

export default ParseReport;
