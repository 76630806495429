import { axiosInstance, options } from "./libs/fetch";

export function savePortalCongrationApi(payload) {
  return axiosInstance.post(`/users/savePortalConfigration`, payload, options);
}
export function saveThemeApi(payload) {
  return axiosInstance.post(`/embedded/admin/theme`, payload, options);
}
export function getThemeApi() {
  return axiosInstance.get(`/embedded/admin/theme/portal`, options);
}

