import React from 'react'

const Konnector = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 152 148" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M61.6624 121.601L74.057 107.776L91.1197 128.353H126.855L43.312 34.633L38 2L151 128.353V147H84.037L61.6624 121.601Z" fill="#9CA3AF" stroke="#9CA3AF" />
      <path d="M24.7045 1H37.9091L43 33.677V63.9394H19.6136V82.7729H43V111.908L37.9091 147H24.7045V100.319H1V46.3936H24.7045V1Z" fill="#9CA3AF" stroke="#9CA3AF" />
      <g filter="url(#filter0_ddddd_1745_8)">
        <rect x="81" y="80.0703" width="19" height="12" transform="rotate(-47.7708 81 80.0703)" fill="#9CA3AF" />
      </g>
      <path d="M39.0845 147H25L42.2855 114.903L125.672 18.129H90.621L72.8553 39.7419L60.6914 26.0323L82.2983 2H150V20.0645L39.0845 147Z" fill="#9CA3AF" stroke="#9CA3AF" />
      <defs>
        <filter id="filter0_ddddd_1745_8" x="80" y="65" width="39.6562" height="40.1328" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0980392 0 0 0 0 0.109804 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1745_8" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0980392 0 0 0 0 0.109804 0 0 0 0.14 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_1745_8" result="effect2_dropShadow_1745_8" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="3" dy="3" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0980392 0 0 0 0 0.109804 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_1745_8" result="effect3_dropShadow_1745_8" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="6" dy="6" />
          <feGaussianBlur stdDeviation="2.5" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0980392 0 0 0 0 0.109804 0 0 0 0.07 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_1745_8" result="effect4_dropShadow_1745_8" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dx="11" dy="11" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0901961 0 0 0 0 0.0980392 0 0 0 0 0.109804 0 0 0 0.02 0" />
          <feBlend mode="normal" in2="effect4_dropShadow_1745_8" result="effect5_dropShadow_1745_8" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect5_dropShadow_1745_8" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default Konnector