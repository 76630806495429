import React from "react";
import FieldValueType from "../../FieldValueType";
import SourceTypes from "../../SourceTypes";
import { TargetInputField } from "pages/KonnectorPage/types";
import SourceFields from "../../SourceFields";

interface FieldItemProps {
  field: TargetInputField;
  register: any;
  errors: object;
  watch: any;
  setInputValue?: any;
  control: any;
  selectedEvent: any;
}

const FieldItem: React.FC<FieldItemProps> = ({
  field,
  control,
  register,
  watch,
  errors,
  setInputValue,
  selectedEvent,
}): JSX.Element => {
  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);
  return (
    <div className="flex mb-3 w-full justify-between items-center">
      <FieldValueType field={field} register={register} errors={errors} />
      {fieldType === "fixed" ? null : (
        <>
          <SourceTypes field={field} control={control} watch={watch} register={register} errors={errors} />
        </>
      )}

      <SourceFields
        selectedEvent={selectedEvent}
        field={field}
        control={control}
        watch={watch}
        setInputValue={setInputValue}
        register={register}
        errors={errors}
      />
    </div>
  );
};

export default FieldItem;
