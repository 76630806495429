import React, { useEffect } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { ActionMode, StatusType } from "state/edit-konnector/types";
import { useParams } from "react-router-dom";
import { WorkFlowState } from "state/workFlows/types";
import { editAction } from "state/edit-workflow/slices";
import { createAction, editSource } from "state/new-workflow/slices";

const SelectApp: React.FC<AppsProps> = ({ apps, sourceApp, konnectorSlice }) => {
  const konnectorState: WorkFlowState = useAppSelector((state) => state.workflows.workflowState);
  const workflowType = useAppSelector((state) => state[konnectorState].data.workflowType);
  const selectedApp = useAppSelector((state) => state[konnectorState].data.trigger.appId);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const actionMode = useAppSelector((state) => state.editWorkFlow.actionMode);
  const createActionMode = useAppSelector((state) => state[konnectorState].displayApp);
  const staticApp = useAppSelector((state) => {
    if (konnectorState !== "editWorkFlow") {
      return state.auth.currentUser.staticApp;
    } else {
      return state[konnectorState].data.actions[0].appId;
    }
  });
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const dispatch = useAppDispatch();
  const edit = useAppSelector((state) => state.createNewWorkflow.data.actions);
  const getApp = (value) => {
    return apps.find((app) => app.id === value);
  };

  const onChangeAppHandler = ({ value }) => {
    const app = getApp(value);
    if (!value) {
      return dispatch(
        konnectorSlice.updateTargetAppLoading({ appId: value, id: currentActionId, iconUrl: app.iconUrl })
      );
    }
    dispatch(
      konnectorSlice.updateTargetAppLoading({
        appId: value,
        id: currentActionId,
        iconUrl: app.iconUrl,
        appName: app.appName,
      })
    );
  };
  const getApps = () => {
    return apps.map((app) => ({ value: app.id, label: app.appName }));
  };

  useEffect(() => {
    return () => {
      // reset the status
      if (apiStatus == StatusType.success && sourceApp) {
        dispatch(konnectorSlice.resetStatus());
      }
    };
  }, [sourceApp]);
  const { other, appId, accountId, eventid, name } = useParams<any>();


  // useEffect(() => {
  //   if (other && edit[0].actionMode !== ActionMode.Edit && edit.length == 1) {
  //     const app = getApp("NVY3oPOqqG");
  //     dispatch(
  //       konnectorSlice.updateTargetAppLoading({
  //         appId: "NVY3oPOqqG",
  //         id: currentActionId,
  //         iconUrl: app.iconUrl,
  //         appName: "app-stripe",
  //       })
  //     );
  //   }
  // }, [sourceApp]);

  // useEffect(() => {
  //   if (staticApp !== selectedApp && createAction && actionMode !== ActionMode.Edit && !createActionMode) {
  //     const app = getApp(staticApp);
  //     dispatch(
  //       konnectorSlice.updateTargetAppLoading({
  //         appId: staticApp,
  //         id: currentActionId,
  //         iconUrl: app.iconUrl,
  //         appName: app.appName,
  //       })
  //     );
  //   }
  // }, []);
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label  inline-block mb-2 text-gray-700 text-md">Select App</label>
      {staticApp !== selectedApp && workflowType != "webhook" ?
        <Select
          options={getApps().filter(function (option) {
            return option.value === staticApp;
          })}
          value={getApps().filter(function (option) {
            return option.value === sourceApp;
          })}
          onChange={onChangeAppHandler}
        /> :
        <Select
          options={getApps()}
          value={getApps().filter(function (option) {
            return option.value === sourceApp;
          })}
          onChange={onChangeAppHandler}
        />}

    </div>
  );
};

export default SelectApp;