import React, { useEffect, useState } from 'react';
import { FieldIcons } from '../Icons/TextBoxIcon';
import { Draggable } from 'react-beautiful-dnd';
import RenderField from '../FormFields/genericFields';
import { useForm } from 'react-hook-form';
import { formLogoField } from '../constants/formSettings';
import Slider from '@mui/material/Slider';
import { useAppDispatch, useAppSelector } from 'state/store';
import { setFormIconAlignment, setFormIconSize, setFormLogo } from 'state/form-builder/slices';
import FileField from '../FormFields/genericFields/File';

const FormLogo: React.FC<any> = ({ field }) => {
	const {
		register,
		formState: { errors },
		reset,
		watch,
		handleSubmit,
	} = useForm();
	const dispatch = useAppDispatch();
	const [formSetting, setFormSetting] = useState({ url: '', alignment: '', size: '' });
	const [logoWidth, setWidth] = useState(50);
	const formLogo = useAppSelector((state) => state.formBuilder.formSettings.formLogo);

	useEffect(() => {
		const subscription = watch(handleSubmit(onSubmit));
		return () => subscription.unsubscribe();
	}, [handleSubmit, watch]);

	useEffect(() => {
		setFormSetting(formLogo);
	}, [formLogo]);

	const onSubmit = (data) => {
		getBase64(data.form_logo[0], (result) => {
			data.url = result;
			delete data.form_logo;
			dispatch(setFormLogo(data));
		});
	};
	const getBase64 = (file, cb) => {
		let reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function () {
			cb(reader.result);
		};
		reader.onerror = function (error) {
			console.log('Error: ', error);
		};
	};

	const handleChange = (e) => {
		setWidth(e.target.value);
		dispatch(setFormIconSize(e.target.value));
	};

	const renderFieldList = (fieldList) => {
		// console.log({ fieldList });
		fieldList['required']['value'] = false;
		return (
			<FileField
				{...fieldList}
				label={fieldList.label}
				key={fieldList.name}
				handleSubmit={handleSubmit}
				register={register}
				isDisabled={false}
				errors={errors}
			/>
		);
	};
	return (
		<div className="flex flex-col w-3/4 mt-4 p-2 space-y-3 h-full">
			{renderFieldList(formLogoField)}
			<div className="block mb-2 text-sm font-medium text-gray-500">Logo size</div>
			<div className="w-2/4">
				<Slider
					size="small"
					defaultValue={50}
					value={parseInt(formLogo.size)}
					aria-label="Small"
					valueLabelDisplay="auto"
					onChange={handleChange}
					className="ml-2 text-gray-300"
					min={50}
					max={300}
				/>
			</div>
			<div className="w-full">
				<label htmlFor={''} className="block mb-2 text-sm font-medium text-gray-500">
					Alignment
				</label>
				<div className="flex space-x-2">
					<label
						className={`w-1/4 rounded border-[1px] relative ${
							formSetting.alignment === 'left' ? 'bg-gray-200' : ''
						} border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
					>
						<div className="absolute right-1 top-1">
							<input
								type="radio"
								value="left"
								checked={formSetting.alignment === 'left' ? true : false}
								onClick={() => {
									dispatch(setFormIconAlignment('left'));
								}}
								{...register(`alignment`)}
								className="rounded-full border-2 border-gray-300 w-3 h-3"
							/>
						</div>
						<p>Left</p>
						<p className="text-xs text-gray-400">aligns at the top left corner</p>
					</label>
					<label
						className={`w-1/4 rounded border-[1px] relative ${
							formSetting.alignment === 'center' ? 'bg-gray-200' : ''
						} border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
					>
						<div className="absolute right-1 top-1">
							<input
								type="radio"
								value="center"
								checked={formSetting.alignment === 'center' ? true : false}
								onClick={() => {
									dispatch(setFormIconAlignment('center'));
								}}
								{...register(`alignment`)}
								className="rounded-full border-2 border-gray-300 w-3 h-3"
							/>
						</div>
						<p className="">center</p>
						<p className="text-xs text-gray-400">aligns at the top center</p>
					</label>
					<label
						className={`w-1/4 rounded border-[1px] relative ${
							formSetting.alignment === 'right' ? 'bg-gray-200' : ''
						} border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
					>
						<div className="absolute right-1 top-1">
							<input
								type="radio"
								value="right"
								checked={formSetting.alignment === 'right' ? true : false}
								onClick={() => {
									dispatch(setFormIconAlignment('right'));
								}}
								{...register(`alignment`)}
								className="rounded-full border-2 border-gray-300 w-3 h-3"
							/>
						</div>
						<p className="">Right</p>
						<p className="text-xs text-gray-400">aligns at the top right corner</p>
					</label>
				</div>
			</div>

			{formLogo.url && (
				<div className="w-full h-2/6 max-h-44 flex flex-col">
					<div className="block mb-2 text-sm font-medium text-gray-500">Logo size</div>

					<div className="p-2 border-2 w-full h-full border-gray-300 border-dashed overflow-y-scroll">
						<img
							src={formLogo.url}
							className={`transition-all duration-150`}
							alt=""
							style={{ width: `${logoWidth}px` }}
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default FormLogo;
