import React from "react";
import { User } from "../types";

const Information: React.FC<User> = ({ name, email, role, organisation, phoneNumber }) => {
  return (
    <div className="p-5 space-y-5">
      <h1 className="text-base font-medium">Profile Details</h1>
      <div className="flex space-x-20">
        <ul className="text-sm font-light text-secondary-color space-y-4">
          <li className="flex flex-col space-y-1">
            <span className="font-medium">Name</span>
            <span>{name}</span>
          </li>
          <li className="flex flex-col space-y-1">
            <span className="font-medium">Phone Number</span>
            <span>{phoneNumber || "-"}</span>
          </li>
          <li className="flex flex-col space-y-1">
            <span className="font-medium">Role</span>
            <span>{"User"}</span>
          </li>
        </ul>
        <ul className="text-sm font-light text-secondary-color space-y-4">
          <li className="flex flex-col space-y-1">
            <span className="font-medium">Email Address</span>
            <span>{email}</span>
          </li>
          <li className="flex flex-col space-y-1">
            <span className="font-medium">Company</span>
            <span>{organisation || "-"}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Information;
