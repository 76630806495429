import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CreateAccountSuccessResponse, StatusType, ValidateAccountSuccessResponse } from "state/new-konnector/types";

import { AccountInitialState } from "../types";

export const initialState: AccountInitialState = {
  appDetails: {},
  data: {},
  activeAccount: {},
  isValidated: false,
  currentPage: 1,
  error: "",
  status: StatusType.idle,
  showModal: false,
  hasPlan: false,
  isTrial: true,
  isFreePlan: true,
  showUpdateModal: false,
  Newmodel: [],
  pageNumber: 1,
  devHeight: 0,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    updateCurrentPage(state, data: PayloadAction<number>) {
      state.currentPage = data.payload;
    },
    setnewmodel: (state, data: PayloadAction<any>) => {
      state.Newmodel = data.payload;
    },
    setpageNumber: (state, data: PayloadAction<any>) => {
      state.pageNumber = data.payload;
    },
    setDevheight: (state, data: PayloadAction<any>) => {
      state.devHeight = data.payload;
    },
    setOAuthAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    setUpdateAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    setUpdateOAuthAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    setAccountApp: (state, data: PayloadAction<any>) => {
      state.appDetails = data.payload;
    },
    setUpdateAccountApp: (state, data: PayloadAction<any>) => {
      state.appDetails = data.payload;
      state.showUpdateModal = true;
    },
    setUpdateActiveAccount: (state, data: PayloadAction<any>) => {
      state.activeAccount = data.payload.account;
      state.appDetails = data.payload.app;
      state.showUpdateModal = true;
    },
    validateAccountSuccess: (state, data: PayloadAction<ValidateAccountSuccessResponse>) => {
      state.status = StatusType.success;
      state.isValidated = true;
    },
    validateAccountFailed: (state, data: PayloadAction<any>) => {
      state.isValidated = false;
      state.error = data.payload.error ? data.payload.error : "Invalid Credentials";
      state.status = StatusType.failed;
    },
    createAccountSuccess: (state, data: PayloadAction<CreateAccountSuccessResponse>) => {
      state.data = data.payload.data;
      state.status = StatusType.success;
    },
    updateAccountSuccess: (state, data: PayloadAction<CreateAccountSuccessResponse>) => {
      state.data = data.payload.data;
      state.status = StatusType.success;
    },
    accountApiFailed: (state) => {
      state.status = StatusType.failed;
    },
    setAccountModal: (state, data) => {
      if (data.payload === false) {
        state.appDetails = {};
        state.data = {};
        state.activeAccount = {};
        state.isValidated = false;
        state.error = "";
        state.status = StatusType.idle;
        state.showModal = false;
        state.hasPlan = false;
        state.isTrial = true;
        state.isFreePlan = true;
        state.showUpdateModal = false;
      }
      state.showModal = data.payload;
    },

    setUpdateAccountModal: (state, data) => {
      if (data.payload === false) {
        state.appDetails = {};
        state.data = {};
        state.activeAccount = {};
        state.isValidated = false;
        state.error = "";
        state.status = StatusType.idle;
        state.showModal = false;
        state.hasPlan = false;
        state.isTrial = true;
        state.isFreePlan = true;
        state.showUpdateModal = false;
      }
      state.showUpdateModal = data.payload;
    },
    resetAccount: () => initialState,
  },
});

export const {
  setAccountLoading,
  updateCurrentPage,
  validateAccountSuccess,
  validateAccountFailed,
  setOAuthAccountLoading,
  setAccountApp,
  resetAccount,
  createAccountSuccess,
  accountApiFailed,
  setAccountModal,
  setUpdateAccountModal,
  setUpdateAccountLoading,
  setUpdateOAuthAccountLoading,
  setUpdateAccountApp,
  setUpdateActiveAccount,
  updateAccountSuccess,
  setnewmodel,
  setpageNumber,
  setDevheight,
} = accountSlice.actions;

export default accountSlice.reducer;
