import { StatusType } from "state/new-konnector/types";

export interface InitialState {
  // customization2: Customisation2
  primaryBackground: string;
  primaryButtonColor: string;
  secondaryButtonColor: string;
  primaryBackgroundText: string;
  TextColor: string;
  rawDataMapping: {};
  primaryButtonText: string;
  secondaryButtonText: string;
  SetConfigured: {};
  fontFamily: "";
  saveDataMapping?: StatusType;
  hasData: boolean;
  headerfontFamily?: "";
  portalTile: string;
  portalDescription: string;
  tabState: TabState;
  customizationState: boolean
  tabModalStatus: boolean
}


export enum TabState {
  Logo = "logo",
  Card = "card",
  Template = "template",
  Layout = "layout",
  Banner = "banner"
}
// export interface Customisation2 {
//   redirectUrl: string;
//   textColor: string;
//   alignment: string;
//   height: string;
//   borderRadius: string;
//   size: string;
//   submitType: string;
//   message: string;
//   text: string;
//   bg: string;
// }
