import React, { useEffect, useState } from "react";
import { useApps } from "api/appsApi";
import { useAccounts } from "api/accountsApi";
import SidebarControls from "./components/SidebarControls";
import { getKonnectorLoading, reset as resetEditKonnector } from "state/edit-konnector/slices";
import { resetWebhook as resetNewKonnector, deleteWebhook } from "state/new-konnector/slices";
import ConfigureKonnector from "./components/ConfigureKonnector";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "state/store";
import { StatusType } from "state/edit-konnector/types";
import Spinner from "common/components/Spinner";
import { KonnectorState } from "state/konnectors/types";
import { setKonnectorState } from "state/konnectors/slices";
import UserMessage from "common/containers/Layout/components/EmailVerification";
import { reset } from "state/form-builder/slices";

const EditKonnectorPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<any>();

  useEffect(() => {
    if (id) {
      dispatch(setKonnectorState(KonnectorState.EditKonnectorState));
    } else {
      dispatch(setKonnectorState(KonnectorState.NewKonnectorState));
    }
  }, []);
  const [konnectorSlice, setKonnectorSlice]: any = useState();
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const konnector = useAppSelector((state) => state[konnectorState]);
  const konnectorType = useAppSelector((state) => state[konnectorState].data.konnectorType);
  const actions = useAppSelector((state) => state[konnectorState].data.actions);
  const planDetails = useAppSelector((state) => state.payments.createKonnector);
  const loadingState = useAppSelector((state) => state.createNewKonnector.isLoading);

  const setKonnectorSliceAsync = async () => {
    switch (konnectorState) {
      case KonnectorState.EditKonnectorState:
        return await import("state/edit-konnector/slices");
      case KonnectorState.NewKonnectorState:
        return await import("state/new-konnector/slices");
    }
  };
  useEffect(() => {
    setKonnectorSliceAsync()
      .then((data) => {
        setKonnectorSlice(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [konnectorState]);

  //mount
  useEffect(() => {
    if (id) {
      dispatch(getKonnectorLoading(id));
    }
  }, []);

  const { data: apps, error: appsError } = useApps(1, 100);
  const { data: accounts, error: accountsError } = useAccounts();
  //unmount
  useEffect(() => {
    return () => {
      dispatch(resetEditKonnector());
      dispatch(reset());
      dispatch(resetNewKonnector({ konnectorType }));
    };
  }, []);
  const { other, appid, accountid, eventid, name } = useParams<any>();

  if (appsError || accountsError) return <p>Failed to load apps</p>;
  if (
    (apiStatus === StatusType.loading && !actions.length && konnectorState === KonnectorState.EditKonnectorState) ||
    !apps ||
    !accounts
  )
    return <Spinner />;

  if (konnectorState === KonnectorState.EditKonnectorState && !konnector.data.id)
    return <UserMessage actionType={"konnectorNotFound"} />;

  return !planDetails.status && konnectorState === KonnectorState.NewKonnectorState ? (
    <UserMessage actionType={planDetails.type} />
  ) : (
    <section className="flex items-start">
      {/* SidebarControls */}
      <SidebarControls />
      {/* ConfigureKonnector */}
      {other && loadingState && (
        <>
          <div className="ml-96 pl-72 ">
            <Spinner />
          </div>
        </>
      )}
      <ConfigureKonnector
        apps={apps.data.items}
        accounts={accounts.data.items}
        konnectorState={konnectorState}
        konnectorSlice={konnectorSlice}
      />
    </section>
  );
};

export default EditKonnectorPage;
