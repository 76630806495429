import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { getAuth } from "state/selectors";
import { useAppSelector } from "state/store";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { KonnectorType } from "pages/KonnectorList/types";
import SubLinks from "../SubLinks";
import { CreateNewMenu } from "./CreateNewMenu";
import { useApp } from "api/appsApi";
import { useWorkflows } from "api/workflowApi";
import { Icon } from "../../Sidebar/EmbeddedSidebar/Icon";
import { AppSideBarTypes } from "../../Sidebar/EmbeddedSidebar/types";

interface HeaderProps {
  name: string;
}

const EmbeddedHeader: React.FC<HeaderProps> = ({ name }) => {
  const location = useLocation();
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const { currentUser } = useAppSelector(getAuth);
  const konnectorType = useAppSelector((state) => state.editKonnector.data.konnectorType);
  const appType = useAppSelector((state) => state.konnectors.appType);
  const konnectorName = useAppSelector((state) => state.editWorkFlow.data.name);
  const categoryType = useAppSelector((state) => state.auth.categoryType);
  const hasConfigurationSaved = useAppSelector((state) => state.editKonnector.hasConfigurationSaved);
  const query = new URLSearchParams(location.search);
  const id: any = query.get("appId");
  const { data: apps, error: error } = useApp(id);
  const { data: konnector, error: errors } = useWorkflows(id);
  const history = useHistory();
  const container = `fixed flex flex-col w-full z-20 bg-[#E7F3FF] mx-auto`;
  const authId = useAppSelector((state) => state.auth.app);
  const triggerId = useAppSelector((state) => state.editWorkFlow.data.trigger.appId);
  const workflowAppId = useAppSelector((state) => state.editWorkFlow.data.workflowAppId);

  const appId = triggerId === authId ? workflowAppId : triggerId;

  const handleClick = () => {
    if (name === "Konnectors") {
      history.push(`/KompApps`);
    } else if (name === "Edit Konnector") {
      history.push(`/KompApps/templates?appId=${appId}`);
    } else if (name === "Events Summary") {
      history.push(`/admin/eventlogs`);
    }
    else {
      history.push(`/admin/settings`);
    }
  };
  const { pathname } = useLocation();
  const toggleProfileSettings = (e) => {
    setShowProfileSettings((value) => !value);
  };
  return (
    <div className={`${pathname.includes(`admin/event-logs`) ? "" : "fixed flex flex-col w-full z-20"}`}>
      <header className="flex justify-between w-full items-center bg-gray-100 border-b-[1px] border-gray-300 h-20 rounded-sm pl-5 pr-8">
        {name === "Edit Konnector" ? (
          <h4 className=" flex items-center justify-center ml-80 cursor-pointer text-xl mx-2 text-primary-color font-medium">
            <span className="hover:text-blue-600" onClick={handleClick}>
              {"Konnectors"}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="gray">
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span className="mt-2"></span>
            {apps?.data.appName ? apps?.data.appName : konnectorName == "Edit Konnector" ? "" : konnectorName}
          </h4>
        ) : !id ? (
          <>
            {name === "KOMP Apps" ? (
              <div className="ml-80 flex space-x-2 justify-center items-center">
                <Icon type={{ type: appType }} fill="#101827" className={"w-7 h-7"} />
                <h4 className="text-2xl  text-komp-color font-bold">{categoryType}</h4>
              </div>
            ) : name === "Admin Dashboard" || name === "Event logs" || name === "New Konnector" ? (
              <h4
                className={`text-xl mx-2 text-primary-color ${["Event logs", "Admin Dashboard"].includes(name) ? "ml-16" : "ml-80"
                  } font-medium`}
              >
                {name}
              </h4>
            ) : (
              <h4 className=" flex items-center justify-center ml-16 cursor-pointer text-xl mx-2 text-primary-color font-medium">
                <span className="hover:text-blue-600" onClick={handleClick}>
                  {name === "Events Summary" ? "Event logs" : "Admin Dashboard"}
                </span>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="gray">
                  <path
                    fill-rule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span className="mt-2"></span>
                {name}
              </h4>
            )}
          </>
        ) : (
          <h4 className=" flex items-center justify-center ml-80 cursor-pointer text-xl mx-2 text-primary-color font-medium">
            <span className="hover:text-blue-600" onClick={handleClick}>
              {"Apps"}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="gray">
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span className="mt-2"></span>
            {apps?.data.appName ? apps?.data.appName : konnectorName == "Edit Konnector" ? "" : konnectorName}
          </h4>
        )}
        <div className="flex ml-auto">
          <CreateNewMenu name={name} />
        </div>
        <div className="flex items-center">
          <div className="relative cursor-pointer self-center p-2">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowProfileSettings(false);
              }}
            >
              <button
                type="button"
                onClick={toggleProfileSettings}
                className="h-10 w-10 flex justify-center items-center bg-komp-secondary-color text-white p-0.5 rounded-full"
              >
                {currentUser.name ? (
                  <span>{currentUser.name.charAt(0).toUpperCase()}</span>
                ) : (
                  <span>{currentUser.email.charAt(0).toUpperCase()}</span>
                )}
              </button>
              {showProfileSettings && <SubLinks />}
            </OutsideClickHandler>
          </div>
        </div>
      </header>
    </div>
  );
};

export default EmbeddedHeader;
