import React, { useState } from "react";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppProps, genericAppData } from "pages/KonnectorPage/types";
import SelectApps from "./SelectApps";
import SelectAccount from "./SelectAccount";
import SelectEvent from "./SelectEvent";
import ShowOutputFields from "./ShowOutputFields";
import { StatusType } from "state/new-konnector/types";
import { AppRole, KonnectorAppState } from "state/new-konnector/types";
import { KonnectorState } from "state/konnectors/types";
import SelectInputSchema from "./SelectInput";
import InputSchema from "./SelectInput";
import Accounts from "../Accounts";
import Apps from "../Apps";

interface TriggerConfigurationProps {
  apps: AppProps[];
  triggerDetails: KonnectorAppState<AppRole.Source>;
  konnectorSlice: any;
  konnectorState: KonnectorState;
  accounts: any;
  appAccounts: any;
}

const TriggerConfiguration: React.FC<TriggerConfigurationProps> = ({
  apps,
  accounts,
  appAccounts,
  triggerDetails,
  konnectorSlice,
  konnectorState,
}) => {
  const hasOutputFields = triggerDetails.outputFields.hasData;
  const inputfields = triggerDetails.inputFields;
  const dispatch = useAppDispatch();
  const selectedSourceApp = triggerDetails.appId;
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const selectedSourceAccount = triggerDetails.connectionId;
  const selectedSourceEvent = triggerDetails.eventId;
  const outputFields: any = triggerDetails.outputFields.fields;
  const showRenderButtons = triggerDetails.inputFields.hasData
    ? triggerDetails.inputFields.data.length === Object.keys(triggerDetails.inputFields.dataMapping).length
    : true;

  const [viewOutputFields, setViewOutputFields] = useState(false);
  apps = [...apps, ...genericAppData];
  const handleOnClickTriggerFields = () => {
    if (!hasOutputFields) {
      dispatch(konnectorSlice.fetchOutputFieldsLoading());
    }
    setViewOutputFields(true);
  };

  const onClickViewOutputFields = (value) => {
    setViewOutputFields(value);
  };

  const saveConfiguration = () => {
    dispatch(konnectorSlice.saveSourceConfiguration());
  };

  const renderOutputFieldsButton = () => {
    if (selectedSourceApp && selectedSourceAccount && selectedSourceEvent && showRenderButtons) {
      return (
        <div className="flex justify-end">
          <button
            onClick={handleOnClickTriggerFields}
            type="button"
            className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color"
          >
            {apiStatus === StatusType.loading ? (
              <>
                <svg
                  role="status"
                  className="inline mr-2 w-4 h-4 text-primary-color animate-spin"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#f3f4f6"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor"
                  />
                </svg>
                {"Loading..."}
              </>
            ) : hasOutputFields ? (
              "View output fields"
            ) : (
              "Trigger output fields"
            )}
          </button>
          <button
            type="button"
            onClick={saveConfiguration}
            className="ml-2 text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all font-medium rounded-sm text-sm px-5 py-2.5 disabled:opacity-50"
            disabled={!hasOutputFields || !outputFields || outputFields.eventResponse?.data.error}
          >
            Save Configuration
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <section className="px-10 py-5 flex flex-col bg-white rounded-md">
        <span className="justify-center bg-primary-color w-16 mb-4 text-white text-xs font-semibold  px-2.5 py-0.5 rounded-sm dark:primary-color dark:text-white">
          Trigger
        </span>
        <div className="flex mb-3 justify-between">
          {<SelectApps apps={apps} konnectorSlice={konnectorSlice} konnectorState={konnectorState} />}
          {selectedSourceApp && (
            <SelectAccount
              sourceApp={selectedSourceApp}
              apps={apps}
              konnectorSlice={konnectorSlice}
              konnectorState={konnectorState}
            />
          )}
        </div>
        <div className="flex justify-between">
          {selectedSourceApp && selectedSourceAccount && (
            <SelectEvent
              sourceApp={selectedSourceApp}
              sourceEvent={selectedSourceEvent}
              apps={apps}
              konnectorSlice={konnectorSlice}
            />
          )}
        </div>
        <div className="mt-2">
          {selectedSourceEvent && (
            <InputSchema inputFields={triggerDetails.inputFields} konnectorSlice={konnectorSlice} />
          )}
        </div>
        {renderOutputFieldsButton()}
        {viewOutputFields && apiStatus === StatusType.success && (
          <ShowOutputFields
            visible={viewOutputFields}
            setViewOutputFields={(value) => onClickViewOutputFields(value)}
            outputFields={outputFields}
          />
        )}
        {!triggerDetails.appId && (
          <>
            <Accounts accounts={accounts} configurationType={"source"} konnectorSlice={konnectorSlice} />
            <Apps apps={appAccounts} configurationType={"source"} konnectorSlice={konnectorSlice} />
          </>
        )}
      </section>
    </>
  );
};

export default TriggerConfiguration;
