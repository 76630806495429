import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusType } from "state/new-workflow/types";

import { SortByType, FilterType, InitialState, WorkFlowState, FilterByType } from "../types";

export const initialState: InitialState = {
  sortBy: SortByType.DEFAULT,
  activeSortBy: SortByType.DEFAULT,
  filterBy: FilterType.ALL,
  filterByWorkflow: FilterByType.TRIGGER,
  status: StatusType.idle,
  workflowState: WorkFlowState.NewWorkFlowState,
  currentPage: 1,
  currentActionId: "",
  hasActivated: false,
  hasDeactivated: false,
  hasDeleted: false,
  selectedKonnectorId: [],
  errorMessage: "",
  activeApps: [],
  deactiveApss: [],
  isModal: false,
  appStatusReload: StatusType.idle,
  reorderApps: [],
  appId: ""
};

const konnectorsSlice = createSlice({
  name: "workflows",
  initialState,
  reducers: {
    updateSortBy(state, data: PayloadAction<SortByType>) {
      state.currentPage = 1;
      state.sortBy = data.payload;
    },
    updateActiveSortBy(state, data: PayloadAction<SortByType>) {
      state.currentPage = 1;
      state.activeSortBy = data.payload;
    },
    updateCurrentPage(state, data: PayloadAction<number>) {
      state.currentPage = data.payload;
    },
    updateFilter(state, data: PayloadAction<FilterType>) {
      state.currentPage = 1;
      state.filterBy = data.payload;
    },
    updateFilterWorkflow(state, data: PayloadAction<FilterByType>) {
      state.filterByWorkflow = data.payload;
    },
    resetStatus(state) {
      state.status = StatusType.idle;
      state.currentActionId = "";
    },
    resetDeactivation(state) {
      state.hasDeactivated = false;
    },
    activateKonnectorLoading(state, data) {
      state.status = StatusType.loading;
      state.currentActionId = data.payload.workflowId;
    },
    activateKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.currentActionId = data.payload.data.id;
      state.hasActivated = true;
      state.hasDeleted = false;
    },
    deactivateKonnectorLoading(state, data) {
      state.status = StatusType.loading;
      state.currentActionId = data.payload.workflowId;
    },
    deactivateKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.currentActionId = data.payload.data.id;
      state.hasActivated = false;
      state.hasDeactivated = true;
      state.hasDeleted = false;
    },
    activateEmbeddedAppLoading(state, data) {
      state.hasActivated = false;
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
    },
    activateLoading(state, data) {
      state.activeApps = data.payload;
    },
    activateAppSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.activeApps = data.payload.data;
      state.currentActionId = data.payload.data.appId;
      state.hasActivated = true;
      state.hasDeleted = false;
    },
    deAactivateEmbeddedAppLoading(state, data) {
      state.deactiveApss = data.payload.data;
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
      state.appStatusReload = StatusType.loading
    },
    deactiveLoading(state, data) {
      state.appStatusReload = StatusType.loading
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
    },
    deactivateAppSuccess(state, data: PayloadAction<any>) {
      state.appStatusReload = StatusType.success
      state.status = StatusType.success;
      state.activeApps = data.payload.data.final;
      state.currentActionId = data.payload.data.appId;
      state.hasActivated = false;
      state.hasDeleted = false;
    },
    deleteKonnectorLoading(state, data: PayloadAction<any>) {
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
      state.hasDeleted = false;
    },
    deleteKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.hasDeleted = true;
    },
    deleteBulkKonnectorLoading(state, data: PayloadAction<any>) {
      state.status = StatusType.loading;
      state.selectedKonnectorId = data.payload;
      state.hasDeleted = true;
    },
    deleteBulkKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.hasDeleted = true;
    },
    apiError(state, data: PayloadAction<any>) {
      state.status = StatusType.failed;
    },
    displayErrorMessage: (state, data: PayloadAction<string>) => {
      state.status = StatusType.failed;
      state.errorMessage = data.payload;
    },
    setKonnectorState: (state, data) => {
      state.workflowState = data.payload;
    },
    reset: () => initialState,
    setWorkflowIndex: (state, data) => {

    },
    setReorderApps: (state, data) => {
      state.reorderApps = data.payload
    },
    setReorderAppsSuccess: (state, data) => {
      state.reorderApps = data.payload
    },
    setApp: (state, data) => {
      state.appId = data.payload
    }
  },
});

export const {
  updateSortBy,
  updateActiveSortBy,
  updateCurrentPage,
  apiError,
  updateFilter,
  updateFilterWorkflow,
  activateKonnectorLoading,
  activateKonnectorSuccess,
  deactivateKonnectorLoading,
  deactivateKonnectorSuccess,
  deleteKonnectorLoading,
  deleteKonnectorSuccess,
  deleteBulkKonnectorLoading,
  deleteBulkKonnectorSuccess,
  displayErrorMessage,
  setKonnectorState,
  reset,
  resetStatus,
  resetDeactivation,
  activateEmbeddedAppLoading,
  activateLoading,
  activateAppSuccess,
  deAactivateEmbeddedAppLoading,
  deactiveLoading,
  deactivateAppSuccess,
  setWorkflowIndex,
  setReorderApps,
  setReorderAppsSuccess,
  setApp
} = konnectorsSlice.actions;

export default konnectorsSlice.reducer;
