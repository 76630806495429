import React from "react";
import { requiredField } from "../constants/fieldList";
import RenderField from "./genericFields";

const RenderDefault: React.FC<any> = ({ fieldList, register, errors }) => {
  const renderFieldList = (fieldList) => {
    return fieldList.map((field) => {
      return <RenderField field={field} register={register} errors={errors} />;
    });
  };
  return (
    <div className="flex flex-col space-y-2">
      <div className="flex w-full space-x-2 justify-between">{renderFieldList(fieldList)}</div>
      <div className="flex space-x-2">
        <div className="flex flex-col space-y-2 border-gray-200 w-1/2 ">
          {[requiredField].map((field) => {
            return (
              <label
                htmlFor={`${field.name}`}
                className="border-[1px] border-gray-300 h-[42px] mr-1 items-center flex rounded p-2"
              >
                <RenderField field={field} register={register} errors={errors} />
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RenderDefault;
