import React from "react";
import { Link, useParams } from "react-router-dom";
// import { setKonnectorState } from "state/konnectors/slices";
import { setKonnectorState } from "state/workFlows/slices";
import { WorkFlowState } from "state/workFlows/types";
// import { KonnectorState } from "state/konnectors/types";
import { useAppDispatch } from "state/store";

const Menu: React.FC<any> = ({ workflowId, setShowModal }) => {
  const { id: appId } = useParams<any>();
  const dispatch = useAppDispatch();
  const onClickHandler = () => {
    dispatch(setKonnectorState(WorkFlowState.EditWorkFlowState));
  };

  return (
    <>
      <div
        className="origin-bottom-left right-0 z-[100] left-[-180px] absolute mt-2 w-56 rounded-sm shadow-lg bg-gray-100 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex={-1}
      >
        <div role="none">
          <Link
            to={`/KompApps/templates/${workflowId}`}
            type="button"
            onClick={onClickHandler}
            className="text-gray-800 cursor-pointer hover:bg-[#E0E4E9] border-b-[1px] border-[#E0E4E9] hover:transition-all block px-4 py-2 text-sm"
            role="menuitem"
            tabIndex={-1}
            id="menu-item-0"
          >
            Edit
          </Link>
          <button
            onClick={() => setShowModal(true)}
            className="text-gray-800 w-full text-left hover:bg-[#E0E4E9] border-b-2 hover:transition-all block px-4 py-2 text-sm"
            role="menuitem"
            tabIndex={-1}
            id="menu-item-1"
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default Menu;
