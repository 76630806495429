import { createSlice } from "@reduxjs/toolkit";
import { ActionMode, FormFieldData, StatusType } from "state/new-konnector/types";
import { InitialState } from "../type";
import { v4 as uuidv4 } from "uuid";
import {
  deleteFormFieldUtils,
  setSubcategoryOrder,
  reArrangeOrderId,
  setFormFieldOrder,
  setOptions,
  setOptionsOrder,
  setSubCategory,
  setItems,
  setOptionData,
} from "utils/formBuilder.utils";
export const initialState: InitialState = {
  currentFormField: { fieldId: "", optionId: -1, subCategoryId: -1 },
  currentFieldType: "text",
  status: StatusType.idle,
  formSettings: {
    formTitle: "",
    formLogo: {
      url: "",
      alignment: "",
      size: "",
    },
    formSubmitHandler: {
      text: "",
      alignment: "",
      size: "",
      bg: "",
      textColor: "",
      redirectUrl: "",
      message: "",
      submitType: "",
      borderRadius: "",
      height: "",
    },
    formBg: "",
  },
  formFields: [],
  rawDataMapping: {},
  saveDataMapping: StatusType.idle,
  showModal: false,
  showOptionModal: false,
  isSaved: false,
};
export const formBuilderSlice = createSlice({
  name: "formBuilder",
  initialState,
  reducers: {
    createField: (state, data) => {
      const formFields: FormFieldData = {
        fieldId: uuidv4(),
        orderId: data.payload.order,
        actionMode: ActionMode.Create,
        description: "",
        sampleData: "",
        fieldName: "",
        inputType: data.payload.type,
        label: "",
        config: {
          requires: false,
          confirmationField: false,
        },
        options: [],
        isSaved: false,
      };
      if (
        formFields.inputType === "select" ||
        formFields.inputType === "dependent" ||
        formFields.inputType === "radio"
      ) {
        formFields.options[0] = { id: 0, label: "", isRepeated: false, subCategory: [] };
      }

      const reOrderFormFields = setFormFieldOrder(formFields, state.formFields);
      state.formFields = reOrderFormFields;
      state.currentFormField.fieldId = formFields.fieldId;
      state.showModal = true;
    },
    setFieldType: (state, data) => {
      state.currentFieldType = data.payload;
    },
    saveFieldSettings: (state, data) => {
      state.showModal = false;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      state.formFields[findFormIndex] = { ...state.formFields[findFormIndex], ...data.payload };
      state.formFields[findFormIndex].fieldId = state.currentFormField.fieldId;
      state.formFields[findFormIndex].isSaved = true;
      state.formFields[findFormIndex].actionMode = ActionMode.View;
      data.payload = { ...data.payload, fieldId: state.currentFormField.fieldId };
      state.currentFormField.fieldId = "";
    },
    setShowModal: (state, data) => {
      state.showModal = data.payload.modal;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === data.payload.id);
      if (!state.formFields[findFormIndex]?.isSaved) {
        state.formFields = state.formFields.filter((field) => field.fieldId !== data.payload.id);
      } else {
        state.formFields[findFormIndex].actionMode = ActionMode.View;
      }
      state.currentFormField = { fieldId: "", optionId: -1, subCategoryId: -1 };
    },
    setShowOptionModal: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      state.showOptionModal = data.payload;
    },
    deleteFormField: (state, data) => {
      const deletedField = state.formFields.find((field) => field.fieldId === data.payload);
      const formField = deleteFormFieldUtils(deletedField, state.formFields);
      state.formFields = formField;
      state.currentFormField = { fieldId: "", optionId: -1, subCategoryId: -1 };
    },
    editField: (state, data) => {
      state.showModal = true;
      state.currentFormField.fieldId = data.payload;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      state.currentFieldType = state.formFields[findFormIndex].inputType;
      state.formFields[findFormIndex].actionMode = ActionMode.Edit;
    },
    editSampleData: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === data.payload.id);
      if (findFormIndex !== -1) state.formFields[findFormIndex].sampleData = data.payload.value;
    },
    editLabelData: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === data.payload.id);
      state.formFields[findFormIndex].label = data.payload.value;
    },
    setCurrentFormField: (state) => {
      state.currentFormField.fieldId = "";
    },
    setCurrentFormOptionId: (state, data) => {
      state.currentFormField.optionId = data.payload;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const findOption = state.formFields[findFormIndex].options.findIndex((opt) => opt.id === data.payload);
      const subCategoryLength = state.formFields[findFormIndex].options[findOption].subCategory?.length;
      if (!subCategoryLength || subCategoryLength === 0) {
        formBuilderSlice.caseReducers.createSubCategory(state, data);
      }
    },
    deleteCurrentFormOptionId: (state) => {
      state.currentFormField.optionId = -1;
    },
    setCurrentSubCategoryId: (state, data) => {
      const currentValue = state.currentFormField;
      state.currentFormField.subCategoryId = data.payload;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const itemsLength =
        state.formFields[findFormIndex].options[currentValue.optionId].subCategory[currentValue.subCategoryId].items
          .length;
      if (!itemsLength || itemsLength === 0) {
        formBuilderSlice.caseReducers.createItems(state, data);
      }
    },
    deleteCurrentFormSubCategoryId: (state) => {
      state.currentFormField.subCategoryId = -1;
    },
    createOptions: (state) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const { option, optionIndex } = setOptions(state.formFields[findFormIndex].options);
      state.formFields[findFormIndex].options = option;
      state.currentFormField = { ...state.currentFormField, optionId: -1, subCategoryId: -1 };
      state.showOptionModal = true;
    },
    saveOptionData: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const setOption = setOptionData(state.formFields[findFormIndex].options, data.payload.value);
      if (setOption.isReapted === -1) {
        state.status = StatusType.failed;
        state.formFields[findFormIndex].options.find((opt) => opt.id === data.payload.id).isRepeated = true;
        state.formFields[findFormIndex].options[setOption.index].isRepeated = true;
      } else {
        state.status = StatusType.idle;
        state.formFields[findFormIndex].options.find((opt) => opt.id === data.payload.id).isRepeated = false;
      }
      state.formFields[findFormIndex].options.find((opt) => opt.id === data.payload.id).label = setOption.value;
    },
    deleteOption: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const findOption = state.formFields[findFormIndex].options.find((opt) => opt.id === data.payload);
      state.formFields[findFormIndex].options = setOptionsOrder(findOption, state.formFields[findFormIndex].options);
      state.currentFormField.optionId = -1;
    },
    setActionMode: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === data.payload);
      state.formFields[findFormIndex].actionMode = data.payload;
    },
    setOrderId: (state, data) => {
      state.formFields = reArrangeOrderId(
        data.payload.source,
        data.payload.destination,
        data.payload.fieldId,
        state.formFields
      );
    },
    editForm: (state, data) => {
      state.formFields = data.payload.formFields;
      state.formSettings = data.payload.formSettings;
    },
    createSubCategory: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const findOption = state.formFields[findFormIndex].options.findIndex((opt) => opt.id === data.payload);
      const { subCategory, subCategoryIndex } = setSubCategory(
        state.formFields[findFormIndex].options[findOption].subCategory
      );
      state.formFields[findFormIndex].options[findOption].subCategory = subCategory;
      state.currentFormField.optionId = state.formFields[findFormIndex].options[findOption].id;
      // state.currentFormField.subCategoryId = subCategoryIndex;
    },
    saveSubCategoryData: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const currentValue = state.currentFormField;
      state.formFields[findFormIndex].options[currentValue.optionId].subCategory[data.payload.id].label =
        data.payload.value;
    },
    deleteSubcategory: (state, data) => {
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      state.formFields[findFormIndex].options[state.currentFormField.optionId].subCategory = setSubcategoryOrder(
        state.formFields[findFormIndex].options[state.currentFormField.optionId].subCategory.filter(
          (subC) => subC.id !== data.payload
        )
      );
      state.currentFormField.subCategoryId = -1;
    },
    createItems: (state, data) => {
      const currentValue = state.currentFormField;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      const { item } = setItems(
        state.formFields[findFormIndex].options[currentValue.optionId].subCategory[currentValue.subCategoryId].items
      );
      state.formFields[findFormIndex].options[currentValue.optionId].subCategory[currentValue.subCategoryId].items =
        item;
    },
    saveItemsData: (state, data) => {
      const currentValue = state.currentFormField;
      const findFormIndex = state.formFields.findIndex((field) => field.fieldId === state.currentFormField.fieldId);
      state.formFields[findFormIndex].options[currentValue.optionId].subCategory[currentValue.subCategoryId].items[
        data.payload.id
      ].label = data.payload.value;
    },
    saveFormData: (state) => {
      state.isSaved = true;
      state.status = StatusType.success;
    },
    setFormTitle: (state, data) => {
      state.formSettings.formTitle = data.payload;
    },
    setFormLogo: (state, data) => {
      state.formSettings.formLogo = { ...state.formSettings.formLogo, ...data.payload };
    },
    setFormBg: (state, data) => {
      state.formSettings.formBg = data.payload;
    },
    setFormIconSize: (state, data) => {
      state.formSettings.formLogo.size = data.payload;
    },
    setFormOnsubmitHandler: (state, data) => {
      state.formSettings.formSubmitHandler = data.payload;
    },
    setFormIconAlignment: (state, data) => {
      state.formSettings.formLogo.alignment = data.payload;
    },
    setFormStatus: (state, data) => {
      state.status = data.payload;
    },
    setFormSubmitType: (state, data) => {
      state.formSettings.formSubmitHandler.submitType = data.payload;
    },
    setFormSubmitUrlMsg: (state, data) => {
      if (state.formSettings.formSubmitHandler.submitType === "url") {
        state.formSettings.formSubmitHandler.redirectUrl = data.payload;
      } else {
        state.formSettings.formSubmitHandler.message = data.payload;
      }
    },
    reset: () => initialState,
  },
});
export const {
  createField,
  setFieldType,
  saveFieldSettings,
  setShowModal,
  editField,
  deleteFormField,
  editSampleData,
  setCurrentFormField,
  setCurrentFormOptionId,
  deleteCurrentFormOptionId,
  setCurrentSubCategoryId,
  deleteCurrentFormSubCategoryId,
  editLabelData,
  setShowOptionModal,
  createOptions,
  saveOptionData,
  deleteOption,
  setOrderId,
  editForm,
  createSubCategory,
  saveSubCategoryData,
  deleteSubcategory,
  createItems,
  saveItemsData,
  saveFormData,
  setFormStatus,
  setFormBg,
  setFormLogo,
  setFormTitle,
  setFormIconSize,
  setFormIconAlignment,
  setFormOnsubmitHandler,
  setFormSubmitType,
  setFormSubmitUrlMsg,
  reset,
} = formBuilderSlice.actions;
export default formBuilderSlice.reducer;
