

export interface InitialState {
    card: {
        background: any;
        hover: any
        text: any
        heigth: any
        width: any
        radius: any
        bordercolour: any
        cardSnippet: string
        view: any
        gridsize: any
        cardSnippetStatus: boolean
    },
    template: {
        background: any;
        hover: any
        text: any
        heigth: any
        width: any
        radius: any
        templateSnippet: string
        templateSnippetStatus: boolean
        togglecolour: any
    }
    layout: {
        heigth: any
        width: any
    }
    banner: {
        width: any
        heigth: any
        radius: any
        successMsg: any
        failureMsg: any
        bordercolour: any
    }
    logo: {
        url: any
        size: any
        colour: any
    }
    customization: any
    cardState: boolean
    saveStatus: StatusType
    tabModalStatus: boolean
}
export enum StatusType {
    idle = "idle",
    loading = "loading",
    success = "success",
    failed = "failed",
}

export enum TabState {
    Logo = "logo",
    Card = "card",
    Template = "template",
    Layout = "layout"
}
// export interface Customisation2 {
//   redirectUrl: string;
//   textColor: string;
//   alignment: string;
//   height: string;
//   borderRadius: string;
//   size: string;
//   submitType: string;
//   message: string;
//   text: string;
//   bg: string;
// }
