import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useAppSelector } from "state/store";
import Select from "react-select";
import { useApps, useAppsByTypes } from "api/appsApi";
import { useDispatch } from "react-redux";
import { setApp, setAppLoading } from "state/auth/slices";
import { StatusType } from "state/auth/types";
import { LoadingIcon } from "common/components/Icons";
import { mutate } from "swr";
import Spinner from "common/components/Spinner";

const NewKonnectorModal: React.FC<any> = ({ visible, closeModalHandler, text }) => {
    // const sourceApp = useAppSelector((state) => state.auth.redirectApp);
    const currentPage: any = useAppSelector((state) => state.account.currentPage);
    const status = useAppSelector((state) => state.auth.status);
    const dispatch = useDispatch();
    const history = useHistory();
    const [error, setError] = useState(false);
    const [sourceApp, setSourceApp] = useState("");
    const appType = useAppSelector((state) => state.konnectors.appType);
    const { data: apps, error: appsError } = useApps();

    const { data: appData, error: err } = useAppsByTypes(currentPage, appType);
    const renderApps = () => {
        return apps?.data?.items?.map((app) => ({
            label: app.appName,
            value: app.id,
        }));
    };
    const onChangeAppHandler = (data) => {
        setSourceApp(data.value)
        dispatch(setApp(data.value));
    };
    const saveHandler = () => {
        history.push(`/KompApps/templates?appId=${sourceApp}`);
    };

    const borderStyle = {
        control: (base) => ({
            ...base,
            border: error ? "1px solid #ff8b67" : "1px solid #cccccc",
            boxShadow: error ? "0px 0px  #ff8b67" : "none",
        }),
    };

    const closeHandler = () => {
        // history.push(`//admin/eventlogs`);
    };
    useEffect(() => {
        if (status === StatusType.success) {
            const pageNumber = 1;
            const APP_PAGE_LIMIT = 50;
            mutate(`/apps/app-type/${appType}?pageNumber=${pageNumber}&pageSize=${APP_PAGE_LIMIT}`);
        }
    }, [status]);
    if (!apps) return <LoadingIcon />;

    return (
        <>
            {visible && (
                <>
                    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-96 bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                                    <h5 className="text-lg font-semibold">Select App</h5>
                                    <button
                                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                    // onClick={() => setShowModal(false)}
                                    >
                                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <Select
                                        options={renderApps()}
                                        value={renderApps().filter(function (option) {
                                            return option.value === sourceApp;
                                        })}
                                        onChange={onChangeAppHandler}
                                        styles={borderStyle}
                                    />
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={closeModalHandler}
                                    >
                                        Close
                                    </button>
                                    <button
                                        className="bg-komp-secondary-color text-white active:bg-komp-secondary-color font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={saveHandler}
                                    >
                                        <>
                                            {
                                                "Create Konnector"
                                            }
                                        </>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </>
    );
};

export default NewKonnectorModal;
