import { useForm } from "react-hook-form";
import SelectInputSchema from "../Action/DataMapping/DynamicInputfields";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { ParentProps } from "state/new-konnector/types";

const InputSchema: React.FC<any> = ({ inputFields, sourceEvent, konnectorSlice, repeater }) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const parentId = currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source;
  const renderFields = (): JSX.Element[] => {
    return inputFields?.data.map((field, index) => {
      return (
        <SelectInputSchema
          field={field}
          index={index}
          dynamicInputfields={inputFields}
          konnectorSlice={konnectorSlice}
        />
      );
    });
  };

  useEffect(() => {
    dispatch(konnectorSlice.fetchDynamicInputFieldsLoading({ parentId }));
  }, [sourceEvent]);

  return (
    <>
      <div>{inputFields?.data.length > 0 && renderFields()}</div>
    </>
  );
};

export default InputSchema;
