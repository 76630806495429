import React from "react";
import { useAppSelector, useAppDispatch } from "state/store";
import { EditIcon } from "./Icons";
import { TrashIcon } from "@heroicons/react/outline";
import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import ActionConfiguration from ".";
import { ActionMode, AppRole, KonnectorAppState } from "state/new-konnector/types";
import { AccountProps, AppProps } from "pages/KonnectorPage/types";
import KonnectifyEngine from "../KonnectifyEngine";
import Accounts from "../Accounts";
import DataMapping from "./DataMapping";
import Apps from "../Apps";

interface ConfigurationDetails {
  action: KonnectorAppState<AppRole.Target>;
  apps: AppProps[];
  accounts: AccountProps[];
  konnectorSlice: any;
}
const ShowConfigurationDetails: React.FC<ConfigurationDetails> = ({
  action,
  apps,
  accounts,
  konnectorSlice,
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const workflowState = useAppSelector((state) => state.workflows.workflowState);
  const currentActionId = useAppSelector((state) => state[workflowState].currentActionId);
  const onClickCloseHandler = () => {
    dispatch(konnectorSlice.updateCurrentActionId());
  };
  const classNames = {
    primary:
      action.actionMode === ActionMode.View
        ? "flex justify-between bg-white items-center p-5 rounded-xl border-[1px] border-gray-300 mt-5 transition-all"
        : "flex justify-between bg-primary-color items-center p-5  rounded-xl mt-5 transition-all",
    text: action.actionMode === ActionMode.View ? "text-lg" : "text-lg text-white",
  };
  const renderActions = (): JSX.Element => {
    return (
      <>
        {action.actionMode !== ActionMode.Create && (
          <div key={action.id} className={classNames.primary}>
            <div className="px-2 w-[70px]">
              <img className="w-full" src={action.iconUrl} alt="" />
            </div>
            <div className="mr-auto px-5">
              <p className={classNames.text}>
                {action.appName}
                <span className="ml-3  text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-gray-300 text-komp-hover-color">
                  Action
                </span>
                <span className="ml-3  text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-gray-300 text-komp-hover-color">
                  {action.connectionName}
                </span>
                <span className="bg-gray-100 text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300">
                  {action.eventId}
                </span>
              </p>
            </div>
            <div className="flex">
              {action.actionMode === ActionMode.View && (
                <>
                  {action.shouldReconfigure ? (
                    <Tooltip text="Please reconfigure to proceed.">
                      <button className="cursor-pointer ">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M12 1C5.925 1 1 5.925 1 12C1 18.075 5.925 23 12 23C18.075 23 23 18.075 23 12C23 5.925 18.075 1 12 1ZM13 7C13 6.73478 12.8946 6.48043 12.7071 6.29289C12.5196 6.10536 12.2652 6 12 6C11.7348 6 11.4804 6.10536 11.2929 6.29289C11.1054 6.48043 11 6.73478 11 7V13C11 13.2652 11.1054 13.5196 11.2929 13.7071C11.4804 13.8946 11.7348 14 12 14C12.2652 14 12.5196 13.8946 12.7071 13.7071C12.8946 13.5196 13 13.2652 13 13V7ZM13 16.5C13 16.2348 12.8946 15.9804 12.7071 15.7929C12.5196 15.6054 12.2652 15.5 12 15.5C11.7348 15.5 11.4804 15.6054 11.2929 15.7929C11.1054 15.9804 11 16.2348 11 16.5V17C11 17.2652 11.1054 17.5196 11.2929 17.7071C11.4804 17.8946 11.7348 18 12 18C12.2652 18 12.5196 17.8946 12.7071 17.7071C12.8946 17.5196 13 17.2652 13 17V16.5Z"
                            fill="#FF0000"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  ) : null}
                  <button
                    className="cursor-pointer ml-3"
                    onClick={() => dispatch(konnectorSlice.editAction(action.id))}
                  >
                    <EditIcon />
                  </button>
                  <Tooltip text="Delete this action">
                    <button className="cursor-pointer ml-3">
                      <TrashIcon className="h-5 w-5" onClick={() => dispatch(konnectorSlice.deleteAction(action.id))} />
                    </button>
                  </Tooltip>
                </>
              )}
              {action.actionMode !== ActionMode.View && action.eventId && (
                <button
                  onClick={onClickCloseHandler}
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-sm p-1.5 ml-auto inline-flex justify-end dark:hover:bg-gray-800 dark:hover:text-white"
                  data-modal-toggle="popup-modal"
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        )}
        {action.actionMode === ActionMode.Edit && currentActionId && (
          <section className="px-10 py-5 flex flex-col bg-white rounded-md">
            <span className="bg-primary-color w-16  text-white text-xs font-semibold mb-3 px-2.5 py-0.5 rounded-sm dark:primary-color dark:text-white">
              Action
            </span>

            <ActionConfiguration apps={apps} targetDetails={action} konnectorSlice={konnectorSlice} />
            <section className="flex justify-start">
              {!action.appId ? (
                <>
                  <KonnectifyEngine konnectorSlice={konnectorSlice} workflowState={workflowState} />
                  <div className="w-[90%] ml-auto">
                    {/* <Accounts accounts={accounts} configurationType={"action"} /> */}
                    <Apps apps={apps} configurationType={"action"} />
                  </div>
                </>
              ) : (
                action.eventId && <DataMapping action={action} konnectorSlice={konnectorSlice} />
              )}
            </section>
          </section>
        )}

        {action.actionMode === ActionMode.Create && currentActionId && (
          <section className="px-10 py-5 mt-5 flex flex-col bg-white rounded-md">
            <span className="bg-primary-color w-16  text-white text-xs font-semibold mb-3 px-2.5 py-0.5 rounded-sm dark:primary-color dark:text-white">
              Action
            </span>

            <button
              onClick={onClickCloseHandler}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm -mt-8 text-sm p-1.5 ml-auto inline-flex justify-end dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-toggle="popup-modal"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <ActionConfiguration apps={apps} targetDetails={action} konnectorSlice={konnectorSlice} />
            <section className="flex justify-start">
              {!action.appId ? (
                <>
                  <KonnectifyEngine konnectorSlice={konnectorSlice} workflowState={workflowState} />
                  <div className="w-[90%] ml-auto">
                    <Accounts accounts={accounts} configurationType={"action"} konnectorSlice={konnectorSlice} />
                    <Apps apps={apps} configurationType={"action"} konnectorSlice={konnectorSlice} />
                  </div>
                </>
              ) : (
                action.eventId && <DataMapping action={action} konnectorSlice={konnectorSlice} />
              )}
            </section>
          </section>
        )}
      </>
    );
  };

  return <>{renderActions()}</>;
};

export default ShowConfigurationDetails;
