import React from 'react';
import { useAppSelector } from 'state/store';
import FormFieldLabel from './FormFieldLabel';
import { fieldList } from '../constants/fieldList';
import { Droppable } from 'react-beautiful-dnd';
import RenderFields from './FormFieldItems';
import { DragDropIcon } from '../Icons/DragDropIcon';
import { ActionMode } from 'state/edit-konnector/types';
import { Tab } from '@headlessui/react';

const FormFieldItems: React.FC<any> = () => {
	const formField = useAppSelector((state) => state.formBuilder.formFields);
	const actionMode = formField.find(
		(field) => field.actionMode === ActionMode.Create || field.actionMode === ActionMode.Edit
	);
	function classNames(...classes) {
		return classes.filter(Boolean).join(' ');
	}
	return (
		<>
			<div className={`flex w-full bg-gray-50 h-full justify-center `}>
				<Droppable droppableId="nullDrop" isDropDisabled={true}>
					{(provided) => (
						<div
							className="flex-col w-1/4 space-y-2 bg-white p-1 flex justify-center"
							ref={provided.innerRef}
							{...provided.droppableProps}
						>
							<div className="flex flex-col justify-start space-y-3 h-full">
								<div className="text-xl p-3 text-center font-semibold border-b-2 text-gray-500">
									Form Builder
								</div>

								<div className="flex flex-col  space-y-3 h-full">
									{fieldList.map((field, index) => {
										return <FormFieldLabel field={field} index={index} />;
									})}
								</div>
								{provided.placeholder}
							</div>
						</div>
					)}
				</Droppable>

				<div className={'flex flex-col w-3/4 h-11/12 bg-gray-50 items-center overflow-y-scroll'}>
					<Droppable droppableId="formField">
						{(provided) => (
							<div
								className="flex flex-col  p-4 w-full space-y-2   mt-10 items-center h-full transition-all"
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								{formField?.map((field, index) => {
									return <RenderFields field={field} index={index} />;
								})}
								{provided.placeholder}
							</div>
						)}
					</Droppable>
					{formField.length === 0 && (
						<div className="fixed flex flex-col items-center justify-center h-[calc(95vh-5rem)] ">
							<DragDropIcon />
							<div className="text-2xl font-bold text-gray-500">Drag and drop components</div>
						</div>
					)}
				</div>
			</div>
			{actionMode && <div className="opacity-10 fixed bottom-0  top-0 inset-x-0 z-40 bg-black"></div>}
		</>
	);
};

export default FormFieldItems;
