import React, { useEffect, useState } from "react";
import SingleSelectField from "common/components/FormFields/SingleSelect";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { setSourceIdLabel } from "utils/konnectorUtils";
import { getSourceIds } from "utils/konnectorStateUtils";

export default function SourceTypes({ field, register, control, watch, errors }) {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const [selectFieldType, setSelectFieldType] = useState(true);
  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);
  useEffect(() => {
    if (fieldType === "fixed") {
      setSelectFieldType(false);
    } else {
      setSelectFieldType(true);
    }
  }, [fieldType]);
  const sourceIds = useAppSelector(getSourceIds);
  console.log({ sourceIds });

  const getFieldOptions = () => {
    return new Field(field).format();
  };
  return (
    <div className="flex justify-center w-[30%] self-end">
      <div className="mb-3 w-full">
        {selectFieldType && (
          <SingleSelectField
            placeholder="Choose source type"
            choices={sourceIds}
            {...getFieldOptions()}
            name={field.propName + "__field_source_type"}
            register={register}
            errors={errors}
          />
        )}
      </div>
    </div>
  );
}
