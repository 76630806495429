import React, { useEffect, useState } from "react";
import SubLinks from "./SubLinks";
import OutsideClickHandler from "react-outside-click-handler";
import { getAuth } from "state/selectors";
import { useAppDispatch, useAppSelector } from "state/store";
import { CreateNewMenu } from "./CreateNewMenu";
import { useParams, useHistory } from "react-router-dom";
import { KonnectorType } from "pages/KonnectorList/types";
import { useApp, useApps } from "api/appsApi";
import Warning from "../Warning";
import Error from "../Error";
import useKonnectors, { useKonnector } from "api/konnectorsApi";
import { useCurrentUserApi } from "api/authApi";
import { setPlanDetailsData } from "utils/authUtils";
import { setPlanDetails } from "state/payment/slices";
import Spinner from "common/components/Spinner";
import { loginSuccess } from "state/auth/slices";
import { PrefilData, setPrimaryBackground } from "state/Embedded-markteplace/AdminSettings/slices";
import { setPortaCustomization } from "state/Embedded-markteplace/ThemeSettings/slices";

interface HeaderProps {
  name: string;
}

const Header: React.FC<HeaderProps> = ({ name }) => {
  const [showProfileSettings, setShowProfileSettings] = useState(false);
  const { currentUser } = useAppSelector(getAuth);
  const dispatch = useAppDispatch();
  const konnectorType = useAppSelector((state) => state.editKonnector.data.konnectorType);
  const konnectorName = useAppSelector((state) => state.editWorkFlow.data.name);
  const hasConfigurationSaved = useAppSelector((state) => state.editKonnector.hasConfigurationSaved);

  const { id } = useParams<any>();
  const { data: apps, error: error } = useApp(id);
  const { data: konnector, error: errors } = useKonnector(id);
  let { data: user, error: userError, mutate } = useCurrentUserApi();

  useEffect(() => {
    mutate(() => {
      if (user) {
        const planDetails = setPlanDetailsData(user?.data);
        dispatch(loginSuccess(user.data));
        dispatch(setPlanDetails(planDetails));
        dispatch(setPrimaryBackground(currentUser.portalConfigration.primary_bgColor));
      }
      return user;
    });
  }, []);

  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };

  const toggleProfileSettings = (e) => {
    setShowProfileSettings((value) => !value);
  };
  if (!user || !user?.data) {
    return <Spinner />;
  }

  return (
    <div className="fixed flex flex-col w-full z-20 ">
      <div className="pl-16">
        <Warning />
        <Error user={user} />
      </div>

      <header className="flex justify-between w-full items-center bg-white border-b-2 border-gray-200  h-20 rounded-sm pl-16 ">
        {!id ? (
          <h4 className="text-xl mx-2 text-primary-color  font-medium">{name}</h4>
        ) : konnectorType === KonnectorType.Form && !hasConfigurationSaved ? (
          <h4 className=" flex items-center justify-center  cursor-pointer text-xl mx-2 text-primary-color font-medium">
            <span className="hover:text-blue-600">{name}</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="gray">
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span className="mt-2"></span>
            {apps?.data.appName ? apps?.data.appName : konnector?.data?.name ? konnector?.data?.name : id}
          </h4>
        ) : (
          <h4 className=" flex items-center justify-center  cursor-pointer text-xl mx-2 text-primary-color font-medium">
            <span className="hover:text-blue-600" onClick={handleClick}>
              {name}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="gray">
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <span className="mt-2"></span>
            {apps?.data.appName ? apps?.data.appName : konnector?.data?.name ? konnector?.data?.name : id}
          </h4>
        )}
        <div className="flex ml-auto">
          <CreateNewMenu user={user} />
        </div>
        <div className="flex items-center">
          <div className="sec relative cursor-pointer self-center p-2 pr-6">
            <OutsideClickHandler
              onOutsideClick={() => {
                setShowProfileSettings(false);
              }}
            >
              <button
                type="button"
                onClick={toggleProfileSettings}
                className="h-10 w-10 flex justify-center items-center bg-primary-color text-white p-0.5 rounded-full"
              >
                {currentUser.name ? (
                  <span>{currentUser.name.charAt(0).toUpperCase()}</span>
                ) : (
                  <span>{currentUser.email.charAt(0).toUpperCase()}</span>
                )}
              </button>
              {showProfileSettings && <SubLinks />}
            </OutsideClickHandler>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
