import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AuthState, LoadingType, PlanType } from "../types";
import { StatusType } from "state/new-konnector/types";

export const initialState: AuthState = {
  currentUser: {},
  isAuthenticated: false,
  error: "",
  externalLogin: false,
  isVerified: true,
  loading: true,
  isTrial: false,
  currentPlan: PlanType.null,
  mailSent: false,
  loadingType: LoadingType.idle,
  resetPassword: false,
  forgotPasswordError: "",
  resetPasswordError: "",
  showWarning: true,
  user: [],
  app: "",
  status: StatusType.idle,
  appStatus: false,
  categoryStatus: StatusType.idle,
  categoryArray: [],
  isModal: false,
  isEditModal: false,
  isReorderModal: false,
  categoryValue: "",
  isDeleteModal: false,
  editCategoryStatus: StatusType.idle,
  deleteValue: {},
  deleteCategoryStatus: StatusType.idle,
  categoryType: "All Apps",
  displayCategoryList: [],
  accountCredentials: {}
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setApp: (state, data) => {
      state.app = data.payload;
    },
    login: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
      state.isAuthenticated = true;
      state.loading = false;
      state.app = payload.staticApp;
      state.categoryArray = payload.appCategory
    },
    loginError: (state, { payload }: PayloadAction<string>) => {
      state.error = payload;
      state.isAuthenticated = false;
      state.loading = false;
    },
    logout: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state) => {
      state.isAuthenticated = false;
      state.currentUser = {};
      state.error = "";
      state.loading = false;
    },
    setExternalLogin: (state, data) => {
      state.externalLogin = data.payload;
    },
    isVerified: (state) => {
      state.loadingType = LoadingType.sentMail;
    },

    isMailSent: (state) => {
      state.mailSent = true;
      state.loadingType = LoadingType.idle;
    },

    isVerifiedSuccess: (state) => {
      state.isVerified = true;
      state.isTrial = true;
      state.currentPlan = PlanType.paid;
      state.loadingType = LoadingType.idle;
      state.planExpired = false;
    },

    isVerifiedError: (state, { payload }: PayloadAction<any>) => {
      state.error = payload;
      state.isVerified = false;
      state.loadingType = LoadingType.idle;
    },

    signIn: (state) => {
      state.signInLoading = true;
    },

    forgotPasswordLoading: (state, data) => {
      state.loadingType = LoadingType.forgotPassword;
    },

    forgotPasswordSuccess: (state, { payload }: PayloadAction<string>) => {
      state.mailSent = true;
      state.forgotPasswordError = "";
      state.loadingType = LoadingType.idle;
    },

    forgotPasswordError: (state, data: PayloadAction<string>) => {
      state.forgotPasswordError = data.payload;
      state.loadingType = LoadingType.idle;
    },

    resetPasswordLoading: (state, data) => {
      state.loadingType = LoadingType.resetPassword;
    },

    resetPasswordSuccess: (state, { payload }: PayloadAction<object>) => {
      state.resetPassword = true;
      state.loadingType = LoadingType.idle;
    },

    resetPasswordError: (state, data: PayloadAction<string>) => {
      state.resetPasswordError = data.payload;
      state.loadingType = LoadingType.idle;
    },

    setShowWarning: (state, data) => {
      state.showWarning = data.payload;
    },
    setUserData: (state, data) => {
      state.currentUser = data.payload;
      state.isAuthenticated = true;
    },
    sendUserApiLoading: (state, data) => {
      console.log("sds");
    },
    sendUserApi: (state, data) => {
      state.user = data.payload;
    },
    setAppLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    setAppSuccess: (state, data) => {
      state.status = StatusType.success;
      state.appStatus = true;
    },
    setAppStatus: (state) => {
      state.appStatus = true;
    },
    setCategoryLoading: (state, data) => {
      state.categoryStatus = StatusType.loading
    },
    setCategorySuccess: (state, data) => {
      state.categoryStatus = StatusType.success;
      state.categoryArray = data.payload
    },
    setModal: (state, data) => {
      state.categoryStatus = StatusType.idle;
      state.isModal = true
    },
    setModalFalse: (state) => {
      state.isModal = false
    },
    setEditModal: (state, data) => {
      state.editCategoryStatus = StatusType.idle
      state.isEditModal = true
      state.categoryValue = data.payload
    },
    editCategoryLoading: (state, data) => {
      state.editCategoryStatus = StatusType.loading
      state.displayCategoryList = data.payload.value
    },
    setDeleteModal: (state, data) => {
      state.deleteCategoryStatus = StatusType.idle;
      state.isDeleteModal = true
      state.deleteValue = data.payload
    },
    setEditCategorySuccess: (state, data) => {
      state.editCategoryStatus = StatusType.success;
      state.categoryType = data.payload.categoryType
      state.categoryArray = data.payload.appCategory
    },
    setEditModalFalse: (state) => {
      state.isEditModal = false
    },
    setDeleteCategoryLoading: (state, data) => {
      state.deleteCategoryStatus = StatusType.loading;
    },
    setDeleteCategorySuccess: (state, data) => {
      state.deleteCategoryStatus = StatusType.success;
      state.categoryArray = data.payload
    },
    setDeleteModalFalse: (state) => {
      state.isDeleteModal = false
    },
    setCategoryType: (state, data) => {
      state.categoryType = data.payload.label;
      state.displayCategoryList = data.payload.value;
    },
    setItemIndex: (state, data) => {

    },
    setItemIndexStatus: (state, data) => {
      state.displayCategoryList = data.payload
    },
    setAppValue: (state, data) => {
      state.categoryArray = data.payload.data.appCategory
      state.displayCategoryList = data.payload.data.value
    },
    setReorderModal: (state, data) => {
      state.isReorderModal = data.payload
    },
    setReorderCategory: (state, data) => {
      state.categoryArray = data.payload
    },
    setReorderCategoryArray: (state, data) => {

    },
    setAppCredentials: (state, data) => {
      state.accountCredentials = data.payload
    }

  },
});

export const {
  login,
  loginSuccess,
  setAppStatus,
  loginError,
  logout,
  logoutSuccess,
  isVerified,
  signIn,
  setExternalLogin,
  isVerifiedSuccess,
  isVerifiedError,
  isMailSent,
  forgotPasswordLoading,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordLoading,
  resetPasswordSuccess,
  resetPasswordError,
  setShowWarning,
  setUserData,
  sendUserApi,
  sendUserApiLoading,
  setApp,
  setAppLoading,
  setAppSuccess,
  setAppCredentials,
  setCategoryLoading,
  setCategorySuccess,
  setModal,
  setModalFalse,
  setEditModal,
  editCategoryLoading,
  setDeleteModal,
  setEditCategorySuccess,
  setEditModalFalse,
  setDeleteCategoryLoading,
  setDeleteCategorySuccess,
  setDeleteModalFalse,
  setCategoryType,
  setItemIndex,
  setItemIndexStatus,
  setAppValue,
  setReorderModal,
  setReorderCategory,
  setReorderCategoryArray
} = authSlice.actions;

export default authSlice.reducer;
