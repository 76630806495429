import React, { useEffect } from "react";

const Modal: React.FC<any> = ({ children, title, visible, setVisible }) => {
	const [showModal, setShowModal] = React.useState(visible);
	useEffect(() => {
		setShowModal(visible);
	}, [visible]);

	useEffect(() => {
		if (!showModal) {
			setVisible(false);
		}
	}, [showModal]);

	return (
		<>
			{visible && (
				<>
					<div
						id="defaultModal"
						className="flex overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-4 z-50 justify-center items-center h-modal md:h-full md:inset-0"
					>
						<div className="relative px-4 w-full max-w-2xl h-full md:h-auto">
							<div className="relative bg-white rounded-sm shadow">
								<div className="flex justify-between items-start p-5 rounded-sm bg-white border-b ">
									<h3 className="text-xl font-semibold text-gray-900 lg:text-2xl ">{title}</h3>
									<button
										type="button"
										className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-sm p-1.5 ml-auto inline-flex items-center "
										data-modal-toggle="defaultModal"
										onClick={() => setVisible(false)}
									>
										<svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fillRule="evenodd"
												d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
												clipRule="evenodd"
											/>
										</svg>
									</button>
								</div>
								<div className="p-6 space-y-6 h-[400px] overflow-y-scroll">{children}</div>
							</div>
						</div>
					</div>
					<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
				</>
			)}
		</>
	);
};

export default Modal;
