import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";

const SingleSelectField: React.FC<Options | any> = ({
  choices,
  label,
  placeholder,
  defaultValue,
  pattern,
  name,
  required,
  register,
  errors,
}) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  const renderOptions = (): JSX.Element[] => {
    return choices.map((choice) => <option value={choice.label}>{choice.label}</option>);
  };

  return (
    <div>
      {required ? (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-500">
          {label ? label : name} <span className="text-red-500 ">*</span>
        </label>
      ) : (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label ? label : name}
        </label>
      )}
      <select
        placeholder="Choose an option"
        value={defaultValue}
        className="bg-gray-50  border rounded border-gray-300 text-gray-500 text-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5"
        {...register(name, getFormConfigs())}
      >
        <option value="">Select option</option>
        {renderOptions()}
      </select>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      />
    </div>
  );
};

export default SingleSelectField;
