import React from "react";
import ReactPaginate from "react-paginate";
import { getEventLogsCurrentPage } from "state/event-logs/selectors";
import { updateCurrentPage } from "state/event-logs/slices";
import { useAppDispatch, useAppSelector } from "state/store";

const limit = 10;
const Pagination: React.FC<any> = ({ total }) => {
  const currentPage: any = useAppSelector(getEventLogsCurrentPage);
  const pageCount = Math.ceil(total / limit);
  const dispatch = useAppDispatch();
  const handlePageClick = (data) => {
    let currentPage = data.selected + 1;
    dispatch(updateCurrentPage(currentPage));
  };

  const getDisabledClasses = (type = "next") => {
    if ((type === "prev" && currentPage === 1) || (type === "next" && currentPage === pageCount)) {
      console.log("page count ")
      return " cursor-not-allowed";
    }
    return "";
  };
  return (
    <ReactPaginate
      forcePage={currentPage - 1}
      previousLabel={
        <svg xmlns="http://www.w3.org/2000/svg" className={"h-4 w-4 mt-1 mb-1" + getDisabledClasses("prev")} color="primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
        </svg>
      }
      nextLabel={
        <svg xmlns="http://www.w3.org/2000/svg" className={"h-4 w-4 mt-1 mb-1 " + getDisabledClasses()} color="primary-color" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
        </svg>
      }
      breakLabel={"..."}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      onPageChange={handlePageClick}
      containerClassName={"pagination -mt-2 flex justify-center"}
      pageClassName={"page-item ml-2   rounded-sm text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700"}
      pageLinkClassName={"page-link px-2 cursor-pointer"}
      previousClassName={
        "page-item  px-1 ml-0 bg-gray-300 text-gray-500 bg-white rounded-sm border border-gray-300 hover:bg-gray-50 hover:text-gray-700" + getDisabledClasses("prev")
      }
      previousLinkClassName={"page-link"}
      nextClassName={"page-item ml-2  px-1 bg-gray-300 text-gray-500 bg-white rounded-sm border border-gray-300 hover:bg-gray-50 hover:text-gray-700" + getDisabledClasses()}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item ml-2"}
      breakLinkClassName={"page-link"}
      activeClassName={"text-blue-600 bg-blue-50"}
    />
  );
};
export default Pagination;
