import { KonnectorType } from "pages/KonnectorList/types";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppSelector } from "state/store";
import * as Icons from "common/containers/Layout/components/icons";

const resources = {
  konnectors: "konnectors",
  apps: "apps",
  eventLogs: "event-logs",
  billing: "payments",
  embeddedApps: "KompApps",
  adminSettings: "settings",
  adminEventLogs: "eventlogs",
};

const PrimarySidebar: React.FC<any> = ({ shrinkWidth }) => {
  const isFormGenerated = useAppSelector((state) => state.editKonnector.isFormGenerated);
  const isEmbeddedUser = useAppSelector((state) => state.auth.currentUser.isEmbeddedUser);
  const [active, setActive] = useState(resources.konnectors);
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const konnectorType = useAppSelector((state) => state.editKonnector.data.konnectorType);
  const hasConfigurationSaved = useAppSelector((state) => state.editKonnector.hasConfigurationSaved);
  const history = useHistory();
  const activeClasses = "flex items-center justify-center w-12 h-12 mt-2 text-gray-200 bg-[#405472] rounded";
  useEffect(() => {
    if (history.location.pathname.includes("admin")) {
      setActive(history.location.pathname.split("/")[2]);
    } else {
      setActive(history.location.pathname.split("/")[1]);
    }
  }, []);
  return (
    <nav
      className={`font-inter flex flex-col  items-center ${
        shrinkWidth ? "w-full" : "w-[20%]"
      } min-h-screen overflow-hidden text-gray-400 bg-komp-color`}
    >
      <div className="relative z-20 flex items-center group">
        <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
          <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
            Konnectify
          </span>
        </div>
        <Link to={"/konnectors"} className="flex items-center justify-center mt-6">
          <Icons.Logo />
        </Link>
      </div>
      {konnectorType === KonnectorType.Form && !hasConfigurationSaved && isFormGenerated ? (
        <div className="flex flex-col items-center mt-3 ">
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 flex items-center  mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none shadow-lg text-white whitespace-no-wrap bg-komp-color">
                Apps
              </span>
            </div>
            <div
              className={
                active === resources.konnectors
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Konnectors />
            </div>
          </div>
          <div className="relative z-20 flex items-center hover:transition-all group">
            <div className="fixed left-16 flex items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                Eventlogs
              </span>
            </div>
            <div
              className={
                active === resources.apps
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Apps className={true} />
            </div>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color shadow-lg">
                Admin
              </span>
            </div>
            <div
              className={
                active === resources.eventLogs
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.KompEventLogs />
            </div>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color shadow-lg">
                Billing
              </span>
            </div>
            <div
              className={
                active === resources.billing
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Billing />
            </div>
          </div>
        </div>
      ) : isEmbeddedUser == true ? (
        <div className="flex flex-col items-center mt-10">
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                KOMP Apps
              </span>
            </div>
            <Link
              to={"/KompApps"}
              className={
                active === resources.embeddedApps
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Apps className={active === resources.embeddedApps} />
            </Link>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 flex items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color shadow-lg">
                Event Logs
              </span>
            </div>
            <Link
              to={"/admin/eventlogs"}
              className={
                active === resources.adminEventLogs
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.KompEventLogs className={active === resources.adminEventLogs} />
            </Link>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16  items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                Admin settings
              </span>
            </div>
            <Link
              to={"/admin/settings"}
              className={
                active === resources.adminSettings
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.AdminSettings />
            </Link>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center mt-3 border-t border-gray-700">
          <div className="relative z-20 flex items-center group">
            <Link
              to={"/workflows"}
              className={
                active === resources.konnectors
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Konnectors />
            </Link>
          </div>
          <div className="relative z-20 flex items-center hover:transition-all group">
            <div className="fixed left-16  items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                Apps
              </span>
            </div>
            <Link
              to={"/KompApps"}
              className={
                active === resources.embeddedApps
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.Apps />
            </Link>
          </div>
          <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 flex items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                Event Logs
              </span>
            </div>
            <Link
              to={"/admin/eventlogs"}
              className={
                active === resources.adminEventLogs
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.EventLogs />
            </Link>
          </div>
          {/* <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 flex items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                EmbeddedApps
              </span>
            </div>
            <Link
              to={"/KompApps"}
              className={
                active === resources.billing
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.EmbeddedApps />
            </Link>
          </div> */}
          {/* <div className="relative z-20 flex items-center group">
            <div className="fixed left-16 flex items-center hidden mr-6 group-hover:flex">
              <span className="relative z-10 p-2 text-xs leading-none text-white whitespace-no-wrap bg-komp-color  shadow-lg">
                Billing
              </span>
            </div>
            <Link
              to={"/billing"}
              className={
                active === resources.billing
                  ? activeClasses
                  : "flex items-center justify-center w-12 h-12 mt-2 rounded hover:bg-[#405472] hover:text-gray-300"
              }
            >
              <Icons.EmbeddedBilling />
            </Link>
          </div> */}
        </div>
      )}
    </nav>
  );
};

export default PrimarySidebar;
