import React from "react";

const Productivity: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 15.8 15.8"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs></defs>
        <g id="darkmode_1" data-name="darkmode 1">
          <g id="apps_sub">
            <g>
              <path
                className="cls-1"
                d="M11.41,15.8c-.22,0-.43,0-.65,0-.13,0-.17-.05-.17-.17,0-.23,0-.47,0-.7,0-.11-.03-.18-.15-.21-.24-.06-.48-.16-.69-.29-.11-.06-.17-.04-.25,.04-.16,.17-.34,.34-.5,.51-.08,.08-.14,.09-.22,0-.31-.31-.62-.62-.93-.93-.08-.08-.09-.14,0-.22,.17-.16,.33-.33,.5-.49,.09-.09,.11-.17,.04-.28-.12-.19-.21-.41-.26-.63-.04-.15-.12-.2-.27-.19-.22,0-.43,0-.65,0-.14,0-.19-.05-.19-.19,0-.42,0-.84,0-1.26,0-.14,.06-.19,.19-.19,.23,0,.47,0,.7,0,.11,0,.17-.04,.2-.15,.07-.25,.17-.5,.3-.72,.05-.09,.04-.14-.03-.21-.18-.17-.35-.36-.53-.53-.08-.08-.08-.13,0-.21,.32-.31,.63-.63,.94-.94,.08-.08,.14-.07,.21,0,.17,.18,.35,.34,.52,.52,.08,.08,.14,.09,.23,.03,.22-.13,.45-.22,.69-.29,.12-.03,.16-.1,.16-.22,0-.23,0-.46,0-.68,0-.13,.04-.19,.17-.19,.43,0,.87,0,1.3,0,.13,0,.17,.06,.17,.18,0,.24,0,.48,0,.72,0,.11,.04,.17,.15,.2,.24,.07,.48,.16,.69,.29,.1,.06,.16,.04,.24-.04,.17-.17,.35-.34,.53-.51,.08-.08,.13-.08,.21,0,.31,.32,.63,.63,.94,.94,.08,.08,.07,.14,0,.21-.18,.17-.35,.36-.53,.53-.08,.07-.08,.13-.03,.22,.13,.22,.23,.46,.3,.71,.03,.11,.09,.15,.19,.14,.24,0,.48,0,.72,0,.11,0,.17,.04,.17,.16,0,.44,0,.88,0,1.32,0,.13-.06,.17-.18,.17-.23,0-.47,0-.7,0-.12,0-.18,.04-.21,.15-.07,.24-.16,.48-.29,.69-.06,.1-.04,.16,.04,.24,.18,.17,.34,.35,.52,.52,.09,.08,.09,.14,0,.22-.31,.29-.62,.59-.91,.9-.1,.11-.18,.11-.28,0-.16-.17-.33-.33-.49-.5-.07-.07-.13-.09-.22-.04-.22,.13-.46,.23-.71,.3-.11,.03-.15,.09-.15,.19,0,.24,0,.48,0,.72,0,.12-.05,.18-.17,.17-.22,0-.43,0-.65,0Zm.01-6.2c-1.03,0-1.82,.77-1.82,1.79,0,1.02,.78,1.82,1.78,1.83,1.02,0,1.84-.79,1.85-1.8,0-1.02-.79-1.81-1.8-1.82Z"
              />
              <path
                className="cls-1"
                d="M8.75,4.39c0,.21,0,.42,0,.63,0,.12-.05,.18-.17,.17-.23,0-.47,0-.7,0-.12,0-.18,.04-.21,.16-.06,.23-.15,.46-.28,.66-.08,.13-.05,.21,.05,.31,.16,.15,.31,.32,.47,.47,.09,.08,.1,.15,0,.24-.31,.3-.62,.61-.92,.92-.09,.09-.15,.08-.23,0-.16-.17-.33-.33-.49-.5-.09-.1-.17-.11-.28-.04-.21,.12-.44,.22-.68,.28-.12,.03-.15,.1-.15,.21,0,.23,0,.46,0,.68,0,.13-.05,.18-.18,.18-.42,0-.83,0-1.25,0-.13,0-.18-.05-.18-.18,0-.22,0-.44,0-.67,0-.12-.04-.19-.16-.22-.26-.07-.51-.17-.74-.31-.09-.05-.15-.02-.21,.04-.17,.17-.34,.34-.51,.51-.08,.09-.14,.1-.23,0-.29-.3-.59-.6-.89-.89-.1-.1-.09-.16,0-.25,.17-.16,.32-.32,.49-.48,.08-.08,.09-.16,.04-.26-.13-.23-.24-.47-.3-.72-.03-.11-.1-.13-.19-.12-.23,0-.47,0-.7,0C.06,5.2,0,5.15,0,5.01c0-.42,0-.84,0-1.26,0-.12,.05-.16,.17-.16,.24,0,.48,0,.72,0,.1,0,.16-.02,.19-.13,.08-.27,.18-.53,.32-.77,.05-.09,0-.15-.05-.21-.17-.17-.34-.35-.52-.52-.08-.08-.08-.13,0-.21,.32-.31,.63-.63,.94-.94,.07-.07,.12-.07,.2,0,.17,.18,.35,.35,.52,.52,.08,.08,.15,.1,.25,.04,.22-.13,.46-.22,.71-.29,.11-.03,.16-.09,.15-.21,0-.23,0-.46,0-.68C3.58,.06,3.63,0,3.77,0c.41,0,.82,0,1.23,0,.14,0,.19,.06,.19,.19,0,.23,0,.47,0,.7,0,.1,.03,.16,.13,.19,.25,.06,.48,.17,.71,.29,.12,.07,.2,.04,.28-.05,.15-.16,.32-.31,.47-.47,.08-.09,.15-.1,.24,0,.3,.31,.6,.61,.91,.91,.09,.09,.08,.14,0,.22-.18,.17-.35,.35-.52,.52-.07,.07-.08,.12-.03,.21,.14,.22,.23,.47,.3,.72,.03,.13,.11,.17,.23,.17,.2,0,.4,0,.6,0,.24,0,.24,0,.24,.25,0,.18,0,.36,0,.55Zm-4.38-1.83c-.97-.04-1.84,.84-1.82,1.82,.02,1.01,.82,1.84,1.82,1.84,1.01,0,1.85-.84,1.85-1.85,0-.99-.85-1.85-1.84-1.81Z"
              />
              <path
                className="cls-2 opacity-50"
                d="M2.88,10.53c0,.15-.04,.31-.14,.45-.07,.09-.05,.16,0,.24,.78,1.18,1.83,2.01,3.17,2.48,.08,.03,.15,.05,.23,.08,.16,.06,.23,.19,.18,.33-.04,.13-.18,.19-.34,.15-.47-.14-.91-.33-1.33-.56-.97-.55-1.76-1.29-2.36-2.23-.07-.11-.15-.13-.27-.13-.46-.01-.79-.38-.78-.84,.01-.45,.37-.79,.84-.78,.45,0,.79,.36,.79,.83Z"
              />
              <path
                className="cls-2 opacity-50"
                d="M14.53,5.28c0,.35-.19,.62-.52,.75-.3,.12-.65,.04-.88-.2-.22-.23-.3-.6-.13-.89,.12-.21,.09-.35-.04-.53-.77-1.1-1.79-1.88-3.06-2.33-.09-.03-.19-.06-.27-.1-.13-.06-.19-.16-.15-.31,.04-.13,.15-.17,.27-.15,.23,.04,.45,.13,.66,.22,1.29,.54,2.32,1.39,3.07,2.57,.07,.11,.15,.15,.28,.15,.44,.01,.77,.36,.77,.8Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Productivity;
