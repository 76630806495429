import SingleSelectField from 'common/components/FormFields/SingleSelect';
import { useAppSelector } from 'state/store';
import { Field } from 'utils/appsUtils';
import { setSourceIdLabel } from 'utils/konnectorUtils';

const SourceType: React.FC<any> = ({ register, watchField, errors, value, index, filterIndex }) => {
	const field = { propName: 'field_' + index, propType: 'string', isRequired: true };

	const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
	const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);

	const getSourceIds = (state) => {
		const triggerSourceId = {
			label: setSourceIdLabel(state[konnectorState].data.trigger.outputFields.schema.sourceId),
			value: state[konnectorState].data.trigger.outputFields.schema.sourceId,
		};
		const lookupSourceIds = state[konnectorState].data.lookups.map((lookup) => {
			return {
				label: setSourceIdLabel(lookup.outputFields.schema.sourceId),
				value: lookup.outputFields.schema.sourceId,
			};
		});
		const parseSourceIds = state[konnectorState].data.parseEngine
			.filter((item) => item.id != currentActionId)
			.map((item) => {
				return {
					label: setSourceIdLabel(item.outputFields.schema.sourceId),
					value: item.outputFields.schema.sourceId,
				};
			});

		return [triggerSourceId, ...lookupSourceIds, ...parseSourceIds];
	};
	const sourceIds = useAppSelector(getSourceIds);
	const getFieldOptions = () => {
		return new Field(field).format();
	};
	return (
		<div className="w-[50%]">
			<SingleSelectField
				placeholder="Choose source type"
				choices={sourceIds}
				{...getFieldOptions()}
				name={`source_id`}
				register={register}
				errors={errors}
			/>
		</div>
	);
};

export default SourceType;
