import { InfoIcon } from "common/components/Icons/InfoIcon";
import React from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";

const SelectInputSchema: React.FC<any> = ({ field, konnectorSlice, register, errors }) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);
  const triggerInputschema: any = useAppSelector((state) => state[konnectorState].data.trigger.inputFields);
  const fieldValue = triggerInputschema.dataMapping[field.propName] || "";
  const propValues = field.propValues ? field.propValues : field.propValue;
  const renderFields = propValues.find((val) => val.value === fieldValue);
  const renderEvents = () => {
    return propValues.map((prop) => {
      return { label: prop.label, value: prop.value };
    });
  };
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">{`Select ${field.label}`}</label>
      <Select
        isDisabled={true}
        options={renderEvents()}
        value={renderFields}
        onChange={(e: any) => {
          dispatch(
            konnectorSlice.setSourceEventDataMapping({
              field: field.propName,
              value: e.value,
            })
          );
        }}
      />
      <div className="flex items-center space-x-3 mt-3">
        <InfoIcon />
        <span className="text-gray-700 text-sm">This will be configured by Komp end users</span>
      </div>
    </div>
  );
};

export default SelectInputSchema;
