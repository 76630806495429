import React, { useEffect, useState } from 'react';
import { BlockPicker } from 'react-color';
import { setFormBg } from 'state/form-builder/slices';
import { useAppDispatch, useAppSelector } from 'state/store';

const FormTitle: React.FC<any> = ({ field }) => {
	const dispatch = useAppDispatch();
	const formBg = useAppSelector((state) => state.formBuilder.formSettings.formBg);
	const [color, setColor] = useState('');

	const onChangeColor = (data) => {
		setColor(data.hex);
		dispatch(setFormBg(data.hex));
	};

	useEffect(() => {
		setColor(formBg);
	}, [formBg]);

	return (
		<div className="flex flex-col w-3/4 mt-4 p-2 space-y-2">
			<div className="flex flex-col space-y-4 justify-center">
				<div className="block mb-2 text-sm font-medium text-gray-500">Form Background</div>
				<BlockPicker color={color} onChangeComplete={onChangeColor} />
			</div>
		</div>
	);
};

export default FormTitle;
