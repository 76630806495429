import React from "react";
import { Link, useHistory } from "react-router-dom";
import { getAuth } from "state/selectors";
import { useAppSelector } from "state/store";

const SubLinks: React.FC = () => {
  const { currentUser, externalLogin } = useAppSelector(getAuth);
  const history = useHistory();
  let logutUrl = externalLogin ? `/auth/external_logout` : "/auth/embbed_logout";
  return (
    <div
      className="origin-top-left right-0 z-[100] left-[-180px] absolute mt-2 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabIndex={-1}
    >
      <div role="none">
        {/* Active: "bg-gray-100 text-gray-900", Not Active: "text-gray-700" */}
        <div className="text-gray-800 bg-[#E0E4E9] cursor-text leading-loose block px-4 py-2 text-sm" id="menu-item-3">
          {currentUser.name && <p>{currentUser.name}</p>}
          <p className="text-xs">{currentUser.email}</p>
        </div>
        <Link
          to={"/users/profile"}
          className="text-gray-800 hover:border-gray-300 hover:border-b-transparent hover:bg-[#E0E4E9] border-b-[1px] border-t-[1px] border-[#E0E4E9] hover:transition-all block px-4 py-2 text-sm"
        >
          Profile
        </Link>
        <a
          href={logutUrl}
          onClick={() => {
            history.replace("");
          }}
          className="text-gray-800 hover:bg-[#E0E4E9] border-b-2 hover:transition-all block px-4 py-2 text-sm"
        >
          Sign out
        </a>
      </div>
    </div>
  );
};

export default SubLinks;
