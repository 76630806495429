import React from "react";
import TargetFields from "./TargetFields";
import { AppRole, KonnectorAppState } from "state/edit-konnector/types";

interface ConfigurationDetails {
  lookupDetails: KonnectorAppState<AppRole.Lookup>;
  konnectorSlice: any;
}
const DataMapping: React.FC<ConfigurationDetails> = ({ lookupDetails, konnectorSlice }): JSX.Element => {
  return (
    <div className="w-full">
      <h4 className="text-md my-3 font-semibold text-primary-color">Field Mapping</h4>
      <TargetFields lookupDetails={lookupDetails} konnectorSlice={konnectorSlice} />
    </div>
  );
};

export default DataMapping;
