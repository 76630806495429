import { useAllKonnector } from "api/konnectorsApi";
import React, { useEffect, useState } from "react";
import { getKonnectorsFilterType } from "state/konnectors/selectors";
import { updateFilter } from "state/workFlows/slices";
import { setAppType } from "state/konnectors/slices";

import { FilterType } from "state/konnectors/types";
import { useAppDispatch, useAppSelector } from "state/store";
import { AppSideBarTypes, KonnectorSideBarType } from "../types";
import { Icon } from "../Icon";
import ActiveKonnectors from "../Icon/ActiveKonnector.icon";
import { getworkflowsFilterType, getworkflowsSortBy } from "state/workFlows/selectors";
import { useHistory, useLocation } from "react-router-dom";

const KonnectorSideBar: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const activeClass = "text-white bg-komp-hover-color hover:bg-[#1f324e] opacity-100";
  const defaultClass = "text-gray-300 opacity-50";
  const filterBy = useAppSelector(getworkflowsFilterType);
  const query = new URLSearchParams(location.search);
  const appId: any = query.get("appId");
  const [appType, setAppType] = useState(filterBy);
  const getClasses = (type) => {
    return type === appType ? ` ${activeClass}` : ` ${defaultClass}`;
  };

  useEffect(() => {
    setAppType(filterBy)
  }, [filterBy])

  const onClickFliterHandler = (type, id) => {
    setAppType(id);
    dispatch(updateFilter(id));
  };

  return (
    <div className="border-r-2 h-full w-[80%] p-2 flex flex-col bg-komp-secondary-color ">
      <div className="text-2xl font-semibold text-white mt-4 mb-12">Konnector</div>
      <div className="flex flex-col space-y-3">
        {KonnectorSideBarType.map((type) => {
          return (
            <>
              <div
                className={
                  "flex space-x-3 group items-center rounded-lg px-2 hover:bg-komp-hover-color" + getClasses(type.id)
                }
              >
                <div className="opacity-50 group-hover:opacity-100">
                  <Icon type={type} />
                </div>
                <button
                  onClick={() => onClickFliterHandler(type.type, type.id)}
                  type="button"
                  className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all "}
                >
                  {type.label}
                </button>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default KonnectorSideBar;
