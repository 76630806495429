import React from "react";
import { AppProps, ConfigurationType } from "pages/KonnectorPage/types";
import { useAppDispatch, useAppSelector } from "state/store";
interface AppListItemProps extends AppProps {
  configurationType: ConfigurationType;
  konnectorSlice: any;
}

const AppListItem: React.FC<AppListItemProps> = ({ configurationType, id, appName, iconUrl, konnectorSlice }) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);

  const onClickAppHandler = () => {
    if (configurationType === ConfigurationType.source) {
      dispatch(konnectorSlice.updateSourceAppLoading({ appId: id, appName, iconUrl }));
    } else if (configurationType === ConfigurationType.lookup) {
      dispatch(konnectorSlice.updateLookupAppLoading({ appId: id, appName, id: currentActionId, iconUrl }));
    } else {
      dispatch(konnectorSlice.updateTargetAppLoading({ appId: id, appName, id: currentActionId, iconUrl }));
    }
  };

  return (
    <div
      onClick={onClickAppHandler}
      className="border-[1px] border-gray-200 h-28 relative font-inter cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-start px-5 py-3 rounded"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 absolute top-1 right-1 text-gray-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
          clipRule="evenodd"
        />
      </svg>
      <figure className="w-[50%]">
        <img src={iconUrl} alt="Gmail" className="w-8" />
      </figure>
      <figcaption className="text-left mt-2">
        {appName?.slice(0, 7)}
        {appName?.length > 7 && "..."}
      </figcaption>
    </div>
  );
};

export default AppListItem;
