import { FormFields } from "interfaces/pages/create-new-konnector";
import { FormFieldData, ParentProps } from "state/new-konnector/types";
import { setSourceIdLabel } from "./konnectorUtils";

export const getSourceIds = (state, fromAction?) => {
  let lookupSourceIds, parseSourceIds;
  const konnectorState = state.konnectors.konnectorState;
  const currentActionId = state[konnectorState].currentActionId;
  const repeaterId = state[konnectorState].data.repeater.currentActionId;
  const genericActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  const triggerSourceId = {
    label: setSourceIdLabel(state[konnectorState].data.trigger.outputFields.schema.sourceId),
    value: state[konnectorState].data.trigger.outputFields.schema.sourceId,
  };

  let lookupData, parseConfig;
  if (currentActionId === ParentProps.Repeater) {
    lookupData = state[konnectorState].data.repeater.lookups;
    parseConfig = [...state[konnectorState].data.repeater.parseEngine, ...state[konnectorState].data.parseEngine];
    const currentActionIndex = lookupData.findIndex((action) => action.id == genericActionId);
    if (fromAction) {
      lookupSourceIds = lookupData.map((lookup) => {
        return {
          label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
          value: lookup.outputFields.schema.sourceId,
        };
      });
    } else {
      lookupSourceIds = lookupData
        .filter((action, index) => index < currentActionIndex)
        .map((lookup) => {
          return {
            label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
            value: lookup.outputFields.schema.sourceId,
          };
        });
    }

    lookupSourceIds.push(
      ...state[konnectorState].data.lookups.map((lookup) => {
        return {
          label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
          value: lookup.outputFields.schema.sourceId,
        };
      })
    );

    parseSourceIds = parseConfig.map((lookup) => {
      return {
        label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
        value: lookup.outputFields.schema.sourceId,
      };
    });
  } else {
    lookupData = state[konnectorState].data.lookups;
    parseConfig = state[konnectorState].data.parseEngine;
    const currentActionIndex = lookupData.findIndex((action) => action.id == genericActionId);
    if (fromAction) {
      lookupSourceIds = lookupData.map((lookup) => {
        return {
          label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
          value: lookup.outputFields.schema.sourceId,
        };
      });
    } else {
      lookupSourceIds = lookupData
        .filter((action, index) => index < currentActionIndex)
        .map((lookup) => {
          return {
            label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
            value: lookup.outputFields.schema.sourceId,
          };
        });
    }
    parseSourceIds = parseConfig.map((lookup) => {
      return {
        label: setSourceIdLabel(lookup.outputFields.schema?.sourceId),
        value: lookup.outputFields.schema.sourceId,
      };
    });
  }

  return [triggerSourceId, ...lookupSourceIds, ...parseSourceIds];
};

export const getSourceFields = (state) => {
  let lookupSourceFields, parseSourceFields;
  const konnectorState = state.konnectors.konnectorState;
  const currentActionId = state[konnectorState].currentActionId;
  const repeaterId = state[konnectorState].data.repeater.currentActionId;
  const genericActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;

  const triggerSourceFields = state[konnectorState].data.trigger.outputFields.schema;
  lookupSourceFields = state[konnectorState].data.lookups.length
    ? state[konnectorState].data.lookups.map((lookup) => lookup.outputFields.schema)
    : [];
  parseSourceFields = state[konnectorState].data.parseEngine.length
    ? state[konnectorState].data.parseEngine.map((lookup) => lookup.outputFields.schema)
    : [];
  if (currentActionId === ParentProps.Repeater) {
    const lookupSchema = state[konnectorState].data.repeater.lookups.length
      ? state[konnectorState].data.repeater.lookups.map((lookup) => lookup.outputFields.schema)
      : [];
    const parseSchema = state[konnectorState].data.repeater.parseEngine.length
      ? state[konnectorState].data.repeater.parseEngine.map((lookup) => lookup.outputFields.schema)
      : [];
    lookupSourceFields.push(...lookupSchema);
    parseSourceFields.push(...parseSchema);
  }

  return [triggerSourceFields, ...lookupSourceFields, ...parseSourceFields];
};
