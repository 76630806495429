import React from "react";
import { ToastContainer } from "react-toastify";
import Sidebar from "./components/Sidebar/SidebarWithIcons";
import Header from "./components/Header";
import "react-toastify/dist/ReactToastify.min.css";
import { Error403 } from "templates/errors";
import SideBarEmbedded from "./components/Sidebar/EmbeddedSidebar";
import { useAppSelector } from "state/store";
import EmbeddedHeader from "./components/Header/EmbeddedMarketPlaceHeader";

interface LayoutProps {
  children: React.Component;
  params: any;
}

const Layout: React.FC<any> = ({ children, params }: LayoutProps) => {
  const showWarning = useAppSelector((state) => state.auth.showWarning);
  const errStatus = useAppSelector((state) => state.payments.createKonnector);
  if (!params)
    return (
      <div>
        <Error403 />
      </div>
    );
  return (
    <>
      <div className="">{/* Warning message */}</div>
      <div>{/* Warning message */}</div>
      <div className="min-h-screen flex font-inter">
        {/* sidebar */}

        {params.type == "Embedded-Marketplace" ? <SideBarEmbedded type={params} /> : <Sidebar />}

        <main className="flex-1 min-w-0 bg-white font-inter">
          {/* header */}
          {params.type == "Embedded-Marketplace" ? (
            <>
              <EmbeddedHeader name={params.name} />
              {children}
            </>
          ) : (
            <>
              <Header name={params.name} />
              <div
                className={`relative   ${showWarning || !errStatus.status ? "top-[115px] overflow-hidden" : "top-20 overflow-auto"
                  } ml-16 `}
              >
                {children}
              </div>
            </>
          )}
        </main>
        <ToastContainer />
      </div>
    </>
  );
};

export default Layout;
