import React from "react";

const Warning: React.FC<any> = ({ text }) => {
  return (
    <span className="ml-2 bg-green-100 text-yellow-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-sm dark:bg-yellow-300 dark:text-yellow-800">
      {text}
    </span>
  );
};

export default Warning;
