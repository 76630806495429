import React from "react";
import { Link } from "react-router-dom";
import { setAccountModal } from "state/account/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import CreateAccount from "common/containers/Account/CreateAccount";

const Menu: React.FC = () => {
  const dispatch = useAppDispatch();
  const showCreateAccount = useAppSelector((state) => state.account.showModal);
  const onClickAccountHandler = () => {
    dispatch(setAccountModal(true));
  };
  return (
    <div
      className="origin-top-left right-0 z-[100] absolute mt-2 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
      role="menu"
    >
      <div role="none">
        <Link
          to={"/konnectors/new"}
          type="button"
          className="text-gray-800 hover:bg-[#E0E4E9] border-b-[1px] border-[#E0E4E9] hover:transition-all block px-4 py-2 text-sm"
        >
          New Konnector
        </Link>
        <button
          onClick={onClickAccountHandler}
          type="button"
          className="w-full text-left text-gray-800 hover:bg-[#E0E4E9] border-b-[1px] border-[#E0E4E9] hover:transition-all block px-4 py-2 text-sm"
        >
          New Account
        </button>
      </div>
      {showCreateAccount && <CreateAccount />}
    </div>
  );
};

export default Menu;
