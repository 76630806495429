import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { setUpdateActiveAccount } from "state/account/slices";
import { AccountObject } from "./../../types";

interface AccountItemProps {
	account: AccountObject;
	app: {
		iconUrl: string;
	};
}

const AccountItem: React.FC<AccountItemProps> = ({ account: accountData, app }) => {
	const dispatch = useAppDispatch();
	const [account, setAccount] = useState(accountData);
	const updateAccountData = useAppSelector((state) => state.account.data);
	const activeAccount = useAppSelector((state) => state.account.activeAccount);

	useEffect(() => {
		if (updateAccountData && account.id === activeAccount.id) {
			setAccount(updateAccountData);
		}
	}, [updateAccountData]);
	return (
		<button
			onClick={(e) => {
				e.preventDefault();
				dispatch(setUpdateActiveAccount({ account, app }));
			}}
			className="flex cursor-pointer space-x-3 items-center bg-white shadow-md rounded-md p-5"
		>
			<div className="w-10">
				<img src={app.iconUrl} alt="" />
			</div>
			<div className="space-y-1 text-left">
				<h3 className="text-base">{account.connectionName}</h3>
				<p className="text-sm font-light">{account.id}</p>
			</div>
		</button>
	);
};

export default AccountItem;
