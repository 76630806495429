import React, { useEffect } from "react";
import { RouteComponentProps, Redirect, useHistory, Route, useLocation } from "react-router-dom";
import { RouteProperties } from "routes/interfaces";
import { Error403, Error404 } from "templates/errors";
import { isPrivate, RouteTypeEnum, isSession, isPublic } from "routes/routeTypes";
import { GlobalLayout } from "templates";
import AppPageInterface from "templates/interfaces/LayoutPageProperties";
import { useAppSelector, useAppDispatch } from "state/store";
import { login, setExternalLogin } from "state/auth/slices";
import Spinner from "common/components/Spinner";
import EmbeddedApps from "pages/EmbeddedMarketPlace/Apps";

interface AuthProperties<T extends { [K in keyof T]?: string } = React.ReactNode> {
  appRoute: RouteProperties;
  Template: React.SFC<AppPageInterface>;
  route: RouteComponentProps<T>;
  type: RouteTypeEnum;
  params: any;
}
const Auth = ({ appRoute, Template, route, type, params }: AuthProperties) => {
  // ===========================================================================
  // Selectors
  // ===========================================================================

  const { loading, isAuthenticated, currentUser, externalLogin } = useAppSelector((state) => state.auth);
  const history: any = useHistory();
  const { pathname } = useLocation();
  // ===========================================================================
  // Dispatch
  // ===========================================================================

  const dispatch = useAppDispatch();

  const _login = () => dispatch(login());
  // const _logout = () => dispatch(logout());

  // ===========================================================================
  // Hooks
  // ===========================================================================

  useEffect(() => {
    if (loading && !isAuthenticated) _login();

    if (!loading && isPrivate(type) && !isAuthenticated) {
      history.push("/login", { previousPath: history.location.pathname });
    }
  }, [isAuthenticated, loading]);

  if (loading) {
    return <Spinner type="public" />;
  }

  // if (isPrivate(type) && !isAuthenticated) {
  //   return <Redirect to="/login" />;
  // }
  if ((isSession(type) && (pathname === "/external_login" || pathname === "/external_signup")) || externalLogin) {
    dispatch(setExternalLogin(true));
  } else {
    dispatch(setExternalLogin(false));
  }

  if (isSession(type) && isAuthenticated) {
    if (history.location.state && history.location.state.previousPath) {
      return !history.location.state.previousPath.includes("/register") ? (
        <Redirect to={history.location.state.previousPath} />
      ) : (
        <Redirect to="/" />
      );
    }
    return <Redirect to="/" />;
  }

  if (currentUser.disablePayments && params.type === "payments") {
    return <Route render={(route) => <GlobalLayout Component={Error404} route={route} type={"public"} />} />;
  }
  if (isAuthenticated && isPrivate(type) && !loading) {
    if (!("isEmbeddedUser" && "staticApp" in currentUser) && params.type === "Embedded-Marketplace") {
      return <Route render={(route) => <GlobalLayout Component={Error404} route={route} type={"public"} />} />;
    } else if (!currentUser?.staticApp && params.type === "Embedded-Marketplace" && params.name !== "KOMP Apps") {
      return <Redirect to="/KompApps" />;
    }
  }
  if ((isAuthenticated && isPrivate(type) && !loading) || isPublic(type) || (isSession(type) && !isAuthenticated)) {
    const Layout = appRoute.template ? appRoute.template : Template;
    return <Layout params={params} Component={appRoute.component} route={route} type={type} />;
  } else {
    return null;
  }
};

export default Auth;
