import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { StatusType } from "state/new-konnector/types";
import { removeCondition, reset } from "state/parseEngine/slices";
import { changeState } from "state/rule-engine/slices";
import { useAppSelector } from "state/store";
import DataType from "./DataType";
import Operator from "./operator";
import Value from "./Value";
const ConditionField: React.FC<any> = ({ watchField, register, errors, index, getValues, id }) => {
  const dispatch = useDispatch();
  const removeConditions = (i) => {
    dispatch(removeCondition(i));
  };
  const status = useAppSelector((state) => state.parseEngine.status);

  const formData = getValues();
  useEffect(() => {
    if (status === StatusType.loading) {
      dispatch(changeState());
    }
  }, [formData]);
  return (
    <>
      <React.Fragment key={id}>
        <div className=" flex border-[1px]  border-solid bg-[#f5f7f9]  border-[#dadfe3] mt-4">
          <div className="flex flex-row group p-4 w-[100%] space-x-4">
            <DataType watchField={watchField} register={register} errors={errors} index={index} id={id} />
            <Operator watchField={watchField} register={register} errors={errors} index={index} id={id} />
            <Value watchField={watchField} register={register} errors={errors} index={index} id={id} />
          </div>
          <div className="mt-2">
            <div className="mt-4 ml-auto group-hover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-5 h-5 mb-2 mt-2  cursor-pointer"
                onClick={() => removeConditions(id)}
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                />
              </svg>
            </div>
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default ConditionField;
