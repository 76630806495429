import React, { useEffect, useState } from "react";
import { AppProps } from "pages/KonnectorPage/types";
import SelectApps from "./SelectApps";
import SelectAccount from "./SelectAccount";
import SelectEvent from "./SelectEvent";
import { AppRole, KonnectorAppState } from "state/edit-konnector/types";
import SelectInputSchema from "./SelectInput";

interface ActionConfigurationProps {
  apps: AppProps[];
  targetDetails: KonnectorAppState<AppRole.Target | AppRole.Repeater>;
  konnectorSlice: any;
  repeater?: boolean;
}

const ActionConfiguration: React.FC<ActionConfigurationProps> = ({ apps, targetDetails, konnectorSlice, repeater }) => {
  const [showDf, setShowDf] = useState(false);
  useEffect(() => {
    if (targetDetails.appId && targetDetails.connectionId && targetDetails.eventId) {
      const getApp: any = () => {
        return apps.find((app) => app.id === targetDetails.appId);
      };
      const hasInputFields = getApp().actions.map((action) => {
        return { id: action.id, inputFields: action.dynamicInputFields || false };
      });
      const hitInputFieldsApi = hasInputFields.find((app) => app.id === targetDetails.eventId).inputFields;
      if (hitInputFieldsApi) {
        setShowDf(true);
      }
    }
  }, [targetDetails.appId, targetDetails.connectionId, targetDetails.eventId]);

  return (
    <>
      <div className="flex mb-3 justify-between">
        <SelectApps apps={apps} sourceApp={targetDetails.appId} konnectorSlice={konnectorSlice} repeater={repeater} />
        {targetDetails.appId && (
          <SelectAccount
            sourceApp={targetDetails.appId}
            apps={apps}
            sourceAccount={targetDetails.connectionId}
            konnectorSlice={konnectorSlice}
            repeater={repeater}
          />
        )}
      </div>
      <div className="flex justify-between">
        {targetDetails.appId && targetDetails.connectionId && (
          <SelectEvent
            sourceApp={targetDetails.appId}
            sourceEvent={targetDetails.eventId}
            apps={apps}
            hitInputFieldsApi={showDf}
            konnectorSlice={konnectorSlice}
            repeater={repeater}
          />
        )}
      </div>
      <div className="flex justify-between">
        {showDf && (
          <SelectInputSchema
            inputFields={targetDetails.dynamicInputFields}
            sourceEvent={targetDetails.eventId}
            konnectorSlice={konnectorSlice}
            repeater={repeater}
          />
        )}
      </div>
    </>
  );
};

export default ActionConfiguration;
