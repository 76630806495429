export interface Lookup {
  id: string;
  name: string;
}

export interface Required {
  message: string;
  value: boolean;
}

export interface Pattern {
  value: string;
  flags: string;
  message: string;
}

export interface Field {
  required: Required;
  pattern: Pattern;
  name: string;
  type: string;
}

export interface ConnectionConfig {
  connectorTypeId: string;
  fields: Field[];
}

export interface Action {
  name: string;
  id: string;
}

export interface Trigger {
  eventSource: string;
  id: string;
  name: string;
}

export interface AppObject {
  appName: string;
  id: string;
  description: string;
  appKey: string;
  lookups: Lookup[];
  dtCreated: Date;
  connectionConfig: ConnectionConfig;
  actions: Action[];
  triggers: Trigger[];
  iconUrl: string;
  dtUpdated: Date;
}
export interface AppsProps {
  apps: AppObject[];
}

export const AppSideBarTypes = [
  {
    type: "all",
    label: "All apps",
  },

  {
    type: "marketing",
    label: "Sales and Marketing",
  },
  {
    type: "productivity",
    label: "Productivity",
  },
  {
    type: "crm",
    label: "CRM",
  },
  {
    type: "communication",
    label: "Communication",
  },
  {
    type: "document_management",
    label: "Document Mangement",
  },
];

export const KonnectorSideBarType = [
  {
    type: "all_konnectors",
    label: "All Konnectors",
    id: "all",
  },
  {
    type: "active_konnectors",
    label: "Active Konnectors",
    id: "active",
  },
  {
    type: "description",
    label: "Settings",
    id: "description",
  },
];
