import SingleSelectField from "common/components/FormFields/SingleSelect";
import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import { Choice } from "common/components/FormFields/types";
import { SourceDataType } from "pages/KonnectorPage/types";
import React, { useEffect, useState } from "react";
import { StatusType } from "state/new-konnector/types";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";
import { setFieldType } from "utils/ruleEngineUtils";

const ValueType: React.FC<any> = ({ register, value, index, watchField, errors, filterIndex, setValue, getValues }) => {
  const field = { propName: "field_4_" + index, propType: "string", isRequired: true };
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const rawDataMapping = useAppSelector((state) => state.ruleEngine.rawDataMapping);
  const status = useAppSelector((state) => state.ruleEngine.status);
  const [data, setdata] = useState([]);
  const [showValueField, setShowValueField] = useState(true);
  const sourceDataType = useAppSelector((state) => state.ruleEngine.filters[filterIndex].conditions[index].data_type);
  const [sourceField, OperatorType] = watchField([
    `filter_${filterIndex}_condition_${index}___field_source_field`,
    `filter_${filterIndex}_condition_${index}___field_operator_type`,
  ]);

  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    fieldData.type = setFieldType(fieldData, sourceDataType);
    return fieldData;
  };

  const getSourceFields = (state) => {
    const triggerSourceFields = state[konnectorState].data.trigger.outputFields.schema;
    const lookupSourceFields = state[konnectorState].data.lookups.length
      ? state[konnectorState].data.lookups.map((lookup) => lookup.outputFields.schema)
      : [];
    return [triggerSourceFields, ...lookupSourceFields];
  };

  const sourceFields: any = useAppSelector(getSourceFields);
  const fieldValue = watchField(`filter_${filterIndex}_condition_${index}___field_source_type`);

  const getChoices = () => {
    const findSourceFields = sourceFields.find((item) => item.sourceId === fieldValue);
    return findSourceFields
      ? flatSourceOutputFields(findSourceFields.eventResponse.data).find((field) => field.propName === sourceField)
      : [];
  };

  const getDropDown = () => {
    let fieldData = getChoices();
    if (fieldData?.propValue) {
      if (fieldData.propType === "number") {
        return fieldData.propValue.map((value) => ({ label: value.label, value: parseInt(value.value) }));
      }
      return fieldData.propValue.map((value) => ({ label: value.label, value: value.value }));
    }
  };

  console.log(getDropDown());

  useEffect(() => {
    if (sourceDataType === SourceDataType.boolean) {
      setShowValueField(false);
    } else {
      setShowValueField(true);
    }
  }, [sourceDataType]);

  useEffect(() => {
    setdata([...data, sourceField]);
    if (data[data.length - 1] && status !== StatusType.loading) {
      setValue(`filter_${filterIndex}_condition_${index}___field_value`, "");
    }
  }, [sourceField, OperatorType]);

  return (
    <>
      {showValueField && (
        <div className="w-[50%]">
          {getDropDown() ? (
            <SingleSelectField
              choices={getDropDown()}
              {...getFieldOptions()}
              name={`filter_${filterIndex}_condition_${index}___field_value`}
              register={register}
              errors={errors}
            />
          ) : (
            <TextField
              {...getFieldOptions()}
              name={`filter_${filterIndex}_condition_${index}___field_value`}
              register={register}
              errors={errors}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ValueType;
