import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusType } from "state/new-konnector/types";

import { SortByType, FilterType, InitialState, KonnectorState } from "../types";

export const initialState: InitialState = {
  sortBy: SortByType.DEFAULT,
  filterBy: FilterType.ALL,
  status: StatusType.idle,
  konnectorState: KonnectorState.NewKonnectorState,
  currentPage: 1,
  currentActionId: "",
  hasActivated: false,
  hasDeleted: false,
  selectedKonnectorId: [],
  appType: "all",
  errorMessage: "",
};

const konnectorsSlice = createSlice({
  name: "konnectors",
  initialState,
  reducers: {
    updateSortBy(state, data: PayloadAction<SortByType>) {
      state.currentPage = 1;
      state.sortBy = data.payload;
    },
    updateCurrentPage(state, data: PayloadAction<number>) {
      state.currentPage = data.payload;
    },
    updateFilter(state, data: PayloadAction<FilterType>) {
      state.currentPage = 1;
      state.filterBy = data.payload;
    },
    resetStatus(state) {
      state.status = StatusType.idle;
      state.currentActionId = "";
    },
    activateKonnectorLoading(state, data: PayloadAction<string>) {
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
    },
    activateKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.currentActionId = data.payload.data.id;
      state.hasActivated = true;
      state.hasDeleted = false;
    },
    deactivateKonnectorLoading(state, data: PayloadAction<string>) {
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
    },
    deactivateKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.currentActionId = data.payload.data.id;
      state.hasActivated = false;
      state.hasDeleted = false;
    },
    deleteKonnectorLoading(state, data: PayloadAction<any>) {
      state.status = StatusType.loading;
      state.currentActionId = data.payload;
      state.hasDeleted = false;
    },
    deleteKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.hasDeleted = true;
    },
    deleteBulkKonnectorLoading(state, data: PayloadAction<any>) {
      state.status = StatusType.loading;
      state.selectedKonnectorId = data.payload;
      state.hasDeleted = true;
    },
    deleteBulkKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.hasDeleted = true;
    },
    apiError(state, data: PayloadAction<any>) {
      state.status = StatusType.failed;
    },
    displayErrorMessage: (state, data: PayloadAction<string>) => {
      state.status = StatusType.failed;
      state.errorMessage = data.payload;
    },
    setKonnectorState: (state, data) => {
      state.konnectorState = data.payload;
    },
    setAppType: (state, data) => {
      state.appType = data.payload;
    },
    reset: () => initialState,
  },
});

export const {
  updateSortBy,
  updateCurrentPage,
  apiError,
  updateFilter,
  activateKonnectorLoading,
  activateKonnectorSuccess,
  deactivateKonnectorLoading,
  deactivateKonnectorSuccess,
  deleteKonnectorLoading,
  deleteKonnectorSuccess,
  deleteBulkKonnectorLoading,
  deleteBulkKonnectorSuccess,
  displayErrorMessage,
  setKonnectorState,
  setAppType,
  reset,
  resetStatus,
} = konnectorsSlice.actions;

export default konnectorsSlice.reducer;
