import { StatusType } from "state/new-konnector/types";
export enum PlanType {
  null = "null",
  paid = "paid",
  free = "free",
  trial = "trial",
  trialExpired = "trial_expired",
  limitReached = "limit_reached",
  growth = "growth",
  startup = "startup",
}
export interface paymentInitialState {
  loading?: boolean;
  currentUser?: any;
  disableModalCloseHandler: boolean;
  planActive?: boolean;
  trialDays: number;
  error?: string;
  cardDetails: any;
  currentPlanCycle?: string;
  showPaymetModal: boolean;
  currentSelectedPlan: PlanType;
  selectedPlanDetails?: SelectedPlanDetails;
  data?: any;
  status?: StatusType;
  clientId: string;
  planDetails?: any;
  subscriptionDetails?: any;
  isTrial?: boolean;
  isPaid?: boolean;
  token?: string;
  isFreePlan?: boolean;
  freePlanDetails?: any;
  currentPlan?: PlanType;
  konnector?: any;
  createKonnector: AllowToCreateKonnector;
}

interface AllowToCreateKonnector {
  type: string;
  status: boolean;
  message: string;
}

export enum AllowToCreateKonnectorType {
  EmailNotVerifed = "emailNotVerified",
  PlanExpired = "planExpired",
  TaskExeeded = "taskExeeded",
  TrailExpired = "trialExpired",
}

interface SelectedPlanDetails {
  addons: number;
  cycle: "yearly" | "monthly";
  tasks: number;
  totalPrice: number;
}
