import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import StyleTab from "./StyleTab";
import CodeSnippet from "./CodeSnippet";
import SettingTab from "./Settingtab";
import { useAppSelector } from "state/store";
import TabSwitching from "common/components/Modal/TabSwitching";
import { Button } from "components/ui/button";
import { resetCustomization, resetStatusModal, saveCardData, setStatusModal, setTab1Data } from "state/Embedded-markteplace/ThemeSettings/slices";
import { useDispatch } from "react-redux";
import { ReloadIcon } from "@radix-ui/react-icons"
import { StatusType } from "state/Embedded-markteplace/ThemeSettings/types";
import { resetState, setRawDataMapping } from "state/Embedded-markteplace/AdminSettings/slices";
import ResetModal from "common/components/Modal/ResetModal";
import ResetPortalModal from "common/components/Modal/ResetPortalModal"
import { toast } from "react-toastify";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
const CustomSettings: React.FC = () => {
  const dispatch = useDispatch()
  const cardState = useAppSelector((state) => state.themeSettings.cardState)
  const saveStatus = useAppSelector((state) => state.themeSettings.saveStatus)
  const resetStatus = useAppSelector((state) => state.themeSettings.tabModalStatus)
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const primaryAccountCred = useAppSelector((state) => state.auth.accountCredentials);
  // const adminSettings = useAppSelector((state) => state.adminSettings);
  // const customizationState: boolean = true
  const [value, setValues] = useState(0);
  const [stateValue, setValueState] = useState(0);
  const [modalState, setModalState] = useState(false);
  const [resetsStatus, setResetState] = useState(false);
  const [resetsState, setReset] = useState(false);
  var queryParams
  if (Object.keys(primaryAccountCred).length === 0) {
    queryParams = {}
  } else {
    queryParams = primaryAccountCred?.map(field => `${field?.name}=`).join('&');
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 0 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  useEffect(() => {
    if (saveStatus === StatusType.success) {
      toast.success("Theme applied successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        // onClose: () => {
        //     history.goBack();
        // },
      });
    }
  }, [saveStatus])
  const closeModalHandler = () => {
    setResetState(false)
    setModalState(false)
    setValues(stateValue)
  };

  const closeResetModalHandler = () => {
    setResetState(false)
    setValues(stateValue)
  };

  const closeHandler = () => {
    setResetState(false)
  }

  const handlePreview = () => {
    setTimeout(
      () =>
        window.open(
          `https://enduser.konnectify.io/apps?apiKey=${currentUser.tenantId}&user=${currentUser.email}&${queryParams}`,
          "_blank", "height=300,width=300,left=100,top=100,toolbar=no,location=no,status=no,menubar=no"
        ),
      3000
    );
  }

  const handleSave = () => {
    // if (value == 0) {
    //   dispatch(setTab1Data())
    //   dispatch(setStatusModal())
    // } else {
    dispatch(saveCardData())
    // }
  };

  const handleReset = () => {
    if (value == 0) {
      dispatch(resetCustomization())
      dispatch(resetStatusModal())

    } else if (value == 1) {

    } else {
      dispatch(saveCardData())
    }

  };

  const resetButton = () => {
    setReset(true)
  };

  console.log(value, "value check")
  return (
    <>
      {resetsStatus && <ResetPortalModal closeModalHandler={closeResetModalHandler} closeHandler={closeHandler}></ResetPortalModal>}
      {modalState && <TabSwitching closeModalHandler={closeModalHandler}></TabSwitching>}
      <Tabs className="fixed w-[100%] bg-gray-100 ml-16 mx-2
z-10
             top-20"
        value={value}
        textColor="primary"
        indicatorColor="primary"
        onChange={(event, newValue) => {
          if (value == 0) {
            if (!resetStatus) {
              setValues(newValue)
            } else {
              setResetState(true)
              setValueState(newValue)
            }
          } else if (value == 1) {
            if (!cardState) {
              setValues(newValue)
            } else {
              setModalState(true)
              setValueState(newValue)
            }
          }
          else {
            setValues(newValue)
            setValueState(newValue)
          }
          // if (!cardState ) {
          //   setValues(newValue)
          // } else if (!customizationState) { setValues(newValue) } 
          // else if (!resetStatus) {
          //   setValues(newValue)
          // }


        }}
      >
        <Tab label="Customisation" />
        <Tab label="Theme" />
        <Tab label="Setting" />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <StyleTab />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <CodeSnippet />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SettingTab />
      </CustomTabPanel>

      <div className="mt-10 flex space-x-2 ml-16 items-start bg-white
             fixed 
             inset-x-0 
             bottom-0 
             border-t border-gray-100 shadow-2xl shadow-slate-600 
             p-4">
        {value == 2 ? <Button type="submit" className="bg-slate-100 text-black w-36 ml-12 border border-slate-200" onClick={handlePreview}>Preview</Button> : ""}
        <Button type="submit" className="bg-slate-100 text-black w-36 ml-12 border border-slate-200" onClick={handleReset}>Reset</Button>
        <Button type="submit" className="bg-slate-900 text-white w-36 px-4 disabled:opacity-60 ml-12" onClick={handleSave} disabled={saveStatus === StatusType.loading}>
          {saveStatus === StatusType.loading ? (
            <>
              <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
              {"Loading..."}
            </>
          ) : saveStatus === StatusType.success ? (
            "Save"
          ) : (
            "Save"
          )}
        </Button>

      </div>

    </>
  );
};

export default CustomSettings;
