import React from "react";

const Info: React.FC<any> = ({ text }) => {
	return (
		<span className="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-800/10">
			{text}
		</span>
	);
};

export default Info;
