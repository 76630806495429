import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "state/store";
import { setFormSubmitType, setFormSubmitUrlMsg } from "state/form-builder/slices";

const FormOnSubmit: React.FC<any> = ({ field }) => {
  const { register, reset, watch } = useForm();
  const dispatch = useAppDispatch();
  const [error, setError] = useState("");

  const formOnSubmit = useAppSelector((state) => state.formBuilder.formSettings.formSubmitHandler);

  const url = watch("url");
  const regex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

  console.log(url, "url", regex.test(url));

  useEffect(() => {
    if (url) {
      if (regex.test(url)) {
        if (formOnSubmit.submitType === "url") {
          dispatch(setFormSubmitUrlMsg(url));
        }
        setError("");
      } else {
        setError("Invalid URL");
      }
    } else {
      const url = "";
      dispatch(setFormSubmitUrlMsg(url));
    }
  }, [url]);
  useEffect(() => {
    reset({ url: formOnSubmit.redirectUrl });
  }, []);
  return (
    <>
      <div className="flex flex-col w-3/4 mt-4 p-2 space-y-3 h-full">
        <div className="w-full">
          <label htmlFor={""} className="block mb-2 text-sm font-medium text-gray-500">
            After Submisson
            {/* <span className="text-red-500 ">*</span> */}
          </label>
          <div className="flex space-x-2">
            <label
              className={`w-1/2 rounded border-[1px] relative ${
                formOnSubmit.submitType === "url" ? "bg-gray-200" : ""
              } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
            >
              <div className="absolute right-1 top-1">
                <input
                  type="radio"
                  value="url"
                  checked={formOnSubmit.submitType === "url" ? true : false}
                  onClick={() => {
                    dispatch(setFormSubmitType("url"));
                  }}
                  className="rounded-full border-2 border-gray-300 w-3 h-3"
                />
              </div>
              <p>Redirect</p>
              <p className="text-xs text-gray-400">redirected to a provided link</p>
            </label>
            <label
              className={`w-1/2 rounded border-[1px] relative ${
                formOnSubmit.submitType === "msg" ? "bg-gray-200" : ""
              } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
            >
              <div className="absolute right-1 top-1">
                <input
                  type="radio"
                  value="msg"
                  checked={formOnSubmit.submitType === "msg" ? true : false}
                  onClick={() => {
                    dispatch(setFormSubmitType("msg"));
                  }}
                  className="rounded-full border-2 border-gray-300 w-3 h-3"
                />
              </div>
              <p className="">Show a Message</p>
              <p className="text-xs text-gray-400">a provided toast message will be displayed</p>
            </label>
          </div>
        </div>
        {formOnSubmit.submitType === "url" && (
          <>
            <label htmlFor={""} className="block mb-2 text-sm font-medium text-gray-500">
              Redirect URL
              <span className="text-red-500 ">*</span>
            </label>
            <input
              type={"text"}
              name={"url"}
              {...register("url")}
              //   value={formOnSubmit.redirectUrl}
              placeholder="provide a redirect URL"
              className={`border-2 rounded  text-gray-800 text-sm focus:ring-primary-color focus:border-primary-color border-dashed bg-transparent block w-full p-2.5`}
            />
            {error && <p className="mt-2 text-sm text-red-600 dark:text-red-500">{error}</p>}
          </>
        )}
        {formOnSubmit.submitType === "msg" && (
          <>
            <label htmlFor={""} className="block mb-2 text-sm font-medium text-gray-500">
              Message
              <span className="text-red-500 ">*</span>
            </label>
            <input
              onChange={(e) => {
                dispatch(setFormSubmitUrlMsg(e.target.value));
              }}
              type={"text"}
              value={formOnSubmit.message}
              name={"msg"}
              placeholder="Enter a message to be shown"
              className={`border rounded  text-gray-800 text-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5`}
            />
          </>
        )}
      </div>
    </>
  );
};

export default FormOnSubmit;
