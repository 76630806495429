const number = [
  { label: "IS_EQUALS", value: "IS_EQUALS" },
  { label: "NOT_EQUALS", value: "NOT_EQUALS" },
  { label: "GREATER_THAN", value: "GREATER_THAN" },
  { label: "LESSER_THAN", value: "LESSER_THAN" },
  { label: "GREATER_THAN_EQUAL", value: "GREATER_THAN_EQUAL" },
  { label: "LESSER_THAN_EQUAL", value: "LESSER_THAN_EQUAL" },
];

const string = [
  { label: "STARTS_WITH", value: "STARTS_WITH" },
  { label: "ENDS_WITH", value: "ENDS_WITH" },
  { label: "MATCHES_WITH", value: "MATCHES_WITH" },
];
const defaultValue = [
  {
    label: "none",
    value: "none",
  },
];
export const Choices = {
  string: string,
  numeric: number,
  default: defaultValue,
};
