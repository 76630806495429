import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";
import { requiredField } from "../constants/fieldList";
import OptionsModal from "../Modal/OptionsModal";
import { useAppSelector } from "state/store";
import RenderField from "./genericFields";

const RenderSelectField: React.FC<any> = ({ fieldList, register, errors, reset }) => {
  const renderFieldList = (fieldList) => {
    return fieldList.map((field) => {
      return <RenderField field={field} register={register} errors={errors} />;
    });
  };
  const currentFieldId = useAppSelector((state) => state.formBuilder.currentFormField);
  const currentFormField = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId)
  );

  return (
    <>
      <div className="flex flex-col space-y-4">
        <div className="flex w-full space-x-2 justify-between">{renderFieldList(fieldList)}</div>
        <div className="flex flex-col space-x-2  w-1/2 ">
          {[requiredField].map((field) => {
            return (
              <label
                htmlFor={`${field.name}`}
                className="border-[1px] border-gray-300 h-[42px] mr-1 items-center flex rounded p-2"
              >
                <RenderField field={field} register={register} errors={errors} />
              </label>
            );
          })}
        </div>
      </div>
      <div
        className={`w-full flex flex-col max-h-60 space-y-1 ${
          currentFormField.options.length > 4 && "overflow-y-scroll"
        } `}
      >
        <OptionsModal register={register} errors={errors} reset={reset} />
      </div>
    </>
  );
};

export default RenderSelectField;
