import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { StatusType } from "state/new-konnector/types";
import { PlanType } from "state/payment/types";

import { paymentInitialState } from "../types";
import { setKonnectorStatusData } from "utils/authUtils";
export const initialState: paymentInitialState = {
  loading: false,
  currentUser: {
    billing: {},
  },
  konnector: {},
  planActive: false,
  trialDays: 0,
  disableModalCloseHandler: false,
  currentPlan: PlanType.null,
  currentPlanCycle: "",
  currentSelectedPlan: PlanType.null,
  selectedPlanDetails: {
    addons: 0,
    cycle: "yearly",
    tasks: 0,
    totalPrice: 0,
  },
  cardDetails: null,
  error: "",
  status: StatusType.idle,
  planDetails: {},
  subscriptionDetails: {},
  showPaymetModal: false,
  clientId: "",
  createKonnector: {
    type: "",
    status: true,
    message: "",
  },
};
const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setPlanDetails: (state, data) => {
      state.currentUser = data.payload.user;
      state.currentPlan = data.payload.plan;
      state.currentPlanCycle = data.payload.cycle;
      state.createKonnector.status = data.payload.status;
      state.createKonnector.type = data.payload.type;
      state.createKonnector.message = data.payload.message;
    },
    setKonnectorStatus: (state, data) => {
      state.createKonnector = setKonnectorStatusData(state.currentUser, data.payload);
    },
    subscribePlanLoading: (state) => {
      state.status = StatusType.loading;
    },
    subscribePlanSuccess: (state, data: PayloadAction<any>) => {
      const metaData = data.payload.items?.data?.find((i) => i.plan.metadata.name !== "addon")?.plan.metadata;
      state.status = StatusType.success;
      state.showPaymetModal = false;
      // state.currentPlan = state.currentSelectedPlan;
      // state.currentPlanCycle = data.payload?.cycle;
      // state.currentUser.billing.addon = state.selectedPlanDetails.addons;
      // state.currentUser.billing.cycle = state.selectedPlanDetails.cycle;
      // state.createKonnector.status = true;
      // state.createKonnector.type = "";
      state.loading = false;
      state.error = "";
    },
    setShowPaymentModal: (state, data) => {
      state.showPaymetModal = data.payload;
      state.cardDetails = null;
      state.clientId = "";
      if (data.payload === false) {
        state.selectedPlanDetails = { addons: 0, cycle: "yearly", tasks: 0, totalPrice: 0 };
        state.disableModalCloseHandler = false;
      }
    },
    setClientId: (state, data) => {
      state.clientId = data.payload;
      state.showPaymetModal = true;
    },
    upgradePlanFailed: (state, data: PayloadAction<any>) => {
      state.status = StatusType.failed;
      state.error = data.payload;
    },
    getCardDetailsLoading: (state, data) => {
      state.loading = true;
    },
    getCardDetailsSucess: (state, data) => {
      state.loading = false;
      state.cardDetails = data.payload;
    },
    resetpaymentInstances: (state, data) => {
      state.cardDetails = null;
      state.clientId = "";
    },
    deleteBulkKonnectorLoading(state, data: PayloadAction<any>) {
      state.status = StatusType.loading;
      state.konnector = data.payload;
    },
    deleteBulkKonnectorSuccess(state, data: PayloadAction<any>) {
      state.status = StatusType.success;
      state.currentPlan = PlanType.free;
      state.createKonnector.status = true;
      state.createKonnector.type = "";
    },
    deleteBulkKonnectorError(state) {
      state.status = StatusType.failed;
    },
    setSeletedPlanCycle(state, data) {
      state.selectedPlanDetails.cycle = data.payload;
    },
    setSeletedPlanAddon(state, data) {
      state.selectedPlanDetails.addons = data.payload;
    },
    setSeletedPlanPrice(state, data) {
      state.selectedPlanDetails.totalPrice = data.payload;
    },
    setcurrentSelectedPlan(state, data) {
      state.currentSelectedPlan = data.payload;
      if (data.payload === "free") {
        state.selectedPlanDetails = { addons: 0, totalPrice: 0, cycle: "monthly", tasks: 0 };
      }
      state.selectedPlanDetails = { addons: 0, totalPrice: 0, cycle: "yearly", tasks: 0 };
    },
    setTrialDays(state, data) {
      state.trialDays = data.payload;
    },
    disableCloseModalHandler(state, data) {
      state.disableModalCloseHandler = data.payload;
    },
    resetSelectedPlan(state) {
      state.selectedPlanDetails = { addons: 0, cycle: "yearly", tasks: 0, totalPrice: 0 };
      state.disableModalCloseHandler = false;
    },
    resetStatus(state) {
      state.status = StatusType.idle;
    },
  },
});
export const {
  setPlanDetails,
  setKonnectorStatus,
  setShowPaymentModal,
  subscribePlanLoading,
  subscribePlanSuccess,
  upgradePlanFailed,
  setSeletedPlanPrice,
  getCardDetailsLoading,
  getCardDetailsSucess,
  deleteBulkKonnectorLoading,
  deleteBulkKonnectorSuccess,
  deleteBulkKonnectorError,
  setSeletedPlanCycle,
  setcurrentSelectedPlan,
  setSeletedPlanAddon,
  resetpaymentInstances,
  setClientId,
  setTrialDays,
  disableCloseModalHandler,
  resetSelectedPlan,
  resetStatus,
} = paymentSlice.actions;
export default paymentSlice.reducer;
