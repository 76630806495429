import React from "react";

const SaveModal: React.FC<any> = ({
  visible,
  ref,
  closeModalHandler,
  confirmModalHandler,
  onChangeNameHandler,
  name,
}) => {
  return (
    <>
      {visible && (
        <>
          <div
            id="popup-modal"
            tabIndex={-1}
            className="overflow-y-auto flex overflow-x-hidden fixed top-0 right-0 left-0 z-[100] md:inset-0 h-modal md:h-full justify-center items-center"
            aria-hidden="true"
          >
            <div className="relative p-2 w-full max-w-md h-full md:h-auto">
              <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
                <div className="flex justify-end p-2">
                  <button
                    onClick={closeModalHandler}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="popup-modal"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div>
                  <div className=" rounded px-4 pt-2 pb-4 mb-4 dark:bg-gray-700">
                    <div className="mb-8">
                      <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                        Konnector Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        placeholder="Konnector Name"
                        ref={ref}
                        maxLength={100}
                        value={name}
                        onChange={onChangeNameHandler}
                        className="bg-gray-50 border mb-2 border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      />
                    </div>
                    <div className="flex justify-end">
                      <button
                        onClick={closeModalHandler}
                        data-modal-toggle="popup-modal"
                        type="button"
                        className="py-2 hover:bg-gray-100 transition-all px-4 mr-2 mb-2 shadow-sm text-sm font-medium text-primary-color bg-gray-200 rounded-sm"
                      >
                        Cancel
                      </button>
                      <button
                        data-modal-toggle="popup-modal"
                        onClick={confirmModalHandler}
                        type="button"
                        disabled={name === "Untitled" || name === ""}
                        className="text-white justify-end bg-primary-color disabled:opacity-50 py-2 px-6 mb-2 font-medium rounded-sm text-sm  items-center cursor-pointer text-center"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};

export default SaveModal;
