import React, { useEffect, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";
import { requiredField, confirmationField } from "../constants/fieldList";
import Logo from "../../Trigger/Icons/Logo";
import { useAppSelector } from "state/store";
import RenderField from "./genericFields";

const RenderTextFields: React.FC<any> = ({ fieldList, register, errors }) => {
  const currentField = useAppSelector((state) => state.formBuilder.currentFormField);
  const textFieldType = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentField.fieldId)
  ).config.textFieldType;
  const [checkRadio, setCheckRadio] = useState(textFieldType);

  const renderFieldList = (fieldList) => {
    return fieldList.map((field) => {
      return <RenderField field={field} register={register} errors={errors} />;
    });
  };
  const onClickHandler = (e) => {
    if (e === checkRadio) {
      return setCheckRadio("");
    }
    setCheckRadio(e);
  };
  // useEffect(() => {
  // 	setCheckRadio(textFieldType);
  // }, [textFieldType]);
  console.log({ checkRadio, textFieldType });

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex w-full space-x-2 justify-between">{renderFieldList(fieldList)}</div>
      <div className="flex space-x-2">
        <div className="flex flex-col space-y-2 border-gray-200 w-1/2 ">
          {[confirmationField, requiredField].map((field) => {
            return (
              <label
                htmlFor={`${field.name}`}
                className="border-[1px] border-gray-300 h-[42px] items-center flex rounded p-2"
              >
                <RenderField field={field} register={register} errors={errors} />
              </label>
            );
          })}
        </div>
        <label
          className={`w-1/4 rounded border-[1px] relative ${
            checkRadio === "email" ? "bg-gray-200" : ""
          } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
        >
          <div className="absolute right-1 top-1">
            <input
              type="radio"
              value="email"
              checked={checkRadio === "email" ? true : false}
              onClick={() => {
                onClickHandler("email");
              }}
              {...register(`text_field_type`)}
              className="rounded-full border-2 border-gray-300 w-3 h-3"
            />
          </div>
          <p>Email field</p>
          <p className="text-xs text-gray-400">Sets input type as email address</p>
        </label>
        <label
          className={`w-1/4 rounded border-[1px] relative ${
            checkRadio === "password" ? "bg-gray-200" : ""
          } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
        >
          <div className="absolute right-1 top-1">
            <input
              type="radio"
              value="password"
              checked={checkRadio === "password" ? true : false}
              onClick={() => {
                onClickHandler("password");
              }}
              {...register(`text_field_type`)}
              className="rounded-full border-2 border-gray-300 w-3 h-3"
            />
          </div>
          <p className="">Password field</p>
          <p className="text-xs text-gray-400">Sets input type as password</p>
        </label>
      </div>
    </div>
  );
};

export default RenderTextFields;
