import { setCustomization } from "state/Embedded-markteplace/ThemeSettings/slices";
import FontPicker from "font-picker-react";
import React, { useEffect, useState } from "react";
import { PrefilData, setFontFamily, setHeaderFontFamily } from "state/Embedded-markteplace/AdminSettings/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import { Milk } from "lucide-react";
const FontStyles: React.FC<any> = ({ loadingStatus, setLoadingStatus }) => {
  console.log(loadingStatus);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const adminSettings = useAppSelector((state) => state.adminSettings);
  const themeSettings = useAppSelector((state) => state.themeSettings);
  const themesetting = useAppSelector((state) => state.themeSettings.tabModalStatus);
  const fontPickerStyle = {
    border: '2px solid #ccc', // Change the border style as needed
    borderRadius: '4px', // Optional: Add border radius for rounded corners
    // Add any other styles you want to customize
  };

  const onChangeFamily = (e) => {
    dispatch(setFontFamily(e.family));
    dispatch(setCustomization({ type: "font", value: e.family }));
  };

  useEffect(() => {
    if (currentUser && currentUser.portalConfigration && !themesetting) {
      dispatch(PrefilData(currentUser.portalConfigration));
    }
  }, [currentUser]);

  //----Commented because future use-----
  // const onChangeHeaderFamily = (e) => {
  //     console.log(e);
  //     dispatch(setHeaderFontFamily(e.family));
  //   };

  useEffect(() => {
    if (!adminSettings.fontFamily) {
      setFontFamily("Arial");
    }
    const timeout = setTimeout(() => {
      setLoadingStatus("finished");
    }, 500);
    return () => {
      clearTimeout(timeout);
    };
  }, [adminSettings.fontFamily]);



  return (
    <>
      {/* <div className="border rounded-xl shadow-sm p-6 w-[70%] border-slate-300	"> */}
      {/* <label htmlFor={""} className="mb-2 mr-2 ml-2 mt-4 text-sm font-semibold">
        Portal Font
      </label> */}
      <div className="grid ">
        <label htmlFor={""} className="mb-2 mr-2 mt-4 ml-2 text-sm font-medium text-gray-500">
          Base Fonts
        </label>
        <div className="mb-2 mt-2 ml-2">
          {loadingStatus === "finished" && (

            <FontPicker
              apiKey="AIzaSyCrAmkqacuiyammyv400dq-l6QUNZkoMSE"
              activeFontFamily={themeSettings?.customization?.font ? themeSettings?.customization?.font : "Arimo"}
              onChange={onChangeFamily}
              pickerId="1"
            />

          )}
          {loadingStatus === "loading" && <p>Loading fonts...</p>}
        </div>

        {/*------- Commented because future use------
          <div className="grid  ">
          <label htmlFor={""} className="mb-2 mr-2 mt-4 text-sm font-medium text-gray-500">
            Header font
           </label>
           <div className="mb-2 mt-2">
           <FontPicker
              apiKey="AIzaSyCrAmkqacuiyammyv400dq-l6QUNZkoMSE"
            activeFontFamily={adminSettings.headerfontFamily || "Arial"}
               onChange={(e) => onChangeHeaderFamily(e)}
               pickerId="2"
             />
           </div>
         </div> */}
      </div>
      {/* </div> */}
    </>
  );
};

export default FontStyles;
