import SingleSelectField from "common/components/FormFields/SingleSelect";
import React from "react";
import { Field } from "utils/appsUtils";

const DataType: React.FC<any> = ({ register, errors, index ,id}) => {
  const field = { propName: "Parse_condition_DataType" + index, propType: "string", isRequired: true };
  const choices = [
    { label: "number", value: "number" },
    { label: "string", value: "string" },
  ];
  const getFieldOptions = () => {
    return new Field(field).format();
  };
  return (
    <>
      <div className=" flex w-[40%]">
        <SingleSelectField
          placeholder="Choose regex type"
          choices={choices}
          {...getFieldOptions()}
          name={`${id}___field_data_type`}
          register={register}
          errors={errors}
        />
      </div>
    </>
  );
};

export default DataType;
