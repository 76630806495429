import React, { useEffect } from "react";
import { reset, editRule } from "state/rule-engine/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import FilterList from "./Filters/Filter/filterList";

export default function RuleEngine() {
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);

  const dispatch = useAppDispatch();
  const editRules = useAppSelector((state) => state[konnectorState].editRule);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const getRuleConfig = (state) => {
    const findRuleIndex = state[konnectorState].data.ruleEngine.findIndex((action) => action.id === currentActionId);
    return state[konnectorState].data.ruleEngine[findRuleIndex];
  };
  const ruleData = useAppSelector(getRuleConfig);
  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  useEffect(() => {
    if (editRules === true) {
      dispatch(editRule(ruleData));
    }
  }, [editRules]);

  return (
    <div className="  w-[100%]">
      <FilterList />
    </div>
  );
}
