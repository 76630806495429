import SingleSelectField from "common/components/FormFields/SingleSelect";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { getSourceIds } from "utils/konnectorStateUtils";
import { setSourceIdLabel } from "utils/konnectorUtils";

const SourceType: React.FC<any> = ({ register, watchField, errors, value, index, filterIndex }) => {
  const field = { propName: "field_" + index, propType: "string", isRequired: true };
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  // const getSourceIds = (state) => {
  //   const triggerSourceId = {
  //     label: setSourceIdLabel(state[konnectorState].data.trigger.outputFields.schema.sourceId),
  //     value: state[konnectorState].data.trigger.outputFields.schema.sourceId,
  //   };
  //   const lookupSourceIds = state[konnectorState].data.lookups.map((lookup) => {
  //     return {
  //       label: setSourceIdLabel(lookup.outputFields.schema.sourceId),
  //       value: lookup.outputFields.schema.sourceId,
  //     };
  //   });
  //   return [triggerSourceId, ...lookupSourceIds];
  // };
  const sourceIds = useAppSelector((state) => {
    return getSourceIds(state, true);
  });
  const getFieldOptions = () => {
    return new Field(field).format();
  };
  return (
    <div className="w-[50%]">
      <SingleSelectField
        placeholder="Choose source type"
        choices={sourceIds}
        {...getFieldOptions()}
        name={`filter_${filterIndex}_condition_${index}___field_source_type`}
        register={register}
        errors={errors}
      />
    </div>
  );
};

export default SourceType;
