import React from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { User } from "../types";

type Inputs = {
  name: string;
  phone: string;
  organisation: string;
  phoneNumber: string;
};

interface EditProfileProps extends User {
  cancelEditProfile: () => void;
  onSubmitHandler: (data: Inputs) => void;
}

const EditProfile: React.FC<EditProfileProps> = ({
  name,
  phoneNumber,
  organisation,
  email,
  cancelEditProfile,
  onSubmitHandler,
}) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name,
      phoneNumber,
      organisation,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => onSubmitHandler(data);

  return (
    <div className="p-5 space-y-5">
      <h1 className="text-base font-medium">Profile Details</h1>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-5">
        <div className="flex space-x-20">
          <ul className="text-sm font-light items-center text-secondary-color space-y-4">
            <li className="flex flex-col space-y-1">
              <span className="font-medium">Name</span>
              <input
                className="bg-gray-50 border border-gray-400 text-gray-900 font-light text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-[200px] p-1.5"
                type="text"
                defaultValue={""}
                {...register("name", { required: true })}
              />
              {errors.name && <span className="text-xs text-red-500">This field is required</span>}
            </li>
            <li className="flex flex-col space-y-1">
              <span className="font-medium">Phone Number</span>
              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: false,
                  validate: (value) => {
                    if (!value) {
                      return true;
                    }
                    return isValidPhoneNumber(value);
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInput
                    className="bg-gray-50 border border-gray-400 text-gray-900 font-light text-sm rounded-sm  w-[200px] p-1.5"
                    value={value}
                    onChange={onChange}
                    defaultCountry="IN"
                  />
                )}
              />
              {errors["phoneNumber"] && <span className="text-xs text-red-500">Invalid Number!</span>}
            </li>
          </ul>
          <ul className="text-sm font-light text-secondary-color space-y-4">
            <li className="flex flex-col space-y-1">
              <span className="font-medium">Company</span>
              <input
                className="bg-gray-50 border border-gray-400 text-gray-900 font-light text-sm rounded-sm focus:ring-primary-color focus:border-primary-color block w-[200px] p-1.5"
                type="text"
                defaultValue={"Konnectify"}
                {...register("organisation")}
              />
            </li>
          </ul>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={cancelEditProfile}
            className="text-sm px-6 py-2 bg-[#E0E4E9] hover:bg-gray-100 transition-all space-x-2 w-auto border flex justify-center items-center rounded-sm"
          >
            Cancel
          </button>
          <button className="text-sm px-6 py-2 text-white bg-primary-color hover:bg-[#283F62] transition-all space-x-2 w-auto border flex justify-center items-center rounded-sm">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProfile;
