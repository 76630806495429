import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "../Modal/modal";
import { useAppDispatch, useAppSelector } from "state/store";
import { retriveFieldSettings, serializefieldSettings } from "utils/formBuilder.utils";
import {
  fieldList,
  renderDefaultFields,
  renderDependentFieldList,
  renderFieldList,
  renderSelectField,
} from "../constants/fieldList";
import { saveFieldSettings, setShowModal } from "state/form-builder/slices";
import { StatusType } from "state/new-konnector/types";
import RenderTextFields from "./RenderTextField";
import RenderSelectField from "./RenderSelectField";
import RenderDependentField from "./RenderDependentField";
import RenderDefault from "./RenderDefault";

const FieldSettings: React.FC<any> = ({ konenctorSlice, konnectorState, field, showModal }) => {
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const currentFieldId = useAppSelector((state) => state.formBuilder.currentFormField);
  const formFields = useAppSelector((state) => state.formBuilder.formFields);
  const status = useAppSelector((state) => state.formBuilder.status);
  const getFormField = (state) => {
    const findField = state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId);

    if (findField) {
      const fieldVlaue = retriveFieldSettings(findField);
      return fieldVlaue;
    }
  };
  const currentFormField = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId)
  );
  const formField = useAppSelector(getFormField);

  const onSubmit = (data) => {
    data.input_type = currentFormField.inputType;
    console.log(data);

    data.sample_data = fieldList.find((field) => field.type === currentFormField.inputType).sampleData;
    const fieldSettings = serializefieldSettings(data, formFields, currentFieldId);
    dispatch(saveFieldSettings(fieldSettings));
    dispatch(setShowModal(false));
    reset();
  };

  const closeModal = () => {
    dispatch(setShowModal({ modal: false, id: field.fieldId }));
  };

  useEffect(() => {
    // reset();
  }, [showModal]);

  useEffect(() => {
    if (currentFieldId) {
      reset(formField);
    }
  }, []);

  const renderForm = (field) => {
    let fields: any;
    switch (field.inputType) {
      case "text":
        fields = [...renderFieldList];
        return <RenderTextFields fieldList={fields} register={register} errors={errors} reset={reset} />;
      case "radio":
      case "select":
        fields = [...renderSelectField];
        return <RenderSelectField fieldList={fields} register={register} errors={errors} reset={reset} />;
      case "dependent":
        fields = [...renderDependentFieldList];
        return <RenderDependentField fieldList={fields} register={register} errors={errors} reset={reset} />;
      default:
        fields = [...renderDefaultFields];
        return <RenderDefault fieldList={fields} register={register} errors={errors} reset={reset} />;
    }
  };

  return (
    <>
      <Modal visible={showModal} closeModalHandler={closeModal}>
        <form
          className="px-3 py-2 pb-2 space-y-1 lg:px-8 sm:pb-6 xl:pb-8 transition-all"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col space-y-2 w-full">{renderForm(field)}</div>
          <div className="flex justify-end">
            <button
              disabled={status === StatusType.failed}
              type="submit"
              className="w-24 text-primary-color font-medium rounded text-sm px-3 py-2 text-center bg-gray-100 border-2 border-gray-300 disabled:opacity-50"
            >
              Save field
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default FieldSettings;
