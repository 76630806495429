import React from "react";
import ContentLoader, { Facebook } from "react-content-loader";
import { updateFilter, updateFilterWorkflow } from "state/workFlows/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import { Icon } from "../Icon";
import { getworkflowsFilterType, getworkflowsSortBy } from "state/workFlows/selectors";
import { useHistory, useLocation } from "react-router-dom";
import { StatusType } from "state/edit-workflow/types";
import { updateDisplay } from "state/edit-workflow/slices";
import { FilterByType } from "state/workFlows/types";
import Describe from "../Icon/DescribeInstruct.icon";

const KonnectorPageSideBar: React.FC<any> = ({ type }) => {
  const appType = useAppSelector((state) => state.konnectors.filterBy);
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const activeClass = "text-white  bg-komp-hover-color hover:bg-[#1f324e] opacity-100";
  const defaultClass = "text-gray-300 opacity-50";
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const status = useAppSelector((state) => state[konnectorState].status);
  const savedLookups = useAppSelector((state) => state[konnectorState].data.lookups);
  const trigger = useAppSelector((state) => state[konnectorState].data.trigger);
  const savedRules = useAppSelector((state) => state[konnectorState].data.ruleEngine);
  const savedActions = useAppSelector((state) => state[konnectorState].data.actions);
  const savedParseEngine = useAppSelector((state) => state[konnectorState].data.parseEngine);
  const hasSampleData = useAppSelector((state) => state[konnectorState].data.trigger.outputFields.hasData);
  let loaderArr = [1, 1, 1];
  let actionArray = [
    ...savedLookups,
    ...savedRules,
    ...savedParseEngine,
    ...savedActions,
    trigger,
    {
      actionOrderId: -1,
      id: "",
      appName: "Configurations",
      appSelectorType: "configration",
    },
  ];
  actionArray = actionArray.sort((a, b) => a.actionOrderId - b.actionOrderId);

  let buildConfig = actionArray.map((action) => {
    return {
      id: action.id,
      type: action.appSelectorType,
      name: action.appName,
      icon: action.iconUrl,
    };
  });

  const filterBy = useAppSelector(getworkflowsFilterType);
  const sortBy = useAppSelector(getworkflowsSortBy);
  const query = new URLSearchParams(location.search);
  const appId: any = query.get("appId");

  const getClasses = (type) => {
    return type.id === currentActionId || (currentActionId === "Source" && type.type === "source")
      ? ` ${activeClass}`
      : ` ${defaultClass}`;
  };

  const onClickFliterHandler = (type) => {
    dispatch(updateFilterWorkflow(FilterByType.INSTRUCT));
    dispatch(updateDisplay(type));
  };

  return (
    <div className="border-r-2 h-full w-[80%] p-2 flex flex-col bg-komp-secondary-color ">
      <div className="text-2xl font-semibold text-white mt-4 mb-12">Konnector</div>
      <div className="flex flex-col space-y-3">
        {actionArray.length < 3 && type !== "New Konnector" ? (
          <>
            {loaderArr.map(() => {
              return (
                <ContentLoader
                  viewBox="0 0 201.6 30.14"
                  animate={true}
                  backgroundColor="#394251"
                  foregroundColor="#576270"
                >
                  {/* Only SVG shapes */}

                  <rect className="loader-1" width="201.6" height="30.14" rx="6.29" ry="6.29" />
                </ContentLoader>
              );
            })}
          </>
        ) : (
          <>
            {buildConfig.map((type) => {
              return (
                <>
                  <div
                    className={
                      "flex  group items-center space-x-3 rounded-lg px-2 hover:bg-komp-hover-color" + getClasses(type)
                    }
                  >
                    <div className="flex group-hover:opacity-100">
                      <Icon type={type} className={"w-5 h-5 relative"} />
                      {/* {type.icon ? (
                      <img src={type.icon} alt="" className="w-5 ml-2" />
                    ) : (
                      <div className="w-5 h-5 bg-komp-secondary-color ml-2 rounded-xl"></div>
                    )} */}
                    </div>
                    <button
                      onClick={() => {
                        onClickFliterHandler(false);
                      }}
                      type="button"
                      className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all "}
                    >
                      {type.name ? type.name : type.type}
                    </button>
                  </div>
                </>
              );
            })}
            <div
              className={
                "flex  group items-center space-x-3 rounded-lg px-2 hover:bg-komp-hover-color" + getClasses("settings")
              }
            >
              <div className="flex group-hover:opacity-100">
                <Describe type={type} className={"w-5 h-5 relative"} />
              </div>
              <button
                onClick={() => onClickFliterHandler(true)}
                type="button"
                className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all "}
              >
                Describe and Instruct
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default KonnectorPageSideBar;
