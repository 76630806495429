import React from "react";
import "./Checkout.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from "./CheckoutForm";
import { useForm } from "react-hook-form";
interface CheckoutProps {
  closeModalHandler?: boolean;
}
loadStripe.setLoadParameters({ advancedFraudSignals: false });

const stripePromise = loadStripe(
  "pk_test_51LUlncCwaHmK3UjoCT8FH1zWVMEFi67uC3H3MtaWphB1Y025HNDi60twGa9SkYiN50rwiPcSHnRiFRxmPSLvjk9K00ISjbiw99"
);
const Checkout: React.FC<CheckoutProps> = ({ closeModalHandler }) => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm planId={""} />
    </Elements>
  );
};

export default Checkout;
