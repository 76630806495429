import { AxiosResponse } from "axios";
import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { validateAccountApi, createAccountApi } from "api/accountsApi";
import { AppRootObject } from "./../../interfaces/modules/apps/index";
import { fetchOutputFieldsApi, fetchDynamicInputFieldsApi, fetchApp, fetchOutputFieldsSchema } from "api/appsApi";
import {
  saveKonnectorApi,
  generateWebhookUrl,
  getWebhookData,
  deleteWebhookDataApi,
  resetWebhookDataApi,
  generateFormSampleDataApi,
  getWebhookFromTemplate,
} from "api/konnectorsApi";
import {
  AppRole,
  CreateAccountSuccessResponse,
  DynamicFieldsSchemaType,
  DynamicInputFields,
  InputFields,
  KonnectifyApiResponse,
  KonnectorAppState,
  KonnectorType,
  UpdateSourceAppPayload,
  UpdateTargetAppPayload,
  ValidateAccountSuccessResponse,
} from "./types";
import { getTriggerDetails, getLookupDetails, getTargetDetails, getCreateWorkflowDetails } from "./selectors";
import { getParseEngineSlice, getRuleConfigSlice } from "state/selectors";
import {
  validateAccountLoading,
  validateAccountSuccess,
  validateAccountFailed,
  createAccountLoading,
  createAccountSuccess,
  createAccountFailed,
  fetchOutputFieldsLoading,
  fetchOutputFieldsSuccess,
  fetchInputFieldsLoading,
  fetchDynamicInputFieldsLoading,
  updateSourceAppLoading,
  updateSourceAppSuccess,
  fetchTriggerInputfieldsLoading,
  fetchTriggerInputfieldsSuccess,
  updateTargetAppLoading,
  updateTargetAppSuccess,
  fetchDynamicInputFieldsSuccess,
  fetchActionDynamicInputFieldsSuccess,
  saveKonnectorLoading,
  updateLookupAppLoading,
  updateLookupAppSuccess,
  saveLookupConfigurationLoading,
  saveLookupConfigurationSuccess,
  saveKonnectorSuccess,
  displayErrorMessage,
  setGenarateUrlSuccess,
  setGenarateUrlLoading,
  getSampleDataLoading,
  getSampleDataSucess,
  resetWebhookLoading,
  resetWebhookSuccess,
  resetWebhook,
  getSampleDataError,
  addFormFields,
  generateFormSampleDataLoading,
  saveFormSourceConfiguration,
  updateSourceEvent,
  reset,
} from "./actions";

import { saveDataMapping } from "state/rule-engine/slices";
import {
  addParseConfig,
  addRuleConfig,
  appDescriptionSuccess,
  saveSourceConfiguration,
  updateAppDescription,
} from "./slices";
import { saveParseDataMapping } from "state/parseEngine/slices";
import { setFormStatus } from "state/form-builder/slices";
import { setSampleDataForSerialization } from "utils/formBuilder.utils";
import { formatDataToFormFields, serializeCreateKonnectorData } from "utils/workflowUtils";
import { saveWorkflowApi, updatingAppDescription } from "api/workflowApi";
import { useAppSelector } from "state/store";

export function* generateWebhookUrlData(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const result = yield call(generateWebhookUrl, user.tenantId, data.payload);
    const payload = {
      url: result.data.data.webhookUrl,
      id: result.data.data.webhookId || result.data.data.formId,
    };
    if (data.payload.konnectorType === KonnectorType.Form) {
      const formFields = yield select((state) => state.formBuilder.formFields);
      yield put(addFormFields(formFields));
    }

    const setGenarateUrlSuccesss = yield put(setGenarateUrlSuccess(payload));

    if (data.payload.eventid && setGenarateUrlSuccesss) {
      const user = yield select((state) => state.auth.currentUser);
      const result = yield call(getWebhookData, payload.id, data.payload.konnectorType, user.tenantId);
      if (result.data.data) {
        yield put(getSampleDataSucess(result.data.data));
      }
    }
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* saveForm(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const webhookUrl = yield select((state) => state.createNewWorkflow.data.webhookUrl);
    let payload = {};
    let formId: string;
    if (!webhookUrl.id) {
      const result = yield call(generateWebhookUrl, user.tenantId, data.payload);
      formId = result.data.data.formId;
      payload = {
        url: result.data.data.webhookUrl,
        id: formId,
      };
    } else {
      formId = webhookUrl.id;
      payload = {
        url: webhookUrl.url,
        id: formId,
      };
    }
    yield put(setGenarateUrlSuccess(payload));
    const stateFormFields = yield select((state) => state.formBuilder.formFields);
    const formFields = formatDataToFormFields(stateFormFields);
    const sampleData = setSampleDataForSerialization(stateFormFields);
    const generateForm = yield call(generateFormSampleDataApi, formId, user.tenantId, { sampleData, formFields });
    if (generateForm.data.data.error) {
      yield put(getSampleDataError(generateForm.data.data));
    } else {
      yield put(getSampleDataSucess(generateForm.data.data));
      yield put(setFormStatus("success"));
    }
    yield put(addFormFields(stateFormFields));
    // yield put(saveSourceConfiguration());
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* getWebhookUrlData(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const result = yield call(getWebhookData, data.payload.webhookId, data.payload.konnectorType, user.tenantId);
    if (result.data.data.error) {
      yield put(getSampleDataError(result.data.data));
    } else {
      yield put(getSampleDataSucess(result.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* restWebhookUrlData(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const result = yield call(resetWebhookDataApi, data.payload.webhookId, user.tenantId);
    yield put(resetWebhookSuccess());
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* deleteWebhookData(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const konnector = yield select((state) => state.createNewWorkflow);
    const created = yield select((state) => state.createNewWorkflow.created);

    const konnectorType = konnector.data.konnectorType;
    if (konnector.data.webhookUrl.id && !created) {
      const deleteData = yield call(deleteWebhookDataApi, konnector.data.webhookUrl.id, konnectorType, user.tenantId);
      console.log(deleteData);
    }
    yield put(reset());
  } catch (e) {
    console.log(e);
  }
}

export function* updateSourceApp(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<AppRootObject>> = yield call(fetchApp, data.payload.appId);
    const payload: UpdateSourceAppPayload = { ...data.payload, appDetails: result.data.data };
    yield put(updateSourceAppSuccess(payload));
  } catch (e) {
    console.log("🚀 ~ file: saga.ts ~ line 38 ~ function*updateSourceApp ~ e", e);
    yield put(createAccountFailed());
  }
}
export function* updateTargetApp(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<AppRootObject>> = yield call(fetchApp, data.payload.appId);
    const payload: UpdateTargetAppPayload = { ...data.payload, appDetails: result.data.data };
    yield put(updateTargetAppSuccess(payload));
  } catch (e) {
    yield put(createAccountFailed());
  }
}
export function* updateLookupApp(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<AppRootObject>> = yield call(fetchApp, data.payload.appId);
    const payload: UpdateTargetAppPayload = { ...data.payload, appDetails: result.data.data };
    yield put(updateLookupAppSuccess(payload));
  } catch (e) {
    yield put(createAccountFailed());
  }
}
export function* getTriggerInputSchema(data) {
  try {
    const payload = {
      appId: data.payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: data.payload.event,
        connectionConfigId: data.payload.connectionId,
      },
    };
    const result = yield call(fetchDynamicInputFieldsApi, payload);
    console.log(result);
    const inputFields = {
      fields: result.data.data.data,
      appId: result.data.appId,
      eventId: result.data.eventId,
    };
    yield put(fetchTriggerInputfieldsSuccess(inputFields));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* validateAccount(data) {
  try {
    const result: AxiosResponse<ValidateAccountSuccessResponse> = yield call(validateAccountApi, data.payload);
    yield put(validateAccountSuccess(result.data));
  } catch (e) {
    yield put(validateAccountFailed());
  }
}

export function* createAccount(data) {
  try {
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(createAccountApi, data.payload);
    yield put(createAccountSuccess("result.data"));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* fetchOutputFields() {
  try {
    const triggerDetails: KonnectorAppState<AppRole.Source> = yield select(getTriggerDetails);
    let dataMapping = {};
    if (triggerDetails.inputFields.hasData) {
      dataMapping = triggerDetails.inputFields.dataMapping;
      console.log({ dataMapping });
    }
    const postReq = {
      appId: triggerDetails.appId,
      data: {
        eventId: triggerDetails.eventId,
        connectionConfigId: triggerDetails.connectionId,
        eventData: { ...dataMapping },
      },
    };

    const [sampleData, outputFieldsSchema]: AxiosResponse<any>[] = yield all([
      call(fetchOutputFieldsApi, postReq),
      call(fetchOutputFieldsSchema, {
        appId: triggerDetails.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.DefaultOutput,
          eventId: triggerDetails.eventId,
          connectionConfigId: triggerDetails.connectionId,
          eventData: { ...dataMapping },
        },
      }),
    ]);
    const payload = { sampleData: sampleData.data.data, outputFieldsSchema: outputFieldsSchema.data.data };

    yield put(fetchOutputFieldsSuccess(payload));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* fetchLookupOutputFields(data) {
  try {
    const lookupDetails: KonnectorAppState<AppRole.Lookup> = yield select((state) =>
      getLookupDetails(state, data.payload)
    );
    console.log(lookupDetails);
    const [sampleData, outputFieldsSchema]: AxiosResponse<any>[] = yield all([
      call(fetchOutputFieldsApi, {
        appId: lookupDetails.appId,
        data: {
          eventId: lookupDetails.eventId,
          connectionConfigId: lookupDetails.connectionId,
        },
      }),
      call(fetchOutputFieldsSchema, {
        appId: lookupDetails.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.DefaultOutput,
          eventId: lookupDetails.eventId,
          connectionConfigId: lookupDetails.connectionId,
        },
      }),
    ]);
    const payload = { sampleData: sampleData.data.data, outputFieldsSchema: outputFieldsSchema.data.data };
    yield put(saveLookupConfigurationSuccess(payload));
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* fetchDynamicInputFields() {
  try {
    const targetDetails: KonnectorAppState<AppRole.Target> = yield select(getTargetDetails);
    let dataMapping = {};
    if (targetDetails.dynamicInputFields.hasData) {
      dataMapping = targetDetails.dynamicInputFields.dataMapping;
    }
    const result: AxiosResponse<KonnectifyApiResponse<InputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: targetDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DefaultInput,
        eventId: targetDetails.eventId,
        connectionConfigId: targetDetails.connectionId,
        eventData: { ...dataMapping },
      },
    });
    console.log(result);
    yield put(fetchDynamicInputFieldsSuccess(result.data.data));
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* fetchActionDynamicInputFields() {
  try {
    const targetDetails: KonnectorAppState<AppRole.Target> = yield select(getTargetDetails);
    const result: AxiosResponse<KonnectifyApiResponse<DynamicInputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: targetDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicInput,
        eventId: targetDetails.eventId,
        connectionConfigId: targetDetails.connectionId,
      },
    });
    console.log(result.data);
    console.log(result.data.data.data);
    yield put(fetchActionDynamicInputFieldsSuccess(result.data.data.data));
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* saveKonnector() {
  try {
    const createKonnectorState = yield select(getCreateWorkflowDetails);
    const workflowState = yield select((state) => state.workflows.workflowState);
    const actionId = yield select((state) => state[workflowState].data.actions[0]?.appId);
    const getappId = yield select((state) => state[workflowState].data.trigger.appId);
    const authId = yield select((state) => state.auth.app);

    const type = createKonnectorState.data.workflowType
    const serializeData = serializeCreateKonnectorData(createKonnectorState, authId, getappId, actionId, type);
    const result: AxiosResponse<any> = yield call(saveWorkflowApi, serializeData);
    console.log(result.data);

    if (result.data.status === 401) {
      yield put(displayErrorMessage(result.data.message));
      throw Error("Upgrade your plan");
    }

    yield put(saveKonnectorSuccess());
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* saveRuleConfig() {
  try {
    const ruleConfig = yield select(getRuleConfigSlice);
    console.log({ ruleConfig });
    yield put(addRuleConfig(ruleConfig));
  } catch (e) {
    console.log(e);
  }
}
export function* saveParseConfig() {
  try {
    const parseConfig = yield select(getParseEngineSlice);
    yield put(addParseConfig(parseConfig));
  } catch (e) {
    console.log(e);
  }
}

export function* updateDescription(data) {
  try {
    const result: AxiosResponse<any> = yield call(updatingAppDescription, data.payload);
    yield put(appDescriptionSuccess(result.data));
  } catch (e) {
    // yield put(validateAccountFailed());
  }
}
export default function* rootSaga() {
  yield takeEvery(setGenarateUrlLoading.type, generateWebhookUrlData);
  yield takeEvery(getSampleDataLoading.type, getWebhookUrlData);
  yield takeEvery(resetWebhookLoading.type, restWebhookUrlData);
  yield takeEvery(resetWebhook.type, deleteWebhookData);
  yield takeEvery(saveFormSourceConfiguration.type, saveForm);
  yield takeEvery(validateAccountLoading.type, validateAccount);
  yield takeEvery(createAccountLoading.type, createAccount);
  yield takeEvery(fetchTriggerInputfieldsLoading.type, getTriggerInputSchema);
  yield takeEvery(fetchOutputFieldsLoading.type, fetchOutputFields);
  yield takeEvery(fetchInputFieldsLoading.type, fetchDynamicInputFields);
  yield takeEvery(fetchDynamicInputFieldsLoading.type, fetchActionDynamicInputFields);
  yield takeEvery(updateSourceAppLoading.type, updateSourceApp);
  yield takeEvery(updateTargetAppLoading.type, updateTargetApp);
  yield takeEvery(updateLookupAppLoading.type, updateLookupApp);
  yield takeEvery(saveLookupConfigurationLoading.type, fetchLookupOutputFields);
  yield takeEvery(saveKonnectorLoading.type, saveKonnector);
  yield takeEvery(saveDataMapping.type, saveRuleConfig);
  yield takeEvery(saveParseDataMapping.type, saveParseConfig);
  yield takeEvery(updateAppDescription.type, updateDescription);
}
