import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { serializeDataMapping, serializeLookupDataMapping } from "utils/appsUtils";
import { setActionOrderId, setTriggerData } from "utils/konnectorUtils";
import { v4 as uuidv4 } from "uuid";
import {
  InitialState,
  InputFields,
  UpdateTargetEventPayload,
  StatusType,
  AppRole,
  UpdateSourceAppPayload,
  ValidateAccountSuccessResponse,
  CreateAccountSuccessResponse,
  UpdateSourceAccountPayload,
  UpdateTargetAppPayload,
  UpdateTargetAccountPayload,
  KonnectorAppState,
  ActionMode,
  ConfigurationType,
  UpdateLookupEventPayload,
  KonnectorType,
  InputFieldSchema,
  ParentProps,
} from "../types";

export const initialState: InitialState = {
  data: {
    id: "",
    name: "Edit Konnector",
    konnectorType: KonnectorType.Initial,
    webhookUrl: {
      url: "",
      id: "",
    },
    trigger: {
      triggerType: "",
      appSelectorKey: "",
      parentId: ParentProps.Source,
      appName: "",
      iconUrl: "",
      appSelectorType: AppRole.Source,
      formFields: [],
      formSettings: {},
      appId: "",
      actionOrderId: 0,
      connectionId: "",
      connectionName: "",
      eventId: "",
      isAccountValidated: false,
      outputFields: {
        hasData: false,
        fields: {},
        schema: {},
      },
      inputFields: { appId: "", data: [], eventId: "", sourceId: "", dataMapping: {}, hasData: false },
      appDetails: {},
    },
    lookups: [],
    actions: [],
    repeater: {
      currentActionId: "",
      actionOrderId: 0,
      appSelectorType: AppRole.Repeater,
      actionMode: ActionMode.Empty,
      actions: [],
      ruleEngine: [],
      lookups: [],
      parseEngine: [],
      source: "",
      editRule: false,
      editParse: false,
    },
    ruleEngine: [],
    parseEngine: [],
    sourceTemplate: "",
  },
  currentActionId: "",
  hasConfigurationSaved: true,
  configurationType: ConfigurationType.Source,
  actionMode: ActionMode.View,
  status: StatusType.idle,
  resetWebhook: StatusType.idle,
  generateUrl: StatusType.idle,
  resetFormStatus: StatusType.idle,
  isFormGenerated: false,
  errorMessage: "",
  created: false,
  saveButtonStatus: false,
  editRule: false,
  edit: false,
  editParse: false,
};

const editKonnectorSlice = createSlice({
  name: "editKonnector",
  initialState,
  reducers: {
    getKonnectorLoading: (state, data: PayloadAction<string>) => {
      state.status = StatusType.loading;
    },
    getKonnectorSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      state.data = data.payload.data;
      state.generateUrl = StatusType.success;
    },
    getKonnectorFailed: (state) => {
      state.status = StatusType.failed;
    },
    updateName: (state, data: PayloadAction<string>) => {
      state.data.name = data.payload;
      state.edit = true;
    },
    updateKonnectorSource: (state, data) => {
      state.data.sourceTemplate = data.payload;
    },
    setGenarateUrlLoading: (state, data) => {
      state.generateUrl = StatusType.loading;
    },
    setGenarateUrlSuccess: (state, data) => {
      state.generateUrl = StatusType.success;
      state.data.webhookUrl = data.payload;
    },
    getSampleDataLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    getSampleDataSucess: (state, data) => {
      state.status = StatusType.success;
      state.data.trigger.outputFields = data.payload;
      state.data.trigger.outputFields.hasData = true;
    },
    getSampleDataError: (state, data) => {
      state.status = StatusType.success;
      state.errorMessage = data.payload.errorMessage;
      state.data.trigger.outputFields = data.payload;
    },
    resetWebhookLoading: (state) => {
      state.resetWebhook = StatusType.loading;
    },
    resetWebhookSuccess: (state) => {
      state.resetWebhook = StatusType.success;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.outputFields.schema = {};
      state.data.trigger.outputFields.hasData = false;
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.rawDataMapping = {};
        lookup.shouldReconfigure = true;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.rawDataMapping = {};
        action.shouldReconfigure = true;
        return action;
      });
    },
    saveFormSourceConfiguration: (state, data) => {
      state.generateUrl = StatusType.loading;
      state.status = StatusType.loading;
      state.isFormGenerated = true;
    },
    deleteWebhook: (state, data) => {
      state.generateUrl = StatusType.loading;
    },
    deleteWebhookSucess: (state) => {
      state.generateUrl = StatusType.success;
    },
    addFormFields: (state, data) => {
      state.data.trigger.formFields = data.payload.formFields;
      state.data.trigger.formSettings = data.payload.formSettings;
    },
    updateSourceAppLoading: (state, data: PayloadAction<UpdateSourceAppPayload>) => {
      state.data.konnectorType = KonnectorType.Regular;
      state.status = StatusType.loading;
    },
    updateKonnectorType: (state, data: PayloadAction<KonnectorType>) => {
      state.data.konnectorType = data.payload;
      state.data.trigger = setTriggerData(data.payload, state.data.trigger);
    },
    updateSourceAppSuccess: (state, data: PayloadAction<UpdateSourceAppPayload>) => {
      state.status = StatusType.idle;
      state.data.trigger.appId = data.payload.appId;
      state.data.trigger.appName = data.payload.appName;
      state.data.trigger.iconUrl = data.payload.iconUrl;
      state.data.trigger.connectionId = data.payload.connectionId || "";
      state.data.trigger.connectionName = data.payload.connectionName || "";
      state.data.trigger.eventId = "";
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.appDetails = data.payload.appDetails;
    },
    updateSourceAccount: (state, data: PayloadAction<UpdateSourceAccountPayload>) => {
      state.data.konnectorType = KonnectorType.Regular;
      state.data.trigger.connectionId = data.payload.connectionId;
      state.data.trigger.connectionName = data.payload.connectionName;
      state.data.trigger.eventId = "";
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.inputFields.hasData = false;
      state.data.trigger.inputFields.dataMapping = {};
      state.data.trigger.inputFields.data = [];
    },
    updateSourceEvent: (state, data: PayloadAction<any>) => {
      state.data.trigger.eventId = data.payload.event;
      state.data.trigger.triggerType = data.payload.type;
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
      state.data.trigger.inputFields.hasData = false;
      state.data.trigger.inputFields.dataMapping = {};
      state.data.trigger.inputFields.data = [];
    },
    fetchTriggerInputfieldsLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    fetchTriggerInputfieldsSuccess: (state, data) => {
      state.status = StatusType.success;
      state.data.trigger.inputFields.data = data.payload.fields;
      state.data.trigger.inputFields.hasData = true;
    },
    setSourceEventDataMapping: (state, data) => {
      state.data.trigger.inputFields.dataMapping[data.payload.field] = data.payload.value;
      state.data.trigger.outputFields.hasData = false;
      state.data.trigger.outputFields.fields = {};
    },
    validateAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    validateAccountSuccess: (state, data: PayloadAction<ValidateAccountSuccessResponse>) => {
      state.status = StatusType.success;
      state.data.trigger.isAccountValidated = data.payload.data;
    },
    validateAccountFailed: (state) => {
      state.status = StatusType.failed;
    },
    createAccountLoading: (state, data: PayloadAction<any>) => {
      state.status = StatusType.loading;
    },
    createAccountSuccess: (state, data: PayloadAction<CreateAccountSuccessResponse>) => {
      state.data.trigger.connectionId = data.payload.data.id;
      state.status = StatusType.success;
    },
    createAccountFailed: (state) => {
      state.status = StatusType.failed;
    },
    fetchOutputFieldsLoading: (state) => {
      state.status = StatusType.loading;
    },
    fetchOutputFieldsSuccess: (state, data: PayloadAction<any>) => {
      state.status = StatusType.success;
      state.data.trigger.outputFields.fields = data.payload.sampleData;
      state.data.trigger.outputFields.schema = data.payload.outputFieldsSchema;
      state.data.trigger.outputFields.hasData = true;
    },
    saveSourceConfiguration: (state) => {
      state.hasConfigurationSaved = true;
      state.edit = true;
      state.data.trigger.actionMode = ActionMode.View;
      state.currentActionId = "";
      state.data.lookups = state.data.lookups.map((lookup) => {
        const copyOfLookup = { ...lookup };
        copyOfLookup.shouldReconfigure = true;
        // copyOfLookup.rawDataMapping = {};
        return copyOfLookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        const copyOfAction = { ...action };
        copyOfAction.shouldReconfigure = true;
        // copyOfAction.rawDataMapping = {};
        return copyOfAction;
      });
      state.data.parseEngine = state.data.parseEngine.map((action) => {
        const copyOfAction = { ...action };
        // copyOfAction.rawDataMapping = {};
        copyOfAction.shouldReconfigure = true;
        return copyOfAction;
      });
    },

    //repeaters
    createRepeater: (state) => {
      state.data.repeater.actionMode = ActionMode.Create;
      state.currentActionId = "repeater";
      state.data.repeater.actionOrderId = setActionOrderId(
        state.data.lookups,
        state.data.ruleEngine,
        state.data.parseEngine,
        state.data.actions,
        state.data.repeater,
        true
      );
    },
    updateRepeaterSource: (state, data) => {
      state.data.repeater.source = data.payload.value;
    },

    //Actions
    createAction: (state, data) => {
      const action: KonnectorAppState<AppRole.Target> = {
        id: uuidv4(),
        // parentId: data.payload.parentId || ParentProps.Source,
        actionOrderId: 0,
        appSelectorKey: "",
        appName: "",
        iconUrl: "",
        appSelectorType: AppRole.Target,
        appId: "",
        connectionId: "",
        connectionName: "",
        eventId: "",
        isAccountValidated: false,
        appDetails: {},
        actionMode: ActionMode.Create,
      };
      let { lookupData, actionData, cState, parseConfig, ruleConfig } =
        editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      actionData.push(action);
      cState.currentActionId = action.id;
      state.configurationType = ConfigurationType.Target;
      const findActionIndex = actionData.findIndex((actionItem) => actionItem.id === action.id);
      actionData[findActionIndex].actionOrderId = setActionOrderId(
        lookupData,
        ruleConfig,
        parseConfig,
        actionData,
        state.data.repeater,
        true
      );
      state.actionMode = ActionMode.Edit;
    },
    updateTargetAppLoading: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.loading;
    },
    updateTargetAppSuccess: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      state.status = StatusType.idle;
      const findActionIndex = actionData.findIndex((action) => action.id === data.payload.id);
      actionData[findActionIndex] = {
        ...actionData[findActionIndex],
        appId: data.payload.appId,
        appName: data.payload.appName,
        iconUrl: data.payload.iconUrl,
        connectionId: data.payload.connectionId || "",
        connectionName: data.payload.connectionName || "",
        eventId: "",
        appDetails: data.payload.appDetails,
      };
    },
    updateTargetAccount: (state, data: PayloadAction<UpdateTargetAccountPayload>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = actionData.findIndex((action) => action.id === data.payload.id);
      actionData[findActionIndex] = {
        ...actionData[findActionIndex],
        connectionId: data.payload.connectionId,
        connectionName: data.payload.connectionName,
        eventId: "",
      };
    },
    updateTargetEvent: (state, data: PayloadAction<UpdateTargetEventPayload>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = actionData.findIndex((action) => action.id === data.payload.id);
      actionData[findActionIndex].eventId = data.payload.eventId;
      actionData[findActionIndex].inputFields = {
        appId: "",
        eventId: "",
        sourceId: "",
        data: [],
      };
      actionData[findActionIndex].dynamicInputFields = {
        data: [],
        hasData: false,
        hasDynamicInputFields: data.payload.hasDynamicInputFields,
        dataMapping: {},
      };
      actionData[findActionIndex].rawDataMapping = {};
    },
    fetchDynamicInputFieldsLoading: (state) => {
      state.status = StatusType.loading;
    },
    fetchInputFieldsLoading: (state) => {
      state.status = StatusType.loading;
    },
    fetchDynamicInputFieldsSuccess: (state, data: PayloadAction<InputFieldSchema>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = actionData.findIndex((action) => action.id === cState.currentActionId);
      state.status = StatusType.success;
      actionData[findActionIndex].inputFields = data.payload.value;
    },
    fetchActionDynamicInputFieldsSuccess: (state, data: PayloadAction<InputFieldSchema>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = actionData.findIndex((action) => action.id === cState.currentActionId);
      state.status = StatusType.success;
      actionData[findActionIndex].dynamicInputFields.data = data.payload.value;
      actionData[findActionIndex].dynamicInputFields.hasData = true;
    },
    setActionEventDataMapping: (state, data) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = actionData.findIndex((action) => action.id === cState.currentActionId);
      actionData[findActionIndex].dynamicInputFields.dataMapping[data.payload.field] = data.payload.value;
      let findFieldIndex = actionData[findActionIndex].dynamicInputFields.data?.findIndex(
        (field) => field?.dependentTo === data.payload.field
      );
      if (findFieldIndex !== -1) {
        actionData[findActionIndex].dynamicInputFields.data[findFieldIndex].propValue = [];
        let dependentToField = actionData[findActionIndex].dynamicInputFields.data[findFieldIndex].propName;
        delete actionData[findActionIndex].dynamicInputFields.dataMapping[dependentToField];
      }
      actionData[findActionIndex].rawDataMapping = {};
      actionData[findActionIndex].inputFields = {
        appId: "",
        eventId: "",
        sourceId: "",
        data: [],
      };
    },
    fetchDynamicFieldsLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    fetchDynamicFieldsSuccess: (state, data) => {
      const findActionIndex = state.data[data.payload.actionType].findIndex(
        (action) => action.id === state.currentActionId
      );
      state.status = StatusType.success;
      state.data[data.payload.actionType][findActionIndex].dynamicInputFields.data.find(
        (val) => val.propName === data.payload.field
      ).propValue = data.payload.value;
    },
    saveDataMapping: (state, data: PayloadAction<any>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);

      const sourceId = state.data.trigger.outputFields.schema.sourceId;
      const findActionIndex = actionData.findIndex((action) => action.id === cState.currentActionId);
      actionData[findActionIndex].actionMode = ActionMode.View;
      const serializeData = serializeDataMapping(
        actionData[findActionIndex].inputFields.data,
        data.payload.value,
        sourceId
      );
      actionData[findActionIndex].dataMapping = serializeData;
      actionData[findActionIndex].rawDataMapping = data.payload.value;
      actionData[findActionIndex].shouldReconfigure = false;
      state.actionMode = ActionMode.Save;
      cState.currentActionId = "";
    },
    //Lookups
    createLookup: (state, data) => {
      const lookup: KonnectorAppState<AppRole.Lookup> = {
        id: uuidv4(),
        parentId: data.payload?.parentId || ParentProps.Source,
        actionOrderId: 0,
        appSelectorKey: "",
        appName: "",
        iconUrl: "",
        appSelectorType: AppRole.Lookup,
        appId: "",
        connectionId: "",
        connectionName: "",
        eventId: "",
        isAccountValidated: false,
        actionMode: ActionMode.Create,
        appDetails: {},
        inputFields: {
          sourceId: "",
          eventId: "",
          appId: "string",
          data: [
            {
              propName: "value",
              propType: "string",
              isRequired: true,
            },
          ],
        },
        outputFields: {
          hasData: true,
          schema: {
            sourceId: "AMVr3a4T9G:new-contact-trigger",
            eventResponse: {
              data: [
                {
                  propName: "id",
                  propType: "string",
                  isRequired: false,
                },
              ],
            },
          },
        },
      };
      let findActionIndex = -1;

      switch (lookup.parentId) {
        case ParentProps.Repeater:
          state.data.repeater.lookups = [...state.data.repeater.lookups, lookup];
          state.data.repeater.currentActionId = lookup.id;
          findActionIndex = state.data.repeater.lookups.findIndex((action) => action.id === lookup.id);
          state.data.repeater.lookups[findActionIndex].actionOrderId = setActionOrderId(
            state.data.repeater.lookups,
            state.data.repeater.ruleEngine,
            state.data.repeater.parseEngine,
            state.data.repeater.actions,
            state.data.repeater
          );
          break;
        default:
          state.data.lookups = [...state.data.lookups, lookup];
          state.currentActionId = lookup.id;
          state.configurationType = ConfigurationType.Lookup;
          findActionIndex = state.data.lookups.findIndex((action) => action.id === lookup.id);
          state.data.lookups[findActionIndex].actionOrderId = setActionOrderId(
            state.data.lookups,
            state.data.ruleEngine,
            state.data.parseEngine,
            state.data.actions,
            state.data.repeater
          );
          state.data.actions = state.data.actions.map((action) => {
            action.actionOrderId += 1;
            return action;
          });
          state.actionMode = ActionMode.Edit;
      }
    },
    fetchLookupInputFieldsLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    fetchLookupInputFieldsSuccess: (state, data: PayloadAction<InputFieldSchema>) => {
      let { lookupData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === cState.currentActionId);
      state.status = StatusType.success;
      lookupData[findActionIndex].inputFields = data.payload.value;
    },
    updateLookupAppLoading: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.loading;
    },
    updateLookupAppSuccess: (state, data: PayloadAction<UpdateTargetAppPayload>) => {
      state.status = StatusType.idle;
      let { lookupData } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      lookupData[findActionIndex] = {
        ...lookupData[findActionIndex],
        appId: data.payload.appId,
        appName: data.payload.appName,
        iconUrl: data.payload.iconUrl,
        connectionId: data.payload.connectionId || "",
        connectionName: data.payload.connectionName || "",
        eventId: "",
        appDetails: data.payload.appDetails,
      };
    },
    updateLookupAccount: (state, data: PayloadAction<UpdateTargetAccountPayload>) => {
      let { lookupData } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      lookupData[findActionIndex] = {
        ...lookupData[findActionIndex],
        connectionId: data.payload.connectionId,
        connectionName: data.payload.connectionName,
        eventId: "",
      };
    },
    updateLookupEvent: (state, data: PayloadAction<UpdateLookupEventPayload>) => {
      let { lookupData } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      lookupData[findActionIndex].eventId = data.payload.eventId;
      if (typeof data.payload?.attribute === "object") {
        const payload = Object.keys(data.payload.attribute);
        lookupData[findActionIndex].inputFields = {
          appId: "",
          eventId: "",
          sourceId: "",
          data: [],
        };
        for (let i of payload) {
          lookupData[findActionIndex].inputFields.data.push({
            propName: i || "value",
            propType: "string",
            isRequired: true,
          });
        }
      } else {
        lookupData[findActionIndex].inputFields = {
          appId: "",
          eventId: "",
          sourceId: "",
          data: [
            {
              propName: data.payload.attribute || "value",
              propType: "string",
              isRequired: true,
            },
          ],
        };
      }

      lookupData[findActionIndex].rawDataMapping = {};
    },
    saveLookupDataMapping: (state, data: PayloadAction<any>) => {
      let { lookupData } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      const serializeData = serializeLookupDataMapping(lookupData[findActionIndex].inputFields.data, data.payload.data);
      lookupData[findActionIndex].dataMapping = serializeData;
      lookupData[findActionIndex].rawDataMapping = data.payload.data;
    },
    saveLookupConfigurationLoading: (state, data) => {
      state.status = StatusType.loading;
    },
    saveLookupConfigurationSuccess: (state, data: PayloadAction<any>) => {
      let { lookupData, actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      lookupData[findActionIndex].outputFields.fields = data.payload.sampleData;
      lookupData[findActionIndex].outputFields.schema = data.payload.outputFieldsSchema;
      lookupData[findActionIndex].actionMode = ActionMode.View;
      cState.currentActionId = "";
      lookupData = lookupData.map((lookup, index) => {
        const copyOfLookup = { ...lookup };
        if (lookup.id === state.currentActionId) {
          copyOfLookup.outputFields.fields = data.payload.sampleData;
          copyOfLookup.outputFields.schema = data.payload.outputFieldsSchema;
          copyOfLookup.shouldReconfigure = false;
        }
        if (index > findActionIndex) {
          // copyOfLookup.rawDataMapping = {};
          copyOfLookup.shouldReconfigure = true;
        }
        return copyOfLookup;
      });
      actionData = actionData.map((action) => {
        const copyOfAction = { ...action };
        // copyOfAction.rawDataMapping = {};
        copyOfAction.shouldReconfigure = true;
        return copyOfAction;
      });
      state.actionMode = ActionMode.Save;
      state.status = StatusType.success;
    },
    editLookup: (state, data: PayloadAction<any>) => {
      let { lookupData, actionData, cState, ruleConfig } = editKonnectorSlice.caseReducers.manageStateForRepeater(
        state,
        data
      );

      cState.currentActionId = data.payload.id;
      editKonnectorSlice.caseReducers.setActionMode(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      lookupData[findActionIndex].actionMode = ActionMode.Edit;
      state.configurationType = ConfigurationType.Lookup;
      state.actionMode = ActionMode.Edit;
    },
    deleteLookup: (state, data: PayloadAction<any>) => {
      let { lookupData, actionData, cState, ruleConfig, parentId } =
        editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = lookupData.findIndex((action) => action.id === data.payload.id);
      if (lookupData[findActionIndex]?.dataMapping) {
        actionData = actionData.map((action) => {
          const copyOfAction = { ...action };
          // copyOfAction.rawDataMapping = {};
          copyOfAction.shouldReconfigure = true;
          return copyOfAction;
        });
      }
      lookupData.map((lookup, index) => {
        const copyOfLookup = { ...lookup };
        if (lookup.id !== data.payload.id && index < findActionIndex) {
          copyOfLookup.rawDataMapping = {};
          copyOfLookup.shouldReconfigure = true;
        }
        return copyOfLookup;
      });
      lookupData.splice(findActionIndex, 1);
    },
    resetStatus: (state) => {
      state.status = StatusType.idle;
    },
    editSource: (state, data) => {
      state.hasConfigurationSaved = false;
      const findActionIndex = state.data.actions.findIndex((action) => action.id === state.currentActionId);
      if (state.currentActionId && !state.data.actions[findActionIndex]?.dataMapping) {
        state.data.actions = state.data.actions.filter((action) => action.id !== state.currentActionId);
      }
      state.currentActionId = "";
      editKonnectorSlice.caseReducers.setActionMode(state, data);
      state.data.trigger.actionMode = ActionMode.Edit;
      state.actionMode = ActionMode.Edit;
      state.configurationType = ConfigurationType.Source;
    },
    closeSourceConfigration: (state) => {
      state.hasConfigurationSaved = true;
      state.currentActionId = "";
      state.data.trigger.actionMode = ActionMode.View;
      state.actionMode = ActionMode.View;
    },
    editAction: (state, data: PayloadAction<any>) => {
      editKonnectorSlice.caseReducers.setActionMode(state, data);
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      cState.currentActionId = data.payload.id;
      const findActionIndex = actionData.findIndex((action) => action.id === cState.currentActionId);
      state.configurationType = ConfigurationType.Target;
      actionData[findActionIndex].actionMode = ActionMode.Edit;
      state.actionMode = ActionMode.Edit;
    },
    deleteAction: (state, data: PayloadAction<any>) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      let findIndex = actionData.findIndex((action) => action.id === data.payload.id);
      actionData.splice(findIndex, 1);
    },
    saveKonnectorLoading: (state) => {
      state.status = StatusType.loading;
      state.saveButtonStatus = true;
    },
    resetForm: (state) => {
      state.resetFormStatus = StatusType.loading;
    },
    resetFormSuccess: (state) => {
      state.resetFormStatus = StatusType.success;
    },
    saveKonnectorSuccess: (state) => {
      state.status = StatusType.success;
      state.created = true;
      state.data.webhookUrl = { url: "", id: "" };
    },
    displayErrorMessage: (state, data: PayloadAction<string>) => {
      state.status = StatusType.failed;
      state.errorMessage = data.payload;
    },
    manageStateForRepeater: (state, data): any => {
      let lookupData, actionData, currentActionId, ruleConfig, currentActionItem, findActionIndex, cState;
      let parseConfig;
      const parentId = data.payload?.parentId || "source";
      findActionIndex = -1;
      if (parentId === ParentProps.Repeater) {
        lookupData = state.data.repeater.lookups;
        actionData = state.data.repeater.actions;
        ruleConfig = state.data.repeater.ruleEngine;
        parseConfig = state.data.repeater.parseEngine;
        currentActionId = state.data.repeater.currentActionId;
        cState = state.data.repeater;
        if (data.payload?.findItem) {
          findActionIndex = state.data.repeater[data.payload.findItem].findIndex(
            (lookup) => lookup.id === currentActionId
          );
          currentActionItem = state.data.repeater[data.payload.findItem][findActionIndex];
        }
      } else {
        lookupData = state.data.lookups;
        cState = state;
        ruleConfig = state.data.ruleEngine;
        currentActionId = state.currentActionId;
        parseConfig = state.data.parseEngine;
        actionData = state.data.actions;
        if (data.payload?.findItem) {
          findActionIndex = state.data[data.payload.findItem].findIndex((lookup) => lookup.id === currentActionId);
          currentActionItem = state.data[data.payload.findItem][findActionIndex];
        }
      }

      return {
        lookupData,
        actionData,
        currentActionId,
        ruleConfig,
        currentActionItem,
        findActionIndex,
        cState,
        parseConfig,
        parentId,
      };
    },
    editRepeater: (state) => {
      state.data.repeater.actionMode = ActionMode.Edit;
      state.currentActionId = "repeater";
    },
    deleteRepeater: (state) => {
      state.data.repeater = initialState.data.repeater;
    },
    setActionMode: (state, data?): any => {
      let { lookupData, actionData, ruleConfig, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(
        state,
        data
      );
      lookupData = lookupData.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      actionData = actionData.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      ruleConfig = ruleConfig.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      return { lookupData, actionData, ruleConfig, cState };
    },

    closeRepeater: (state) => {
      state.data.repeater.lookups = state.data.repeater.lookups.map((lookup) => {
        const rawDataMapping = lookup.rawDataMapping;
        lookup.actionMode = ActionMode.View;
        if (rawDataMapping) {
          if (!Object.keys(rawDataMapping).length) {
            lookup.shouldReconfigure = true;
          }
        } else {
          return;
        }
        return lookup;
      });
      state.data.repeater.actions = state.data.repeater.actions.map((lookup) => {
        const rawDataMapping = lookup.rawDataMapping;
        lookup.actionMode = ActionMode.View;
        if (rawDataMapping) {
          if (!Object.keys(rawDataMapping).length) {
            lookup.shouldReconfigure = true;
          }
        } else {
          return;
        }
        return lookup;
      });
      state.data.repeater.actionMode = ActionMode.View;
      state.currentActionId = "";
      state.data.repeater.currentActionId = "";
    },
    reset: () => initialState,
    updateCurrentActionId: (state, data) => {
      let { actionData, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const findActionIndex = actionData.findIndex((action) => action.id === cState.currentActionId);
      const rawDataMapping = actionData[findActionIndex]?.rawDataMapping;
      actionData[findActionIndex].actionMode = ActionMode.View;
      if (rawDataMapping) {
        if (!Object.keys(rawDataMapping).length) {
          actionData[findActionIndex].shouldReconfigure = true;
          cState.currentActionId = "";
        }
      } else {
        actionData.splice(findActionIndex, 1);
      }
      cState.currentActionId = "";
      state.actionMode = ActionMode.View;
    },
    updateCurrentLookupId: (state, data) => {
      data.payload.findItem = "lookups";
      let { lookupData, currentActionId, findActionIndex, cState } =
        editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);

      lookupData[findActionIndex].actionMode = ActionMode.View;
      const rawDataMapping = lookupData[findActionIndex].rawDataMapping;

      if (rawDataMapping) {
        if (!Object.keys(rawDataMapping).length) {
          lookupData[findActionIndex].shouldReconfigure = true;
          cState.currentActionId = "";
        }
        cState.currentActionId = "";
      } else {
        lookupData.splice(findActionIndex, 1);
      }
      cState.currentActionId = "";
      state.actionMode = ActionMode.View;
    },
    updateRuleCurrentActionId: (state, data) => {
      let { cState, ruleConfig } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      const rule = ruleConfig.findIndex((action) => action.id === cState.currentActionId);
      const ruleData = ruleConfig[rule].ruleConfigration;
      ruleConfig[rule].actionMode = ActionMode.View;
      if (ruleData) {
        if (Object.keys(ruleData).length === 0) {
          ruleConfig.splice(rule, 1);
          cState.currentActionId = "";
        } else {
          cState.currentActionId = "";
          cState.editRule = false;
        }
      }
    },
    //Rule Engine
    createRule: (state, data) => {
      let { ruleConfig, parseConfig, cState, lookupData, actionData } =
        editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);

      const prevCurrentActionId = cState.currentActionId;
      const configurationType = state.configurationType;
      if (configurationType === "target") {
        let index = actionData.findIndex((action) => action.id === cState.currentActionId);
        actionData.splice(index, 1);
      }
      if (data.payload.parentId === ParentProps.Repeater) {
        state.data.repeater.lookups = state.data.repeater.lookups.filter((lookup) => lookup.id !== prevCurrentActionId);
        lookupData = state.data.repeater.lookups;
      } else {
        state.data.lookups = state.data.lookups.filter((lookup) => lookup.id !== prevCurrentActionId);
        lookupData = state.data.repeater.lookups;
      }

      const ruleEngine = {
        id: uuidv4(),
        ruleConfigration: {},
        actionOrderId: 0,
        appSelectorType: "ruleEngine",
        actionMode: ActionMode.Create,
      };
      ruleConfig.push(ruleEngine);
      cState.currentActionId = ruleEngine.id;
      const findActionIndex = ruleConfig.findIndex((action) => action.id === ruleEngine.id);
      ruleConfig[findActionIndex].actionOrderId = setActionOrderId(
        lookupData,
        ruleConfig,
        parseConfig,
        actionData,
        state.data.repeater
      );
      state.configurationType = ConfigurationType.RuleEngine;
      state.actionMode = ActionMode.Edit;
    },
    addRuleConfig(state, data) {
      const payloadData = JSON.parse(JSON.stringify(data));
      payloadData.payload.findItem = "ruleEngine";
      let { ruleConfig, cState, findActionIndex, actionData } = editKonnectorSlice.caseReducers.manageStateForRepeater(
        state,
        payloadData
      );
      ruleConfig[findActionIndex].ruleConfigration = data.payload.ruleConfig;
      ruleConfig[findActionIndex].actionMode = ActionMode.View;
      actionData = actionData.map((action) => {
        action.actionOrderId += 1;
        return action;
      });
      cState.currentActionId = "";
      state.editRule = false;
      state.actionMode = ActionMode.View;
    },
    deleteRuleConfig(state, data) {
      let { ruleConfig, cState } = editKonnectorSlice.caseReducers.manageStateForRepeater(state, data);
      let findIndex = ruleConfig.findIndex((action) => action.id !== data.payload.id);
      ruleConfig.splice(findIndex, 1);
    },
    editRuleConfig: (state, data: PayloadAction<any>) => {
      let { ruleConfig, cState } = editKonnectorSlice.caseReducers.setActionMode(state, data);
      cState.currentActionId = data.payload.id;
      const findActionIndex = ruleConfig.findIndex((action) => action.id === cState.currentActionId);
      ruleConfig[findActionIndex].actionMode = ActionMode.Edit;
      if (cState.currentActionId && !ruleConfig[findActionIndex].ruleConfigration) {
        ruleConfig = ruleConfig.filter((action) => action.id !== cState.currentActionId);
      }
      state.configurationType = ConfigurationType.RuleEngine;
      cState.editRule = true;
    },
    closeActionWithoutData(state) {
      state.data.actions = state.data.actions.filter((action) => action.id !== state.currentActionId);
      state.currentActionId = "";
    },
    closeLookupWithoutData(state) {
      state.data.lookups = state.data.lookups.filter((lookup) => lookup.id !== state.currentActionId);
      state.currentActionId = "";
    },
    closeSourceData(state) {
      state.currentActionId = "";
    },
    resetWebhook: (state) => {
      state.status = StatusType.loading;
    },

    //Parse Engine

    createParse: (state) => {
      const prevCurrentActionId = state.currentActionId;
      const configurationType = state.configurationType;
      if (configurationType === "target") {
        state.data.actions = state.data.actions.filter((action) => action.id !== prevCurrentActionId);
      }
      state.data.lookups = state.data.lookups.filter((lookup) => lookup.id !== prevCurrentActionId);
      state.data.ruleEngine = state.data.ruleEngine.filter((lookup) => lookup.id !== prevCurrentActionId);
      const parseEngine = {
        id: state.data.parseEngine.length ? state.data.parseEngine.length + 1 : 1,
        parseConfigration: {},
        actionOrderId: 0,
        appSelectorType: AppRole.ParseEngine,
        actionMode: ActionMode.Create,
        outputFields: {
          hasData: true,
          schema: {
            sourceId: "Genric:Parse-engine",
            eventResponse: {
              data: [
                {
                  propName: "Parsed-data",
                  propType: "string",
                  isRequired: false,
                },
              ],
            },
          },
        },
      };

      state.data.parseEngine = [...state.data.parseEngine, parseEngine];
      state.currentActionId = parseEngine.id;
      const findActionIndex = state.data.parseEngine.findIndex((action) => action.id === parseEngine.id);
      state.data.parseEngine[findActionIndex].actionOrderId = setActionOrderId(
        state.data.lookups,
        state.data.ruleEngine,
        state.data.parseEngine,
        state.data.actions,
        state.data.repeater
      );
      state.configurationType = ConfigurationType.ParseEngine;
      state.actionMode = ActionMode.Edit;
    },
    updateParseCurrentActionId: (state) => {
      const parse = state.data.parseEngine.findIndex((action) => action.id === state.currentActionId);
      const parseData = state.data.parseEngine[parse].parseConfigration;
      state.data.parseEngine[parse].actionMode = ActionMode.View;
      if (!Object.keys(parseData).length) {
        state.data.parseEngine = state.data.parseEngine.filter((action) => action.id !== state.currentActionId);
        state.currentActionId = "";
      } else {
        state.currentActionId = "";
        state.editParse = false;
      }
      state.actionMode = ActionMode.View;
    },

    addParseConfig(state, data) {
      const findActionIndex = state.data.parseEngine.findIndex((action) => action.id === state.currentActionId);
      state.data.actions = state.data.actions.map((action) => {
        if (state.data.parseEngine[findActionIndex].parseConfigration) {
          return action;
        } else {
          action.actionOrderId += 1;
          return action;
        }
      });
      state.data.parseEngine[findActionIndex].parseConfigration = data.payload;
      state.data.parseEngine[findActionIndex].actionMode = ActionMode.View;
      state.currentActionId = "";
      state.editParse = false;
      state.data.parseEngine[findActionIndex].shouldReconfigure = false;
      state.actionMode = ActionMode.View;
      const id = state.data.parseEngine[findActionIndex].id;
      const propName = `Parsed-output-${id}`;
      state.data.parseEngine[findActionIndex].outputFields = {
        hasData: true,
        schema: {
          sourceId: `Generic:Parse-engine-${id}`,
          eventResponse: {
            data: [
              {
                propName: propName,
                propType: "string",
                isRequired: false,
              },
            ],
          },
        },
      };
    },
    deleteParseConfig(state, data) {
      state.data.parseEngine = state.data.parseEngine.filter((action) => action.id !== data.payload);
      const findActionIndex = state.data.parseEngine.findIndex((action) => action.id === data.payload);
      if (state.data.parseEngine?.dataMapping) {
        state.data.actions = state.data.actions.map((action) => {
          const copyOfAction = { ...action };
          copyOfAction.shouldReconfigure = true;
          return copyOfAction;
        });
      }
      state.data.parseEngine = state.data.parseEngine
        .map((lookup, index) => {
          const copyOfLookup = { ...lookup };
          if (lookup.id !== data.payload && index > findActionIndex) {
            copyOfLookup.rawDataMapping = {};
            copyOfLookup.shouldReconfigure = true;
          }
          return copyOfLookup;
        })
        .filter((lookup) => lookup.id !== data.payload);
      state.edit = true;
    },
    editParseConfig: (state, data: PayloadAction<string>) => {
      state.data.lookups = state.data.lookups.map((lookup) => {
        lookup.actionMode = ActionMode.View;
        return lookup;
      });
      state.data.actions = state.data.actions.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.ruleEngine = state.data.ruleEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.data.parseEngine = state.data.parseEngine.map((action) => {
        action.actionMode = ActionMode.View;
        return action;
      });
      state.currentActionId = data.payload;
      let findActionIndex = state.data.parseEngine.findIndex((action) => action.id === state.currentActionId);
      state.data.parseEngine[findActionIndex].actionMode = ActionMode.Edit;
      if (state.currentActionId && !state.data.parseEngine[findActionIndex]?.parseConfigration) {
        state.data.parseEngine = state.data.parseEngine.filter((action) => action.id !== state.currentActionId);
      }
      state.configurationType = ConfigurationType.ParseEngine;
      state.editParse = true;
    },
  },
});

export const {
  resetForm,
  resetFormSuccess,
  getKonnectorLoading,
  getKonnectorSuccess,
  getKonnectorFailed,
  updateName,
  addParseConfig,
  deleteParseConfig,
  editParseConfig,
  setGenarateUrlSuccess,
  setGenarateUrlLoading,
  getSampleDataLoading,
  getSampleDataSucess,
  getSampleDataError,
  resetWebhookLoading,
  resetWebhookSuccess,
  deleteWebhook,
  createParse,
  deleteWebhookSucess,
  saveFormSourceConfiguration,
  addFormFields,
  updateSourceAppLoading,
  updateSourceAppSuccess,
  updateKonnectorType,
  updateCurrentLookupId,
  updateSourceAccount,
  updateSourceEvent,
  updateTargetAppLoading,
  updateTargetAppSuccess,
  updateTargetAccount,
  updateTargetEvent,
  closeSourceData,
  closeActionWithoutData,
  closeLookupWithoutData,
  validateAccountLoading,
  validateAccountSuccess,
  validateAccountFailed,
  createAccountLoading,
  createAccountSuccess,
  createAccountFailed,
  fetchInputFieldsLoading,
  fetchActionDynamicInputFieldsSuccess,
  fetchTriggerInputfieldsLoading,
  fetchTriggerInputfieldsSuccess,
  setActionEventDataMapping,
  setSourceEventDataMapping,
  fetchOutputFieldsLoading,
  fetchOutputFieldsSuccess,
  fetchDynamicInputFieldsLoading,
  fetchDynamicInputFieldsSuccess,
  fetchDynamicFieldsLoading,
  fetchDynamicFieldsSuccess,
  createAction,
  saveDataMapping,
  saveSourceConfiguration,
  editSource,
  closeSourceConfigration,
  editAction,
  resetStatus,
  deleteAction,
  updateLookupAccount,
  updateLookupEvent,
  updateLookupAppLoading,
  updateLookupAppSuccess,
  saveLookupDataMapping,
  createLookup,
  createRule,
  editLookup,
  deleteLookup,
  saveLookupConfigurationLoading,
  saveLookupConfigurationSuccess,
  saveKonnectorLoading,
  saveKonnectorSuccess,
  displayErrorMessage,
  resetWebhook,
  reset,
  updateCurrentActionId,
  updateRuleCurrentActionId,
  addRuleConfig,
  deleteRuleConfig,
  editRuleConfig,
  updateParseCurrentActionId,
  closeRepeater,
  editRepeater,
  deleteRepeater,
  createRepeater,
  updateRepeaterSource,
  fetchLookupInputFieldsLoading,
  fetchLookupInputFieldsSuccess,
} = editKonnectorSlice.actions;

export default editKonnectorSlice.reducer;
