import React, { useEffect } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { ActionMode, ParentProps, StatusType } from "state/edit-konnector/types";
import { useParams } from "react-router-dom";

const SelectApp: React.FC<AppsProps> = ({ apps, sourceApp, konnectorSlice, repeater }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const dispatch = useAppDispatch();
  const edit = useAppSelector((state) => state.createNewKonnector.data.actions);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  const parentId = currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source;
  const getApp = (value) => {
    return apps.find((app) => app.id === value);
  };

  const onChangeAppHandler = ({ value }) => {
    if (!value) {
      return dispatch(konnectorSlice.updateTargetAppLoading({ appId: value, id: isRepeaterCurrentActionId, parentId }));
    }
    const app = getApp(value);
    dispatch(
      konnectorSlice.updateTargetAppLoading({
        appId: value,
        id: isRepeaterCurrentActionId,
        iconUrl: app.iconUrl,
        appName: app.appName,
        parentId,
      })
    );
  };
  const getApps = () => {
    return apps.map((app) => ({ value: app.id, label: app.appName }));
  };

  useEffect(() => {
    return () => {
      // reset the status
      if (apiStatus == StatusType.success && sourceApp) {
        dispatch(konnectorSlice.resetStatus());
      }
    };
  }, [sourceApp]);
  const { other, appid, accountId, eventid, name } = useParams<any>();

  useEffect(() => {
    if (other && edit[0].actionMode !== ActionMode.Edit && edit.length == 1) {
      const app = getApp("NVY3oPOqqG");
      dispatch(
        konnectorSlice.updateTargetAppLoading({
          appId: "NVY3oPOqqG",
          id: isRepeaterCurrentActionId,
          iconUrl: app.iconUrl,
          appName: "app-stripe",
          actionType: "actions",
        })
      );
    }
  }, [sourceApp]);

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label  inline-block mb-2 text-gray-700 text-md">Select App</label>
      <Select
        options={getApps()}
        value={getApps().filter(function (option) {
          return option.value === sourceApp;
        })}
        onChange={onChangeAppHandler}
      />
    </div>
  );
};

export default SelectApp;
