import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setModalState, setSavedValues } from "state/Embedded-markteplace/ThemeSettings/slices";
import { useAppSelector } from "state/store";

const TabSwitching: React.FC<any> = ({ closeModalHandler }) => {
  const dispatch = useDispatch()
  const {
    register,
    formState: { errors },
    // handleSubmit,
    reset,
  } = useForm();
  const handleSubmit = (event) => {
    dispatch(setModalState())
    closeModalHandler()
    dispatch(setSavedValues())
  };
  const closeHandler = () => {
    // dispatch(setDeleteModalFalse())
  };
  return (

    <>
      <div className="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          <div className="border-0  shadow-lg relative flex flex-col w-[40rem] bg-white outline-none focus:outline-none rounded-lg">

            <form onSubmit={handleSubmit}>
              <div className="relative p-6 flex-auto">
                <h1>  Your theme has unsaved changes. If you leave now, these modifications will be lost. Are you sure you want to proceed without saving??</h1>
              </div>
              <div className="flex items-center justify-end p-3">
                <button
                  className="text-slate-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={closeHandler}
                >
                  Close
                </button>
                <button
                  className="bg-komp-secondary-color text-white active:bg-komp-secondary-color font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="submit"
                // onClick={saveHandler}
                >
                  <>
                    {"Yes"}
                  </>
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>


  );
};

export default TabSwitching;
