import React, { useEffect, useState } from "react";
import { deactivateAppApi, useApps, useAppsByTypes } from "api/appsApi";
import Spinner from "common/components/Spinner";
import AppList from "./AppList";
import { useCurrentUserApi } from "api/authApi";
import { useAppDispatch, useAppSelector } from "state/store";
import { mutate } from "swr";
import { resetStatus, setReorderApps } from "state/workFlows/slices";
import { getWorkflowAppSlice } from "state/selectors";
import { useWorkflowForApps } from "api/workflowApi";
import AppSelectionModal from "common/components/Modal/AppSelectionModal";
import { StatusType } from "state/auth/types";
import CreateCategoryModal from "common/components/Modal/CreateCategory";
import EditCategoryModal from "common/components/Modal/EditCategoryModal";
import DeleteCategoryModal from "common/components/Modal/DeleteCategoryModal";
import { setItemIndex, setItemIndexStatus, setReorderModal } from "state/auth/slices";
import ReorderModal from "common/components/Modal/ReorderAppModal";
const Apps: React.FC = () => {
  const [currentvalue, setcurrentvalue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [allEmbeddedPageApps, setEmbeddedApps] = useState([]);
  const [activeApps, setActiveApps] = useState([]);
  const [allWorkflows, setWorkflows] = useState(null);
  const currentPage: any = useAppSelector((state) => state.account.currentPage);
  const getKonnectors = useAppSelector(getWorkflowAppSlice);
  const appType = useAppSelector((state) => state.konnectors.appType);
  const staticApp = useAppSelector((state) => state.auth.currentUser.staticApp);
  const isReorderModal = useAppSelector((state) => state.auth.isReorderModal);
  const status = useAppSelector((state) => state.auth.status);
  const isModal = useAppSelector((state) => state.auth.isModal);
  const isEditModal = useAppSelector((state) => state.auth.isEditModal);
  const isDeleteModal = useAppSelector((state) => state.auth.isDeleteModal);
  const appStatus = useAppSelector((state) => state.auth.appStatus);
  const categoryType = useAppSelector((state) => state.auth.categoryType);
  const appStatusReload = useAppSelector((state) => state.workflows.appStatusReload);
  const displayCategoryList = useAppSelector((state) => state.auth.displayCategoryList);
  const { data: workflows, error: error } = useWorkflowForApps();
  let apps;
  const { data, error: appsError } = useAppsByTypes(currentPage, appType);
  useEffect(() => {
    mutate(`/apps/app-type/${appType}?pageNumber=1&pageSize=0`)
  }, [apps])
  if (categoryType == "All Apps") {
    apps = data;
  } else if (categoryType === "Connected Apps") {
    const connectedAppList = data?.data?.items?.filter((datas: any) => {
      return allEmbeddedPageApps.some((item: any, index) => datas.id === item)
    });
    apps = connectedAppList;
  }
  else {
    const categoryTypeList = displayCategoryList?.filter((datas: any) => {
      return data?.data.items?.some((item: any, index) => datas.id === item.id)
    });
    apps = categoryTypeList;
  }
  const { data: embeddedApps, error: embeddedAppsError } = useCurrentUserApi();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (workflows) {
      setWorkflows(workflows.data ? workflows.data : []);
    }
  });
  useEffect(() => {
    if (appStatusReload == "success") {
      mutate(`/users/current_user`);
    }
  }, [appStatusReload]);

  let filterApps;
  let filteredApps = [];
  let appsLists;

  if (embeddedApps && apps?.data) {
    filterApps = apps?.data?.items?.filter((app) => {
      return embeddedApps?.data?.activeApps?.some((item, index) => app.id === item
      );
    });
    if (filterApps && allWorkflows) {
      filterApps.forEach((filterApp) => {
        const hasWorkflows = allWorkflows?.some((workflow) => workflow.workflowAppId === filterApp.id);
        if (hasWorkflows) {
        } else {
          deactivateAppApi({ appId: filterApp.id, categoryType });
          mutate("/users/current_user");
        }
      });
    }
    filteredApps = apps?.data?.items?.filter((app) => {
      return !embeddedApps?.data?.activeApps?.some((item) => app.id === item);
    });
    if (filterApps) {
      if (categoryType === "All Apps") {
        appsLists = [...filterApps, ...filteredApps]
      }
    }
  }
  useEffect(() => {
    if (embeddedApps) {
      setEmbeddedApps(embeddedApps.data.activeApps ? embeddedApps.data.activeApps : []);
    }
  }, [embeddedApps]);

  useEffect(() => {
    if (embeddedApps) {
      setActiveApps(getKonnectors.activeApps ? getKonnectors.activeApps : []);
    }
  }, [embeddedApps]);

  const handleFilter = (event) => {
    const enteredValue = event.target.value;
    setcurrentvalue(enteredValue);
    const newFilter = apps?.data?.items.filter((value) => {
      return value.appName.toLowerCase().includes(enteredValue.toLowerCase());
    });
    const activeappFilter = activeApps.filter((value) => {
      return value.appName.toLowerCase().includes(enteredValue.toLowerCase());
    });
    if (enteredValue === "") {
      setFilteredData([]);
    } else {
      if (newFilter) {
        setFilteredData(newFilter);
      } else {
        setFilteredData(activeappFilter);
      }
      if (categoryType !== "All Apps") {
        const appsArray = apps.filter((value) => {
          return value.appName.toLowerCase().includes(enteredValue.toLowerCase());
        });
        setFilteredData(appsArray)
      }
    }
  };
  useEffect(() => {
    if (getKonnectors.status == "success") {
      mutate(`/users/current_user`);
      dispatch(resetStatus());
    }
  }, [getKonnectors.status]);

  const closeModalHandler = () => {
    // setShowModal(false);
  };

  const confirmModalHandler = () => {
    // setShowModal(false);
  };

  const reorderModal = () => {
    dispatch(setReorderModal(true))
    dispatch(setReorderApps(apps))
  };

  const { data: app, error: erro } = useApps();
  if (appsError) return <p>Failed to load apps</p>;
  if (!apps) return <Spinner />;
  if (!embeddedApps) return <Spinner />;
  console.log(status, "status")
  return (
    <>
      {!staticApp && status === StatusType.idle ? (
        <AppSelectionModal
          text={""}
          visible={true}
          closeModalHandler={closeModalHandler}
          confirmHandler={confirmModalHandler}
        />
      ) : appStatus ? (
        <>
          {isDeleteModal && (
            <DeleteCategoryModal
              text={""}
              visible={true}
              closeModalHandler={closeModalHandler}
              confirmHandler={confirmModalHandler}
            />
          )}
          {isReorderModal && (
            <ReorderModal
              text={""}
              visible={true}
              closeModalHandler={closeModalHandler}
              confirmHandler={confirmModalHandler}
            />
          )}
          {isEditModal && (
            <EditCategoryModal
              text={""}
              visible={true}
              closeModalHandler={closeModalHandler}
              confirmHandler={confirmModalHandler}
            />
          )}
          {isModal && (
            <CreateCategoryModal
              text={""}
              visible={true}
              closeModalHandler={closeModalHandler}
              confirmHandler={confirmModalHandler}
            />
          )}
          <div className="relative top-20 ml-80">
            <section className="flex items-start h-[calc(100vh-5rem)] ">
              <div className="p-5 w-full flex flex-col justify-between">
                <div className="flex justify-end">
                  <input
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5"
                    type="text"
                    placeholder="Search by app name"
                    value={currentvalue}
                    onChange={handleFilter}
                  />
                </div>
                {filteredData?.length !== 0 ? (
                  <><h2 className="inline-block text-xl font-bold">Listed Apps</h2><AppList apps={filteredData} embeddedApps={allEmbeddedPageApps} isTrue={false} /></>
                ) : (
                  <>
                    {activeApps.length === 0 ? (
                      <>
                        {appsLists?.length === 0 ? (
                          <h1>No Connected Apps</h1>
                        ) : (
                          <>
                            {categoryType === "All Apps" ?
                              <>
                                <h2 className="inline-block text-xl font-bold">Listed Apps</h2>
                                <AppList apps={appsLists} embeddedApps={allEmbeddedPageApps} isTrue={false} />
                              </>
                              :
                              <>
                                <div className="block mt-3">
                                  <h2 className="inline-block text-xl font-bold">Listed Apps</h2>
                                  {categoryType === "Connected Apps" ? "" : <button onClick={reorderModal} className="text-white rounded-lg text-xs font-normal float-right cursor-pointer bg-komp-secondary-color p-2 inline-flex items-center  mr-2 mb-2 px-5 py-2.5 text-center">Rearrange</button>}
                                </div>
                                <AppList apps={apps} embeddedApps={allEmbeddedPageApps} isTrue={true} />
                              </>}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {categoryType === "All Apps" ?
                          <>
                            <div className="block mt-3">
                              <h2 className="inline-block text-xl font-bold">Listed Apps</h2>
                            </div>
                            <AppList apps={appsLists} embeddedApps={allEmbeddedPageApps} isTrue={false} />
                          </>
                          :
                          <>
                            <div className="block mt-3">
                              <h2 className="inline-block text-xl font-bold">Listed Apps</h2>
                              {categoryType === "Connected Apps" ? "" : <button onClick={reorderModal} className="text-white rounded-lg text-xs font-normal float-right cursor-pointer bg-komp-secondary-color p-2 inline-flex items-center  mr-2 mb-2 px-5 py-2.5 text-center">Rearrange</button>}
                            </div>
                            <AppList apps={apps} embeddedApps={allEmbeddedPageApps} isTrue={true} />
                          </>}</>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </>
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default Apps;
