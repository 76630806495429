import React, { useState } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Options } from 'common/components/FormFields/types';

const FileField: React.FC<Options | any> = ({
	type,
	pattern,
	isHover,
	isRepeated,
	label,
	name,
	required,
	register,
	handleSubmit,
	placeholder,
	errors,
	isDisabled = false,
}) => {
	return (
		<div className="w-full">
			<label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-500">
				{label ? label : name} {required.value && <span className="text-red-500 ">*</span>}
			</label>
			<input
				type={'file'}
				name={name}
				disabled={isDisabled}
				className={`${isHover ? 'bg-gray-200' : 'bg-gray-50'} ${
					isRepeated ? 'border-red-600' : 'border-gray-300'
				}  border rounded  text-gray-800 text-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5`}
				placeholder={placeholder ? `${placeholder}` : `Enter ${label}`}
				{...register(name, {
					validate: {
						lessThan10MB: (files) => files[0]?.size < 100000 || 'Max 100kb',
						acceptedFormats: (files) =>
							['image/jpeg', 'image/png'].includes(files[0]?.type) || 'Only PNG, JPEG',
					},
				})}
			/>
			<ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
			/>
		</div>
	);
};

export default FileField;
