import { FormFields } from "interfaces/pages/create-new-konnector";
import _ from "lodash";
import { ItemField, SubcategoryField, ControlType } from "state/form-builder/type";
import { FormFieldData, SelectOption } from "state/new-konnector/types";

export const serializefieldSettings = (data, formFields: FormFieldData[], currentFieldId): FormFieldData => {
  let sampleData = data.sample_data;
  if (data.sample_data === "select" || data.sample_data === "dependent") {
    sampleData = data.option_0;
  }
  const fieldData: FormFieldData = {
    sampleData: data.sample_data,
    inputType: data.input_type,
    description: data.description,
    label: data.label,
    fieldName: setFieldName(data.label, formFields, currentFieldId),
    config: {
      requires: data.field_required,
      itemsField: data.items,
      itemDescription: data.item_description,
      textFieldType: data.text_field_type,
      subcategoryField: data.subcategory,
      subcategoryDescription: data.sub_description,
      confirmationField: data.field_confirmation_required,
    },
  };
  return fieldData;
};

const setFieldName = (label: string, formFields: FormFieldData[], currentFieldId: any) => {
  const fields = label.split(" ");
  let name = "";
  if (fields[1]) {
    name = fields.reduce((final, value) => {
      const result = `${final.toLowerCase()}_${value.toLowerCase()}`;
      return result;
    });
  } else {
    name = label.toLocaleLowerCase();
  }

  let form = [];
  formFields.map((field) => {
    if (field.fieldId !== currentFieldId.fieldId) {
      form.push(field.label);
    }
  });
  const flags = _.countBy(form);
  if (flags[label]) {
    return `${name}_${flags[label]}`;
  }
  return name;
};

export const setSampleDataForSerialization = (formFields: FormFieldData[]) => {
  let sampleData = {};
  for (let i in formFields) {
    if (formFields[i].inputType === "dependent") {
      sampleData[formFields[i].config.itemsField] =
        formFields[i].options[0]?.subCategory[0]?.items[0]?.label || "janeDoe";
      sampleData[formFields[i].config.subcategoryField] = formFields[i].options[0]?.subCategory[0]?.label || "janeDoe";
    }
    sampleData[formFields[i].fieldName] = formFields[i].sampleData;
  }
  return sampleData;
};

export const retriveFieldSettings = (data: FormFieldData) => {
  const formfield = {
    field_type: data.inputType,
    sample_data: data.sampleData,
    description: data.description,
    label: data.label,
    field_required: data.config.requires,
    field_confirmation_required: data.config.confirmationField,
    subcategory: data.config.subcategoryField,
    sub_description: data.config.subcategoryDescription,
    item_description: data.config.itemDescription,
    items: data.config.itemsField,
  };
  const options = retirveOptions(data);
  return { ...formfield, ...options };
};

export const setOptions = (state: SelectOption[]) => {
  const optionIndex = state?.length || 0;

  return {
    option: [
      ...state,
      { id: optionIndex, label: "", isRepeated: false, subCategory: [{ id: 0, label: "", items: [] }] },
    ],
    optionIndex,
  };
};

export const serializeOptionData = (data): SelectOption[] => {
  const options: SelectOption[] = [];
  let flag = 0;
  for (let i in data) {
    if (i.includes("option_")) {
      options.push({
        id: flag,
        label: data[i],
        isRepeated: false,
      });
      flag += 1;
    }
  }
  return options;
};

export const retirveOptions = (data: FormFieldData) => {
  const optionData = {};
  data.options?.map((val) => {
    optionData[`option_${val.id}`] = val.label;
  });
  return optionData;
};

export const retirveDependentOptions = (data: FormFieldData) => {
  const optionData = {};
  data.options?.map((val) => {
    optionData[`option_${val.id}`] = val.label;
    val.subCategory?.map((subC) => {
      optionData[`option_${val.id}_subcategory_${subC.id}`] = subC.label;
      subC.items.map((item) => {
        optionData[`option_${val.id}_subcategory_${subC.id}_item_${item.id}`] = item.label;
      });
    });
  });
  return optionData;
};

export const setFormFieldOrder = (data: FormFieldData, formFields: FormFieldData[]): FormFieldData[] => {
  const result = formFields.map((field) => {
    if (field.orderId >= data.orderId) {
      return { ...field, orderId: (field.orderId += 1) };
    } else {
      return field;
    }
  });
  return [...result, data].sort((a, b) => a.orderId - b.orderId);
};

export const deleteFormFieldUtils = (data: FormFieldData, formFields: FormFieldData[]): FormFieldData[] => {
  console.log(data.fieldId);

  const newFormFields = formFields.filter((field) => field.fieldId !== data.fieldId);
  const result = newFormFields.map((field) => {
    if (field.orderId >= data.orderId) {
      return { ...field, orderId: (field.orderId -= 1) };
    } else {
      return field;
    }
  });
  return result.sort((a, b) => a.orderId - b.orderId);
};

export const setOptionsOrder = (data: SelectOption, options: SelectOption[]): SelectOption[] => {
  const newOptions = options.filter((opt) => opt.id !== data.id);
  const result = newOptions.map((opt) => {
    if (opt.id >= data.id) {
      return { ...opt, id: (opt.id -= 1) };
    } else {
      return opt;
    }
  });
  return result.sort((a, b) => a.id - b.id);
};

export const reArrangeOrderId = (source, destination, fieldId, formField: FormFieldData[]): FormFieldData[] => {
  const findForm = formField.find((field) => field.fieldId === fieldId);
  const formData = formField.filter((field) => field.fieldId !== fieldId);
  const result = formData.map((field) => {
    if (field.orderId <= source && field.orderId >= destination) {
      return { ...field, orderId: (field.orderId += 1) };
    } else if (field.orderId >= source && field.orderId <= destination) {
      return { ...field, orderId: (field.orderId -= 1) };
    } else return field;
  });
  findForm.orderId = destination;
  return [...result, findForm].sort((a, b) => a.orderId - b.orderId);
};

export const setSubCategory = (subCategory: SubcategoryField[]) => {
  const subCategoryIndex = subCategory?.length || 0;
  return { subCategory: [...subCategory, { id: subCategoryIndex, label: "", items: [] }], subCategoryIndex };
};

export const setItems = (items: ItemField[]) => {
  const itemIndex = items?.length || 0;
  return { item: [...items, { id: itemIndex, label: "" }], itemIndex };
};

export const setSubcategoryOrder = (state) => {
  return state.map((sub, index) => {
    return { ...sub, id: index };
  });
};

export const setOptionData = (options: SelectOption[], value) => {
  let optValue = 0;
  let index = -1;
  options.map((opt) => {
    if (opt.label === value) {
      optValue = -1;
      index = opt.id;
    }
  });
  return { value, isReapted: optValue, index };
};
