import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState, StatusType, TabState } from "../../ThemeSettings/types";

export const initialState: InitialState = {
    card: {
        background: "#fcfcfc",
        hover: "#F0F0F0",
        text: "#000000",
        heigth: "100",
        width: "600",
        radius: "3",
        bordercolour: "#ededed",
        cardSnippet: "",
        view: "Grid",
        gridsize: "3",
        cardSnippetStatus: false,
    },
    template: {
        background: "#fcfcfc",
        hover: "#F0F0F0",
        text: "#000000",
        heigth: "100",
        width: "600",
        radius: "3",
        templateSnippet: "",
        templateSnippetStatus: false,
        togglecolour: "#000000"
    },
    layout: {
        width: "1440",
        heigth: "780",
    },
    banner: {
        width: "300",
        heigth: "38",
        radius: "0",
        successMsg: "Konnector saved successfully",
        failureMsg: "Error in saving the konnector",
        bordercolour: "#f0f0f0"
    },
    cardState: false,
    logo: {
        url: "",
        size: "10",
        colour: "#000000"
    },
    customization: {
        title: "",
        description: "",
        primary_colour: "#f0f0f0",
        primary_button_colour: "#07006b",
        secondary_button_colour: "#F0F0F0",
        text_colour: "#07006b",
        font: ""
    },
    saveStatus: StatusType.idle,
    tabModalStatus: false

};

const themeSettingSlice = createSlice({
    name: "themeSettings",
    initialState,
    reducers: {
        saveCardData: (state) => {
            state.saveStatus = StatusType.loading
            state.cardState = false
            // state.card.background = data.payload.card.background
            // state.card.hover = data.payload.card.hover
            // state.card.text = data.payload.card.text
            // state.card.heigth = data.payload.card.heigth
            // state.card.width = data.payload.card.width
            // state.card.radius = data.payload.card.radius
            // state.card.cardSnippet = data.payload.card.cardSnippet
            // state.template.background = data.payload.templates.background
            // state.template.hover = data.payload.templates.hover
            // state.template.text = data.payload.templates.text
            // state.template.heigth = data.payload.templates.heigth
            // state.template.width = data.payload.templates.width
            // state.template.radius = data.payload.templates.radius
            // state.template.templateSnippet = data.payload.templates.templateSnippet
            // state.layout.heigth = data.payload.layout.heigth
            // state.layout.width = data.payload.layout.width
        },
        setLogoUrl: (state, data) => {
            state.cardState = true
            state.logo.url = data.payload
        },
        setCardData: (state, data) => {
            // state.card.cardSnippetStatus = false
            state.cardState = true
            state.card[data.payload.type] = data.payload.value
        },
        setLogoData: (state, data) => {
            // state.cardState = true
            state.cardState = true
            state.logo[data.payload.type] = data.payload.value
        },
        setTemplateData: (state, data) => {
            state.cardState = true
            state.template[data.payload.type] = data.payload.value
        },
        setLayoutData: (state, data) => {
            state.cardState = true
            state.layout[data.payload.type] = data.payload.value
        },
        setBannerData: (state, data) => {
            state.cardState = true
            state.banner[data.payload.type] = data.payload.value
        },
        setModalState: (state) => {
            state.cardState = false
        },
        setModalStateTrue: (state) => {
            state.cardState = true
        },
        setSavedValues: (state) => {
            console.log("set saved values")
        },
        setPortaCustomization: (state, data) => {
            state.card = data.payload.card
            state.template = data.payload.template
            state.layout = data.payload.layout
            state.logo = data.payload.logo
            state.customization = data.payload.customization
        },
        setSaveData: (state, data) => {
            state.saveStatus = StatusType.success
            state.card = data.payload.data.card
            state.template = data.payload.data.template
            state.layout = data.payload.data.layout
            state.logo = data.payload.data.logo
        },
        setTab1Data: (state) => {
            state.saveStatus = StatusType.loading
        },
        setCardsnippet: (state, data) => {
            state.card.cardSnippetStatus = data.payload
        },
        setTemplateSnippet: (state, data) => {
            state.template.templateSnippetStatus = data.payload
        },
        resetState: () => initialState,
        resetStatusModal(state) {
            state.tabModalStatus = true
        },
        resetCustomization: (state) => {

            state.customization.title = ""
            state.customization.description = ""
            state.customization.primary_colour = "#f0f0f0"
            state.customization.primary_button_colour = "#07006b"
            state.customization.secondary_button_colour = "#F0F0F0"
            state.customization.text_colour = "#07006b"
            state.customization.font = "Arimo"

        },
        setStatusModal(state) {
            state.tabModalStatus = false
        },
        setCustomization(state, data) {
            state.customization[data.payload.type] = data.payload.value
        }
    },
});

export const {
    saveCardData,
    resetState,
    setLogoUrl,
    setCardData,
    setCardsnippet,
    setLogoData,
    setPortaCustomization,
    setTemplateData,
    setTemplateSnippet,
    setLayoutData,
    setModalState,
    setSavedValues,
    setModalStateTrue,
    setSaveData,
    setBannerData,
    setTab1Data,
    resetStatusModal,
    setStatusModal,
    setCustomization,
    resetCustomization
} = themeSettingSlice.actions;

export default themeSettingSlice.reducer;
