import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";

const TextField: React.FC<Options> = ({
  type,
  pattern,
  isHover,
  isRepeated,
  label,
  name,
  required,
  register,
  placeholder,
  errors,
  isDisabled = false,
}) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };
  return (
    <div>
      {label !== "no_label" &&
        (required ? (
          <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-500">
            {label ? label : name} <span className="text-red-500 ">*</span>
          </label>
        ) : (
          <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            {label ? label : name}
          </label>
        ))}
      <input
        type={type}
        name={name}
        disabled={isDisabled}
        className={`${isHover ? "bg-gray-100" : "bg-gray-50"} ${
          isRepeated ? "border-red-600" : "border-gray-300"
        }  border rounded  text-gray-800 text-sm focus:ring-primary-color focus:border-primary-color block w-full p-2.5`}
        placeholder={placeholder ? `${placeholder}` : `Enter ${label}`}
        {...register(name, getFormConfigs())}
      />
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      />
    </div>
  );
};

export default TextField;
