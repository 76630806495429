import React from "react";

const Sales: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 15.8 15.8"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style></style>
        </defs>
        <g id="darkmode_1" data-name="darkmode 1">
          <g id="apps_sub">
            <path
              className="cls-1"
              d="M5.75,15.52C2.67,14.54,.74,12.48,.14,9.3-.67,5.05,2.16,.97,6.4,.15c4.28-.83,8.45,1.99,9.27,6.27,.05,.28,.08,.57,.13,.85,0,.43,0,.86,0,1.29-.05,.38-.1,.75-.18,1.12-.16,.67-.38,1.31-.72,1.91-.1,.18-.11,.33-.04,.52,.29,.88,.57,1.77,.86,2.65,.03,.08,.06,.15,.08,.23,0,.24,0,.48,0,.72,0,.07-.01,.08-.08,.08-.24,0-.48,0-.72,0-.74-.24-1.49-.49-2.23-.73-.26-.08-.52-.16-.77-.25-.1-.03-.19-.03-.28,.02-.83,.46-1.71,.75-2.65,.89-.17,.02-.33,.05-.5,.07h-1.29c-.51-.06-1.02-.13-1.51-.28Zm5.42-1.83c.07-.04,.14-.08,.21-.12,.2-.12,.41-.14,.63-.07,.66,.22,1.32,.43,1.98,.64,.05,.02,.11,.07,.16,.02,.04-.04,0-.11-.02-.16-.21-.65-.41-1.3-.63-1.95-.08-.25-.06-.47,.08-.69,.48-.78,.78-1.62,.9-2.52,.22-1.73-.14-3.33-1.16-4.76-.86-1.2-1.99-2.06-3.41-2.49-2.13-.64-4.12-.33-5.92,.98-1.38,1-2.25,2.35-2.58,4.02-.37,1.89,0,3.65,1.14,5.21,1.01,1.38,2.36,2.26,4.04,2.61,.34,.07,.69,.12,1.04,.12,1.26,.04,2.44-.23,3.54-.85Z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Sales;
