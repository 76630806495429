import React, { useEffect } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { StatusType } from "state/edit-konnector/types";
import { ParentProps } from "state/new-konnector/types";

const SelectApp: React.FC<AppsProps> = ({ apps, sourceApp, konnectorSlice }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const dispatch = useAppDispatch();
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);

  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;

  const getApp = (value) => {
    return apps.find((app) => app.id === value);
  };

  const onChangeAppHandler = ({ value }) => {
    if (!value) {
      return dispatch(konnectorSlice.updateLookupAppLoading({ appId: value, id: currentActionId }));
    }
    const app = getApp(value);
    dispatch(
      konnectorSlice.updateLookupAppLoading({
        appId: value,
        id: isRepeaterCurrentActionId,
        iconUrl: app.iconUrl,
        appName: app.appName,
        parentId: currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source,
      })
    );
  };
  const getApps = () => {
    return apps.map((app) => ({ value: app.id, label: app.appName }));
  };

  useEffect(() => {
    return () => {
      // reset the status
      if (apiStatus == StatusType.success && sourceApp) {
        dispatch(konnectorSlice.resetStatus());
      }
    };
  }, [sourceApp]);

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select App</label>
      <Select
        options={getApps()}
        value={getApps().filter(function (option) {
          return option.value === sourceApp;
        })}
        onChange={onChangeAppHandler}
      />
    </div>
  );
};

export default SelectApp;
