import { useAllKonnector } from "api/konnectorsApi";
import React from "react";
import { getKonnectorsFilterType } from "state/konnectors/selectors";
import { setAppType, updateFilter } from "state/konnectors/slices";
import { FilterType } from "state/konnectors/types";
import { useAppDispatch, useAppSelector } from "state/store";
import { AppSideBarTypes } from "../types";
import { Icon } from "../Icon";
import AppsSideBar from "./Apps.sidebar";
import KonnectorsSideBar from "./Konnector.sidebar";
import KonnectorPageSideBar from "./KonnectorPage.sidebar";

const SidebarControls: React.FC<any> = ({ type }) => {
  const appType = useAppSelector((state) => state.konnectors.appType);
  const dispatch = useAppDispatch();
  const activeClass = "text-white bg-komp-hover-color hover:bg-[#1f324e]";
  const defaultClass = "text-gray-300";
  const getClasses = (type) => {
    return type === appType ? ` ${activeClass}` : ` ${defaultClass}`;
  };

  const onClickFliterHandler = (type) => {
    dispatch(setAppType(type));
  };

  const renderSidebar = (type) => {
    switch (type) {
      case "KOMP Apps":
        return <AppsSideBar />;
      case "Konnectors":
        return <KonnectorsSideBar />;
      case "New Konnector":
      case "Edit Konnector":
        return <KonnectorPageSideBar type={type} />;
    }
  };
  return <>{renderSidebar(type)}</>;
};

export default SidebarControls;
