import { useForm } from "react-hook-form";
import SelectInputSchema from "../Action/DataMapping/DynamicInputfields";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { WorkFlowState } from "state/workFlows/types";

const InputSchema: React.FC<any> = ({ inputFields, sourceEvent, konnectorSlice }) => {
  const konnectorState: WorkFlowState = useAppSelector((state) => state.workflows.workflowState);
  const hasActionDynamicInput = useAppSelector((state) => state[konnectorState].hasActionDynamicInput);
  const dispatch = useAppDispatch();
  const renderFields = (): JSX.Element[] => {
    return inputFields?.data.map((field, index) => {
      return (
        <SelectInputSchema
          field={field}
          index={index}
          dynamicInputfields={inputFields}
          konnectorSlice={konnectorSlice}
        />
      );
    });
  };

  useEffect(() => {
    if(hasActionDynamicInput){
    dispatch(konnectorSlice.fetchDynamicInputFieldsLoading());
    }
  }, [sourceEvent]);

  return (
    <>
      <div>{inputFields?.data.length > 0 && renderFields()}</div>
    </>
  );
};

export default InputSchema;
