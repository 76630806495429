import AppAccounts from "pages/AppAccounts";
import Apps from "pages/Apps";

import KonnectorPage from "pages/KonnectorPage";
import EventLogs from "pages/EventLogs";
import EventLogSummary from "pages/EventLogSummary";
import KonnectorListPage from "pages/KonnectorList";
import ProfileSettings from "pages/ProfileSettings";
import NewKonnectorPage from "pages/KonnectorPage";
import NewWorkFlowPage from "pages/EmbeddedMarketPlace/WorkFlow";
import workFlowListPage from "pages/EmbeddedMarketPlace/WorkflowList";
import EmbeddedApps from "pages/EmbeddedMarketPlace/Apps";
import EmbeddedBilling from "pages/EmbeddedMarketPlace/Billing";
import EventLogsAdmin from "pages/EmbeddedMarketPlace/EventLogs";
import { RouteProperties } from "./interfaces";
import AdminPage from "pages/EmbeddedMarketPlace/Admin";
import CustomSettings from "pages/EmbeddedMarketPlace/Admin/Customization-tab";
import AdminSettings from "pages/EmbeddedMarketPlace/Admin/Settings-tab";
import Billing from "pages/BillingTest";
import ThemeEditor from "pages/EmbeddedMarketPlace/Admin/ThemeEditor-tab";
const routes: RouteProperties[] = [
  {
    component: ProfileSettings,
    exact: true,
    params: {
      name: "Profile Settings",
    },
    path: "/users/profile",
  },
  {
    component: NewKonnectorPage,
    exact: true,
    params: {
      name: "New Konnector",
    },
    path: "/konnectors/new",
  },
  {
    component: NewKonnectorPage,
    exact: true,
    params: {
      name: "New Konnector",
    },
    path: "/konnectors/new/:other/:appid/:accountid/:eventid/:name",
  },
  // {
  //   component: KonnectorListPage,
  //   exact: true,
  //   params: {
  //     name: "Konnectors",
  //   },
  //   path: "/konnectors",
  // },
  {
    component: KonnectorPage,
    params: {
      name: "Konnector",
    },
    exact: true,
    path: "/konnectors/:id",
  },
  {
    component: EventLogs,
    params: {
      name: "Event Logs",
    },
    exact: true,
    path: "/event-logs",
  },
  {
    component: EventLogSummary,
    params: {
      name: "Event Logs",
    },
    exact: true,
    path: "/event-logs/:id",
  },
  {
    component: Apps,
    params: {
      name: "Apps",
    },
    exact: true,
    path: "/apps",
  },
  {
    component: AppAccounts,
    params: {
      name: "App Accounts",
    },
    exact: true,
    path: "/apps/:id/accounts",
  },
  {
    component: Billing,
    params: {
      name: "Billing & Plans",
    },
    exact: true,
    path: "/payments",
  },

  //Embedded marketplace routes
  {
    component: NewWorkFlowPage,
    exact: true,
    params: {
      name: "New Konnector",
      type: "Embedded-Marketplace",
    },
    path: "/:appId/workflow/new",
  },
  {
    component: NewWorkFlowPage,
    exact: true,
    params: {
      name: "Edit Konnector",
      type: "Embedded-Marketplace",
    },
    path: "/KompApps/templates/:id",
  },
  {
    component: EmbeddedApps,
    exact: true,
    params: {
      name: "KOMP Apps",
      type: "Embedded-Marketplace",
    },
    path: "/KompApps",
  },
  {
    component: workFlowListPage,
    exact: true,
    params: {
      name: "Konnectors",
      type: "Embedded-Marketplace",
    },
    path: "/KompApps/templates",
  },
  {
    component: EmbeddedBilling,
    params: {
      name: "Billing & Plans",
      type: "Embedded-Marketplace",
    },
    exact: true,
    path: "/billing",
  },
  {
    component: AdminPage,
    params: {
      name: "Admin Dashboard",
      type: "Embedded-Marketplace",
    },
    exact: true,
    path: "/Admin/settings",
  },
  // {
  //   component: CustomSettings,
  //   params: {
  //     name: "Theme Editor",
  //     type: "Embedded-Marketplace",
  //   },
  //   exact: true,
  //   path: "/Admin/:id/Customize",
  // },
  {
    component: CustomSettings,
    params: {
      name: "Theme Editor",
      type: "Embedded-Marketplace",
    },
    exact: true,
    path: "/Admin/:id/Customize",
  },
  {
    component: AdminSettings,
    params: {
      name: "Marketplace settings",
      type: "Embedded-Marketplace",
    },
    exact: true,
    path: "/Admin/:id/settings",
  },
  {
    component: EventLogSummary,
    params: {
      name: "Events Summary",
      type: "Embedded-Marketplace",
    },
    exact: true,
    path: "/admin/event-logs/:id",
  },
  {
    component: EventLogsAdmin,
    params: {
      name: "Event logs",
      type: "Embedded-Marketplace",
    },
    exact: true,
    path: "/admin/eventlogs",
  },
];

export default routes;
