import React, { useState } from "react";
import ViewJson from "../../ViewJSon";
import LookupSummary from "./LookupItem";

const LookupDetails: React.FC<any> = ({ lookups, lookupData,dtUpdated }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };
  return (
    <>
      <LookupSummary
        handleActionChange={() => handleActionClick()}
        iconUrl={lookupData?.iconUrl}
        appEventId={lookupData?.appEventId?lookupData.appEventId:lookups.name}
        status={lookups.status}
        outputFields={lookups}
        title={lookupData.appEventId}
        dtUpdated={dtUpdated}
      />
      {state && (
        <ViewJson visible={state} setViewOutputFields={setState} outputFields={lookups} title={lookupData.appEventId?lookupData.appEventId:lookups.name} />
      )}
    </>
  );
};

export default LookupDetails;
