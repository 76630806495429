import ParsingItem from "./ParsingItems";

const Drag: React.FC<any> = ({ register, errors, watchFields, index,handleSubmit }) => {
  return (
    <div className="  border-[1px]  border-solid border-[#dadfe3] mt-4">
      <div className=" flex p-4 w-[100%] space-x-4">
        {/* <DropComponent register={register} errors={errors} index={index} /> */}
        
        <ParsingItem register={register} errors={errors} index={index} handleSubmit={handleSubmit} />
        {/* <DropItem register={register} errors={errors} index={index}/> */}
      </div>
    </div>
  );
};
export default Drag;
