
// import { Label } from "components/ui/label";
import { Input } from "components/ui/input";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLogoData, setLogoUrl } from "state/Embedded-markteplace/ThemeSettings/slices";
import { useAppSelector } from "state/store";

const LogoTab: React.FC<any> = ({ types, colors, onColorChange, onChange, onEditorValueChange }) => {
    const dispatch = useDispatch()
    const logoUrl = useAppSelector((state) => state.themeSettings.logo)
    const [fileUrl, setFileUrl] = useState(null);
    const [localColors, setLocalColors] = useState(colors);
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const dataUrl = reader.result;
                setFileUrl(dataUrl);
                dispatch(setLogoUrl(dataUrl))
            };
            reader.readAsDataURL(file);
        }
    };
    console.log({ fileUrl })
    const handleInputChange = (type, value) => {
        setLocalColors((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: value,
        }));
        dispatch(setLogoData({ type: type.toLowerCase(), value }))
        onColorChange(type, value);
    };
    return (
        <>
            <form>
                <div className="flex flex-row">
                    <div className="flex-1 h-[60vh] bg-transparent space-y-10 overflow-scroll">
                        <div className="grid grid-cols-2 gap-6">

                            <div className="flex flex-col">
                                <span className="font-bold text-xs">Upload Logo</span>

                                {/* <Label htmlFor="picture">Picture</Label> */}
                                {/* <label>Upload Logo</label> */}
                                <Input type="file" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs file:hidden" onChange={handleFileChange} />
                                <span className="text-slate-500 text-xs">This Logo will be used for Loader</span>
                            </div>



                            <div className="flex flex-col w-40"><span className="font-bold text-xs">Loader Size</span>
                                <Input type="number" placeholder="Loader Size" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" value={logoUrl?.size} onChange={(e) => handleInputChange(types[1], e.target.value)} />
                            </div>

                            <div className="flex flex-col"><span className="font-bold text-xs">Spinner color</span>
                                <div className="flex rounded-full mt-5 ">
                                    <span className=" flex rounded-md w-40 border border-gray-300 ">
                                        <input
                                            type="text"
                                            className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                            value={logoUrl?.colour}
                                            onChange={(e) => handleInputChange(types[2], e.target.value)} />
                                        <input
                                            type="color"
                                            className="mt-2 mb-2 ml-4 border w-[30%] h-6 bg-transparent mr-2 "
                                            value={logoUrl?.colour}
                                            onChange={(e) => handleInputChange(types[2], e.target.value)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <span>Preview</span> */}
                    <div className="flex-initial flex h-[70vh] items-start border-dashed border-slate-200 border-2 w-[85vh] -mr-12">
                        <div style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                            {logoUrl?.url == "" ? <>
                                <div className="flex flex-col">
                                    <div className="flex flex-col items-center justify-center">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M7 12V3.85L4.4 6.45L3 5L8 0L13 5L11.6 6.45L9 3.85V12H7ZM2 16C1.45 16 0.979333 15.8043 0.588 15.413C0.196666 15.0217 0.000666667 14.5507 0 14V11H2V14H14V11H16V14C16 14.55 15.8043 15.021 15.413 15.413C15.0217 15.805 14.5507 16.0007 14 16H2Z" fill="black" />
                                        </svg></div>
                                    <span className="text-xs text-slate-600">Upload logo (Loader)</span></div></>
                                : <img src={logoUrl?.url} style={{
                                    position: 'absolute',
                                    width: `${logoUrl?.size * 0.6}%`,
                                    height: `${logoUrl?.size * 0.6}%`,
                                    objectFit: 'contain',
                                }} />}

                        </div>
                    </div>
                </div>

            </form >







        </>
    );
};
export default LogoTab;


