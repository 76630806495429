import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useAppDispatch, useAppSelector } from "state/store";
import { PlanType } from "state/payment/types";
import moment from "moment";
import { useSubscription } from "api/paymentApi";
import Menu from "./Menu";
import { useHistory } from "react-router-dom";
import { setTrialDays } from "state/payment/slices";
import Spinner from "common/components/Spinner";

export const CreateNewMenu: React.FC<any> = ({ user }) => {
  const dispatch = useAppDispatch();
  const [showMenu, setShowMenu] = useState(false);

  const [isEmbedded, setEmbedded] = useState(true);
  const isAccountValidated = useAppSelector((state) => state.account.isValidated);
  const currentUser = user?.data;
  const isEmbeddedUser = useAppSelector((state) => state.auth.currentUser.isEmbeddedUser);

  const { currentPlan, trialDays } = useAppSelector((state) => state.payments);

  const history = useHistory();
  const { data: userSub, error: userSubError } = useSubscription(encodeURIComponent(currentUser.email));

  const handleChange = (e) => {
    setEmbedded(!isEmbeddedUser);
    let { checked } = e.target;
    if (checked) {
      history.push("/KompApps");
    }
  };

  const toggleMenu = (e) => {
    setShowMenu((value) => !value);
  };
  var camelSentence = function camelSentence(str) {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  };

  useEffect(() => {
    if (userSub) {
      dispatch(setTrialDays(userSub?.data?.data?.trialEndDays));
    }
  }, []);

  if (!userSub) {
    return null;
  }
  const trialEndDays = userSub?.data?.data?.trialEndDays;

  return (
    <>
      {
        <div className="border-none mr-3 py-2 text-sm items-center justify-center text-center flex text-gray-400">
          {currentUser?.hasTrial && (
            <>
              <div className="italic ">
                {trialEndDays <= 3 ? (
                  <h1 className="text-red-500 ">
                    <b>
                      Your trial ends in {trialEndDays} {trialEndDays !== 1 ? <>days </> : <>day</>}
                    </b>
                  </h1>
                ) : (
                  <> Your trial ends in {trialEndDays} days </>
                )}
              </div>
              <div className="text-md px-1">&bull;</div>
            </>
          )}
          <div className="">
            <p>Konnectify {currentPlan ? "| " + camelSentence(currentPlan) : ""}</p>
          </div>
        </div>
      }
      <div className="relative mr-4">
        <OutsideClickHandler
          onOutsideClick={() => {
            if (!isAccountValidated) setShowMenu(false);
          }}
        >
          <button
            onClick={toggleMenu}
            className="border-none flex items-center py-2 px-3 rounded-sm text-gray-50 bg-primary-color"
          >
            <span className="pr-1">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
            New
          </button>
          {showMenu && <Menu />}
        </OutsideClickHandler>
      </div>
      {isEmbeddedUser == true ? (
        <label className="inline-flex items-center space-x-4 cursor-pointer">
          <span className="relative">
            <input
              onChange={handleChange}
              // disabled={currentUser.planExpired || (currentActionId === konnectorId && konnStatus === StatusType.loading)}
              checked={!isEmbedded}
              type="checkbox"
              className="hidden peer"
            />
            <div className="absolute bg-white inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto  peer-disabled:bg-gray-500"></div>
            <div className="w-10 h-6 bg-gray-500 peer-checked:bg-green-700 rounded-full shadow-inner peer-disabled:bg-gray-200"></div>
          </span>
        </label>
      ) : (
        ""
      )}
    </>
  );
};
