import React from "react";
import KonnectorItemActive from "./KonnectorItemActive";
import { Droppable } from "react-beautiful-dnd";

const KonnectorListActive: React.FC<any> = ({ data }) => {
  console.log(data, "data true")
  const renderKonnectors = () => {
    return data.map((konnector, index) => <KonnectorItemActive key={konnector.id} konnector={konnector} index={index} />);
  };


  return (

    <Droppable droppableId="droppable">
      {(provided) => (
        <div className="fon h-[80vh]"
          ref={provided.innerRef}
          {...provided.droppableProps}>
          {renderKonnectors()}
          {provided.placeholder}

        </div>)}
    </Droppable>);


};

export default KonnectorListActive;
