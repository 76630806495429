import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { setCategoryType, setDeleteModal, setEditModal, setModal, setReorderCategory, setReorderCategoryArray } from "state/auth/slices";
import { DeleteIcon } from "common/components/Icons/DeleteIcon";
import { EditIcon } from "common/components/Icons/EditIcon";
import { ReorderIcon } from "common/components/Icons/ReorderIcon";
import { DraggableIcon } from "pages/KonnectorPage/components/ConfigureKonnector/FormBuilder/Icons/DragableIcon";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AppsSideBar: React.FC = () => {
  const appType = useAppSelector((state) => state.konnectors.appType);
  const appCategory: any = useAppSelector((state) => state.auth.categoryArray);
  const [icon, setIcon] = useState(false)
  const dispatch = useAppDispatch();
  let [over, setOver] = useState(false);
  const activeClass = "text-white hover:bg-[#1f324e] opacity-100";
  const defaultClass = "text-white opacity-50";
  const getClasses = (type) => {
    return type === appType ? ` ${activeClass}` : ` ${defaultClass}`;
  };

  const editCategory = (all) => {
    dispatch(setEditModal(all))
  };
  const deleteCategory = (all) => {
    dispatch(setDeleteModal(all))
  };

  const onClickFliterHandler = (type) => {
    dispatch(setModal(type))
  };

  const onClickHandler = () => {
    if (icon) { setIcon(false) } else {
      setIcon(true)
    }
  };

  const categoryList = (data) => {
    dispatch(setCategoryType(data))
  };

  const onDragEnd = (result) => {
    const { source, destination } = result
    if (!result.destination) {
      return;
    }
    const updatedItems = [...appCategory]
    const [reorderedItem] = updatedItems.splice(result.source.index, 1);
    updatedItems.splice(result.destination.index, 0, reorderedItem);
    const updatedItemsWithIndex = updatedItems.map((item, index) => ({
      ...item,
      index: index,
    }));
    dispatch(setReorderCategory(updatedItemsWithIndex))
    dispatch(setReorderCategoryArray(updatedItemsWithIndex))
  }

  const renderCategory = () => {
    return appCategory?.map((type: any, index) => <>
      <Draggable key={type.id} draggableId={type.id} index={index}>
        {(provided, snapshot) => (
          <div className="flex space-x-4 group hover:bg-komp-hover-color rounded-lg px-2 cursor-pointer" ref={provided.innerRef}
            {...provided.draggableProps}>
            {icon ? <div className="flex items-center" {...provided.dragHandleProps}>
              <DraggableIcon ></DraggableIcon></div> : ""}
            <span onClick={() => categoryList({ label: type.label, id: type.id, value: type.value })} className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all text-white cursor-pointer"} >{type.label}</span>
            <button onClick={() => editCategory({ label: type.label, id: type.id, value: type.value })} className="cursor-pointer"
              type="button" >
              <EditIcon /></button><button onClick={() => deleteCategory({ label: type.label, id: type.id })} className="cursor-pointer	">
              <DeleteIcon />
            </button>

          </div>
        )}
      </Draggable>
    </>
    );
  };

  return (
    <div className="border-r-2 h-full w-[80%] p-2 flex flex-col bg-komp-secondary-color ">
      <div className="text-2xl font-semibold text-white mt-4 mb-12">Apps</div>
      <div className="flex flex-col space-y-3">
        <div onClick={() => onClickFliterHandler("all")}
          className={
            "flex w-40 space-x-3 group items-center rounded-lg px-2 hover:bg-komp-hover-color cursor-pointer" + getClasses("all")
          }
        >
          <div className="group-hover:opacity-100" onClick={() => onClickFliterHandler("all")}>
            <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 h-5"}
              fill={"#fff"} viewBox="0 0 20 20" >
              <path
                fillRule="evenodd"
                d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <button
            onClick={() => onClickFliterHandler("all")}
            type="button"
            className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all cursor-pointer"}
          >
            {"Add Category"}
          </button>
        </div>
        {/* <div className="-mt-6 ml-51"> */}

        <button onClick={() => onClickHandler()}><ReorderIcon /></button>
        {/* </div> */}
        <div className="items-center rounded-lg px-2 cursor-pointer">
          <div className="flex space-x-3 group hover:bg-komp-hover-color rounded-lg px-2">
            <h1 onClick={() => dispatch(setCategoryType({ label: "All Apps" }))} className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all text-white cursor-pointer"}>All Apps</h1>
          </div>
          <div className="flex space-x-3 group hover:bg-komp-hover-color rounded-lg px-2">
            <h1 onClick={() => dispatch(setCategoryType({ label: "Connected Apps" }))} className={"w-full font-normal transition-all text-sm py-3 text-left hover:transition-all text-white cursor-pointer"}>Connected Apps</h1>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div className="items-center rounded-lg px-2 overflow-y-auto h-3/5"  {...provided.droppableProps}
                ref={provided.innerRef}>
                {renderCategory()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default AppsSideBar;
