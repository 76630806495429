import react from "react";

const WarningIcon: React.FC<any> = ({ onClick }) => {
  return (
    <svg className="w-5 h-5" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 0.5C4.38075 0.5 5.5 1.61925 5.5 3C5.5 4.38075 4.38075 5.5 3 5.5C1.61925 5.5 0.5 4.38075 0.5 3C0.5 1.61925 1.61925 0.5 3 0.5ZM3 3.75C2.9337 3.75 2.87011 3.77634 2.82322 3.82322C2.77634 3.87011 2.75 3.9337 2.75 4C2.75 4.0663 2.77634 4.12989 2.82322 4.17678C2.87011 4.22366 2.9337 4.25 3 4.25C3.0663 4.25 3.12989 4.22366 3.17678 4.17678C3.22366 4.12989 3.25 4.0663 3.25 4C3.25 3.9337 3.22366 3.87011 3.17678 3.82322C3.12989 3.77634 3.0663 3.75 3 3.75ZM3 1.5C2.93877 1.50001 2.87967 1.52249 2.83391 1.56318C2.78815 1.60387 2.75891 1.65994 2.75175 1.72075L2.75 1.75V3.25C2.75007 3.31372 2.77447 3.37501 2.81821 3.42134C2.86195 3.46768 2.92174 3.49556 2.98535 3.49929C3.04896 3.50303 3.11159 3.48233 3.16046 3.44143C3.20932 3.40054 3.24072 3.34252 3.24825 3.27925L3.25 3.25V1.75C3.25 1.6837 3.22366 1.62011 3.17678 1.57322C3.12989 1.52634 3.0663 1.5 3 1.5Z" fill="#FFC178" />
    </svg>

  );
};
export default WarningIcon;
