import React, { useState } from "react";
import WarningIcon from "pages/BillingTest/Icons/WarningIcon";
import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import ViewJson from "pages/EventLogSummary/component/ViewJSon";

const Error: React.FC<any> = ({ text, outputFields, title }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };

  return (
    <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-800/10">
      {text}
    </span>
  );
};

export default Error;
