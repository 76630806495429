import React from "react";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { useAppSelector } from "state/store";

const CustomDatePicker = ({ isVisible, value, onSelect, resetCalendar }) => {
  return isVisible ? (
    <div className="">
      <DateRangePicker
        value={resetCalendar ? null : value}
        onSelect={onSelect}
        isClearable={true}
        format="yyyy-MM-dd HH:mm:ss"
        singleDateRange={true}
        showSelectionPreview={true}
      />
    </div>
  ) : null;
};

export default CustomDatePicker;
