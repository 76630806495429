import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Tick } from "common/components/Icons";
import PlanCalculator from "./PlanCalculator";
import { useAppDispatch, useAppSelector } from "state/store";
import { setShowPaymentModal, setcurrentSelectedPlan } from "state/payment/slices";
import { PlanType } from "state/payment/types";
import * as Icon from "../Icons";
import Badge from "common/components/Badge";
import RenderModal from "../Modal";
import { toast } from "react-toastify";
import PaymentModal from "../Modal/PaymentModal/PaymentModal";
import { BadgeTypes } from "common/components/Badge/types";

const PlanItems: React.FC<any> = ({ planType, user }) => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const [addon, setAddon] = useState(0);

  const currentUser = user;
  const planDetails = useAppSelector((state) => state.payments);
  const currentPlan = useAppSelector((state) => state.payments.currentPlan);
  const isCurrentPlan =
    currentPlan === "trial" && planType.slug === "growth"
      ? true
      : planType.slug === "free" && currentUser.billing?.plan === PlanType.limitReached && !currentUser.hasTrial
      ? true
      : currentPlan === planType.name;
  const showPaymentModal = useAppSelector((state) => state.payments.showPaymetModal);
  const currentSelectedPlan = planDetails.currentSelectedPlan === planType.slug;
  const userStripeId = useAppSelector((state) => state.auth.currentUser.stripeId);
  const planCycle = useAppSelector((state) => state.payments.selectedPlanDetails.cycle);

  const linkToPayment = `/billing/payments/create-checkout-session/${planType.productId[planCycle]}/${userStripeId}?frequency=${planCycle}&addon=${addon}`;

  const onClickHandler = () => {
    if (planType.slug === "free") {
      if (currentUser.isEmailVerified) {
        dispatch(setcurrentSelectedPlan(planType.slug));
        return setShowModal(true);
      } else {
        return toast.error("Verify your email.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    }
    dispatch(setcurrentSelectedPlan(planType.slug));
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };
  const showModalHandler = () => {
    setShowModal(true);
  };
  return (
    <>
      <div
        className={`flex flex-col h-full border-2 w-1/4 ${
          isCurrentPlan ? "bg-gray-200" : "bg-gray-100"
        } transition-all ease-in-out`}
      >
        <div className={`h-1/2 w-full  bg-secondary-color bg-opacity-10 flex flex-col justify-between p-4 rounded`}>
          <div className="">
            <div className="flex mb-5 mt-3 items-center space-x-1 justify-start">
              <p className="text-2xl  font-bold text-primary-color">{planType.name}</p>

              {isCurrentPlan &&
                (currentPlan === "trial" ? (
                  <>
                    <Badge type={BadgeTypes.Info} text={"trial"} />
                  </>
                ) : (
                  <>
                    {<Badge type={"success"} text={currentUser?.billing.cycle} />}
                    <p className="">{"Current"}</p>
                  </>
                ))}
            </div>
            <div className="border-t-[1px]  border-gray-300"></div>
            <p className={`text-sm text-primary-color mt-2 h-10  ${currentSelectedPlan ? "text-center" : "text-left"}`}>
              {planType.description}
            </p>
          </div>
          <div className="flex flex-col mt-10 text-primary-color space-y-2 items-center transition-all ease-in-out">
            <img src={Icon[planType.slug].default} className="h-28" />
            <p className="font-semibold text-4xl transition-all ease-in-out">${planType.amount.yearly}</p>
            <p className="text-sm">{planType.name === "Free" ? "free forever" : "/month billed annually"}</p>
            <div
              className={`w-full h-10 border-[1px] border-gray-300 rounded  items-center p-2 text-primary-color font-semibold flex justify-center`}
            >
              {planType.tasks} tasks/mo
            </div>
          </div>
          <div className="flex flex-col px-5 mt-5 text-primary-color justify-center">
            <p className="font-bold">{planType.name} plan Features</p>
            <div className="flex flex-col mt-3 space-y-4 h-[450px]">
              {planType.features.map((feature) => {
                return (
                  <div className="flex space-x-1 items-center">
                    <Tick />
                    <p className="text-sm">{feature}</p>
                  </div>
                );
              })}
            </div>
          </div>
          {isCurrentPlan && currentPlan !== "trial" ? (
            <button
              disabled
              className="w-full bg-gray-400 mt-4 flex  justify-center items-center rounded h-10 text-white disabled:cursor-not-allowed"
            >
              Current Plan
            </button>
          ) : currentSelectedPlan && planType.slug !== "free" ? (
            <PlanCalculator planType={planType} showModalHandler={showModalHandler} addon={addon} setAddon={setAddon} />
          ) : (
            <button
              onClick={onClickHandler}
              className="w-full mt-4 flex  justify-center items-center rounded h-10 bg-primary-color text-gray-50"
            >
              {currentPlan === "trial" && isCurrentPlan ? "Upgrade" : "Choose Plan"}
            </button>
          )}
        </div>
        {
          <RenderModal
            planType={planType}
            visible={showModal}
            linkToPayment={linkToPayment}
            closeModalHandler={closeModalHandler}
            fromPlan={currentUser.billing?.plan || "growth"}
          />
        }
      </div>
    </>
  );
};

export default PlanItems;
