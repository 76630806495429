import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Tick } from "common/components/Icons";
import { PlanTypes } from "../Constants/planTypes";
import PlanItems from "./PlanItems";
import PaymentModal from "../Modal/PaymentModal/PaymentModal";
import { useAppDispatch, useAppSelector } from "state/store";
import { setShowPaymentModal } from "state/payment/slices";

const Plans: React.FC<any> = ({ user }) => {
  const dispatch = useAppDispatch();
  const showPaymentModal = useAppSelector((state) => state.payments.showPaymetModal);
  const disableCloseModalHandler = useAppSelector((state) => state.payments.disableModalCloseHandler);
  const closePaymentModal = () => {
    dispatch(setShowPaymentModal(false));
  };
  return (
    <>
      <div className="flex mb-4 flex-col px-14 py-2 justify-center bg-gray-100 space-y-2 w-full text-primary-color min-h-[calc(110vh-1rem)]">
        <div className="flex justify-center space-x-5 w-full h-full">
          {PlanTypes.map((planType) => {
            return <PlanItems planType={planType} user={user} />;
          })}
        </div>
      </div>
      <PaymentModal
        visible={showPaymentModal}
        closeModalHandler={closePaymentModal}
        disabled={disableCloseModalHandler}
      />
    </>
  );
};

export default Plans;
