import React from "react";

const Form: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 15.8 16.63"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style></style>
        </defs>
        <g id="darkmode_1" data-name="darkmode 1">
          <g id="apps_sub">
            <g>
              <path
                className="cls-1"
                d="M1.09,8.98c0,1.7,0,3.4,0,5.1,0,.69,.44,1.24,1.08,1.39,.13,.03,.26,.02,.39,.02,1.33,0,2.67,0,4,0,.1,0,.2,0,.3,.03,.23,.07,.38,.27,.39,.52,0,.26-.14,.47-.38,.55-.09,.03-.19,.03-.29,.03-1.35,0-2.71,0-4.06,0-1.45,0-2.53-1.1-2.54-2.59C0,10.66,0,7.28,0,3.9c0-1.31,.84-2.33,2.08-2.54,.44-.08,.89-.02,1.33-.03,.48,0,.37,.07,.55-.38C4.23,.27,4.62,0,5.34,0c1,0,2.01,0,3.01,0,.67,0,1.07,.28,1.32,.91,.2,.51,.06,.4,.6,.42,.47,.01,.94-.05,1.4,.04,1.14,.23,1.96,1.25,1.98,2.45,.01,1.16,0,2.32,0,3.48,0,.44-.34,.72-.71,.6-.24-.08-.38-.3-.38-.61,0-.66,0-1.32,0-1.97,0-.48,0-.97,0-1.45,0-.83-.59-1.42-1.4-1.43-.25,0-.49,.01-.74,0-.18-.01-.24,.05-.26,.23-.06,.52-.36,.85-.83,1.03-.11,.04-.22,.05-.34,.05-1.44,0-2.89,0-4.33,0-.6,0-1.1-.48-1.16-1.1-.01-.15-.06-.21-.21-.2-.35,.02-.7-.04-1.04,.03-.7,.13-1.14,.68-1.14,1.41,0,1.7,0,3.4,0,5.1ZM6.82,2.64c.68,0,1.36,0,2.04,0,.21,0,.27-.09,.19-.29-.13-.35-.27-.69-.4-1.04-.05-.13-.13-.18-.26-.18-1.05,0-2.09,0-3.14,0-.14,0-.22,.06-.27,.19-.11,.31-.23,.61-.35,.92-.13,.35-.1,.4,.26,.4,.64,0,1.29,0,1.93,0Z"
              />
              <path
                className="cls-1"
                d="M15.8,10.89c-.03,.49-.23,.89-.55,1.22-1.27,1.31-2.54,2.62-3.81,3.94-.23,.24-.51,.33-.82,.38-.34,.05-.69,.12-1.03,.17-.67,.1-1.21-.47-1.09-1.16,.07-.41,.13-.82,.2-1.23,.04-.23,.13-.44,.29-.61,1.3-1.34,2.6-2.69,3.9-4.03,.49-.5,1.09-.62,1.73-.42,.62,.19,.99,.65,1.14,1.3,.03,.14,.03,.28,.04,.42Zm-6.09,4.57c.3-.05,.59-.1,.89-.16,.04,0,.08-.05,.12-.08,.85-.88,1.7-1.75,2.55-2.63,.07-.08,.07-.13,0-.2-.22-.23-.45-.45-.66-.69-.1-.11-.16-.08-.25,0-.83,.86-1.66,1.72-2.5,2.58-.04,.04-.09,.09-.1,.15-.05,.3-.1,.6-.15,.9-.01,.09,.01,.13,.1,.12Zm4.39-5.27c-.12,0-.23,.02-.33,.09-.18,.13-.32,.3-.47,.46-.05,.05-.02,.1,.02,.14,.24,.25,.48,.5,.73,.75,.06,.06,.1,.05,.15,0,.12-.13,.25-.25,.36-.39,.17-.21,.2-.45,.09-.7-.11-.24-.3-.34-.55-.35Z"
              />
              <path
                className="cls-1"
                d="M8.13,8.55c-.62,0-1.25,0-1.87,0-.37,0-.59-.21-.6-.54,0-.33,.24-.56,.59-.56,1.26,0,2.52,0,3.79,0,.31,0,.52,.19,.57,.49,.04,.23-.11,.49-.33,.58-.09,.04-.18,.04-.27,.04-.62,0-1.25,0-1.87,0Z"
              />
              <path
                className="cls-1"
                d="M7.47,11.82c-.41,0-.82,0-1.23,0-.28,0-.48-.14-.56-.38-.1-.29,.05-.61,.34-.7,.08-.03,.18-.04,.27-.04,.79,0,1.59,0,2.38,0,.37,0,.61,.21,.62,.53,.02,.35-.21,.59-.57,.6-.41,0-.83,0-1.24,0Z"
              />
              <path
                className="cls-1"
                d="M3.83,11.83c-.12,0-.24,0-.36-.02-.27-.07-.43-.29-.43-.56,0-.27,.18-.47,.45-.52,.23-.04,.47-.04,.7,0,.27,.05,.44,.26,.45,.53,0,.27-.17,.5-.43,.56-.12,.03-.25,.01-.38,.02Z"
              />
              <path
                className="cls-1"
                d="M3.84,8.55c-.09,0-.18,0-.27,0-.3,0-.51-.23-.53-.53-.01-.29,.2-.55,.49-.57,.21-.02,.42-.02,.63,0,.28,.02,.49,.28,.48,.57-.01,.3-.23,.51-.53,.52-.09,0-.18,0-.27,0Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Form;
