import React, { useEffect, useState } from "react";
import { ActionMode, KonnectorType } from "state/new-konnector/types";
import { useAppSelector, useAppDispatch } from "state/store";
import TriggerConfiguration from ".";
import Accounts from "../Accounts";
import Apps from "../Apps";
import { EditIcon } from "./Icons";
import WebhookKonnector from "./WebhookKonnectorTrigger";
import { WebhookIcon } from "common/components/Icons";
import FormFields from "../FormBuilder";
import { WorkFlowState } from "state/workFlows/types";

const ShowConfigurationDetails: React.FC<any> = ({ apps, accounts, konnectorSlice, konnectorState }) => {
  const dispatch = useAppDispatch();
  const [appAccounts, setAppAccounts] = useState(apps);
  const triggerDetails = useAppSelector((state) => state[konnectorState].data.trigger);
  const type: any = useAppSelector((state) => state[konnectorState].data);
  const konnectorType = useAppSelector((state) => {
    if (konnectorState == WorkFlowState.NewWorkFlowState) {
      return state[konnectorState].data.workflowType
    } else {
      return type.konnectorType
    } 
  });
  const hasOutputFields = useAppSelector((state) => state[konnectorState].data.trigger.outputFields.hasData);
  const classNames = {
    primary:
      triggerDetails.actionMode === ActionMode.View
        ? "flex justify-between bg-white items-center p-5 rounded-xl border-[1px] border-gray-300 mt-5 transition-all"
        : "flex justify-between bg-primary-color items-center p-5  rounded-xl mt-5 transition-all",
    text: triggerDetails.actionMode === ActionMode.View ? "text-lg" : "text-lg text-white",
    icon: triggerDetails.actionMode === ActionMode.View ? "h-10 w-10" : "h-10 w-10 text-white",
  };

  const renderIcon = () => {
    switch (konnectorType) {
      case KonnectorType.Form:
        return <img src="https://img.icons8.com/material-outlined/96/707070/application-form.png" alt="" />;
      case undefined || KonnectorType.WebHook:
        return <WebhookIcon url={""} actionMode={triggerDetails.actionMode} />;
      default:
        return <img className="w-full" src={triggerDetails.iconUrl} alt="" />;
    }
  };
  console.log(konnectorType, "konnectorType : ")
  console.log(konnectorType, "konnectorType : ")
  const triggerConfigration = (konnectorType: KonnectorType) => {

    switch (konnectorType) {
      case undefined || KonnectorType.WebHook:
        return (
          <>
            <WebhookKonnector
              apps={appAccounts}
              triggerDetails={triggerDetails}
              konnectorSlice={konnectorSlice}
              konnectorState={konnectorState}
            />
          </>
        );
      case KonnectorType.Form:
        return (
          <FormFields
            apps={appAccounts}
            triggerDetails={triggerDetails}
            konnectorSlice={konnectorSlice}
            konnectorState={konnectorState}
          />
        );
      default:
        return (
          <>
            <TriggerConfiguration
              apps={appAccounts}
              triggerDetails={triggerDetails}
              konnectorSlice={konnectorSlice}
              konnectorState={konnectorState}
              accounts={accounts}
              appAccounts={appAccounts}
            />
          </>
        );
    }
  };

  return (
    <>
      {triggerDetails.actionMode !== ActionMode.Create &&
        !(triggerDetails.actionMode === ActionMode.Edit && konnectorType === KonnectorType.Form) && (
          <div className={classNames.primary}>
            <div className="px-2 w-[70px]">{renderIcon()}</div>
            <div className="mr-auto px-5">
              <p className={classNames.text}>
                {triggerDetails.appName}
                <span className="ml-3 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-gray-300 text-komp-hover-color">
                  Source
                </span>
                <span className="ml-3 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-gray-300 text-komp-hover-color">
                  {triggerDetails.connectionName}
                </span>
                <span className="text-xs font-semibold mr-2 px-2.5 py-0.5 rounded bg-gray-700 text-gray-300">
                  {triggerDetails.eventId}
                </span>
              </p>
            </div>
            {triggerDetails.actionMode === ActionMode.View && (
              <button onClick={() => dispatch(konnectorSlice.editSource())}>
                <EditIcon />
              </button>
            )}
            {triggerDetails.actionMode !== ActionMode.View && (
              <>
                {hasOutputFields && (
                  <button
                    onClick={() => {
                      dispatch(konnectorSlice.closeSourceConfigration());
                    }}
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-sm text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                    data-modal-toggle="popup-modal"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
              </>
            )}
          </div>
        )}
      {triggerDetails.actionMode !== ActionMode.View && <> {triggerConfigration(konnectorType)}</>}
    </>
  );
};

export default ShowConfigurationDetails;
