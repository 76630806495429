import React from "react";
import { AppsProps, ConfigurationType } from "pages/KonnectorPage/types";
import AppListItem from "./AppListItem";
import { WebhookIcon } from "common/components/Icons";
import { useAppDispatch } from "state/store";
import GenericAppList from "./GenericAppList";

const AppList: React.FC<AppsProps> = ({ apps, configurationType, konnectorSlice }) => {
  const dispatch = useAppDispatch();
  const renderApps = () => {
    switch (configurationType) {
      case ConfigurationType.Action:
        return apps.map((app) => (
          <AppListItem key={app.id} configurationType={configurationType} konnectorSlice={konnectorSlice} {...app} />
        ));
      case ConfigurationType.source:
        return (
          <>
            <GenericAppList configurationType={configurationType} konnectorSlice={konnectorSlice} />
            {apps.map((app) => (
              <AppListItem
                key={app.id}
                configurationType={configurationType}
                konnectorSlice={konnectorSlice}
                {...app}
              />
            ))}
          </>
        );
      default:
        return apps.map((app) => (
          <AppListItem key={app.id} configurationType={configurationType} konnectorSlice={konnectorSlice} {...app} />
        ));
    }
  };
  return <div className="grid gap-2 my-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-6">{renderApps()}</div>;
};

export default AppList;
