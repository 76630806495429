import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import TextField from "../FormFields/Text";
import CheckBoxField from "../FormFields/Checkbox";
import SingleSelectField from "../FormFields/SingleSelect";
import Modal from "./";
import { useAppDispatch, useAppSelector } from "state/store";
import { retriveFieldSettings, serializefieldSettings } from "utils/formBuilder.utils";
import {
  fieldList,
  renderDefaultFields,
  renderDependentFieldList,
  renderFieldList,
  renderSelectField,
  requiredField,
} from "../constants/fieldList";
import { saveFieldSettings, setShowModal } from "state/form-builder/slices";
import CreateOptions from "../FormFields/CreateOptions";
import OptionsModal from "./OptionsModal";
import DependentFieldModal from "./DependentFieldModal";
import SubCategoryModal from "./SubCategoryModal";
import ItemsModal from "./ItemsModal";
import { StatusType } from "state/new-konnector/types";

const FieldSettings: React.FC<any> = ({ konenctorSlice, konnectorState, field, showModal }) => {
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm();

  const currentFieldId = useAppSelector((state) => state.formBuilder.currentFormField);
  const formFields = useAppSelector((state) => state.formBuilder.formFields);
  const status = useAppSelector((state) => state.formBuilder.status);
  const getFormField = (state) => {
    const findField = state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId);

    if (findField) {
      const fieldVlaue = retriveFieldSettings(findField);
      return fieldVlaue;
    }
  };
  const currentFormField = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId)
  );
  const formField = useAppSelector(getFormField);

  const onSubmit = (data) => {
    data.input_type = currentFormField.inputType;
    data.sample_data = fieldList.find((field) => field.type === currentFormField.inputType).sampleData;
    const fieldSettings = serializefieldSettings(data, formFields, currentFieldId);
    dispatch(saveFieldSettings(fieldSettings));
    dispatch(setShowModal(false));
    reset();
  };

  const closeModal = () => {
    dispatch(setShowModal({ modal: false, id: field.fieldId }));
  };

  useEffect(() => {
    // reset();
  }, [showModal]);

  useEffect(() => {
    if (currentFieldId) {
      reset(formField);
    }
  }, []);

  const getDropDown = () => {
    return currentFormField?.options?.map((field) => {
      return { label: field.label, value: field.label };
    });
  };

  const getOptionsDropDown = () => {
    return currentFormField.options?.map((field) => {
      return { label: field.label, value: field.label };
    });
  };

  const renderField = (field) => {
    switch (field.type) {
      case "textbox | textarea":
      case "textbox": {
        return (
          <TextField
            {...field}
            label={field.label}
            key={field.name}
            register={register}
            isDisabled={false}
            errors={errors}
            placeholder={field.placeholder}
          />
        );
      }
      case "checkbox": {
        return (
          <CheckBoxField
            {...field}
            label={field.label}
            key={field.name}
            register={register}
            isDisabled={false}
            errors={errors}
          />
        );
      }
      case "singleSelect": {
        return (
          <SingleSelectField
            choices={getDropDown()}
            defaultValue={field.inputType}
            {...field}
            label={field.label}
            name={field.name}
            register={register}
            errors={errors}
          />
        );
      }
      case "button": {
        return (
          <CreateOptions
            choices={getOptionsDropDown()}
            defaultValue={field.inputType}
            {...field}
            label={field.label}
            name={field.name}
            register={register}
            errors={errors}
            currentField={currentFormField}
          />
        );
      }
      default:
        // return "<p>Field not found</p>";
        return <p></p>;
    }
  };

  const renderForm = () => {
    let fields: any;
    switch (field.inputType) {
      case "text":
        fields = [...renderFieldList];
        break;
      case "select":
      case "radio":
        fields = [...renderSelectField];
        break;
      case "dependent":
        fields = [...renderDependentFieldList];
        break;
      default:
        fields = [...renderDefaultFields];
        break;
    }

    return fields.map((field) => renderField(field));
  };

  return (
    <>
      <Modal visible={showModal} closeModalHandler={closeModal}>
        <form className="px-3 py-2 pb-2 space-y-1 lg:px-8 sm:pb-6 xl:pb-8" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col space-y-2">
            <div className={`grid ${field.inputType === "dependent" ? "grid-cols-3" : "grid-cols-2"} gap-2`}>
              {renderForm()}
            </div>
            {renderField(requiredField)}
            {(field.inputType === "select" || field.inputType === "radio") && (
              <div className="w-2/3">
                <OptionsModal register={register} errors={errors} reset={reset} />
              </div>
            )}
            {field.inputType === "dependent" && (
              <div className="grid grid-cols-3 gap-2">
                <div className="">
                  <DependentFieldModal register={register} errors={errors} reset={reset} />
                </div>
                {currentFieldId.optionId !== -1 && (
                  <div className="">
                    <SubCategoryModal register={register} errors={errors} reset={reset} />
                  </div>
                )}
                {currentFieldId.subCategoryId !== -1 &&
                  currentFieldId.optionId !== -1 &&
                  currentFormField.options[currentFieldId.optionId]?.subCategory[currentFieldId.subCategoryId]?.items
                    .length > 0 && (
                    <div className="">
                      <ItemsModal register={register} errors={errors} reset={reset} />
                    </div>
                  )}
              </div>
            )}
          </div>
          <div className="flex justify-end">
            <button
              disabled={status === StatusType.failed}
              type="submit"
              className="w-24 text-primary-color font-medium rounded text-sm px-3 py-2 text-center bg-gray-100 border-2 border-gray-300 disabled:opacity-50"
            >
              Save field
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default FieldSettings;
