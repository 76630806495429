import React, { useEffect, useState } from "react";
import { AccountsProps } from "pages/KonnectorPage/types";
import AccountList from "./AccountList";
import { useAccounts } from "api/accountsApi";

const Apps: React.FC<AccountsProps> = ({ configurationType, konnectorSlice }) => {
  const [allAccounts, setAccounts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const { data: account } = useAccounts(pageNumber);
  useEffect(() => {
    if (account) {
      setAccounts((prevState) => {
        if (prevState.length) {
          return [...prevState, ...account.data.items];
        }
        return account.data.items;
      });
    }
  }, [account]);

  return (
    <div className="mb-3">
      {/* <h4 className="text-md font-semibold text-primary-color">My App Accounts</h4>
      <AccountList
        accounts={allAccounts}
        configurationType={configurationType}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        konnectorSlice={konnectorSlice}
      /> */}
    </div>
  );
};

export default Apps;
