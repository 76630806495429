import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useAppSelector } from "state/store";
import { PlanType } from "state/payment/types";
import moment from "moment";
import { useSubscription } from "api/paymentApi";
import Menu from "./Menu";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Komp from "../../../icons/Komp";
import KompMenu from "./Menu/KompMenu";
import { WorkFlowState } from "state/workFlows/types";
import NewKonnectorModal from "common/components/Modal/NewKonnectorModal";

interface HeaderProps {
  name: string;
}

export const CreateNewMenu: React.FC<HeaderProps> = ({ name }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id: any = query.get("appId");
  const { appId } = useParams<any>();
  const [showMenu, setShowMenu] = useState(false);
  const [showKomp, setShowKomp] = useState(false);
  const [isEmbedded, setEmbedded] = useState(true);
  const [showModal, setModal] = useState(false);
  const isAccountValidated = useAppSelector((state) => state.account.isValidated);
  const workflowState = useAppSelector((state) => state.workflows.appId);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const appIds = useAppSelector((state) => state.editWorkFlow.data.trigger.appId);
  const currentPlan = useAppSelector((state) => state.payments.currentPlan);
  const history = useHistory();
  const url = window.location.href;
  const { data: userSub, error: userSubError } = useSubscription(currentUser.email);
  const baseUrl = window.location.origin;
  if (!userSub) {
    return null;
  }
  const trialEndDays = userSub.data.data.trialEndDays;

  const handleChange = (e) => {
    setEmbedded(!isEmbedded);
    let { checked } = e.target;
    if (!checked) {
      history.push("/");
    }
  };

  const toggleMenu = (e) => {
    // if (workflowState === WorkFlowState.EditWorkFlowState) {
    const id = workflowState;
    //   console.log(id, "createstateeditstate")
    history.push(`/${id}/workflow/new`);
    // } else {
    //   console.log(id, "createstate")
    //   history.push(`/${id}/workflow/new`);
    // }
    setShowMenu((value) => !value);
  };
  const toggleKomp = (e) => {
    setShowKomp((value) => !value);
  };
  var camelSentence = function camelSentence(str) {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  };

  const closeModalHandler = () => {
    setModal(false);
  };

  return (
    <>
      {showModal && <NewKonnectorModal text={""} visible={showModal} closeModalHandler={closeModalHandler} />}
      <OutsideClickHandler
        onOutsideClick={() => {
          if (!isAccountValidated) setShowMenu(false);
          setShowKomp(false);
        }}
      >
        <div className="flex">
          <div className="relative mr-4">
            {window.location.href == baseUrl + "/KompApps" ||
              window.location.href == baseUrl + `/${appId}/workflow/new` || name === "Event logs" || name === "Admin Dashboard" ? (
              ""
            ) : (
              <>


                <button
                  onClick={toggleMenu}
                  className="flex px-4 py-2 w-14rem bg-komp-secondary-color text-[14px] font-medium text-white transition-all duration-400 rounded hover:bg-komp-hover-color"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className={"w-5 h-5"}
                    fill={"#fff"} viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                      clipRule="evenodd" />
                  </svg>
                  New Konnector Template
                </button>
              </>
            )}
          </div>
          {/* <button
            onClick={toggleKomp}
            className="w-10 h-10 rounded-full flex items-center justify-center bg-gray-400 mr-2"
          >
            <Komp />
          </button> */}
        </div>
        {/* {showKomp && <KompMenu isEmbedded={isEmbedded} handleChange={handleChange} />} */}
      </OutsideClickHandler>
    </>
  );
};
