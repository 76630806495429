import React from "react";
import { useAppSelector } from "state/store";
import { useApps } from "api/appsApi";
import { useDispatch } from "react-redux";
import { setItemIndex, setItemIndexStatus, setReorderModal } from "state/auth/slices";
import Spinner from "common/components/Spinner";
import { useForm } from "react-hook-form";
import AppItem from "./AppItem";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { setReorderAppsSuccess } from "state/workFlows/slices";

const ReorderModal: React.FC<any> = ({ visible, closeModalHandler, text }) => {
    const appsArray: any = useAppSelector((state) => state.workflows.reorderApps);
    const dispatch = useDispatch();
    const categoryType = useAppSelector((state) => state.auth.categoryType);
    const {
        register,
        formState: { errors },
        // handleSubmit,
        reset,
    } = useForm();
    const { data: apps, error: appsError } = useApps();
    const closeHandler = () => {
        dispatch(setReorderModal(false))
    };

    const ondragend = (result) => {
        const { source, destination } = result
        if (!result.destination) {
            return;
        }
        const updatedItems = [...appsArray]
        const [reorderedItem] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, reorderedItem);
        const updatedItemsWithIndex = updatedItems.map((item, index) => ({
            ...item,
            index: index,
        }));
        dispatch(setReorderAppsSuccess(updatedItemsWithIndex))
        dispatch(setItemIndexStatus(updatedItemsWithIndex))
        dispatch(setItemIndex({ categoryType: categoryType, updatedItemsWithIndex }));

    };

    if (!apps) return <Spinner />;

    return (
        <>
            <div className="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                    <div className="border-0  shadow-lg relative flex flex-col w-[55rem] h-[50rem] bg-white outline-none focus:outline-none">
                        <div className="flex items-start justify-between p-5  rounded-t">
                            <h6 className="text-lg font-semibold">Reorder Apps</h6>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                onClick={closeHandler}>
                                <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                    <path stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="relative overflow-auto ml-10 h-[80%]">
                            <DragDropContext onDragEnd={ondragend}>
                                <section className="flex  items-start">
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <div className={"flex  flex-col gap-3"}
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}>
                                                {appsArray.map((item, index) => {
                                                    return <AppItem app={item} index={index} />
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </section>
                            </DragDropContext>
                        </div>
                    </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>

    );
};

export default ReorderModal;
