import React from "react";

const Actions: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 63.21 63.24"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style>
            {`
      .tigger-1 {
        fill: #fff;
      }

      .trigger-2 {
        opacity: .85;
      }`}
          </style>
        </defs>
        <g id="Layer_13" data-name="Layer 13">
          <g className="trigger-2">
            <path
              className="tigger-1"
              d="M31.44,56.74c12.37,.11,23.05-9.05,24.96-21.13,.2-1.27,.26-2.54,.31-3.82,.1-2.58,2.19-4.03,4.52-3.16,1.35,.51,2.04,1.66,1.99,3.44-.19,6.71-2.16,12.84-6.19,18.23-5.33,7.13-12.41,11.49-21.24,12.63-10.35,1.34-19.31-1.83-26.7-9.21C3.81,48.46,.78,42.01,.14,34.59-.72,24.75,2.43,16.25,9.4,9.24,14.7,3.91,21.18,.96,28.64,.18c1.21-.13,2.44-.31,3.68-.06,1.53,.31,2.54,1.57,2.52,3.19-.03,1.62-1,2.86-2.52,3.11-1.58,.26-3.18,.17-4.77,.42-10.51,1.65-19.12,10.25-20.76,20.81-1.91,12.26,5.21,23.95,16.98,27.87,2.54,.84,5.15,1.29,7.66,1.22Z"
            />
            <path
              className="tigger-1"
              d="M47.12,25.14c-1.26,0-2.51,.02-3.77,0-.51-.01-.88,.16-1.23,.53-2.52,2.63-5.04,5.26-7.59,7.86-.93,.95-2.06,1.52-3.45,1.3-1.33-.21-2.15-1.05-2.57-2.27-.42-1.21-.05-2.29,.82-3.18,1.81-1.84,3.65-3.66,5.48-5.49,.92-.92,1.83-1.86,2.77-2.76,.37-.35,.51-.74,.51-1.24-.02-2.49,.01-4.97-.02-7.46-.02-1.28,.39-2.31,1.32-3.23,2.73-2.66,5.4-5.39,8.11-8.08,1.39-1.38,2.9-1.46,4.43-.26,.74,.58,1.13,1.35,1.13,2.3,0,1.87,0,3.74,0,5.61,0,1,0,.99,1.04,1.01,2.09,.04,4.18-.11,6.27,.08,1.27,.12,2.1,.78,2.56,2.01,.46,1.25,.29,2.35-.6,3.28-2.8,2.94-5.61,5.87-8.46,8.77-.79,.81-1.81,1.24-3,1.22-1.25-.02-2.51,0-3.77,0Zm-.54-13.82l-.19-.1c-.52,.55-1.04,1.1-1.56,1.65-.19,.19-.21,.43-.21,.68,0,1.47,0,2.95,0,4.42,0,.48,.2,.65,.66,.64,1.13-.02,2.27-.1,3.39,.02,1.59,.17,2.11-1.08,3.14-2.01-1.66-.06-3.2,.23-4.35-1.03-1.15-1.25-.71-2.82-.87-4.28Z"
            />
            <path
              className="tigger-1"
              d="M31.6,42.52c5.87,.04,10.7-4.65,10.91-10.56,.07-2.04,.94-3.18,2.63-3.47,2.31-.4,4,1.09,3.89,3.51-.27,5.83-2.8,10.48-7.52,13.9-2.92,2.11-6.23,3.23-9.82,3.17-6.62-.11-11.77-3.07-15.12-8.76-6.44-10.94,.78-24,12.37-25.85,1.11-.18,2.23-.36,3.37-.15,1.54,.29,2.55,1.54,2.53,3.17-.01,1.65-1.01,2.84-2.57,3.15-.85,.17-1.71,.1-2.57,.24-5.07,.88-9.27,5.69-9.04,11.25,.21,5.13,4.4,9.76,9.61,10.39,.44,.05,.88,0,1.33,0Z"
            />
          </g>
        </g>
      </svg>
    </>
  );
};

export default Actions;
