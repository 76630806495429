import { useForm, Controller, SubmitHandler } from "react-hook-form";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import React, { useEffect, useReducer, useState } from "react";
import { signInApi } from "api/authApi";
import { Link, useHistory, useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import { StatusType } from "state/auth/types";
import { Eye } from "common/containers/Layout/components/icons";
import HeaderIndex from "common/containers/Layout/components/Header/MainHeader";
import HomePageContent from "common/containers/Layout/components/Header/MainHeader/HomePageContent";
import TickMarkIcon from "pages/BillingTest/Icons/TickMarkIcon";
import SSOLogin from "common/containers/Layout/components/Header/MainHeader/SsoLogin";
import { Icon } from "common/components/Icons/Xicon";
import { useDispatch } from "react-redux";
import { setUserData } from "state/auth/slices";
import { useAppSelector } from "state/store";
import { setTenantId } from "api/libs/fetch";

type Inputs = {
  email: string;
  phoneNumber: string;
  organisation: string;
  password: string;
};
const Signup: React.FC = () => {
  const {
    register,
    formState: { errors },
    control,
    watch,
    handleSubmit,
  } = useForm<Inputs>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated);
  const externalLogin = useAppSelector((state) => state.auth.externalLogin);

  const [showIError, setShowIError] = useState(false);

  const [sendResponse, setSendResponse] = useState({
    status: 302,
    message: "",
    loading: StatusType.idle,
  });
  const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
    loading: true,
    formState: {
      submitted: false,
      form: {},
    },
  });

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setState({
      formState: { ...state.formState, submitted: true, form: data },
    });
  };

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  let loginRedirect = externalLogin ? "/external_login" : "/login";

  const signInUser = async () => {
    try {
      setSendResponse({ ...sendResponse, loading: StatusType.loading });
      const response = await signInApi(state.formState.form);
      if (response.data.status === 200) {
        setSendResponse({ ...sendResponse, status: 200, message: "User signed in successfully" });
        setTenantId(response.data.data.user.tenantId);
        dispatch(setUserData(response.data.data.user));
      } else {
        setSendResponse({
          status: response.data.status,
          message: response.data.error.errorMessage,
          loading: StatusType.idle,
        });
      }
    } catch (err) {
      if (err.response.status === 400) {
        setSendResponse({ status: 400, message: "This account already exists", loading: StatusType.idle });
      }
    }
    setState({
      formState: { submitted: false },
    });
  };

  const watchFields = watch("password");
  const emailWatchFields = watch("email");
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  useEffect(() => {
    setSendResponse({
      status: 302,
      message: "",
      loading: StatusType.idle,
    });
    if (emailWatchFields) {
      if (emailRegex.test(emailWatchFields)) {
        setShowIError(false);
      } else {
        setShowIError(true);
      }
    }
  }, [emailWatchFields]);

  const onIconClickHandler = () => {
    setIsRevealPwd((prevState) => !prevState);
  };

  useEffect(() => {
    if (state.formState.submitted) {
      signInUser();
    }
  }, [state.formState.submitted]);
  useEffect(() => {
    if (isAuthenticated) {
      history.push("/konnectors");
    }
  }, [isAuthenticated]);
  return (
    <>
      {!externalLogin && <HeaderIndex />}
      <div className=" w-full h-[calc(100vh-137px)] primary-gradient p-8 ">
        <div className={`flex w-[60%] mx-auto ${externalLogin ? "mt-24" : ""} bg-[#EDF6FF] shadow-2xl rounded-xl `}>
          <div className="flex flex-row w-[50%] bg-white rounded-2xl shadow-xl ">
            <form className="space-y-4 p-10 mb-6 w-full" onSubmit={handleSubmit(onSubmit)}>
              <h2 className=" font-raleway text-[20px] mt-6 font-bold mb-5 text-center">User Sign Up</h2>
              <div>
                <label htmlFor={"Email"} className="text-[12px] mb-2 block font-poppins text-[#000000] ">
                  {"Email"}
                </label>
                <div className="z-10 relative">
                  <div className="right-1 mt-2 absolute">
                    {showIError ? <Icon /> : <>{!showIError && emailWatchFields && <TickMarkIcon />}</>}
                  </div>
                  <input
                    type={"email"}
                    value={emailWatchFields}
                    className="bg-gray-50 border border-gray-300 text-[12px] font-poppins text-[#000000] rounded-md focus:ring-[#09234C] focus:border-[#09234C]  w-full p-2.5 "
                    placeholder={"Enter your email address"}
                    {...register("email", {
                      required: true,
                      pattern: emailRegex,
                    })}
                  />
                  {errors["email"] && <span className="text-[12px] text-red-500 font-poppins">Invalid email</span>}
                  {!(sendResponse.status === 200) && !errors["email"] && (
                    <span className="text-xs text-red-500">{sendResponse.message}</span>
                  )}
                </div>
                <label htmlFor={"Password"} className="text-[12px] mt-2 mb-2 block font-poppins text-[#000000]  ">
                  {"Password"}
                </label>

                <div className="z-10 relative">
                  <div className=" right-1 mt-3 absolute">
                    <Eye onIconClickHandler={onIconClickHandler} isRevealPwd={isRevealPwd} />
                  </div>
                  <input
                    type={isRevealPwd ? "text" : "password"}
                    value={watchFields}
                    className="bg-gray-50 border border-gray-300 font-poppins  text-[12px] rounded-md focus:ring-[#09234C] focus:border-[#09234C] w-full p-2.5 "
                    placeholder={"Enter your password"}
                    {...register("password", {
                      required: true,
                      minLength: { value: 8, message: "Minimum 8 characters are allowed" },
                      maxLength: { value: 50, message: "Reached maximum characters" },
                    })}
                  />
                </div>

                {errors["password"] &&
                  (errors["password"].message ? (
                    <span className="text-[12px] text-red-500 font-poppins">{errors["password"].message}</span>
                  ) : (
                    <span className="text-[12px] text-red-500 font-poppins">Password required</span>
                  ))}
                <div className="flex w-full space-x-1 mt-2 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 opacity-100"
                    viewBox="0 0 20 20"
                    fill="#0C4D94"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span className=" font-poppins text-[9px] mt-1 text-[#333333]">
                    Must contain atleast 8 characters.
                  </span>
                </div>
              </div>
              <div className="flex flex-row space-x-4 font-poppins">
                <div className=" w-3/6">
                  <label htmlFor={"phone Number"} className=" block text-[12px]   mb-2 font-poppins text-[#000000] ">
                    {"Phone Number"}
                  </label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: false,
                      validate: (value) => {
                        if (!value) {
                          return true;
                        }
                        return isValidPhoneNumber(value);
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <PhoneInput
                        className="bg-gray-50 border border-gray-300 text-gray-900 font-light text-sm rounded-md   h-10 p-1.5 placeholder-gray-400"
                        value={value}
                        onChange={onChange}
                        defaultCountry="IN"
                      />
                    )}
                  />
                  {errors["phoneNumber"] && <span className="text-xs text-red-500">Invalid Number</span>}
                </div>
                <div className="w-3/5">
                  <label htmlFor={"Organisation"} className=" block mb-2  text-[12px]  font-poppins text-[#000000] ">
                    {"Organisation Name"}
                  </label>
                  <input
                    type={"textbox"}
                    className="bg-gray-50 border border-gray-300 text-[12px]  font-poppins text-[#000000] rounded-md focus:ring-[#09234C] focus:border-[#09234C] w-full p-2.5"
                    placeholder={"Enter your organisation"}
                    {...register("organisation", { required: true })}
                  />
                  {errors["organisation"] && <span className="text-xs text-red-500">Organisation required</span>}
                </div>
              </div>
              <button
                type="submit"
                className="w-full text-white  bg-[#0C4E98] hover:transition-all duration-400 button-gradient  shadow-md font-raleway  text-[18px] font-medium rounded-md px-5 py-2.5 text-center"
              >
                {sendResponse.loading === StatusType.loading ? (
                  <>
                    <svg
                      role="status"
                      className="inline mr-2 w-4 h-4 text-gray-200 animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="#09234C"
                      />
                    </svg>
                    Loading...
                  </>
                ) : (
                  <>Get Started for Free</>
                )}
              </button>
              {!externalLogin && (
                <>
                  <div className="text-md mt-2 text-center text-[10px] font-normal font-poppins flex items-center justify-center ">
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                      </svg>
                    </span>
                    <span>Or Sign In with</span>
                    <span>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15" />
                      </svg>
                    </span>
                  </div>
                  <SSOLogin />
                </>
              )}
              <h2 className="text-[10px] font-poppins ">
                Already have an account?
                <Link to={loginRedirect} className=" text-[#074280] text-[10px] ml-2 font-semibold">
                  Log In here
                </Link>
              </h2>
            </form>
          </div>
          {sendResponse.status === 200 && (
            <p className="text-sm text-center font-poppins mt-3 text-green-500">{sendResponse.message}</p>
          )}
          <HomePageContent />
        </div>
      </div>
    </>
  );
};

export default Signup;
