import { AxiosResponse } from "axios";
import { takeEvery, put, call, all, select } from "redux-saga/effects";
import { validateAccountApi, createAccountApi } from "api/accountsApi";
import { AppRootObject } from "../../interfaces/modules/apps/index";
import {
  fetchOutputFieldsApi,
  fetchDynamicInputFieldsApi,
  fetchApp,
  fetchOutputFieldsSchema,
  getAppsApi,
  getAppsFieldsApi,
  getTriggerInputFieldApi,
  getActionDynamicInputFieldApi,
  getAppsFieldsApiTest,
} from "api/appsApi";
import {
  updateKonnectorApi,
  getKonnectorApi,
  getWebhookData,
  resetWebhookDataApi,
  generateFormSampleDataApi,
  resetEditFormData,
} from "api/konnectorsApi";
import {
  AppRole,
  CreateAccountSuccessResponse,
  DynamicFieldsSchemaType,
  DynamicInputFields,
  InputFields,
  KonnectifyApiResponse,
  KonnectorAppState,
  KonnectorType,
  UpdateSourceAppPayload,
  UpdateTargetAppPayload,
  ValidateAccountSuccessResponse,
} from "./types";
import { getTriggerDetails, getTargetDetails, getLookupDetails, getEditKonnectorDetails } from "./selectors";
import {
  getKonnectorLoading,
  getKonnectorSuccess,
  validateAccountLoading,
  validateAccountSuccess,
  validateAccountFailed,
  createAccountLoading,
  createAccountSuccess,
  createAccountFailed,
  fetchOutputFieldsLoading,
  fetchOutputFieldsSuccess,
  fetchDynamicInputFieldsLoading,
  fetchLookupInputFieldsLoading,
  updateSourceAppLoading,
  updateSourceAppSuccess,
  updateTargetAppLoading,
  updateTargetAppSuccess,
  fetchDynamicInputFieldsSuccess,
  saveKonnectorLoading,
  saveKonnectorSuccess,
  updateLookupAppLoading,
  updateLookupAppSuccess,
  saveLookupConfigurationSuccess,
  saveLookupConfigurationLoading,
  displayErrorMessage,
  getSampleDataLoading,
  getSampleDataError,
  getSampleDataSucess,
  resetWebhookLoading,
  resetWebhookSuccess,
  fetchDynamicFieldsLoading,
  fetchDynamicFieldsSuccess,
  saveFormSourceConfiguration,
} from "./actions";
import {
  deserializeKonnectorToEditKonnectorState,
  deserializeRepeaterToEditKonnectorState,
  serializeCreateKonnectorData,
} from "utils/konnectorUtils";
import { getParseEngineSlice, getRuleConfigSlice } from "state/selectors";
import { saveDataMapping } from "state/rule-engine/slices";
import {
  addParseConfig,
  addFormFields,
  addRuleConfig,
  getKonnectorFailed,
  saveSourceConfiguration,
  fetchTriggerInputfieldsSuccess,
  fetchActionDynamicInputFieldsSuccess,
  fetchInputFieldsLoading,
  fetchTriggerInputfieldsLoading,
  resetForm,
  resetFormSuccess,
  fetchLookupInputFieldsSuccess,
} from "./slices";
import { saveParseDataMapping } from "state/parseEngine/slices";
import { editForm } from "state/form-builder/slices";
import { setSampleDataForSerialization } from "utils/formBuilder.utils";
import { serializeAppFields } from "utils/appsUtils";

export function* getKonnector(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<any>> = yield call(getKonnectorApi, data.payload);
    const user = yield select((state) => state.auth.currentUser);
    if (result.data.status !== 500) {
      const appsDetails = yield call(getAppsApi, result.data.data.konnectorApps);
      const isRepeaterExist = result.data.data?.repeater?.repeaterApps?.length > 0;
      let repeaterAppDynamicFields, repeaterAppDetails, serializeRepeaterAppsField;

      const triggerInputFields = result.data.data.triggerDefinition.inputFields;
      const payload = {
        webhookId: result.data.data.webhookUrl?.id || "",
        konnectorType: result.data.data.konnectorType,
        tenantId: user.tenantId,
        triggerInputFields: triggerInputFields
          ? Object.keys(triggerInputFields).length === 0
            ? null
            : triggerInputFields
          : null,
        actionInputFields: result.data.data.actionItems.slice(-1)[0].dynamicInputFields || null,
      };

      let appsDynamicFields = yield call(getAppsFieldsApiTest, result.data.data.konnectorApps, payload);
      const serializedappsFields = serializeAppFields(appsDynamicFields);
      let state = deserializeKonnectorToEditKonnectorState(result.data, appsDetails, serializedappsFields);
      if (isRepeaterExist) {
        repeaterAppDetails = yield call(getAppsApi, result.data.data.repeater.repeaterApps);
        repeaterAppDynamicFields = yield call(getAppsFieldsApiTest, result.data.data.repeater.repeaterApps, payload);
        serializeRepeaterAppsField = serializeAppFields(repeaterAppDynamicFields);
        state = deserializeRepeaterToEditKonnectorState(
          state,
          result.data.data?.repeater,
          repeaterAppDetails,
          serializeRepeaterAppsField
        );
      }
      if (payload.konnectorType === KonnectorType.Form) {
        yield put(
          editForm({
            formFields: state.data.trigger.formFields,
            formSettings: state.data.trigger.formSettings,
          })
        );
      }
      yield put(getKonnectorSuccess(state));
    } else {
      yield put(getKonnectorFailed());
    }
  } catch (e) {
    console.log("🚀 ~ file: saga.ts ~ line 38 ~ function*updateSourceApp ~ e", e);
    yield put(createAccountFailed());
  }
}

export function* getWebhookUrlData(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const result = yield call(getWebhookData, data.payload.webhookId, data.payload.konnectorType, user.tenantId);
    if (result.data.data.error) {
      yield put(getSampleDataError(result.data.data));
    } else {
      yield put(getSampleDataSucess(result.data.data));
    }
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* restWebhookUrlData(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const result = yield call(resetWebhookDataApi, data.payload.webhookId, user.tenantId);
    console.log({ result });

    yield put(resetWebhookSuccess());
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* editFormConfigration(data) {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const formId = data.payload.webhookId;
    const stateFormFields = yield select((state) => state.formBuilder.formFields);
    const formSettings = yield select((state) => state.formBuilder.formSettings);
    const formFields = yield select((state) => state.formBuilder.formFields);
    const sampleData = setSampleDataForSerialization(stateFormFields);
    const generateForm = yield call(generateFormSampleDataApi, formId, user.tenantId, {
      sampleData,
      formFields,
      formSettings,
    });
    if (generateForm.data.data.error) {
      yield put(getSampleDataError(generateForm.data.data));
    } else {
      yield put(getSampleDataSucess(generateForm.data.data));
    }
    yield put(addFormFields({ formFields, formSettings }));
    // yield put(saveSourceConfiguration());
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* getTriggerInputSchema(data) {
  try {
    const payload = {
      appId: data.payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: data.payload.event,
        connectionConfigId: data.payload.connectionId,
      },
    };
    const result = yield call(fetchDynamicInputFieldsApi, payload);
    console.log(result);
    const inputFields = {
      fields: result.data.data.data,
      appId: result.data.appId,
      eventId: result.data.eventId,
    };
    yield put(fetchTriggerInputfieldsSuccess(inputFields));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* updateSourceApp(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<AppRootObject>> = yield call(fetchApp, data.payload.appId);
    const payload: UpdateSourceAppPayload = {
      ...data.payload,
      appDetails: result.data.data,
    };
    yield put(updateSourceAppSuccess(payload));
  } catch (e) {
    console.log("🚀 ~ file: saga.ts ~ line 38 ~ function*updateSourceApp ~ e", e);
    yield put(createAccountFailed());
  }
}

export function* updateLookupApp(data) {
  try {
    const result: AxiosResponse<KonnectifyApiResponse<AppRootObject>> = yield call(fetchApp, data.payload.appId);
    const payload: UpdateTargetAppPayload = {
      ...data.payload,
      appDetails: result.data.data,
    };
    yield put(updateLookupAppSuccess(payload));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* updateTargetApp(data) {
  try {
    if (!data.payload.actionType) {
      data.payload.actionType = "actions";
    }
    const result: AxiosResponse<KonnectifyApiResponse<AppRootObject>> = yield call(fetchApp, data.payload.appId);
    const payload: UpdateTargetAppPayload = {
      ...data.payload,
      appDetails: result.data.data,
    };
    yield put(updateTargetAppSuccess({ ...payload }));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* updateSourceAccount(data) {
  try {
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(createAccountApi, data.payload);
    yield put(createAccountSuccess(result.data));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* validateAccount(data) {
  try {
    const result: AxiosResponse<ValidateAccountSuccessResponse> = yield call(validateAccountApi, data.payload);
    yield put(validateAccountSuccess(result.data));
  } catch (e) {
    yield put(validateAccountFailed());
  }
}

export function* createAccount(data) {
  try {
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(createAccountApi, data.payload);
    yield put(createAccountSuccess(result.data));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* fetchOutputFields() {
  try {
    const triggerDetails: KonnectorAppState<AppRole.Source> = yield select(getTriggerDetails);
    let dataMapping = {};
    if (triggerDetails.inputFields.hasData) {
      dataMapping = triggerDetails.inputFields.dataMapping;
      console.log({ dataMapping });
    }
    const postReq = {
      appId: triggerDetails.appId,
      data: {
        eventId: triggerDetails.eventId,
        connectionConfigId: triggerDetails.connectionId,
        eventData: { ...dataMapping },
      },
    };
    const [sampleData, outputFieldsSchema]: AxiosResponse<any>[] = yield all([
      call(fetchOutputFieldsApi, postReq),
      call(fetchOutputFieldsSchema, {
        appId: triggerDetails.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.Output,
          eventId: triggerDetails.eventId,
          connectionConfigId: triggerDetails.connectionId,
        },
      }),
    ]);
    const payload = {
      sampleData: sampleData.data.data,
      outputFieldsSchema: outputFieldsSchema.data.data,
    };
    yield put(fetchOutputFieldsSuccess(payload));
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* fetchLookupOutputFields(data) {
  try {
    const lookupDetails: KonnectorAppState<AppRole.Lookup> = yield select((state) =>
      getLookupDetails(state, data.payload)
    );
    const [sampleData, outputFieldsSchema]: AxiosResponse<any>[] = yield all([
      call(fetchOutputFieldsApi, {
        appId: lookupDetails.appId,
        data: {
          eventId: lookupDetails.eventId,
          connectionConfigId: lookupDetails.connectionId,
        },
      }),
      call(fetchOutputFieldsSchema, {
        appId: lookupDetails.appId,
        data: {
          schemaType: DynamicFieldsSchemaType.Output,
          eventId: lookupDetails.eventId,
          connectionConfigId: lookupDetails.connectionId,
        },
      }),
    ]);
    const payload = {
      sampleData: sampleData.data.data,
      id: data.payload.id,
      outputFieldsSchema: outputFieldsSchema.data.data,
      parentId: data.payload.parentId,
    };
    yield put(saveLookupConfigurationSuccess(payload));
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* fetchDynamicInputFields(data) {
  try {
    const targetDetails: KonnectorAppState<AppRole.Target> = yield select(getTargetDetails, data.payload.parentId)
    let dataMapping = {};
    if (targetDetails.dynamicInputFields.hasData) {
      dataMapping = targetDetails.dynamicInputFields.dataMapping;
    }
    const result: AxiosResponse<KonnectifyApiResponse<InputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: targetDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: targetDetails.eventId,
        connectionConfigId: targetDetails.connectionId,
        eventData: { ...dataMapping },
      },
    });
    yield put(fetchDynamicInputFieldsSuccess({ value: result.data.data, parentId: data.payload.parentId }));
  } catch (e) {
    yield put(createAccountFailed());
  }
}

export function* fetchLookupInputFields(data) {
  try {
    const targetDetails: KonnectorAppState<AppRole.Lookup> = yield select(getLookupDetails, data.payload.parentId);
    let dataMapping = {};
    const result: AxiosResponse<KonnectifyApiResponse<InputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: targetDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: targetDetails.eventId,
        connectionConfigId: targetDetails.connectionId,
        eventData: { ...dataMapping },
      },
    });
    yield put(
      fetchLookupInputFieldsSuccess({
        value: result.data.data,
        parentId: data.payload.parentId,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* fetchDynamicFieldsSaga(data) {
  try {
    const targetDetails: KonnectorAppState<AppRole.Target> = yield select(getTargetDetails, data.payload.actionType);
    let dataMapping = {};
    if (targetDetails.dynamicInputFields.hasData) {
      dataMapping = targetDetails.dynamicInputFields.dataMapping;
    }

    const result: AxiosResponse<KonnectifyApiResponse<InputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: targetDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicFields,
        eventId: data.payload.eventId,
        connectionConfigId: targetDetails.connectionId,
        eventData: { ...dataMapping },
      },
    });
    yield put(
      fetchDynamicFieldsSuccess({
        value: result.data.data.data,
        field: data.payload.field,
        actionType: data.payload.actionType,
      })
    );
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* fetchActionDynamicInputFields(data) {
  try {
    const targetDetails: KonnectorAppState<AppRole.Target> = yield select(getTargetDetails, data.payload.parentId);
    const result: AxiosResponse<KonnectifyApiResponse<DynamicInputFields>> = yield call(fetchDynamicInputFieldsApi, {
      appId: targetDetails.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicInput,
        eventId: targetDetails.eventId,
        connectionConfigId: targetDetails.connectionId,
      },
    });
    console.log(result, "result");
    yield put(fetchActionDynamicInputFieldsSuccess({ value: result.data.data.data, parentId: data.payload.parentId }));
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}
export function* saveKonnector() {
  try {
    const updateKonnectorState = yield select(getEditKonnectorDetails);
    const serializeData = serializeCreateKonnectorData(updateKonnectorState);
    const result: AxiosResponse<any> = yield call(updateKonnectorApi, updateKonnectorState.data.id, serializeData);
    if (result.data.status === 401) {
      yield put(displayErrorMessage(result.data.message));
      throw Error("Upgrade your plan");
    }
    yield put(saveKonnectorSuccess());
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}

export function* saveRuleConfig() {
  try {
    const editKonnectorState = yield select(getEditKonnectorDetails);
    const parentId = editKonnectorState.currentActionId;
    const ruleConfig = yield select(getRuleConfigSlice);
    yield put(addRuleConfig({ ruleConfig, parentId }));
  } catch (e) {
    console.log(e);
  }
}
export function* saveParseConfig() {
  try {
    const parseConfig = yield select(getParseEngineSlice);
    console.log({ parseConfig });
    yield put(addParseConfig(parseConfig));
  } catch (e) {
    console.log(e);
  }
}

export function* resetEditForm() {
  try {
    console.log("entered reset form");
    const updateKonnectorState = yield select(getEditKonnectorDetails);
    const konnectorId = updateKonnectorState.data.id;
    const formId = updateKonnectorState.data.webhookUrl.id;
    const user = yield select((state) => state.auth.currentUser);
    const payload = {
      konnectorId: konnectorId,
      formId: formId,
    };
    const result: AxiosResponse<any> = yield call(resetEditFormData, user.tenantId, payload);
    if (result.data) {
      yield put(resetFormSuccess());
    }
  } catch (e) {
    console.log(e);
    yield put(createAccountFailed());
  }
}
export default function* rootSaga() {
  yield takeEvery(resetForm.type, resetEditForm);
  yield takeEvery(getKonnectorLoading.type, getKonnector);
  yield takeEvery(getSampleDataLoading.type, getWebhookUrlData);
  yield takeEvery(resetWebhookLoading.type, restWebhookUrlData);
  yield takeEvery(saveFormSourceConfiguration.type, editFormConfigration);
  yield takeEvery(validateAccountLoading.type, validateAccount);
  yield takeEvery(createAccountLoading.type, createAccount);
  yield takeEvery(fetchTriggerInputfieldsLoading.type, getTriggerInputSchema);
  yield takeEvery(fetchOutputFieldsLoading.type, fetchOutputFields);
  yield takeEvery(fetchInputFieldsLoading.type, fetchDynamicInputFields);
  yield takeEvery(fetchLookupInputFieldsLoading.type, fetchLookupInputFields);
  yield takeEvery(fetchDynamicInputFieldsLoading.type, fetchActionDynamicInputFields);
  yield takeEvery(fetchDynamicFieldsLoading.type, fetchDynamicFieldsSaga);
  yield takeEvery(updateSourceAppLoading.type, updateSourceApp);
  yield takeEvery(updateTargetAppLoading.type, updateTargetApp);
  yield takeEvery(saveKonnectorLoading.type, saveKonnector);
  yield takeEvery(updateLookupAppLoading.type, updateLookupApp);
  yield takeEvery(saveLookupConfigurationLoading.type, fetchLookupOutputFields);
  yield takeEvery(saveDataMapping.type, saveRuleConfig);
  yield takeEvery(saveParseDataMapping.type, saveParseConfig);
}
