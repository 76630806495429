import useSWR from "swr";

import { fetcher, axiosInstance, options } from "./libs/fetch";
import { log } from "console";

export default function useWorkflow(appId, page, sortOrder = "", activeSortBy, filterBy) {
  let limit;
  if (filterBy === "active") {
    limit = 50;
  } else {
    limit = 5;
  }
  const queryValue = sortOrder.split("-")[0] || "";
  const sortId = sortOrder.split("-")[1] || "";
  return useSWR(
    `/embedded/apps/${appId}/all?pageNumber=${page}&pageSize=${100}${filterBy !== "active" ? "&sortValue=" + queryValue + "&sortOrder=" + sortId : ""
    }${filterBy === "active"
      ? "&filterBy=status" + "&sortValue=" + queryValue + "&sortOrder=" + sortId
      : filterBy === "freshdesk"
        ? "&filterBy=sourceTemplate.freshdesk"
        : ""
    }`,
    fetcher
  );
}

export function saveWorkflowApi(payload) {
  const appId = payload.triggerDefinition.appId;
  return axiosInstance.post(`/embedded/apps/${appId}`, payload, options);
}

export function updatingAppDescription(payload) {
  return axiosInstance.put(`/embedded/apps/description`, payload, options);
}

export function updateWorkflowApi(appId, workflowId, payload) {
  return axiosInstance.put(`/embedded/apps/${appId}/${workflowId}`, payload, options);
}

export function getWorkflowApi(payload) {
  return axiosInstance.get(`/embedded/apps/${payload.appId}/${payload.id}`, options);
}

export function useAppDescription(appId) {
  return useSWR(`/embedded/apps/description/${appId}`, fetcher);
}

export function useWorkflows(id) {
  return useSWR(`/embedded/apps/${id}`, fetcher);
}

export function useWorkflowForApps() {
  return useSWR(`/embedded/apps/workflows`, fetcher);
}

export function useAllWorkflows(appId) {
  return useSWR(`/embedded/apps/${appId}/all`, fetcher);
}

export function activateKonnectorApi(payload) {
  return axiosInstance.post(`/embedded/apps/${payload.id}/${payload.workflowId}/activate`, options);
}
export function deactivateKonnectorApi(payload) {
  return axiosInstance.post(`/embedded/apps/${payload.id}/${payload.workflowId}/deactivate`, options);
}

export function deleteKonnectorApi(workflowId) {
  return axiosInstance.delete(`/embedded/apps/${workflowId}`, options);
}

export function updateWorkflowIndex(payload) {
  return axiosInstance.put(`/embedded/workflow/index`, payload, options);
}

export function updateWorkflowSettings(workflowAppId, payload) {
  return axiosInstance.put(`/embedded/workflow/${workflowAppId}/settings`, payload, options)
}
