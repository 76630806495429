import { CurrentUser, PlanType, UserPlan, UserPlanObj, UserPlanVm } from "state/auth/types";

export const auth = {};

export const setPlanDetailsData = (data: CurrentUser) => {
  const res = setType(data);
  return {
    plan: res.plan,
    cycle: data.billing?.cycle,
    planExpired: data.planExpired,
    type: res.type,
    message: res.message,
    status: res.status,
    user: data,
  };
};

const setType = (user: CurrentUser) => {
  let type,
    message = "",
    plan: any = UserPlanObj?.[user.billing?.plan],
    status = false;
  if (!user.isEmailVerified) {
    plan = UserPlanVm.Upgrade;
    type = "emailNotVerified";
    message = "Please verify your email";
  } else if (user.billing?.plan === UserPlan.TrialExpired) {
    type = "trialExpired";
    message = "Your Free Trial plan has expired, Please upgrade to proceed";
  } else if (user.planExpired) {
    plan = UserPlanVm.Upgrade;
    type = "planExpired";
    message = `Your ${user.billing?.plan} plan has expired, Please upgrade to proceed`;
  } else if (user.billing.plan === UserPlan.LimitReached) {
    type = "taskExeeded";
    plan = UserPlanVm.Upgrade;
    message = "You have reached the your tasks limit, Please upgrade your plan to proceed";
  } else {
    if (user.hasTrial) {
      plan = UserPlanVm.Trial;
    }
    type = "";
    status = true;
  }
  return { type, status, message, plan };
};

export const setKonnectorStatusData = (user, data) => {
  const res = setType(user);
  if (res.status) {
    res.type = data.type;
    res.status = data.status;
    res.message = data.message;
  }
  return {
    type: res.type,
    status: res.status,
    message: res.message,
  };
};
