import Modal from "common/components/Modal/DefaultModal";
import React, { useEffect } from "react";
import ReactJson from "react-json-view";
import { toast } from "react-toastify";
import { updateSourceEvent } from "state/edit-konnector/slices";
import { KonnectorState } from "state/konnectors/types";
import { KonnectorType } from "state/new-konnector/types";
import { WorkflowType } from "state/new-workflow/types";
import { useAppDispatch, useAppSelector } from "state/store";
import { WorkFlowState } from "state/workFlows/types";

const ShowOutputFields: React.FC<any> = ({ visible, outputFields, setViewOutputFields }) => {
  const dispatch = useAppDispatch();
  const konnectorState: WorkFlowState = useAppSelector((state) => state.workflows.workflowState);
  const konnectorType = useAppSelector((state) => state[konnectorState].data.workflowType);

  useEffect(() => {
    if (outputFields?.eventResponse?.data?.error) {
      toast.info(outputFields.eventResponse.data.error, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (konnectorType === WorkflowType.WebHook) {
        dispatch(updateSourceEvent({ event: "webhook", type: "webhook" }));
      } else if (konnectorType === WorkflowType.Form) {
        dispatch(updateSourceEvent({ event: "form", type: "form" }));
      } else {
        dispatch(updateSourceEvent({ viewOutputFields: false }));
      }
      visible && setViewOutputFields(false);
    }
  }, [outputFields]);

  return (
    <div>
      {outputFields && (
        <Modal title={outputFields.eventId} visible={visible} setVisible={setViewOutputFields}>
          <ReactJson src={outputFields} />
        </Modal>
      )}
    </div>
  );
};

export default ShowOutputFields;
