import { all, fork } from "redux-saga/effects";
import newKonnectorSaga from "./new-konnector/saga";
import editKonnectorSaga from "./edit-konnector/saga";
import accountSaga from "./account/saga";
import authSaga from "./auth/saga";
import konnectorsSaga from "./konnectors/saga";
import paymentSaga from "./payment/saga";
import newWorkFlowSaga from "./new-workflow/saga";
import edtWorkFlowSaga from "./edit-workflow/saga";
import workFlowsSaga from "./workFlows/saga";
import adminSettingsSaga from "./Embedded-markteplace/AdminSettings/saga";
import themeSettingsSaga from "./Embedded-markteplace/ThemeSettings/saga";
export function* rootSaga() {
  yield all([
    fork(newKonnectorSaga),

    fork(authSaga),

    fork(konnectorsSaga),

    fork(workFlowsSaga),
    fork(editKonnectorSaga),

    fork(accountSaga),
    fork(adminSettingsSaga),
    fork(themeSettingsSaga),
    fork(paymentSaga),
    ,
    fork(newWorkFlowSaga),
    fork(edtWorkFlowSaga),
  ]);
}
