import Select from "react-select";
import { useAppsByTypes } from "api/appsApi";
import { useAppSelector } from "state/store";

const customStyles = {
  control: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
  container: (provided) => ({
    ...provided,
    fontSize: "14px",
  }),
};

const SelectApp = ({ selectedApp, onChangeAppHandler }) => {
  const currentPage = useAppSelector((state) => state.account.currentPage);
  const appType = useAppSelector((state) => state.konnectors.appType);
  const { data: apps, error: appsError } = useAppsByTypes(currentPage, appType);

  if (appsError) return <p>Failed to load apps</p>;

  const renderKonnectors = () => {
    if (!apps) return [{ label: "Loading...", value: "" }];

    const appsItems = [
      { label: "All", value: "" },
      ...apps.data.items.map((app) => ({ label: app?.appName, value: app.id })),
    ];
    return appsItems;
  };

  return (
    <div className=" mb-2">
      <label className="form-label inline-block mb-2 text-gray-700 text-sm">Select App</label>
      <Select
        styles={customStyles}
        options={renderKonnectors()}
        value={renderKonnectors().find((option) => option.value === selectedApp)}
        onChange={onChangeAppHandler}
      />
    </div>
  );
};

export default SelectApp;
