import { DataMappingObject } from "./../state/new-konnector/types";
import { Options } from "./../common/components/FormFields/types";

export interface PropValueObject {
  label: string;
  value: string;
}

export interface InputField {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue?: PropValueObject[];
}

export class Field {
  constructor(public field: InputField) {}
  format(): Options {
    return {
      type: "select",
      name: this.field.propName,
      pattern: { message: "", value: "", flags: "" },
      required: { message: "This field is mandatory!", value: this.field.isRequired },
    };
  }
}

export const serializeDataMapping = (inputFields: InputField[], mappingData, sourceId: string): DataMappingObject[] => {
  return inputFields.map((field: InputField): DataMappingObject => {
    const type = mappingData[field.propName + "__field_type"];
    const sourceId = mappingData[field.propName + "__field_source_type"];
    const value =
      (type !== "source" && field.propType) === "number"
        ? parseInt(mappingData[field.propName])
        : mappingData[field.propName];
    return {
      ...field,
      source: sourceId,
      sourceProp: value,
      staticValue: type !== "source" ? value : "",
    };
  });
};
export const serializeLookupDataMapping = (inputFields: InputField[], mappingData): DataMappingObject[] => {
  return inputFields.map((field: InputField): DataMappingObject => {
    const sourceId = mappingData[field.propName + "__field_source_type"];
    const type = mappingData[field.propName + "__field_type"];
    const value = mappingData[field.propName];
    return {
      ...field,
      source: sourceId,
      sourceProp: value,
      staticValue: type !== "source" ? value : "",
    };
  });
};

export const deserializeDataMapping = (targetActionProperties) => {
  const rawDataMapping = {};
  targetActionProperties.forEach((field: DataMappingObject) => {
    rawDataMapping[field.propName + "__field_type"] = field.staticValue ? "fixed" : "source";
    rawDataMapping[field.propName + "__field_source_type"] = field.source || "";
    rawDataMapping[field.propName] = field.sourceProp;
  });
  return rawDataMapping;
};

export const serializeAppFields = (appFields) => {
  try {
    const source = appFields[0];
    const lookups = appFields[1];
    const actions = appFields[2];
    let fields: any = {};
    if (source.length > 0) {
      if (source.length === 1) {
        fields["sourceSample"] = source[0].data.data;
      } else if (source.length === 3) {
        fields["sourceInput"] = source[0].data.data;
        fields["sourceSample"] = source[1].data.data;
        fields["sourceOutput"] = source[2].data.data;
      } else {
        fields["sourceSample"] = source[0].data.data;
        fields["sourceOutput"] = source[1].data.data;
      }
    }
    if (lookups[0].length > 0) {
      for (let i in lookups) {
        fields[`lookupInput_${i}`] = lookups[i][0].data.data;
        fields[`lookupOutput_${i}`] = lookups[i][1].data.data;
      }
    }
    if (actions[0].length > 0) {
      for (let i in actions) {
        if (actions[i].length === 3) {
          fields[`targetDynamicInput_${i}`] = actions[i][0].data.data;
          fields[`targetInput_${i}`] = actions[i][1].data.data;
          fields[`targetOutput_${i}`] = actions[i][2].data.data;
        } else {
          fields[`targetInput_${i}`] = actions[i][0].data.data;
          fields[`targetOutput_${i}`] = actions[i][1].data.data;
        }
      }
    }
    return fields;
  } catch (e) {
    console.log("Error in serializeAppFields at app util Line 101", e);
  }
};
