import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "state/store";
import FieldItem from "./FieldItem";
import { AppRole, KonnectorAppState } from "state/new-konnector/types";

interface ConfigurationDetails {
  action: KonnectorAppState<AppRole.Target>;
  konnectorSlice: any;
}

const TargetFields: React.FC<ConfigurationDetails> = ({ action, konnectorSlice }): JSX.Element => {
  const {
    register,
    formState: { errors },
    reset,
    handleSubmit,
    watch,
    setValue,
    control,
  } = useForm();

  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);
  const hasActionDynamicInput = useAppSelector((state) => state[konnectorState].hasActionDynamicInput);
  const hasDynamicInput = useAppSelector((state) => state[konnectorState].hasDynamicInput);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const inputFields: any = action.inputFields;
  const hasDynamicInputfields = action.dynamicInputFields.hasDynamicInputFields;
  const selectedEvent = action.eventId;
  const rawDataMapping = action.rawDataMapping;
  const shouldRenderDynamicFields = action.dynamicInputFields.hasDynamicInputFields
    ? Object.keys(action.dynamicInputFields.dataMapping).length === action.dynamicInputFields.data.length
    : true;

  const onSubmit = (data) => {
    dispatch(konnectorSlice.saveDataMapping(data));
  };

  const renderFields = (): JSX.Element[] => {
    return inputFields.data.map((field, index) => {
      return (
        <FieldItem
          field={field}
          key={index}
          watch={watch}
          setInputValue={setValue}
          control={control}
          register={register}
          errors={errors}
        />
      );
    });
  };
  useEffect(() => {
    if (selectedEvent) {
      reset(rawDataMapping);
      if (!hasDynamicInputfields) {
        dispatch(konnectorSlice.fetchInputFieldsLoading());
      }
    }
  }, [selectedEvent]);
  useEffect(() => {
    if (inputFields?.data && inputFields.data.error) {
      toast.info(inputFields.data.error, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(konnectorSlice.updateTargetEvent({ eventId: "", id: currentActionId }));
    }
  }, [inputFields]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>

        {inputFields?.data?.length ? renderFields() : <p>Loading...</p>}
        {inputFields?.data?.length && renderFields().length ? (
          <div className="flex justify-end">
            <button
              type="submit"
              className="text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all  font-medium rounded-sm text-sm px-5 py-2.5 text-center"
            >
              Save
            </button>
          </div>
        ) : null}
      </form>
      {/* } */}

    </div>
  );
};

export default TargetFields;
