import moment from "moment";
import { Item } from "pages/EmbeddedMarketPlace/WorkflowList/types";
import React from "react";
import { Link, useParams } from "react-router-dom";
import Apps from "./Apps";
import Controls from "./Controls";
import { setKonnectorState } from "state/workFlows/slices";
import { WorkFlowState } from "state/workFlows/types";
import { useAppDispatch } from "state/store";
import Konnector from "common/containers/Layout/components/icons/Konnectors";
import { DragDropIcon } from "pages/KonnectorPage/components/ConfigureKonnector/FormBuilder/Icons/DragDropIcon";
import { DraggableIcon } from "pages/EmbeddedMarketPlace/WorkFlow/components/ConfigureWorkflow/FormBuilder/Icons/DragableIcon";
import { Draggable } from "react-beautiful-dnd";

interface KonnectorItemProps {
  konnector: Item;
  index: any;
}
const KonnectorItemActive: React.FC<KonnectorItemProps> = ({ konnector, index }) => {
  const getHumanReadableTime = (datetime) => moment(datetime).fromNow();
  const { id } = useParams<any>();
  const dispatch = useAppDispatch();
  const onClickHandler = () => {
    dispatch(setKonnectorState(WorkFlowState.EditWorkFlowState));
  };


  return (
    <Draggable key={konnector.id} draggableId={konnector.id} index={index}>
      {(provided) => (
        <div className="p-5 mb-4 rounded-lg border-[1px] border-gray-300 flex items-center"
          ref={provided.innerRef} {...provided.draggableProps}>
          <div className="p-4" {...provided.dragHandleProps}>
            <DraggableIcon></DraggableIcon>
          </div>
          <div className="basis-[60%]">
            <Link
              onClick={onClickHandler}
              to={`/KompApps/templates/${konnector.id}`}
              className="text-base mb-1 hover:text-komp-hover-color flex flex-col"
            >
              <p className="text-gray-400 text-xs">#{konnector.id}</p>
              <p className="font-semibold text-komp-hover-color">{konnector.name}</p>
            </Link>

            <p className="flex text-xs text-gray-500">
              <span className="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd" />
                </svg>
                <span>Created by {konnector.owner || "Unknown"}</span>
              </span>
              <span className="ml-4 flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                    clipRule="evenodd"
                  />
                </svg>
                <span>Created {getHumanReadableTime(konnector.dtCreated)}</span>
              </span>
              {konnector.dtExecuted && (
                <span className="ml-4 flex items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <span>Executed {getHumanReadableTime(konnector.dtExecuted)}</span>
                </span>
              )}
            </p>
          </div>
          <div className="flex justify-between basis-[40%]">
            <Apps apps={konnector.konnectorApps} ruleConfig={konnector.ruleConfig.length} konnector={konnector} />
            <div className="ml-auto ">
              <Controls workflowId={konnector.id} status={konnector.status} name={konnector.name} />
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );

};

export default KonnectorItemActive;
