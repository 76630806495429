import React, { useState } from "react";
import Modal from "./Modal";
import * as Icon from "../../Icons";
import Downgrade from "../DowngradeModal";
import { useKonnectorTrialPlan } from "api/konnectorsApi";
import { useAppSelector } from "state/store";
import Checkout from "../../CheckoutForm";

const PaymentModal: React.FC<any> = ({ children, visible, closeModalHandler, fromPlan, disabled }) => {
  const [showModal, setShowModal] = useState(false);
  const { data: konnector, error: konnectorError } = useKonnectorTrialPlan();

  const userStripeId = useAppSelector((state) => state.auth.currentUser.stripeId);

  if (!konnector) return <></>;
  console.log({ visible });

  return (
    <>
      <Modal visible={visible} closeModalHandler={closeModalHandler} disabled={disabled}>
        <Checkout />
      </Modal>
    </>
  );
};

export default PaymentModal;
