import React from "react";

const AllApps: React.FC<any> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      viewBox="0 0 21.57 21.56"
      className="h-5 w-5"
    >
      <defs>
        <style>
          {`
      .komp-all-apps-1 {
        fill: ${"#bbbdc1"};
      }

      .komp-all-apps-2 {
        fill: ${"#727b88"};
      }`}
        </style>
      </defs>
      <g id="darkmode_1" data-name="darkmode 1">
        <g id="sidebar">
          <g>
            <path
              className="komp-all-apps-2"
              d="M4.99,0c.85,0,1.69,0,2.54,0,1.34,0,2.44,1.1,2.44,2.44,0,1.7,0,3.4,0,5.1,0,1.32-1.1,2.43-2.42,2.43-1.71,0-3.41,0-5.12,0C1.12,9.96,.01,8.86,0,7.56c0-1.72,0-3.43,0-5.15C.01,1.12,1.12,.02,2.4,0c.86,0,1.72,0,2.59,0Z"
            />
            <path
              className="komp-all-apps-2"
              d="M11.59,4.99c0-.84,0-1.67,0-2.51C11.6,1.1,12.69,0,14.06,0c1.68,0,3.36,0,5.04,0,1.36,0,2.46,1.09,2.46,2.45,0,1.69,0,3.38,0,5.07,0,1.35-1.1,2.44-2.46,2.45-1.68,0-3.36,0-5.04,0-1.37,0-2.45-1.1-2.46-2.47,0-.84,0-1.67,0-2.51Z"
            />
            <path
              className="komp-all-apps-2"
              d="M4.99,21.56c-.84,0-1.67,0-2.51,0-1.38,0-2.47-1.09-2.47-2.46,0-1.68,0-3.36,0-5.04,0-1.36,1.1-2.46,2.45-2.46,1.69,0,3.38,0,5.07,0,1.34,0,2.44,1.1,2.45,2.43,0,1.7,0,3.4,0,5.1,0,1.34-1.1,2.43-2.45,2.44-.84,0-1.69,0-2.54,0Z"
            />
            <path
              className="komp-all-apps-1"
              d="M16.58,21.56c-.84,0-1.67,0-2.51,0-1.38,0-2.47-1.09-2.47-2.46,0-1.68,0-3.36,0-5.04,0-1.36,1.1-2.45,2.45-2.46,1.69,0,3.38,0,5.07,0,1.34,0,2.44,1.1,2.45,2.44,0,1.7,0,3.4,0,5.1,0,1.34-1.1,2.43-2.45,2.43-.84,0-1.69,0-2.54,0Z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AllApps;
