import React, { useEffect } from "react";
import Select from "react-select";
import { useAppAccounts } from "api/accountsApi";
import { useAppDispatch, useAppSelector } from "state/store";
import CreateAccount from "common/containers/Account/CreateAccount";
import { setAccountModal } from "state/account/slices";
import { StatusType } from "state/new-konnector/types";
const SelectAccount: React.FC<any> = ({ sourceApp, apps, konnectorSlice, konnectorState }) => {
  const sourceAccount = useAppSelector((state) => state[konnectorState].data.trigger.connectionId);
  const status = useAppSelector((state) => state[konnectorState].status);

  const dispatch = useAppDispatch();

  const { data: appAccounts, error: appAccountsError, mutate } = useAppAccounts(sourceApp);
  const appAccountState = useAppSelector((state) => state.account);

  const modal = useAppSelector((state) => state.account.Newmodel);
  useEffect(() => {
    dispatch(setAccountModal(false));

    if (appAccountState.data.id && appAccountState.status === "success") {
      mutate({
        ...appAccounts,
        data: {
          ...appAccounts.data,
          items: [appAccountState.data, ...appAccounts.data.items],
        },
      });
    }
  }, [appAccountState.status]);

  useEffect(() => {
    if (sourceApp) {
      if (appAccounts && !appAccounts.data.items.length) {
        dispatch(setAccountModal(true));
      }
    }
  }, [appAccounts && sourceApp]);

  useEffect(() => {
    if (appAccounts && !appAccounts.data.items.length) {
      dispatch(setAccountModal(true));
    }
  }, [appAccounts && sourceApp]);
  const showCreateAccount = useAppSelector((state) => state.account.showModal);
  const createdAccount = useAppSelector((state) => state.account.data);
  useEffect(() => {
    if (createdAccount.id) {
      dispatch(
        konnectorSlice.updateSourceAccount({
          connectionId: createdAccount.id,
          connectionName: createdAccount.connectionName,
        })
      );
    }
  }, [createdAccount.id]);
  if (appAccountsError) return <p>Failed to load apps</p>;

  const onChangeAccountHandler = ({ label: connectionName, value: connectionId }) => {
    dispatch(konnectorSlice.updateSourceAccount({ connectionId, connectionName }));
  };

  const onClickCreateAccountHandler = (e) => {
    e.preventDefault();
    dispatch(setAccountModal(true));
  };

  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };

  const renderAppAccounts = () => {
    if (!appAccounts) return [{ label: "Loading...", value: "" }];
    return appAccounts.data.items.map((account) => ({
      label: account.connectionName,
      value: account.id,
    }));
  };

  return (
    <div className="mb-3 xl:w-96 2xl:w-96">
      <label htmlFor="exampleFormControlInput2" className="form-label inline-block mb-2 text-gray-700 text-md">
        Select Account
      </label>
      <Select
        options={renderAppAccounts()}
        value={renderAppAccounts().filter(function (option) {
          return option.value === sourceAccount;
        })}
        onChange={onChangeAccountHandler}
      />
      <div className="text-sm mt-2 font-medium text-gray-500 dark:text-gray-300">
        Not registered?{" "}
        <button
          onClick={onClickCreateAccountHandler}
          className="text-blue-700 cursor-pointer hover:underline dark:text-blue-500"
        >
          Create account
        </button>
      </div>
      {showCreateAccount && <CreateAccount appDetails={getApp()} />}
    </div>
  );
};

export default SelectAccount;
