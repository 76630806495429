import { useWorkflowForApps } from "api/workflowApi";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { getWorkflowAppSlice } from "state/selectors";
import { useAppDispatch, useAppSelector } from "state/store";
import { activateEmbeddedAppLoading, deAactivateEmbeddedAppLoading } from "state/workFlows/slices";

interface ControlsProps {
  appId: string;
  status: string;
  embeddedApps: any;
}
const notificationMessage = {
  true: "App is Enabled",
  false: "App is Disabled",
};
const Controls: React.FC<ControlsProps> = ({ appId, status, embeddedApps }) => {
  const [appStatus, setAppStatus] = useState(status);
  const dispatch = useAppDispatch();
  const [allWorkflows, setWorkflows] = useState([]);
  const getKonnectors = useAppSelector(getWorkflowAppSlice);
  const { data: workflows, error: appsError } = useWorkflowForApps();
  const hasActivated: any = useAppSelector((state) => state.workflows.hasActivated);
  const categoryType: any = useAppSelector((state) => state.auth.categoryType);
  const [isVisible, SetVisible] = useState(false);
  useEffect(() => {
    if (workflows) {
      setWorkflows(workflows.data ? workflows.data : []);
    }
  });

  useEffect(() => {
    if (hasActivated) {
      SetVisible(false);
    }
  }, [hasActivated, isVisible]);

  const handleChange = (e) => {
    const checked = e.target.checked;
    if (checked) {
      SetVisible(true);
      dispatch(activateEmbeddedAppLoading(appId));
    } else {
      dispatch(deAactivateEmbeddedAppLoading({ appId, categoryType }));
    }
  };

  const setAppActivated = () => {
    const isEmbeddedAppEnabled = embeddedApps.filter((app) => app === appId);
    const hasWorkflows = allWorkflows.some((workflow) => workflow.workflowAppId === appId);
    if (!hasWorkflows) {
      return (
        <input
          id={appId}
          onChange={handleChange}
          checked={false}
          type="checkbox"
          className="hidden peer"
          disabled={!hasWorkflows}
        />
      );
    }
    if (isEmbeddedAppEnabled.length) {
      return <input id={appId} onChange={handleChange} checked={true} type="checkbox" className="hidden peer" />;
    }
    return <input id={appId} onChange={handleChange} checked={false} type="checkbox" className="hidden peer" />;
  };

  useEffect(() => {
    if (getKonnectors.status === "success" && getKonnectors.currentActionId === appId) {
      setAppStatus(getKonnectors.hasActivated ? "active" : "inactive");
      toast.success(getKonnectors.hasActivated ? "App enabled" : "App disabled", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [getKonnectors.status]);
  useEffect(() => {
    setAppStatus(status);
  }, [status]);

  return (
    <div className="flex">
      <label className="inline-flex items-center space-x-4 cursor-pointer">
        {isVisible && (
          <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite] mr-[-0.25rem]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"></span>
          </div>
        )}
        <span className="relative">
          {setAppActivated()}
          {!isVisible && (
            <>
              <div className="absolute bg-white inset-y-0 left-0 w-4 h-4 m-[2px] rounded-full shadow peer-checked:right-0 peer-checked:left-auto  peer-disabled:bg-gray-400"></div>
              <div className="w-10 h-5 bg-gray-500 peer-checked:bg-green-700 rounded-full shadow-inner peer-disabled:bg-gray-200"></div>
            </>
          )}
        </span>
      </label>
    </div>
  );
};

export default Controls;
