import useSWR from "swr";

import { fetcher, axiosInstance, options } from "./libs/fetch";

const PAGE_LIMIT = 10;

export default function useEventLogs(page, queryValue = "", konnectorName = "") {
	return useSWR(
		`/event-logs?pageNumber=${page}&pageSize=${PAGE_LIMIT}&sortValue=${queryValue}&konnectorId=${konnectorName}`,
		fetcher
	);
}
export function useEventlog(eventId) {
	return useSWR(`/event-logs/${eventId}`, fetcher);
}
