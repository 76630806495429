import useSWR from "swr";
import { fetcher, planaxiosInstance, options } from "./libs/fetch";

export function getPlanApi() {
  return planaxiosInstance.get(`/plans`, options);
}

export function freePlan(payload) {
  return planaxiosInstance.post(`/plans/free`, payload, options);
}
export function upgradePlanApi(payload) {
  return planaxiosInstance.put(`/plans/upgrade`, payload);
}
export function usePaymentHistory(email) {
  return useSWR(`/payments/history?email=${email}`, planaxiosInstance);
}
export function usePaymentHistoryApi(email, startAfter) {
  return useSWR(`/payments/history?email=${email}?startingAfter=${startAfter}`, planaxiosInstance);
}
export function useSubscription(email) {
  return useSWR(`/plans/subscription?email=${email}`, planaxiosInstance);
}

export function getInvoiceItemsApi(data) {
  return planaxiosInstance.get(`/plans/invoiceItems?customer=${data.customer}`, options);
}

export function createSession(planId, stripeId, planCycle, addon) {
  return planaxiosInstance.post(
    `/payments/create-checkout-session/${planId}/${stripeId}?frequency=${planCycle}&addon=${addon}`
  );
}

export function createPayment(payload) {
  return planaxiosInstance.post(`/payments/create_payments`, payload, options);
}

export function createIncompleteSubscription(payload) {
  return planaxiosInstance.post(`/payments/create_subscription`, payload, options);
}

export function cancelPreviousSubscription(payload) {
  return planaxiosInstance.post(`/payments/cancel_subscription`, payload, options);
}
export function cancelIncompleteSubscription(payload) {
  return planaxiosInstance.post(`/payments/cancel_incomplete_subscription`, payload, options);
}

export function retrivePaymentMethod(id) {
  return planaxiosInstance.get(`/payments/payment_methods/${id}`, options);
}
