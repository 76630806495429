import React, { useEffect } from 'react';
import { FieldIcons } from '../Icons/TextBoxIcon';
import { Draggable } from 'react-beautiful-dnd';
import RenderField from '../FormFields/genericFields';
import { useForm } from 'react-hook-form';
import { formTitleField } from '../constants/formSettings';
import { useAppDispatch, useAppSelector } from 'state/store';
import { setFormTitle } from 'state/form-builder/slices';

const FormTitle: React.FC<any> = ({ field }) => {
	const {
		register,
		formState: { errors },
		reset,
		watch,
		handleSubmit,
	} = useForm();

	const dispatch = useAppDispatch();
	const onSubmit = (data) => {
		console.log(data);
	};
	const formTitle = useAppSelector((state) => state.formBuilder.formSettings.formTitle);
	const watchformTitle = watch('form_title');
	useEffect(() => {
		dispatch(setFormTitle(watchformTitle));
	}, [watchformTitle]);

	useEffect(() => {
		reset({ form_title: formTitle });
	}, []);

	const renderFieldList = (fieldList) => {
		return fieldList.map((field) => {
			field.required = false;
			return <RenderField field={field} register={register} errors={errors} />;
		});
	};
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-3/4 mt-4 p-2 space-y-2">
			{renderFieldList(formTitleField)}
			<div className="flex justify-end">
				{/* <button
          type="submit"
          className="w-24 text-primary-color font-medium rounded text-sm px-3 py-2 text-center bg-gray-100 border-2 border-gray-300 disabled:opacity-50"
        >
          Save
        </button> */}
			</div>
		</form>
	);
};

export default FormTitle;
