import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import React from "react";

export const ReorderIcon: React.FC = () => {
    return (
        // <Tooltip text={"Reorder"}>
        <svg className="h-4 mr-2 ml-52 -mt-10 mb-9" viewBox="0 0 44 35" fill="rgb(255 255 255 / var(--tw-text-opacity))" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.184998 24.925H43.295V20.135H0.184998V24.925ZM0.184998 34.505H43.295V29.715H0.184998V34.505ZM0.184998 15.345H43.295V10.555H0.184998V15.345ZM0.184998 0.974998V5.765H43.295V0.974998H0.184998Z" fill="rgb(255 255 255)" />
        </svg>
        // </Tooltip>


        // <svg className="w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 125" version="1.1" x="0px" y="0px" fill="white"><title>Arrow 41.2</title><desc>Created with Sketch.</desc><g stroke="none" stroke-width="1" fill="white" fill-rule="evenodd"><g fill="white"><path className="w-5 h-5" d="M62.0011847,65.5042591 L63.4988153,65.5042591 C64.6040891,65.5042591 65.0377723,66.2746386 64.4675856,67.2249498 L57.5324144,78.7835684 C56.9586846,79.7397848 56.0377723,79.7338796 55.4675856,78.7835684 L48.5324144,67.2249498 C47.9586846,66.2687334 48.3959609,65.5042591 49.5011847,65.5042591 L50.9988153,65.5042591 C52.1040891,65.5042591 53,64.6072191 53,63.5006642 L53,42.507854 C53,41.3940223 53.8980806,40.5042591 55.0059191,40.5042591 L57.9940809,40.5042591 C59.1134452,40.5042591 60,41.4012991 60,42.507854 L60,63.5006642 C60,64.6144959 60.8959609,65.5042591 62.0011847,65.5042591 Z M37.9988153,34.5042591 L36.5011847,34.5042591 C35.3959109,34.5042591 34.9622277,33.7338796 35.5324144,32.7835684 L42.4675856,21.2249498 C43.0413154,20.2687334 43.9622277,20.2746386 44.5324144,21.2249498 L51.4675856,32.7835684 C52.0413154,33.7397848 51.6040391,34.5042591 50.4988153,34.5042591 L49.0011847,34.5042591 C47.8959109,34.5042591 47,35.4012991 47,36.507854 L47,57.5006642 C47,58.6144959 46.1019194,59.5042591 44.9940809,59.5042591 L42.0059191,59.5042591 C40.8865548,59.5042591 40,58.6072191 40,57.5006642 L40,36.507854 C40,35.3940223 39.1040391,34.5042591 37.9988153,34.5042591 Z" /></g></g></svg>
    );
};
