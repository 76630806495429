export enum BadgeTypes {
  Success = "success",
  Warning = "Warning",
  Error = "failed",
  Info = "Info",
  FieldMappingFailed = "field_mapping_failed",
}
export interface BadgeProps {
  type: BadgeTypes | string;
  text: string;
}
