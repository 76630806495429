export const renderFieldList = [
  {
    type: "textbox",
    label: "Label",
    name: "label",
    required: {
      message: "Please enter the label for your field",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];

export const requiredField = {
  type: "checkbox",
  label: "Mandatory",
  name: "field_required",
  required: {
    message: "Please enter a sample data for your field",
    value: false,
  },
  pattern: {
    message: "",
    value: "..*",
    placeholder: "is required",
  },
};

export const renderSelectField = [
  {
    type: "textbox",
    label: "Label",
    name: "label",
    required: {
      message: "Please enter the label for your field",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];

export const renderDependentFieldList = [
  {
    type: "textbox",
    label: "Field Name",
    name: "label",
    required: {
      message: "Please enter the label",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
  {
    type: "textbox",
    label: "Subcategory",
    name: "subcategory",
    required: {
      message: "Please enter the subcategory name",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
  {
    type: "textbox",
    label: "Items",
    name: "items",
    required: {
      message: "Please enter the item name",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];

export const renderDefaultFields = [
  {
    type: "textbox",
    label: "Label",
    name: "label",
    required: {
      message: "Please enter the label for your field",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];

export const fieldList = [
  {
    id: 1,
    type: "text",
    sampleData: "janeDoe",
    label: "Text",
  },
  {
    id: 2,
    type: "select",
    sampleData: "select",
    label: "Dropdown",
  },
  {
    id: 3,
    type: "checkbox",
    sampleData: "yes",
    label: "Checkbox",
  },
  {
    id: 4,
    type: "number",
    sampleData: 1,
    label: "Number ",
  },
  {
    id: 5,
    type: "date",
    sampleData: Date.now(),
    label: "Date ",
  },
  {
    id: 6,
    type: "radio",
    sampleData: "janeDoe",
    label: "Radio button",
  },
  {
    id: 7,
    type: "dependent",
    sampleData: "dependent",
    label: "Dependent Field",
  },
  {
    id: 8,
    type: "textarea",
    sampleData: "This is the MultiLine Text Area",
    label: "MultiLine Text",
  },
];

export const optionsField = [
  {
    type: "textbox",
    label: "Option",
    // name: "label",
    required: {
      message: "Please enter the label for your field",
      value: true,
    },
    placeholder: "",
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];
