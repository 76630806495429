import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import moment from "moment";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import CustomDatePicker from "./CustomRangePicker";
import { extendMoment } from "moment-range";
import { useAppSelector } from "state/store";
const originalMoment1: any = originalMoment;
const moments = extendMoment(originalMoment1);
const customDateOptions = [
  { value: "anytime", label: "Any time" },
  { value: "within5", label: "Within 5 minutes" },
  { value: "within10", label: "Within 10 minutes" },
  { value: "within1day", label: "Within 1 day" },
  { value: "today", label: "Today" },
  { value: "thismonth", label: "This month" },
  { value: "thisweek", label: "This week" },
  { value: "yesterday", label: "Yesterday" },
  { value: "30days", label: "Last 30 days" },
  { value: "custom", label: "Custom" },
];

const Example = ({ setDate, isClear, setClear }) => {
  const today = moment();
  const [selectedDateOption, setSelectedDateOption] = useState(null);

  const [value, setValue] = useState(moments.range(today.clone().subtract(7, "days"), today.clone()));

  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const resetCalendar = useAppSelector((state) => state.adminEventLogs.resetCalendar);
  const dafaultDate = useAppSelector((state) => state.adminEventLogs.filters);
  const onSelect = (value, states) => {
    setValue(value);
    setDatePickerVisible(false);
    setDate(value);
  };

  const { control, handleSubmit } = useForm();

  const handleDateOptionChange = (selectedOption) => {
    setSelectedDateOption(selectedOption);
    if (selectedOption.value === "custom") {
      setDatePickerVisible(true);
      setDate(getDateRange(selectedOption.value));
    } else {
      setDatePickerVisible(false);
      setDate(getDateRange(selectedOption.value));
    }
  };
  const handle = () => {
    setDatePickerVisible(false);
  };
  const getDateRange = (selectedValue) => {
    const startOfDay = moment().startOf("day");

    switch (selectedValue) {
      case "within5":
        return { start: moment(today).subtract(5, "minutes"), end: today, name: "Within 5 minutes" };
      case "within10":
        return { start: moment(today).subtract(10, "minutes"), end: today, name: "Within 10 minutes " };
      case "within1day":
        return { start: moment(today).subtract(1, "day"), end: today, name: "Within 1 day " };
      case "today":
        return { start: startOfDay, end: today, name: "Today" };
      case "thismonth":
        return { start: moment(today).startOf("month"), end: today, name: "This Month" };
      case "thisweek":
        return { start: moment(today).startOf("week"), end: today, name: "This Week" };
      case "yesterday":
        return {
          start: moment(today).subtract(1, "day").startOf("day"),
          end: moment(today).subtract(1, "day").endOf("day"),
          name: "Yesterday",
        };
      case "30days":
        return { start: moment(today).subtract(30, "days"), end: today, name: "30 days" };
      case "custom":
        return {
          ...value,
          name: "custom",
        };

      default:
        return null;
    }
  };
  useEffect(() => {
    if (dafaultDate.date?.name) {
      setSelectedDateOption({
        label: dafaultDate.date?.name ? dafaultDate.date?.name : "Select your time frame",
        value: dafaultDate.date?.name ? dafaultDate.date?.name : "Select your time frame",
      });
    } else if (dafaultDate.date?.end) {
      setSelectedDateOption({
        label: `${dafaultDate.date?.start?.format("DD-MM-YYYY")} & ${dafaultDate?.date?.end?.format("DD-MM-YYYY")}`,
        value: dafaultDate?.date?.start?.format("DD-MM-YYYY") & dafaultDate?.date?.end?.format("DD-MM-YYYY"),
      });
    } else {
      setSelectedDateOption({ label: "Any time" });
    }
  }, [dafaultDate.date?.name]);
  useEffect(() => {
    if (isClear) {
      setSelectedDateOption({ value: "anytime", label: "Any time" });
      setClear(false);
    }
  }, [isClear]);
  const renderSelectionValue = () => {
    const dateRange = selectedDateOption ? getDateRange(selectedDateOption.value) : dafaultDate.date;
    if (dateRange && dateRange.start && dateRange?.end) {
      return (
        <div className="">
          <div className="form-label inline-block mb-2 text-gray-700 text-sm mt-2">Select your time frame</div>
          <div></div>

          {/* {selectedDateOption.value == "custom" ? (
            <>
              {value.start.format("YYYY-MM-DD")}-{value.end.format("YYYY-MM-DD")}
            </>
          ) : (
            <>
              {dateRange.start.format("YYYY-MM-DD HH:mm:ss")} - {dateRange.end.format("YYYY-MM-DD HH:mm:ss")}
            </>
          )} */}
        </div>
      );
    }
    return <div className="form-labelinline-block mb-2 text-gray-700 text-sm mt-2">Select your time frame</div>;
  };
  const customDate = selectedDateOption?.value == "custom" && (
    <>
      {value.start.format("YYYY-MM-DD ")}&{value.end.format("YYYY-MM-DD ")}
    </>
  );

  return (
    <div>
      {renderSelectionValue()}
      <Controller
        control={control}
        name="selectedDateOption"
        defaultValue={selectedDateOption}
        render={({ field }) => (
          <Select
            {...field}
            onMenuOpen={handle}
            options={customDateOptions}
            onChange={(selectedOption) => handleDateOptionChange(selectedOption)}
            value={
              selectedDateOption?.value === "custom"
                ? [
                    { label: customDate, value: customDate },
                    { label: customDate, value: customDate },
                  ]
                : selectedDateOption
            }
          />
        )}
      />

      {isDatePickerVisible && (
        // <div className=" w-full h-full  z-50 ">
        <div className="rounded-lg w-64 h-full  z-50 ml-[-2.5rem]">
          <CustomDatePicker
            isVisible={isDatePickerVisible}
            value={value}
            onSelect={onSelect}
            resetCalendar={resetCalendar}
          />
        </div>
        // </div>
      )}
    </div>
  );
};

export default Example;
