import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { Options } from "common/components/FormFields/types";
import { useAppDispatch } from "state/store";
import { createOptions, setShowModal, setShowOptionModal } from "state/form-builder/slices";

const CreateOptions: React.FC<Options | any> = ({
  choices,
  label,
  currentField,
  defaultValue,
  pattern,
  name,
  required,
  register,
  errors,
}) => {
  const dispatch = useAppDispatch();

  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  return (
    <div>
      {required ? (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label ? label : name} <span className="text-red-500 ">*</span>
        </label>
      ) : (
        <label htmlFor={name} className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
          {label ? label : name}
        </label>
      )}
      <button
        onClick={(e) => {
          e.preventDefault();
          if (!currentField.options.length) dispatch(createOptions());
          dispatch(setShowModal(false));
        }}
        placeholder="Choose an option"
        value={defaultValue}
        className="form-select flex appearance-none justify-center border items-center border-gray-500 w-full px-2 py-1 text-base
					font-normal text-gray-300 bg-gray-600 bg-clip-padding bg-no-repeat h-11
					rounded-sm transition ease-in-out m-0 aria-label=Default select example"
        {...register(name, getFormConfigs())}
      >
        <p> Create Your Options</p>
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mt-1" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
      />
    </div>
  );
};

export default CreateOptions;
