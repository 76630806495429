import MultipleSelectField from 'common/components/FormFields/MultipleSelect';
import { Choice } from 'common/components/FormFields/types';
import { useAppSelector } from 'state/store';
import { Field } from 'utils/appsUtils';
import { flatSourceOutputFields } from 'utils/konnectorUtils';

const SourceField: React.FC<any> = ({ register, index, watchField, errors, control }) => {
	const field = { propName: 'Parse_1' + index, propType: 'string', isRequired: true };
	const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
	const getSourceFields = (state) => {
		const triggerSourceFields = state[konnectorState].data.trigger.outputFields.schema;

		const parseSourceFields = state[konnectorState].data.parseEngine.length
			? state[konnectorState].data.parseEngine.map((lookup) => lookup.outputFields.schema)
			: [];
		return [triggerSourceFields, ...parseSourceFields];
	};

	// let sourceFields: any = useAppSelector(getSourceFields);
	// const fieldValue = watchField(`__field_source_type`);
	// // const getChoices = (): Choice[] => {
	// //   sourceFields = sourceFields.map((e) => {
	// //     return e.eventResponse.data
	// //       .filter((field) => field.propType !== "array" && field.propType !== "date")
	// //       .map((field) => ({ label: field.label || field.propName, value: field.propName }));
	// //   });
	// //   let sourceFields2 = [];
	// //   sourceFields.forEach((item) => {
	// //     sourceFields2.push(...item);
	// //   });
	// //   return sourceFields2;
	let sourceFields: any = useAppSelector(getSourceFields);
	const fieldValue = watchField(`source_id`);

	const getChoices = (): any => {
		const findSourceFields = sourceFields.find((item) => item.sourceId === fieldValue);
		return findSourceFields
			? flatSourceOutputFields(findSourceFields.eventResponse.data)
					.filter((field) => field.propType !== 'array' && field.propType !== 'date')
					.map((field) => ({ label: field.label || field.propName, value: field.propName }))
			: [];
		// sourceFields = sourceFields.map((e) => {
		//   return e.eventResponse.data
		//     .filter((field) => field.propType !== "array" && field.propType !== "date")
		//     .map((field) => ({ label: field.label || field.propName, value: field.propName }));
		// });
		// let sourceFields2 = [];
		// sourceFields.forEach((item) => {
		//   sourceFields2.push(...item);
		// });
		// return sourceFields2;
	};

	const getFieldOptions = () => {
		return new Field(field).format();
	};
	return (
		<div className=" flex w-[50%]">
			<MultipleSelectField
				control={control}
				placeholder="Choose source field"
				choices={getChoices()}
				{...getFieldOptions()}
				name={`Source_field`}
				register={register}
				errors={errors}
			/>
		</div>
	);
};
export default SourceField;
