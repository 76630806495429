import TextField from "./Text";
import CheckBoxField from "./Checkbox";
import SingleSelectField from "./SingleSelect";
import CreateOptions from "./CreateOptions";
import FileField from "./File";

const RenderField: React.FC<any> = ({ field, register, errors }) => {
  switch (field.type) {
    case "textbox | textarea":

    case "textbox": {
      return (
        <TextField
          {...field}
          label={field.label}
          key={field.name}
          register={register}
          isDisabled={false}
          errors={errors}
          placeholder={field.placeholder}
        />
      );
    }
    case "checkbox": {
      return (
        <CheckBoxField
          {...field}
          label={field.label}
          key={field.name}
          register={register}
          isDisabled={false}
          errors={errors}
        />
      );
    }
    case "file":
      return (
        <FileField
          {...field}
          label={field.label}
          key={field.name}
          register={register}
          isDisabled={false}
          errors={errors}
        />
      );
    default:
      // return "<p>Field not found</p>";
      return <p></p>;
  }
};

export default RenderField;
