import Badge from "common/components/Badge";
import { BadgeTypes } from "common/components/Badge/types";
import { FormIcon, WebhookIcon } from "common/components/Icons";
import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import { KonnectorType } from "interfaces/pages/create-new-konnector";
import moment from "moment";
import { Item } from "pages/EventLogs/types";
import { RepeaterIcon } from "pages/KonnectorPage/components/ConfigureKonnector/KonnectifyEngine/Icons/RepeaterIcon";
import React from "react";
import { Link } from "react-router-dom";

interface EventItemProps {
  eventLog: Item;
  konnectorData: any;
}
const MAX_LENGTH = 35;

const EventItem: React.FC<EventItemProps> = ({ eventLog, konnectorData }) => {
  const getHumanReadableTime = (datetime) => moment(datetime).fromNow();

  // const data = konnectorData && konnectorData.konnectorApps.find((konnector) => konnector.appSelectorType === "source");

  const iconUrl = konnectorData && konnectorData.konnectorApps.map((konnector) => konnector.iconUrl);
  const konnectorType = konnectorData && konnectorData.konnectorType;
  console.log(konnectorType);

  const renderAppIcons = () => {
    return iconUrl ? (
      iconUrl.map((app, index) => {
        return app ? (
          <img className=" ml-2 mr-2" src={app} key={index} alt="" width="30" height="35" />
        ) : (
          <>
            {konnectorType !== KonnectorType.Form ? <WebhookIcon classNameOp={"h-8 w-8 rounded-md"} /> : <FormIcon />}
          </>
        );
      })
    ) : (
      <Badge type={BadgeTypes.Error} text={"deleted"} />
    );
  };
  return (
    <tr className="">
      <td className="px-6 pr-56 py-4 text-sm font-medium  text-gray-800 whitespace-nowrap">
        <div className="flex">
          {eventLog.trigger.konnectorName.length > MAX_LENGTH ? (
            <Link
              to={"/event-logs/" + eventLog.id}
              className="hover:text-blue-400 "
            >{`${eventLog.trigger.konnectorName.substring(0, MAX_LENGTH)}...`}</Link>
          ) : (
            <Link to={"/event-logs/" + eventLog.id} className="hover:text-blue-400 w-1/2">
              {eventLog.trigger.konnectorName}
            </Link>
          )}
        </div>
      </td>
      <td className=" py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
        <div className="flex -ml-2 space-x-2 w-1/2">{renderAppIcons()}</div>
      </td>

      <td className=" py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{eventLog.id}</td>
      <td className="px-4 py-4 text-sm  text-gray-800 whitespace-nowrap text-right">
        {eventLog.taskExecuted ? (
          <>
            <span className="ml-2  text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 ">
              {eventLog.taskExecuted}
            </span>
          </>
        ) : (
          <>
            <span className="ml-2  text-gray-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-sm ">0</span>
          </>
        )}
      </td>
      <td className="px-6 py-4 pl-10 text-sm text-left text-gray-800 whitespace-nowrap">
        Created {getHumanReadableTime(eventLog.dtUpdated)}
      </td>
      <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
        {eventLog.status !== BadgeTypes.Data_not_found ? (
          <Badge type={eventLog.status} text={eventLog.status} />
        ) : (
          <Badge type={BadgeTypes.Info} text={"Data not found"} />
        )}
      </td>
      <td className="px-2 py-4 text-sm text-gray-800 whitespace-nowrap">
        <a href={"/event-logs/" + eventLog.id}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            className="w-4 h-4 cursor-pointer"
          >
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
          </svg>
        </a>
      </td>
    </tr>
  );
};

export default EventItem;
