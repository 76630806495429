import React from "react";
export const DragDropIcon: React.FC = () => {
  return (
    <svg className="h-20 w-20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 0H0V2H2V0ZM16 4H18V10H16V6H6V16H10V18H4V4H16ZM4 0H6V2H4V0ZM2 4H0V6H2V4ZM0 8H2V10H0V8ZM2 12H0V14H2V12ZM8 0H10V2H8V0ZM14 0H12V2H14V0ZM12 14V12H18V14H16V16H14V18H12V14ZM16 16V18H18V16H16Z"
        fill="#6B7280"
      />
    </svg>
  );
};
