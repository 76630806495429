import Badge from "common/components/Badge";
import { BadgeTypes } from "common/components/Badge/types";
import { AppObject } from "pages/Apps/types";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { PrefilData } from "state/Embedded-markteplace/AdminSettings/slices";
import { useAppDispatch, useAppSelector } from "state/store";
const AppItem: React.FC<any> = ({ app }) => {
  const configrationStatus: any = useAppSelector((state) => state.adminSettings.SetConfigured);
  const adminSettingss = useAppSelector((state) => state.adminSettings);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentUser.portalConfigration) {
      dispatch(PrefilData(currentUser.portalConfigration));
    }
  }, [currentUser]);
  return (
    <Link
      to={app.type == "Customization-end-user-marketplace" && `/Admin/${app.type}/Customize`}
      className="flex cursor-pointer space-x-3 border items-center bg-white hover:bg-gray-100 rounded-md p-2"
    >
      <div className="w-10">
        <img src={app.icon} />
      </div>
      <div className="space-y-2">
        {configrationStatus.id === app.id ? (
          <>
            <span className="flex justify-end ">
              <Badge type={BadgeTypes.Success} text={"Live"} />
            </span>
          </>
        ) : (
          <></>
        )}
        <h3 className="text-sm font-semibold">{app.name}</h3>
        <p className="text-sm font-light ">{app.description}</p>
      </div>
    </Link>
  );
};

export default AppItem;
