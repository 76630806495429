import React from "react";
import { getEventLogsSortBy } from "state/event-logs/selectors";
import { updateSortBy } from "state/event-logs/slices";
import { useAppDispatch, useAppSelector } from "state/store";

const SortBy: React.FC = () => {
  const sortBy = useAppSelector(getEventLogsSortBy);
  const dispatch = useAppDispatch();
  const onChangeHandler = (e) => {
    const value = e.target.value;
    dispatch(updateSortBy(value));
  };
  return (
    // <><button id="dropdownRadioButton" data-dropdown-toggle="dropdownRadio" className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-1.5" type="button">
    //   <svg className="w-3 h-3 text-gray-500 dark:text-gray-400 mr-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
    //     <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
    //   </svg>
    //   Last 30 days
    //   <svg className="w-2.5 h-2.5 ml-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
    //     <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
    //   </svg>
    // </button>
    // </>
    <div className="">
      <select
        className="inline-flex items-center text-gray-500 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 font-medium rounded-lg text-sm px-3 py-1.5 h-10"
        onChange={onChangeHandler}
        value={sortBy}
      >
        <option value="" disabled>Sortby</option>
        <option value="status">{sortBy === "status" ? "Sortby: Status" : "Status"}</option>
        <option value="dtCreated">{sortBy === "dtCreated" ? "Sortby: Created" : "Created"}</option>
      </select>
    </div>
  );
};

export default SortBy;
