import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { addParseConfig } from 'state/edit-konnector/slices';
import { KonnectorState } from 'state/konnectors/types';
import { StatusType } from 'state/new-konnector/types';
import {
	addCondition,
	editParse,
	editParseData,
	reset as resetData,
	saveDataMappingSucces,
	saveParseDataMapping,
} from 'state/parseEngine/slices';
import { useAppSelector } from 'state/store';
import TargetFields from '../Action/DataMapping/TargetFields';
import ConditionField from './ParsingCondition/ConditionValues';
import RegexType from './RegexType/Regex';
import Drag from './RegexType/Regex/Drag';
import RegexPattern from './RegexType/Regex/Drag/regexPattern';
import SourceField from './SourceField';
import SourceType from './SourceType';
const ParseEngine: React.FC<any> = (): JSX.Element => {
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
		setValue,
		control,
		getValues,
		reset,
	} = useForm();
	const conditions = useAppSelector((state) => state.parseEngine.conditions);
	const rawDataMapping = useAppSelector((state) => state.parseEngine.rawDataMapping);
	const saveDataMappingStatus = useAppSelector((state) => state.parseEngine.saveDataMapping);
	const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
	const editParses = useAppSelector((state) => state[konnectorState].editParse);
	const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
	const onSubmit = (data) => {
		dispatch(saveParseDataMapping({ data: data }));
	};
	const getParseConfig = (state) => {
		const findRuleIndex = state[konnectorState].data.parseEngine.findIndex(
			(action) => action.id === currentActionId
		);
		return state[konnectorState].data.parseEngine[findRuleIndex];
	};
	const ruleData = useAppSelector(getParseConfig);
	useEffect(() => {
		if (rawDataMapping.data) {
			reset(rawDataMapping.data);
		}
		if (rawDataMapping && saveDataMappingStatus === StatusType.loading) {
			dispatch(saveDataMappingSucces());
		}
	}, [saveDataMappingStatus, rawDataMapping]);
	useEffect(() => {
		if (editParses === true) {
			if (KonnectorState.NewKonnectorState) {
				dispatch(editParse(ruleData));
			}
			dispatch(editParseData(ruleData));
		}
	}, [editParses]);
	const renderConditions = () => {
		return conditions.map((conditions, index) => (
			<ConditionField
				index={index}
				id={conditions.id}
				watchField={watch}
				register={register}
				getValues={getValues}
				errors={errors}
				control={control}
				setValue={setValue}
			/>
		));
	};
	const dispatch = useDispatch();
	const addConditions = (e) => {
		e.preventDefault();
		<h2>Conditions</h2>;
		dispatch(addCondition());
	};
	useEffect(() => {
		return () => {
			dispatch(resetData());
		};
	}, []);
	if (!conditions) return <p>error</p>;
	return (
		<>
			<div className=" flex space-x-2 mb-3 items-center w-[100%] h-[100%]"></div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className=" flex flex-row justify-between space-x-6 mb-3 items-center w-[80%] h-[100%]">
					<SourceType
						field={TargetFields}
						register={register}
						errors={errors}
						watch={watch}
						control={control}
					/>
					<SourceField
						watchField={watch}
						register={register}
						errors={errors}
						value={setValue}
						control={control}
					/>
					{/* <RegexType watchField={watch} register={register} errors={errors} value={setValue} control={control} /> */}
				</div>
				<div className="mt-10">
					<h2>Regex pattern </h2>
					<Drag
						watchField={watch}
						register={register}
						errors={errors}
						control={control}
						handleSubmit={handleSubmit}
					/>
				</div>
				<div>{renderConditions()}</div>
				<div className="flex mt-4 ml-auto justify-end">
					<button
						type="submit"
						className=" text-left text-white bg-primary-color border-b-[1px] border-[#E0E4E9] hover:transition-all block px-4 py-2 text-sm"
					>
						Save
					</button>

					<button
						onClick={(e) => addConditions(e)}
						className=" ml-4 text-left text-white bg-primary-color border-b-[1px] border-[#E0E4E9] hover:transition-all block px-4 py-2 text-sm"
					>
						Add condition
					</button>
				</div>
			</form>
		</>
	);
};
export default ParseEngine;
