import React from "react";
import Select from "react-select";
import { KonnectorState } from "state/konnectors/types";
import { useAppSelector, useAppDispatch } from "state/store";
import { WorkFlowState } from "state/workFlows/types";

const SelectKonnectorType: React.FC<any> = ({ konnectorSlice, konnectorState }) => {
  const type: any = useAppSelector((state) => state[konnectorState].data);
  const konnectorType = useAppSelector((state) => {
    if (konnectorState == WorkFlowState.NewWorkFlowState) {
      return state[konnectorState].data.workflowType
    } else {
      return type.konnectorType
    }
  });
  const dispatch = useAppDispatch();

  const onChangeAppHandler = ({ value }) => {
    dispatch(konnectorSlice.updateKonnectorType(value));
  };
  const renderApps = () => {
    return [
      {
        label: "Regular",
        value: "regular",
      },
      {
        label: "Webhook based",
        value: "webhook",
      },
    ];
  };
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Konnector Type</label>
      <Select
        options={renderApps()}
        value={renderApps().filter(function (option) {
          return option.value === konnectorType;
        })}
        onChange={onChangeAppHandler}
      />
    </div>
  );
};

export default SelectKonnectorType;
