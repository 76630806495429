import React from "react";
import { FieldIcons } from "../Icons/TextBoxIcon";
import { Draggable } from "react-beautiful-dnd";

const FormFieldLabel: React.FC<any> = ({ field, index }) => {
  return (
    <>
      <Draggable draggableId={field.id.toString()} index={index} key={field.id.toString()}>
        {(provided, snapshot) => (
          <div
            className="text-lg rounded text-left flex items-center p-1 space-x-2 text-gray-600"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <div className="bg-blue-100 p-2 rounded">
              <FieldIcons type={field.type} />
            </div>
            <div>{field.label}</div>
          </div>
        )}
      </Draggable>
    </>
  );
};

export default FormFieldLabel;
