import { Link } from "react-router-dom";
import { KonnectorState } from "state/konnectors/types";
import { useAppSelector } from "state/store";

const UserMessage: React.FC<any> = ({ actionType }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  let action, actionState, actionLink;
  switch (actionType) {
    case "emailNotVerified":
      action =
        konnectorState === KonnectorState.NewKonnectorState
          ? "You must verify your mail to create a new konnector"
          : "You must verify your mail to edit this konnector";
      actionState = "Verify your email";
      actionLink = "";
      break;
    case "trialExpired":
      action = "Your trial has expired, please update your plan";
      actionState = "Upgarde your plan";
      actionLink = "/payments";
      break;
    case "planExpired":
      action = "Your current plan has expired, please update your plan";
      actionState = "Upgarde your plan";
      actionLink = "/payments";
      break;
    case "freePlan":
      action = "You cannot create more than 5 konnectors in free plan";
      actionState = "Upgarde your plan";
      actionLink = "/payments";
      break;
    case "taskExeeded":
      action = "You have reached the your tasks limit, Please upgrade your plan to proceed";
      actionState = "Upgarde your plan";
      actionLink = "/payments";
      break;
    case "konnectorNotFound":
      action = "Requested konnector is not found";
      actionState = "Back to home page";
      actionLink = "/";
      break;
  }

  return (
    <>
      <div className="flex w-full h-[calc(100vh-5rem)] justify-center items-center flex-col">
        <p className="mb-3">{action} </p>
        <Link
          to={actionLink}
          className="text-white  bg-red-800 hover:bg-[#1f324e] font-medium rounded-sm text-sm px-5 py-2 text-center mr-2 mb-6 hover:transition-all"
        >
          {actionState}
        </Link>
      </div>
    </>
  );
};

export default UserMessage;
