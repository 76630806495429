import { StatusType } from "state/auth/types";
import { KonnectorAppState, AppRole, InitialState, ParentProps } from "./types";
export const getTriggerDetails = (state): KonnectorAppState<AppRole.Source> => state.editKonnector.data.trigger;
export const getTargetDetails = (state, parentId): KonnectorAppState<AppRole.Target> => {
  const repeaterId = state.editKonnector.data.repeater.currentActionId;
  const currentActionId = state.editKonnector.currentActionId;
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  let actionData =
    parentId === ParentProps.Repeater ? state.editKonnector.data.repeater.actions : state.editKonnector.data.actions;
  return actionData.find((action) => action.id == isRepeaterCurrentActionId);
};

export const getLookupDetails = (state, data): KonnectorAppState<AppRole.Lookup> => {
  let lookupdata =
    data.parentId === ParentProps.Repeater
      ? state.editKonnector.data.repeater.lookups
      : state.editKonnector.data.lookups;
  const currentActionId = state.editKonnector.currentActionId;
  const repeaterId = state.editKonnector.data.repeater.currentActionId;
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  return lookupdata.find((lookup) => lookup.id == isRepeaterCurrentActionId);
};

export const getEditKonnectorDetails = (state): InitialState => state.editKonnector;
export const getEditKonnectorStatus = (state): StatusType => state.editKonnector.status;
