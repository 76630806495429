import React, { useState } from "react";
import ViewJson from "../../ViewJSon";
import RepeaterSummary from "./RepeaterItem";
import { RepeaterIcon } from "pages/KonnectorPage/components/ConfigureKonnector/KonnectifyEngine/Icons/RepeaterIcon";

const Repeaterdetails: React.FC<any> = ({ actions, actionData, dtUpdated }) => {
    const [state, setState] = useState(false);
    const handleActionClick = () => {
        setState(true);
    };
    const iconUrl = () => {
        return <RepeaterIcon />
    };
    return (
        <>
            {actions && (
                <RepeaterSummary
                    handleActionChange={() => handleActionClick()}
                    iconUrl={() => iconUrl()}
                    appEventId={actions?.action[0].name ? actions?.action[0].name : actions?.action[0].name}
                    status={actions.status}
                    dtUpdated={dtUpdated}
                    outputFields={actions}
                    title={actionData?.appEventId}
                />
            )}
            {state && (
                <ViewJson
                    visible={state}
                    setViewOutputFields={setState}
                    outputFields={actions}
                    title={actionData?.appEventId ? actionData?.appEventId : actions.name}
                />
            )}
        </>
    );
};

export default Repeaterdetails;
