import { FormIcon, WebhookIcon } from "common/components/Icons";
import { ActionType } from "interfaces/pages/create-new-konnector";
import { KonnectorApp, KonnectorType } from "pages/KonnectorList/types";
import {
  ParseEngineIcon,
  RuleEngineIcon,
} from "pages/KonnectorPage/components/ConfigureKonnector/KonnectifyEngine/Icons";
import React from "react";

interface KonnectorAppsProps {
  apps: KonnectorApp[] | any;
  ruleConfig: number;
  konnector: any;
}

const Apps: React.FC<KonnectorAppsProps> = ({ apps, ruleConfig, konnector }) => {
  // const sourceApp = konnector.triggerDefinition.appId;
  // const targetApps = konnector.actionItems.map((e) => {
  //   if (e.actionType === "ruleConfig") {
  //     return "ruleConfig";
  //   }
  //   if (e.actionType === "parseConfig") {
  //     return "parseConfig";
  //   } else return e.appId;
  // });
  // var appslist = [sourceApp, ...targetApps];
  // appslist = appslist.map((e) => {
  //   if (e === "generic") {
  //     return e;
  //   }
  //   const appurl = apps.filter((app) => {
  //     if (app.appId === e) {
  //       return app.iconUrl;
  //     }
  //   });
  //   return appurl.length ? appurl[0].iconUrl : e;
  // });

  // apps = appslist;
  // const renderConnectedApps = () => {
  //   return apps.map((app, i) => (
  //     <div className="flex items-center mr-2">
  //       {app === "generic" ? (
  //         konnector.konnectorType === KonnectorType.WebHook ? (
  //           <WebhookIcon url={konnector.webhookUrl.url} />
  //         ) : (
  //           <>
  //             <FormIcon url={konnector.webhookUrl.id} />
  //           </>
  //         )
  //       ) : app !== "ruleConfig" && app !== "parseConfig" ? (
  //         <img className="w-[30px] mr-2" src={app} alt="" />
  //       ) : app == "ruleConfig" ? (
  //         <RuleEngineIcon className={"h-8 w-8 text-gray-500"} />
  //       ) : app == "parseConfig" ? (
  //         <ParseEngineIcon className={"h-8 w-8 text-gray-500"} />
  //       ) : null}
  //       {i === 0 && (
  //         <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#394251">
  //           <path
  //             fillRule="evenodd"
  //             d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
  //             clipRule="evenodd"
  //           />
  //           <path
  //             fillRule="evenodd"
  //             d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
  //             clipRule="evenodd"
  //           />
  //         </svg>
  //       )}
  //     </div>
  //   ));
  // };
  const serializeOldKonnectorVersion = (konnectorV1Data) => {
    const targetApps: any = konnectorV1Data?.actionItems?.map((e) => {
      switch (e.actionType) {
        case ActionType.RuleConfig:
          return "https://storage.googleapis.com/konnectify-form-files/logo/filter.svg"
        case ActionType.Lookup:
        case ActionType.Action:
          let iconUrl = konnectorV1Data.konnectorApps.find((konn) => e.actionOrderId === konn.actionOrderId)?.iconUrl;
          return iconUrl
        case ActionType.ParseConfig:
          return "https://storage.googleapis.com/konnectify-form-files/logo/parse_engine.svg"
      }
    });

    let triggerApp;
    switch (konnectorV1Data.konnectorApps[0].appEventId) {
      case "form-data":
      case "webhook":
        triggerApp = "https://storage.googleapis.com/konnectify-form-files/logo/webhook.svg"
        break;
      default:
        triggerApp = konnectorV1Data.konnectorApps[0].iconUrl
        break;

    }

    const app_meta = [triggerApp, ...targetApps];

    return <>
      {
        app_meta.map((app, i) => {
          return <><div className="flex items-center mr-2"><img className="h-5 w-5 object-contain" src={app} />
            {i === 0 && (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="#394251">
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div></>
        })
      }
    </>
  }
  return <div className="flex items-center  ">{serializeOldKonnectorVersion(konnector)}</div>;
};

export default Apps;
