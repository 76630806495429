import React from "react";
import { FieldIcons } from "../Icons/TextBoxIcon";
import { useAppDispatch, useAppSelector } from "state/store";
import { TrashIcon } from "@heroicons/react/outline";
import { deleteFormField, editField, setShowModal } from "state/form-builder/slices";
import { EditIcon } from "../Icons/EditIcon";
import { Draggable } from "react-beautiful-dnd";
import FieldSettings from "../FormFields/index";
import { ActionMode, FormFieldData } from "state/new-konnector/types";
import { DraggableIcon } from "../Icons/DragableIcon";
import { fieldList } from "../constants/fieldList";

interface FormFieldProps {
  field: FormFieldData;
  index: number;
}

const FormFields: React.FC<FormFieldProps> = ({ field, index }) => {
  const dispatch = useAppDispatch();
  const showModal = useAppSelector((state) => state.formBuilder.showModal);
  const fieldLength = useAppSelector((state) => state.formBuilder.formFields).length;
  const closeModal = () => {
    dispatch(setShowModal({ modal: false, id: field.fieldId }));
  };
  return (
    <Draggable draggableId={field.fieldId} index={index} key={field.fieldId} isDragDisabled={showModal}>
      {(provided, snapshot) => (
        <div
          className={`w-full flex flex-col justify-center items-center transition-all duration-200 ${
            field.actionMode === ActionMode.Edit || field.actionMode === ActionMode.Create ? "" : ""
          }`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div
            className={` ${
              field.actionMode === ActionMode.Edit || field.actionMode === ActionMode.Create
                ? `z-50 bg-white shadow-md ${fieldLength > 10 ? "w-1/2 fixed right-24 top-48" : " w-[95%]"}`
                : "w-4/5 bg-white"
            } h-auto  items-center  rounded text-lg text-gray-500 shadow-md 
             transition-all`}
          >
            <div
              className={`flex items-center  w-full p-2  justify-between ${
                (field.actionMode === ActionMode.Edit || field.actionMode === ActionMode.Create) && "z-50 "
              }`}
            >
              <div className="flex items-center space-x-1 ">
                <DraggableIcon />
                <div className="p-1 bg-gray-100 rounded-sm">
                  <FieldIcons type={field.inputType} />
                </div>

                <span className="ml-3 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                  {field.label || fieldList.find((f) => f.type === field.inputType).label}
                </span>
                <span className="text-red-600">{field.config.requires ? "*" : ""}</span>
              </div>
              <div>
                {field.actionMode === ActionMode.View ? (
                  <button
                    onClick={() => {
                      dispatch(editField(field.fieldId));
                    }}
                  >
                    <EditIcon />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="cursor-pointer "
                    data-modal-toggle="account-modal"
                    onClick={() => closeModal()}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                )}
                <button className="cursor-pointer ml-3">
                  <TrashIcon className="h-5 w-5" onClick={() => dispatch(deleteFormField(field.fieldId))} />
                </button>
              </div>
            </div>
            {field.actionMode !== ActionMode.View && (
              <FieldSettings field={field} showModal={showModal} closeModalHandler={closeModal} />
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default FormFields;
