import React from "react";

const Trigger: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 58.17 57.89"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style>
            {`
      .action-1 {
        fill: #fff;
      }

      .action-2 {
        opacity: .85;
      }`}
          </style>
        </defs>
        <g id="Layer_10" data-name="Layer 10">
          <g id="sales">
            <g className="action-2">
              <path
                className="action-1"
                d="M33.94,57.89c0-.36,0-.64,0-.92,0-6.58,0-13.15,0-19.73,0-.94,0-.94-.95-.94-4.14,0-8.28,0-12.42,0-.24,0-.47,0-.71-.02-.33-.03-.39-.21-.22-.48,.1-.16,.2-.32,.31-.48C27.67,23.75,35.38,12.18,43.09,.61c.04-.06,.07-.12,.12-.18,.13-.16,.2-.48,.42-.43,.3,.07,.17,.4,.17,.61,0,6.6,0,13.2,0,19.8q0,1.13,1.13,1.13c4.14,0,8.28,0,12.42,0,.27,0,.63-.13,.78,.17,.13,.25-.16,.47-.3,.67-7.75,11.63-15.5,23.26-23.26,34.89-.14,.21-.22,.47-.63,.62Zm5.02-16.36c.21-.23,.28-.29,.33-.37,3.12-4.68,6.25-9.36,9.37-14.05,.09-.13,.26-.27,.18-.44-.1-.21-.34-.12-.51-.12-2.89,0-5.77-.03-8.66,.01-.73,0-.92-.24-.92-.94,.03-2.84,.02-5.68,0-8.51,0-.25,.08-.51-.12-.83-3.32,4.98-6.62,9.92-9.92,14.88,.33,.18,.6,.11,.85,.11,2.81,0,5.63,0,8.44,0,.93,0,.94,.01,.94,.96,0,2.58,0,5.16,0,7.73,0,.44,0,.89,0,1.54Z"
              />
              <path
                className="action-1"
                d="M17.09,40.95c3.74,0,7.48,.02,11.21-.01,.73,0,.95,.23,.92,.94-.05,1.06-.05,2.13,0,3.19,.04,.73-.22,.93-.93,.93-5.16-.02-10.32-.01-15.47-.01-2.37,0-4.73-.02-7.1,.01-.69,0-.91-.23-.89-.91,.04-1.11,.04-2.22,0-3.34-.02-.63,.22-.82,.83-.81,3.81,.02,7.62,0,11.43,0Z"
              />
              <path
                className="action-1"
                d="M13.38,26.56c-2.55,0-5.11-.02-7.66,.01-.69,0-.91-.21-.89-.89,.04-1.11,.03-2.22,0-3.33-.02-.57,.16-.81,.76-.81,5.2,.01,10.4,.01,15.6,0,.58,0,.79,.22,.77,.8-.03,1.16-.03,2.32,0,3.47,.02,.6-.23,.77-.79,.76-2.6-.02-5.2,0-7.8,0Z"
              />
              <path
                className="action-1"
                d="M7.31,36.3c-2.15,0-4.3-.01-6.45,0-.63,0-.89-.19-.86-.85,.05-1.16,.03-2.32,0-3.47-.01-.52,.18-.71,.7-.7,4.44,.01,8.88,.01,13.33,0,.48,0,.69,.16,.68,.66-.02,1.2-.03,2.41,0,3.61,.02,.62-.28,.76-.82,.76-2.2-.01-4.39,0-6.59,0Z"
              />
              <path
                className="action-1"
                d="M20.69,16.89c-1.8,0-3.59-.02-5.39,0-.58,0-.81-.19-.8-.78,.03-1.16,.03-2.31,0-3.47-.01-.57,.18-.8,.77-.8,3.59,.02,7.18,.02,10.77,0,.54,0,.76,.18,.75,.74-.03,1.18-.03,2.36,0,3.54,.02,.61-.24,.77-.8,.76-1.77-.02-3.54,0-5.31,0Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default Trigger;
