import React, { useEffect } from "react";
import MultipleSelectField from "common/components/FormFields/MultipleSelect";
import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import { Choice } from "common/components/FormFields/types";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";
import SingleSelectField from "common/components/FormFields/SingleSelect";
import { setFieldType } from "utils/ruleEngineUtils";
import { getSourceFields } from "utils/konnectorStateUtils";
export default function SourceFields({ field, register, control, watch, errors, setInputValue }) {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  // const getSourceFields = (state) => {
  //   const triggerSourceFields = state[konnectorState].data.trigger.outputFields.schema;
  //   const lookupSourceFields = state[konnectorState].data.repeater.lookups.length
  //     ? state[konnectorState].data.repeater.lookups.map((lookup) => lookup.outputFields.schema)
  //     : [];
  //   const parseSourceFields = state[konnectorState].data.parseEngine.length
  //     ? state[konnectorState].data.parseEngine.map((lookup) => lookup.outputFields.schema)
  //     : [];

  //   return [triggerSourceFields, ...lookupSourceFields, ...parseSourceFields];
  // };

  const sourceFields: any = useAppSelector((state) => {
    return getSourceFields(state);
  });
  const [fieldType, fieldSourceId] = watch([field.propName + "__field_type", field.propName + "__field_source_type"]);

  const getChoices = (): Choice[] => {
    const findSourceFields = sourceFields.find((item) => item.sourceId === fieldSourceId);
    if (!findSourceFields) {
      setInputValue(field.propName + "__field_source_type", "");
      return [{ label: "", value: "" }];
    }
    return flatSourceOutputFields(findSourceFields.eventResponse.data)
      .filter((field) => field.propType !== "object" && field.propType !== "array")
      .map((field) => ({ label: field.label || field.propName, value: field.propName }));
  };

  const getFixedChoices = (): Choice[] => {
    if (field.dependentTo) {
      const dependentField = watch(field.dependentTo);
      if (!dependentField) {
        return [{ label: `Please select ${field.dependentTo} first`, value: "kf_req_deps" }];
      }
      return field.propValue[dependentField];
    }
    return field.propValue.map((ele) => ({ label: ele.label, value: ele.value }));
  };
  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    if (!["array", "object"].includes(field.propType)) {
      fieldData.type = setFieldType(fieldData, field.propType);
    }
    return fieldData;
  };

  useEffect(() => {
    if (fieldType === "fixed" && fieldSourceId) {
      setInputValue(field.propName, "");
      setInputValue(field.propName + "__field_source_type", "");
    }
    if (!fieldType) {
      setInputValue(field.propName.toString(), "");
    }
  }, [fieldType]);

  return (
    <div className="flex justify-center w-[30%] self-end">
      <div className="mb-3 w-full">
        {fieldType === "fixed" ? (
          field.propValue && (field.propValue.length > 0 || Object.keys(field.propValue)?.length > 0) ? (
            <SingleSelectField choices={getFixedChoices()} {...getFieldOptions()} register={register} errors={errors} />
          ) : (
            <TextField {...getFieldOptions()} register={register} errors={errors} />
          )
        ) : (
          fieldSourceId && (
            <MultipleSelectField
              choices={getChoices()}
              control={control}
              {...getFieldOptions()}
              register={register}
              errors={errors}
            />
          )
        )}
      </div>
    </div>
  );
}
