import React, { useEffect } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { ActionMode, StatusType } from "state/edit-konnector/types";
import { useParams } from "react-router-dom";
import { setSourceIdLabel } from "utils/konnectorUtils";
import DropdownField from "common/components/Dropdown";

const SelectSource: React.FC<AppsProps> = ({ apps, sourceApp, konnectorSlice }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);

  const repeaterSource = useAppSelector((state) => state[konnectorState].data.repeater.source);

  const dispatch = useAppDispatch();

  const onChangeAppHandler = ({ value }) => {
    return dispatch(konnectorSlice.updateRepeaterSource({ value, id: currentActionId }));

    // const app = getApp(value);
    // dispatch(
    //   konnectorSlice.updateTargetAppLoading({
    //     appId: value,
    //     id: currentActionId,
    //     iconUrl: app.iconUrl,
    //     appName: app.appName,
    //   })
    // );
  };
  const getSourceIds = (state) => {
    let triggerSourceId;
    const hasTriggerRepeater = state[konnectorState].data?.trigger.appDetails.triggers.find((trigger) => trigger.id === state[konnectorState].data?.trigger.eventId).hasRepeater;
    if (hasTriggerRepeater) {
      triggerSourceId = {
        label: setSourceIdLabel(state[konnectorState].data.trigger.outputFields.schema.sourceId),
        value: state[konnectorState].data.trigger.outputFields.schema.sourceId,
      };
    }
    else {
      triggerSourceId = null;
    }
    const lookupSourceIds = state[konnectorState].data.lookups.map((lookups) => {
      const hasRepeater = lookups.appDetails.lookups.find((lookup) => lookup.id === lookups.eventId).hasRepeater;
      if (hasRepeater) {
        return {
          label: setSourceIdLabel(lookups.outputFields.schema.sourceId),
          value: lookups.outputFields.schema.sourceId,
        };
      } else {
        return null
      }
    });
    const parseSourceIds = state[konnectorState].data.parseEngine.map((lookups) => {
      const hasRepeater = lookups.appDetails.lookups.find((lookup) => lookup.id === lookups.eventId).hasRepeater;
      if (hasRepeater) {
        return {
          label: setSourceIdLabel(lookups.outputFields.schema.sourceId),
          value: lookups.outputFields.schema.sourceId,
        };
      } else {
        return;
      }
    });
    const sourceFields = [triggerSourceId, ...lookupSourceIds, ...parseSourceIds].filter((fields) => fields !== null);
    return sourceFields;
  };

  const sourceIds = useAppSelector(getSourceIds);

  const getApps = () => {
    return apps.map((app) => ({ value: app.id, label: app.appName }));
  };

  return (
    <div className="xl:w-72">
      {sourceIds.length != 0 ? <Select
        options={sourceIds}
        value={
          repeaterSource
            ? { label: setSourceIdLabel(repeaterSource), value: repeaterSource }
            : { label: "Select", value: "" }
        }
        onChange={onChangeAppHandler}
      /> : " :  No Source Items"}

      {/* <DropdownField choices={sourceIds} /> */}
    </div>
  );
};

export default SelectSource;
