import React, { useEffect, useRef, useState } from "react";
import { FieldIcons } from "../Icons/TextBoxIcon";
import { Draggable } from "react-beautiful-dnd";
import RenderField from "../FormFields/genericFields";
import { useForm } from "react-hook-form";
import { formSubmitField } from "../constants/formSettings";
import Slider from "@mui/material/Slider";
import { useAppDispatch, useAppSelector } from "state/store";
import { setFormIconAlignment, setFormOnsubmitHandler } from "state/form-builder/slices";
import { BlockPicker, ChromePicker, SketchPicker, TwitterPicker } from "react-color";

const FormSubmitButton: React.FC<any> = ({ field }) => {
  const {
    register,
    formState: { errors },
    reset,
    watch,
    handleSubmit,
  } = useForm();
  const dispatch = useAppDispatch();
  const [logoWidth, setWidth] = useState(50);
  const [logoHeight, setHeight] = useState(50);
  const [showBgcolor, setShowBgcolor] = useState(false);
  const [showTextcolor, setShowTextcolor] = useState(false);
  const [bgColor, setBgcolor] = useState("");
  const [textColor, setTextColor] = useState("");

  const formOnSubmit = useAppSelector((state) => state.formBuilder.formSettings.formSubmitHandler);

  useEffect(() => {
    const subscription = watch(handleSubmit(onSubmit));
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch]);

  const onSubmit = (data) => {
    dispatch(setFormOnsubmitHandler(data));
  };

  const onChangeBgcolor = () => {
    setShowBgcolor(true);
  };
  const textColorHandler = () => {
    setShowTextcolor(true);
  };

  const onChangeColor = (data) => {
    reset({ ...formOnSubmit, bg: data.hex });
    setBgcolor(data.hex);
    // setShowBgcolor(false);
  };

  const onChangeTextColor = (data) => {
    reset({ ...formOnSubmit, textColor: data.hex });
    setTextColor(data.hex);
    // setShowBgcolor(false);
  };

  const handleWidthChange = (e) => {
    setWidth(e.target.value);
    reset({ ...formOnSubmit, size: e.target.value });
  };

  const handleHeightChange = (e) => {
    setHeight(e.target.value);
    reset({ ...formOnSubmit, height: e.target.value });
  };

  useEffect(() => {
    setWidth(parseInt(formOnSubmit.size));
    setBgcolor(formOnSubmit.bg);
    setHeight(parseInt(formOnSubmit.height));
    reset(formOnSubmit);
  }, []);

  const renderFieldList = (fieldList) => {
    console.log({ fieldList });

    return fieldList.map((field) => {
      field.required.value = false;
      return <RenderField field={field} register={register} errors={errors} />;
    });
  };
  return (
    <>
      <div className="flex flex-col w-3/4 mt-4 p-2 space-y-3 h-full">
        <div className="flex w-full space-x-2">
          <div className="w-1/2">{renderFieldList(formSubmitField)}</div>
          <div className=" flex flex-col w-1/2">
            <div className="block mb-1 text-sm font-medium text-gray-500">Button BG color</div>
            <div className="w-full flex h-full space-x-2">
              <button
                className={`w-1/2 mt-1 h-full rounded-sm z-50 ${showBgcolor && "z-50"}`}
                onClick={onChangeBgcolor}
                style={{ background: `${bgColor}` || "#d9d9d9" }}
              >
                {showBgcolor && (
                  <div className="fixed block mt-10 z-50 bg-gray-50 rounded-xl">
                    <TwitterPicker color={bgColor} className="z-50 " onChangeComplete={onChangeColor} />
                  </div>
                )}
                <div className="z-50 text-white">BG color</div>
              </button>
              <button
                className={`w-1/2 mt-1 h-full rounded-sm z-50 ${showBgcolor && "z-50"}`}
                onClick={textColorHandler}
                style={{ background: textColor || "#d9d9d9" }}
              >
                {showTextcolor && (
                  <div className="fixed block mt-10 z-50 bg-gray-50 rounded-xl">
                    <TwitterPicker color={textColor} className="z-50 " onChangeComplete={onChangeTextColor} />
                  </div>
                )}
                <div className="z-50 text-white">Text Color</div>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full">
          <div className="block mb-1 text-sm font-medium text-gray-500">Button Dimensions</div>
          <div className="w-full flex space-x-4 items-center">
            <div className="flex flex-col w-1/3">
              <div className="block mb-1 text-xs font-medium text-gray-500">Width</div>
              <Slider
                size="small"
                defaultValue={50}
                value={parseInt(formOnSubmit.size)}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={handleWidthChange}
                className="ml-2 text-gray-300"
                min={50}
                max={300}
              />
            </div>
            <div className="w-1/3">
              <div className="block mb-1 text-xs font-medium text-gray-500">Height</div>
              <Slider
                size="small"
                defaultValue={50}
                value={parseInt(formOnSubmit.height)}
                aria-label="Small"
                valueLabelDisplay="auto"
                onChange={handleHeightChange}
                className="ml-2 text-gray-300"
                min={30}
                max={300}
              />
            </div>
            <div className="w-1/3">
              <div className="block mb-1 text-xs font-medium text-gray-500">Border Radius</div>
              <input
                type={"number"}
                name={"borderRadius"}
                min={0}
                max={20}
                className={`border-2 rounded p-2 w-full text-xs bg-transparent`}
                {...register("borderRadius")}
              />
            </div>
          </div>
        </div>
        <input type={"number"} name={"size"} className={`hidden`} {...register("size")} />
        <input type={"number"} name={"height"} className={`hidden`} {...register("height")} />
        <input type={"text"} name={"textColor"} className={`hidden`} {...register("textColor")} />
        <input type={"text"} name={"bgColor"} className={`hidden`} {...register("bg")} />

        <div className="w-full">
          <label htmlFor={""} className="block mb-2 text-sm font-medium text-gray-500">
            Alignment
            {/* <span className="text-red-500 ">*</span> */}
          </label>
          <div className="flex space-x-2">
            <label
              className={`w-1/4 rounded border-[1px] relative ${
                formOnSubmit.alignment === "left" ? "bg-gray-200" : ""
              } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
            >
              <div className="absolute right-1 top-1">
                <input
                  type="radio"
                  value="left"
                  checked={formOnSubmit.alignment === "left" ? true : false}
                  onClick={() => {
                    dispatch(setFormIconAlignment("left"));
                  }}
                  {...register(`alignment`)}
                  className="rounded-full border-2 border-gray-300 w-3 h-3"
                />
              </div>
              <p>Left</p>
              <p className="text-xs text-gray-400">aligns at the top left corner</p>
            </label>
            <label
              className={`w-1/4 rounded border-[1px] relative ${
                formOnSubmit.alignment === "center" ? "bg-gray-200" : ""
              } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
            >
              <div className="absolute right-1 top-1">
                <input
                  type="radio"
                  value="center"
                  checked={formOnSubmit.alignment === "center" ? true : false}
                  onClick={() => {
                    dispatch(setFormIconAlignment("center"));
                  }}
                  {...register(`alignment`)}
                  className="rounded-full border-2 border-gray-300 w-3 h-3"
                />
              </div>
              <p className="">center</p>
              <p className="text-xs text-gray-400">aligns at the bottom center</p>
            </label>
            <label
              className={`w-1/4 rounded border-[1px] relative ${
                formOnSubmit.alignment === "right" ? "bg-gray-200" : ""
              } border-gray-200 text-sm p-2 flex flex-col justify-center transition-all`}
            >
              <div className="absolute right-1 top-1">
                <input
                  type="radio"
                  value="right"
                  checked={formOnSubmit.alignment === "right" ? true : false}
                  onClick={() => {
                    dispatch(setFormIconAlignment("right"));
                  }}
                  {...register(`alignment`)}
                  className="rounded-full border-2 border-gray-300 w-3 h-3"
                />
              </div>
              <p className="">Right</p>
              <p className="text-xs text-gray-400">aligns at the bottom right corner</p>
            </label>
          </div>
        </div>

        {
          <div className="w-full h-2/6 max-h-44 flex flex-col">
            <div className="block mb-2 text-sm font-medium text-gray-500">Submit Button Preview</div>

            <div
              className={`p-2 border-2 w-full h-full border-gray-300 border-dashed flex items-center transition-all transform duration-150 justify-${
                formOnSubmit.alignment === "right" ? "end" : formOnSubmit.alignment
              }`}
            >
              <div
                className="text-center  flex items-center justify-center transition-all transform duration-150"
                style={{
                  width: `${logoWidth}px`,
                  height: `${logoHeight}px`,
                  borderRadius: `${formOnSubmit.borderRadius}px`,
                  background: bgColor,
                  color: textColor,
                }}
              >
                {formOnSubmit.text || "submit"}
              </div>
            </div>
          </div>
        }
      </div>
      {(showBgcolor || showTextcolor) && (
        <div
          onClick={() => {
            setShowBgcolor(false);
            setShowTextcolor(false);
          }}
          className="opacity-10 fixed bottom-0  top-0 inset-x-0 z-40 bg-black"
        ></div>
      )}
    </>
  );
};

export default FormSubmitButton;
