import Login from "pages/Login";
import Index from "../pages/Index";
import { RouteProperties } from "./interfaces";

const routes: RouteProperties[] = [
  {
    component: Index,
    exact: true,
    path: "/",
  },
 
];

export default routes;
