import TextField from "common/components/FormFields/TextFieldWithoutRegister";
import React from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { setFieldType } from "utils/ruleEngineUtils";

const SelectInputSchema: React.FC<any> = ({ field, konnectorSlice, register, errors }) => {
  console.log({ errors });
  const dispatch = useAppDispatch();
  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    if (!["array", "object"].includes(field.propType)) {
      fieldData.type = setFieldType(fieldData, field.propType);
    }
    console.log({ fieldData, errors });
    return fieldData;
  };
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const triggerInputschema: any = useAppSelector((state) => state[konnectorState].data.trigger.inputFields);
  const fieldPropValue = field.propValue ? field.propValue : field.propValues;
  const fieldValue = triggerInputschema.dataMapping[field.propName] || "";
  const propValues = field.propValues ? field.propValues : field.propValue;
  const renderFields = propValues?.find((val) => val.value === fieldValue);

  const renderEvents = () => {
    return propValues.map((prop) => {
      return { label: prop.label, value: prop.value };
    });
  };
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">{`Select ${field.label}`}</label>
      {field.propValues?.length ? (
        <Select
          options={renderEvents()}
          value={renderFields}
          onChange={(e: any) => {
            console.log({ e });

            dispatch(
              konnectorSlice.setSourceEventDataMapping({
                field: field.propName,
                value: e.value,
              })
            );
          }}
        />
      ) : (
        <TextField
          {...getFieldOptions()}
          onChange={(e: any) => {
            console.log({ e });

            dispatch(
              konnectorSlice.setSourceEventDataMapping({
                field: field.propName,
                value: e.value,
              })
            );
          }}
        />
      )}
    </div>
  );
};

export default SelectInputSchema;
