import React, { useEffect, useState } from "react";
import Editor from '@monaco-editor/react';
import { useAppSelector } from "state/store";
import { useDispatch } from "react-redux";
import { setCardData, setCardsnippet } from "state/Embedded-markteplace/ThemeSettings/slices";
import { BackIcon } from "common/components/Icons/BackIcon";
import { Check, ChevronLeftIcon, ChevronsUpDown } from "lucide-react"
import { cn } from "lib/utils";
import { Button } from "components/ui/button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "components/ui/popover"
import { Input } from "components/ui/input";
import { Label } from "@headlessui/react/dist/components/label/label";


const statuses: any = [
    {
        value: ".class1",
        label: "class1",
    },
    {
        value: ".class2",
        label: "class2",
    },
    {
        value: ".class3",
        label: "class3",
    },

]


const CardTab: React.FC<any> = ({ types, colors, onColorChange, onChange, onEditorValueChange }) => {
    const [open, setOpen] = useState(false)
    const [error, setError] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState<any | null>(
        null
    )
    const cardData = useAppSelector((state) => state.themeSettings.card)
    const dispatch = useDispatch()
    const [snippet, setSnippet] = useState(false);
    const [editorValue, setEditorValue] = useState(cardData.cardSnippet);
    const [selectedOption, setSelectedOption] = useState(cardData.view);
    const handleRadioButtonChange = (event) => {
        setSelectedOption(event.target.value);
        dispatch(setCardData({ type: "view", value: event.target.value }))
        // dispatch()
    };
    const gridSizes = {
        "2": "grid-cols-2",
        "3": "grid-cols-3",
        "4": "grid-cols-4",
        "5": "grid-cols-5",
        "6": "grid-cols-6"
    };

    const gridClass = gridSizes[cardData.gridsize] || "grid-cols-6";

    const handleEditorChange = (value) => {
        setEditorValue(value);
        if (value.length > 0) {
            dispatch(setCardsnippet("true"))
        } else {
            dispatch(setCardsnippet("false"))
        }
        dispatch(setCardData({ type: "cardSnippet", value }))
        onEditorValueChange(value);
    };

    const handleSnippet = () => {
        setSnippet(true)
    }

    const [localColors, setLocalColors] = useState(colors);

    const handleInputChange = (type, value) => {
        if (type == "gridSize") {
            if (value > 6) {
                setError(true)
            } else {
                setLocalColors((prevColors) => ({
                    ...prevColors,
                    [type.toLowerCase()]: value,
                }));

                dispatch(setCardData({ type: type.toLowerCase(), value }))
                setBackgroundColor(cardData.background);
                onColorChange(type, value);
                setError(false)
            }
        } else {
            setLocalColors((prevColors) => ({
                ...prevColors,
                [type.toLowerCase()]: value,
            }));

            dispatch(setCardData({ type: type.toLowerCase(), value }))
            setBackgroundColor(cardData.background);
            onColorChange(type, value);
        }

    };

    const handleInputChange1 = (type, value) => {
        setLocalColors((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: value,
        }));
        onChange(type, value);
    };

    const handleClick = () => {
        setSnippet(false)
    };

    const [hover, setHover] = useState<any>();
    const [backgroundColor, setBackgroundColor] = useState(cardData.background);


    const handleMouseEnter = () => {
        setHover(true);
        setBackgroundColor(cardData.hover);
    };
    const handleMouseLeave = () => {
        setHover(false);
        setBackgroundColor(cardData.background);
    };
    const data = [1, 1, 1, 1, 1, 4]
    const ListData = [1, 1, 1]
    console.log(cardData.background, "check", backgroundColor, cardData.gridsize)

    return (
        <>
            {/* <div className="flex flex-row">
                <BackIcon></BackIcon>
            </div> */}
            {snippet ? <>
                <div className="flex p-4 bg-gray-100 mb-11 border-slate-500 border-solid">
                    <Button variant="outline" size="icon" onClick={handleClick} className="border-slate-300">
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <div className="flex items-center space-x-4 ml-auto z-10 border-slate-300">
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="w-[150px] justify-start">
                                    {selectedStatus ? <>{selectedStatus.label}</> : <>+ Default CSS Class</>}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="p-0 bg-white" side="right" align="start">
                                <Command>
                                    <CommandInput placeholder="Change CSS..." />
                                    <CommandList>
                                        <CommandEmpty>No results found.</CommandEmpty>
                                        <CommandGroup>
                                            {statuses.map((status) => (
                                                <CommandItem
                                                    key={status.value}
                                                    value={status.value}
                                                    onSelect={(value) => {
                                                        setSelectedStatus(
                                                            statuses.find((priority) => priority.value === value) ||
                                                            null
                                                        )
                                                        setOpen(false)
                                                    }}
                                                >
                                                    {status.label}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </div></div>
                <Editor height="50vh" defaultLanguage="css" defaultValue={editorValue} language="css" theme="bg-primary-color" className="border-solid border-2 border-slate-200 -mt-16" options={{
                    fontSize: 15,
                }} onChange={handleEditorChange} /></>
                : <>

                    <form>
                        <div className="flex flex-row -mt-2 p-2">
                            <Button onClick={handleSnippet} className="bg-slate-50 text-black w-40 text-left ml-auto ">Advanced Settings</Button>
                        </div>

                        <div className="flex flex-row">
                            <div className="flex-1 h-[60vh] bg-transparent space-y-10 overflow-scroll">
                                <div className="flex flex-row ">
                                    <div className="flex flex-col w-40"><span className="font-bold text-xs">View</span>
                                        {/* <RadioGroup defaultValue="comfortable">
    <div className="flex items-center space-x-2">
        <RadioGroupItem value="default" id="r1" />
        <Label htmlFor="r1">Default</Label>
    </div>
    <div className="flex items-center space-x-2">
        <RadioGroupItem value="comfortable" id="r2" />
        <Label htmlFor="r2">Comfortable</Label>
    </div>
    <div className="flex items-center space-x-2">
        <RadioGroupItem value="compact" id="r3" />
        <Label htmlFor="r3">Compact</Label>
    </div>
</RadioGroup> */}

                                        <div className="flex items-center mb-4  mt-5">
                                            <input id="default-radio-1" type="radio" value="Grid" name="default-radio" className="w-4 h-4 text-black bg-gray-100 border-gray-300 " checked={selectedOption === 'Grid'}
                                                onChange={handleRadioButtonChange}></input>
                                            <label className="ms-2 text-sm font-medium text-gray-900">Grid</label>
                                        </div>
                                        <div className="flex items-center">
                                            <input id="default-radio-2" type="radio" value="List" name="default-radio" className="w-4 h-4 text-black bg-gray-100 border-gray-300" checked={selectedOption === 'List'}
                                                onChange={handleRadioButtonChange}></input>
                                            <label className="ms-2 text-sm font-medium text-gray-900">List</label>
                                        </div>

                                    </div>

                                    <div></div>
                                </div>
                                <div className="flex flex-row   space-x-8">
                                    <div className="flex flex-col ">
                                        <span className="font-bold text-xs">Background color</span>
                                        <div className="flex rounded-full mt-5 ">
                                            <span className=" flex rounded-md w-40 border border-gray-300 ">
                                                <input
                                                    type="text"
                                                    className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                                    value={cardData.background}
                                                    onChange={(e) => handleInputChange(types[0], e.target.value)} />
                                                <input
                                                    type="color"
                                                    className="mt-2 mb-2 ml-4 border w-[30%] h-6 bg-transparent mr-2  "
                                                    value={cardData.background}
                                                    onChange={(e) => handleInputChange(types[0], e.target.value)} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="flex flex-col"><span className="font-bold text-xs">Hover color</span>
                                        <div className="flex rounded-full mt-5 ">
                                            <span className=" flex rounded-md w-40 border border-gray-300 ">
                                                <input
                                                    type="text"
                                                    className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                                    value={cardData.hover}
                                                    onChange={(e) => handleInputChange(types[1], e.target.value)} />
                                                <input
                                                    type="color"
                                                    className="mt-2 mb-2 ml-4 border w-[30%] h-6 bg-transparent mr-2"
                                                    value={cardData.hover}
                                                    onChange={(e) => handleInputChange(types[1], e.target.value)} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row    space-x-8">
                                    <div className="flex flex-col"><span className="font-bold text-xs">Text color</span>
                                        <div className="flex rounded-full mt-5 ">
                                            <span className=" flex rounded-md w-40 border border-gray-300 ">
                                                <input
                                                    type="text"
                                                    className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                                    value={cardData.text}
                                                    onChange={(e) => handleInputChange(types[2], e.target.value)} />
                                                <input
                                                    type="color"
                                                    className="mt-2 mb-2 ml-4 border w-[30%] h-6 bg-transparent mr-2 "
                                                    value={cardData.text}
                                                    onChange={(e) => handleInputChange(types[2], e.target.value)} />
                                            </span>
                                        </div>
                                    </div>


                                    <div className="flex flex-col w-40"><span className="font-bold text-xs">Border Radius</span>
                                        <Input type="number" placeholder="Width" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" max="50" maxLength={100} value={cardData?.radius} onChange={(e) => handleInputChange(types[5], e.target.value)} />
                                    </div>
                                </div>
                                <div className="flex flex-row    space-x-8">
                                    <div className="flex flex-col"><span className="font-bold text-xs">Border color</span>
                                        <div className="flex rounded-full mt-5 ">
                                            <span className=" flex rounded-md w-40 border border-gray-300 ">
                                                <input
                                                    type="text"
                                                    className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                                    value={cardData?.bordercolour}
                                                    onChange={(e) => handleInputChange(types[6], e.target.value)} />
                                                <input
                                                    type="color"
                                                    className="mt-2 mb-2 ml-4 border w-[30%] h-6 bg-transparent mr-2  "
                                                    value={cardData?.bordercolour}
                                                    onChange={(e) => handleInputChange(types[6], e.target.value)} />
                                            </span>
                                        </div>
                                    </div>

                                    {cardData.view == "Grid" ? <div className="flex flex-col w-40"><span className="font-bold text-xs">Grid size</span>
                                        <Input type="number" placeholder="Grid size" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" min={2} max={6} value={cardData?.gridsize} onChange={(e) => handleInputChange(types[7], e.target.value)} />
                                        {error && <span className="text-xs text-slate-400">Maximum grid size 6</span>}
                                    </div> : <div></div>}

                                </div>
                                {cardData.view == "Grid" ? "" : <>
                                    <div className="flex flex-row    space-x-8">
                                        <div className="flex flex-col w-40">
                                            <span className="font-bold text-xs">Width</span>
                                            <Input type="number" placeholder="Width" className="mt-5 cursor-pointer py-1 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" max={600} value={cardData?.width} onChange={(e) => handleInputChange(types[3], e.target.value)} disabled={cardData.view == "Grid"} />
                                        </div>
                                        <div className="flex flex-col w-40"><span className="font-bold text-xs">Heigth</span>
                                            <Input type="number" placeholder="Width" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" value={cardData?.heigth} onChange={(e) => handleInputChange(types[4], e.target.value)} disabled={cardData.view == "Grid"} />
                                        </div>
                                    </div>
                                </>}


                            </div>
                            {/* <span>Preview</span> */}
                            <div className="flex-initial flex items-start border-dashed border-slate-200 border-2 ">
                                <>
                                    <div style={{ width: `630px`, height: `410px` }} className="md:w-1/4">
                                        {/* <div>
                                    <div className="rounded-md bg-slate-200 h-4 w-36 mt-4 ml-5"></div>
                                    <div className="rounded-md bg-slate-200 h-4 w-60 mt-4 ml-5"></div>
                                </div> */}
                                        {selectedOption == "Grid" ?
                                            <div className={`grid grid-cols-${cardData.gridsize} p-4 gap-8`}>
                                                {data.map((index) => {
                                                    return (
                                                        <a
                                                            className=" block overflow-hidden rounded-lg border border-zinc-400 p-4 relative bg-white"
                                                            style={{
                                                                width: `${540 / cardData.gridsize}px`,
                                                                height: `${350 / Math.ceil(data.length / cardData.gridsize)}px`,
                                                                backgroundColor: backgroundColor,
                                                                borderRadius: `${cardData.radius}%`,
                                                                borderColor: `${cardData?.bordercolour}`,
                                                                transition: "background-color 0.3s",
                                                            }}
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <div className="sm:flex sm:justify-between sm:gap-6">
                                                                <div className="rounded-full bg-slate-200 h-6 w-6"></div>
                                                            </div>
                                                            <div className="mt-1">
                                                                <h3
                                                                    className="text-sm  text-gray-600 truncate hover:text-clip overflow-hidden line-clamp-1"
                                                                    style={{
                                                                        // fontFamily: customisation.fontFamily,
                                                                        color: cardData.text,
                                                                    }}
                                                                >
                                                                    App Name
                                                                    {/* {
                           "text-base font-bold text-gray-600 sm:text-lg text-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lg"
                           } */}
                                                                </h3>
                                                                <p
                                                                    className="max-w-[40ch] text-xs text-gray-500 truncate line-clamp-1"
                                                                    style={{
                                                                        color: cardData.text,
                                                                    }}
                                                                >
                                                                    <h2


                                                                        className="truncate line-clamp-1"
                                                                    > App Description </h2>
                                                                </p>
                                                            </div>
                                                        </a>)
                                                })}

                                            </div> : <div className="grid grid-cols-1 p-4 gap-8">
                                                {ListData.map((index) => {
                                                    return (
                                                        <a
                                                            className=" block overflow-hidden  border border-zinc-400 p-4 relative bg-white"
                                                            style={{
                                                                width: `${cardData.width}px`,
                                                                height: `${cardData.heigth}px`,
                                                                backgroundColor: backgroundColor,
                                                                borderRadius: `${cardData.radius}px`,
                                                                borderColor: `${cardData.bordercolour}`,
                                                                transition: "background-color 0.3s",
                                                            }}
                                                            onMouseEnter={handleMouseEnter}
                                                            onMouseLeave={handleMouseLeave}
                                                        >
                                                            <div className="sm:flex sm:justify-between sm:gap-6">
                                                                <div className="rounded-full bg-slate-200 h-6 w-6"></div>
                                                            </div>
                                                            <div className="mt-1">
                                                                <h3
                                                                    className="text-sm  text-gray-600 truncate hover:text-clip overflow-hidden line-clamp-1"
                                                                    style={{
                                                                        // fontFamily: customisation.fontFamily,
                                                                        color: cardData.text,
                                                                    }}
                                                                >
                                                                    App Name
                                                                    {/* {
                           "text-base font-bold text-gray-600 sm:text-lg text-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lgtext-base font-bold text-gray-600 sm:text-lg"
                           } */}
                                                                </h3>
                                                                <p
                                                                    className="max-w-[40ch] text-xs text-gray-500 truncate line-clamp-1"
                                                                    style={{
                                                                        color: cardData.text,
                                                                    }}
                                                                >
                                                                    <h2


                                                                        className="truncate line-clamp-1"
                                                                    > App Description </h2>
                                                                </p>
                                                            </div>
                                                        </a>)
                                                })}

                                            </div>}

                                    </div>
                                </>
                            </div>
                        </div>

                    </form>


                </>}

        </>
    );
};

export default CardTab;


