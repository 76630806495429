import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { ErrorMessage } from "@hookform/error-message";
import { MultiOptions } from "../types";

const MultipleSelectField: React.FC<MultiOptions> = ({
  choices,
  pattern,
  control,
  name,
  required,
  register,
  errors,
  placeholder,
}) => {
  const getFormConfigs = () => {
    if (required) {
      return {
        required: { value: required.value, message: required.message },
        pattern: {
          value: new RegExp(pattern.value, pattern.flags),
          message: pattern.message,
        },
      };
    }
    return {
      pattern: {
        value: new RegExp(pattern.value, pattern.flags),
        message: pattern.message,
      },
    };
  };

  return (
    <div>
      <Controller
        control={control}
        {...register(name, getFormConfigs())}
        render={({ field: { onChange, value, ref } }) => (
          <Select
            placeholder={placeholder}
            ref={ref}
            value={value ? choices.filter((c) => value.includes(c.value)) : []}
            onChange={(val) => onChange(val.map((c) => c.value))}
            options={choices}
            isMulti
            className={`${errors[name] ? "border-red-600 focus:border-red-600" : "border-gray-300"}`}
          />
        )}
        isMulti
        options={choices}
      />

      {/* <ErrorMessage
				errors={errors}
				name={name}
				render={({ message }) => <p className="mt-2 text-sm text-red-600 dark:text-red-500">{message}</p>}
			/> */}
    </div>
  );
};

export default MultipleSelectField;
