import SingleSelectField from "common/components/FormFields/SingleSelect";
import { Choice } from "common/components/FormFields/types";
import { useEffect, useState } from "react";
import { StatusType } from "state/new-konnector/types";
import { useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { getSourceFields } from "utils/konnectorStateUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";

const SourceField: React.FC<any> = ({ register, value, index, watchField, errors, filterIndex, watch, setValue }) => {
  const field = { propName: "field_2_" + index, propType: "string", isRequired: true };
  const [data, setdata] = useState([]);
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const status = useAppSelector((state) => state.ruleEngine.status);

  const sourceFields: any = useAppSelector(getSourceFields);
  const fieldValue = watchField(`filter_${filterIndex}_condition_${index}___field_source_type`);

  const getChoices = (): Choice[] => {
    const findSourceFields = sourceFields.find((item) => item.sourceId === fieldValue);
    return findSourceFields
      ? flatSourceOutputFields(findSourceFields.eventResponse.data)
          .filter((field) => field.propType !== "object")
          .map((field) => ({ label: field.label || field.propName, value: field.propName }))
      : [];
  };

  const getFieldOptions = () => {
    return new Field(field).format();
  };
  useEffect(() => {
    setdata([...data, fieldValue]);
    if (data[data.length - 1] && status !== StatusType.loading) {
      setValue(`filter_${filterIndex}_condition_${index}___field_source_field`, "");
    }
  }, [fieldValue]);

  return (
    <div className="w-[50%]">
      <SingleSelectField
        placeholder="Choose source field"
        choices={getChoices()}
        {...getFieldOptions()}
        name={`filter_${filterIndex}_condition_${index}___field_source_field`}
        register={register}
        errors={errors}
      />
    </div>
  );
};
// const useMountedRef = () => {
//   const mountedRef = useRef(false);

//   useEffect(() => {
//     setTimeout(() => {
//       mountedRef.current = true;
//     });
//   }, []);
//   return mountedRef;
// };
export default SourceField;
