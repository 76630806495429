import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useAppSelector } from "state/store";
import Select from "react-select";
import { useApps, useAppsByTypes } from "api/appsApi";
import { useDispatch } from "react-redux";
import { setApp, setAppLoading, setCategoryLoading, setDeleteCategoryLoading, setDeleteModalFalse } from "state/auth/slices";
import { StatusType } from "state/auth/types";
import { LoadingIcon } from "common/components/Icons";
import { mutate } from "swr";
import Spinner from "common/components/Spinner";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";

const DeleteCategoryModal: React.FC<any> = ({ visible, closeModalHandler, text }) => {
    const deleteValue: any = useAppSelector((state) => state.auth.deleteValue);
    const categoryType = useAppSelector((state) => state.auth.categoryType);
    const status = useAppSelector((state) => state.auth.deleteCategoryStatus);
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors },
        // handleSubmit,
        reset,
    } = useForm();
    const { data: apps, error: appsError } = useApps();
    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(setDeleteCategoryLoading(deleteValue.id))
    };
    const closeHandler = () => {
        dispatch(setDeleteModalFalse())
    };
    if (!apps) return <Spinner />;

    return (
        <>
            {visible && (
                <>
                    <div className="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0  shadow-lg relative flex flex-col w-[40rem] bg-white outline-none focus:outline-none">

                                <form onSubmit={handleSubmit}>
                                    <div className="relative p-6 flex-auto">
                                        <h1>Do you want to delete this category - {deleteValue.label}?</h1>
                                    </div>
                                    <div className="flex items-center justify-end p-3">
                                        <button
                                            className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={closeHandler}
                                        >
                                            Close
                                        </button>
                                        <button
                                            className="bg-komp-secondary-color text-white active:bg-komp-secondary-color font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                            type="submit"
                                        // onClick={saveHandler}
                                        >
                                            <>
                                                {status === StatusType.loading ? (
                                                    <>
                                                        <LoadingIcon />
                                                        {"Yes..."}
                                                    </>
                                                ) : status === StatusType.success ? (
                                                    closeModalHandler()
                                                ) : (
                                                    "Yes"
                                                )}
                                            </>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </>
    );
};

export default DeleteCategoryModal;
