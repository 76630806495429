import { AppObject } from "pages/Apps/types";
import React from "react";
import { Link } from "react-router-dom";

interface AppItemProps {
  app: AppObject;
}

const AppItem: React.FC<AppItemProps> = ({ app }) => {
  return (
    <Link
      to={`/apps/${app.id}/accounts`}
      className="flex cursor-pointer space-x-3 items-center bg-white shadow-md rounded-md p-7"
    >
      <div className="w-12">
        <img src={app.iconUrl} alt={app.appName} />
      </div>
      <div className="space-y-1">
        <h3 className="text-base">{app.appName}</h3>
        <p className="text-sm font-light">{app.description}</p>
      </div>
    </Link>
  );
};

export default AppItem;
