import React from "react";

const HelpDesk: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 15.8 11.19"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style></style>
        </defs>
        <g id="darkmode_1" data-name="darkmode 1">
          <g id="apps_sub">
            <g>
              <path
                fill="#7a818c"
                className="cls-2 "
                d="M2.96,6.58c.91,0,1.65-.74,1.65-1.65s-.74-1.65-1.65-1.65-1.65,.74-1.65,1.65,.74,1.65,1.65,1.65Z"
              />
              <path
                fill="#7a818c"
                className="cls-2 bg-[#7a818c]"
                d="M12.84,6.58c.91,0,1.65-.74,1.65-1.65s-.74-1.65-1.65-1.65-1.65,.74-1.65,1.65,.74,1.65,1.65,1.65Z"
              />
              <path
                fill="#7a818c"
                className="cls-2 bg-[#7a818c]"
                d="M14.19,7.9H1.56c-.86,0-1.56,.7-1.56,1.56v.17c0,.86,.7,1.56,1.56,1.56H14.19c.89,0,1.61-.72,1.61-1.61v-.07c0-.89-.72-1.61-1.61-1.61Z"
              />
              <path
                fill="#fff"
                className="cls-1 bg-[#fff]"
                d="M9.88,11.19h-3.95c-1.09,0-1.98-1.03-1.98-2.3s.88-2.3,1.98-2.3h3.95c1.09,0,1.98,1.03,1.98,2.3s-.88,2.3-1.98,2.3Z"
              />
              <path
                fill="#fff"
                className="cls-1 bg-[#fff]"
                d="M7.9,5.27c1.45,0,2.63-1.18,2.63-2.63S9.35,0,7.9,0s-2.63,1.18-2.63,2.63,1.18,2.63,2.63,2.63Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};

export default HelpDesk;
