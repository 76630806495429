import FreePlanModal from "./FreePlanModal";
import StartupPlanModal from "./StartUpPlanModal";
const RenderModal: React.FC<any> = ({ planType, linkToPayment, visible, closeModalHandler, fromPlan }) => {
  const renderModal = () => {
    switch (planType.slug) {
      case "free":
        return <FreePlanModal visible={visible} closeModalHandler={closeModalHandler} fromPlan={fromPlan} />;
      case "startup":
        return (
          <StartupPlanModal
            visible={visible}
            closeModalHandler={closeModalHandler}
            fromPlan={fromPlan}
            linkToPayment={linkToPayment}
          />
        );
    }
  };

  return <>{renderModal()}</>;
};
export default RenderModal;
