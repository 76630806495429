import { StatusType } from "state/new-workflow/types";

export enum FilterType {
  ALL = "all",
  ACTIVE = "active",
  freshdesk = "freshdesk",
  description = "description",
}
export enum FilterByType {
  TRIGGER = "trigger",
  INSTRUCT = "instruct",
  ACTION = "action",
}

export enum SortByType {
  DEFAULT = "",
  STATUS = "status",
  ACTIVE = "active",
  INACTIVE = "inactive",
  CREATED = "dtCreated",
  LATEST = "latest",
  OLDEST = "oldest",
}

export interface InitialState {
  sortBy: SortByType;
  activeSortBy: SortByType;
  filterBy: FilterType;
  filterByWorkflow: FilterByType;
  status: StatusType;
  currentPage: number;
  workflowState: WorkFlowState;
  hasActivated: boolean;
  currentActionId: string;
  hasDeleted: boolean;
  errorMessage: string;
  selectedKonnectorId: any;
  activeApps: [];
  deactiveApss: [];
  hasDeactivated: boolean;
  isModal: boolean;
  appStatusReload: StatusType;
  reorderApps: [];
  appId: string
}

export enum WorkFlowState {
  NewWorkFlowState = "createNewWorkflow",
  EditWorkFlowState = "editWorkFlow",
}
export const ErrorStatus = [400, 401, 500];
