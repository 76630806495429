import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { Tick } from "common/components/Icons";
import { PlanOrder, PlanPeriods } from "../Constants/planTypes";
import Select from "react-select";
import { Options } from "common/components/FormFields/types";
import { useAppDispatch, useAppSelector } from "state/store";
import {
  setSeletedPlanCycle,
  setcurrentSelectedPlan,
  setSeletedPlanAddon,
  setShowPaymentModal,
  setSeletedPlanPrice,
  setClientId,
  resetSelectedPlan,
} from "state/payment/slices";
import { Link, Redirect } from "react-router-dom";
import PaymentModal from "../Modal/PaymentModal/PaymentModal";
import { createIncompleteSubscription } from "api/paymentApi";
import { flatMap } from "lodash";

const PlanCalculator: React.FC<any> = ({ planType, showModalHandler }) => {
  const dispatch = useAppDispatch();
  const { billing, isEmailVerified } = useAppSelector((state) => state.auth.currentUser);
  const [subscriptionData, setSubscriptionData] = useState(null);
  let [subLoading, setSubLoading] = useState(false);

  const userStripeId = useAppSelector((state) => state.auth.currentUser.stripeId);
  const planCycle = useAppSelector((state) => state.payments.selectedPlanDetails.cycle);
  const planAddon = useAppSelector((state) => state.payments.selectedPlanDetails.addons);
  const planPrice =
    planCycle === "yearly"
      ? 12 * planAddon * 15 + 12 * planType.amount[planCycle]
      : planAddon * 15 + planType.amount[planCycle];

  const renderApps: any = () => {
    return PlanPeriods.map((app) => ({
      label: app,
      value: app,
    }));
  };
  const onChangeCycle = (data) => {
    dispatch(setSeletedPlanCycle(data.value));
  };

  const handleProceedToPayment = async () => {
    dispatch(setShowPaymentModal(true));
  };

  const handlePlanSwitch = () => {
    showModalHandler();
  };

  useEffect(() => {
    dispatch(setSeletedPlanPrice(planPrice));
  }, [planPrice]);

  const linkToPayment = `/billing/payments/create-checkout-session/${planType.productId[planCycle]}/${userStripeId}?frequency=${planCycle}&addon=${planAddon}`;

  return (
    <div className="mt-4 border-t-[1px] border-gray-300 transition-all relative ease-in-out">
      <button
        onClick={() => {
          dispatch(resetSelectedPlan());
          dispatch(setcurrentSelectedPlan(""));
        }}
        type="button"
        className="text-gray-400 bg-transparent absolute rounded-sm text-sm p-1.5 ml-auto inline-flex right-0"
        data-modal-toggle="popup-modal"
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div className="flex mt-10 flex-col space-y-5">
        <div className="flex w-full justify-between items-center">
          <h1>Billing</h1>
          <div className="w-1/2 text-primary-color">
            <Select
              options={renderApps()}
              value={{ label: planCycle, value: planCycle }}
              className="text-primary-color"
              onChange={onChangeCycle}
            />
          </div>
        </div>
        <div className="flex flex-col ">
          <div className="flex w-full justify-between items-center mb-2">
            <h1>Pricing</h1>
            <div className="flex space-x-0 w-2/3 justify-end">
              <div className="text-primary-color font-semibold">
                {planCycle === "yearly" && "12 x "}
                {planType.amount[planCycle]}
              </div>
            </div>
          </div>
          <div className="flex w-full justify-between items-center">
            <div className="flex">
              <h1 className="w-1/3">Addons</h1>
            </div>

            <div className="flex space-x-2 w-2/3 justify-end">
              <input
                type="number"
                value={planAddon}
                min={0}
                onChange={(e) => {
                  const value = e.target.value ? parseInt(e.target.value) : 0;
                  dispatch(setSeletedPlanAddon(value));
                }}
                className="w-1/3 px-2 bg-gray-50 border-[2px] rounded border-gray-300 "
              />
              <div className="text-primary-color font-semibold"> {planCycle === "yearly" && "12 "} x 15</div>
            </div>
          </div>
          <div className="flex justify-start">
            <p className="text-xs">(10k tasks per addon)</p>
          </div>
          <div className="flex w-full  mt-2 justify-between items-center">
            <h1 className="w-1/3">Tasks</h1>
            <div className="flex space-x-2 w-2/3 justify-end">
              <div className=" text-primary-color font-semibold">{planAddon * 10000 + planType.tasks}</div>
            </div>
          </div>
        </div>
        <div className="w-full border-t-2 border-gray-300"></div>
        <div className=" flex flex-col">
          <div className="flex w-full justify-between items-center">
            <h1>Total</h1>
            <div className="flex space-x-2 w-2/3 justify-end text-primary-color font-semibold">
              $ {planPrice.toFixed(2)}
              {/* <div className="w-1/3 text-primary-color font-semibold"></div> */}
            </div>
          </div>

          {planCycle === "yearly" && <p className="flex justify-end text-xs">${(planPrice / 12).toFixed(2)}/month</p>}
        </div>
        {PlanOrder[billing?.plan] > planType.order ? (
          <button
            onClick={showModalHandler}
            disabled={!isEmailVerified}
            className="w-full bg-primary-color  flex  justify-center items-center rounded h-10 text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Proceed to payment
          </button>
        ) : (
          <button
            disabled={!isEmailVerified}
            onClick={handleProceedToPayment}
            className="w-full bg-primary-color  flex  justify-center items-center rounded h-10 text-white disabled:opacity-50"
          >
            {subLoading ? "Loading" : "Proceed to payment"}
          </button>
        )}
      </div>
    </div>
  );
};

export default PlanCalculator;
