import React from "react";
import Modal from "../Modal/Modal";
import * as Icon from "../Icons";
import { useAppDispatch, useAppSelector } from "state/store";
import { setShowPaymentModal } from "state/payment/slices";
import { XCircleIcon, ArrowCircleDownIcon } from "@heroicons/react/outline";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";

const StartupPlanModal: React.FC<any> = ({ linkToPayment, visible, closeModalHandler, fromPlan, addon }) => {
  const userStripeId = useAppSelector((state) => state.auth.currentUser.stripeId);
  const dispatch = useAppDispatch();

  return (
    <Modal visible={visible} closeModalHandler={closeModalHandler} fromPlan={fromPlan} toPlan={"free"}>
      <div className="flex-col flex h-full w-full p-2 items-center justify-center relative">
        <p className="text-center flex flex-col items-center">
          Are You sure You want to downgrade from <p className="font-bold">{fromPlan.toUpperCase()} to STARTUP</p>
        </p>
        <div className="flex mt-4 flex-col justify-start items-start w-4/5  text-gray-500">
          <div>
            <div className="flex items-center space-x-2">
              <ArrowCircleDownIcon className="h-4 w-4 " />
              <p> 10 min polling interval</p>
            </div>
            <div className="flex items-center space-x-2">
              <ArrowCircleDownIcon className="h-4 w-4" />
              <p>5 Smart Forms</p>
            </div>
            <div className="flex items-center space-x-2">
              <ArrowCircleDownIcon className="h-4 w-4" />
              <p>7500 tasks only</p>
            </div>
          </div>
        </div>
        <div className="flex w-full p-2 mt-4 space-x-2">
          <button
            onClick={closeModalHandler}
            className="w-1/2 h-10 text-gray-600 rounded-sm  bg-gray-300 p-2 flex items-center justify-center"
          >
            No, Cancel
          </button>
          <button
            onClick={() => {
              closeModalHandler();
              dispatch(setShowPaymentModal(true));
            }}
            className="w-1/2 h-10 bg-gray-600 text-gray-200  rounded-sm p-2 flex items-center justify-center"
          >
            Yes, Proceed
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default StartupPlanModal;
