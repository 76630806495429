import { saveUserApi } from "api/authApi";
import Success from "common/components/Toast/SuccessWithoutRedirect";
import React, { useEffect, useReducer, useState } from "react";
import { toast } from "react-toastify";
import { getAuth } from "state/selectors";
import { useAppSelector } from "state/store";
import EditProfile from "./EditProfile";
import Header from "./Header";
import Information from "./Information";

const ProfileSettings: React.FC = () => {
  const authState = useAppSelector(getAuth);
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: true,
      formState: {
        submitted: false,
        data: authState.currentUser,
        form: {},
      },
      editProfile: false,
      response: {},
    }
  );
  const handleEditProfile = (value = true) => {
    setState({ editProfile: value });
  };
  const updateUser = async () => {
    const response = await saveUserApi(
      authState.currentUser.id,
      state.formState.form
    );
    setState({
      response,
      formState: { data: response.data.data, submitted: false },
      editProfile: false,
    });
  };

  const onSubmitHandler = (formData) => {
    setState({
      formState: { ...state.formState, submitted: true, form: formData },
    });
  };

  useEffect(() => {
    if (state.formState.submitted) {
      updateUser();
      toast.success("Profile updated successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [state.formState.submitted]);

  return (
    <>
      <div className="flex flex-col space-y-2">
        <Header
          handleEditProfile={handleEditProfile}
          {...state.formState.data}
        />

        {!state.editProfile && <Information {...state.formState.data} />}

        {state.editProfile && (
          <EditProfile
            {...state.formState.data}
            cancelEditProfile={() => handleEditProfile(false)}
            onSubmitHandler={onSubmitHandler}
          />
        )}
      </div>
    </>
  );
};

export default ProfileSettings;
