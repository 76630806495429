import React, { useState } from "react";
import WarningIcon from "pages/BillingTest/Icons/WarningIcon";
import Tooltip from "common/containers/Layout/components/Tooltip/Tooltip";
import ViewJson from "pages/EventLogSummary/component/ViewJSon";

const Error: React.FC<any> = ({ text, outputFields, title }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };

  return (
    <div>
      {/* <Tooltip text="Failed"> */}
      <WarningIcon onClick={handleActionClick} />
      {state && <ViewJson visible={state} setViewOutputFields={setState} outputFields={outputFields} title={title} />}
      {/* </Tooltip> */}
    </div>
  );
};

export default Error;
