import { EventLogsPayloadProps } from "pages/EventLogs/types";
import React, { useState } from "react";
import Events from "./EventList";

const EventLogs: React.FC<EventLogsPayloadProps> = ({ data, konnector }) => {
  return (
    <>
      {data.items.length > 0 ? (
        <>
          <Events data={data.items} konnectorData={konnector.data.items} />
        </>
      ) : (
        <div className="flex w-[500%] h-[calc(100vh-7rem)] justify-center items-center">
          <p className="flex justify-center text-center">No events occurred!</p>
        </div>
      )}
    </>
  );
};

export default EventLogs;
