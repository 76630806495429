import React from "react";
import { Item } from "pages/EventLogSummary/types";
import moment from "moment";
import Badge from "common/components/Badge";
import { FormIcon, WebhookIcon } from "common/components/Icons";
import { useLocation } from "react-router-dom";

interface HeaderProps {
  header: Item;
  apps: any;
  taskExecuted?: any;
}

const HeaderComponent: React.FC<HeaderProps> = ({ header, apps, taskExecuted }) => {
  const renderAppIcons = () => {
    return apps.slice(1).map((app, index) => {
      return <img className=" ml-2 mr-2" src={app.iconUrl} key={index} alt="" width="30" height="35" />;
    });
  };
  const { pathname } = useLocation();
  return (
    <div
      className={`${
        pathname.includes("admin")
          ? "ml-[4%] overflow-x-auto  h-[10%] w-[100%] border-b-2 bg-[#F2F6FF] shadow-inner"
          : "overflow-x-auto  h-[10%] w-[100%] border-b-2 bg-[#F2F6FF] shadow-inner"
      }`}
    >
      <table className="min-w-full max-w-full">
        <thead className="">
          <tr>
            <th scope="col" className="pl-6  py-3 text-xs font-bold text-left uppercase ">
              {header.trigger.konnectorName}
            </th>
            {apps ? (
              <th scope="col" className="pl-6 pr-56 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
                Task order
              </th>
            ) : (
              <th scope="col" className="pl-6 pr-56 py-3 text-xs font-bold text-left text-gray-500 uppercase "></th>
            )}
            <th scope="col" className="pl-6 pr-70 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              Task counter
            </th>
            <th scope="col" className="pl-6 pr-70 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              Event id
            </th>
            <th scope="col" className="pl-6 pr-70 py-3 text-xs font-bold text-left text-gray-500 uppercase ">
              Event created
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="">
            <td className="px-6 py-2 text-sm font-medium text-gray-800 whitespace-nowrap">
              Status:
              <span className="inline-flex">
                <Badge type={header.status} text={header.status} />
              </span>
            </td>
            <td className="px-6 py-2 text-sm font-medium text-gray-800 whitespace-nowrap">
              {apps && (
                <span className=" inline-flex ">
                  {apps[0].appId !== "generic" ? (
                    <img src={apps[0].iconUrl} alt="" width="30" height="30" />
                  ) : (
                    <>{apps[0].appEventId === "webhook" ? <WebhookIcon classNameOp="h-8 w-8" /> : <FormIcon />}</>
                  )}
                  <span>
                    <div className="flex items-center ml-2 mr-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                        <path
                          fillRule="evenodd"
                          d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                      <div className="flex items-center space-x-2 ">{renderAppIcons()}</div>
                    </div>
                  </span>
                </span>
              )}
            </td>
            <td className="px-6 py-2 text-sm font-medium text-gray-800 whitespace-nowrap">
              {taskExecuted ? <>{taskExecuted} </> : 0} executed
            </td>
            <td className="px-6 py-2 text-sm font-medium text-gray-800 whitespace-nowrap">{header.id}</td>
            <td className="px-6 py-2 text-sm font-medium text-gray-800 whitespace-nowrap">
              {moment(header.dtUpdated).fromNow()}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HeaderComponent;
