import React, { useEffect, useRef } from "react";
import TextField from "../FormFields/genericFields/Text";
import { useAppDispatch, useAppSelector } from "state/store";
import { retirveDependentOptions } from "utils/formBuilder.utils";
import { optionsField } from "../constants/fieldList";
import {
  createOptions,
  deleteCurrentFormOptionId,
  deleteOption,
  saveOptionData,
  setCurrentFormOptionId,
} from "state/form-builder/slices";
import { TrashIcon } from "@heroicons/react/outline";

const OptionsModal: React.FC<any> = ({ register, errors, reset }) => {
  const dispatch = useAppDispatch();
  const ref = useRef([]);
  const currentFieldId = useAppSelector((state) => state.formBuilder.currentFormField);
  const getFormField = (state) => {
    const findField = state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId);

    if (findField) {
      const fieldVlaue = retirveDependentOptions(findField);
      return fieldVlaue;
    }
  };

  const currentFormField = useAppSelector((state) =>
    state.formBuilder.formFields.find((field) => field.fieldId === currentFieldId.fieldId)
  );
  const formField = useAppSelector(getFormField);
  const setOptionVlaue = (e, option) => {
    dispatch(saveOptionData({ value: e.target.value, id: option.id }));
  };

  useEffect(() => {
    reset(formField);
  }, [currentFormField, currentFieldId]);

  const renderField = (field, id, isRepeated, isHover?) => {
    switch (field.type) {
      case "textbox | textarea":
      case "textbox": {
        return (
          <TextField
            {...field}
            label={field.label}
            key={`option_${id}`}
            name={`option_${id}`}
            register={register}
            isDisabled={false}
            errors={errors}
            isHover={isHover}
            isRepeated={isRepeated}
            placeholder={field.placeholder}
          />
        );
      }
      default:
        // return "<p>Field not found</p>";
        return <p></p>;
    }
  };

  const renderForm = (id, isRepeated, isHover?) => {
    let fields: any;
    fields = [...optionsField, (optionsField[0].placeholder = "Fields"), (optionsField[0].label = "no_label")];
    return fields.map((field) => renderField(field, id, isRepeated, isHover));
  };

  return (
    <>
      <p className="text-sm mb-2">Fields</p>
      {currentFormField.options?.map((option, index) => {
        return (
          <>
            <div
              className="flex w-full items-center space-x-2"
              ref={(ele) => (ref.current[index] = ele)}
              onChange={(e) => {
                setOptionVlaue(e, option);
              }}
            >
              <div className={` ${currentFieldId.optionId === option.id ? "w-full" : "w-4/5"}   transition-all`}>
                {renderForm(option.id, option.isRepeated, currentFieldId.optionId === option.id)}
              </div>
              {currentFormField.options.length > 1 && currentFieldId.optionId !== option.id && (
                <button
                  className="cursor-pointer ml-3  w-1/12 transition-all"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteOption(option.id));
                  }}
                >
                  <TrashIcon className="h-5 w-5" onClick={() => {}} />
                </button>
              )}
              {currentFieldId.optionId === option.id ? (
                <button
                  className="cursor-pointer w-1/12 text-2xl"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(deleteCurrentFormOptionId());
                  }}
                >
                  {"-"}
                </button>
              ) : (
                <button
                  className="cursor-pointer w-1/12 text-2xl"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setCurrentFormOptionId(option.id));
                  }}
                >
                  {"+"}
                </button>
              )}
            </div>
          </>
        );
      })}
      <div className="w-full flex mt-2 justify-start">
        <button
          className="bg-gray-300 text-sm text-gray-600 p-2 rounded"
          onClick={(e) => {
            dispatch(createOptions());
          }}
          type="button"
        >
          +Add more options
        </button>
      </div>
    </>
  );
};

export default OptionsModal;
