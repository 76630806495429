import React from "react";
import { KonnectityEngineFeatures } from "pages/KonnectorPage/types";
import { ParseEngineIcon, RuleEngineIcon, SentimentIcon } from "./Icons";
import { useDispatch } from "react-redux";
import { useAppSelector } from "state/store";
import { ParentProps } from "state/new-konnector/types";
const KonnectifyEngine: React.FC<any> = ({ konnectorSlice }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);

  const dispatch = useDispatch();
  const onClickRuleHandler = () => {
    dispatch(
      konnectorSlice.createRule({
        parentId: currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source,
      })
    );
  };
  const onClickParseOpen = () => {
    dispatch(
      konnectorSlice.createParse({
        parentId: currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source,
      })
    );
    console.log("onClickParseOpen");
  };
  return (
    <div className="w-[20%]">
      <h4 className="text-md font-semibold text-primary-color">Konnectify Engine</h4>
      <div className="flex flex-col">
        <button
          onClick={() => onClickRuleHandler()}
          className="my-3 max-w-[80%]  bg-gray-100 relative cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
        >
          <span className="absolute top-1 left-1 bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
            Active
          </span>
          <RuleEngineIcon />
          <figcaption className="text-center text-sm mt-2">{KonnectityEngineFeatures.RuleEngine}</figcaption>
        </button>
      </div>
      {currentActionId === ParentProps.Repeater ? (
        ""
      ) : (
        <div className="flex flex-col">
          <button
            onClick={() => onClickParseOpen()}
            className="my-3 max-w-[80%]  bg-gray-100 relative cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
          >
            <span className="absolute top-1 left-1 bg-green-100 text-green-800  text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
              Active
            </span>
            <ParseEngineIcon />
            <figcaption className="text-center text-sm mt-2">{KonnectityEngineFeatures.ParseEngine}</figcaption>
          </button>
        </div>
      )}
      <div className="my-3 max-w-[80%] bg-gray-100 relative cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm">
        <SentimentIcon />
        <figcaption className="text-center text-sm mt-2">{KonnectityEngineFeatures.SentimentAnalysis}</figcaption>
      </div>
    </div>
  );
};

export default KonnectifyEngine;
