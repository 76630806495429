import PaintBrushIcon from "../../../Iconss/PaintBrushIcon.svg";
import SettingsIcon from "../../../Iconss/SettingsIcon.svg";
import BillingIcon from "../../../Iconss/BillingIcon.svg";
export interface Lookup {
  id: string;
  name: string;
}

export interface Required {
  message: string;
  value: boolean;
}

export interface Pattern {
  value: string;
  flags: string;
  message: string;
}

export interface Field {
  required: Required;
  pattern: Pattern;
  name: string;
  type: string;
}

export interface ConnectionConfig {
  connectorTypeId: string;
  fields: Field[];
}

export interface Action {
  name: string;
  id: string;
}

export interface Trigger {
  eventSource: string;
  id: string;
  name: string;
}

export interface AppObject {
  appName: string;
  id: string;
  description: string;
  appKey: string;
  lookups: Lookup[];
  dtCreated: Date;
  connectionConfig: ConnectionConfig;
  actions: Action[];
  triggers: Trigger[];
  iconUrl: string;
  dtUpdated: Date;
  status?: string;
}
export interface AppsProps {
  apps: AppObject[];
}
export const AdminIcons = [
  // {
  //   id: 0,
  //   name: "Marketplace settings",
  //   type: "Marketplace settings",
  //   icon: SettingsIcon,
  //   description: "Market place settings",
  //   redirectUrl: "",
  // },
  {
    id: 1,
    name: "Marketplace customisation and end user portal",
    type: "Customization-end-user-marketplace",
    icon: PaintBrushIcon,
    description: "Customize the appearance of the portal on your website",
    redirectUrl: "",
  },
  {
    id: 2,
    name: "Billing",
    type: "Billing",
    icon: BillingIcon,
    description: "Pay your bills",
    redirectUrl: "",
  },
];

export const appTypeField = [
  {
    type: "all",
    label: "All apps",
  },

  {
    type: "marketing",
    label: "Sales and Marketing",
  },
  {
    type: "productivity",
    label: "Productivity",
  },
  {
    type: "crm",
    label: "CRM",
  },
  {
    type: "communication",
    label: "Communication",
  },
  {
    type: "document_management",
    label: "Document Mangement",
  },
];
