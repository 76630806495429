import { combineReducers } from "redux";

import createNewKonnector from "./new-konnector/slices";
import auth from "./auth/slices";
// import embeddedAuth from "./EmbeddedAuth/slices";
import account from "./account/slices";
import konnectors from "./konnectors/slices";
import editKonnector from "./edit-konnector/slices";
import eventLogs from "./event-logs/slices";
import payments from "./payment/slices";
import ruleEngine from "./rule-engine/slices";
import parseEngine from "./parseEngine/slices";
import formBuilder from "./form-builder/slices";
import createNewWorkflow from "./new-workflow/slices";
import editWorkFlow from "./edit-workflow/slices";
import workflows from "./workFlows/slices";
import adminSettings from "./Embedded-markteplace/AdminSettings/slices";
import themeSettings from "./Embedded-markteplace/ThemeSettings/slices";
import adminEventLogs from "./Embedded-markteplace/event-logs/slices";

const rootReducer = combineReducers({
  createNewKonnector,
  auth,
  konnectors,
  workflows,
  editKonnector,
  eventLogs,
  account,
  payments,
  ruleEngine,
  parseEngine,
  formBuilder,
  createNewWorkflow,
  editWorkFlow,
  adminSettings,
  adminEventLogs,
  themeSettings
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
