import React from "react";
import { AppsProps } from "../types";
import AppItem from "./AppItem";


const AppList: React.FC<any> = ({ apps, pageNumber, setPageNumber }) => {
  const renderApps = () => {
    return apps.map((app, index) => <AppItem key={app.id} app={app} />);
  };
  return (
    <div className="flex flex-col space-y-5 items-start">
      <div className="grid grid-cols-4 gap-4">{renderApps()}</div>
    </div>
  );
};

export default AppList;
