import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
// import { ActionMode } from "state/new-konnector/types";
import { ActionMode } from "state/new-workflow/types";
import { useAppDispatch, useAppSelector } from "state/store";
import { WorkFlowState } from "state/workFlows/types";

const SelectEvent: React.FC<any> = ({ sourceApp, sourceEvent, apps, konnectorSlice }) => {
  const workflowState: WorkFlowState = useAppSelector((state) => state.workflows.workflowState);
  const currentActionId = useAppSelector((state) => state[workflowState].currentActionId);
  const status = useAppSelector((state) => state[workflowState].data.trigger.outputFields.hasData);
  const dispatch = useAppDispatch();
  const edit = useAppSelector((state) => state[workflowState]?.data.actions);
  const loadingState = useAppSelector((state) => state[workflowState]?.isLoading);
  const kon = useAppSelector((state) => state[workflowState]?.data.actions[0].connectionName);
  const onChangeEventHandler = ({ value: eventId }) => {
    const hitInputFieldsApi = hasInputFields.find((app) => app.id === eventId).inputFields;
    console.log(hasInputFields, hitInputFieldsApi);
    dispatch(
      konnectorSlice.updateTargetEvent({ eventId, id: currentActionId, hasDynamicInputFields: hitInputFieldsApi })
    );
    if (hitInputFieldsApi) {
      dispatch(konnectorSlice.fetchDynamicInputFieldsLoading());
    }
  };
  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };

  const hasInputFields = getApp().actions.map((action) => {
    return { id: action.id, inputFields: action.dynamicInputFields || false };
  });

  const renderEvents = () => {
    return getApp().actions.map((event) => ({
      label: event.name,
      value: event.id,
      disabled: event.disabled ? event.disabled : false,
    }));
  };
  const { other, appid, accountId, eventid, name } = useParams<any>();

  useEffect(() => {
    if (other && status && edit[0].actionMode !== ActionMode.Edit && edit.length == 1 && kon) {
      dispatch(konnectorSlice.updateTargetEvent({ eventId: eventid, id: currentActionId }));
      if (loadingState) {
        dispatch(konnectorSlice.updateLoadingState(false));
      }
    }
  }, [sourceEvent]);
  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Event</label>
      <Select
        options={renderEvents().filter((event) => !event.disabled)}
        value={renderEvents().filter(function (option) {
          return option.value === sourceEvent;
        })}
        onChange={onChangeEventHandler}
      />
    </div>
  );
};

export default SelectEvent;
