import TextField from "common/components/FormFields/TextFieldWithoutRegister";
import { InfoIcon } from "common/components/Icons/InfoIcon";
import React, { useEffect } from "react";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { setFieldType } from "utils/ruleEngineUtils";

const SelectInputSchema: React.FC<any> = ({ field, konnectorSlice, index, dynamicInputfields }) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const fieldValue = dynamicInputfields.dataMapping[field.propName] || "";
  const dependentField = field.dependentTo;
  const dependentFieldValue = dynamicInputfields.dataMapping[dependentField] || "";
  const propValues = field.propValues ? field.propValues : field.propValue;
  const renderFields = propValues?.find((val) => val.value === fieldValue) || { label: fieldValue, value: fieldValue };
  const renderEvents = () => {
    let choices = propValues;
    if (propValues?.length === 0) {
      choices = [{ label: fieldValue, value: fieldValue }];
    }
    return choices?.map((prop) => {
      return { label: prop.label, value: prop.value };
    });
  };
  const getFieldOptions = () => {
    const fieldData = new Field(field).format();
    if (!["array", "object"].includes(field.propType)) {
      fieldData.type = setFieldType(fieldData, field.propType);
    }
    return fieldData;
  };

  const onChangeHandler = (e) => {
    dispatch(
      konnectorSlice.setActionEventDataMapping({
        field: field.propName,
        value: e.value,
        actionType: "actions",
      })
    );
    if (index === dynamicInputfields.data.length - 1) {
      dispatch(konnectorSlice.fetchInputFieldsLoading({ actionType: "actions" }));
    }
  };

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">{`Select ${field.label}`}</label>
      {propValues?.length || dependentField ? (
        <><Select
          isDisabled={true}
          options={renderEvents()}
          value={renderFields}
          onMenuOpen={() => {
            if (dependentField && dependentFieldValue && (!propValues || propValues.length === 0)) {
              dispatch(
                konnectorSlice.fetchDynamicFieldsLoading({
                  actionType: "actions",
                  eventId: field.lookupId,
                  eventData: dependentFieldValue,
                  field: field.propName,
                })
              );
            }
          }}
          onChange={(e: any) => {
            onChangeHandler(e);
          }} /><div className="flex items-center space-x-3 mt-3">
            <InfoIcon />
            <span className="text-gray-700 text-sm">This will be configured by Komp end users</span>
          </div></>
      ) : (
        <><TextField
          isDisabled={true}
          {...getFieldOptions()}
          value={renderFields?.value}
          onChange={(e: any) => {
            dispatch(
              konnectorSlice.setActionEventDataMapping({
                field: field.propName,
                value: e.value,
                actionType: "actions",
              })
            );

          }} /><div className="flex items-center space-x-3 mt-3">
            <InfoIcon />
            <span className="text-gray-700 text-sm">This will be configured by Komp end users</span>
          </div></>
      )}
    </div>
  );
};

export default SelectInputSchema;
