import React, { useEffect, useState } from "react";
import Editor from '@monaco-editor/react';
import { useAppSelector } from "state/store";
import { setTemplateData, setTemplateSnippet } from "state/Embedded-markteplace/ThemeSettings/slices";
import { useDispatch } from "react-redux";
import { Input } from "components/ui/input";
import { Button } from "components/ui/button";
import { Check, ChevronLeftIcon, ChevronsUpDown } from "lucide-react";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, } from "components/ui/command"
import { Popover, PopoverContent, PopoverTrigger, } from "components/ui/popover"
import { Link } from "react-router-dom";
const statuses: any = [
    {
        value: ".class1",
        label: "class1",
    }, {
        value: ".class2",
        label: "class2",
    }, {
        value: ".class3",
        label: "class3",
    },
]
const TemplateTab: React.FC<any> = ({ types, colors, onColorChange, onChange, onEditorTemplateValueChange }) => {
    const dispatch = useDispatch()
    const templateData = useAppSelector((state) => state.themeSettings.template)
    const [open, setOpen] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState<any | null>(
        null
    )
    const [snippet, setSnippet] = useState(false);
    const [hover, setHover] = useState<any>();
    const [backgroundColor, setBackgroundColor] = useState(templateData?.background);
    const [isChecked, setIsChecked] = useState(true);
    const handleCheckboxChange = () => {
        setIsChecked(true);
    };
    const [editorValue, setEditorValue] = useState(templateData.templateSnippet);
    const handleEditorChange = (value) => {
        setEditorValue(value);
        if (value.length > 0) {
            dispatch(setTemplateSnippet(true))
        } else {
            dispatch(setTemplateSnippet(false))
        }
        dispatch(setTemplateData({ type: "templateSnippet", value }))
        // onEditorTemplateValueChange(value);
    };
    const handleSnippet = () => {
        setSnippet(true)
    }
    const [localColors, setLocalColors] = useState(colors);
    const handleInputChange = (type, value) => {
        console.log(type, value, "type value")
        setLocalColors((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: value,
        }));
        dispatch(setTemplateData({ type: type.toLowerCase(), value }))
        setBackgroundColor(templateData?.background);
        onColorChange(type, value);
    };
    const handleMouseEnter = () => {
        setHover(true);
        setBackgroundColor(templateData?.hover);
    };
    const handleMouseLeave = () => {
        setHover(false);
        setBackgroundColor(templateData?.background);
    };
    const handleClick = () => {
        setSnippet(false)
    };
    const data = [1, 1, 1]
    return (
        <>{snippet ?
            <>
                <div className="flex p-4 bg-gray-100 mb-11 border-slate-500 border-solid">
                    <Button variant="outline" size="icon" onClick={handleClick} className="border-slate-300">
                        <ChevronLeftIcon className="h-4 w-4" />
                    </Button>
                    <div className="flex items-center space-x-4 ml-auto z-10 border-slate-300">
                        <Popover open={open} onOpenChange={setOpen}>
                            <PopoverTrigger asChild>
                                <Button variant="outline" className="w-[150px] justify-start">
                                    {selectedStatus ? <>{selectedStatus.label}</> : <>+ Default CSS Class</>}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="p-0 bg-white" side="right" align="start">
                                <Command>
                                    <CommandInput placeholder="Change CSS..." />
                                    <CommandList>
                                        <CommandEmpty>No results found.</CommandEmpty>
                                        <CommandGroup>
                                            {statuses.map((status) => (
                                                <CommandItem
                                                    key={status.value}
                                                    value={status.value}
                                                    onSelect={(value) => {
                                                        setSelectedStatus(
                                                            statuses.find((priority) => priority.value === value) ||
                                                            null
                                                        )
                                                        setOpen(false)
                                                    }}
                                                >
                                                    {status.label}
                                                </CommandItem>
                                            ))}
                                        </CommandGroup>
                                    </CommandList>
                                </Command>
                            </PopoverContent>
                        </Popover>
                    </div></div>
                <Editor height="50vh" defaultLanguage="css" defaultValue={editorValue} language="css" theme="bg-primary-color" className="border-solid border-2 border-slate-200 -mt-16" options={{
                    fontSize: 15,
                }} onChange={handleEditorChange} /></>
            : <><form>
                <div className="flex flex-row -mt-2 p-2">
                    <Button onClick={handleSnippet} className="bg-slate-50 text-black w-40 text-left ml-auto ">Advanced Settings</Button>
                </div>
                <div className="flex flex-row ">
                    <div className="flex-1 bg-transparent space-y-10 h-[60vh] overflow-scroll">
                        {/* <div className="grid grid-cols-2 gap-6"> */}
                        <div className="flex flex-row   space-x-8">
                            <div className="flex flex-col ">
                                <span className="font-bold text-xs">Background color</span>
                                <div className="flex rounded-full mt-5 ">
                                    <span className=" flex rounded-md w-40 border border-gray-300">
                                        <input
                                            type="text"
                                            className=" w-[40%] bg-transparent  pl-2 ml-1 cursor-pointer text-xs"
                                            value={templateData?.background}
                                            onChange={(e) => handleInputChange(types[0], e.target.value)} />
                                        <input
                                            type="color"
                                            className="cursor-pointer mt-2 mb-2 ml-4 border w-[30%] bg-transparent mr-2"
                                            value={templateData?.background}
                                            onChange={(e) => handleInputChange(types[0], e.target.value)} />
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="font-bold text-xs">Hover color</span>
                                <div className="flex rounded-full mt-5 ">
                                    <span className=" flex rounded-md w-40 border border-gray-300 ">
                                        <input
                                            type="text"
                                            className=" w-[40%] bg-transparent  pl-2 ml-1 cursor-pointer text-xs"
                                            value={templateData?.hover}
                                            onChange={(e) => handleInputChange(types[1], e.target.value)} />
                                        <input
                                            type="color"
                                            className="mt-2 mb-2 ml-4 border w-[30%] bg-transparent mr-2 cursor-pointer"
                                            value={templateData?.hover}
                                            onChange={(e) => handleInputChange(types[1], e.target.value)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row  space-x-8">
                            <div className="flex flex-col">
                                <span className="font-bold text-xs">Text color</span>
                                <div className="flex rounded-full mt-5 ">
                                    <span className=" flex rounded-md w-40 border border-gray-300 ">
                                        <input
                                            type="text"
                                            className=" w-[40%] bg-transparent  pl-2 ml-1 cursor-pointer text-xs"
                                            value={templateData?.text}
                                            onChange={(e) => handleInputChange(types[2], e.target.value)} />
                                        <input
                                            type="color"
                                            className="cursor-pointer mt-2 mb-2 ml-4 border w-[30%] bg-transparent mr-2"
                                            value={templateData?.text}
                                            onChange={(e) => handleInputChange(types[2], e.target.value)} />
                                    </span>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="font-bold text-xs">Toggle color</span>
                                <div className="flex rounded-full mt-5 ">
                                    <span className=" flex rounded-md w-40 border border-gray-300 ">
                                        <input
                                            type="text"
                                            className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                            value={templateData?.togglecolour}
                                            onChange={(e) => handleInputChange(types[7], e.target.value)} />
                                        <input
                                            type="color"
                                            className="mt-2 mb-2 ml-4 border w-[30%] bg-transparent mr-2 "
                                            value={templateData?.togglecolour}
                                            onChange={(e) => handleInputChange(types[7], e.target.value)} />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row  space-x-8">
                            <div className="flex flex-col w-40">
                                <span className="font-bold text-xs">Width</span>
                                <Input type="number" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" placeholder="Width" value={templateData?.width} onChange={(e) => handleInputChange(types[3], e.target.value)} />
                            </div>
                            <div className="flex flex-col w-40">
                                <span className="font-bold text-xs">Heigth</span>
                                <Input type="number" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" placeholder="Heigth" value={templateData?.heigth} onChange={(e) => handleInputChange(types[4], e.target.value)} />
                            </div>
                        </div>
                        <div className="flex flex-row   space-x-8">
                            <div className="flex flex-col w-40">
                                <span className="font-bold text-xs">Border Radius</span>
                                <Input type="number" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40 text-xs" placeholder="Border Radius" max="50" maxLength={100} value={templateData?.radius} onChange={(e) => handleInputChange(types[5], e.target.value)} />
                            </div>

                        </div>

                        {/* </div> */}
                    </div>
                    <div className="flex-initial flex items-start border-dashed border-slate-200 border-2 ">
                        <div style={{ width: `630px`, height: `410px` }} className="md:w-1/4">
                            {/* <div>
                                <div className="rounded-md bg-slate-200 h-4 w-36 mt-4 ml-5">
                                    </div>
                                    <div className="rounded-md bg-slate-200 h-4 w-60 mt-4 ml-5"></div>
                            </div> */}
                            <div className={`grid grid-cols-1 p-4 gap-8`}>{data.map((index) => {
                                return (
                                    <div className="p-5 border border-gray-300 flex items-center" style={{
                                        backgroundColor: backgroundColor, borderRadius: `${templateData.radius}px`, width: `${templateData.width}px`,
                                        height: `${templateData.heigth}px`,
                                    }}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <div className="basis-[60%]">
                                            <Link
                                                to={`/apps/createtemplates`}
                                                //   onClick={onClickHandler}
                                                className="text-base text-komp-color font-semibold table hover:text-komp-hover-color"
                                                style={{ color: templateData?.text }}
                                            >
                                                <span className="mr-2 ">Konnector name</span>
                                            </Link>
                                            <p
                                                className="flex text-xs text-gray-500"
                                                style={{ color: templateData?.text }}
                                            >
                                                <span className="flex items-center">
                                                    <span className="w-40 truncate">Description about the konnector</span>
                                                </span>
                                            </p>
                                        </div>
                                        <div className="flex justify-between basis-[40%]">
                                            {/* <KonnectorApps
                                                            apps={konnector?.konnectorApps}
                                                            ruleConfig={konnector?.ruleConfig?.length}
                                                            konnectors={konnector}
                                                        /> */}
                                            <div className="ml-auto flex items-center justify-center">
                                                <div className="flex">
                                                    <div className="flex items-end ml-60">
                                                        {/* <Switch id="airplane-mode" /> */}
                                                        <label className="relative inline-flex  items-end cursor-pointer">
                                                            <input type="checkbox" value="" className="sr-only peer" checked={true}
                                                                onChange={handleCheckboxChange}></input>
                                                            <div className="w-11 h-6 bg-gray-200  rounded-full peer 
                                                             peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white 
                                                             after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 
                                                             after:border after:rounded-full after:h-5 after:w-5 after:transition-all " style={{ backgroundColor: isChecked ? `${templateData?.togglecolour}` : 'gray' }}></div>
                                                        </label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                            </div>
                        </div>
                    </div>
                </div>
            </form></>}</>);
};
export default TemplateTab;


