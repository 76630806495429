import React, { useEffect, useRef, useState } from "react";
import { useAppSelector, useAppDispatch } from "state/store";
import { toast } from "react-toastify";
import { StatusType } from "state/new-konnector/types";
import { useHistory, useParams } from "react-router-dom";
import CancelModal from "common/components/Modal/CancelModel";
import EmbeddedCancelModal from "common/components/Modal/EmbeddedCancelModel";
import { ActionMode } from "state/edit-konnector/types";
import SaveModal from "common/components/Modal/SaveModal";
import { ActionType } from "interfaces/pages/create-new-konnector";
import { KonnectorType } from "pages/KonnectorList/types";
import FormModal from "common/components/Modal/FormModal/FormModal";
import { useForm } from "react-hook-form";
import { reset } from "state/workFlows/slices";
import { WorkflowType } from "state/new-workflow/types";
import { getAccordionActionsUtilityClass } from "@mui/material";

const Header: React.FC<any> = ({ konnectorSlice, konnectorState }) => {
  const history = useHistory();
  const { register, handleSubmit, setValue, watch } = useForm();
  const lastActionOrderId = useAppSelector((state) => state[konnectorState].actionOrderId);
  const konnectorName = useAppSelector((state) => state[konnectorState].data.name);

  const shouldReconfigure = useAppSelector((state) =>
    state[konnectorState].data.lookups.find((lookup) => lookup.shouldReconfigure) ||
      state[konnectorState].data.actions.find((action) => action.shouldReconfigure)
      ? true
      : false
  );
  const { appId } = useParams<any>();
  const actionMode = useAppSelector((state) => state[konnectorState].actionMode);
  const konnectorActions = useAppSelector((state) => state[konnectorState].data.actions);
  const hasKonnectorCreated = useAppSelector((state) => state[konnectorState].created);
  const hasActionDynamicInput = useAppSelector((state) => state[konnectorState].hasActionDynamicInput);
  const hasDynamicInput = useAppSelector((state) => state[konnectorState].hasDynamicInput);
  const konnectorType = useAppSelector((state) => state[konnectorState].data.workflowType);
  const konnStatus = useAppSelector((state) => state[konnectorState].status);
  const editRule = useAppSelector((state) => state[konnectorState].editRule);
  const saveButtonStatus = true;
  const errMsg = useAppSelector((state) => state[konnectorState].errorMessage);
  const formId = useAppSelector((state) => state[konnectorState].data.webhookUrl.id);
  const [saveModal, setSaveModal] = useState(false);
  const [width, setWidth] = useState(100);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const inputFocus = useRef(null);
  const [showFormModal, setShowFormModal] = useState(false);
  //  const authId = useAppSelector((state) => state.auth.app);
  // const appIds = triggerId === authId ? workflowAppId : triggerId;
  // const workflowDesk = watch("workflowDescription");
  // const workflowInstruct = watch("workflowInstruction");
  // useEffect(() => {
  //   dispatch(konnectorSlice.updateDescription(workflowDesk));
  // }, [workflowDesk]);

  // useEffect(() => {
  //   dispatch(konnectorSlice.updateInstruction(workflowInstruct));
  // }, [workflowInstruct]);

  // const handleInputChange1 = (e) => {};

  // const handleInputChange2 = (e) => {};
  let getsourceId;

  getsourceId = useAppSelector((state) => {
    if (hasActionDynamicInput && hasDynamicInput) {
      return true;
    }
    return state[konnectorState].data.trigger.outputFields.schema;
  });

  const getappId = useAppSelector((state) => state[konnectorState].data.trigger.appId);
  const actionId = useAppSelector((state) => state[konnectorState].data.actions[0]?.appId);
  const authId = useAppSelector((state) => state.auth.app);

  useEffect(() => {
    setWidth(ref.current.offsetWidth);
  }, [konnectorName]);

  const handleClick = () => {
    inputFocus.current.focus();
  };

  const onChangeNameHandler = (e) => {
    const value = e.target.value;
    dispatch(konnectorSlice.updateName(value));
  };

  const closeFormModalHandler = () => {
    setShowFormModal(false);
    history.push("/");
  };
  const onSaveKonnectorHandler = (e) => {
    e.preventDefault();
    if (konnectorName.includes("Untitled") || konnectorName === "" || konnectorName === "untitled") {
      setSaveModal(true);
    } else {
      setSaveModal(false);
      dispatch(konnectorSlice.saveKonnectorLoading(getappId));
    }
  };

  const closeModalHandler = () => {
    setShowModal(false);
  };
  const confirmModalHandler = () => {
    setShowModal(false);
  };
  const onCloseModalHandler = () => {
    setSaveModal(false);
  };

  useEffect(() => {
    if (hasKonnectorCreated) {
      const toastMessage =
        konnectorState === "createNewWorkflow" ? "Workflow created successfully" : "Workflow updated successfully";
      toast.success(toastMessage, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          if (getappId == authId) {
            history.push(`/KompApps/templates?appId=${actionId}`);
          }
          if (konnectorType === "webhook") {
            history.push(`/KompApps/templates?appId=${actionId}`);
          }
          else if (getappId !== authId) {
            history.push(`/KompApps/templates?appId=${getappId}`);
          }
        },
      });
    }

    if (konnStatus === StatusType.failed && errMsg) {
      toast.error(errMsg, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => {
          history.push("/");
        },
      });
    }
    // if (hasKonnectorCreated && konnectorType === KonnectorType.Form) {
    //   setShowFormModal(true);
    // }
  }, [hasKonnectorCreated, konnStatus]);

  return (
    <>
      <header className="flex sticky px-20 bg-white justify-between z-10 top-0 py-5">
        <div className="w-[60%]">
          <div className="flex items-center mb-5">
            <span className="opacity-0 absolute z-[-100]" ref={ref}>
              {konnectorName}
            </span>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Konnector Name"
              style={{ width }}
              maxLength={100}
              value={konnectorName}
              ref={inputFocus}
              onChange={onChangeNameHandler}
              className="min-w-[200px] py-2 border-b-2 bg-transparent border-gray-400 text-gray placeholder-gray-500 outline-none"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="gray"
              className="ml-2 bi bi-pencil-fill"
              viewBox="0 0 16 16"
              onClick={handleClick}
            >
              <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
            </svg>
          </div>
          {/* {workflowDescription === "" ? (
            <div className="flex gap-3 my-4">
              <div>
                <label>Enter the description</label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  placeholder="description..."
                  onChange={handleInputChange1}
                  {...register("workflowDescription")}
                />
              </div>
              <div>
                <label>Enter the Instruction</label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  placeholder="Instructions..."
                  onChange={handleInputChange2}
                  {...register("workflowInstruction")}
                />
              </div>
            </div>
          ) : (
            <div className="flex gap-3 my-4">
              <div>
                <label>Enter the description</label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  placeholder="description..."
                  onChange={handleInputChange1}
                  value={workflowDescription}
                  {...register("workflowDescription")}
                />
              </div>
              <div>
                <label>Enter the Instruction</label>
                <input
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-96 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  type="text"
                  placeholder="Instructions..."
                  onChange={handleInputChange2}
                  value={workflowInstruction}
                  {...register("workflowInstruction")}
                />
              </div>
            </div>
          )} */}
        </div>

        <div>
          {showModal && (
            <EmbeddedCancelModal
              text={""}
              visible={showModal}
              closeModalHandler={closeModalHandler}
              confirmHandler={confirmModalHandler}
            />
          )}
          <button
            type="button"
            onClick={() => setShowModal(true)}
            className="py-2 px-5 mr-2 mb-2 text-sm font-medium text-primary-color bg-gray-100 rounded-sm"
          >
            Cancel
          </button>

          {saveModal && (
            <SaveModal
              name={konnectorName}
              ref={ref}
              visible={saveModal}
              closeModalHandler={onCloseModalHandler}
              confirmModalHandler={onSaveKonnectorHandler}
              onChangeNameHandler={onChangeNameHandler}
            />
          )}
          <button
            type="button"
            className="text-white  bg-komp-secondary-color hover:bg-[#1f324e] font-medium rounded-sm text-sm px-5 py-2 text-center mr-2 mb-6 hover:transition-all focus:outline-none disabled:opacity-50"
            onClick={onSaveKonnectorHandler}
            disabled={
              !konnectorActions.length ||
              shouldReconfigure ||
              actionMode === ActionMode.Edit ||
              (saveButtonStatus && konnStatus === StatusType.loading) ||
              hasKonnectorCreated ||
              (lastActionOrderId == -1 && !ActionType.Action) ||
              !getsourceId ||
              editRule === true
            }
          >
            Save
          </button>
        </div>
      </header>
      <FormModal visible={showFormModal} closeModalHandler={closeFormModalHandler} url={formId} />
    </>
  );
};

export default Header;
