import React from "react";
import {
  ParseEngineIcon,
  RuleEngineIcon,
} from "pages/KonnectorPage/components/ConfigureKonnector/KonnectifyEngine/Icons";
import Badge from "../../Badge";
import moment from "moment";
export interface ActionsProps {
  name: string;
  id: string;
  status: "success" | "failure";
}

const ParseSummary: React.FC<any> = ({ status, handleActionChange, name, dtUpdated, outputFields, title }) => {
  const ruleIconClassName = "h-6 w-6 opacity-50 inline-flex";
  return (
    <tr className="bg-gray-100">
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">Parse engine</td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
        <ParseEngineIcon className={ruleIconClassName} /> <span className="inline-flex">Parse engine</span>
      </td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{moment(dtUpdated).fromNow()}</td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
        <Badge type={status} text={status} outputFields={outputFields} title={title} />
      </td>
      <td className="px-4 py-4 text-sm text-gray-800 whitespace-nowrap">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="w-4 h-4 cursor-pointer"
          onClick={() => handleActionChange()}
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
        </svg>
      </td>
    </tr>
  );
};

export default ParseSummary;
