import React from "react";
import { editSource } from "state/edit-konnector/slices";
import { useAppDispatch, useAppSelector } from "state/store";
const SidebarControls: React.FC = () => {
  const dispatch = useAppDispatch();

  const hasConfigurationSaved = useAppSelector((state) => state.editKonnector.hasConfigurationSaved);
  const onclickTirggerHandler = () => {
    if (hasConfigurationSaved) {
      // dispatch(editSource());
    }
  };
  const configurationType = useAppSelector((state) => state.editKonnector.configurationType);

  const currentActionId = useAppSelector((state) => state.editKonnector.currentActionId);
  return (
    <div className="border-r-2  h-[calc(100vh-5rem)] w-[20%] flex flex-col pt-20">
      {(currentActionId && configurationType === "target") ||
      (currentActionId && configurationType === "lookup") ||
      (currentActionId && configurationType === "ruleEngine") ? (
        <button
          type="button"
          className="py-3 w-full px-6 mr-2 mb-2 text-sm font-medium text-primary-color bg-white rounded-sm"
        >
          Trigger
        </button>
      ) : (
        <button
          type="button"
          onClick={onclickTirggerHandler}
          className="text-white w-full bg-primary-color hover:bg-[#1f324e] font-medium rounded-sm text-sm px-6 py-3 text-center mr-2 mb-6 hover:transition-all"
        >
          Trigger
        </button>
      )}
      {(currentActionId && configurationType === "target") ||
      (currentActionId && configurationType === "lookup") ||
      (currentActionId && configurationType === "ruleEngine") ? (
        <button
          type="button"
          className="py-3 w-full px-6 mr-2 mb-2 text-sm font-medium text-white bg-primary-color rounded-sm"
        >
          Actions
        </button>
      ) : (
        <button
          type="button"
          className="py-3 w-full px-6 mr-2 mb-2 text-sm font-medium text-primary-color bg-white rounded-sm"
        >
          Actions
        </button>
      )}
    </div>
  );
};

export default SidebarControls;
