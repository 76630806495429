import { Input } from "components/ui/input";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLayoutData } from "state/Embedded-markteplace/ThemeSettings/slices";
import { useAppSelector } from "state/store";

const LayoutTab: React.FC<any> = ({ types, colors, onColorChange, onChange }) => {
    const dispatch = useDispatch()
    const layoutData = useAppSelector((state) => state.themeSettings.layout)


    const [localColors, setLocalColors] = useState(colors);

    const handleInputChange = (type, value) => {
        setLocalColors((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: value,
        }));
        dispatch(setLayoutData({ type: type.toLowerCase(), value }))
        onColorChange(type, value);
    };


    return (
        <>
            <div className="grid grid-cols-4 gap-24">
                <div className="flex flex-col">
                    <div className="flex flex-col w-40"><span className="font-bold text-xs">Width</span>
                        <Input type="number" placeholder="Width" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40" max="1449" maxLength={1440} value={layoutData?.width} onChange={(e) => handleInputChange(types[0], e.target.value)} />
                    </div>
                </div>
                <div className="flex flex-col w-40"><span className="font-bold text-xs">Heigth</span>
                    <Input type="number" placeholder="Width" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40" max="780" maxLength={780} value={layoutData?.heigth} onChange={(e) => handleInputChange(types[1], e.target.value)} />
                </div>
            </div>
        </>
    );
};

export default LayoutTab;


