import { AxiosResponse } from "axios";
import { activateKonnectorApi, deactivateKonnectorApi, deleteKonnectorApi, updateWorkflowIndex } from "api/workflowApi";
import { takeEvery, put, call } from "redux-saga/effects";
import { ErrorStatus } from "./types";
import {
  activateKonnectorLoading,
  deactivateKonnectorLoading,
  deactivateKonnectorSuccess,
  activateKonnectorSuccess,
  apiError,
  deleteKonnectorLoading,
  deleteKonnectorSuccess,
  displayErrorMessage,
  activateEmbeddedAppLoading,
  activateAppSuccess,
  deactivateAppSuccess,
  deAactivateEmbeddedAppLoading,
  setWorkflowIndex,
} from "./slices";
import { activateAppApi, deactivateAppApi } from "api/appsApi";
import { setAppValue } from "state/auth/slices";

function* activateKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(activateKonnectorApi, data.payload);
    const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
    if (statusCode) {
      yield put(displayErrorMessage(result.data.message));
      throw Error(result.data.message);
    }
    yield put(activateKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* deactivateKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(deactivateKonnectorApi, data.payload);
    yield put(deactivateKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* deleteKonnector(data) {
  try {
    const result: AxiosResponse<any> = yield call(deleteKonnectorApi, data.payload);
    yield put(deleteKonnectorSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* activateEmbeddedApp(data) {
  try {
    const result: AxiosResponse<any> = yield call(activateAppApi, data.payload);
    const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
    if (statusCode) {
      yield put(displayErrorMessage(result.data.message));
      throw Error(result.data.message);
    }
    yield put(activateAppSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* deActivateEmbeddedApp(data) {
  try {
    const result: AxiosResponse<any> = yield call(deactivateAppApi, data.payload);
    yield put(deactivateAppSuccess(result.data));
    yield put(setAppValue(result.data))
    console.log(result.data);
  } catch (error) {
    yield put(apiError(error.message));
  }
}
function* setWorkflowsIndex(data) {
  try {
    const result: AxiosResponse<any> = yield call(updateWorkflowIndex, data.payload);
    // yield put(deactivateAppSuccess(result.data));
    console.log(result.data);
  } catch (error) {
    yield put(apiError(error.message));
  }
}

export default function* rootSaga() {
  yield takeEvery(activateKonnectorLoading, activateKonnector);
  yield takeEvery(deactivateKonnectorLoading, deactivateKonnector);
  yield takeEvery(deleteKonnectorLoading, deleteKonnector);
  yield takeEvery(activateEmbeddedAppLoading, activateEmbeddedApp);
  yield takeEvery(deAactivateEmbeddedAppLoading, deActivateEmbeddedApp);
  yield takeEvery(setWorkflowIndex, setWorkflowsIndex)
}
