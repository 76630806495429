export const getAuth = (state) => state.auth;
export const getNewKonnector = (state) => state.newKonnectorPage;
export const getKonnectorsSlice = (state) => state.konnectors;
export const getWorkflowSlice = (state) => state.workflows;
export const getEventLogsSlice = (state) => state.eventLogs;
export const getEditKonnectorsSlice = (state) => state.editKonnector;
export const getPaymentsSlice = (state) => state.payments;
export const getRuleConfigSlice = (state) => state.ruleEngine;
export const getRuleEngineSlice = (state) => state.ruleSlice;
export const getParseEngineSlice = (state) => state.parseEngine;
export const getFormFieldSlice = (state) => state.formFields;
export const getCreateWorkflowSlice = (state) => state.newWorkflowPage;
export const getEditWorkFlowSlice = (state) => state.editWorkFlow;
export const getWorkflowAppSlice = (state) => state.workflows;
export const getAdminSettingsSlice = (state) => state.adminSettings;
export const getThemesettingSlice = (state) => state.themeSettingSlice
