import useSWR from "swr";
import { axiosInstance, fetcher, options } from "./libs/fetch";

const APP_ACCOUNT_PAGE_LIMIT = 20;
const ACCOUNT_PAGE_LIMIT = 16;

export function useAccounts(pageNumber = 1) {
  return useSWR([`/connection-config?pageNumber=${pageNumber}&pageSize=${ACCOUNT_PAGE_LIMIT}`], fetcher);
}

export function useAppAccounts(appId, pageNumber = 1) {
  return useSWR([`/connection-config/${appId}?pageNumber=${pageNumber}&pageSize=${APP_ACCOUNT_PAGE_LIMIT}`], fetcher);
}

export function fetchAccount(accountId) {
  return axiosInstance.get(`/connection-config/${accountId}`);
}

export function createAccountApi(data) {
  return axiosInstance.post(`/connection-config/${data.appId}`, data, options);
}

export function updateAccountApi(accountId, data) {
  return axiosInstance.put(`/connection-config/${data.appId}/${accountId}`, data, options);
}

export function validateAccountApi(data) {
  return axiosInstance.post(`/connection-config/validate/${data.appId}`, data, options);
}
