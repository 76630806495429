import React, { useState, useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "state/store";
import { getEventLogsFilters } from "state/Embedded-markteplace/event-logs/selectors";
import { reset, resetCalendar, setFilters } from "state/Embedded-markteplace/event-logs/slices";
import SelectKonnector from "./SelectKonnector";
import SelectApp from "./SelectApp";
import TimeFrame from "./SelectTimeFrame";
import SelectUserEmail from "./SelectUserEmail";

const Filters = () => {
  const dispatch = useAppDispatch();
  const selectedFilter = useAppSelector(getEventLogsFilters);
  const [konnector, setKonnector] = useState(selectedFilter.konnectorName);
  const [app, setApp] = useState(selectedFilter.appName);
  const [date, setDate] = useState(selectedFilter.date);
  const [user, setUserId] = useState(selectedFilter.userId);
  const [isClear, setClear] = useState(false);
  const onClickFilterHandler = useCallback(() => {
    dispatch(setFilters({ konnectorName: konnector, appName: app, date: date, userId: user }));
  }, [dispatch, konnector, app, date, user]);
  const onClickFilterClear = () => {
    setKonnector("");
    setUserId("");
    setApp("");
    setDate("");
    setClear(!isClear);
    dispatch(reset());
    dispatch(resetCalendar())
  };
  const onChangeKonnectorHandler = (konnectorValue) => {
    setKonnector(konnectorValue.value);
  };
  const onChangeUserHandler = (konnectorValue) => {
    setUserId(konnectorValue.value);
  };
  const onChangeAppHandler = (e) => {
    setApp(e.value);
  };

  return (
    <div className=" flex flex-col h-[81%] ml-auto p-2 bg-[#ffffff] space-x-2">
      <div>
        <h4 className="text-primary-color font-medium mb-3 ">Filters</h4>

        <SelectApp onChangeAppHandler={onChangeAppHandler} selectedApp={app} />
        <SelectKonnector
          onChangeKonnectorHandler={onChangeKonnectorHandler}
          selectedApp={app}
          selectedKonnector={konnector}
        />
        <TimeFrame setDate={setDate} isClear={isClear} setClear={setClear} />
        <SelectUserEmail onChangeUserHandler={onChangeUserHandler} selectedKonnector={user} />
      </div>
      <div className="flex flex-row mb-5">
        <button
          onClick={onClickFilterHandler}
          className="justify-center text-white w-full bg-komp-color hover:bg-komp-color font-medium rounded-lg text-sm px-6 py-3 text-center mr-2 mb-7   hover:transition-all"
        >
          Apply
        </button>
        <button
          onClick={onClickFilterClear}
          className="justify-center text-black w-full bg-white border border-gray-200  font-medium rounded-lg text-sm px-6 py-3 text-center mr-2 mb-7   hover:transition-all"
        >
          Clear
        </button>
      </div>
    </div>
  );
};

export default Filters;
