import SingleSelectField from "common/components/FormFields/SingleSelect";
import React, { useEffect, useState } from "react";
import { setSourceFieldDatatype } from "state/rule-engine/slices";
import { useAppDispatch, useAppSelector } from "state/store";
import { Field } from "utils/appsUtils";
import { flatSourceOutputFields } from "utils/konnectorUtils";
import { Choices } from "pages/KonnectorPage/types";
import { StatusType } from "state/new-konnector/types";

const OperatorType: React.FC<any> = ({ register, watchField, errors, setValue, index, filterIndex }) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);

  const status = useAppSelector((state) => state.ruleEngine.status);
  const [data, setdata] = useState([]);
  const field = { propName: "field_3_" + index, propType: "string", isRequired: true };

  const getSourceFields = (state) => {
    const triggerSourceFields = state[konnectorState].data.trigger.outputFields.schema;
    const lookupSourceFields = state[konnectorState].data.lookups.length
      ? state[konnectorState].data.lookups.map((lookup) => lookup.outputFields.schema)
      : [];
    return [triggerSourceFields, ...lookupSourceFields];
  };
  const sourceFields: any = useAppSelector(getSourceFields);
  const [sourceField, sourceId] = watchField([
    `filter_${filterIndex}_condition_${index}___field_source_field`,
    `filter_${filterIndex}_condition_${index}___field_source_type`,
  ]);
  const findSourceFields = sourceFields.find((item) => item.sourceId === sourceId);

  const propTypeData = sourceField
    ? findSourceFields
      ? flatSourceOutputFields(findSourceFields.data).filter(
          (field) => field.propType !== "object" && field.propType !== "array"
        )
      : []
    : [];
  const propType = propTypeData?.find((field) => field.propName === sourceField)?.propType;

  const getFieldOptions = () => {
    return new Field(field).format();
  };

  const getChoices = (propType) => {
    switch (propType) {
      case "string": {
        return Choices.string;
      }
      case "boolean": {
        return Choices.boolean;
      }
      case "number": {
        return Choices.numeric;
      }
      case "date": {
        return Choices.date;
      }
      default: {
        return Choices.default;
      }
    }
  };

  useEffect(() => {
    dispatch(setSourceFieldDatatype({ filterIndex: filterIndex, conditionIndex: index, dataType: propType }));
  }, [propType]);

  useEffect(() => {
    setdata([...data, sourceField]);
    if (data[data.length - 1] && status !== StatusType.loading) {
      setValue(`filter_${filterIndex}_condition_${index}___field_operator_type`, "");
    }
  }, [sourceField]);

  return (
    <>
      <div className="w-[50%]">
        <SingleSelectField
          placeholder="Choose operator type"
          choices={getChoices(propType)}
          {...getFieldOptions()}
          name={`filter_${filterIndex}_condition_${index}___field_operator_type`}
          register={register}
          errors={errors}
        />
      </div>
    </>
  );
};

export default OperatorType;
