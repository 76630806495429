import { AppObject } from "pages/Apps/types";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { DraggableIcon } from "pages/KonnectorPage/components/ConfigureKonnector/FormBuilder/Icons/DragableIcon";

interface AppItemProps {
    app: AppObject;
    index: number
}

const AppItem: React.FC<AppItemProps> = ({ app, index }) => {
    return (
        <>
            <Draggable key={app.id} draggableId={app.id} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className="flex h-[55px] w-[50rem] flex-row items-start cursor-pointer  transition-all border-[1px] border-gray-300 p-4 space-x-5">
                        <div className="flex flex-col items-center mb-8" {...provided.dragHandleProps}>
                            <DraggableIcon></DraggableIcon>
                        </div>
                        <div className="w-6 items-start">
                            <img src={app.iconUrl} alt="" />
                        </div>
                        <div className="">
                            <h3 className="text-base font-semibold">{app.appName}</h3>
                        </div>
                    </div>
                )}
            </Draggable>
        </>
    );
};

export default AppItem;
