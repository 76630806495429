import React from "react";

const AllApps: React.FC<any> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_2"
      data-name="Layer 2"
      viewBox="0 0 22.43 19.5"
      className="h-5 w-5"
    >
      <defs>
        <style>
          {`
      .event-log-1 {
        fill: #bbbdc1;
      }

      .event-log-2 {
        fill: #727b88;
      }`}
        </style>
      </defs>
      <g id="darkmode_1" data-name="darkmode 1">
        <g id="sidebar">
          <g>
            <rect className="event-log-2" x="6.5" y=".01" width="15.93" height="4.55" rx="1.77" ry="1.77" />
            <rect className="event-log-1" width="4.61" height="4.58" rx=".84" ry=".84" />
            <rect className="event-log-2" x="6.5" y="7.43" width="15.93" height="4.55" rx="1.77" ry="1.77" />
            <rect className="event-log-1" y="7.42" width="4.61" height="4.58" rx=".84" ry=".84" />
            <rect className="event-log-2" x="6.5" y="14.93" width="15.93" height="4.55" rx="1.77" ry="1.77" />
            <rect className="event-log-1" y="14.92" width="4.61" height="4.58" rx=".84" ry=".84" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AllApps;
