import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { mutate } from "swr";
import useKonnectors from "api/konnectorsApi";

import {
  getworkflowsCurrentPage,
  getworkflowsFilterType,
  getworkflowsSortBy,
  getworkflowsDeleted,
  getAppId,
} from "state/workFlows/selectors";
import { useAppDispatch, useAppSelector } from "state/store";
import Konnectors from "./components/Konnectors";
import SidebarControls from "./components/SidebarControls";
import { updateFilter, updateSortBy, resetStatus, resetDeactivation, setWorkflowIndex, setApp } from "state/workFlows/slices";
import Spinner from "common/components/Spinner";
import { toast } from "react-toastify";
import { getKonnectorsSlice } from "state/selectors";
import { setKonnectorStatus } from "state/payment/slices";
import { PlanType } from "state/payment/types";
import useWorkflow from "api/workflowApi";
import { FilterType } from "state/workFlows/types";
import { DragDropContext } from "react-beautiful-dnd";
import { useCurrentUserApi } from "api/authApi";

const WorkFlowListPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector(getworkflowsSortBy);
  const filterBy = useAppSelector(getworkflowsFilterType);
  const activeState = useAppSelector((state) => state.workflows.activeSortBy);
  const activeQueryValue = activeState.split("-")[0] || "";
  const activeSortId = activeState.split("-")[1] || "";
  const getKonnectors = useAppSelector(getKonnectorsSlice);
  const currentPage = useAppSelector(getworkflowsCurrentPage);
  const hasDeleted = useAppSelector(getworkflowsDeleted);
  const hasActivated = useAppSelector((state) => state.workflows.hasActivated);
  const hasDeactivated = useAppSelector((state) => state.workflows.hasDeactivated);
  const queryValue = sortBy.split("-")[0] || "";
  const sortId = sortBy.split("-")[1] || "";
  const query = new URLSearchParams(location.search);
  const id: any = query.get("appId");
  useEffect(() => {
    if (id) {
      dispatch(setApp(id))
    }
  }, [id])
  const { data: embeddedApps, error: embeddedAppsError } = useCurrentUserApi();
  let activeSortBy;
  if (filterBy === FilterType.ACTIVE) {
    activeSortBy = activeState;
  } else {
    activeSortBy = "";
  }
  const { data: konnectors, error: konnectorsError } = useWorkflow(id, currentPage, sortBy, activeSortBy, filterBy);
  const [sortData, setSortData] = useState({ data: {} })
  const [arr, setArr] = useState([])
  const currentPlan = useAppSelector((state) => state.payments.currentPlan);
  useEffect(() => {
    if (getKonnectors.status === "failed") {
      toast.error(getKonnectors.errorMessage ? getKonnectors.errorMessage : "Error Performing Action", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [getKonnectors.status]);

  useEffect(() => {
    if (hasDeleted) {
      mutate(
        `/embedded/apps/${id}/all?pageNumber=1&pageSize=100&sortValue=${queryValue}&sortOrder=${sortId}`
      );
      mutate(`/users/current_user`)
      dispatch(resetStatus());
    }
  }, [hasDeleted]);

  useEffect(() => {
    if (hasDeleted) {
      mutate(
        `/embedded/apps/${id}/all?pageNumber=${currentPage}&pageSize=5&filterBy=status&sortValue=${activeQueryValue}&sortOrder=${activeSortId}`
      );
      mutate(`/users/current_user`)
      dispatch(resetStatus());
    }
  }, [hasDeleted]);

  useEffect(() => {
    if (filterBy && konnectors && embeddedApps) {
      const datas = {
        data: {
          items: [],
          pageNumber: konnectors?.data.pageNumber,
          totalItems: konnectors?.data.totalItems
        }
      }
      embeddedApps?.data?.activeWorkflows?.filter((app) => {
        if (app.appId === id) {
          app.value.filter((val) => {
            konnectors?.data?.items.filter((items) => {
              if (val === items.id) {
                console.log(items, "items")
                datas.data.items.push(items)
              }
            })
          })
        }
      });
      console.log(datas, "datas")
      setSortData(datas)
      setArr(datas.data.items)
    }
  }, [filterBy, konnectors, embeddedApps, hasDeactivated]);


  useEffect(() => {
    if (filterBy) {
      mutate(
        `/users/current_user`
      );
      mutate(`embedded/workflows?pageNumber=${currentPage}&pageSize=5&sortValue=${queryValue}&sortOrder=${sortId}`);

      dispatch(resetStatus());
    }
  }, [filterBy]);

  useEffect(() => {
    if (getKonnectors.status == "success") {
      mutate(`embedded/workflows?pageNumber=${currentPage}&pageSize=5&sortValue=${queryValue}&sortOrder=${sortId}`);
      dispatch(resetStatus());
    }
  }, [getKonnectors.status]);

  useEffect(() => {
    if (hasActivated) {
      mutate(
        `/embedded/apps/${id}/all?pageNumber=${currentPage}&pageSize=5&sortValue=${queryValue}&sortOrder=${sortId}`
      );
      dispatch(resetStatus());
    }
  }, [hasActivated]);
  useEffect(() => {
    if (hasDeactivated) {
      mutate(
        `/embedded/apps/${id}/all?pageNumber=${currentPage}&pageSize=5&filterBy=status&sortValue=${activeQueryValue}&sortOrder=${activeSortId}`
      );
      mutate(`/users/current_user`)
      dispatch(resetDeactivation());
    }
  }, [hasDeactivated]);

  useEffect(() => {
    const params = new URLSearchParams({ sortBy: sortBy, filterBy, appId: id });
    history.replace({ pathname: location.pathname, search: params.toString() });
  }, [sortBy, filterBy]);
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const sortBy: any = query.get("sortBy") || "dtCreated-desc";
    const filterBy: any = query.get("filterBy") || "all";
    if (sortBy) {
      dispatch(updateSortBy(sortBy));
      dispatch(updateFilter(filterBy));
    }
  }, []);

  // const onDragEnd = (result) => {
  //   const { source, destination } = result;
  //   if (!result.destination) {
  //     return;
  //   }
  //   if (source.droppableId === "droppable" && destination.droppableId === "droppable") {
  //     const updatedItems = arr
  //     const [reorderedItem] = updatedItems.splice(result.source.index, 1);
  //     updatedItems.splice(result.destination.index, 0, reorderedItem);
  //     let workflowItems = []
  //     updatedItems.filter((items: any) => {
  //       workflowItems.push(items.id)
  //     })
  //     dispatch(setWorkflowIndex({ workflowItems: workflowItems, workflowId: result.draggableId }))
  //   }
  // }

  useEffect(() => {
    if (currentPlan === PlanType.free && konnectors && konnectors.data.items.length >= 5) {
      dispatch(setKonnectorStatus({ type: "freePlan", status: false }));
    } else {
      dispatch(setKonnectorStatus({ type: "", status: true }));
    }
  }, [konnectors]);
  if (konnectorsError) return <p>Failed to load workflows</p>;
  if (!konnectors)
    return <Spinner />

  return (
    <div className="relative   top-20 overflow-auto ml-80">
      <section className="flex  items-start bg-white">
        {/* <DragDropContext onDragEnd={onDragEnd}>
          {console.log("filterBy:", filterBy)} */}
        {filterBy === "active" ? (
          <Konnectors data={sortData?.data} />
        ) : (
          <Konnectors data={konnectors?.data} />
        )}
        {/* </DragDropContext> */}
      </section>
    </div>
  );
};

export default WorkFlowListPage;
