import React, { useEffect } from "react";
import { addFilter, saveDataMapping, saveDataMappingSucces } from "state/rule-engine/slices";
import { useAppSelector } from "state/store";
import FilterItem from "./FilterItem";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { StatusType } from "state/edit-konnector/types";
import { toast } from "react-toastify";

const FilterList: React.FC<any> = () => {
  const filters = useAppSelector((state) => state.ruleEngine.filters);
  const rawDataMapping = useAppSelector((state) => state.ruleEngine.rawDataMapping);
  const saveDataMappingStatus = useAppSelector((state) => state.ruleEngine.saveDataMapping);
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm();
  const renderFilters = () => {
    return filters.map((filter, index) => (
      <FilterItem
        key={filter.name}
        filter={filter}
        index={index}
        register={register}
        watch={watch}
        errors={errors}
        handleSubmit={handleSubmit}
        getValues={getValues}
        reset={reset}
        setValue={setValue}
      />
    ));
  };
  const dispatch = useDispatch();
  const onAddFilter = (e) => {
    e.preventDefault();
    dispatch(addFilter());
  };
  const onSubmit = (data) => {
    dispatch(saveDataMapping(data));
  };
  useEffect(() => {
    if (rawDataMapping && saveDataMappingStatus === StatusType.loading) {
      reset(rawDataMapping);
      dispatch(saveDataMappingSucces());
    }
  }, [saveDataMappingStatus]);
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const getsourceId = useAppSelector((state) => state[konnectorState].data.trigger.outputFields.schema);
  useEffect(() => {
    if (!getsourceId) {
      toast.info("Your account is suspended.Please reconfigure konnector", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, []);
  if (!getsourceId) return (
    <span className="ml-2 bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded-sm dark:bg-red-100 ">
      Your account is suspended.Please reconfigure konnector
    </span>
  );
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {renderFilters()}
        <div className="flex mb-8 justify-items-end">
          <div className="w-2/3 flex justify-end">
            {filters.length > 4 ? null : (
              <button
                onClick={(e) => onAddFilter(e)}
                className="mt-2 mr-20 text-center text-sm flex bg-white  border-[1px] hover:bg-primary-color transition-all hover:text-white border-solid text-primary-color py-2 px-4  rounded-md w-30"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="primary-color"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="">Add Filter</p>
              </button>
            )}
          </div>
          <div className="mt-3 w-1/3 flex justify-end">
            <button
              type="submit"
              className="text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all focus:ring-4  font-medium rounded-sm text-sm px-5 py-2.5 text-center "
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
export default FilterList;
