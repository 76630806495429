import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppsProps } from "pages/KonnectorPage/types";
import { StatusType, KonnectorType } from "state/new-konnector/types";
import { useParams } from "react-router-dom";
import { KonnectorState } from "state/konnectors/types";
import { WorkFlowState } from "state/workFlows/types";

const SelectApp: React.FC<AppsProps> = ({ apps, konnectorSlice, konnectorState }) => {
  const { id, appId } = useParams<any>();
  const selectedApp = useAppSelector((state) => state[konnectorState].data.trigger.appId);
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const editSource = useAppSelector((state) => state.createNewWorkflow.editSource);
  const dispatch = useAppDispatch();
  const getApp = (value) => {
    return apps.find((app) => app.id === value);
  };

  const { other } = useParams<any>();
  const onChangeAppHandler = (data) => {
    if (!data.value) {
      return dispatch(konnectorSlice.updateSourceAppLoading({ appId: data.value }));
    }
    const app = getApp(data.value);
    switch (data.label) {
      case "webhook":
        return dispatch(konnectorSlice.updateKonnectorType(KonnectorType.WebHook));
      case "form":
        return dispatch(konnectorSlice.updateKonnectorType(KonnectorType.Form));
    }
    dispatch(konnectorSlice.updateSourceAppLoading({ appId: data.value, iconUrl: app.iconUrl, appName: app.appName }));
  };

  // useEffect(() => {
  //   if (appId && !editSource && !selectedApp) {
  //     const appWithDesiredId = apps.find((app) => app.id === appId);
  //     dispatch(
  //       konnectorSlice.updateSourceAppLoading({
  //         appId: appWithDesiredId?.id,
  //         iconUrl: appWithDesiredId?.iconUrl,
  //         appName: appWithDesiredId?.appName,
  //       })
  //     );
  //   }
  // }, [selectedApp]);
  const renderApps = () => {
    const appWithDesiredId = apps.find((app) => app.id === appId);
    return [
      {
        label: appWithDesiredId.appName,
        value: appWithDesiredId.id,
      },
    ];
  };

  const renderApps1 = () => {
    return apps.map((app) => ({
      label: app.appName,
      value: app.id,
    }));
  };

  useEffect(() => {
    return () => {
      // reset the status
      if (apiStatus == StatusType.success && selectedApp) {
        dispatch(konnectorSlice.resetStatus());
      }
    };
  }, [selectedApp]);
  useEffect(() => {
    if (other) {
      dispatch(konnectorSlice.updateKonnectorType(KonnectorType.WebHook));
    }
  }, [selectedApp]);

  // if (konnectorState == "editWorkFlow") {
  //   return (
  //     <div className="mb-3  xl:w-96">
  //       <label className="form-label inline-block mb-2 text-gray-700 text-md">Select App</label>
  //       <Select
  //         options={renderApps1()}
  //         value={renderApps1().filter(function (option) {
  //           return option.value === selectedApp;
  //         })}
  //         onChange={onChangeAppHandler}
  //       />
  //     </div>
  //   );
  // } else if (konnectorState == "createNewWorkflow") {
  //   return (
  //     <div className="mb-3  xl:w-96">
  //       <label className="form-label inline-block mb-2 text-gray-700 text-md">Select App</label>
  //       <Select
  //         options={renderApps1()}
  //         value={renderApps1().filter(function (option) {
  //           return option.value === selectedApp;
  //         })}
  //         onChange={onChangeAppHandler}
  //       />
  //     </div>
  //   );
  // } else {

  return (
    <div className="mb-3  xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select App</label>
      {selectedApp ? (
        <Select
          options={renderApps1()}
          value={renderApps1().filter(function (option) {
            return option.value === selectedApp;
          })}
          onChange={onChangeAppHandler}
        />
      ) : (
        <Select
          options={renderApps1()}
          value={renderApps1().filter(function (option) {
            return option.value === appId;
          })}
          onChange={onChangeAppHandler}
        />
      )}
    </div>
  );
};
// };

export default SelectApp;
