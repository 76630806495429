import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { createParsingItems, deletePattern, reorderItems, updateValue } from "state/parseEngine/slices";
import { useAppSelector } from "state/store";
import { parsingSchema } from "pages/KonnectorPage/types";
import { TrashIcon } from "@heroicons/react/outline";

const ParsingItem: React.FC<any> = ({ index, register, errors, handleSubmit }) => {
  const dispatch = useDispatch();
  const items = useAppSelector((state) => state.parseEngine.parsingItems);
  const ref = useRef();
  const ParsingSchema = parsingSchema;
  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!result.destination) {
      return;
    }
    if (source.droppableId === "source" && destination.droppableId === "drop") {
      const findData = ParsingSchema.find((schema) => schema.id === source.index);
      dispatch(createParsingItems({ id: findData.id.toString(), name: findData.name, value: findData.name }));
    }
    if (source.droppableId === "drop" && destination.droppableId === "drop") {
      console.log("Droppable");
      dispatch(
        reorderItems({ sourceIndex: source.index, destinationIndex: destination.index, dragableId: result.draggableId })
      );
    }
  };

  const onClick = (id) => {
    console.log("onClick", id);
    dispatch(deletePattern(id));
  };
  const onChangeNameHandler = (e,id) => {
    const value = e.target.value;
    dispatch(updateValue({ name: value ,id:id}));
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="h-[50%]  w-full">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="source">
              {(provided) => (
                <>
                  <h2 className=" px-2 text-sm font-semibold mb-2">Please drag and drop your patterns bellow</h2>
                  <div className=" flex flex-row " ref={provided.innerRef} {...provided.droppableProps}>
                    {ParsingSchema.map((item, index) => (
                      <Draggable draggableId={item.id.toString()} index={index}>
                        {(provided) => (
                          <div
                            className="flex flex-row rounded-md p-4 m-2  shadow-md"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="gray"
                              className="w-6 h-6 mr-5"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                              />
                            </svg>
                            {item.name}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </>
              )}
            </Droppable>
            <Droppable droppableId="drop">
              {(provided) => (
                <div
                  className=" p-4 mt-4 w-full bg-[#f5f7f9] w-[100%] border"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {items.length == 0 && <h2 className="text-center">Drop your patterns here</h2>}

                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided) => (
                        <>
                          <div
                            className=" flex w-[70%] rounded-md px-3 py-2 border m-4 shadow-sm"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {item.value == "Custom" ? (
                              <input
                                type="text"
                                id="name"
                                // value={upda}
                                value={item.name}
                                placeholder="Enter your custom pattern"
                                maxLength={100}
                                ref={ref}
                                onChange={(e)=>onChangeNameHandler(e,item.id)}
                                className="min-w-[200px] py-2 border-b-2 bg-transparent border-gray-400 text-gray placeholder-gray-500 outline-none"
                              />
                            ) : (
                              <>{item.name || item.pattern.name}</>
                            )}
                            <TrashIcon onClick={() => onClick(item.id)} className="h-5 w-5 ml-auto cursor-pointer" />
                          </div>
                        </>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </form>
    </>
  );
};

export default ParsingItem;
const sam = {
  draggableId: "1",
  type: "DEFAULT",
  source: {
    index: 0,
    droppableId: "drag",
  },
  reason: "DROP",
  mode: "FLUID",
  destination: {
    droppableId: "droppable",
    index: 0,
  },
  combine: null,
};
