import React from "react";

export const RepeaterIcon: React.FC<any> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className ? className : "mt-5 h-12 w-12"}
      id="Layer_2"
      data-name="Layer 2"
      stroke="currentColor"
      fill="currentColor"
      viewBox="0 0 409.28 375.78"
    >
      <defs>
        {/* <style>
          {`
      .repeater-1 {
        fill: #172f53;
      }`}
        </style> */}
      </defs>
      <g id="Layer_14" data-name="Layer 14">
        <g>
          <path
            // className="repeater-1"
            d="M0,174.3c2.1-11.23,4.25-22.44,8.55-33.12,6.55-16.27,15.99-30.63,28.33-43.04,6.84-6.89,17.23-7,24.16-.64,6.82,6.26,7.66,16.78,1.34,24.09-6.99,8.08-13.96,16.13-18.4,25.94-15.76,34.8-11.91,68.08,9.44,99.07,16.96,24.62,41.18,38.31,70.82,42.25,4.6,.61,9.28,.89,13.92,.9,29.54,.08,59.08,.05,88.62,.03,.89,0,1.85,.3,2.7-.4-.08-1.37-1.28-1.92-2.07-2.72-5.14-5.21-10.08-10.65-15.57-15.47-11.11-9.76-8.61-21.55-2.74-27.36,7.67-7.6,18.1-6.7,27.31,2.51,15.43,15.43,30.86,30.86,46.29,46.29,.75,.75,1.52,1.49,2.25,2.27,6.63,7.04,7.04,17.22,.26,24.14-16.95,17.29-34.07,34.43-51.37,51.36-7.36,7.21-17.53,7.03-24.43,.37-6.93-6.68-7.25-17.04-.31-24.74,5.43-6.02,11.37-11.58,17.06-17.37,1.01-1.02,2.29-1.84,2.81-3.4-1.4-1.18-3.05-.6-4.55-.6-28.21-.04-56.42-.11-84.62,0-28.7,.11-55.35-6.97-79.22-23.03-27.98-18.83-46.64-44.64-55.96-77.14-2.09-7.3-2.91-14.85-4.6-22.23v-27.95Z"
          />
          <path
            // className="repeater-1"
            d="M178.04,86.18c7.92,7.78,15.15,14.47,21.89,21.61,9.26,9.82,4.54,25.57-8.46,28.76-5.94,1.46-11.52,.23-15.91-4.11-17.49-17.32-34.96-34.65-52.2-52.22-6.83-6.96-6.09-17.28,1.29-24.7,16.32-16.42,32.72-32.75,49.11-49.11,.94-.94,1.95-1.82,3.02-2.6,6.06-4.44,12.55-5.13,19.09-1.43,6.53,3.69,9.34,9.61,8.73,17.07-.41,4.91-3.07,8.7-6.41,12.07-5.16,5.19-10.36,10.34-15.5,15.54-1.01,1.02-2.29,1.85-2.82,3.4,1.38,1.22,3.04,.62,4.53,.62,28.47,.04,56.94,.07,85.42,.02,28.56-.05,55.05,7.19,78.76,23.21,27.08,18.29,45.66,43.2,54.91,74.63,9.79,33.29,7.36,65.73-8.37,96.89-5.98,11.84-13.8,22.43-23.19,31.79-6.88,6.85-17.25,6.96-24.16,.6-6.81-6.28-7.63-16.78-1.3-24.1,6.99-8.07,13.95-16.13,18.39-25.94,15.74-34.79,11.88-68.07-9.46-99.07-16.95-24.64-41.2-38.27-70.81-42.23-4.6-.61-9.28-.89-13.92-.9-29.54-.08-59.07-.04-88.61-.04-.89,0-1.78,.1-4,.22Z"
          />
        </g>
      </g>
    </svg>
  );
};
