import SelectInputSchema from "../Trigger/DataMapping";

const InputSchema: React.FC<any> = ({ inputFields, konnectorSlice }) => {
  const renderFields = (): JSX.Element[] => {
    return inputFields.data.map((field, index) => {
      return <SelectInputSchema field={field} index={index} konnectorSlice={konnectorSlice} />;
    });
  };

  return (
    <>
      <div>{inputFields?.data?.length > 0 && renderFields()}</div>
    </>
  );
};

export default InputSchema;
