export const formSettings = [
  {
    id: 1,
    type: "formTitle",
    sampleData: "janeDoe",
    label: "Form Title",
  },
  {
    id: 2,
    type: "logo",
    sampleData: "select",
    label: "Logo",
  },
  {
    id: 3,
    type: "background",
    sampleData: "yes",
    label: "Form Background",
  },
  {
    id: 4,
    type: "submitButton",
    sampleData: "yes",
    label: "Submit button",
  },
  {
    id: 5,
    type: "onSubmitHandler",
    sampleData: "yes",
    label: "On Submit Handler",
  },
];

export const formTitleField = [
  {
    type: "textbox",
    label: "Form Title",
    name: "form_title",
    required: {
      message: "Please enter the label for your field",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];

export const formSubmitField = [
  {
    type: "textbox",
    label: "Submit Button Text",
    name: "text",
    required: {
      message: "Please enter the label for your field",
      value: true,
    },
    pattern: {
      message: "",
      value: "..*",
      placeholder: "..*",
    },
  },
];

export const formLogoField = {
  type: "file",
  label: "Form Logo",
  name: "form_logo",
  required: {
    message: "Please enter the label for your field",
    value: true,
  },
  pattern: {
    message: "",
    value: "..*",
    placeholder: "..*",
  },
};
