import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
    setRawDataMapping,
    setConfigured,
    PrefilData,
    resetStatus,
    setPortalTilte,
    setPortalDescription,
    previewSetRawDataMapping,
    resetState,
    setSecondaryButtonColor,
    setTextColor,
    setPrimaryBackground,
    setPrimaryButtonColor,
    setFontFamily,
} from "state/Embedded-markteplace/AdminSettings/slices";
import "../style.css"
import { useAppDispatch, useAppSelector } from "state/store";
import FontStyles from "../Font-Styles";
import { useHistory } from "react-router-dom";
import { StatusType } from "state/new-konnector/types";
import { toast } from "react-toastify";
import WarningIcon from "pages/BillingTest/Icons/WarningIcon";
import ReactQuill from "react-quill";
import AdminSettings from "../../Settings-tab";
import { Button } from "components/ui/button";

const SettingTab: React.FC<any> = () => {
    const {
        register,
        formState: { errors },
        watch,
        handleSubmit,
        control,
        setValue,
        reset,
    } = useForm({
        defaultValues: {
            primary_bgColor_text: "#f0f0f0",
            primary_bgColor: "#f0f0f0",
            primary_button_Text: "#07006b",
            primary_button_Color: "#07006b",
            secondary_button_text: "#F0F0F0",
            secondary_button_Color: "#F0F0F0",
            bodytext: "07006b",
            bodytext_color: "07006b",
        },
    });
    const [loadingStatus, setLoadingStatus] = useState("loading");
    const apiStatus = useAppSelector((state) => state.adminSettings.saveDataMapping);
    const currentUser = useAppSelector((state) => state.auth.currentUser);
    const primaryAccountCred = useAppSelector((state) => state.auth.accountCredentials);
    const dispatch = useAppDispatch();
    const adminSettings = useAppSelector((state) => state.adminSettings);
    const themeSettings = useAppSelector((state) => state.themeSettings);
    const [value, setValues] = useState(2);
    const [states, setstates] = useState(false);
    var queryParams
    if (Object.keys(primaryAccountCred).length === 0) {
        queryParams = "accessToken={add your accessToken}&refreshToken={add your refreshToken}&SpreadSheetName={add your spread sheat name}&WorksheetName={add your worksheetName}"
    } else {
        if (currentUser.staticApp == "HighPerfor") {
            const params = primaryAccountCred.map(field => `${field.name}={add your ${field.name}}`).join('&');
            queryParams = params + `&SpreadSheetName={add your SpreadSheet Name}&WorkSheetName={add your WorkSheet Name}`
        } else {
            queryParams = primaryAccountCred.map(field => `${field.name}={add your ${field.name}}`).join('&');
        }
    }

    console.log(`https://enduser.konnectify.io/apps?apiKey=${currentUser.tenantId}&user={add enduser email}&${queryParams}`, "queryparams")
    const [description, setdescription] = useState(false);
    useEffect(() => {
        const titlevalues = adminSettings.portalTile;
        function isHTMLStringEmpty(htmlString) {
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlString;

            return tempElement.innerText.trim() === "";
        }
        setstates(isHTMLStringEmpty(titlevalues));
    }, [adminSettings.portalTile]);

    useEffect(() => {
        const descriptionvalues = adminSettings.portalDescription;
        function isHTMLStringEmpty(htmlString) {
            const tempElement = document.createElement("div");
            tempElement.innerHTML = htmlString;

            return tempElement.innerText.trim() === "";
        }
        setdescription(isHTMLStringEmpty(descriptionvalues));
    }, [adminSettings.portalDescription]);

    const url = `<iframe width=${themeSettings.layout.width} heigth=${themeSettings.layout.heigth} src=https://enduser.konnectify.co/apps?apiKey=${currentUser.tenantId}&user={add enduser email}&${queryParams}></iframe>`;
    const [state, setstate] = useState(false);
    useEffect(() => {
        if (state) {
            dispatch(setPrimaryBackground(adminSettings.primaryBackground));
        }
    }, [state]);

    const history = useHistory();

    const handledescriptionchange = (value) => {
        dispatch(setPortalDescription(value));
    };

    const onSubmit = (data) => {
        if (states) {
            return toast.error("Please fill your Portal Title ", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (description) {
            return toast.error("Please fill your Portal description", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (states && description) {
            return toast.error("Please fill your Portal Title and Portal description", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            dispatch(setConfigured(1));
            data.portalTitle = adminSettings.portalTile;
            data.portalDescription = adminSettings.portalDescription;
            data.fontFamily = adminSettings.fontFamily;
            data.primary_bgColor = adminSettings.primaryBackground;
            data.primary_bgColor_text = adminSettings.primaryBackground;
            data.primary_button_Text = adminSettings.primaryButtonText;
            data.primary_button_Color = adminSettings.primaryButtonColor;
            data.secondary_button_text = adminSettings.secondaryButtonText;
            data.secondary_button_Color = adminSettings.secondaryButtonColor;
            data.bodytext = adminSettings.TextColor;
            data.bodytext_color = adminSettings.TextColor;
            dispatch(
                setRawDataMapping({
                    payload: data,
                })
            );
        }
    };

    const handletitlechange = (value) => {
        dispatch(setPortalTilte(value));
    };

    React.useEffect(() => {
        dispatch(setPrimaryBackground(adminSettings.primaryBackground));
    }, [adminSettings.primaryBackground]);



    useEffect(() => {
        if (apiStatus === StatusType.success) {
            const reloadTimeout = setTimeout(() => {
                window.location.reload();
            }, 10);

            setTimeout(() => {
                toast.success("Theme applied successfully", {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    onClose: () => {
                        history.goBack();
                    },
                });
                dispatch(resetStatus());
            }, 5);
        }
    });
    // useEffect(() => {
    //     if (currentUser.portalConfigration) {
    //         dispatch(PrefilData(currentUser.portalConfigration));
    //     }
    // }, []);
    const onClickClearHandler = () => {
        setstate(true);

        reset({
            primary_bgColor_text: "#f0f0f0",
            primary_bgColor: "#f0f0f0",
            primary_button_Text: "#07006b",
            primary_button_Color: "#07006b",
            secondary_button_text: "#F0F0F0",
            secondary_button_Color: "#F0F0F0",
            bodytext: "07006b",
            bodytext_color: "07006b",
        });
        dispatch(resetState());
    };

    const redirectToPreviewPage = (data) => {
        if (states) {
            return toast.error("Please fill your Portal Title ", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (description) {
            return toast.error("Please fill your Portal description", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        if (states && description) {
            return toast.error("Please fill your Portal Title and Portal description", {
                position: "bottom-center",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        } else {
            setstate(true);
            data.portalTitle = adminSettings.portalTile;
            data.portalDescription = adminSettings.portalDescription;
            data.fontFamily = adminSettings.fontFamily;
            data.primary_bgColor = adminSettings.primaryBackground;
            data.primary_bgColor_text = adminSettings.primaryBackground;
            data.primary_button_Text = adminSettings.primaryButtonText;
            data.primary_button_Color = adminSettings.primaryButtonColor;
            data.secondary_button_text = adminSettings.secondaryButtonText;
            data.secondary_button_Color = adminSettings.secondaryButtonColor;
            data.bodytext = adminSettings.TextColor;
            data.bodytext_color = adminSettings.TextColor;
            dispatch(
                previewSetRawDataMapping({
                    payload: data,
                })
            );

            setTimeout(
                () =>
                    window.open(
                        `https://enduser.konnectify.io/apps?apiKey=${currentUser.tenantId}&user=${currentUser.email}&${queryParams}`,
                        "_blank", "height=300,width=300,left=100,top=100,toolbar=no,location=no,status=no,menubar=no"
                    ),
                3000
            );
        }
    };

    const onChangeSecondaryButtonColor = (e) => {
        dispatch(setSecondaryButtonColor(e.target.value));
    };

    const onChangeTextColorForCard = (e) => {
        dispatch(setTextColor(e.target.value));
    };
    const onChangeFormColor = (e) => {
        dispatch(setPrimaryBackground(e.target.value));
    };

    const onChangePrimaryButtonColor = (e) => {
        dispatch(setPrimaryButtonColor(e.target.value));
    };
    const [activeIndex, setActiveIndex] = useState(-1);
    const handleClick = (index) => {
        setActiveIndex(index === activeIndex ? -1 : index);
    };

    return (
        // <div className="relative w-[90%] h-2/3 top-32 ml-14 overflow-scroll">
        //     <div className="flex justify-items-center align-items-center w-full p-4 mb-10">
        //         <div className="flex w-[90%] text-sm  flex-col space-y-3 pl-8">
        <div className="relative w-[90%] h-2/3 top-32 ml-14 overflow-scroll">
            <div className="flex justify-items-center align-items-center w-full p-4 mb-10">
                <div className="flex w-[90%] text-sm  flex-col space-y-3 pl-8">
                    {/* <label className="block mb-2 text-xl font-bold m-6 ">Portal Customization</label> */}
                    <div className="w-[70%] ">
                        <div className="space-y-2">
                            <div
                                className="group flex flex-col gap-2 bg-transparent p-6 text-black border rounded-md shadow-sm border-slate-300"
                            // onClick={() => handleClick(0)}
                            >
                                <div className="flex cursor-pointer items-center justify-between">
                                    <span className="mb-4 text-md font-extrabold"> Product </span>
                                    {/* <svg fill="none" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" className="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"><path d="M6 9l6 6 6-6"></path>
                                    </svg> */}
                                </div>
                                <AdminSettings />
                            </div>

                            <div
                                className="group flex flex-col gap-2 rounded-md bg-transparent p-5 text-black border border-slate-300"
                            // onClick={() => handleClick(1)}
                            >
                                <div className="flex cursor-pointer items-center justify-between">
                                    <span className="mb-4 text-md font-extrabold"> Embedded Code </span>
                                    {/* <svg fill="none" shape-rendering="geometricPrecision" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" className="h-6 w-6 transition-all duration-500 group-focus:-rotate-180"><path d="M6 9l6 6 6-6"></path>
                                    </svg> */}
                                </div>
                                <><ul className="list-none">
                                    <li >
                                        <span className="text-gray-400 text-sm mb-2 -mt-1 pt-5">
                                            Please copy and paste the code below anywhere before the tag in the html template of your site or
                                            every page where you'd like to provide the KOMP.
                                        </span>
                                        <textarea className="border p-2 w-full rounded-md">{url}</textarea>
                                    </li>
                                </ul>
                                    <div className="flex flex-row">
                                        <div className="flex flex-row mt-2 text-gray-400 space-x-2">
                                            {/* <ErrorOutlineIcon /> */}
                                            <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M9 15H11V9H9V15ZM10 7C10.2833 7 10.521 6.904 10.713 6.712C10.905 6.52 11.0007 6.28267 11 6C11 5.71667 10.904 5.47933 10.712 5.288C10.52 5.09667 10.2827 5.00067 10 5C9.71667 5 9.47933 5.096 9.288 5.288C9.09667 5.48 9.00067 5.71733 9 6C9 6.28333 9.096 6.521 9.288 6.713C9.48 6.905 9.71733 7.00067 10 7ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20ZM10 18C12.2333 18 14.125 17.225 15.675 15.675C17.225 14.125 18 12.2333 18 10C18 7.76667 17.225 5.875 15.675 4.325C14.125 2.775 12.2333 2 10 2C7.76667 2 5.875 2.775 4.325 4.325C2.775 5.875 2 7.76667 2 10C2 12.2333 2.775 14.125 4.325 15.675C5.875 17.225 7.76667 18 10 18Z" fill="black" />
                                            </svg>
                                            <div className="flex flex-col">
                                                <span >
                                                    When you click on <b>"Preview",</b> it will save the configuration. If you don't want that
                                                    configuration saved, you can reset the settings and then save the configuration.
                                                </span>
                                            </div>
                                        </div>
                                    </div></>

                            </div>
                        </div>
                    </div>


                    {/* <div className="mt-10 flex space-x-2 ml-5 items-center justify-center bg-white
             fixed 
             inset-x-0 
             bottom-0 
             border-t border-gray-100
             p-4">
                        <Button type="submit" onClick={handleSubmit(redirectToPreviewPage)} className="bg-slate-50 text-black w-36">Preview</Button>
                        {/* <button
                            onClick={handleSubmit(redirectToPreviewPage)}
                            className="bg-gray-200 p-2 w-[10%] rounded-md text-primary-color "
                        >
                            Preview
                        </button> */}
                    {/* <Button type="submit" onClick={onClickClearHandler} className="bg-slate-50 text-black w-36">Reset</Button> */}
                    {/* <Button type="submit" onClick={handleSubmit(onSubmit)} className="bg-slate-900 text-white w-36 disabled:opacity-60">

                        {apiStatus == StatusType.loading ? (
                            <>
                                <svg
                                    role="status"
                                    className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                                    viewBox="0 0 100 101"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor"
                                    />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="primary-color"
                                    />
                                </svg>
                                Loading...
                            </>
                        ) : (
                            <>Save</>
                        )}
                    </Button> */}
                    {/* </div> */}
                </div>
            </div>
        </div>
    );
};

export default SettingTab;
