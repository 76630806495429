import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InitialState, TabState } from "../types";
import { StatusType } from "state/new-konnector/types";
import { serializeAdminSettings } from "utils/Embedded-marketPlace/adminSettings";

export const initialState: InitialState = {
  primaryBackground: "#f0f0f0",
  primaryBackgroundText: "#f0f0f0",
  rawDataMapping: {},
  SetConfigured: { id: "" },
  fontFamily: "",
  saveDataMapping: StatusType.idle,
  hasData: false,
  headerfontFamily: "",
  primaryButtonColor: "#07006b",
  secondaryButtonColor: "#F0F0F0",
  TextColor: "07006b",
  primaryButtonText: "#07006b",
  secondaryButtonText: "#F0F0F0",
  portalDescription: "",
  portalTile: "",
  tabState: TabState.Logo,
  customizationState: false,
  tabModalStatus: false
  // customization2:
};

const adminSettingSlice = createSlice({
  name: "adminSettings",
  initialState,
  reducers: {
    setPrimaryBackground: (state, payload) => {
      state.primaryBackground = payload.payload;
      state.customizationState = true
    },
    setTextColor: (state, payload) => {
      state.TextColor = payload.payload;
      state.customizationState = true
    },
    setPrimaryButtonColor: (state, payload) => {
      state.primaryButtonColor = payload.payload;
      state.customizationState = true
    },
    setSecondaryButtonColor: (state, payload) => {
      state.secondaryButtonColor = payload.payload;
      state.customizationState = true
    },

    setFontFamily: (state, payload) => {
      state.fontFamily = payload.payload;
      state.customizationState = true
    },
    setHeaderFontFamily: (state, payload) => {
      state.headerfontFamily = payload.payload;
    },
    setConfigured: (state, payload) => {
      state.SetConfigured = { id: payload.payload };
    },
    setRawDataMapping: (state, action) => {
      state.rawDataMapping = {
        ...action.payload,
        portalTitle: state.portalTile,
        portalDescription: state.portalDescription,
        fontFamily: state.fontFamily,
        headerfontFamily: state.headerfontFamily,
      };

      state.saveDataMapping = StatusType.loading;
    },
    previewSetRawDataMapping: (state, action) => {
      state.rawDataMapping = {
        ...action.payload,
        portalTitle: state.portalTile,
        portalDescription: state.portalDescription,
        fontFamily: state.fontFamily,
        headerfontFamily: state.headerfontFamily,
      };
    },

    setRawDataMappingSuccess(state, data: PayloadAction<any>) {
      state.saveDataMapping = StatusType.success;
    },
    previewSetRawDataMappingSuccess(state, data: PayloadAction<any>) {
      console.log(data);
    },
    PrefilData: (state, action) => {
      const serializedData = serializeAdminSettings(action.payload);
      Object.assign(state, serializedData);
    },
    resetStatus(state) {
      state.saveDataMapping = StatusType.idle;
    },
    setPortalTilte(state, payload) {
      state.portalTile = payload.payload;
      state.customizationState = true
    },
    setPortalDescription(state, payload) {
      state.portalDescription = payload.payload;
      state.customizationState = true
    },
    setTabState(state, data) {
      state.tabState = data.payload
    },
    resetState: () => initialState,

  },
});

export const {
  setPrimaryBackground,
  setPrimaryButtonColor,
  setRawDataMapping,
  setFontFamily,
  setConfigured,
  setRawDataMappingSuccess,
  PrefilData,
  setSecondaryButtonColor,
  setTextColor,
  setHeaderFontFamily,
  setPortalTilte,
  setPortalDescription,
  previewSetRawDataMappingSuccess,
  previewSetRawDataMapping,
  resetState,
  resetStatus,
  setTabState
} = adminSettingSlice.actions;

export default adminSettingSlice.reducer;
