import React, { useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "state/store";
import { AppProps } from "pages/KonnectorPage/types";
import ShowOutputFields from "./ShowOutputFields";
import { StatusType } from "state/new-konnector/types";
import { ActionMode, AppRole, KonnectorAppState } from "state/edit-konnector/types";
import { KonnectorState } from "state/konnectors/types";
import SelectKonnectorType from "./selectKonnectorType";
import { KonnectorType } from "pages/KonnectorList/types";
import WebhookModal from "common/components/Modal/webhookUrl";
import { LoadingIcon } from "common/components/Icons";
import { useParams } from "react-router-dom";
import { WorkFlowState } from "state/workFlows/types";
import { WorkflowType } from "state/new-workflow/types";

interface TriggerConfigurationProps {
  apps: AppProps[];
  triggerDetails: KonnectorAppState<AppRole.Source>;
  konnectorSlice: any;
  konnectorState: WorkFlowState;
}

const WebhookKonnector: React.FC<TriggerConfigurationProps> = ({
  apps,
  triggerDetails,
  konnectorSlice,
  konnectorState,
}) => {
  const hasOutputFields = triggerDetails.outputFields.hasData;
  const dispatch = useAppDispatch();
  const selectedSourceApp = triggerDetails.appId;
  const apiStatus = useAppSelector((state) => state[konnectorState].status);
  const geneateWebhhokStatus = useAppSelector((state) => state[konnectorState].generateUrl);
  const resetWebhhokStatus = useAppSelector((state) => state[konnectorState].resetWebhook);
  const webhookUrl = useAppSelector((state) => state[konnectorState].data.webhookUrl.url);
  const webhookId = useAppSelector((state) => state[konnectorState].data.webhookUrl.id);
  const konnectorType = useAppSelector((state) => state[konnectorState].data.workflowType);
  const selectedSourceAccount = triggerDetails.connectionId;
  const selectedSourceEvent = triggerDetails.eventId;
  const outputFields: any = triggerDetails.outputFields.fields;
  const [viewOutputFields, setViewOutputFields] = useState(false);
  const [viewWebhookUrl, setviewWebhookUrl] = useState(false);


  const handleOnClickTriggerFields = () => {
    if (!hasOutputFields) {
      dispatch(konnectorSlice.getSampleDataLoading({ webhookId, konnectorType }));
    }
    setViewOutputFields(true);
  };

  const handleOnClickResetWebhook = () => {
    dispatch(konnectorSlice.resetWebhookLoading({ webhookId }));
  };
  const { other, appid, accountId, eventid } = useParams<any>();
  const eventId = "create-subscription"
  const handleOnClickGenerateUrl = () => {
    if (!webhookUrl) {
      dispatch(konnectorSlice.setGenarateUrlLoading({ konnectorType }));
    }
    setviewWebhookUrl(true);
  };
  useEffect(() => {
    if (other) {
      if (!webhookUrl) {
        dispatch(konnectorSlice.updateKonnectorSource("freshdesk"))
        dispatch(konnectorSlice.setGenarateUrlLoading({ konnectorType, eventid }));
      }
      if (triggerDetails.actionMode !== ActionMode.Edit) dispatch(konnectorSlice.saveSourceConfiguration());
    }
  }, [other]);
  const onClickViewOutputFields = (value) => {
    setViewOutputFields(value);
  };

  const onClickcloseHandler = () => {
    setviewWebhookUrl(false);
  };

  const saveConfiguration = () => {
    dispatch(konnectorSlice.saveSourceConfiguration());
  };

  const renderOutputFieldsButton = () => {
    if (
      (selectedSourceApp && selectedSourceAccount && selectedSourceEvent) ||
      konnectorType === WorkflowType.WebHook
    ) {
      return (
        <div className="flex justify-end">
          <button
            onClick={handleOnClickGenerateUrl}
            type="button"
            className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color"
          >
            {geneateWebhhokStatus === StatusType.loading ? (
              <>
                <LoadingIcon />
                {"Loading..."}
              </>
            ) : !webhookUrl ? (
              <>View webhook URL</>
            ) : (
              <>View webhook URL</>
            )}
          </button>
          {/* <button
            onClick={handleOnClickTriggerFields}
            type="button"
            disabled={!webhookId}
            className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {apiStatus === StatusType.loading ? (
              <>
                <LoadingIcon />
                {"Loading..."}
              </>
            ) : hasOutputFields ? (
              "View output fields"
            ) : (
              "Trigger output fields"
            )}
          </button> */}
          {/* {hasOutputFields && ( */}
          <>
            {/* <button
              onClick={handleOnClickResetWebhook}
              type="button"
              className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <>
                {resetWebhhokStatus === StatusType.loading ? (
                  <>
                    <LoadingIcon />
                    Loading...
                  </>
                ) : (
                  <>Reset webhook data</>
                )}
              </>
            </button> */}
          </>
          {/* // )} */}
          <button
            type="button"
            onClick={saveConfiguration}
            className="ml-2 text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all font-medium rounded-sm text-sm px-5 py-2.5 disabled:opacity-50"
          // disabled={!hasOutputFields || !outputFields || outputFields.eventResponse?.data?.error}
          >
            Save Configuration
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <section className="px-10 py-5 flex flex-col bg-white rounded-md">
        <span className="justify-center bg-primary-color w-16 mb-4 text-white text-xs font-semibold  px-2.5 py-0.5 rounded-sm dark:primary-color dark:text-white">
          Trigger
        </span>
        <div className="flex mb-3 justify-between">
          <SelectKonnectorType konnectorSlice={konnectorSlice} konnectorState={konnectorState} />
        </div>
        {renderOutputFieldsButton()}
        {viewOutputFields && apiStatus === StatusType.success && (
          <ShowOutputFields
            visible={viewOutputFields}
            setViewOutputFields={other ? other : (value) => onClickViewOutputFields(value)}
            outputFields={outputFields}
          />
        )}
        {geneateWebhhokStatus === StatusType.success && (
          <WebhookModal visible={viewWebhookUrl} closeModalHandler={onClickcloseHandler} url={webhookUrl} />
        )}
      </section>
    </>
  );
};

export default WebhookKonnector;
