export enum ConfigurationType {
  source = "source",
  lookup = "lookup",
  action = "target",
  Target = "target",
  Action = "action",
  Repeater = "repeater",
  RuleEngine = "ruleEngine",
  ParseEngine = "parseEngine",
}

export enum KonnectityEngineFeatures {
  RuleEngine = "Rule Engine",
  ParseEngine = "Parse Engine",
  SentimentAnalysis = "Sentiment Analysis",
  Delay = "Delay",
}

export interface SelectEventObject {
  id: string;
  name: string;
  eventSource: string;
}
export interface AppProps {
  id?: string;
  iconUrl: string;
  appName: string;
  connectionConfig: object;
  triggers: SelectEventObject[];
}

export interface AppsProps {
  apps: AppProps[];
  configurationType?: any;
  sourceApp?: any;
  konnectorSlice?: any;
  konnectorState?: any;
  repeater?: boolean;
}

export interface AccountProps {
  appId: string;
  configId: string;
  appName?: string;
  connectionName: string;
  connectorTypeId: string;
  iconUrl: string;
}

export interface AccountsProps {
  accounts: AccountProps[];
  configurationType?: any;
  konnectorSlice?: any;
  konnctorState?: any;
}

// Data Mapping Fields
export interface PropValueObject {
  label: string;
  value: string;
}
export interface TargetInputField {
  propName: string;
  propType: string;
  isRequired: boolean;
  propValue?: PropValueObject[];
}

export enum SourceDataType {
  boolean = "boolean",
  string = "string",
}

const string = [
  { label: "Contains", value: "CONTAINS" },
  { label: "Does not contains", value: "DOES_NOT_CONTAINS" },
  { label: "Ends with", value: "ENDS_WITH" },
  { label: "Does not ends with", value: "DOES_NOT_ENDS_WITH" },
  { label: "Starts with", value: "STARTS_WITH" },
  { label: "Does not starts with", value: "DOES_NOT_STARTS_WITH" },
  { label: "Exactly match", value: "EXACTLY_MATCHES" },
  { label: "Does not exactly match", value: "DOES_NOT_EXACTLY_MATCHES" },
  { label: "Is exists", value: "IS_EXISTS" },
  { label: "Is not exists", value: "IS_NOT_EXISTS" },
];
const boolean = [
  { label: "IsFalse", value: "IS_FALSE" },
  { label: "IsTrue", value: "IS_TRUE" },
  { label: "Is exists", value: "IS_EXISTS" },
  { label: "Is not exists", value: "IS_NOT_EXISTS" },
];
const number = [
  { label: "Is equal to", value: "IS_EQUALS_TO" },
  { label: "Is greater than", value: "IS_GREATER_THAN" },
  { label: "Is less than", value: "IS_LESSER_THAN" },
  { label: "Is exists", value: "IS_EXISTS" },
  { label: "Is not exists", value: "IS_NOT_EXISTS" },
];
const date = [
  { label: "Is after", value: "IS_GREATER_THAN" },
  { label: "Is before", value: "IS_LESSER_THAN" },
  { label: "Is same as", value: "IS_EUQAL_TO" },
];

const defaultValue = [
  {
    label: "none",
    value: "none",
  },
];

export const Choices = {
  string: string,
  boolean: boolean,
  numeric: number,
  date: date,
  default: defaultValue,
};

export const AppData = {
  Stripe: "NVY3oPOqqG",
  Freshsales: "AMVr3a4T9G",
  Freshdesk: "RjyEvbOALG",
  SalesForce: "BMKr3a4T8G",
  Generic: "generic",
};

export const webhookAppData: AppProps = {
  id: "generic",
  appName: "webhook",
  connectionConfig: {},
  iconUrl: "",
  triggers: [{ id: "", name: "", eventSource: "" }],
};

export const genericAppData: AppProps[] = [
  {
    id: "generic",
    appName: "webhook",
    connectionConfig: {},
    iconUrl: "",
    triggers: [{ id: "", name: "", eventSource: "" }],
  },
  {
    id: "generic",
    appName: "form",
    connectionConfig: {},
    iconUrl: "",
    triggers: [{ id: "", name: "", eventSource: "" }],
  },
];

export const parsingSchema = [
  {
    id: 0,
    name: "a-z",
  },
  {
    id: 1,
    name: "ALPHA",
  },
  {
    id: 2,
    name: "SPL",
  },
  {
    id: 3,
    name: "0-9",
  },
  {
    id: 4,
    name: "Custom",
    value: "",
  },
  {
    id: 5,
    name: "SPACE",
    value: "",
  },
  {
    id: 6,
    name: "Email",
    value: "",
  },
];
