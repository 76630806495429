import TextField from "common/components/FormFields/TextFieldWithoutLabel";
import React, { useEffect } from "react";
import { Field } from "utils/appsUtils";
const Value: React.FC<any> = ({ register, errors, index, watchField, id }) => {
  const field = { propName: "Parse_condition_Value" + index, propType: "string", isRequired: true };
  const fieldValue = watchField(`${id}___field_operator`);
  const getFieldOptions = () => {
    return new Field(field).format();
  };

  return (
    <>
      <>
        <div className=" flex w-[40%] ">
          <TextField
            placeholder="Enter the input value"
            {...getFieldOptions()}
            name={`${id}___field_input_value`}
            register={register}
            errors={errors}
          />
        </div>
        {fieldValue != "STARTS_WITH" ? (
          <>
            {fieldValue != "ENDS_WITH" ? (
              <>
                <div className=" flex w-[40%]">
                  <TextField
                    placeholder="Enter starting index"
                    {...getFieldOptions()}
                    name={`${id}___field_start_index`}
                    register={register}
                    errors={errors}
                  />
                </div>
                <div className=" flex w-[40%]">
                  <TextField
                    placeholder="Enter total charecter"
                    {...getFieldOptions()}
                    name={`${id}___field_end_index`}
                    register={register}
                    errors={errors}
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </>
    </>
  );
};

export default Value;
