import React, { useEffect, useState } from 'react';
import Modal from './';
import { toast } from 'react-toastify';
const FormModal: React.FC<any> = ({ visible, url, closeModalHandler }) => {
	const formUrl = `https://storage.googleapis.com/test-forms/${url}.html`;
	const [copySuccess, setCopySuccess] = useState(false);
	const instructionClassName = {
		mainDiv: 'relative px-4 w-full max-w-xl h-full md:h-auto',
		insetOpacity: 'opacity-50 fixed inset-0 z-40 bg-black',
	};
	const modalText = () => {
		return (
			<div className="font-semibold text-center text-xl flex justify-center space-x-1">
				<div>Webhook URL</div>
			</div>
		);
	};
	const copyToClipBoard = async (copyMe) => {
		try {
			await navigator.clipboard.writeText(copyMe);

			setCopySuccess(true);
		} catch (err) {
			setCopySuccess(false);
		}
	};
	useEffect(() => {
		if (copySuccess) {
			toast.info('Successfully copied', {
				position: 'bottom-center',
				autoClose: 1000,
				hideProgressBar: true,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				onClose: () => {
					setCopySuccess(false);
					closeModalHandler();
				},
			});
		}
	}, [copySuccess]);
	return (
		<>
			<Modal
				visible={visible}
				closeModalHandler={closeModalHandler}
				text={modalText()}
				className={instructionClassName}
			>
				<>
					<div className="flex w-full justify-center items-center">
						<h3 className="text-xl font-medium text-gray-900 dark:text-white">Form Preview</h3>
					</div>
					<iframe className="w-full h-[650px] p-2" src={formUrl} title="form"></iframe>
				</>
			</Modal>
		</>
	);
};

export default FormModal;
