import React from "react";
import Select from "react-select";
import { ParentProps } from "state/new-konnector/types";
import { useAppDispatch, useAppSelector } from "state/store";

const SelectEvent: React.FC<any> = ({ sourceApp, sourceEvent, apps, konnectorSlice }) => {
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);

  const dispatch = useAppDispatch();
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;

  const onChangeEventHandler = ({ value: eventId, attribute }) => {
    dispatch(
      konnectorSlice.updateLookupEvent({
        eventId,
        id: isRepeaterCurrentActionId,
        attribute,
        parentId: currentActionId === ParentProps.Repeater ? ParentProps.Repeater : ParentProps.Source,
      })
    );
  };
  const getApp = () => {
    return apps.find((app) => app.id === sourceApp);
  };
  const renderEvents = () => {
    return getApp().lookups.map((event) => ({
      label: event.name,
      value: event.id,
      attribute: event.attribute || "",
      disabled: event.disabled ? event.disabled : false,
    }));
  };

  return (
    <div className="mb-3 xl:w-96">
      <label className="form-label inline-block mb-2 text-gray-700 text-md">Select Event</label>
      <Select
        options={renderEvents().filter((event) => !event.disabled)}
        value={renderEvents().filter(function (option) {
          return option.value === sourceEvent;
        })}
        onChange={onChangeEventHandler}
      />
    </div>
  );
};

export default SelectEvent;
