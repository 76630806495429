import React from "react";
import KonnectorItem from "./KonnectorItem";
import { Droppable } from "react-beautiful-dnd";

const KonnectorList: React.FC<any> = ({ data }) => {
  const renderKonnectors = () => {
    return data.map((konnector, index) => <KonnectorItem key={konnector.id} konnector={konnector} index={index} />);
  };


  return (

    <div className="fon h-[80vh]"
    >
      {renderKonnectors()}
    </div>

  );


};

export default KonnectorList;
