import React from "react";
import { AppProps, ConfigurationType } from "pages/KonnectorPage/types";
import { useAppDispatch, useAppSelector } from "state/store";
import { ParentProps } from "state/new-konnector/types";
interface AppListItemProps extends AppProps {
  configurationType: ConfigurationType;
  konnectorSlice: any;
}

const AppListItem: React.FC<AppListItemProps> = ({ configurationType, id, appName, iconUrl, konnectorSlice }) => {
  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.konnectors.konnectorState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const repeaterId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);
  const parentId = currentActionId === "repeater" ? "repeater" : "source";

  const onClickAppHandler = () => {
    if (configurationType === ConfigurationType.source) {
      dispatch(konnectorSlice.updateSourceAppLoading({ appId: id, appName }));
    } else if (configurationType === ConfigurationType.lookup) {
      if (currentActionId === ParentProps.Repeater) {
        dispatch(konnectorSlice.updateLookupAppLoading({ appId: id, appName, id: repeaterId, parentId }));
      }
      dispatch(konnectorSlice.updateLookupAppLoading({ appId: id, appName, id: currentActionId, parentId }));
    } else {
      if (currentActionId === ParentProps.Repeater) {
        dispatch(konnectorSlice.updateTargetAppLoading({ appId: id, appName, id: repeaterId, parentId }));
      }
      dispatch(konnectorSlice.updateTargetAppLoading({ appId: id, appName, id: currentActionId }));
    }
  };

  return (
    <div
      onClick={onClickAppHandler}
      className="bg-gray-100 relative cursor-pointer hover:bg-gray-50 hover:shadow-md hover:transition-all flex flex-col justify-center items-center px-5 py-3 rounded-sm"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-5 w-5 absolute top-1 right-1 text-gray-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
          clipRule="evenodd"
        />
      </svg>
      <figure className="w-[80%]">
        <img src={iconUrl} alt="Gmail" />
      </figure>
      <figcaption className="text-center mt-2">{appName}</figcaption>
    </div>
  );
};

export default AppListItem;
