import React, { useEffect, useState } from "react";
import { useApps, useAppsByTypes } from "api/appsApi";
import Spinner from "common/components/Spinner";
import AppList from "./AppList";
import Paginantion from "./Pagenation";
import SidebarControls from "./SidebarControls";
import { useAppSelector } from "state/store";
import { updateCurrentPage } from "state/account/slices";
const Apps: React.FC = () => {
  const [allPageApps, setApps] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const currentPage: any = useAppSelector((state) => state.account.currentPage);
  const appType = useAppSelector((state) => state.konnectors.appType);
  const { data: apps, error: appsError } = useAppsByTypes(currentPage, appType);

  useEffect(() => {
    if (apps) {
      setApps((prevState) => {
        if (prevState.length) {
          return [...prevState, ...apps.data?.items];
        }
        return apps.data.items;
      });
    }
  }, [apps]);
  if (appsError) return <p>Failed to load apps</p>;
  if (!apps) return <Spinner />;

  return (
    <section className="flex items-start h-[calc(100vh-5rem)] bg-gray-100 h-auto">
      <SidebarControls />
      <div className="p-5 w-full h-[calc(100vh-5rem)] flex flex-col justify-between">
        <AppList apps={apps.data?.items} pageNumber={pageNumber} setPageNumber={setPageNumber} />
        {/* <Paginantion total={apps.data.totalItems} /> */}
      </div>
    </section>
  );
};

export default Apps;
