import React, { useEffect } from "react";
import { AppProps, AccountProps } from "pages/KonnectorPage/types";
import { useAppDispatch, useAppSelector } from "state/store";
import Header from "./header";
import ShowConfigurationDetails from "../Trigger/ShowConfigurationDetails";
import ShowActionConfigurationDetails from "../Action/ShowConfigurationDetails";
import ShowLookupConfigurationDetails from "../Lookup/ShowConfigurationDetails";
import ShowRuleConfigurationDetails from "../RuleEngine/ShowConfigrationDetails";
import ShowRepeaterConfigurationDetails from "../Repeater/showConfigrationDetails";
import ShowParseConfigurationDetails from "../ParseEngine/ShowParseConfigrationDetails";
import { PlusIcon } from "common/components/Icons";
import { KonnectorState } from "state/konnectors/types";
import { KonnectorType, ParentProps } from "state/new-konnector/types";
import { useParams } from "react-router-dom";
import SelectSource from "./SelectSource";

interface ConfigureRepeaterProps {
  apps: AppProps[];
  accounts: AccountProps[];
  konnectorState: KonnectorState;
  konnectorSlice: any;
}

const ConfigureRepeater: React.FC<ConfigureRepeaterProps> = ({ apps, accounts, konnectorState, konnectorSlice }) => {
  const dispatch = useAppDispatch();
  const currentActionId = useAppSelector((state) => state[konnectorState].data.repeater.currentActionId);
  const konnectorType = useAppSelector((state) => state[konnectorState].data.konnectorType);
  const hasConfigurationSaved = useAppSelector((state) => state[konnectorState].hasConfigurationSaved);
  const repeaterSource = useAppSelector((state) => state[konnectorState].data.repeater.source);
  const savedLookups = useAppSelector((state) => state[konnectorState].data.repeater.lookups);
  const savedRules = useAppSelector((state) => state[konnectorState].data.repeater.ruleEngine);
  const savedActions = useAppSelector((state) => state[konnectorState].data.repeater.actions);
  const savedParseEngine = useAppSelector((state) => state[konnectorState].data.repeater.parseEngine);
  const hasSampleData = useAppSelector((state) => state[konnectorState].data.trigger.outputFields.hasData);
  const repeaterId = useAppSelector((state) => state[konnectorState].currentActionId);
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  let actionArray = [...savedLookups, ...savedRules, ...savedParseEngine, ...savedActions];
  actionArray = actionArray.sort((a, b) => a.actionOrderId - b.actionOrderId);
  const loadingState = useAppSelector((state) => state.createNewKonnector.isLoading);

  const onClickActionHandler = () => {
    dispatch(
      konnectorSlice.createAction({
        parentId: ParentProps.Repeater,
      })
    );
  };

  const onClickRepeaterHandler = () => {
    dispatch(konnectorSlice.createRepeater());
  };

  const onClickLookupHandler = () => {
    dispatch(konnectorSlice.createLookup({ parentId: ParentProps.Repeater }));
  };
  const { other, appid, accountid, eventid, name } = useParams<any>();
  const className =
    other && loadingState
      ? "h-[calc(100vh-5rem)] overflow-y-auto flex w-full flex-col justify-start invisible"
      : " overflow-y-auto flex w-full flex-col justify-start";
  useEffect(() => {
    if (other && hasSampleData) {
      dispatch(konnectorSlice.createAction());
      dispatch(konnectorSlice.updateName(name));
    }
  }, [hasSampleData]);
  const ComponentArray = () => {
    const compArray = [];

    for (let i = 0; i < actionArray.length; i++) {
      const action = actionArray[i];
      switch (action.appSelectorType) {
        case "lookup":
          compArray.push(
            <ShowLookupConfigurationDetails
              action={action}
              apps={apps}
              accounts={accounts}
              konnectorSlice={konnectorSlice}
              parentId={ParentProps.Repeater}
            />
          );
          break;
        case "target":
          compArray.push(
            <ShowActionConfigurationDetails
              action={action}
              apps={apps}
              accounts={accounts}
              konnectorSlice={konnectorSlice}
            />
          );
          break;
        case "repeater":
          compArray.push(
            <ShowRepeaterConfigurationDetails
              action={action}
              konnectorSlice={konnectorSlice}
              apps={apps}
              accounts={accounts}
            />
          );
          break;
        case "ruleEngine":
          compArray.push(<ShowRuleConfigurationDetails action={action} konnectorSlice={konnectorSlice} />);
          break;
        default:
          compArray.push(<ShowParseConfigurationDetails action={action} konnectorSlice={konnectorSlice} />);
      }
    }
    return compArray;
  };

  return (
    <>
      <section className={`${className}`}>
        <section className={`flex px-20 pb-10 bg-gray-50 w-full flex-col justify-start`}>
          {/* configuration list */}
          {/* <Header konnectorSlice={konnectorSlice} konnectorState={konnectorState} /> */}
          <section className={`pb-5 flex flex-col bg-transparent rounded-md`}>
            {
              // <ShowConfigurationDetails
              //   apps={apps}
              //   accounts={accounts}
              //   konnectorSlice={konnectorSlice}
              //   konnectorState={konnectorState}
              // />
            }
            <div className="flex mb-3 space-x-2 items-center">
              <p>For each Item in </p>
              <SelectSource apps={apps} konnectorSlice={konnectorSlice} />
            </div>
            {repeaterSource && (
              <>
                {ComponentArray()}

                {!currentActionId && (
                  <div className="flex space-x-3 justify-end w-full">
                    <button
                      onClick={onClickLookupHandler}
                      className="mt-4 flex text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all font-medium rounded-sm text-sm px-5 py-2.5 text-center"
                    >
                      <PlusIcon /> <span className="ml-2">Add Lookup</span>
                    </button>
                    <button
                      onClick={onClickActionHandler}
                      className="mt-4 flex text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all font-medium rounded-sm text-sm px-5 py-2.5 text-center"
                    >
                      <PlusIcon /> <span className="ml-2">Add Action</span>
                    </button>
                  </div>
                )}
              </>
            )}
          </section>
        </section>
      </section>
    </>
  );
};

export default ConfigureRepeater;
