import { DynamicFieldsSchemaType } from "./../state/new-konnector/types";
import useSWR from "swr";

import { axiosInstance, fetcher, options } from "./libs/fetch";
import { getWebhookData } from "./konnectorsApi";
import { ConfigurationType } from "pages/KonnectorPage/types";
import { useAppSelector } from "state/store";

export function useApps(pageNumber = 1, currentPageLimit?) {
  const Total_Data = useAppSelector((state) => state.account.devHeight);
  const APP_PAGE_LIMIT = Total_Data;
  currentPageLimit = currentPageLimit ? currentPageLimit : APP_PAGE_LIMIT;
  return useSWR(`/apps?pageNumber=${pageNumber}&pageSize=${currentPageLimit}`, fetcher);
}

export function useAppsByTypes(pageNumber = 1, appType) {
  const Total_Data = useAppSelector((state) => state.account.devHeight);
  const APP_PAGE_LIMIT = Total_Data;
  return useSWR(`/apps/app-type/${appType}?pageNumber=${pageNumber}&pageSize=${APP_PAGE_LIMIT}`, fetcher);
}

export function useApp(appId) {
  return useSWR(`/apps/${appId}`, fetcher);
}

export function fetchApp(appId) {
  return axiosInstance.get(`/apps/${appId}`);
}

export function addAppId(data) {
  return axiosInstance.put(`/embedded/apps/${data.payload}`);
}

export function fetchOutputFieldsApi(payload) {
  return axiosInstance.post(`/apps/${payload.appId}/app-sample`, payload.data, options);
}

export function setCategoryList(payload) {
  return axiosInstance.put(`/users/category`, payload.payload, options);
}
export function setEditCategoryApi(payload) {
  return axiosInstance.put(`/users/edit/category`, payload.payload, options);
}
export function setDeleteCategoryApi(payload) {
  return axiosInstance.put(`/users/delete/category`, payload, options);
}
export function setItemIndexApi(payload) {
  return axiosInstance.put(`/users/index/category`, payload, options);
}
export function setCategoryIndexApi(payload) {
  return axiosInstance.put(`/users/category/reorder`, payload.payload, options);
}
export function fetchOutputFieldsSchema(payload) {
  return axiosInstance.post(`/apps/${payload.appId}/app-schema`, payload.data, options);
}

export function fetchDynamicInputFieldsApi(payload) {
  return axiosInstance.post(`/apps/${payload.appId}/app-schema`, payload.data, options);
}

export function getAppsApi(apps) {
  const appsPromises = () =>
    apps.map((app) => {
      if (app.appId !== "generic") {
        return fetchApp(app.appId);
      }
    });
  return Promise.all(appsPromises());
}
export async function getAppsFieldsApi(apps, payload?) {
  const appsPromises = async () => {
    let fields: any = {};
    let actionIndex = 0,
      lookupIndex = 0;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.source:
          if (apps[i].appId !== "generic") {
            if (payload.triggerInputFields) {
              fields["sourceInput"] = (
                await fetchDynamicInputFieldsApi({
                  appId: apps[i].appId,
                  data: {
                    schemaType: DynamicFieldsSchemaType.Input,
                    eventId: apps[i].appEventId,
                    connectionConfigId: apps[i].connectionId,
                    eventData: payload.triggerInputFields,
                  },
                })
              ).data.data;
            }
            fields["sourceSample"] = (
              await fetchOutputFieldsApi({
                appId: apps[i].appId,
                data: {
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
            fields["sourceOutput"] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.Output,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
          } else {
            fields["sourceSample"] = (
              await getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)
            ).data.data;
          }
          break;
        case ConfigurationType.Target:
          if (payload.actionInputFields) {
            fields[`targetDynamicInput_${actionIndex}`] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DynamicInput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.actionInputFields,
                },
              })
            ).data.data;
          }
          fields[`targetInput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`targetOutput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          actionIndex += 1;
          break;
        case ConfigurationType.lookup:
          fields[`lookupInput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`lookupOutput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          lookupIndex += 1;
          break;
      }
    }
    return fields;
  };

  return await appsPromises();
}

export async function getAppsFieldsApiDefault(apps, payload?) {
  const appsPromises = async () => {
    let fields: any = {};
    let actionIndex = 0,
      lookupIndex = 0;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.source:
          if (apps[i].appId !== "generic") {
            if (payload.triggerInputFields) {
              fields["sourceInput"] = (
                await fetchDynamicInputFieldsApi({
                  appId: apps[i].appId,
                  data: {
                    schemaType: DynamicFieldsSchemaType.Input,
                    eventId: apps[i].appEventId,
                    connectionConfigId: apps[i].connectionId,
                    eventData: payload.triggerInputFields,
                  },
                })
              ).data.data;
            }
            fields["sourceSample"] = (
              await fetchOutputFieldsApi({
                appId: apps[i].appId,
                data: {
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
            fields["sourceOutput"] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DefaultOutput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              })
            ).data.data;
          } else {
            fields["sourceSample"] = (
              await getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)
            ).data.data;
          }
          break;
        case ConfigurationType.Target:
          if (payload.actionInputFields) {
            fields[`targetDynamicInput_${actionIndex}`] = (
              await fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DynamicInput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.actionInputFields,
                },
              })
            ).data.data;
          }
          fields[`targetInput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultInput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`targetOutput_${actionIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultOutput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          actionIndex += 1;
          break;
        case ConfigurationType.lookup:
          fields[`lookupInput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultInput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          fields[`lookupOutput_${lookupIndex}`] = (
            await fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultOutput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            })
          ).data.data;
          lookupIndex += 1;
          break;
      }
    }
    return fields;
  };

  return await appsPromises();
}

export async function getAppsFieldsApiTest(apps, payload?) {
  console.log({ apps });

  const appsPromises = () => {
    let fields: any = [[], [[]], [[]]];
    let actionIndex = 0,
      lookupIndex = 0;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.source:
          if (apps[i].appId !== "generic") {
            if (payload.triggerInputFields) {
              fields[0].push(
                fetchDynamicInputFieldsApi({
                  appId: apps[i].appId,
                  data: {
                    schemaType: DynamicFieldsSchemaType.Input,
                    eventId: apps[i].appEventId,
                    connectionConfigId: apps[i].connectionId,
                    eventData: payload.triggerInputFields,
                  },
                })
              );
            }
            fields[0] = Promise.all([
              ...fields[0],
              fetchOutputFieldsApi({
                appId: apps[i].appId,
                data: {
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              }),
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.Output,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              }),
            ]);
          } else {
            fields[0] = Promise.all([getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)]);
          }
          break;
        case ConfigurationType.Target:
          console.log({ re: apps[i] });

          if (payload.actionInputFields) {
            fields[2][actionIndex].push(
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DynamicInput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.actionInputFields,
                },
              })
            );
          }
          fields[2][actionIndex] = Promise.all([
            ...fields[2][actionIndex],

            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
          ]);

          actionIndex += 1;
          break;
        case ConfigurationType.lookup:
          fields[1][lookupIndex] = Promise.all([
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Input,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.Output,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
          ]);

          lookupIndex += 1;
          break;
      }
    }
    fields[2] = Promise.all(fields[2]);
    fields[1] = Promise.all(fields[1]);
    return fields;
  };

  return Promise.all(appsPromises());
}

export async function getAppsFieldsApiTestDefault(apps, payload?) {
  const appsPromises = () => {
    let fields: any = [[], [[]], [[]]];
    let actionIndex = 0,
      lookupIndex = 0;
    for (let i in apps) {
      switch (apps[i].appSelectorType) {
        case ConfigurationType.source:
          if (apps[i].appId !== "generic") {
            if (payload.triggerInputFields) {
              fields[0].push(
                fetchDynamicInputFieldsApi({
                  appId: apps[i].appId,
                  data: {
                    schemaType: DynamicFieldsSchemaType.Input,
                    eventId: apps[i].appEventId,
                    connectionConfigId: apps[i].connectionId,
                    eventData: payload.triggerInputFields,
                  },
                })
              );
            }
            fields[0] = Promise.all([
              ...fields[0],
              fetchOutputFieldsApi({
                appId: apps[i].appId,
                data: {
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              }),
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DefaultOutput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.triggerInputFields,
                },
              }),
            ]);
          } else {
            fields[0] = Promise.all([getWebhookData(payload.webhookId, payload.konnectorType, payload.tenantId)]);
          }
          break;
        case ConfigurationType.Repeater:
        case ConfigurationType.Target:
          if (payload.actionInputFields) {
            fields[2][actionIndex].push(
              fetchDynamicInputFieldsApi({
                appId: apps[i].appId,
                data: {
                  schemaType: DynamicFieldsSchemaType.DynamicInput,
                  eventId: apps[i].appEventId,
                  connectionConfigId: apps[i].connectionId,
                  eventData: payload.actionInputFields,
                },
              })
            );
          }
          fields[2][actionIndex] = Promise.all([
            ...fields[2][actionIndex],

            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultInput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultOutput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
          ]);

          actionIndex += 1;
          break;
        case ConfigurationType.lookup:
          fields[1][lookupIndex] = Promise.all([
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultInput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
            fetchDynamicInputFieldsApi({
              appId: apps[i].appId,
              data: {
                schemaType: DynamicFieldsSchemaType.DefaultOutput,
                eventId: apps[i].appEventId,
                connectionConfigId: apps[i].connectionId,
                eventData: payload.actionInputFields,
              },
            }),
          ]);

          lookupIndex += 1;
          break;
      }
    }
    fields[2] = Promise.all(fields[2]);
    fields[1] = Promise.all(fields[1]);
    return fields;
  };

  return Promise.all(appsPromises());
}

export function getTriggerInputFieldApi(payload) {
  return Promise.all([
    fetchDynamicInputFieldsApi({
      appId: payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.Input,
        eventId: payload.appEventId,
        connectionConfigId: payload.connectionId,
        eventData: payload.triggerInputFields,
      },
    }),
  ]);
}

export function getActionDynamicInputFieldApi(payload) {
  return Promise.all([
    fetchDynamicInputFieldsApi({
      appId: payload.appId,
      data: {
        schemaType: DynamicFieldsSchemaType.DynamicInput,
        eventId: payload.appEventId,
        connectionConfigId: payload.connectionId,
        eventData: payload.actionInputFields,
      },
    }),
  ]);
}

export function activateAppApi(appId) {
  return axiosInstance.post(`/embedded/apps/activate/${appId}`, options);
}
export function deactivateAppApi(data) {
  return axiosInstance.post(`/embedded/apps/deactivate/${data.appId}`, data, options);
}
