import React from "react";
import { AddressElement, CardElement, PaymentElement } from "@stripe/react-stripe-js";

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#303238",
      fontSize: "16px",
      fontFamily: "sans-serif",
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#CFD7DF",
      },
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238",
      },
    },
  },
};

function CardSection() {
  return (
    <CardElement
      className=" flex flex-col drop-shadow-sm bg-white p-2 border-[1px] border-gray-300 rounded"
      options={CARD_ELEMENT_OPTIONS}
    />
  );
}

export default CardSection;
