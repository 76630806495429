import ConfirmModal from "common/components/Modal/ConfirmModal";
import EmbeddedConfirmModal from "common/components/Modal/EmbeddedConfirmModal";
import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import {
  activateKonnectorLoading,
  deactivateKonnectorLoading,
  deleteKonnectorLoading,
  resetDeactivation,
  resetStatus,
} from "state/workFlows/slices";
import { getWorkflowSlice } from "state/selectors";
import { useAppDispatch, useAppSelector } from "state/store";
import Menu from "../Menu";
import { PlanType } from "state/auth/types";
import { StatusType } from "state/new-workflow/types";
import { mutate } from "swr";
import {
  getworkflowsCurrentPage,
  getworkflowsDeleted,
  getworkflowsFilterType,
  getworkflowsSortBy,
} from "state/workFlows/selectors";
import { useLocation, useParams } from "react-router-dom";
import useWorkflow from "api/workflowApi";
import { FilterType } from "state/workFlows/types";

interface ControlsProps {
  workflowId: string;
  status: string;
  name: string;
}
const notificationMessage = {
  true: "Workflow is Enabled",
  false: "Workflow is Disabled",
};
const Controls: React.FC<ControlsProps> = ({ workflowId, status, name }) => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabled, isDisabled] = useState(false);
  const [konnectorStatus, setKonnectorStatus] = useState(status);
  const getWorkflows = useAppSelector(getWorkflowSlice);
  const currentUser = useAppSelector((state) => state.auth.currentUser);
  const konnStatus = useAppSelector((state) => state.konnectors.status);
  const currentActionId = useAppSelector((state) => state.konnectors.currentActionId);
  const activeState = useAppSelector((state) => state.workflows.activeSortBy);
  const sortBy = useAppSelector(getworkflowsSortBy);
  const currentPage = useAppSelector(getworkflowsCurrentPage);
  const hasDeleted = useAppSelector(getworkflowsDeleted);
  const query = new URLSearchParams(location.search);
  const id: any = query.get("appId");
  const filterBy = useAppSelector(getworkflowsFilterType);
  const hasDeactivated = useAppSelector((state) => state.workflows.hasDeactivated);
  const queryValue = sortBy.split("-")[0] || "";
  const sortId = sortBy.split("-")[1] || "";
  let activeSortBy;
  if (filterBy === FilterType.ACTIVE) {
    activeSortBy = activeState;
  } else {
    activeSortBy = "";
  }
  const { data: konnectors, error: konnectorsError } = useWorkflow(id, currentPage, sortBy, activeSortBy, filterBy);

  const dispatch = useAppDispatch();
  const toggleMenu = (e) => {
    setShowMenu((value) => !value);
  };
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const confirmModalHandler = () => {
    setShowModal(false);
    dispatch(deleteKonnectorLoading(workflowId));
    if (deleteKonnectorLoading(workflowId)) {
      toast.success("Workflow deleted successfully", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    // dispatch(reset());
  };

  const handleOnChange = (e) => {
    const checked = e.target.checked;
    const data = {
      id: id,
      workflowId: workflowId,
    };
    if (checked) {
      return dispatch(activateKonnectorLoading(data));
    }
    dispatch(deactivateKonnectorLoading(data));
  };

  useEffect(() => {
    if (getWorkflows.status === "success" && getWorkflows.currentActionId === workflowId && !hasDeleted) {
      setKonnectorStatus(getWorkflows.hasActivated ? "active" : "inactive");
      toast.success(notificationMessage[getWorkflows.hasActivated], {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(resetStatus());
    }
  }, [getWorkflows.status]);
  useEffect(() => {
    setKonnectorStatus(status);
  }, [status]);

  useEffect(() => {
    if (currentUser.currentPlan === PlanType.paid) {
      isDisabled(false);
    } else {
      isDisabled(true);
    }
  }, [currentUser]);

  useEffect(() => {
    if (hasDeactivated) {
      mutate(
        `/embedded/apps/${id}/all?pageNumber=${currentPage}&pageSize=5&filterBy=status&sortValue=${queryValue}&sortOrder=${sortId}`
      );
      dispatch(resetDeactivation());
    }
  }, [hasDeactivated]);

  return (
    <div className="flex">
      {showModal && (
        <EmbeddedConfirmModal
          text={`konnector "${name}"`}
          visible={showModal}
          closeModalHandler={closeModalHandler}
          confirmHandler={confirmModalHandler}
        />
      )}
      <label htmlFor={workflowId} className="inline-flex items-center space-x-4 cursor-pointer">
        <span className="relative">
          <input
            id={workflowId}
            onChange={handleOnChange}
            disabled={currentUser.planExpired || getWorkflows.status === StatusType.loading && getWorkflows.currentActionId === workflowId}
            checked={konnectorStatus === "active" ? true : false}
            type="checkbox"
            className="hidden peer"
          />
          <div className="absolute bg-white inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto  peer-disabled:bg-gray-500"></div>
          <div className="w-10 h-6 bg-gray-400 peer-checked:bg-green-700 rounded-full shadow-inner peer-disabled:bg-gray-200"></div>
        </span>
      </label>
      <div className="ml-3 relative">
        <OutsideClickHandler
          onOutsideClick={() => {
            setShowMenu(false);
          }}
        >
          <button onClick={toggleMenu} className="cursor-pointer " disabled={currentUser.planExpired}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
              />
            </svg>
          </button>
          {showMenu && !showModal && <Menu workflowId={workflowId} setShowModal={setShowModal} />}
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default Controls;
