import { AxiosResponse } from "axios";
import { takeEvery, put, call, select, take } from "redux-saga/effects";
import { logoutApi, currentUserApi, verifyUserApi, forgotPasswordApi, resetPasswordApi, getAllUser } from "api/authApi";
import {
  login,
  loginSuccess,
  loginError,
  logout,
  logoutSuccess,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
  forgotPasswordLoading,
  resetPasswordLoading,
  isVerified,
  isVerifiedError,
  isMailSent,
  setUserData,
  sendUserApiLoading,
  sendUserApi,
  setAppLoading,
  setAppSuccess,
  setAppStatus,
  setCategoryLoading,
  setCategorySuccess,
  editCategoryLoading,
  setEditCategorySuccess,
  setEditModalFalse,
  setDeleteCategoryLoading,
  setDeleteCategorySuccess,
  setDeleteModalFalse,
  setItemIndex,
  setItemIndexStatus,
  setReorderCategoryArray,
  setAppCredentials,
} from "./slices";
import { setTenantId } from "api/libs/fetch";
import { setPlanDetails } from "state/payment/slices";
import { setPlanDetailsData } from "utils/authUtils";
import { addAppId, fetchApp, setCategoryIndexApi, setCategoryList, setDeleteCategoryApi, setEditCategoryApi, setItemIndexApi } from "api/appsApi";
import { setModalFalse } from "state/auth/slices";
import { setReorderAppsSuccess } from "state/workFlows/slices";
import { setPortaCustomization } from "state/Embedded-markteplace/ThemeSettings/slices";
import { getThemeApi } from "api/adminApi";

const isDemo = false;

function* loginUser() {
  try {
    if (isDemo) {
      // setTenantId("109575191505870530182");`
      setTenantId("118350845587774157575");
      yield put(
        loginSuccess({
          name: "snekha",
          eventsLimit: 10000,
          email: "Snekha@konnectify.co",
          tenantId: "118350845587774157575",
          dtUpdated: "2022-11-27T05:57:33.030Z",
          id: "DHcPovUr8d",
          currentPlan: "paid",
          dtCreated: "2022-10-17T10:36:52.401Z",
          taskExecutedCount: 482,
          hasTrial: false,
          stripeId: "cus_Md1WHGObYpE34b",
          isEmailVerified: true,
          planExpired: false,
        })
      );
    } else {
      const result: AxiosResponse<any> = yield call(currentUserApi);
      if (result.data.statusCode === 404) {
        yield put(loginError(result.data.message));
      } else {
        yield put(setUserData(result.data));
        // setTenantId(result.data.tenantId);
        const planDetails = setPlanDetailsData(result.data);
        yield put(loginSuccess(result.data));
        if (result.data.staticApp) {
          const fetchAppData: AxiosResponse<any> = yield call(fetchApp, result.data.staticApp);
          if (fetchAppData.data) {
            yield put(setAppCredentials(fetchAppData?.data?.data?.connectionConfig.fields))
          }
        }

        yield put(setPlanDetails(planDetails));
      }
    }
  } catch (error) {
    console.log(error, "err checks")
    yield put(loginError(error.message));
  }
}

function* setUserInfo(result) {
  try {
    result = result.payload;
    setTenantId(result.tenantId);
    const planDetails = setPlanDetailsData(result);
    yield put(loginSuccess(result));
    const portalData: AxiosResponse<any> = yield call(getThemeApi);
    console.log(portalData, "portal data testing")
    if (portalData.data.data.length == 0) {
      const data = {
        card: {
          background: "#fcfcfc",
          hover: "#F0F0F0",
          text: "#000000",
          heigth: "100",
          width: "600",
          radius: "3",
          bordercolour: "#ededed",
          cardSnippet: "",
          view: "Grid",
          gridsize: "3",
          cardSnippetStatus: false,
        },
        template: {
          background: "#fcfcfc",
          hover: "#F0F0F0",
          text: "#000000",
          heigth: "100",
          width: "600",
          radius: "3",
          templateSnippet: "",
          templateSnippetStatus: false,
          togglecolour: "#000000"
        },
        layout: {
          width: "1440",
          heigth: "780",
        },
        banner: {
          width: "300",
          heigth: "38",
          radius: "0",
          successMsg: "Konnector saved successfully",
          failureMsg: "Error in saving the konnector",
          bordercolour: ""
        },
        cardState: false,
        logo: {
          url: "",
          size: "10",
          colour: "#000000"
        },
        customization: {
          title: "",
          description: "",
          primary_colour: "#f0f0f0",
          primary_button_colour: "#07006b",
          secondary_button_colour: "#F0F0F0",
          text_colour: "#07006b",
          font: ""
        },
      }
      yield put(setPortaCustomization(data))
    } else {
      yield put(setPortaCustomization(portalData.data.data[0]))
    }
    if (result.staticApp != "") {
      const fetchAppData: AxiosResponse<any> = yield call(fetchApp, result.staticApp);
      console.log(fetchAppData, "fetch 34")
      yield put(setAppCredentials(fetchAppData.data.data.connectionConfig.fields))
      yield put(setAppStatus());
    }
    yield put(setPlanDetails(planDetails));
  }
  catch (e) {
    console.log(e, "err check 01")
  }
}

// Remove cookie from Express
function* logoutUser() {
  try {
    if (isDemo) {
      yield put(logoutSuccess());
    } else {
      yield call(logoutApi);
    }
  } catch (error) {
    yield put(logoutSuccess());
  }
}

function* forgotPassword(response) {
  try {
    const result: any = yield call(forgotPasswordApi, response.payload);
    if (result.data.error) {
      yield put(forgotPasswordError(result.data.error.errorMessage));
    } else {
      yield put(forgotPasswordSuccess());
    }
  } catch (error) {
    yield put(forgotPasswordError(error.message));
  }
}

function* resetPassword(action: any) {
  try {
    const result: any = yield call(resetPasswordApi, action.payload.token, action.payload.body);
    if (result.data.error) {
      yield put(resetPasswordError(result.data.error.errorMessage));
    } else {
      yield put(resetPasswordSuccess());
    }
  } catch (error) {
    yield put(resetPasswordError(error.message));
  }
}

function* verifyUser() {
  try {
    const user = yield select((state) => state.auth.currentUser);
    const data = { email: user.email };
    const tenantId = user.tenantId;
    const result: AxiosResponse<any> = yield call(verifyUserApi, data, tenantId);
    if (result.data.statusCode === 401) {
      yield put(isVerifiedError(result.data.message));
    } else {
      yield put(isMailSent());
    }
  } catch (error) {
    yield put(isVerifiedError(error.message));
  }
}

function* getAllEmbeddedUser(data) {
  try {
    let response: AxiosResponse<any> = yield call(getAllUser, data);
    yield put(sendUserApi(response.data));
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}

function* setApp(data) {
  try {
    let response: AxiosResponse<any> = yield call(addAppId, data);
    const result: AxiosResponse<any> = yield call(currentUserApi);
    const fetchAppData: AxiosResponse<any> = yield call(fetchApp, data.payload);
    console.log({ result });
    yield put(loginSuccess(result.data));
    console.log(fetchAppData.data.data.connectionConfig.fields, "fetch 56")
    yield put(setAppCredentials(fetchAppData.data.data.connectionConfig.fields))
    yield put(setAppSuccess(response.data));
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}

function* setCategory(data) {
  try {
    let response: AxiosResponse<any> = yield call(setCategoryList, data);
    const result: AxiosResponse<any> = yield call(currentUserApi);
    yield put(loginSuccess(result.data));
    yield put(setCategorySuccess(response.data.data.appCategory));
    yield put(setModalFalse())
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}

function* setEditCategory(data) {
  try {
    let response: AxiosResponse<any> = yield call(setEditCategoryApi, data);
    const result: AxiosResponse<any> = yield call(currentUserApi);
    yield put(loginSuccess(result.data));
    yield put(setEditCategorySuccess({ appCategory: response.data.data.result.appCategory, categoryType: response.data.data.categorytype }));
    yield put(setEditModalFalse())
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}
function* setDeleteCategory(data) {
  try {
    let response: AxiosResponse<any> = yield call(setDeleteCategoryApi, data);
    const result: AxiosResponse<any> = yield call(currentUserApi);
    console.log({ result }, response, "kk");
    yield put(loginSuccess(result.data));
    yield put(setDeleteCategorySuccess(response.data.data.appCategory));
    yield put(setDeleteModalFalse())
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}

function* setItemsIndex(data) {
  try {
    let response: AxiosResponse<any> = yield call(setItemIndexApi, data);
    const result: AxiosResponse<any> = yield call(currentUserApi);
    yield put(loginSuccess(result.data));
    yield put(setReorderAppsSuccess(response.data.data))
    yield put(setItemIndexStatus(response.data.data))
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}

function* setCategoryArray(data) {
  try {
    let response: AxiosResponse<any> = yield call(setCategoryIndexApi, data);
    const result: AxiosResponse<any> = yield call(currentUserApi);
    // yield put(loginSuccess(result.data));
    // yield put(setReorderAppsSuccess(response.data.data))
    // yield put(setItemIndexStatus(response.data.data))
  } catch (error) {
    console.error("Error in getAllEmbeddedUser:", error);
  }
}

export default function* rootSaga() {
  yield takeEvery(login.type, loginUser);
  yield takeEvery(logout.type, logoutUser);
  yield takeEvery(forgotPasswordLoading.type, forgotPassword);
  yield takeEvery(resetPasswordLoading.type, resetPassword);
  yield takeEvery(isVerified.type, verifyUser);
  yield takeEvery(setUserData, setUserInfo);
  yield takeEvery(sendUserApiLoading, getAllEmbeddedUser);
  yield takeEvery(setAppLoading, setApp);
  yield takeEvery(setCategoryLoading, setCategory);
  yield takeEvery(editCategoryLoading, setEditCategory);
  yield takeEvery(setDeleteCategoryLoading, setDeleteCategory);
  yield takeEvery(setItemIndex, setItemsIndex);
  yield takeEvery(setReorderCategoryArray, setCategoryArray)
}
