import { deserializeDataMapping } from "./appsUtils";
import {
  FormFieldDataVm,
  KonnectorRootObject,
  RepeaterKonnectorRoot,
  SelectOptionVm,
} from "interfaces/modules/konnectors/index";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  RootObject,
  KonnectorApp,
  TriggerDefinition,
  TargetActionData,
  ActionItem,
  ActionItemDataType,
  LookupDataType,
  ActionType,
  RuleDataType,
  ActionDataType,
  ParseDataType,
  FormFields,
} from "interfaces/pages/create-new-konnector";
import {
  InitialState,
  KonnectorAppState,
  AppRole,
  StatusType,
  ActionMode,
  ConfigurationType,
  KonnectorType,
  FormFieldData,
  SelectOption,
  NewKonnectorState,
  RepeaterProps,
} from "state/new-konnector/types";
import { setRawDataMapping, setRuleConfig } from "./ruleEngineUtils";
import { AppData } from "pages/KonnectorPage/types";
import { setParseConfig, setRawDataMappingForParseEngine } from "./parseEngineUtils";
import { WorkflowType } from "state/new-workflow/types";
import { useAppSelector } from "state/store";
import { KonnectorState } from "state/konnectors/types";
import { AnyIfEmpty } from "react-redux";

export const serializeCreateKonnectorData = (
  state: InitialState,
  authId: any,
  getappId: any,
  actionId: any,
  type: any
): RootObject => {
  return {
    name: state.data.name,

    description: "",
    iconUrl: "",
    status: "inactive",
    konnectorSource: state.data.sourceTemplate ? state.data.sourceTemplate : "Konnectify",
    konnectorType: type,
    webhookUrl: { url: state.data.webhookUrl.url, id: state.data.webhookUrl.id },
    konnectorApps: formatDataToKonnectorApp(state),
    ruleConfig: formatDataToRuleConfig(state.data.ruleEngine),
    parseConfig: formatDataToParseConfig(state.data?.parseEngine),
    formFields: state.data.trigger.formFields?.length > 0 ? formatDataToFormFields(state.data.trigger.formFields) : [],
    targetActionData: formatDatatoTargetActionData(state.data.actions),
    actionItems: formDatatoActionItemsTest(state.data),
    triggerDefinition: formatDataToTriggerDefinition(state.data.trigger),
    formSettings: state.data.trigger.formSettings,
    workflowAppId: state.data.trigger.appId == authId || state.data.trigger.appId == "generic" ? actionId : getappId,
    workflowDescription: state.data.workflowDescription,
    workflowInstruction: state.data.workflowInstruction,
    isParseEngineSelected: state.isParseEngineSelected,
  };
};

export const formatDataToKonnectorApp = (state: InitialState): KonnectorApp[] => {
  const data = [state.data.trigger, ...state.data.lookups, ...state.data.actions];
  return data.map((app): KonnectorApp => {
    return {
      actionOrderId: app.actionOrderId,
      appSelectorType: app.appSelectorType,
      appSelectorKey: app.appSelectorKey,
      appId: app.appId,
      appEventId: app.eventId,
      appDetail: app.appDetails,
      connectionId: app.connectionId,
      connectionName: app.connectionName,
    };
  });
};

const formatDatatoTargetActionData = (actions): TargetActionData[] => {
  return actions.map((action: KonnectorAppState<AppRole.Target>): TargetActionData => {
    const { inputFields } = action;
    return {
      sourceId: inputFields.sourceId,
      appEventId: inputFields.eventId,
      appId: inputFields.appId,
      inputSchema: inputFields.data,
    };
  });
};
const getTriggerProps = (triggerType) => {
  if (triggerType === "webhook") {
    return {
      subscriptionType: "auto",
    };
  }
  return {
    recurringType: "minutely",
    minute: 5,
  };
};
const formatDataToTriggerDefinition = (trigger: KonnectorAppState<AppRole.Source>): TriggerDefinition => {
  return {
    appId: trigger.appId,
    eventId: trigger.eventId,
    triggerType: trigger.triggerType,
    triggerProps: getTriggerProps(trigger.triggerType),
    inputFields: trigger.inputFields?.dataMapping,
  };
};
// Edit Konnector
export const deserializeKonnectorToEditKonnectorState = (
  konnector: KonnectorRootObject,
  appsDetails,
  appsDynamicFields
): InitialState => {
  let triggerAppDetails,
    outputFields,
    outputFieldsSchema,
    triggerData,
    triggerInputSchema,
    dynamicInputFields,
    actionInputFields;
  const inputFields = getTargetInputFields(appsDynamicFields, "targetInput");
  const actionAppsDetails = appsDetails.slice(1);
  switch (konnector.data.konnectorType) {
    case KonnectorType.WebHook:
    case KonnectorType.Form:
      outputFields = appsDynamicFields["sourceSample"].fields;
      outputFieldsSchema = appsDynamicFields["sourceSample"].schema;
      triggerData = deserializeTriggerFromWebhookKonnector(
        konnector,
        konnector.data.konnectorType,
        outputFields,
        outputFieldsSchema
      );
      break;
    default:
      actionInputFields = konnector.data.actionItems.slice(-1)[0].dynamicInputFields || null;
      triggerAppDetails = appsDetails[0]?.data.data;
      triggerAppDetails = appsDetails[0]?.data.data;
      outputFields = appsDynamicFields["sourceSample"];
      triggerInputSchema = appsDynamicFields["sourceInput"] || null;
      outputFieldsSchema = appsDynamicFields["sourceOutput"];
      dynamicInputFields = getTargetInputFields(appsDynamicFields, "targetDynamicInput");
      triggerData = deserializeTriggerFromKonnector(
        konnector,
        triggerAppDetails,
        outputFields,
        outputFieldsSchema,
        triggerInputSchema
      );
      break;
  }
  return {
    data: {
      id: konnector.data.id,
      name: konnector.data.name,
      konnectorType: konnector.data.konnectorType == undefined ? KonnectorType.WebHook : konnector.data.konnectorType,
      webhookUrl: { url: konnector.data.webhookUrl?.url || "", id: konnector.data.webhookUrl?.id || "" },
      trigger: triggerData,
      lookups: deserializeLookupsFromKonnector(konnector, appsDetails, outputFields, appsDynamicFields),
      actions: deserializeActionsFromKonnector(
        konnector,
        actionAppsDetails,
        inputFields,
        dynamicInputFields,
        actionInputFields
      ),
      ruleEngine: konnector.data.ruleConfig ? deserializeRuleEngineFromKonnector(konnector) : [],
      parseEngine: konnector.data.parseConfig ? deserializeParseEngineFromKonnector(konnector) : [],
      sourceTemplate: konnector.data.konnectorSource,
      workflowAppId: konnector.data.workflowAppId,
      workflowDescription: konnector.data.workflowDescription,
      workflowInstruction: konnector.data.workflowInstruction,
      repeater: {
        actionMode: ActionMode.Empty,
        actionOrderId: 0,
        currentActionId: "",
        actions: [],
        appSelectorType: AppRole.Repeater,
        lookups: [],
        ruleEngine: [],
        source: "",
        editParse: false,
        editRule: false,
        parseEngine: [],
      },
    },

    hasConfigurationSaved: true,
    status: StatusType.idle,
    created: true,
    generateUrl: StatusType.success,
    actionMode: ActionMode.View,
    currentActionId: "",
    configurationType: ConfigurationType.Source,
    errorMessage: "",
  };
};
const getEventSourceType = (eventId, appDetails) => {
  return appDetails?.triggers.find((trigger) => trigger.id === eventId).eventSource;
};

const deserializeTriggerFromWebhookKonnector = (
  konnector: KonnectorRootObject,
  konnectorType: KonnectorType,
  outputFields,
  outputFieldsSchema
): KonnectorAppState<AppRole.Source> => {
  return {
    id: konnector.data.id,
    triggerType: konnectorType,
    appSelectorType: AppRole.Source,
    actionMode: ActionMode.View,
    appName: "generic",
    formFields: konnector.data.formFields?.length > 0 ? deserializeFormFields(konnector.data.formFields) : [],
    connectionName: konnector.data.konnectorApps[0].connectionId,
    actionOrderId: konnector.data.konnectorApps[0].actionOrderId,
    appSelectorKey: konnector.data.konnectorApps[0].appSelectorKey,
    iconUrl: "",
    appId: konnector.data.konnectorApps[0].appId,
    eventId: konnector.data.konnectorApps[0].appEventId,
    connectionId: konnector.data.konnectorApps[0].connectionId,
    isAccountValidated: true,
    appDetails: {},
    outputFields: {
      hasData: true,
      fields: outputFields,
      schema: outputFieldsSchema,
    },
  };
};

const getTargetInputFields = (appsDynamicFields: any, type: string) => {
  let targetFields = {};
  for (let i in appsDynamicFields) {
    if (i.includes(type)) {
      targetFields[i] = appsDynamicFields[i];
    }
  }
  return targetFields;
};

const deserializeTriggerFromKonnector = (
  konnector: KonnectorRootObject,
  appDetails,
  outputFields,
  outputFieldsSchema,
  triggerInputSchema
): KonnectorAppState<AppRole.Source> => {
  return {
    id: konnector.data.id,
    triggerType: getEventSourceType(konnector.data.konnectorApps[0].appEventId, appDetails),
    appSelectorType: AppRole.Source,
    actionMode: ActionMode.View,
    appName: appDetails?.appName,
    connectionName: konnector.data.konnectorApps[0].connectionId,
    actionOrderId: konnector.data.konnectorApps[0].actionOrderId,
    appSelectorKey: konnector.data.konnectorApps[0].appSelectorKey,
    iconUrl: appDetails?.iconUrl,
    appId: konnector.data.konnectorApps[0].appId,
    eventId: konnector.data.konnectorApps[0].appEventId,
    connectionId: konnector.data.konnectorApps[0].connectionId,
    isAccountValidated: true,
    appDetails: appDetails,
    inputFields: {
      hasData: triggerInputSchema ? true : false,
      dataMapping: triggerInputSchema ? konnector.data.triggerDefinition.inputFields : {},
      data: triggerInputSchema?.data || [],
      appId: konnector.data.konnectorApps[0].appId,
      eventId: "",
      sourceId: "",
    },
    outputFields: {
      hasData: true,
      fields: outputFields,
      schema: outputFieldsSchema,
    },
  };
};

const deserializeActionsFromKonnector = (
  konnector: KonnectorRootObject,
  appsDetails,
  inputFields,
  dynamicInputFields,
  actionInputFields
): KonnectorAppState<AppRole.Target>[] => {
  const konnectorApps = konnector.data.konnectorApps;
  const actionKonnectorApps = konnectorApps
    .map((konn, index) => ({
      konn,
      index,
    }))
    .filter((konnIndex) => konnIndex.konn.appSelectorType === "target");
  return actionKonnectorApps.map((app, index) => {
    const appDetails = appsDetails[app.index - 1].data.data;
    const appInputFields = inputFields[`targetInput_${index}`];
    const appDynamicFields = dynamicInputFields ? dynamicInputFields[`targetDynamicInput_${index}`] : null;
    const actionsProperties: any = konnector.data.targetActionProperties[index].actionProperties;
    const rawDataMapping = deserializeDataMapping(actionsProperties);
    return {
      id: uuidv4(),
      appSelectorType: AppRole.Target,
      actionOrderId: app.konn.actionOrderId,
      actionMode: ActionMode.View,
      appName: appDetails.appName,
      connectionName: app.konn.connectionId,
      appSelectorKey: app.konn.appSelectorKey,
      iconUrl: appDetails.iconUrl,
      appId: app.konn.appId,
      eventId: app.konn.appEventId,
      connectionId: app.konn.connectionId,
      isAccountValidated: true,
      appDetails: appDetails,
      inputFields: appInputFields,
      dynamicInputFields: {
        data: appDynamicFields?.data || [],
        dataMapping: actionInputFields || [],
        hasData: actionInputFields ? true : false,
        hasDynamicInputFields: actionInputFields ? true : false,
      },
      rawDataMapping: rawDataMapping,
      dataMapping: actionsProperties,
    };
  });
};

const deserializeLookupsFromKonnector = (
  konnector: KonnectorRootObject,
  appsDetails,
  inputFields,
  outputFieldsSchema
): KonnectorAppState<AppRole.Lookup>[] => {
  if (konnector.data.konnectorApps[1].appSelectorType !== "lookup") {
    return [];
  }
  const lookup = konnector.data.konnectorApps.filter((lookup) => lookup.appSelectorType === "lookup");
  return lookup.map((app, index) => {
    const appDetails = appsDetails[index + 1].data.data;
    const dataSourceProperties: any = konnector.data.actionItems.find(
      (lookup) => lookup.actionOrderId === app.actionOrderId
    ).eventProps;
    const rawDataMapping = deserializeDataMapping(dataSourceProperties);
    return {
      id: uuidv4(),
      actionOrderId: app.actionOrderId,
      appSelectorType: AppRole.Lookup,
      appName: appDetails.appName,
      connectionName: app.connectionId,
      appSelectorKey: app.appSelectorKey,
      iconUrl: appDetails.iconUrl,
      appId: app.appId,
      eventId: app.appEventId,
      actionMode: ActionMode.View,
      connectionId: app.connectionId,
      isAccountValidated: true,
      appDetails: appDetails,
      inputFields: {
        sourceId: "",
        eventId: "",
        appId: "string",
        data: [
          {
            propName: dataSourceProperties[0].propName,
            propType: "string",
            isRequired: true,
          },
        ],
      },
      outputFields: {
        hasData: true,
        schema: outputFieldsSchema[`lookupOutput_${index}`],
      },
      rawDataMapping: rawDataMapping,
      dataMapping: dataSourceProperties,
    };
  });
};

export const flatSourceOutputFields = (outputFields): any[] => {
  const getMembers = (member) => {
    if (!member.propChildren || !member.propChildren.length) {
      return member;
    }
    return [member, _.flatMapDeep(member.propChildren, getMembers)];
  };
  return _.flatMapDeep(outputFields, getMembers);
};

const formatDataToRuleConfig = (ruleEngine) => {
  const ruleConfig = ruleEngine.map((ruleData) => {
    const setRuleConfigData = setRuleConfig(ruleData.ruleConfigration, "");
    return {
      name: "",
      operator: setRuleConfigData.operator,
      filters: setRuleConfigData.filters,
    };
  });
  return ruleConfig;
};
const formatDataToParseConfig = (parseEngine) => {
  const parseConfig = parseEngine.map((parseData) => {
    const schema = parseData.outputFields;
    if (parseData.parseConfigration.conditions?.length) {
      const setParseConfigData = setParseConfig(parseData.parseConfigration);

      return {
        id: parseData.id,
        source_value: setParseConfigData.rawDataMapping.data.Source_field,
        pattern_type: setParseConfigData.rawDataMapping.data.pattern_type,
        patterns: setParseItem(parseData),
        conditions: setParseConfigData.conditions,
        outputFields: schema,
        source_id: setParseConfigData.rawDataMapping.data.source_id,
        actionOrderId: parseData.actionOrderId,
      };
    } else {
      return {
        id: parseData.id,
        source_value: parseData.parseConfigration.rawDataMapping.data.Source_field,
        pattern_type: parseData.parseConfigration.parseType,
        patterns: setParseItem(parseData),
        outputSchema: schema,
        source_id: parseData.parseConfigration.rawDataMapping.data.source_id,
        actionOrderId: parseData.actionOrderId,
      };
    }
  });
  return parseConfig;
};
const deserializeRuleEngineFromKonnector = (konnector: KonnectorRootObject) => {
  const rule = konnector.data.actionItems.filter((actionItem) => actionItem.actionType === "ruleConfig");
  const ruleEngineState = rule.map((ruleData) => {
    return {
      id: uuidv4(),
      actionOrderId: ruleData.actionOrderId,
      appSelectorType: AppRole.RuleEngine,
      actionMode: ActionMode.View,
      ruleConfigration: {
        operator: ruleData.operator,
        configurationType: AppRole.RuleEngine,
        filters: setFilterData(ruleData),
        rawDataMapping: setRawDataMapping(ruleData),
      },
    };
  });
  return ruleEngineState;
};
const deserializeParseEngineFromKonnector = (konnector: KonnectorRootObject) => {
  const parse = konnector.data.parseConfig;
  const parseEngineState = parse.map((parseData) => {
    const schema = parseData.outputSchema ? parseData.outputSchema : parseData.outputFields;
    return {
      id: uuidv4(),
      actionOrderId: parseData.actionOrderId,
      appSelectorType: AppRole.ParseEngine,
      actionMode: ActionMode.View,
      parseConfigration: {
        sourceData: parseData.source_value,
        patternType: parseData.pattern_type,
        parsingItems: setParseData(parseData),
        conditions: setConditionDataForParseEngine(parseData),
        configurationType: AppRole.ParseEngine,
        rawDataMapping: setRawDataMappingForParseEngine(parseData),
      },
      outputFields: schema,
    };
  });
  return parseEngineState;
};
const setConditionDataForParseEngine = (parseConfig) => {
  return parseConfig.conditions?.map((condition) => {
    return {
      data_type: condition.data_type,
      operator_type: condition.field_type,
      inputValue: condition.field_value,
      startIndex: condition.startIndex,
      endIndex: condition.endIndex,
      id: condition.id,
    };
  });
};
const setParseData = (parseConfig) => {
  let id;
  let name;
  let result = [];
  for (let i in parseConfig.patterns) {
    if (parseConfig.patterns.hasOwnProperty(i)) {
      name = parseConfig.patterns[i];
      id = uuidv4();
    }
    result.push({ id: id, name: name });
  }
  return result;
};

const deserializeFormFields = (formFields: FormFieldDataVm[]) => {
  const result: FormFieldData[] = formFields.map((field) => {
    return {
      orderId: field.orderId,
      fieldId: uuidv4(),
      actionMode: ActionMode.View,
      fieldName: field.fieldName,
      description: field.description,
      label: field.label,
      sampleData: field.sampleData,
      config: field.config,
      options: deserializeFormFieldOptions(field.options),
      inputType: field.inputType,
      isSaved: true,
    };
  });

  return result;
};

const deserializeFormFieldOptions = (options: SelectOptionVm[]) => {
  return options.map((opt, index) => {
    return {
      id: index,
      label: opt.label,
      subCategory: opt.subCategory.map((subC, index) => {
        return {
          id: index,
          label: subC.label,
          items: subC.items.map((item, index) => {
            return {
              id: index,
              label: item.label,
            };
          }),
        };
      }),
    };
  });
};

const setFilterData = (ruleConfig) => {
  return ruleConfig.filters.map((filter) => {
    return { name: filter.name, match_type: filter.match_type, conditions: setConditionData(filter) };
  });
};
const setConditionData = (filter) => {
  return filter.conditions.map((condition) => {
    return {
      data_type: condition.data_type,
      field_type: condition.field_type,
      field_name: condition.field_name,
      operator: condition.operator,
      value: condition.value,
      name: condition.name,
    };
  });
};
//ActionItems
export const setActionOrderId = (lookups, ruleConfig, parseConfig, actions?) => {
  let actionOrderId = 0;
  if (lookups.length === 0 && ruleConfig.length === 0 && parseConfig.length === 0) {
    actionOrderId = 1;
    return actionOrderId;
  } else {
    if (actions) {
      actionOrderId = ruleConfig.length + lookups.length + parseConfig.length + actions.length;
      return actionOrderId;
    }
    actionOrderId = ruleConfig.length + parseConfig.length + lookups.length;
    return actionOrderId;
  }
};
export const formDatatoActionItemsTest = (
  data: NewKonnectorState | RepeaterProps
): ActionItem<ActionItemDataType>[] => {
  let actionItems: ActionItem<ActionItemDataType>[] = [];
  const lookupItem = seraializeLookupDataActionItem(data.lookups);
  const ruleConfig = seraializeRuleConfigDataActionItem(data.ruleEngine);
  const parseConfig = seraializeParseConfigDataActionItem(data.parseEngine);
  const actionItem = seraializeActionDataActionItem(data.actions);
  actionItems = [...lookupItem, ...ruleConfig, ...parseConfig, ...actionItem];
  actionItems.sort((a, b) => {
    return a.actionOrderId - b.actionOrderId;
  });
  return actionItems;
};
const seraializeLookupDataActionItem = (lookups): ActionItem<LookupDataType>[] => {
  return lookups.map((lookup): ActionItem<LookupDataType> => {
    const schema = lookup.outputFields.schema;
    const eventProps = lookup.dataMapping;
    return {
      actionOrderId: lookup.actionOrderId,
      actionType: ActionType.Lookup,
      data: {
        sourceId: schema.sourceId,
        appEventId: schema.eventId,
        appId: schema.appId,
        actionOrderId: lookup.actionOrderId,
        outputSchema: schema.data,
        eventProps,
      },
    };
  });
};
const seraializeRuleConfigDataActionItem = (ruleEngine): ActionItem<RuleDataType>[] => {
  return ruleEngine.map((ruleData): ActionItem<RuleDataType> => {
    const setRuleConfigData = setRuleConfig(ruleData.ruleConfigration, "");
    return {
      actionOrderId: ruleData.actionOrderId,
      actionType: ActionType.RuleConfig,
      data: {
        name: "",
        operator: setRuleConfigData.operator,
        filters: setRuleConfigData.filters,
      },
    };
  });
};
const seraializeParseConfigDataActionItem = (parseEngine): ActionItem<ParseDataType>[] => {
  return parseEngine.map((parseData): ActionItem<ParseDataType> => {
    const schema = parseData.outputFields.schema;
    const setParseConfigData = setParseConfig(parseData.parseConfigration);
    debugger
    return {
      actionOrderId: parseData.actionOrderId,
      actionType: ActionType.ParseConfig,
      data: {
        id: parseData.id,
        source_value: setParseConfigData.rawDataMapping.data.Source_field,
        pattern_type: setParseConfigData.rawDataMapping.data.pattern_type,
        patterns: setParseItem(parseData),
        conditions: setParseConfigData.conditions,
        outputSchema: schema.eventResponse.data,
        source_id: setParseConfigData.rawDataMapping.data.source_id,
      },
    };
  });
};

const setParseItem = (parsedData): any => {
  return parsedData.parseConfigration.parsingItems.map((name) => name.name);
};
const seraializeActionDataActionItem = (actions: KonnectorAppState<AppRole.Target>[]): ActionItem<ActionDataType>[] => {
  return actions.map((action): ActionItem<ActionDataType> => {
    const { dataMapping } = action;
    debugger
    return {
      actionOrderId: action.actionOrderId,
      actionType: ActionType.Action,
      data: {
        appId: action.appId,
        actionOrderId: action.actionOrderId,
        appEventId: action.eventId,
        actionProperties: [...dataMapping],
        connectionId: action.connectionId,
        dynamicInputFields: action.dynamicInputFields?.dataMapping || {},
      },
    };
  });
};

export const setSourceIdLabel = (sourceId: string) => {
  const data = sourceId || "";
  const sourceApp = data?.split(":");
  for (let i in AppData) {
    if (sourceApp[0] === AppData[i]) {
      sourceApp[0] = i;
    }
  }
  return sourceApp.join(":");
};

export const setTriggerData = (workflowType: WorkflowType, triggerData: KonnectorAppState<AppRole.Source> | any) => {
  switch (workflowType) {
    case WorkflowType.WebHook:
      triggerData = {
        ...triggerData,
        appId: "generic",
        eventId: "webhook",
        iconUrl: "",
        appName: "generic",
        connectionId: "generic",
        connectionName: "generic",
        outputFields: { fields: {}, schema: {}, hasData: false },
        appDetails: {},
      };
      break;
    case WorkflowType.Form:
      triggerData = {
        ...triggerData,
        appId: "generic",
        iconUrl: "",
        eventId: "form-data",
        appName: "generic",
        connectionId: "generic",
        connectionName: "generic",
        outputFields: { fields: {}, schema: {}, hasData: false },
        appDetails: {},
      };
      break;
    default:
      triggerData = {
        ...triggerData,
        appId: "",
        eventId: "",
        appName: "",
        connectionId: "",
        connectionName: "",
      };
      break;
  }
  return triggerData;
};

export const formatDataToFormFields = (state: FormFieldData[]): FormFields[] => {
  return state.map((field) => {
    return {
      fieldName: field.fieldName,
      inputType: field.inputType,
      label: field.label,
      description: field.description,
      sampleData: field.sampleData,
      config: field.config,
      options: setFormOptions(field.options),
      orderId: field.orderId,
    };
  });
};

export const setFormOptions = (options: SelectOption[]): SelectOptionVm[] => {
  return options.map((opt) => {
    return {
      label: opt.label,
      subCategory: opt.subCategory?.map((subC) => {
        return {
          label: subC.label,
          items: subC.items?.map((i) => {
            return {
              label: i.label,
            };
          }),
        };
      }),
    };
  });
};
