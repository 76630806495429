import { KonnectorAppState, AppRole, InitialState, ParentProps } from "./types";
export const getTriggerDetails = (state): KonnectorAppState<AppRole.Source> => state.createNewKonnector.data.trigger;
export const getTargetDetails = (state, parentId): KonnectorAppState<AppRole.Target> => {
  const repeaterId = state.createNewKonnector.data.repeater.currentActionId;
  const currentActionId = state.createNewKonnector.currentActionId;
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  let actionData =
    parentId === ParentProps.Repeater
      ? state.createNewKonnector.data.repeater.actions
      : state.createNewKonnector.data.actions;
  return actionData.find((action) => action.id == isRepeaterCurrentActionId);
};
export const getLookupDetails = (state, data): KonnectorAppState<AppRole.Lookup> => {
  let lookupdata =
    data.parentId === ParentProps.Repeater
      ? state.createNewKonnector.data.repeater.lookups
      : state.createNewKonnector.data.lookups;
  const currentActionId = state.createNewKonnector.currentActionId;
  const repeaterId = state.createNewKonnector.data.repeater.currentActionId;
  const isRepeaterCurrentActionId = currentActionId === ParentProps.Repeater ? repeaterId : currentActionId;
  return lookupdata.find((lookup) => lookup.id == isRepeaterCurrentActionId);
};

export const getCreateKonnectorDetails = (state): InitialState => state.createNewKonnector;
