export interface KonnectorApp {
  appId: string;
  appEventId: string;
  appSelectorType: string;
  appSelectorKey: string;
  connectionId: string;
  iconUrl: string;
}

export interface DataSource {
  appEventId: string;
  appId: string;
  sourceId: string;
  eventProps: any[];
}

export interface ActionProperty {
  propName: string;
  propType: string;
  format: string;
  source: string;
  sourceProp: any;
  staticValue: string;
}

export interface TargetActionProperty {
  appId: string;
  appEventId: string;
  actionProperties: ActionProperty[];
}

export interface TriggerProps {
  minute: number;
  recurringType: string;
  scheduleName: string;
}

export interface TriggerDefinition {
  triggerType: string;
  appId: string;
  eventId: string;
  triggerProps: TriggerProps;
}

export interface Item {
  id: string;
  dtCreated: Date;
  dtUpdated: Date;
  name: string;
  owner: string;
  iconUrl: string;
  konnectorType: KonnectorType;
  konnectorApps: KonnectorApp[];
  dataSources: DataSource[];
  targetActionProperties: TargetActionProperty[];
  triggerDefinition: TriggerDefinition;
  status: string;
  dtExecuted?: Date;
  ruleConfig?: any;
}

export interface Konnectors {
  items: Item[];
  totalItems: number;
  pageNumber: number;
}

export interface RootObject {
  data: Konnectors;
  status: number;
}

export enum KonnectorType {
  WebHook = "webhook",
  Regular = "regular",
  Initial = "",
  Form = "form",
}
