import { AxiosResponse } from "axios";
import { takeEvery, put, call } from "redux-saga/effects";
import {
  previewSetRawDataMapping,
  previewSetRawDataMappingSuccess,
  setRawDataMapping,
  setRawDataMappingSuccess,
} from "./slices";
import { ErrorStatus } from "state/konnectors/types";
import { displayErrorMessage } from "state/new-konnector/slices";
import { apiError } from "state/konnectors/slices";
import { savePortalCongrationApi, saveThemeApi } from "api/adminApi";

function* saveConfigration(data) {
  try {
    console.log(data.payload.payload);
    const portalData = {
      portalCustomization: data.payload.payload
    }
    const result: AxiosResponse<any> = yield call(savePortalCongrationApi, data.payload.payload);
    // const resutData: AxiosResponse<any> = yield call(saveThemeApi, portalData);
    const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
    if (statusCode) {
      yield put(displayErrorMessage(result.data.message));
      throw Error(result.data.message);
    }
    yield put(setRawDataMappingSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* PreviewConfigration(data) {
  try {
    console.log(data.payload.payload);
    const result: AxiosResponse<any> = yield call(savePortalCongrationApi, data.payload.payload);

    const statusCode = ErrorStatus.find((status) => status === result.data.status) ? true : false;
    if (statusCode) {
      yield put(displayErrorMessage(result.data.message));
      throw Error(result.data.message);
    }
    yield put(previewSetRawDataMappingSuccess(result.data));
  } catch (error) {
    yield put(apiError(error.message));
  }
}
export default function* rootSaga() {
  yield takeEvery(setRawDataMapping.type, saveConfigration);
  yield takeEvery(previewSetRawDataMapping.type, PreviewConfigration);
}
