import { useDescriptions } from "@headlessui/react/dist/components/description/description";
import { useAppDescription } from "api/workflowApi";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { handleInputChange } from "react-select/dist/declarations/src/utils";
import { toast } from "react-toastify";
import { updateDisplay, updateDisplayFalse, updateWorkflowDescription } from "state/edit-workflow/slices";
import { updateAppDescription, updateDescription } from "state/new-workflow/slices";
import { useAppSelector } from "state/store";

const WorkflowDescription: React.FC<any> = ({ konnectorSlice, konnectorState }) => {
  const { id } = useParams<any>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, setValue, reset, watch } = useForm();
  const { data: apps, error: error } = useAppDescription(id);
  const workflowDescription = useAppSelector((state) => state[konnectorState].data.workflowDescription);
  const workflowInstruction = useAppSelector((state) => state[konnectorState].data.workflowInstruction);
  console.log(workflowDescription);
  const workflowDesk = watch("workflowDescription");
  console.log(workflowDesk);
  const workflowInstruct = watch("workflowInstruction");
  console.log(workflowInstruct);
  const handleInput = () => {
    dispatch(konnectorSlice.updateDescription(workflowDesk));
    dispatch(konnectorSlice.updateInstruction(workflowInstruct));
    console.log(konnectorState, "konnectorstate", id)
    if (konnectorState === "editWorkFlow") {
      dispatch(updateWorkflowDescription())
    }
    const toastMessage = "Saved Successfully";
    toast.success(toastMessage, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      onClose: () => {
        dispatch(updateDisplay(false));
      },
    });
  };
  useEffect(() => {
    dispatch(konnectorSlice.updateDescription(workflowDesk));
  }, [workflowDesk]);

  useEffect(() => {
    dispatch(konnectorSlice.updateInstruction(workflowInstruct));
  }, [workflowInstruct]);

  const handleInputChange1 = (e) => { };

  const handleInputChange2 = (e) => { };

  return (
    <>
      <div className="p-6 pt-20 w-full">
        {workflowDescription === "" ? (
          <>
            <div className="flex flex-col">
              <div>
                <label className="flex items-start">Enter the Description</label>
                <textarea
                  rows={4}
                  className="p-2.5 my-4 w-full flex items-center bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg  "
                  // type="text"
                  placeholder="Description..."
                  onChange={handleInputChange1}
                  {...register("workflowDescription")}
                />
              </div>
              <div>
                <label className="flex items-start">Enter the Instruction</label>
                <textarea
                  rows={4}
                  className="p-2.5 my-4 w-full flex items-center bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg  "
                  // type="text"
                  placeholder="Instructions..."
                  onChange={handleInputChange2}
                  {...register("workflowInstruction")}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col">
            <div>
              <label className="flex items-start">Enter the Description</label>
              <textarea
                rows={4}
                className="p-2.5 my-4 w-full flex items-center bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg  "
                // type="text"
                placeholder="Description..."
                onChange={handleInputChange1}
                defaultValue={workflowDescription}
                {...register("workflowDescription")}
              ></textarea>
            </div>
            <div>
              <label className="flex items-start">Enter the Instruction</label>
              <textarea
                rows={4}
                className="p-2.5 my-4 w-full flex items-center bg-gray-50 border border-gray-300 text-gray-600 text-sm rounded-lg  "
                // type="text"
                placeholder="Instructions..."
                onChange={handleInputChange2}
                defaultValue={workflowInstruction}
                {...register("workflowInstruction")}
              />
            </div>
          </div>
        )}
        <div className="flex p-2 mt-4 space-x-2">
          <button
            data-modal-toggle="popup-modal"
            type="button"
            onClick={handleInput}
            className=" justify-center mr-2 text-white bg-primary-color hover:bg-primary-color focus:ring-4 focus:outline-none  font-medium rounded-sm text-sm inline-flex items-center px-5 py-2.5 text-center "
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default WorkflowDescription;
