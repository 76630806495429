import React from "react";
import { AppsProps } from "pages/KonnectorPage/types";
import AppList from "./AppList";

const Apps: React.FC<AppsProps> = ({ apps, configurationType, konnectorSlice }) => {
  return (
    <div>
      <h4 className="text-md font-semibold text-primary-color">All Apps</h4>
      <AppList apps={apps} configurationType={configurationType} konnectorSlice={konnectorSlice} />
    </div>
  );
};

export default Apps;
