import moment from "moment";
import React, { useEffect, useState } from "react";
import Apps from "./Apps";
import Modal from "../Modal";
import { toast } from "react-toastify";
import { useAppSelector } from "state/store";
import { deleteBulkKonnectorLoading, setShowPaymentModal } from "state/payment/slices";
import { useDispatch } from "react-redux";
import { StatusType } from "state/new-konnector/types";
import { bulckDeleteKonnectorApi } from "api/konnectorsApi";
import { createSession } from "api/paymentApi";

const RemoveKonnector: React.FC<any> = ({ data, visible, text, onChange, closeModalHandler, goBack }) => {
  const getHumanReadableTime = (datetime) => moment(datetime).fromNow();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const modalText = () => {
    return (
      <div className="ml-16  text-lg text-center">
        Please select any 5 konnectors you wish to proceed <br />
        <div className="text-sm flex text-white ml-14 font-semibold space-x-2">
          Note that remaining konnectors will be deleted
        </div>
      </div>
    );
  };
  const [selectedKonnectors, setSelectedKonnectors] = useState([]);
  const [isAllKonnectorsSelected, setAllKonnectorsSelected] = useState(false);
  const [totalCheckBoxSelected, setTotalCheckBoxSelected] = useState(5);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const userStripeId = useAppSelector((state) => state.auth.currentUser.stripeId);

  const removeKonnectorsClassName = {
    mainDiv: "relative px-4 w-full max-w-2xl h-full md:h-auto",
    insetOpacity: "opacity-50 fixed inset-0 z-40 bg-black",
  };

  const onClickHandler = () => {
    setSelectAll((value) => !value);
  };

  const handleChange = (e) => {
    const { name: currentValue, checked } = e.target;
    const konnectorname = e.target.dataset.konnectorname;
    if (!checked) {
      setAllKonnectorsSelected(false);
      const updateSelectedKonnectors = selectedKonnectors.filter((konnector) => currentValue !== konnector.id);
      return setSelectedKonnectors(updateSelectedKonnectors);
    }
    const updateSelectedKonnectors = [...selectedKonnectors, { id: currentValue, name: konnectorname }];
    setSelectedKonnectors(updateSelectedKonnectors);
    setTotalCheckBoxSelected(totalCheckBoxSelected - 1);
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      const allSelectedKonnectorIds = data.items.map((item) => ({ id: item.id, name: item.name }));
      setAllKonnectorsSelected(true);
      return setSelectedKonnectors(allSelectedKonnectorIds);
    }
    setAllKonnectorsSelected(false);
    setSelectedKonnectors([]);
  };
  const dispatch = useDispatch();

  const findNonSelectedKonnector = async () => {
    if (selectedKonnectors.length > 5) {
      toast.error("You have selected more than 5 Konnectors.", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      const selectKonnector = selectedKonnectors.filter((konnector) => {
        return selectedKonnectors.find((item) => item == konnector.id) ? false : true;
      });
      const deleteKonnector = selectKonnector.map((konn) => konn.id);
      setLoading(true);
      const storeKonnector = await bulckDeleteKonnectorApi(deleteKonnector, true);
      setLoading(false);
      if (storeKonnector.data.status === 200) {
        closeModalHandler();
        dispatch(setShowPaymentModal(true));
      }
    }
  };

  return (
    <>
      {visible && (
        <Modal
          visible={visible}
          closeModalHandler={closeModalHandler}
          text={modalText()}
          className={removeKonnectorsClassName}
        >
          <form>
            <div className="p-8 py-1 flex-col space-y-2 overflow-y-scroll h-[50vh]">
              {data.items.map((konnector, index) => (
                <>
                  <label className="h-full mt-2">
                    <div className="flex  mt-3 pl-8 py-4 focus:bg-gray-500 w-full  border-[1px] border-gray-500 border-opacity-50 rounded-none shadow-sm justify-left items-center cursor-pointer peer-checked:bg-gray-500">
                      {/* <div className="bg-gray-200 w-3 h-4 peer-checked:bg-gray-500"></div> */}
                      <input
                        type="checkbox"
                        value={1}
                        className="peer"
                        data-konnectorName={konnector.name}
                        name={konnector.id}
                        checked={
                          selectedKonnectors.find((currentKonnector) => currentKonnector.id == konnector.id) || false
                        }
                        onChange={handleChange}
                      />
                      <button
                        onClick={() => openInNewTab("/konnectors")}
                        className="text-base px-2 p-2 text-left text-gray-600 hover:text-blue-600"
                      >
                        {konnector.name}
                      </button>
                      <div>
                        <Apps apps={konnector.konnectorApps} />
                      </div>
                      <span className="ml-2 bg-gray-100 text-gray-800 text-xs font-semibold mr-2 h-1/2 p-1 px-2 rounded-sm dark:bg-gray-700 dark:text-gray-300">
                        {konnector.id}
                      </span>
                    </div>
                  </label>
                </>
              ))}
            </div>
            {/* </div> */}
            <button
              onClick={(e) => {
                e.preventDefault();
                findNonSelectedKonnector();
              }}
              data-modal-toggle="popup-modal"
              type="button"
              className="px-1 rounded-sm text-white p-3 mt-2 bg-gray-600 w-full"
            >
              {loading ? (
                <>
                  <svg
                    role="status"
                    className="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="primary-color"
                    />
                  </svg>
                  Loading...
                </>
              ) : (
                <> Downgrade Plan</>
              )}
            </button>
          </form>
        </Modal>
      )}
    </>
  );
};

export default RemoveKonnector;
