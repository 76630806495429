import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StatusType } from "state/new-konnector/types";

import { SortByType, InitialState } from "../types";

export const initialState: InitialState = {
	sortBy: SortByType.DEFAULT,
	filterBy: "",
	status: StatusType.idle,
	currentPage: 1,
	currentActionId: "",
	hasActivated: false,
	hasDeleted: false,
};

const eventLogsSlice = createSlice({
	name: "eventLogs",
	initialState,
	reducers: {
		updateSortBy(state, data: PayloadAction<SortByType>) {
			state.currentPage = 1;
			state.sortBy = data.payload;
		},
		updateCurrentPage(state, data: PayloadAction<number>) {
			state.currentPage = data.payload;
		},
		updateFilter(state, data: PayloadAction<string>) {
			state.currentPage = 1;
			state.filterBy = data.payload;
		},
		apiError(state, data: PayloadAction<any>) {
			state.status = StatusType.failed;
		},
		reset: () => initialState,
	},
});

export const { updateSortBy, updateCurrentPage, apiError, updateFilter, reset } = eventLogsSlice.actions;

export default eventLogsSlice.reducer;
