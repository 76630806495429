import React, { useEffect, useState } from "react";
import { useAppSelector } from "state/store";
import { setBannerData } from "state/Embedded-markteplace/ThemeSettings/slices";
import { useDispatch } from "react-redux";
import { Input } from "components/ui/input";
import mainLogo from "../../images/customPage.png"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "components/ui/tabs"
import { Textarea } from "components/ui/textarea";
const BannerTab: React.FC<any> = ({ types, colors, onColorChange, onChange, onEditorTemplateValueChange }) => {
    const dispatch = useDispatch()
    const bannerData = useAppSelector((state) => state.themeSettings.banner)
    const [localColors, setLocalColors] = useState(colors);
    const [toastStatus, setToastStatus] = useState("success");
    const handleInputChange = (type, value) => {
        setLocalColors((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: value,
        }));
        dispatch(setBannerData({ type: type.toLowerCase(), value }))
        onColorChange(type, value);
    };
    const handleTextValueChange = (e) => {
        dispatch(setBannerData({ type: "failureMsg", value: e.target.value }))
    }
    const handleTextValuechange1 = (e) => {
        dispatch(setBannerData({ type: "successMsg", value: e.target.value }))
    }
    const handleSuccessClick = (state) => {
        setToastStatus(state)
    }
    return (
        <>
            <div className="flex flex-row">
                <div className="grid grid-cols-2 w-3/4 ">
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col w-40">
                            <h2 className="font-bold text-xs">Width</h2>
                            <Input type="number" placeholder="Width" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40" value={bannerData?.width} onChange={(e) => handleInputChange(types[0], e.target.value)} />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col w-40">
                            <h2 className="font-bold text-xs">Heigth</h2>
                            <Input type="number" placeholder="Heigth" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40" value={bannerData?.heigth} onChange={(e) => handleInputChange(types[1], e.target.value)} />
                        </div>
                    </div>

                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col w-40">
                            <h2 className="font-bold text-xs">Radius</h2>
                            <Input type="number" placeholder="Radius" className="mt-5 cursor-pointer py-2 px-3 bg-transparent border border-gray-300 rounded-md w-40" max="50" maxLength={100} value={bannerData?.radius} onChange={(e) => handleInputChange(types[2], e.target.value)} />
                        </div>
                    </div>
                    <div className="flex flex-col"><h2 className="font-bold text-xs">Border color</h2>
                        <div className="flex rounded-full mt-5">
                            <span className=" flex rounded-md w-40 border border-gray-300 h-10 ">
                                <input
                                    type="text"
                                    className=" w-[40%] bg-transparent  pl-2 ml-1 text-xs"
                                    value={bannerData?.bordercolour}
                                    onChange={(e) => handleInputChange(types[5], e.target.value)} />
                                <input
                                    type="color"
                                    className="mt-2 mb-2 ml-4 border w-[30%] bg-transparent mr-2"
                                    value={bannerData?.bordercolour}
                                    onChange={(e) => handleInputChange(types[5], e.target.value)} />
                            </span>
                        </div>
                    </div>
                    <div>
                        <Tabs defaultValue="success" className="w-[200px]">
                            <TabsList className="grid w-full grid-cols-2">
                                <TabsTrigger value="success" onClick={(e) => handleSuccessClick("success")}>Success</TabsTrigger>
                                <TabsTrigger value="error" onClick={(e) => handleSuccessClick("error")}>Error</TabsTrigger>
                            </TabsList>
                            <TabsContent value="success" className="w-96">
                                <div className="flex flex-col space-y-5">
                                    <div className="flex flex-col w-48">
                                        <h2 className="font-bold text-xs">Success Message</h2>
                                        <Textarea placeholder="Success Message" className="mt-5" value={bannerData.successMsg} onChange={handleTextValuechange1} />
                                    </div>
                                </div>
                            </TabsContent>
                            <TabsContent value="error" className="w-96">
                                <div className="flex flex-col space-y-5">
                                    <div className="flex flex-col w-48">
                                        <h2 className="font-bold text-xs">Error Message</h2>
                                        <Textarea placeholder="Error Message." className="mt-5" value={bannerData.failureMsg} onChange={handleTextValueChange} />
                                    </div>
                                </div>
                            </TabsContent>
                        </Tabs>
                    </div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    {/* <div className="flex flex-col space-y-5">
                        <div className="flex flex-col w-48">
                            <h2 className="font-bold text-xs">Success Message</h2>
                            <Textarea placeholder="Success Message" className="mt-5" onChange={handleTextValuechange1} />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-5">
                        <div className="flex flex-col w-48">
                            <h2 className="font-bold text-xs">Failure Message</h2>
                            <Textarea placeholder="Error Message." className="mt-5" onChange={handleTextValueChange} />
                        </div>
                    </div> */}
                </div>

                <div className="flex-initial flex h-[70vh] items-start border-dashed border-slate-200 border-2 w-[85vh]  -mr-12 relative">
                    <div className="" style={{ width: `630px`, height: `410px` }}>
                        <img className=" p-5 transition-all duration-300 rounded-lg cursor-pointer filter object-cover" src={mainLogo} alt="image description">
                        </img>
                        {toastStatus == "success" ?
                            // <div className="absolute flex max-w-full top-8 right-72  bg-white border border-gray-200 rounded-xl shadow-lg" style={{
                            //     width: `${bannerData.width}px`,
                            //     height: `${bannerData.heigth}px`,
                            //     borderRadius: `${bannerData.radius}px`,
                            //     borderColor: bannerData.bordercolour
                            //     //   background: bgColor,
                            //     //   color: textColor,
                            // }} role="alert">
                            //     <div className="flex p-4">
                            //         <div className="flex-shrink-0">
                            //             <svg className="flex-shrink-0 size-4 text-teal-500 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            //                 <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            //             </svg>
                            //         </div>
                            //         <div className="ms-3">
                            //             <p className="text-sm text-gray-700 dark:text-gray-400">
                            //                 {bannerData.successMsg}
                            //             </p>
                            //         </div>
                            //     </div>
                            // </div>
                            <div id="toast-success" className=" absolute flex items-center w-full max-w-xs top-8 right-44 p-4 mb-4 text-gray-500 bg-white rounded-lg shadow border" style={{
                                width: `${bannerData.width}px`,
                                height: `${bannerData.heigth}px`,
                                borderRadius: `${bannerData.radius}px`,
                                borderColor: bannerData.bordercolour
                                //   background: bgColor,
                                //   color: textColor,
                            }} role="alert">
                                <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 text-green-500 bg-green-100 rounded-lg">
                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                    </svg>
                                </div>
                                <div className="ms-3 text-sm font-normal">{bannerData.successMsg}</div>
                                <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8" data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                </button>
                            </div>
                            :
                            <div id="toast-success" className="absolute flex items-center w-full max-w-xs top-8 right-44 p-4 mb-4 text-gray-500 bg-white rounded-lg shadow border" style={{
                                width: `${bannerData.width}px`,
                                height: `${bannerData.heigth}px`,
                                borderRadius: `${bannerData.radius}px`,
                                borderColor: bannerData.bordercolour
                                //   background: bgColor,
                                //   color: textColor,
                            }} role="alert">
                                <div className="inline-flex items-center justify-center flex-shrink-0 w-6 h-6 text-red-500 bg-red-100 rounded-lg">
                                    <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                                    </svg>
                                </div>
                                <div className="ms-3 text-sm font-normal">{bannerData.failureMsg}</div>
                                <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8" data-dismiss-target="#toast-success" aria-label="Close">
                                    <span className="sr-only">Close</span>
                                    <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                </button>
                            </div>
                            // <div className="absolute justify-center items-center top-5 right-36 bg-white border border-gray-200 rounded-xl shadow-lg" style={{
                            //     width: `${bannerData.width}%`,
                            //     height: `${bannerData.heigth}%`,
                            //     borderRadius: `${bannerData.radius}px`,
                            //     borderColor: bannerData.bordercolour
                            //     //   background: bgColor,
                            //     //   color: textColor,
                            // }} role="alert">
                            //     <div className="flex p-4">
                            //         <div className="flex-shrink-0">
                            //             <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            //                 <path d="M10 0C10.918 0 11.8034 0.117188 12.6562 0.351562C13.5091 0.585938 14.3034 0.924479 15.0391 1.36719C15.7747 1.8099 16.4486 2.33073 17.0605 2.92969C17.6725 3.52865 18.1966 4.20247 18.6328 4.95117C19.069 5.69987 19.4043 6.4974 19.6387 7.34375C19.873 8.1901 19.9935 9.07552 20 10C20 10.918 19.8828 11.8034 19.6484 12.6562C19.4141 13.5091 19.0755 14.3034 18.6328 15.0391C18.1901 15.7747 17.6693 16.4486 17.0703 17.0605C16.4714 17.6725 15.7975 18.1966 15.0488 18.6328C14.3001 19.069 13.5026 19.4043 12.6562 19.6387C11.8099 19.873 10.9245 19.9935 10 20C9.08203 20 8.19661 19.8828 7.34375 19.6484C6.49089 19.4141 5.69661 19.0755 4.96094 18.6328C4.22526 18.1901 3.55143 17.6693 2.93945 17.0703C2.32747 16.4714 1.80339 15.7975 1.36719 15.0488C0.93099 14.3001 0.595703 13.5026 0.361328 12.6562C0.126953 11.8099 0.00651042 10.9245 0 10C0 9.08203 0.117188 8.19661 0.351562 7.34375C0.585938 6.49089 0.924479 5.69661 1.36719 4.96094C1.8099 4.22526 2.33073 3.55143 2.92969 2.93945C3.52865 2.32747 4.20247 1.80339 4.95117 1.36719C5.69987 0.93099 6.4974 0.595703 7.34375 0.361328C8.1901 0.126953 9.07552 0.00651042 10 0ZM11.1035 10L14.4434 6.66016L13.3398 5.55664L10 8.89648L6.66016 5.55664L5.55664 6.66016L8.89648 10L5.55664 13.3398L6.66016 14.4434L10 11.1035L13.3398 14.4434L14.4434 13.3398L11.1035 10Z" fill="#FF7F7F" />
                            //             </svg>
                            //         </div>
                            //         <div className="ms-3">
                            //             <p className="text-sm text-gray-700 dark:text-gray-400">
                            //                 {bannerData.failureMsg}
                            //             </p>
                            //         </div>
                            //     </div>
                            // </div>
                        }
                    </div>
                </div>
            </div >
        </>
    );
};
export default BannerTab;



