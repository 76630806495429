import React from "react";
import EventItem from "./EventItem";

const EventList: React.FC<any> = ({ data, konnectorData }) => {
  const renderKonnectors = () => {
    return data.map((eventLog) => {
      const konnector = konnectorData.find((konnector) => konnector.id == eventLog.konnectorId);

      return <EventItem key={eventLog.id} eventLog={eventLog} konnectorData={konnector} />;
    });
  };
  return <>{renderKonnectors()}</>;
};

export default EventList;
