export const setParseConfig = (state) => {
  const data = JSON.parse(JSON.stringify(state));

  const conditionLen = data.conditions?.length;
  for (let j = 0; j < conditionLen; j++) {
    const id = data.conditions[j].id;
    data.conditions[j].data_type = data.rawDataMapping.data[`${id}___field_data_type`];
    data.conditions[j].operator = data.rawDataMapping.data[`${id}___field_operator`];
    data.conditions[j].field_value = data.rawDataMapping.data[`${id}___field_input_value`];
    data.conditions[j].startIndex = data.rawDataMapping.data[`${id}___field_start_index`];
    data.conditions[j].totalCharecter = data.rawDataMapping.data[`${id}___field_end_index`];
  }
  data.parsingItems = data.rawDataMapping.parsingItems;
  data.source_id = data.rawDataMapping.source_id;
  return data;
};
export const setParseConfigForEdit = (state, condition) => {
  const data = JSON.parse(JSON.stringify(state));
  const conditionLen = data.conditions?.length;
  for (let j = 0; j < conditionLen; j++) {
    const id = data.conditions[j].id;
    data.conditions[j].data_type = data.rawDataMapping[`${id}___field_data_type`];
    data.conditions[j].operator = data.rawDataMapping[`${id}___field_operator`];
    data.conditions[j].field_value = data.rawDataMapping[`${id}___field_input_value`];
    data.conditions[j].startIndex = data.rawDataMapping[`${id}___field_start_index`];
    data.conditions[j].totalCharecter = data.rawDataMapping[`${id}___field_end_index`];
  }
  return data;
};
export const setRawDataMappingForParseEngine = (state) => {
  const data = JSON.parse(JSON.stringify(state));
  data.rawDataMapping = {};
  data.rawDataMapping = { data: {} };
  const conditionLen = state.conditions?.length;
  for (let j = 0; j < conditionLen; j++) {
    const id = data.conditions[j].id;
    data.rawDataMapping.data[`${id}___field_data_type`] = data.conditions[j].data_type;
    data.rawDataMapping.data[`${id}___field_operator`] = data.conditions[j].operator;
    data.rawDataMapping.data[`${id}___field_input_value`] = data.conditions[j].field_value;
    data.rawDataMapping.data[`${id}___field_start_index`] = data.conditions[j].startIndex;
    data.rawDataMapping.data[`${id}___field_end_index`] = data.conditions[j].totalCharecter;
  }
  data.rawDataMapping.data.source_id = data.source_id;
  data.rawDataMapping.data.Source_field = data.source_value;
  data.rawDataMapping.parsingItems = data.parsingItems;
  return data.rawDataMapping;
};
export const setRawDataMappingKeys = (id) => {
  const format = `condition${id}___`;
  return format;
};
