import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useAppSelector, useAppDispatch } from 'state/store';
import OutsideClickHandler from 'react-outside-click-handler';
import FormFieldItems from './Components/FormFieldsItems';
import { AppProps } from 'pages/KonnectorPage/types';
import { ActionMode, AppRole, KonnectorAppState, KonnectorType, StatusType } from 'state/new-konnector/types';
import { KonnectorState } from 'state/konnectors/types';
import { fieldList } from './constants/fieldList';

import { createField, setOrderId, editForm, setFormStatus } from 'state/form-builder/slices';
import { LoadingIcon } from 'common/components/Icons';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import WebhookModal from 'common/components/Modal/webhookUrl';
import CancelModal from 'common/components/Modal/CancelModel';
// import { Icons } from "react-toastify";
import { reset } from 'state/edit-konnector/slices';
import CancelFormModal from 'common/components/Modal/CancelFormModel';
import ShowOutputFields from '../Trigger/ShowOutputFields';
interface TriggerConfigurationProps {
	apps: AppProps[];
	triggerDetails: KonnectorAppState<AppRole.Source>;
	konnectorSlice: any;
	konnectorState: KonnectorState;
}

const FormFields: React.FC<TriggerConfigurationProps> = ({ apps, triggerDetails, konnectorSlice, konnectorState }) => {
	console.log(triggerDetails);
	const hasOutputFields = triggerDetails.outputFields.hasData;
	const dispatch = useAppDispatch();
	const selectedSourceApp = triggerDetails.appId;
	const apiStatus = useAppSelector((state) => state[konnectorState].status);
	const konnectorId = triggerDetails.id;
	const webhookId = useAppSelector((state) => state[konnectorState].data.webhookUrl.id);
	const konnectorType = useAppSelector((state) => state[konnectorState].data.konnectorType);
	const formFields = useAppSelector((state) => state[konnectorState].data.trigger.formFields);
	const localFormFields = useAppSelector((state) => state.formBuilder.formFields);
	const formStatus = useAppSelector((state) => state.formBuilder.status);
	const konnStatus = useAppSelector((state) => state[konnectorState].status);
	const isFormGenerated = useAppSelector((state) => state.editKonnector.isFormGenerated);
	const webhookUrl = useAppSelector((state) => state[konnectorState].data.webhookUrl.url);
	const [showModal, setShowModal] = useState(false);
	const formId = useAppSelector((state) => state[konnectorState].data.webhookUrl.id);
	const saveConfigEnabled = _.isEqual(formFields, localFormFields);
	const previewFormDisabled = localFormFields.length === 0;
	const selectedSourceAccount = triggerDetails.connectionId;
	const selectedSourceEvent = triggerDetails.eventId;
	const outputFields: any = triggerDetails.outputFields.fields;
	const [viewOutputFields, setViewOutputFields] = useState(false);
	console.log({ viewOutputFields });

	const [showFormUrl, setShowFormUrl] = useState(false);
	const formEmbedScript = `<script type="text/javascript" src="https://storage.googleapis.com/test-forms/${webhookId}.html"></script>`;

	const handleOnClickTriggerFields = () => {
		setViewOutputFields(true);
	};

	const onClickViewOutputFields = (value) => {
		setViewOutputFields(value);
	};

	const onClickFormUrl = () => {
		setShowFormUrl(true);
	};

	const onClickcloseHandler = () => {
		setShowFormUrl(false);
	};

	const previewForm = () => {
		if (saveConfigEnabled) {
			return window.open(`https://storage.googleapis.com/test-forms/${webhookId}.html`);
		}

		if (konnectorState === KonnectorState.NewKonnectorState) {
			dispatch(konnectorSlice.saveFormSourceConfiguration({ konnectorType }));
		} else if (konnectorState === KonnectorState.EditKonnectorState) {
			dispatch(konnectorSlice.saveFormSourceConfiguration({ konnectorType, webhookId }));
		}
	};

	// useEffect(() => {
	//   return () => {
	//     dispatch(reset());
	//   };
	// }, []);

	// useEffect(() => {
	//   if (actionMode === ActionMode.Edit) {
	//     return () => {
	//       dispatch(editForm(formFields));
	//     };
	//   }
	// }, [actionMode]);

	const saveConfiguration = () => {
		dispatch(konnectorSlice.saveSourceConfiguration());
	};
	const closeModalHandler = () => {
		setShowModal(false);
	};
	const confirmModalHandler = () => {
		dispatch(konnectorSlice.resetForm());
	};

	useEffect(() => {
		if (formStatus === StatusType.success) {
			console.log(formStatus);
			// window.open(`https://storage.googleapis.com/test-forms/${webhookId}.html`, "_blank", "noreferrer");
			// history.replace(`https://storage.googleapis.com/test-forms/${webhookId}.html`);
			dispatch(setFormStatus(StatusType.idle));
		}
	}, [formStatus]);

	const onDragEnd = (result: DropResult) => {
		const { destination, source } = result;
		if (!destination) {
			return;
		}
		if (destination.droppableId === source.droppableId && destination.index === source.index) {
			return;
		}

		if (destination.droppableId === 'formField' && source.droppableId === 'nullDrop') {
			const inputType = fieldList.find((field) => field.id.toString() === result.draggableId);
			dispatch(createField({ type: inputType.type, order: destination.index }));
		}
		if (destination.droppableId === 'formField' && source.droppableId === 'formField') {
			dispatch(setOrderId({ source: source.index, destination: destination.index, fieldId: result.draggableId }));
		}
	};

	const renderOutputFieldsButton = () => {
		if (
			(selectedSourceApp && selectedSourceAccount && selectedSourceEvent) ||
			konnectorType === KonnectorType.WebHook
		) {
			return (
				<div className="flex justify-end">
					<button
						onClick={() => {
							if (webhookUrl) {
								dispatch(konnectorSlice.closeSourceConfigration());
								if (!saveConfigEnabled) {
									return dispatch(editForm(formFields));
								}
							} else {
								dispatch(konnectorSlice.reset());
							}
						}}
						type="button"
						className="py-2.5 px-5 mr-2 text-sm font-medium z-10 text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color"
					>
						Go back
					</button>
					{hasOutputFields && (
						<button
							onClick={handleOnClickTriggerFields}
							type="button"
							disabled={!webhookId || !saveConfigEnabled}
							className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color disabled:opacity-50 disabled:cursor-not-allowed"
						>
							View Output fields
						</button>
					)}
					{
						<button
							onClick={previewForm}
							type="button"
							disabled={konnStatus === StatusType.loading || previewFormDisabled}
							className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color disabled:opacity-50 disabled:cursor-not-allowed"
						>
							{konnStatus === StatusType.loading ? (
								<>
									<LoadingIcon />
									{'Loading...'}
								</>
							) : webhookId && saveConfigEnabled ? (
								'Preview form'
							) : (
								'Generate form'
							)}
						</button>
					}
					{webhookId && (
						<button
							onClick={onClickFormUrl}
							type="button"
							// disabled={!webhookId || !saveConfigDisabled}
							className="py-2.5 px-5 mr-2 text-sm font-medium text-gray-900 bg-gray-50 rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-primary-color disabled:opacity-50 disabled:cursor-not-allowed"
						>
							Embed script
						</button>
					)}
					<button
						type="button"
						onClick={saveConfiguration}
						disabled={!webhookUrl || !saveConfigEnabled}
						className="ml-2 text-white hover:bg-[#0e1a2e] bg-primary-color hover:transition-all font-medium rounded-sm text-sm px-5 py-2.5 disabled:opacity-50"
						// disabled={!hasOutputFields || !outputFields || outputFields.eventResponse?.data?.error}
					>
						Save Configuration
					</button>
				</div>
			);
		}
		return null;
	};

	return (
		<OutsideClickHandler
			onOutsideClick={() => {
				if (saveConfigEnabled && isFormGenerated) {
					setShowModal(true);
				}
			}}
		>
			<section className="px-2 py-2 mt-4 flex flex-col h-[calc(95vh-5rem)] space-y-2 bg-white rounded-md">
				<div className="flex-col space-y-2 h-full">
					<DragDropContext onDragEnd={onDragEnd}>
						<div className="h-full">
							<FormFieldItems konnectorSlice={konnectorSlice} konnectorState={konnectorState} />
						</div>
					</DragDropContext>
					<div>
						{viewOutputFields && (
							<ShowOutputFields
								visible={viewOutputFields}
								setViewOutputFields={(value) => onClickViewOutputFields(value)}
								outputFields={outputFields}
							/>
						)}
					</div>
				</div>
				<div className="flex justify-end">{renderOutputFieldsButton()}</div>
				{webhookUrl && (
					<WebhookModal visible={showFormUrl} closeModalHandler={onClickcloseHandler} url={formEmbedScript} />
				)}
			</section>
			{showModal && (
				<CancelFormModal
					text={''}
					konnectorState={konnectorState}
					visible={showModal}
					closeModalHandler={closeModalHandler}
					confirmHandler={confirmModalHandler}
				/>
			)}
		</OutsideClickHandler>
	);
};

export default FormFields;
