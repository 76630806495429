import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { AppRole, KonnectorAppState, StatusType } from "state/edit-konnector/types";
import { useAppDispatch, useAppSelector } from "state/store";
import FieldItem from "./FieldItem";

interface TargetFieldsType {
  lookupDetails: KonnectorAppState<AppRole.Lookup>;
  konnectorSlice: any;
}
const TargetFields: React.FC<TargetFieldsType> = ({ lookupDetails, konnectorSlice }): JSX.Element => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    control,
  } = useForm();

  const dispatch = useAppDispatch();
  const konnectorState = useAppSelector((state) => state.workflows.workflowState);
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const status = useAppSelector((state) => state[konnectorState].status);
  const inputFields = lookupDetails.inputFields;
  const selectedEvent = lookupDetails.eventId;
  const rawDataMapping = lookupDetails.rawDataMapping;

  const onSubmit = (data) => {
    dispatch(konnectorSlice.saveLookupDataMapping(data));
    dispatch(konnectorSlice.saveLookupConfigurationLoading(currentActionId));
  };

  const renderFields = (): JSX.Element[] => {
    return inputFields.data.map((field, index) => {
      return (
        <FieldItem
          field={field}
          key={index}
          watch={watch}
          setInputValue={setValue}
          control={control}
          register={register}
          errors={errors}
          selectedEvent={selectedEvent}
        />
      );
    });
  };

  useEffect(() => {
    if (selectedEvent) {
      reset(rawDataMapping);
      // dispatch();
    }
  }, [selectedEvent]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {inputFields.data.length ? renderFields() : <p>Loading...</p>}
        <div className="flex justify-end">
          <button
            type="submit"
            className="text-white  bg-primary-color hover:transition-all font-medium rounded-sm text-sm px-5 py-2.5 text-center disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={status === StatusType.loading}
          >
            {status === StatusType.loading ? "Loading..." : "save"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TargetFields;
