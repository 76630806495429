import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeState, removeCondition } from "state/rule-engine/slices";
import { ruleConfigRawDataMappingConditions } from "utils/ruleEngineUtils";
import OperatorType from "./Operator";
import SourceField from "./SourceField";
import SourceType from "./SourceType";
import ValueType from "./Value";
import { useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";

const Condition: React.FC<any> = ({
  conditions,
  watchField,
  filterName,
  match_type,
  value,
  register,
  errors,
  filterIndex,
  getValues,
  setValue,
  reset,
}) => {
  const dispatch = useDispatch();
  const status = useAppSelector((state) => state.ruleEngine.status);
  const removeCon = (i) => {
    const formValue = getValues();
    const result = ruleConfigRawDataMappingConditions(formValue, filterIndex, i);
    reset(result);
    dispatch(removeCondition({ removeConditionIndex: i, filterName: filterName }));
  };
  const formData = getValues();
  useEffect(() => {
    if (status === StatusType.loading) {
      dispatch(changeState());
    }
  }, [formData]);
  const renderConditions = () => {
    return conditions.map((condition, index) => (
      <React.Fragment key={index}>
        <div className="border-[1px] block border-solid bg-[#f5f7f9]  border-[#dadfe3] mt-2">
          <div className="flex flex-row group p-4 w-[100%] space-x-4">
            <SourceType
              register={register}
              watchField={watchField}
              errors={errors}
              value={value}
              index={index}
              filterIndex={filterIndex}
              setValue={setValue}
            />
            <SourceField
              register={register}
              watchField={watchField}
              errors={errors}
              value={value}
              index={index}
              filterIndex={filterIndex}
              setValue={setValue}
            />

            <OperatorType
              register={register}
              watchField={watchField}
              errors={errors}
              value={value}
              index={index}
              filterIndex={filterIndex}
              setValue={setValue}
            />

            <div className="">
              {conditions.length == 1 ? null : (
                <div className="ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mb-2 mt-2  cursor-pointer"
                    onClick={() => removeCon(index)}
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col group px-4 mb-1">
            <ValueType
              register={register}
              watchField={watchField}
              errors={errors}
              value={value}
              getValues={getValues}
              index={index}
              filterIndex={filterIndex}
              setValue={setValue}
            />
          </div>
        </div>
        <div className=" flex  justify-center">
          {conditions.length == 1
            ? null
            : conditions.length != index + 1 && (
                <label className="mt-2 text-center text-sm bg-white shadow-md  border-[1px] border-solid text-primary-color px-4 py-1 rounded-2xl">
                  {match_type}
                </label>
              )}
        </div>
      </React.Fragment>
    ));
  };

  return <>{renderConditions()}</>;
};

export default Condition;
