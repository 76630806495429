import React, { useEffect, useState } from "react";
import { ReloadIcon } from "@radix-ui/react-icons"
import { useDispatch } from "react-redux";
import { setTabState } from "state/Embedded-markteplace/AdminSettings/slices";
import { TabState } from "state/Embedded-markteplace/AdminSettings/types";
import { useAppSelector } from "state/store";
import CardTab from "./Card";
import TemplateTab from "./Template";
import LogoTab from "./Logo";
import LayoutTab from "./Layout";
import { useForm } from "react-hook-form";
import { saveCardData } from "state/Embedded-markteplace/ThemeSettings/slices";
import BannerTab from "./Banner";
import ResetModal from "common/components/Modal/ResetModal";
import { Button } from "components/ui/button";
import { StatusType } from "state/Embedded-markteplace/ThemeSettings/types";



const CodeSnippet: React.FC<any> = () => {
    const {
        register,
        formState: { errors },
        reset,
        watch,
        handleSubmit,
    } = useForm();
    const dispatch = useDispatch()
    const value = useAppSelector((state) => state.adminSettings.tabState)
    const saveStatus = useAppSelector((state) => state.themeSettings.saveStatus)
    const [parentTemplateEditorValue, setParenttemplateEditorValue] = useState('');
    const [parentEditorValue, setParentEditorValue] = useState('');
    const [resetState, setReset] = useState(false);
    const logoTypes = ['url', "size", "colour"];
    const colorTypes = ['Background', 'Hover', 'Text', "width", "heigth", "radius", "bordercolour", "gridSize", "cardSnippet"];
    const templateTypes = ['Background', 'Hover', 'Text', "width", "heigth", "radius", "templateSnippet", "togglecolour"];
    const layoutTypes = ["width", "heigth"];
    const bannerTypes = ["width", "heigth", "radius", "successMessage", "failureMessage", "borderColour"];

    const changeTab = (logo) => {
        dispatch(setTabState(logo))
    }

    const handleEditorValueChange = (value) => {
        setParentEditorValue(value);
    };


    const handleTemplateEditorValueChange = (value) => {
        setParenttemplateEditorValue(value);
    };

    const [colors, setColors] = useState({
        background: '#0D4750',
        hover: '#0D4750',
        text: '#0D4750',
        cardSnippet: ""
    });
    const [templates, setTemplates] = useState({
        background: '#0D4750',
        hover: '#0D4750',
        text: '#0D4750',
        templateSnippet: "",
        togglecolour: ""
    });
    const [layout, setLayout] = useState({
        width: "",
        heigth: "",

    });
    const [banner, setBanner] = useState({
        width: "",
        heigth: "",
        radius: "",
        successMessage: "",
        failureMessage: ""

    });
    const [logo, setLogo] = useState({
        url: "",
        width: "",
        heigth: "",
        colour: ""

    });
    const handleColorChange = (type, color) => {
        setColors((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: color,
        }));
    };
    const handleTemplateChange = (type, color) => {
        setTemplates((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: color,
        }));
    };
    const handleLayoutChange = (type, color) => {
        setLayout((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: color,
        }));
    };

    const handleBannerChange = (type, color) => {
        setBanner((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: color,
        }));
    };
    const handleLogoChange = (type, color) => {
        setLogo((prevColors) => ({
            ...prevColors,
            [type.toLowerCase()]: color,
        }));
    };

    const handleSave = () => {
        colors.cardSnippet = parentEditorValue
        templates.templateSnippet = parentTemplateEditorValue
        dispatch(saveCardData())
    };
    const resetButton = () => {
        setReset(true)
    };
    const closeModalHandler = () => {
        setReset(false)
    };

    const renderTabData = () => {
        switch (value) {
            case TabState.Card: {
                return (
                    <>
                        <CardTab types={colorTypes} colors={colors} onColorChange={handleColorChange} onChange={handleColorChange} onEditorValueChange={handleEditorValueChange} />
                    </>
                )
            }
            case TabState.Template: {
                return (<TemplateTab types={templateTypes} colors={templates} onColorChange={handleTemplateChange} onChange={handleTemplateChange} onEditorValueChange={handleTemplateEditorValueChange} />)
            }
            case TabState.Logo: {
                return (<LogoTab types={logoTypes} colors={layout} onColorChange={handleLogoChange} onChange={handleLogoChange} />)
            }
            case TabState.Layout: {
                return (<LayoutTab types={layoutTypes} colors={layout} onColorChange={handleLayoutChange} onChange={handleLayoutChange} />)
            }
            case TabState.Banner: {
                return (<BannerTab types={bannerTypes} colors={banner} onColorChange={handleBannerChange} onChange={handleBannerChange} />)
            }
        }
    }

    return (
        <>
            {resetState && <ResetModal closeModalHandler={closeModalHandler}></ResetModal>}
            <div className="absolute left-28 right-20 top-36 bottom-28 w-auto h-auto flex">
                <div className="bg-transparent rounded-md md:p-14 lg:p-12 -ml-8 -mt-3 overflow-auto mr-4 m-0 box-border w-56 border-slate-100 border-r-2">
                    <div className="flex flex-col space-y-2 items-center">
                        <div className={value == "logo" ? "bg-komp-secondary-color px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-white flex-row space-x-3 cursor-pointer" : "bg-transparent px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-black flex-row space-x-3 cursor-pointer"} onClick={() => changeTab("logo")}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={value == "logo" ? "lucide lucide-upload w-3 h-3 me-2 text-white" : "lucide lucide-upload w-3 h-3 me-2 text-black"}><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" /><polyline points="17 8 12 3 7 8" /><line x1="12" x2="12" y1="3" y2="15" /></svg>
                            <span className="text-sm">Logo</span>
                        </div>
                        <div className={value == "card" ? "bg-komp-secondary-color px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-white flex-row space-x-3 cursor-pointer" : "bg-transparent px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-black flex-row space-x-3 cursor-pointer"} onClick={() => changeTab("card")}>
                            <svg className={value == "card" ? "w-3 h-3 me-2 text-white" : "w-3 h-3 me-2 text-black"} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18"><path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" /></svg>
                            <span className="text-sm">Cards</span>
                        </div>
                        <div className={value == "template" ? "bg-komp-secondary-color px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-white flex-row space-x-3 cursor-pointer" : "bg-transparent px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-black flex-row space-x-3 cursor-pointer"} onClick={() => changeTab("template")}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={value == "template" ? "lucide lucide-stretch-horizontal w-3 h-3 me-2 text-white" : "lucide lucide-stretch-horizontal w-3 h-3 me-2 text-black"}><rect width="20" height="6" x="2" y="4" rx="2" /><rect width="20" height="6" x="2" y="14" rx="2" /></svg>
                            <span className="text-sm">Template</span>
                        </div>
                        <div className={value == "layout" ? "bg-komp-secondary-color px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-white flex-row space-x-3 cursor-pointer" : "bg-transparent px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-black flex-row space-x-3 cursor-pointer"} onClick={() => changeTab("layout")}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={value == "layout" ? "lucide lucide-layout-template w-3 h-3 me-2 text-white" : "lucide lucide-layout-template w-3 h-3 me-2 text-black"}><rect width="18" height="7" x="3" y="3" rx="1" /><rect width="9" height="7" x="3" y="14" rx="1" /><rect width="5" height="7" x="16" y="14" rx="1" /></svg>
                            <span className="text-sm">Layout</span>
                        </div>
                        <div className={value == "banner" ? "bg-komp-secondary-color px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-white flex-row space-x-3 cursor-pointer" : "bg-transparent px-3 py-2 w-48 mt-17 box-border mr-1 rounded-md flex items-center text-black flex-row space-x-3 cursor-pointer"} onClick={() => changeTab("banner")}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className={value == "banner" ? "lucide lucide-layout-template w-3 h-3 me-2 text-white" : "lucide lucide-layout-template w-3 h-3 me-2 text-black"}><rect width="18" height="7" x="3" y="3" rx="1" /><rect width="9" height="7" x="3" y="14" rx="1" /><rect width="5" height="7" x="16" y="14" rx="1" /></svg>
                            <span className="text-sm">Banner</span>
                        </div>
                    </div>
                </div>

                <div className="flex-1 flex relative flex-col bg-transparent p-2 space-y-4 mt-0">
                    {renderTabData()}
                </div>

            </div >
            {/* <div className="mt-10 flex space-x-2 ml-5 items-center justify-center bg-white
             fixed 
             inset-x-0 
             bottom-0 
             border-t border-gray-100
             p-4">
                <Button type="submit" onClick={resetButton} className="bg-slate-50 text-black w-36">Reset</Button>
                <Button type="submit" onClick={handleSave} className="bg-slate-900 text-white w-36 disabled:opacity-60" disabled={saveStatus === StatusType.loading}>
                    {saveStatus === StatusType.loading ? (
                        <>
                            <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />
                            {"Loading..."}
                        </>
                    ) : saveStatus === StatusType.success ? (
                        "Save"
                    ) : (
                        "Save"
                    )}
                </Button>
            </div> */}

        </>
    );
};

export default CodeSnippet;


