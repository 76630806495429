import React from "react";

const AllKonnectors: React.FC<any> = ({ fill, className }) => {
  return (
    <>
      <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 23.53 15.1"
        className={className ? className : "w-5 h-5"}
        fill={fill ? fill : "#fff"}
      >
        <defs>
          <style>
            {`.cls-all-konn {
        fill: none;
        stroke: #fff;
        stroke-linecap: round;
        stroke-miterlimit: 10;
        stroke-width: 2px;
      }`}
          </style>
        </defs>
        <g id="konnector_1">
          <g id="konnectors_1">
            <g id="konn_sub_1">
              <g>
                <path className="cls-all-konn" d="M1,1H22.53" />
                <path className="cls-all-konn" d="M1,7.58H22.53" />
                <path className="cls-all-konn" d="M1,14.1H22.53" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
export default AllKonnectors;
