import * as React from "react";
import { Itrigger } from "pages/EventLogSummary/types";
import moment from "moment";
import { FormIcon, WebhookIcon } from "common/components/Icons";
import { KonnectorType } from "state/new-konnector/types";

export interface TriggerProps {
  trigger: Itrigger;
  konnectorStatus: boolean;
  konnector: any;
  dtUpdated: Date;
}

const TriggerDetails: React.FC<TriggerProps> = ({ trigger, konnectorStatus, konnector, dtUpdated }) => {
  const iconUrl = konnector.data
    ? konnector.data.konnectorApps.find((icon) => icon.appSelectorType === "source").appId !== "generic"
      ? konnector.data.konnectorApps.find((icon) => icon.appSelectorType === "source").iconUrl
      : "webhook"
    : "";
  const konnectorType = konnector && konnector?.data?.konnectorType;

  return (
    <tr className="bg-gray-100">
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">Trigger</td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
        {konnectorType ? (
          konnectorType !== KonnectorType.Regular ? (
            konnectorType === KonnectorType.WebHook ? (
              <WebhookIcon classNameOp=" inline-flex mr-2 h-8 w-8" />
            ) : (
              <FormIcon />
            )
          ) : (
            <img src={iconUrl} alt="" width="30" height="30" className="inline-flex mr-2" />
          )
        ) : null}

        {trigger.triggerId}
      </td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">{moment(dtUpdated).fromNow()}</td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"></td>
      <td className="px-4 py-4 text-sm font-medium text-gray-800 whitespace-nowrap"></td>
    </tr>
  );
};
export default TriggerDetails;
