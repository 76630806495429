import React from "react";
import { AppProps } from "pages/KonnectorPage/types";
import SelectApps from "./SelectApps";
import SelectAccount from "./SelectAccount";
import SelectEvent from "./SelectEvent";
import { AppRole, KonnectorAppState } from "state/new-konnector/types";
import SelectInputSchema from "./SelectInput";

interface ActionConfigurationProps {
  apps: AppProps[];
  targetDetails: KonnectorAppState<AppRole.Target>;
  konnectorSlice: any;
}

const ActionConfiguration: React.FC<ActionConfigurationProps> = ({ apps, targetDetails, konnectorSlice }) => {
  return (
    <>
      <div className="flex mb-3 justify-between">
        <SelectApps apps={apps} sourceApp={targetDetails.appId} konnectorSlice={konnectorSlice} />
        {targetDetails.appId && (
          <SelectAccount
            sourceApp={targetDetails.appId}
            apps={apps}
            sourceAccount={targetDetails.connectionId}
            konnectorSlice={konnectorSlice}
          />
        )}
      </div>
      <div className="flex justify-between">
        {targetDetails.appId && targetDetails.connectionId && (
          <SelectEvent
            sourceApp={targetDetails.appId}
            sourceEvent={targetDetails.eventId}
            apps={apps}
            konnectorSlice={konnectorSlice}
          />
        )}
      </div>
      <div className="flex justify-between">
        {targetDetails.appId && targetDetails.connectionId && targetDetails.eventId && (
          <SelectInputSchema
            inputFields={targetDetails.dynamicInputFields}
            sourceEvent={targetDetails.eventId}
            konnectorSlice={konnectorSlice}
          />
        )}
      </div>
    </>
  );
};

export default ActionConfiguration;
