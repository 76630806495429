import React from "react";
import { PencilIcon } from "@heroicons/react/outline";
import { User } from "../types";
import { useAppSelector } from "state/store";
import Badge from "common/components/Badge";
import { BadgeTypes } from "common/components/Badge/types";

interface HeaderProps extends User {
  handleEditProfile: () => void;
}

const Header: React.FC<HeaderProps> = ({ handleEditProfile, name, email }) => {
  const isVerify = useAppSelector((state) => state.auth.isVerified);
  return (
    <div className="flex justify-between items-center p-4 border-2 border-gray-200">
      <div className="flex space-x-3 items-center">
        <div className="h-14 w-14 flex justify-center items-center bg-primary-color text-white p-0.5 rounded-full">
          {name
            ? name.split("").slice(0, 2).join("").toUpperCase()
            : email.split("").slice(0, 2).join("").toUpperCase()}
        </div>
        <div>
          <h1 className="text-primary-color font-medium">{name}</h1>
          <h4 className="text-[#A3ADBC] text-sm">{email}</h4>
        </div>
        {!isVerify ? (
          <div className={name && "mt-5"}>
            <Badge type={BadgeTypes.Error} text={"Not Verified"} />
          </div>
        ) : (
          <div className={name && "mt-5"}>
            <Badge type={BadgeTypes.Success} text={"Verified"} />
          </div>
        )}
      </div>
      <div>
        <button
          onClick={handleEditProfile}
          className="text-xs p-1 bg-white hover:bg-gray-100 transition-all space-x-2 w-auto border flex justify-center items-center rounded-sm border-[#A3ADBC]"
        >
          <PencilIcon className="h-4 w-4 text-primary-color" />
          <span>Edit Profile</span>
        </button>
      </div>
    </div>
  );
};

export default Header;
