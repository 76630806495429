import SingleSelectField from "common/components/FormFields/SingleSelect";
import { Field } from "utils/appsUtils";

interface FieldValueTypeProps {
	propName: string;
	isRequired: boolean;
}

const FIELD_VALUE_TYPES = [
	{
		label: "Source",
		value: "source",
	},
	{
		label: "Fixed",
		value: "fixed",
	},
];
export default function FieldValueType({ field, errors, register }) {
	const getFieldOptions = () => {
		return new Field(field).format();
	};
	return (
    <div className="flex justify-center">
      <div className="mb-3 xl:w-96">
        <label htmlFor={field.propName} className="form-label flex  inline-block mb-2 text-primary-color text-sm">
          {field.propName} {field.isRequired ? <span className="ml-2 text-red-600">*</span> : <span className="ml-auto text-gray-400">optional</span>}
        </label>
        <SingleSelectField choices={FIELD_VALUE_TYPES} {...getFieldOptions()} name={field.propName + "__field_type"} register={register} errors={errors} />
      </div>
    </div>
  );
}
