export enum StatusType {
  idle = "idle",
  loading = "loading",
  success = "success",
  failed = "failed",
}

export enum LoadingType {
  idle = "idle",
  sentMail = "sentMail",
  forgotPassword = "forgotPassword",
  resetPassword = "resetPassword",
}
export enum PlanType {
  null = "null",
  paid = "paid",
  free = "free",
}

export interface AuthState {
  loading: boolean;
  currentUser: CurrentUser | any;
  isAuthenticated: boolean;
  externalLogin: boolean;
  error?: string;
  isVerified?: boolean;
  isVerifiedLoading?: any;
  disableKonnector?: boolean;
  isTrial?: boolean;
  data?: any;
  signInLoading?: boolean;
  mailSent?: boolean;
  resetPassword?: boolean;
  forgotPasswordError?: string;
  resetPasswordError?: string;
  showWarning?: boolean;
  loadingType?: any;
  currentPlan?: any;
  planExpired?: boolean;
  user?: any;
  app?: string;
  status?: StatusType;
  appStatus?: boolean;
  categoryStatus?: StatusType;
  categoryArray?: [];
  isModal?: boolean;
  isEditModal?: boolean;
  isDeleteModal?: boolean;
  categoryValue?: string;
  editCategoryStatus?: StatusType;
  deleteValue?: any;
  deleteCategoryStatus?: StatusType;
  categoryType?: string;
  displayCategoryList?: [];
  isReorderModal?: boolean;
  accountCredentials?: any
}

export interface CurrentUser {
  name: string;
  tenantId: string;
  billing: UserBilling;
  hasTrial: boolean;
  currentPlan: string;
  email: string;
  dtUpdated?: string;
  stripeId: string;
  eventsLimit: number;
  dtCreated: string;
  id: string;
  isEmailVerified: boolean;
  planExpired: boolean;
  taskExecutedCount: number;
}

export interface UserBilling {
  addon: number;
  cycle: "annual" | "monthly";
  plan: UserPlan;
  subscriptionId: string;
}
export enum UserPlan {
  Growth = "growth",
  Free = "free",
  Startup = "startup",
  TrialExpired = "trial_expired",
  LimitReached = "limit_reached",
  Enterprise = "enterprise",
}

export enum UserPlanVm {
  Growth = "Growth",
  Free = "Free",
  Startup = "Startup",
  TrialExpired = "Trial Expired",
  LimitReached = "Limit Reached",
  Upgrade = "Upgrade Plan",
  Trial = "Trial",
  Enterprise = "Enterprise",
}

export const UserPlanObj = {
  growth: UserPlanVm.Growth,
  free: UserPlanVm.Free,
  startup: UserPlanVm.Startup,
  trial_expired: UserPlanVm.TrialExpired,
  upgrade: UserPlanVm.Upgrade,
  enterprise: UserPlanVm.Enterprise,
};
