import { InitialState, TabState } from "state/Embedded-markteplace/AdminSettings/types";
import { StatusType } from "state/new-konnector/types";

export const serializeAdminSettings = (data): InitialState => {
  return {
    primaryBackground: data.primary_bgColor,
    rawDataMapping: {},
    primaryBackgroundText: data.primary_bgColor_text,
    SetConfigured: { id: 1 },
    fontFamily: data.fontFamily,
    saveDataMapping: StatusType.idle,
    hasData: false,
    headerfontFamily: data.headerfontFamily,
    primaryButtonColor: data.primary_button_Color,
    secondaryButtonColor: data.secondary_button_Color,
    TextColor: data.bodytext_color,
    primaryButtonText: data.primary_button_Text,
    secondaryButtonText: data.secondary_button_text,
    portalTile: data.portalTitle,
    portalDescription: data.portalDescription,
    tabState: TabState.Logo,
    customizationState: false,
    tabModalStatus: false
  };
};

// bodytext: "#07006b";
// bodytext_color: "#07006b";
// primary_bgColor: "#c70505";
// primary_bgColor_text: "#F0F0F0";
// primary_button_Color: "#07006b";
// primary_button_Text: "#07006b";
// secondary_button_Color: "#db2424";
// secondary_button_text: "#F0F0F0";
