import React, { useEffect, useState } from "react";
import { AppProps, AccountProps } from "pages/KonnectorPage/types";
import { useAppDispatch, useAppSelector } from "state/store";
import Header from "./Header";
import ShowConfigurationDetails from "./Trigger/ShowConfigurationDetails";
import ShowActionConfigurationDetails from "./Action/ShowConfigurationDetails";
import ShowLookupConfigurationDetails from "./Lookup/ShowConfigurationDetails";
import ShowRuleConfigurationDetails from "./RuleEngine/ShowConfigrationDetails";
import ShowParseConfigurationDetails from "./ParseEngine/ShowParseConfigrationDetails";
import { PlusIcon } from "common/components/Icons";
import { KonnectorState } from "state/konnectors/types";
import { KonnectorType } from "state/new-konnector/types";
import { useParams } from "react-router-dom";
import { WorkFlowState } from "state/workFlows/types";
import { WorkflowType } from "state/new-workflow/types";

interface ConfigureKonnectorProps {
  apps: AppProps[];
  accounts: AccountProps[];
  konnectorState: WorkFlowState;
  konnectorSlice: any;
}

const ConfigureKonnector: React.FC<ConfigureKonnectorProps> = ({ apps, accounts, konnectorState, konnectorSlice }) => {
  const dispatch = useAppDispatch();
  const currentActionId = useAppSelector((state) => state[konnectorState].currentActionId);
  const konnectorType = useAppSelector((state) => state[konnectorState].data.workflowType);
  const hasConfigurationSaved = useAppSelector((state) => state[konnectorState].hasConfigurationSaved);
  const savedLookups = useAppSelector((state) => state[konnectorState].data.lookups);
  const savedRules = useAppSelector((state) => state[konnectorState].data.ruleEngine);
  const savedActions = useAppSelector((state) => state[konnectorState].data.actions);
  const savedParseEngine = useAppSelector((state) => state[konnectorState].data.parseEngine);
  const hasSampleData = useAppSelector((state) => state[konnectorState].data.trigger.outputFields.hasData);
  let actionArray = [...savedLookups, ...savedRules, ...savedParseEngine, ...savedActions];
  actionArray = actionArray.sort((a, b) => a.actionOrderId - b.actionOrderId);
  const loadingState = useAppSelector((state) => state.createNewWorkflow.isLoading);
  const [showModal, setShowModal] = useState(false);
  const closeModalHandler = () => {
    setShowModal(false);
  };
  const confirmModalHandler = () => {
    setShowModal(false);
  };

  const onClickActionHandler = () => {
    dispatch(konnectorSlice.createAction());
  };

  const onClickLookupHandler = () => {
    dispatch(konnectorSlice.createLookup());
  };
  const { other, appid, accountid, eventid, name } = useParams<any>();
  const className =
    other && loadingState
      ? "h-[calc(100vh-5rem)] overflow-y-scroll flex w-full flex-col justify-start invisible"
      : "h-[calc(100vh-5rem)] overflow-y-scroll flex w-full flex-col justify-start ";
  useEffect(() => {
    if (other && hasSampleData) {
      dispatch(konnectorSlice.createAction());
      dispatch(konnectorSlice.updateName(name));
    }
  }, [hasSampleData]);
  const ComponentArray = () => {
    const compArray = [];

    for (let i = 0; i < actionArray.length; i++) {
      const action = actionArray[i];
      switch (action.appSelectorType) {
        case "lookup":
          compArray.push(
            <ShowLookupConfigurationDetails
              action={action}
              apps={apps}
              accounts={accounts}
              konnectorSlice={konnectorSlice}
            />
          );
          break;
        case "target":
          compArray.push(
            <ShowActionConfigurationDetails
              action={action}
              apps={apps}
              accounts={accounts}
              konnectorSlice={konnectorSlice}
            />
          );
          break;
        case "ruleEngine":
          compArray.push(<ShowRuleConfigurationDetails action={action} konnectorSlice={konnectorSlice} />);
          break;
        default:
          compArray.push(<ShowParseConfigurationDetails action={action} konnectorSlice={konnectorSlice} />);
      }
    }
    return compArray;
  };

  return (
    <>
      <section className={`${className}`}>
        {konnectorType === WorkflowType.Form && !hasConfigurationSaved ? (
          <></>
        ) : (
          <>
            <Header konnectorSlice={konnectorSlice} konnectorState={konnectorState} />
          </>
        )}

        <section
          className={`flex ${
            konnectorType === WorkflowType.Form && !hasConfigurationSaved ? "px-2 pb-1" : "px-20 pb-10"
          }bg-gray-50 w-full flex-col justify-start`}
        >
          {/* configuration list */}
          <section className={`pb-5 flex flex-col bg-transparent rounded-md`}>
            {
              <ShowConfigurationDetails
                apps={apps}
                accounts={accounts}
                konnectorSlice={konnectorSlice}
                konnectorState={konnectorState}
              />
            }
            {hasConfigurationSaved && (
              <>
                {ComponentArray()}

                {!currentActionId && (
                  <div className="flex space-x-3 justify-end w-full">
                    <button
                      onClick={onClickLookupHandler}
                      className="mt-4 flex text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all hover:bg-komp-hover-color font-medium rounded text-sm px-5 py-2.5 text-center"
                    >
                      <PlusIcon /> <span className="ml-2">Add Lookup</span>
                    </button>
                    <button
                      onClick={onClickActionHandler}
                      className="mt-4 flex text-white hover:bg-[#0e1a2e] bg-komp-secondary-color hover:transition-all hover:bg-komp-hover-color font-medium rounded text-sm px-5 py-2.5 text-center"
                    >
                      <PlusIcon /> <span className="ml-2">Add Action</span>
                    </button>
                  </div>
                )}
              </>
            )}
          </section>
        </section>
      </section>
    </>
  );
};

export default ConfigureKonnector;
