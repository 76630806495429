import React, { useState } from "react";
import ViewJson from "../../ViewJSon";
import ActionSummary from "./ActionItem";

const Actiondetails: React.FC<any> = ({ actions, actionData, dtUpdated }) => {
  const [state, setState] = useState(false);

  const handleActionClick = () => {
    setState(true);
  };
  return (
    <>
      {actions && (
        <ActionSummary
          handleActionChange={() => handleActionClick()}
          iconUrl={actionData?.iconUrl}
          appEventId={actionData?.appEventId ? actionData?.appEventId : actions.name}
          status={actions.status}
          dtUpdated={dtUpdated}
          outputFields={actions}
          title={actionData?.appEventId}
        />
      )}
      {state && (
        <ViewJson
          visible={state}
          setViewOutputFields={setState}
          outputFields={actions}
          title={actionData?.appEventId ? actionData?.appEventId : actions.name}
        />
      )}
    </>
  );
};

export default Actiondetails;
