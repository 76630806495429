import React from "react";
import { useAppSelector } from "state/store";
import FormFieldLabel from "./FormFieldLabel";
import { fieldList } from "../constants/fieldList";
import { Droppable } from "react-beautiful-dnd";
import RenderFields from "../FormFields";
import { DragDropIcon } from "../Icons/DragDropIcon";

const FormFieldItems: React.FC<any> = () => {
  const formField = useAppSelector((state) => state.formBuilder.formFields);

  return (
    <>
      <div className={`flex w-full bg-gray-50 h-full justify-center `}>
        <Droppable droppableId="nullDrop" isDropDisabled={true}>
          {(provided) => (
            <div
              className="flex-col w-1/4 space-y-2 bg-gray-50 p-1 flex justify-center"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              <div className="flex flex-col justify-start space-y-3 h-full">
                <div className="text-xl p-3 text-left font-semibold border-b-2 text-gray-500">Form Builder</div>
                <div className="flex flex-col space-y-3 h-full">
                  {fieldList.map((field, index) => {
                    return <FormFieldLabel field={field} index={index} />;
                  })}
                </div>
                {provided.placeholder}
              </div>
            </div>
          )}
        </Droppable>
        <div className="flex flex-col w-3/4 h-full items-center bg-gray-100 overflow-y-scroll">
          <Droppable droppableId="formField">
            {(provided) => (
              <div
                className="flex-col p-4 w-4/5 space-y-2 justify-center items-center h-full"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {formField?.map((field, index) => {
                  return <RenderFields field={field} index={index} />;
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {formField.length === 0 && (
            <div className="fixed flex flex-col items-center justify-center h-[calc(95vh-5rem)] ">
              <DragDropIcon />
              <div className="text-2xl font-bold text-gray-500">Drag and drop components</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FormFieldItems;
