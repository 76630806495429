import React, { useEffect, useState } from "react";

import AppList from "./AppList";
import { AdminIcons } from "./types";
import { useAppSelector } from "state/store";

const Apps: React.FC<any> = () => {
  const apps = AdminIcons;
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <section className="flex items-start h-[calc(100vh-5rem)] mt-20  h-auto ml-20">
      <div className="p-5 w-full h-[calc(100vh-5rem)] flex flex-col justify-between">
        <AppList apps={apps} pageNumber={pageNumber} setPageNumber={setPageNumber} />
      </div>
    </section>
  );
};

export default Apps;
