import React, { useEffect, useState } from "react";
import { useAppSelector } from "state/store";
import Select from "react-select";
import { useApps } from "api/appsApi";
import { useDispatch } from "react-redux";
import { setCategoryLoading, setModalFalse } from "state/auth/slices";
import { StatusType } from "state/auth/types";
import { LoadingIcon } from "common/components/Icons";
import Spinner from "common/components/Spinner";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import { useCurrentUserApi } from "api/authApi";

const CreateCategoryModal: React.FC<any> = ({ visible, closeModalHandler, text }) => {
    const status = useAppSelector((state) => state.auth.categoryStatus);
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState([]);
    const [allEmbeddedPageApps, setEmbeddedApps] = useState([]);
    const [textError, setTextError] = useState(false)
    const [error, setError] = useState(false)
    const { data: embeddedApps, error: embeddedAppsError } = useCurrentUserApi();
    const {
        register,
        formState: { errors },
        // handleSubmit,
        reset,
    } = useForm();
    const { data: apps, error: appsError } = useApps();
    useEffect(() => {
        if (embeddedApps) {
            setEmbeddedApps(embeddedApps?.data.activeApps ? embeddedApps?.data.activeApps : []);
        }
    }, [embeddedApps]);
    const renderApps = () => {
        const appList = apps?.data?.items?.filter((data) => {
            return allEmbeddedPageApps?.some((item: any, index) => data.id === item)
        });
        return appList.map((app) => ({
            label: app.appName,
            value: app.id,
            iconUrl: app.iconUrl,
            description: app.description
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const categoryName = event.target[0].value;
        let selectedValueArr = selectedValue.map(item => {
            return {
                appName: item.label,
                id: item.value,
                iconUrl: item.iconUrl,
                description: item.description
            };
        });
        const id = uuidv4()
        const categoryList = {}
        categoryList["id"] = id
        categoryList["label"] = categoryName
        categoryList["value"] = selectedValueArr
        const categoryExists = embeddedApps?.data?.appCategory?.some(app => app.label === categoryName);
        if (categoryExists || event.target[0].value == "") {
            setTextError(true);
        } else if (selectedValue.length === 0 || allEmbeddedPageApps.length == 0) {
            setError(true);
        } else {
            dispatch(setCategoryLoading(categoryList));
        }
    };

    const handleChange = e => {
        setSelectedValue(e);
    }
    const borderStyle = {
        control: (base) => ({
            ...base,
            border: error ? "1px solid #ff8b67" : "1px solid #cccccc",
            boxShadow: error ? "0px 0px  #ff8b67" : "none",
        }),
    };

    const closeHandler = () => {
        dispatch(setModalFalse())
    };
    if (!apps) return <Spinner />;

    return (
        <>
            {visible && (
                <>
                    <div className="justify-center  flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-auto my-6 mx-auto max-w-3xl">
                            <div className="border-0  shadow-lg relative flex flex-col w-[40rem] bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5  rounded-t">
                                    <h5 className="text-lg font-semibold">Create Category</h5>
                                    <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                        onClick={closeHandler}>
                                        <svg className="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                            <path stroke="gray" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                </div>
                                <form onSubmit={handleSubmit}>
                                    <div className="relative p-6 flex-auto">
                                        <TextField id="outlined-basic" label="Enter Category Name" variant="outlined" size="small" className="w-full" error={textError ? true : false} helperText={textError ? "Invalid Category." : ""} />
                                    </div>
                                    <div className="relative p-6 flex-auto">
                                        <label>Select Apps</label>
                                        <Select
                                            {...register}
                                            isMulti
                                            id="firstName"
                                            name="colors"
                                            options={renderApps()}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={handleChange}
                                            styles={borderStyle}
                                        />
                                        <h1 className="text-gray-400 text-sm">These selected apps will be added to created category</h1>
                                    </div>
                                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                                        <button
                                            className="bg-komp-secondary-color text-white active:bg-komp-secondary-color font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 disabled:opacity-50 disabled:cursor-not-allowed"
                                            type="submit"
                                            disabled={status === StatusType.loading ? true : false}
                                        // onClick={saveHandler}
                                        >
                                            <>
                                                {status === StatusType.loading ? (
                                                    <>
                                                        <LoadingIcon />
                                                        {"Save..."}
                                                    </>
                                                ) : status === StatusType.success ? (
                                                    closeModalHandler()
                                                ) : (
                                                    "Save"
                                                )}
                                            </>
                                        </button>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            )}
        </>
    );
};

export default CreateCategoryModal;
