import React from "react";

const RenderFields: React.FC<any> = ({ children, visible, closeModalHandler }) => {
  return (
    <>
      {visible && (
        <>
          <div
            id="account-modal"
            aria-hidden="true"
            className="flex overflow-y-auto overflow-x-hidden z-50 w-full justify-center items-center h-modal md:h-full md:inset-0"
          >
            <div className="relative w-full h-full md:h-auto">
              {/* Modal content */}

              <div className="relative bg-slate-200 shadow "> {children}</div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-gray-300"></div>
        </>
      )}
    </>
  );
};

export default RenderFields;
