import React from "react";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.min.css";
import { useAppSelector } from "state/store";
import { StatusType } from "state/new-konnector/types";

const Error: React.FC<any> = ({ user }) => {
  const currentUser = user;
  const status = useAppSelector((state) => state.payments.createKonnector);

  return (
    <>
      {!status.status && status.type !== "emailNotVerified" && (
        <div className="flex max-h-14 bg-red-200 w-full justify-center">
          <header className=" text-center flex items-start px-3 py-2 space-x-1">
            <>
              <div className="text-sm flex space-x-1 text-center align-middle">
                <p className="text-center font-semibold">{status.message}</p>
              </div>
            </>

            <Link to={"/payments"} className={"text-sm font-semibold text-[#2c5cc5] opacity-80"}>
              Upgrade your plan
            </Link>
          </header>
        </div>
      )}
    </>
  );
};

export default Error;
