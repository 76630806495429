import { createSlice } from "@reduxjs/toolkit";
import { ConfigurationType, StatusType } from "state/new-konnector/types";
import { InitialState } from "../types";
import {
  ruleConfigRawDataMapping,
  setOperator,
  setOperatorArray,
  setOperatorArrayAfterRemovingFilter,
  setRawDataMappingKeys,
  setRuleConfig,
} from "utils/ruleEngineUtils";

const initialState: InitialState = {
  operator: "",
  currentFilterId: "",
  currentConditionId: "",
  operatorArray: {},
  status: StatusType.idle,
  configurationType: ConfigurationType.RuleEngine,
  filters: [
    {
      name: "filter_set_1",
      match_type: "all",
      conditions: [
        {
          field_type: "",
          field_name: "",
          operator: "all",
          value: "",
          name: "",
          data_type: "",
        },
      ],
    },
  ],
  rawDataMapping: {},
  saveDataMapping: StatusType.idle,
};

const ruleSlice = createSlice({
  name: "ruleEngine",
  initialState,
  reducers: {
    addCondition(state, payload) {
      const findFilterName = state.filters.findIndex((filter) => filter.name === payload.payload.name);
      const conditionLength = state.filters.find((condition) => condition.conditions.length < 5);
      if (conditionLength) {
        const newCondition = {
          field_type: "",
          field_name: "",
          operator: "",
          value: "",
          name: "",
          data_type: "",
        };
        state.filters[findFilterName].conditions.push(newCondition);
      } else {
        console.log("You cant add a filter with more than 5 conditions");
      }
    },
    addFilter(state) {
      const filterLength = state.filters.length;
      const currentFilterName = state.filters[0].name;
      const existingFilter = state.filters.map((e) => {
        return e.name;
      });
      var filterNames = ["filter_set_1", "filter_set_2", "filter_set_3", "filter_set_4", "filter_set_5"];
      filterNames = filterNames.filter((e) => {
        return !existingFilter.includes(e);
      });
      if (filterLength < 5) {
        const newFilter = {
          name: filterNames[0],
          match_type: "all",
          conditions: [
            {
              field_type: "",
              field_name: "",
              operator: "",
              value: "",
              name: "",
              data_type: "",
            },
          ],
        };
        state.filters.push(newFilter);
        const filterLengthh = state.filters.length;
        const lastIndex = filterLengthh - 1;
        const lastIndexName = state.filters[lastIndex].name;
        state.operatorArray = setOperatorArray(newFilter, state.operatorArray);
        state.operator =
          filterLength >= 2 ? state.operator + " AND " + lastIndexName : currentFilterName + " AND " + lastIndexName;
      } else {
        console.log("You cant add a filter with more than 5 filters");
      }
    },
    removeFilterState(state, data) {
      const filterLength = state.filters.length;
      if (filterLength < 1) {
        console.log("you can't delete a filter with less than 1 filters");
      } else {
        state.filters.pop();
        state.operatorArray = setOperatorArrayAfterRemovingFilter(state.operatorArray, data.payload.index + 1);
      }
      state.status = StatusType.loading;
    },
    removeCondition(state, payload) {
      const findFilterIndex = state.filters.findIndex((filter) => filter.name === payload.payload.filterName);
      const conditions = state.filters[findFilterIndex].conditions;
      conditions.splice(payload.payload.removeConditionIndex, 1);
      state.status = StatusType.loading;
    },
    operatorCondition(state, payload) {
      const findFilterIndex = state.filters.findIndex((filter) => filter.name == payload.payload.filterName);
      state.filters[findFilterIndex].match_type = payload.payload.match_type;
      console.log(state.filters[findFilterIndex].match_type);
    },
    saveDataMapping: (state, data) => {
      for (const payload in data.payload) {
        if (data.payload[payload] !== "") state.rawDataMapping[payload] = data.payload[payload];
      }
      state.operator = setOperator(state.operator, state.operatorArray);
      state.saveDataMapping = StatusType.loading;
    },
    changeState: (state) => {
      state.status = StatusType.success;
    },
    operatorType(state, payload) {
      const attachedFilters = payload.payload.attachedFilters;
      const toggleState = payload.payload.toggleState;
      const regexObj = new RegExp("filter_set_" + attachedFilters[0] + "\\s(AND|OR)\\sfilter_set_[0-9]", "gm");
      const matchResults = JSON.parse(JSON.stringify(regexObj.exec(state.operator)));
      const filterOperatorChangedString = `filter_set_${attachedFilters[0]} ${toggleState} filter_set_${attachedFilters[1]}`;
      state.operator = state.operator.replace(matchResults ? matchResults[0] : [], filterOperatorChangedString);
      switch (attachedFilters[0]) {
        case 1:
          state.operatorArray["filter_set_1"] = toggleState;
          break;
        case 2:
          state.operatorArray["filter_set_2"] = toggleState;
          break;
        case 3:
          state.operatorArray["filter_set_3"] = toggleState;
          break;
        case 4:
          state.operatorArray["filter_set_4"] = toggleState;
          break;
      }
    },
    setSourceFieldDatatype: (state, data) => {
      state.filters[data.payload.filterIndex].conditions[data.payload.conditionIndex].data_type = data.payload.dataType;
      const rawDataFormat = setRawDataMappingKeys(data.payload.filterIndex, data.payload.conditionIndex, "data_type");
      state.rawDataMapping[rawDataFormat] = data.payload.dataType;
    },
    saveDataMappingSucces(state) {
      state.saveDataMapping = StatusType.success;
    },
    editRule(state, data) {
      state.rawDataMapping = data.payload.ruleConfigration.rawDataMapping;
      state.operator = data.payload.ruleConfigration.operator;
      state.operatorArray = data.payload.ruleConfigration.operatorArray;
      const stateValue = setRuleConfig(data.payload.ruleConfigration, data.payload.filterConditionLength);
      state.filters = stateValue.filters;
      state.operatorArray = stateValue.operatorArray;
      state.saveDataMapping = StatusType.loading;
    },
    reset: () => initialState,
  },
});
export const {
  addCondition,
  addFilter,
  removeFilterState,
  removeCondition,
  operatorCondition,
  saveDataMapping,
  changeState,
  operatorType,
  setSourceFieldDatatype,
  saveDataMappingSucces,
  editRule,
  reset,
} = ruleSlice.actions;

export default ruleSlice.reducer;
