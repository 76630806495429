import { useAllKonnector } from "api/konnectorsApi";
import React from "react";
import { getKonnectorsFilterType } from "state/konnectors/selectors";
import { setAppType, updateFilter } from "state/konnectors/slices";
import { FilterType } from "state/konnectors/types";
import { useAppDispatch, useAppSelector } from "state/store";
import { appTypeField } from "../types";

const SidebarControls: React.FC = () => {
  const appType = useAppSelector((state) => state.konnectors.appType);
  const dispatch = useAppDispatch();
  const activeClass = "text-white bg-primary-color hover:bg-[#1f324e]";
  const defaultClass = "text-primary-color bg-white";
  const getClasses = (type) => {
    return type === appType ? ` ${activeClass}` : ` ${defaultClass}`;
  };

  const onClickFliterHandler = (type) => {
    dispatch(setAppType(type));
  };
  return (
    <div className="border-r-2 h-[calc(100vh-5rem)] w-[20%] flex flex-col">
      {appTypeField.map((type) => {
        return (
          <button
            onClick={() => onClickFliterHandler(type.type)}
            type="button"
            className={
              "w-full font-medium transition-all rounded-sm text-sm px-6 py-3 text-left mr-2 hover:transition-all" +
              getClasses(type.type)
            }
          >
            {type.label}
          </button>
        );
      })}
    </div>
  );
};

export default SidebarControls;
