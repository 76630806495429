import { AxiosResponse } from "axios";
import { takeEvery, put, call, select } from "redux-saga/effects";
import {
  validateAccountSuccess,
  accountApiFailed,
  createAccountSuccess,
  setOAuthAccountLoading,
  setAccountLoading,
  setUpdateAccountLoading,
  updateAccountSuccess,
  setUpdateOAuthAccountLoading,
  validateAccountFailed,
} from "./slices";
import { CreateAccountSuccessResponse, ValidateAccountSuccessResponse } from "state/new-konnector/types";
import { createAccountApi, updateAccountApi, validateAccountApi } from "api/accountsApi";

export function* createAccount(data) {
  try {
    const isValidated = yield select((state) => state.account.isValidated);
    if (isValidated) {
      const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(createAccountApi, data.payload);
      yield put(createAccountSuccess(result.data));
    } else {
      const result: AxiosResponse<ValidateAccountSuccessResponse> = yield call(validateAccountApi, data.payload);
      if (result.data.data) {
        yield put(validateAccountSuccess(result.data));
      } else {
        yield put(validateAccountFailed(result.data));
      }
    }
  } catch (e) {
    yield put(accountApiFailed());
  }
}

export function* createOAuthAccount(data) {
  try {
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(createAccountApi, data.payload);
    yield put(createAccountSuccess(result.data));
  } catch (e) {
    yield put(accountApiFailed());
  }
}

export function* updateAccount(data) {
  try {
    const isValidated = yield select((state) => state.account.isValidated);
    const accountId = yield select((state) => state.account.activeAccount.id);
    if (isValidated) {
      const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(updateAccountApi, accountId, data.payload);
      yield put(updateAccountSuccess(result.data));
    } else {
      const result: AxiosResponse<ValidateAccountSuccessResponse> = yield call(validateAccountApi, data.payload);
      yield put(validateAccountSuccess(result.data));
    }
  } catch (e) {
    yield put(accountApiFailed());
  }
}

export function* updateOAuthAccount(data) {
  try {
    const accountId = yield select((state) => state.account.activeAccount.id);
    const result: AxiosResponse<CreateAccountSuccessResponse> = yield call(updateAccountApi, accountId, data.payload);
    yield put(updateAccountSuccess(result.data));
  } catch (e) {
    yield put(accountApiFailed());
  }
}

export default function* rootSaga() {
  yield takeEvery(setAccountLoading.type, createAccount);
  yield takeEvery(setOAuthAccountLoading.type, createOAuthAccount);
  yield takeEvery(setUpdateAccountLoading.type, updateAccount);
  yield takeEvery(setUpdateOAuthAccountLoading.type, updateOAuthAccount);
}
