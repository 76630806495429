export interface Field {
  name: string;
  value: string;
}

export interface Account {
  appId: string;
  connectorTypeId: string;
  connectionName: string;
  fields: Field[];
}

export function generateJSONForAccountValidation(requestData, data = null): Account {
  const fields = Object.keys(requestData.fields).map((name) => {
    const value = requestData.fields[name];
    return { name, value: value };
  });
  if (data) {
    for (let i in data.data) {
      fields.push({ name: parseOauthData(i), value: data.data[i] });
    }
  }
  return {
    appId: requestData.id,
    connectorTypeId: requestData.connectionConfig.connectorTypeId,
    connectionName: requestData.connectionName,
    fields,
  };
}

function parseOauthData(data: string) {
  let payload = {
    access_token: "accessToken",
    refresh_token: "refreshToken",
  };
  if (payload[data]) {
    return payload[data];
  } else return data;
}

export function parseOauthUrl(url: string, data: any) {
  const regex = /\{(.*?)\}/g;
  const matches = url.match(regex);
  if (!matches || matches?.length == 0) {
    return url;
  }
  for (const match of matches) {
    const content = match.slice(1, -1);
    url = url.replace(new RegExp(`{${content}}`, "gi"), data[content]);
  }
  return url;
}
