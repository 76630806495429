import useSWR from "swr";

import { fetcher, axiosInstance, options, setTenantId } from "./libs/fetch";
import moment from "moment";

const PAGE_LIMIT = 10;

export function useAdminEventLogs(page, queryValue = "", filterParam) {
  const { konnectorName, appName, date, userId } = filterParam;
  var startDate = date?.start?.toISOString();
  var endDate = date?.end?.toISOString();
  var startDateone = moment(endDate).startOf("day").toISOString();
  var endDateone = moment(endDate).endOf("day").toISOString();
  const url = `/endusers/event-logs/filter?pageNumber=${page}&pageSize=${PAGE_LIMIT}&sortValue=${queryValue}&konnectorId=${konnectorName}&appName=${appName}&userId=${userId}`;
  let startParam =
    startDate !== undefined ? `&startDate=${startDate !== endDate ? startDate : startDateone || ""}` : `&startDate=`;
  let endParam = endDate !== undefined ? `&endDate=${endDate !== startDate ? endDate : endDateone || ""}` : `&endDate=`;

  return useSWR(`${url}${startParam}${endParam}`, fetcher);
}

export function useAllKonnector() {
  return useSWR(`/endusers/konnectors/all`, fetcher);
}
export function useKonnectors(page, sortOrder = "", filterBy, limit = PAGE_LIMIT, appId) {
  const queryValue = sortOrder.split("-")[0] || "";
  const sortId = sortOrder.split("-")[1] || "";
  return useSWR(
    `/endusers/konnectors/${appId}/all?pageNumber=${page}&pageSize=${limit}${
      filterBy !== "active" ? "&sortValue=" + queryValue + "&sortOrder=" + sortId : ""
    }${
      filterBy === "active" ? "&filterBy=status" : filterBy === "freshdesk" ? "&filterBy=sourceTemplate.freshdesk" : ""
    }`,
    fetcher
  );
}
export function useAdminEventlog(eventId) {
  return useSWR(`/endusers/event-logs/${eventId}`, fetcher);
}
export function useAdminKonnector(id) {
  return useSWR(`/endusers/konnectors/${id}`, fetcher);
}
